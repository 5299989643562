import { UserRole, Trial } from '@curebase/core/types';
import { GtmContainerBlob } from '../../lib/analytics-gtm';

export interface ReduxUserRole {
  __typename: Pick<UserRole, '__typename'>;
  id: UserRole['id'];
  type: UserRole['type'];
  trial?: Pick<Trial, '__typename' | 'id'>;
}

interface SharedAdditionalInfo {
  firstName?: string;
  lastName?: string;
  roles?: ReduxUserRole[];
  allowedTrials?: Trial[];
}

interface SponsorInfo extends SharedAdditionalInfo {
  sponsorId: number;
  trialIdsAndLabels: any[];
}

interface ProviderInfo extends SharedAdditionalInfo {
  clinics: {
    id: number;
    name: string;
  }[];
}

interface PatientInfo extends SharedAdditionalInfo {
  patientId: number;
  trialId: number;
  trialName: string;
  clinicId: number;
  clinicName: string;
}

export type TTrackingData = {
  trialIdentifier?: string;
  referralCode?: string;
  utmCode?: string;
};

export type AdditionalUserInfo =
  | SponsorInfo
  | ProviderInfo
  | PatientInfo
  | SharedAdditionalInfo;

export interface UserState {
  userId?: number;
  userType: any;
  isStub?: boolean;
  pinCode?: string;
  additionalUserInfo?: AdditionalUserInfo;
  fullLegalAdoption?: boolean;
  trackingData?: TTrackingData;
  phoneNumber?: string;
  email?: string;
  trialOptionId?: number;
  gtmContainers?: GtmContainerBlob;
  roles?: ReduxUserRole[];
}

export const SET_USER = 'SET_USER';
export const SET_PIN_CODE = 'SET_PIN_CODE';
export const FULLY_ADOPT_LEGAL = 'FULLY_ADOPT_LEGAL';
export const SAVE_TRACKING_DATA = 'SAVE_TRACKING_DATA';
export const CLEAR_USER = 'CLEAR_USER';
export const DONT_ADOPT_LEGAL = 'DONT_ADOPT_LEGAL';
export const DELETE_SELECTED_TRIAL = 'DELETE_SELECTED_TRIAL';
export const SELECT_TRIAL = 'SELECT_TRIAL';
export const SET_GTM_CONTAINERS = 'SET_GTM_CONTAINERS';

export interface FullAdoptLegalInterface {
  type: typeof FULLY_ADOPT_LEGAL;
}

export interface SetPinCodeInterface {
  type: typeof SET_PIN_CODE;
  pinCode: string;
}

export interface SaveTrackingDataInterface {
  type: typeof SAVE_TRACKING_DATA;
  data: TTrackingData;
}

export interface ClearUserInterface {
  type: typeof CLEAR_USER;
}

export interface DontAdoptLegalInterface {
  type: typeof DONT_ADOPT_LEGAL;
}

export interface DeleteTrialOptionInterface {
  type: typeof DELETE_SELECTED_TRIAL;
}

export interface SetGtmContainers {
  type: typeof SET_GTM_CONTAINERS;
  payload: { gtmContainers: GtmContainerBlob };
}

export interface SelectTrialOptionInterface {
  type: typeof SELECT_TRIAL;
  payload: { trialOptionId: number };
}

export interface SetUserInterface {
  type: typeof SET_USER;
  isStub?: boolean;
  userId?: number;
  userType: any;
  pinCode?: string;
  phoneNumber?: string;
  email?: string;
  additionalUserInfo?: AdditionalUserInfo;
  roles?: ReduxUserRole[];
}

export type UserActionTypes =
  | ClearUserInterface
  | FullAdoptLegalInterface
  | SaveTrackingDataInterface
  | SetPinCodeInterface
  | SetUserInterface
  | DontAdoptLegalInterface
  | DeleteTrialOptionInterface
  | SelectTrialOptionInterface
  | SetGtmContainers;
