import { SaveDataCaptureResponse } from '@curebase/core/decoders/data';
import {
  dataCaptureCanBeViewed,
  flattenCapturedData,
} from '@curebase/modules/dataCapture/services';
import {
  markDependenciesOnChange,
  validateDynamicFormPages,
} from '@curebase/core/lib/dynamicform/dynamicForms';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';
import { getAutoFilledCapturedDataForSurvey } from '@curebase/core/lib/mockTrialOptions';
import {
  DataCapture,
  DataCaptureQuery,
  DataCaptureQueryVariables,
  PostCompletionContentOptions,
} from '@curebase/core/types';
import { Mutex } from 'async-mutex';
import { Location } from 'history';
import produce from 'immer';
import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import _pull from 'lodash/pull';
import _range from 'lodash/range';
import _uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import React from 'react';
import { TFunction, useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { executeQuery } from 'src/ApolloClient';
import { hideZendeskWidget, showZendeskWidget } from 'src/lib/zendeskWidget';
import { StatusColor } from 'src/shared/lib/colors';
import { ErrorResponse } from '@curebase/core/decoders/patients';
import {
  autoCompleteSignCaseReport,
  saveDataCapture,
  signCaseReport,
  submitCapturedData,
  uploadFile,
  validateCapturedData,
} from '../../controllers/dataCaptureController';
import { getNextExploreRoute } from '../../controllers/patientController';
import {
  attemptCustomGtmEvent,
  GtmContainerBlob,
  gtmContainerInit,
  GtmCustomEvents,
} from '../../lib/analytics-gtm';
import { generateDynamicFormPagesFromCaseReport } from '../../lib/caseReports';
import { userIsParticipant } from '../../lib/users';
import {
  showAlertMessage,
  showEnterPatientModeDialog,
  showExitPatientModeDialog,
} from '../../store/actions';
import { PatientModeState } from '../../store/reducers/patientMode';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import DynamicForm from '../basic/DynamicForm';
import { FileUploadContext } from '../basic/FilePreview';
import Dialog from '../basic/SafeDialog';
import { InjectedProps, withQueryResult } from '../hocs/WithQueryResult';
import Loading from '../Loading';
import PageHeader from '../PageHeader';
import { getPostInfoPageConfig } from '../ParticipantInterface/ParticipantDataCaptureReview';
import CustomStyleProvider from '../../providers/CustomStyleProvider';
import DataCaptureMissed from './DataCaptureMissed';
import SignatureCaptureInDialog from '../basic/SignatureCaptureInDialog';
import { onDialogOpen } from '../../utils/mobileHelpers';
import { DataCaptureVisitHeader } from './DataCaptureVisitHeader';
import { submitSkipData } from '../../controllers/dataCaptureController';
import ExploreStepProgress from '../Explore/ExploreStepProgress';
import { getI18nValue } from 'src/utils/locales';
import { getLocale } from 'src/context/localeContext';

interface OwnProps {
  mountingFunc?: (configId: string) => any;
}

interface ReduxProps {
  patientModeState: PatientModeState;
  gtmContainers: GtmContainerBlob;
  currentDate: number;
}

interface DataCaptureProps
  extends OwnProps,
    ReduxProps,
    InjectedProps<
      DataCaptureQueryVariables,
      DataCaptureQuery,
      { visitId: string }
    > {
  t: TFunction;
}

type State = {
  data: Record<string, Record<string, any>>;
  errors: Object;
  currentFormPage: number;
  pagesAndCaseReportSlugs: any[];
  completedFormPages: number[];
  loading: boolean;
  inPatientMode: boolean;
  showSurrogateWarning: boolean;
  disclaimerAccepted: boolean;
  requireSignature: boolean;
  signedCaseReports: object;
  nextPage: number;
  submitText: string | undefined;
  nextText: string | undefined;
  showSkipDialog: boolean;
};

const SAVE_DEBOUNCE_MS = 2000;

const isExploreMode = (location: Location | undefined) =>
  location?.pathname.startsWith('/explore') ?? false;

// set null values for every possible data field in the visit
const getNullSubmissionData = (
  queryResult: DataCaptureQuery
): Record<string, Record<string, any | null>> => {
  const { getVisit: visit } = queryResult;
  const initialData = {};
  for (const caseReport of visit.studyPlanVisit.caseReports) {
    initialData[caseReport.id] = {};
    for (const dataField of caseReport.dataFields) {
      initialData[caseReport.id][dataField.id] = null;
    }
  }
  return initialData;
};

const capturedDataForVisit = (queryResult: DataCaptureQuery | undefined) => {
  if (!queryResult) return {};
  const nullSubmissionData = getNullSubmissionData(queryResult);
  const { getVisit: visit } = queryResult;
  const {
    studyPlanVisit: { caseReports },
  } = visit;
  return produce(nullSubmissionData, data => {
    const { caseReportInstances = [] } = visit;
    for (const cri of caseReportInstances) {
      const caseReport = caseReports.find(cr => cr.id === cri.caseReportId);
      if (!caseReport) continue;
      const { capturedData = [] } = cri;
      for (const datum of capturedData) {
        const dataField = caseReport.dataFields.find(
          df => df.id === datum.dataFieldId
        );
        if (!dataField) continue;
        if (!data[caseReport.id]) data[caseReport.id] = {};
        data[caseReport.id][dataField.id] = datum.value;
      }
    }
  });
};

const getPageErrors = (pages: any[], data: any, t: TFunction) => {
  return (
    validateDynamicFormPages(
      pages,
      flattenCapturedData(data),
      undefined,
      undefined,
      undefined,
      t
    ) ?? {}
  );
};

const SurrogateDialog = (props: { open: boolean; onConfirm: () => void }) => {
  const history = useHistory();
  const { t } = useTranslation('translations');

  const onClose = () => history.goBack();
  return (
    <Dialog open={props.open} onClose={onClose}>
      <ConfirmationInDialog
        title={t('dataCapture.confirmDialog.title')}
        message={t('dataCapture.confirmDialog.message')}
        onClose={onClose}
        onConfirm={props.onConfirm}
      />
    </Dialog>
  );
};

const ConfirmSkipDialog = (props: {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation('translations');

  return (
    <Dialog open={props.open}>
      <ConfirmationInDialog
        title={t(
          'participants.skipConfirmTitle',
          'Are you sure you want to skip this task?'
        )}
        message={t(
          'participants.skipConfirmMsg',
          'This task is optional. Skipping will not affect your participation in this study but you will not receive any additional compensation associated with completing this task.'
        )}
        onConfirm={props.onConfirm}
        onClose={props.onClose}
      />
    </Dialog>
  );
};

const saveProgressMutex = new Mutex();

class DataCaptureView extends React.Component<DataCaptureProps, State> {
  state: State = {
    data: {},
    errors: {},
    currentFormPage: 0,
    pagesAndCaseReportSlugs: [],
    completedFormPages: [],
    loading: true,
    showSurrogateWarning: true, // Even when true, surrogateWarning is only shown when this.useSurrogateMode() == true
    inPatientMode: true,
    disclaimerAccepted: false,
    requireSignature: false,
    signedCaseReports: {},
    nextPage: 0,
    submitText: undefined,
    nextText: undefined,
    showSkipDialog: false,
  };

  autoScrollToTop = () => {
    setTimeout(() => {
      window?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 400);
  };

  async componentDidMount() {
    const { queryResult, history, gtmContainers } = this.props;
    const visit = queryResult.getVisit;
    if (!visit) return history.push('/');

    gtmContainerInit(gtmContainers);
    this.prepareState();
    this.handleZendeskIntegration();
    this.autoScrollToTop();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onPageUnload);
  }

  handleZendeskIntegration() {
    const { queryResult } = this.props;
    const visit = queryResult.getVisit;
    const trial = visit?.trialOption?.trial;

    const shouldPresentZendeskWidget = trial?.participantIntegrations?.some(
      integration => integration === 'ZENDESK'
    );

    if (!shouldPresentZendeskWidget) {
      hideZendeskWidget();
    } else {
      showZendeskWidget();
    }
  }

  async prepareState() {
    const { queryResult, mountingFunc, t } = this.props;
    const { locale } = getLocale();
    const visit = queryResult.getVisit;
    if (mountingFunc) mountingFunc(visit.studyPlanVisit.slug);

    const { studyPlanVisit, caseReportInstances } = visit;
    const errors = {};
    const pagesAndCaseReportSlugs = [] as any[];
    const completedFormPages = [] as number[];

    for (const caseReport of studyPlanVisit.caseReports) {
      errors[caseReport.id] = {};

      const pages = generateDynamicFormPagesFromCaseReport(caseReport);
      for (const [index, page] of pages.entries()) {
        pagesAndCaseReportSlugs.push({
          caseReport,
          caseReportId: caseReport.id,
          page,
          lastPageForCRF: pages.length - 1 === index,
        });
      }
    }

    for (const i of _range(pagesAndCaseReportSlugs.length)) {
      const currentCaseReport = studyPlanVisit.caseReports.find(
        cr => cr.id === pagesAndCaseReportSlugs[i].caseReportId
      );
      if (!currentCaseReport) throw new Error('Missing case report');
      if (!studyPlanVisit) throw new Error('Missing studyplanvisit');

      const caseReportPageCount = _filter(
        pagesAndCaseReportSlugs,
        obj => obj.caseReportId === currentCaseReport.id
      ).length;
      const caseReportLocalIndex =
        i -
        _findIndex(
          pagesAndCaseReportSlugs,
          obj => obj.caseReportId === currentCaseReport.id
        ) +
        1;

      const nameI18n = getI18nValue(currentCaseReport, locale, 'name');
      const stepName =
        caseReportPageCount === 1
          ? nameI18n
          : `${nameI18n} ${caseReportLocalIndex > 1 ? '(cont.)' : ''}`;

      const nPages = pagesAndCaseReportSlugs.length;
      if (nPages > 1) {
        pagesAndCaseReportSlugs[i].page.headerText =
          t('dataCapture.stepTitle', {
            current: i + 1,
            steps: nPages,
          }) + `: ${stepName}`;
      } else {
        pagesAndCaseReportSlugs[i].page.headerText = t(stepName, stepName);
      }

      if (currentCaseReport.knowledgeContent) {
        pagesAndCaseReportSlugs[i].page.knowledgeContent = getI18nValue(
          currentCaseReport,
          locale,
          'knowledgeContent'
        );
      }
    }

    const data = capturedDataForVisit(queryResult);

    let i = 0;
    let minPageWithError;
    const validationErrors = await this.getValidationErrors(data, false);
    for (const { page, caseReportId } of pagesAndCaseReportSlugs) {
      const pagesData = data[caseReportId];
      const atLeastOneQuestionAnswered = Object.values(pagesData).some(
        answer => answer
      );

      const pageErrors = getPageErrors([page], data, t);
      if (
        _isEmpty(pageErrors) &&
        _isEmpty(validationErrors) &&
        atLeastOneQuestionAnswered
      ) {
        completedFormPages.push(i);
      } else if (minPageWithError === undefined) {
        minPageWithError = i;
      }
      i += 1;
    }
    let initialPageIndex = minPageWithError || 0;
    if (
      initialPageIndex > 0 &&
      !pagesAndCaseReportSlugs[initialPageIndex].lastPageForCRF &&
      pagesAndCaseReportSlugs[initialPageIndex - 1].caseReport
        .requireSignature &&
      caseReportInstances
    ) {
      const caseReportInstance = caseReportInstances.find(
        cri =>
          cri.caseReportId ===
          pagesAndCaseReportSlugs[initialPageIndex - 1].caseReportId
      );
      if (!caseReportInstance!.signed) initialPageIndex--;
    }
    const inPatientMode = !userIsParticipant();
    if (inPatientMode) {
      this.updatePatientModeState(initialPageIndex, pagesAndCaseReportSlugs);
    }

    window.addEventListener('beforeunload', this.onPageUnload);

    this.setState(
      {
        data,
        errors,
        currentFormPage: initialPageIndex,
        pagesAndCaseReportSlugs,
        completedFormPages,
        inPatientMode,
        loading: false,
      },
      () => this.prepareForSignature()
    );
  }

  onPageUnload = event => {
    this.saveProgressDebounce.flush();
  };

  useSurrogateMode = () => {
    return (
      !userIsParticipant() &&
      (this.props.queryResult.getVisit?.studyPlanVisit?.isRemote ?? false)
    );
  };

  updatePatientModeState = (
    pageIndex: number,
    pagesAndCaseReportSlugs: any
  ) => {
    const { patientModeState, history, location } = this.props;

    if (!this.useSurrogateMode()) {
      const inPatientModeOrExiting = [
        'IN_PATIENT_MODE',
        'SHOW_EXIT_DIALOG',
      ].includes(patientModeState);

      const caseReportIsForPatient =
        pagesAndCaseReportSlugs[pageIndex].caseReport.forPatient;

      if (caseReportIsForPatient && !inPatientModeOrExiting) {
        showEnterPatientModeDialog({
          pendingSettings: {
            baseRoute: location.pathname,
            successRoute: location.pathname,
          },
          history,
        });
      }
      if (!caseReportIsForPatient && inPatientModeOrExiting) {
        showExitPatientModeDialog(() => {});
      }
    }
  };

  exploreModeSubmit = async (trialOption, visit, response) => {
    const { history } = this.props;

    return history.push(
      await getNextExploreRoute({
        trialIdentifier: trialOption.trial.trialIdentifier,
        trialOptionId: trialOption.id,
      })
    );
  };

  goToParticipantPostSubmissionPage = async visitId => {
    const { history } = this.props;
    const { evaluation, studyPlanVisit } = (
      await executeQuery(
        `
        getVisit(id: ${visitId}) {
          id
          evaluation {
            didPass
          }
          studyPlanVisit {
            id
            postCompletionContent {
              PASSED
              FAILED
            }
          }
        }`
      )
    ).getVisit;

    if (evaluation.didPass) {
      attemptCustomGtmEvent(GtmCustomEvents.DATA_CAPTURE_COMPLETED_SUCCESS);
    }

    const configToUse = getPostInfoPageConfig(
      evaluation?.didPass,
      studyPlanVisit?.postCompletionContent
    );
    if (configToUse === PostCompletionContentOptions.NoPage) {
      history.push('/u');
    } else {
      history.push(`/u/data/${visitId}/review`);
    }
  };

  submitData = async () => {
    const { history, queryResult, currentDate, location } = this.props;
    const visit = queryResult.getVisit;
    if (!visit) return;
    const { trialOption, studyPlanVisit } = visit;

    const response = await this.saveProgress();
    if (!response.success) {
      return;
    }

    //TODO add mock date HERE WITH DEVELOPER_FEATURES
    const responseCapturedData = await submitCapturedData(
      visit.id,
      currentDate
    );

    // @ts-ignore
    if (responseCapturedData.error) {
      const error = responseCapturedData as ErrorResponse;
      const currentPage = this.state.pagesAndCaseReportSlugs.findIndex(page => {
        return (page?.page?.elements || []).find(i => {
          return Object.keys(error.errorFields || {}).indexOf(i.key) > -1;
        });
      });
      this.setState({
        errors: error.errorFields as any,
        currentFormPage: currentPage,
      });
      return;
    }

    // this is called on final submission and on each page turn.
    attemptCustomGtmEvent(
      `${
        isExploreMode(location)
          ? GtmCustomEvents.PRE_SCREENER_CASE_REPORT_COMPLETED
          : GtmCustomEvents.CASE_REPORT_COMPLETED
      }`
    );
    if (isExploreMode(location)) {
      return await this.exploreModeSubmit(trialOption, visit, response);
    }

    // @ts-ignore
    if (responseCapturedData.success) {
      if (userIsParticipant()) {
        await this.goToParticipantPostSubmissionPage(visit.id);
      } else {
        return history.push(
          `/u/data/${trialOption.id}/${studyPlanVisit.slug}/review`
        );
      }
    } else {
      this.displayErrorDialog();
    }
  };

  getPageByNumber = (pageNumber: number) => {
    const { pagesAndCaseReportSlugs } = this.state;
    const { page } = pagesAndCaseReportSlugs[pageNumber];
    return page;
  };

  prepareForSignature = () => {
    const { t } = this.props;
    const { pagesAndCaseReportSlugs, currentFormPage } = this.state;
    const { getVisit } = this.props.queryResult;
    const { lastPageForCRF, caseReport } = pagesAndCaseReportSlugs[
      currentFormPage
    ];
    if (!caseReport.requireSignature) return;

    this.setState({
      nextText: undefined,
      submitText: undefined,
    });

    if (!lastPageForCRF) return;

    const alreadySigned =
      this.state.signedCaseReports[caseReport.id] ||
      getVisit.caseReportInstances.find(
        cri => cri.caseReportId === caseReport.id
      )?.signed;
    if (alreadySigned) return;

    const isLastPageForSPV =
      pagesAndCaseReportSlugs.length - 1 === currentFormPage;
    this.setState({
      nextText: !isLastPageForSPV ? t('dataCapture.signNextBtn') : undefined,
      submitText: isLastPageForSPV ? t('dataCapture.signSubmitBtn') : undefined,
    });
  };

  onPageChange = async (newPage: number) => {
    const {
      currentFormPage,
      pagesAndCaseReportSlugs,
      completedFormPages,
    } = this.state;
    this.autoScrollToTop();
    const {
      queryResult: { getVisit: visit },
      location,
    } = this.props;
    if (!visit) return;
    const isPageForward = newPage > currentFormPage;
    const errors = await this.checkDataForErrors(currentFormPage);
    const updatedCompletedFormPages = !_isEmpty(errors)
      ? _pull(completedFormPages, currentFormPage)
      : _uniq([...completedFormPages, currentFormPage]);

    const changePage = async () => {
      if (isPageForward) {
        attemptCustomGtmEvent(
          `${
            isExploreMode(location)
              ? GtmCustomEvents.PRE_SCREENER_CASE_REPORT_COMPLETED
              : GtmCustomEvents.CASE_REPORT_COMPLETED
          }`
        );
      }

      if (!userIsParticipant()) {
        this.updatePatientModeState(newPage, pagesAndCaseReportSlugs);
      }

      // this is executed AFTER the page has been changed
      this.prepareForSignature();
    };

    //save data BEFORE changing the page
    this.saveProgressDebounce.cancel();
    await this.saveProgress();

    if (isPageForward) {
      if (!_isEmpty(errors)) {
        this.setState({ errors });
        return;
      }
    }

    const { lastPageForCRF, caseReport } = pagesAndCaseReportSlugs[
      currentFormPage
    ];

    const alreadySigned =
      this.state.signedCaseReports[caseReport.id] ||
      visit.caseReportInstances.find(cri => cri.caseReportId === caseReport.id)
        ?.signed;

    //show the signature form if required, and don't change the page
    if (
      isPageForward &&
      caseReport.requireSignature &&
      !alreadySigned &&
      lastPageForCRF
    ) {
      this.setState({ requireSignature: true, nextPage: newPage });
      return;
    }

    this.setState(
      {
        currentFormPage: newPage,
        completedFormPages: updatedCompletedFormPages,
      },
      async () => {
        changePage();
      }
    );
  };

  displayErrorDialog = () => {
    showAlertMessage(
      'Your responses could not be saved. If this error persists please contact support@mycurebase.com',
      StatusColor.Red,
      30
    );
  };

  setStateSynchronous = stateUpdate => {
    return new Promise<void>(resolve => {
      this.setState(stateUpdate, () => resolve());
    });
  };

  getCurrentPageData = (
    pageNumber: number
  ): Record<string, Record<string, any>> => {
    const { data } = this.state;
    const dataAsEntries = Object.entries(data);
    const pageDataFieldIds =
      this.state.pagesAndCaseReportSlugs[pageNumber]?.page?.elements?.map(
        item => item.key
      ) ?? [];

    const currentPageData: Record<string, Record<string, any>> = {};

    for (const dataFieldId of pageDataFieldIds) {
      const [caseReportId, caseReportData] =
        dataAsEntries.find(
          ([_, caseReportData]) => caseReportData[dataFieldId] !== undefined
        ) ?? [];
      if (!caseReportId || !caseReportData) continue;

      if (!currentPageData[caseReportId]) {
        currentPageData[caseReportId] = {};
      }

      currentPageData[caseReportId][dataFieldId] = caseReportData[dataFieldId];
    }
    return currentPageData;
  };

  saveProgress = async (
    allData: boolean = false
  ): Promise<SaveDataCaptureResponse> => {
    const { t } = this.props;
    return await saveProgressMutex.runExclusive(async () => {
      const { queryResult, history } = this.props;
      const visit = queryResult.getVisit;
      const trialOptionId = visit.trialOption.id;
      this.setState({ errors: {} });

      const pageDataFieldIds =
        this.state.pagesAndCaseReportSlugs[
          this.state.currentFormPage
        ]?.page?.elements?.map(item => item.key) ?? [];
      const dataToSave = allData
        ? this.state.data
        : _pickBy(flattenCapturedData(this.state.data), (_, key) =>
            pageDataFieldIds.includes(key)
          );

      //upload all the signatures
      const dataWithFileIds = await produce(dataToSave, async draft => {
        for (const [k, v] of Object.entries(dataToSave)) {
          if (v instanceof File) {
            const { path } = await uploadFile(v, trialOptionId);
            draft[k] = path;
          }
        }
      });

      //add the new /save request to pending promises
      const { currentDate } = this.props;
      const saveDataPromise = saveDataCapture(
        visit.id,
        dataWithFileIds,
        currentDate
      );

      const response = await saveDataPromise;

      if (response.success) {
        showAlertMessage(t('questionStatus.successMessage'), StatusColor.Green);
        //@ts-ignore
      } else if (response?.error?.startsWith('Visit time restricted')) {
        history.push('/');
        showAlertMessage(t('questionStatus.errorMessage'), StatusColor.Red, 5);
      } else {
        this.displayErrorDialog();
      }

      return response;
    });
  };

  saveProgressDebounce = _debounce(this.saveProgress, SAVE_DEBOUNCE_MS);

  getValidationErrors = async (data: Object, entireSpv: boolean) => {
    const visit = this.props.queryResult.getVisit;
    if (!visit) return {};
    const { studyPlanVisit, trialOption } = visit;

    return (
      (
        await validateCapturedData(
          data,
          studyPlanVisit.id,
          trialOption.id,
          entireSpv
        )
      ).validation ?? {}
    );
  };

  checkDataForErrors = async (pageNumber?: number) => {
    const { data, pagesAndCaseReportSlugs } = this.state;

    const dataToValidate =
      pageNumber !== undefined && pageNumber !== null //because if pageNumber === 0, it's falsy
        ? this.getCurrentPageData(pageNumber)
        : data;
    const validationErrors = await this.getValidationErrors(
      dataToValidate,
      pageNumber !== undefined ? false : true
    );
    const pageErrors = getPageErrors(
      pageNumber !== undefined && pageNumber !== null //because if pageNumber === 0, it's falsy
        ? [this.getPageByNumber(pageNumber)]
        : pagesAndCaseReportSlugs.map(obj => obj.page),
      data,
      this.props.t
    );

    return { ...validationErrors, ...pageErrors };
  };

  onChange = (key, value, pageIndex, optionalMeta) => {
    const { type } = optionalMeta ?? {};
    const { pagesAndCaseReportSlugs, data } = this.state;
    const {
      page: { elements },
      caseReportId,
    } = pagesAndCaseReportSlugs[pageIndex];

    const currentPageData = data[caseReportId];

    const proposedNewPageData = {
      ...currentPageData,
      [key]: value,
    };

    this.setState(
      {
        data: {
          ...this.state.data,
          [caseReportId]: markDependenciesOnChange(
            proposedNewPageData,
            elements
          ),
        },
      },
      () => {
        //hacky workaround. replace soon. Key normally is a dataFieldId
        if (type && type !== 'TELEHEALTH_FILE_UPLOAD')
          this.saveProgressDebounce();
      }
    );
  };

  render() {
    const { queryResult, location, history, t } = this.props;
    const visit = queryResult.getVisit;
    const { status, id: visitId } = visit;
    const {
      currentFormPage,
      pagesAndCaseReportSlugs,
      data,
      loading,
      showSkipDialog,
    } = this.state;
    if (loading) return <Loading />;
    if (!visit) return null;
    const { studyPlanVisit, completedAt } = visit;
    const { canRetry } = studyPlanVisit;
    const {
      patient,
      id: trialOptionId,
      trial: { customStyle, name, deactivated },
      studyActivity,
      status: trialOptionStatus,
      subjectIdentifier,
    } = visit.trialOption;
    if (!patient?.user) throw new Error('Missing User');
    const { user } = patient;
    const { inPatientMode } = this.state;
    const activityConfig = studyActivity?.config ?? {};
    const { caseReport = null } =
      pagesAndCaseReportSlugs[currentFormPage] || {};
    const thisCR = visit.studyPlanVisit.caseReports.find(
      cri => cri?.id === caseReport?.id
    );
    let hiddenFieldsCount = 0;
    if (thisCR && caseReport) {
      // This is a slightly hacky way of figuring out whether this case report
      // has any hidden fields, and if so, how many hidden fields there are.
      // @ts-ignore
      hiddenFieldsCount =
        // @ts-ignore
        thisCR?.caseReportInstances?.reduce(
          (prev, curr) => {
            if (prev.value > curr?.hiddenFieldsCount) {
              return prev;
            }
            return { value: curr?.hiddenFieldsCount };
          },
          // @ts-ignore
          { value: thisCR.caseReportInstances[0]?.hiddenFieldsCount }
        )?.value || 0;
    }

    const canSkipActivity =
      userIsParticipant() &&
      activityConfig.optional &&
      activityConfig.showSkipButton;

    return dataCaptureCanBeViewed(visit.status, canRetry) ? (
      <CustomStyleProvider customStyle={customStyle}>
        <Dialog
          maxWidth={false}
          open={onDialogOpen(this.state.requireSignature)}
          onClose={() => {
            this.setState({ requireSignature: false });
          }}
        >
          <SignatureCaptureInDialog
            inPatientMode={inPatientMode}
            reason={'I certify that the information provided is accurate'}
            patient={patient}
            onSubmit={async (signatureData: any) => {
              const { pagesAndCaseReportSlugs, currentFormPage } = this.state;
              const { caseReport } = pagesAndCaseReportSlugs[currentFormPage];
              const {
                getVisit: { id: visitId },
              } = this.props.queryResult;

              //re-fetch caseReportInstances to get newly created ids
              const {
                getVisit: { caseReportInstances },
              } = await executeQuery(`getVisit(id: ${visitId}) {
                      id
                      caseReportInstances {
                          id
                          caseReportId
                      }
                  }
              `);

              const caseReportInstanceId = caseReportInstances?.find(
                cri => cri.caseReportId === caseReport.id
              )?.id;

              if (!caseReportInstanceId)
                throw new Error('Missing caseReportInstanceId');

              const { success } = await signCaseReport({
                visitId,
                caseReportInstanceId,
                signature: signatureData,
              });
              if (!success) throw new Error('Could not sign the case report');

              const signedCaseReports = {
                ...this.state.signedCaseReports,
                [caseReport.id]: true,
              };

              this.setState(
                {
                  signedCaseReports,
                  requireSignature: false,
                },
                async () => {
                  const isLastPageForSPV =
                    pagesAndCaseReportSlugs.length - 1 === currentFormPage;
                  if (isLastPageForSPV) {
                    this.saveProgressDebounce.cancel();
                    await this.submitData();
                  } else {
                    await this.onPageChange(this.state.nextPage);
                  }
                }
              );
            }}
            onClose={() => this.setState({ requireSignature: false })}
          />
        </Dialog>
        <div className='data-capture'>
          {!isExploreMode(location) && <PageHeader customStyle={customStyle} />}

          {!userIsParticipant() && (
            <DataCaptureVisitHeader
              status={trialOptionStatus}
              trialName={name}
              trialDeactivated={deactivated}
              history={this.props.history}
              displayName={user.displayName!!}
              subjectIdentifier={subjectIdentifier!!}
              studyPlanVisitSummary={visit.studyPlanVisit.summary}
              onBack={() =>
                this.props.history.push(
                  `/u/data/${trialOptionId}/${visit.studyPlanVisit.slug}/review`
                )
              }
              trialOption={visit.trialOption}
            />
          )}

          <FileUploadContext.Provider
            value={{
              trialOptionId,
            }}
          >
            {Boolean(hiddenFieldsCount) && (
              <div className='data-message'>
                {' '}
                {t('dataCaptureResultsView.hiddenFieldSubtext', {
                  count: hiddenFieldsCount,
                })}
              </div>
            )}
            <DynamicForm
              // @ts-ignore
              pages={pagesAndCaseReportSlugs.map(obj => obj.page)}
              currentPage={currentFormPage}
              visitId={visit.id}
              nextText={this.state.nextText}
              submitText={this.state.submitText}
              onPageChange={this.onPageChange}
              data={flattenCapturedData(data)}
              errors={this.state.errors}
              onChange={this.onChange}
              leftSideFooterSlot={
                isExploreMode(location) && <ExploreStepProgress mobileOnly />
              }
              onSubmit={async () => {
                const errors = await this.checkDataForErrors();

                if (!_isEmpty(errors)) {
                  // clear queue and save changes that were entered immediately before submit.
                  // If we don't do this, we will silence validator errors
                  this.saveProgressDebounce.cancel();
                  await this.saveProgress();
                  this.setState({ errors });
                  alert(
                    'You have incomplete responses. Ensure each section is complete, then submit.'
                  );
                } else {
                  const { caseReport } = pagesAndCaseReportSlugs[
                    currentFormPage
                  ];

                  const alreadySigned =
                    this.state.signedCaseReports[caseReport.id] ||
                    visit.caseReportInstances.find(
                      cri => cri.caseReportId === caseReport.id
                    )?.signed;

                  //show the signature form if required, and don't change the page
                  if (caseReport.requireSignature && !alreadySigned) {
                    this.setState({
                      requireSignature: true,
                    });
                    return;
                  }
                  // Clear debounce queue and await saveProgress inside of
                  // submitData to avoid a race condition
                  this.saveProgressDebounce.cancel();
                  await this.submitData();
                }
              }}
              onSkipActivity={
                canSkipActivity
                  ? () => {
                      this.setState({ showSkipDialog: true });
                    }
                  : undefined
              }
              preferences={{
                hidePersonalInformation: true,
              }}
              autocomplete={
                DEVELOPER_FEATURES_ENABLED
                  ? () => {
                      const filledData = getAutoFilledCapturedDataForSurvey(
                        studyPlanVisit.caseReports
                      );
                      this.setState(
                        {
                          data: filledData,
                          errors: {},
                        },
                        async () => {
                          const { currentDate } = this.props;
                          await saveDataCapture(
                            visit.id,
                            filledData,
                            currentDate
                          );
                          //save signatures if needed
                          const {
                            getVisit: {
                              id: visitId,
                              studyPlanVisit: { caseReports },
                            },
                          } = this.props.queryResult;
                          if (caseReports.find(cr => cr.requireSignature)) {
                            await autoCompleteSignCaseReport(visitId);
                          }

                          await this.submitData();
                        }
                      );
                    }
                  : null
              }
            />
          </FileUploadContext.Provider>
          <SurrogateDialog
            open={this.useSurrogateMode() && this.state.showSurrogateWarning}
            onConfirm={() =>
              void this.setState({
                showSurrogateWarning: false,
              })
            }
          />
          <ConfirmSkipDialog
            open={canSkipActivity === true && showSkipDialog}
            onConfirm={() => {
              submitSkipData(visitId)
                .then(res => {
                  showAlertMessage(
                    t('participants.skipTaskSuccess', 'Activity skipepd!'),
                    StatusColor.Green,
                    5000
                  );
                  return history.push('/');
                })
                .catch(err => {
                  console.error('Error on try to skip Activity!', { err });
                  showAlertMessage(
                    t(
                      'participants.skipTaskFail',
                      'Sorry! An error occurred while trying to skip the activity.'
                    ),
                    StatusColor.Red,
                    5000
                  );
                })
                .finally(() => this.setState({ showSkipDialog: false }));
            }}
            onClose={() => this.setState({ showSkipDialog: false })}
          />
        </div>
      </CustomStyleProvider>
    ) : (
      <CustomStyleProvider customStyle={customStyle}>
        <DataCaptureMissed
          completedAt={moment(completedAt!)
            .tz(user.safeTimezone)
            .format('LLLL')}
          status={status}
        />
      </CustomStyleProvider>
    );
  }
}

const mapStateToProps = state => ({
  patientModeState: state.patientMode.state,
  gtmContainers: state.user.gtmContainers,
  currentDate: state.currentDate,
});

const DataCaptureComponent = withQueryResult<
  DataCaptureQueryVariables,
  DataCaptureQuery,
  OwnProps,
  { visitId: string }
>(DataCaptureView, DataCapture, props => ({
  visitId: parseInt(props.match.params.visitId),
}));

export default connect(mapStateToProps)(
  withTranslation('translations')(DataCaptureComponent)
);
