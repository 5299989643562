import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DocumentHeaderCount = ({
  current,
  howManyDocuments,
}: {
  current: number;
  howManyDocuments: number;
}) => {
  const { t } = useTranslation('translations');

  return (
    <Typography className='ic-header-documents'>
      {t('signDocuments.documentHeaderCounter', {
        current: current,
        numDocuments: howManyDocuments,
      })}
    </Typography>
  );
};
