import { Redirect } from 'react-router-dom';
import { CustomRouteProps } from './PrivateRoute';
import { Route } from 'react-router-dom';

const PublicRoute = (props: CustomRouteProps) => {
  const { component: Component, render, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props => {
        if (Component) return <Component {...props} />;
        if (render) return render(props);
        console.error(`PublicRoute: missing component and render props`);
        return <Redirect to='/' />;
      }}
    />
  );
};

export default PublicRoute;
