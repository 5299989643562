import { useTranslation } from 'react-i18next';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { LuxonFromAnything } from '@curebase/core/lib/dates';
import { getLocaleFormatDates } from '../../context/localeContext';

const localeFormat = getLocaleFormatDates();
const dateFormat = localeFormat.luxon.dateFormat;

const FormResultsMedicationQuestion = ({ isOptingOut, medications }) => {
  const { t } = useTranslation('translations');

  if (medications.length <= 0 || isOptingOut) {
    return (
      <span>{t('dataCaptureResultsView.medication.noMedicationReported')}</span>
    );
  }

  return (
    <Paper>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.otherMedication')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.medicationName')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.route')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.dosage')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.dates')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.indication')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='subtitle1'>
                {t('dataCaptureResultsView.medication.frequency')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {medications.map(medication => {
            // Account for if the dates have not yet been filled out
            const [startAt, endAt] = medication.dates ?? [null, null];
            const formattedStarAt = startAt
              ? LuxonFromAnything(startAt).toFormat(dateFormat)
              : '--';
            const getFormattedEndAt = end => {
              if (!end) {
                return '--';
              }
              if (end === 'present') {
                return t('dataCaptureResultsView.medication.currentlyTaking');
              }
              return LuxonFromAnything(end).toFormat(dateFormat);
            };
            const formattedEndAt = getFormattedEndAt(endAt);

            return (
              <TableRow key={medication.medication}>
                <TableCell component='th' scope='row'>
                  {!!medication.other
                    ? t('dataCaptureResultsView.medication.yes')
                    : t('dataCaptureResultsView.medication.no')}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {medication.medication}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {medication.route}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {medication.dosageStr}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {formattedStarAt} - {formattedEndAt}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {medication.indication ? medication.indication : '--'}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {medication.frequency ? medication.frequency : '--'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FormResultsMedicationQuestion;
