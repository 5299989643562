import { DateTime, Settings } from 'luxon';
import { DEPLOYED_REGION } from '../lib/env';
import React from 'react';
import fr from 'react-phone-number-input/locale/fr.json';
import es from 'react-phone-number-input/locale/es.json';
import en from 'react-phone-number-input/locale/en.json';

const PHONE_INPUT_COUNTRY_NAMES = {
  fr,
  es,
  en,
};
const AVAILABLE_LOCALES = ['en-us', 'fr-fr', 'es-us'];

const formatLocale = (str: string) => {
  const [language = 'en', country = 'us'] = str?.split('-') || [];
  return {
    language,
    country,
    locale: `${language}-${country}`,
  };
};

const getLocaleFromEnvironment = () => formatLocale(DEPLOYED_REGION);

const getLocaleFromStorage = () => {
  let locale: any = window.localStorage.getItem('.l');
  if (locale && AVAILABLE_LOCALES.includes(locale)) {
    return formatLocale(locale);
  }
};

const getLocaleFromURL = () => {
  const url = new URL(window.location.href);
  const locale = url.searchParams.get('.l');
  if (locale && AVAILABLE_LOCALES.includes(locale)) {
    window.localStorage.setItem('.l', locale);
    url.searchParams.delete('.l');
    window.history.replaceState({}, '', url.href);
    return formatLocale(locale);
  }
};

export const setLocaleInStorage = locale => {
  let localeToSet: any = window.localStorage.getItem('.l');
  if (localeToSet !== locale || !localeToSet) {
    window.localStorage.setItem('.l', locale);
  }
};
/**
 * This method returns the locale information, using the next algorithm
 * 1 - Checks if the current locale information comes from the query param .l
 * Then sets in the localStorage this locale information and returns the
 * locale structure.
 * 2 - Checks if the locale information is in the localStorage. If exists in the locale storage
 * that means the language set is the one stored in there. The only method that saves the local
 * is the URL method.
 * 3 - If the url and storage does not contain locale information, then the application will take the
 * locale information from the environment.
 * @returns object with locale information
 */
export const getLocale = () => {
  return (
    getLocaleFromURL() || getLocaleFromStorage() || getLocaleFromEnvironment()
  );
};

export const LocaleContext = React.createContext({
  locales: [getLocale()],
});

/**
 * This is only for the moment, in the future this should be dynamic
 * and in concordance with business, since there are similar formats
 * separated by commas or other element
 */
const FORMAT_DATES_BY_COUNTRY = {
  us: {
    luxon: {
      longDate: 'MMMM d, yyyy',
      shortTimeMeridiem: 'h:mm a',
      dateFormat: 'MM/dd/yyyy',
      schedulerSlot: 'ccc, MMMM dd, yyyy',
      longTime: 'hh:mm ZZZZ',
      shortTime: 'h:mma',
      scheduleDate: 'MMMM dd, yyyy',
      dataCaptureList: 'EEEE, MMMM d, yyyy h:mm a',
      dataCaptureResults: 'MMMM d, yyyy h:mm a ZZZZ',
      trialOptionCRF: 'MMMM d, yyyy',
      queries: 'MMMM d, yyyy',
      bookingFormat: 'MMM dd, yyyy, h:mm a ZZZZ',
    },
    moment: {
      dateFormat: 'MMMM Do, YYYY',
      dateFormatInformal: 'MMMM D, YYYY',
      dateFormatShortDay: 'D MMMM YYYY',
      defaultFormat: 'YYYY-MM-DD',
      shortTimeMeridiem: 'h:mm A',
      longDateTimeFormat: 'YYYY-MM-DD h:mm a z',
      shortdateFormat: 'MMM D, YYYY',
      longFormalDateTime: 'dddd, MMMM Do YYYY',
      monthYearFormat: 'YYYY-MM',
      shortDate: 'dddd, MMMM D',
      timeWithZone: 'h:mma z',
      shortTime: 'h:mma',
      longTime: 'hh:mma',
      longTimeMeridiem: 'hh:mm a',
      longTimeMeridiemZone: 'hh:mm a (z)',
      paymentFormat: 'MMM Do YYYY',
      reportedProtocol: 'YYYY-MM-DD HH:mm',
      eventDateFormat: 'MMM DD, YYYY',
      editAvailabilityStartFormat: 'D MMMM YYYY',
      editAvailabilityEndFormat: 'Do MMMM YYYY',
      availabilityFormat: 'dddd, MMMM Do',
      bookingFormat: 'MMM DD, YYYY, h:mm A zz',
    },
  },
  fr: {
    luxon: {
      longDate: 'd MMMM, yyyy',
      shortTimeMeridiem: 'HH:mm',
      dateFormat: 'dd/MM/yy',
      schedulerSlot: 'cccc dd MMMM yyyy',
      longTime: 'hh:mm ZZZZ',
      shortTime: 'HH:mm',
      scheduleDate: 'dd MMMM, yyyy',
      dataCaptureList: 'EEEE dd MMMM yyyy, HH:mm',
      dataCaptureResults: 'd MMMM, yyyy HH:mm ZZZZ',
      trialOptionCRF: 'd MMMM, yyyy',
      queries: 'd MMMM, yyyy',
      bookingFormat: 'MMM dd, yyyy, h:mm a ZZZZ',
    },
    moment: {
      dateFormat: 'DD MMMM YYYY',
      dateFormatInformal: 'DD MMMM YYYY',
      dateFormatShortDay: 'D MMMM YYYY',
      defaultFormat: 'YYYY-MM-DD',
      shortTimeMeridiem: 'HH:mm',
      longDateTimeFormat: 'DD/MM/YYYY HH:mm z',
      bookingFormat: 'MMM DD, YYYY, HH:mm z',
      shortdateFormat: 'D MMM YYYY',
      longFormalDateTime: 'dddd D MMMM YYYY',
      monthYearFormat: 'YYYY-MM',
      timeWithZone: 'HH:mm z',
      shortDate: 'dddd, MMMM D',
      shortTime: 'HH:mm',
      longTime: 'HH:mm',
      longTimeMeridiem: 'HH:mm',
      longTimeMeridiemZone: 'HH:mm a (z)',
      paymentFormat: 'D MMM YYYY',
      reportedProtocol: 'YYYY-MM-DD HH:mm',
      eventDateFormat: 'MMM DD, YYYY',
      editAvailabilityStartFormat: 'D MMMM YYYY',
      editAvailabilityEndFormat: 'Do MMMM YYYY',
      availabilityFormat: 'dddd, MMMM Do',
    },
  },
};

//TODO: This will be implemented in future iterations
const createFormatDate = (country = 'us') => {
  return (value, format) => {
    let valueToParse: DateTime | null = null;
    if (typeof value === 'string') {
      valueToParse = DateTime.fromISO(value);
    } else if (value instanceof Date) {
      valueToParse = DateTime.fromJSDate(value);
    } else if (value && value.isMoment && value.isMoment()) {
      valueToParse = DateTime.fromISO(value.formatISO());
    } else if (typeof value === 'number') {
      valueToParse = DateTime.fromMillis(value);
    }

    return valueToParse?.setLocale(country).toLocaleString();
  };
};

//TODO: This will be implemented in future iterations
const createFormatTime = (country = 'us') => {
  return null;
};

const CURRENCY_FORMAT = {
  us: {
    name: 'USD',
    symbol: '$',
  },
  fr: {
    name: 'EUR',
    symbol: '€',
  },
};

//TODO: This will be implemented in future iterations
const createFormatCurrency = (country = 'us') => {
  return (value: number) => `${CURRENCY_FORMAT[country].symbol}${value}`;
};

const getFormatDatesByCountry = (country = 'us') =>
  FORMAT_DATES_BY_COUNTRY[country];

export const getLocaleFormatDates = () =>
  FORMAT_DATES_BY_COUNTRY[getLocale()?.country];

//TODO: This will be implemented in future iterations
export const useLocale = () => {
  const { locales = [] } = React.useContext(LocaleContext);
  const [locale]: any = locales;
  Settings.defaultLocale = locale?.country || 'us';

  return {
    formatDate: createFormatDate(locale?.country),
    formatTime: createFormatTime(locale?.country),
    formatCurrency: createFormatCurrency(locale?.country),
    FORMAT_DATE_TIME: getFormatDatesByCountry(locale?.country),
  };
};

export const getPhoneInputLocales = () =>
  PHONE_INPUT_COUNTRY_NAMES[getLocale()?.language] ??
  PHONE_INPUT_COUNTRY_NAMES.en;

export const getBrowserRegion = () => {
  const currentTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  const [region = 'America'] = currentTimeZone?.split('/');
  return region;
};
