import { StudyActivity } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';
import ActionListItem from '../../basic/ActionListItem';

interface PlutoActionListItemProps {
  activity: StudyActivity;
}

function PlutoActionListItem(_: PlutoActionListItemProps) {
  const { t } = useTranslation('translations');
  return (
    <ActionListItem
      title={t('participants.pluto.taskCard.title')}
      description={t('participants.pluto.taskCard.description')}
      iconSrc={`/icons/pluto.svg`}
    />
  );
}

export default PlutoActionListItem;
