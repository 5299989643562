import moment from 'moment-timezone';
import React from 'react';

import { withQueryResult } from '../hocs/WithQueryResult';

import {
  Accordion as ExpansionPanel,
  AccordionDetails as ExpansionPanelDetails,
  AccordionSummary as ExpansionPanelSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import BoldList from '../basic/BoldList';

import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import { getReadableValueFromCapturedData } from '../../lib/caseReports';
import { capitalizeDisplayName } from '../../lib/ui';

import {
  TrialOptionCrfDataEdits,
  TrialOptionCrfDataEditsQuery,
  TrialOptionCrfDataEditsQueryVariables,
} from '@curebase/core/types';
import { TFunction, useTranslation } from 'react-i18next';
import { getLocaleFormatDates } from '../../context/localeContext';

type Props = {
  trialOptionId: number;
  queryResult: TrialOptionCrfDataEditsQuery;
};

const localesFormat = getLocaleFormatDates();

const EDIT = 'EDIT';
const QUERY_OPENED = 'QUERY_OPENED';
const QUERY_CLOSED = 'QUERY_CLOSED';

const DATE_FORMAT_FULL_USER_TIMEZONE = localesFormat.moment.longDateTimeFormat;

const VIEW_USER_TIMEZONE = moment.tz.guess();

type CaptureDataChanges = Pick<
  TrialOptionCrfDataEditsQuery,
  'getCapturedDataChanges'
>;

type CaptureDataChange = any;

const makeSingleRowsHistory = (capturedDataChanges: CaptureDataChanges) => {
  const {
    dataField,
    value,
    editReason,
    authoredByUser,
    submittedOn,
  } = capturedDataChanges[0].capturedData;

  // value, author, submittedOn->at
  // @ts-ignore
  let edits = capturedDataChanges.map(
    ({ value, authorUser, submittedOn, editReason, capturedData }) => ({
      value: getReadableValueFromCapturedData(dataField, value),
      author: authorUser,
      eventTime: submittedOn,
      editReason,
      type: EDIT,
      dataFieldName: dataField?.name,
      isHiddenFromUser: capturedData?.dataField?.isHiddenFromUser,
    })
  );
  // if there are no changes, then dont add the current value
  if (edits.length > 0) {
    edits.push({
      value: getReadableValueFromCapturedData(dataField, value),
      editReason,
      author: authoredByUser,
      eventTime: submittedOn,
      type: EDIT,
      dataFieldName: dataField?.name,
      isHiddenFromUser: dataField?.isHiddenFromUser,
    });
  }

  // oldest first
  edits.sort((a, b) => (a.eventTime > b.eventTime ? 1 : -1));
  // set the prior value
  edits = edits.map((m, i) => {
    if (i > 0) {
      m.priorValue = edits[i - 1].value;
    }
    return m;
  });

  // remove the initial data submission
  edits.shift();
  return edits;
};

const makeChangeList = (rawChanges: ReadonlyArray<CaptureDataChange>) => {
  const capturedDataRows: { [id: number]: CaptureDataChanges } = {};

  rawChanges.forEach(change => {
    const { capturedDataId } = change;
    if (capturedDataRows[capturedDataId]) {
      // @ts-ignore
      capturedDataRows[capturedDataId].push(change);
    } else {
      // @ts-ignore
      capturedDataRows[capturedDataId] = [change];
    }
  });

  return Object.values(capturedDataRows).flatMap(makeSingleRowsHistory);
};

// add an element for each query opened and each query closed.
const makeQueryList = rawQueries =>
  [].concat(
    rawQueries.map(q =>
      Object.assign({}, q, { type: QUERY_OPENED, eventTime: q.createdAt })
    ),
    rawQueries
      .filter(q => q.resolvedOn)
      .map(q =>
        Object.assign({}, q, { type: QUERY_CLOSED, eventTime: q.resolvedOn })
      )
  );

type DataSectionProps = {
  title: string;
  time?: string;
  content?: any;
  isHiddenField?: boolean;
};

const DataSectionWrapper = ({
  title,
  time,
  content,
  isHiddenField,
}: DataSectionProps) => {
  const { t } = useTranslation('translations');
  return (
    <React.Fragment>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={content && <ExpandMoreIcon />}>
          <div className='expansion-summary-content'>
            {time && <span className='summary-time'>{time}</span>}
            <span className='summary-title'>{title}</span>
          </div>
        </ExpansionPanelSummary>

        {content && (
          <ExpansionPanelDetails>
            <div className='expansion-details-content expansion-inner-content'>
              {isHiddenField && (
                <div className='data-message'>
                  {t('trialOptionCRFDataEdits.hiddenData')}
                </div>
              )}
              {content}
            </div>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    </React.Fragment>
  );
};

const getTitleForSection = (
  section: Section | any,
  t: TFunction<'translations'>
) => {
  const { type } = section;
  if (type === QUERY_CLOSED) {
    return `${t(
      'trialOptionCRFDataEdits.titleForSection.qResolvedBy'
    )} ${capitalizeDisplayName(section.resolvedByUser, t)}`;
  }
  if (type === QUERY_OPENED) {
    return `${t(
      'trialOptionCRFDataEdits.titleForSection.qIssuedBy'
    )} ${capitalizeDisplayName(section.submittedByUser, t)}`;
  }
  if (type === EDIT) {
    return `${t(
      'trialOptionCRFDataEdits.titleForSection.editMadeBy'
    )} ${capitalizeDisplayName(section.author, t)}`;
  }
  return '';
};

type Section = {
  type: 'EDIT' | 'QUERY_OPENED' | 'QUERY_CLOSED';
  eventTime: any;
  isHiddenFromUser?: boolean;
};

type QuerySection = {
  type: 'QUERY_OPENED' | 'QUERY_CLOSED';
  eventTime: any;
  capturedData: any;
  initialMessage: string;
  submittedByUser: any;
  resolvedByUser?: any;
  submittedAt: any;
  resolvedOn?: any;
};

const getQuerySectionContent = (
  section: QuerySection,
  t: TFunction<'translations'>
) => {
  const {
    type,
    capturedData,
    initialMessage,
    submittedByUser,
    resolvedByUser,
    submittedAt,
    resolvedOn,
  } = section;
  const { caseReportInstance } = capturedData;
  const { isHiddenFromUser = false } = section?.capturedData?.dataField || {};
  // Fields that should be hidden in the UI if this datafield is hidden from this user
  const dataList = isHiddenFromUser
    ? []
    : [
        {
          title: t('trialOptionCRFDataEdits.sectionContent.dataField'),
          value: capturedData.dataField.name,
        },
        {
          title: t('trialOptionCRFDataEdits.sectionContent.query'),
          value: initialMessage,
        },
      ];
  const list = [
    {
      title: t('trialOptionCRFDataEdits.sectionContent.caseReport'),
      value: caseReportInstance.visit.studyPlanVisit.summary.title,
    },
    {
      title: t('trialOptionCRFDataEdits.sectionContent.reportSubmitted'),
      value: moment(caseReportInstance.visit.submittedAt)
        .tz(VIEW_USER_TIMEZONE)
        .format(DATE_FORMAT_FULL_USER_TIMEZONE),
    },
    ...dataList,
    {
      title: t('trialOptionCRFDataEdits.sectionContent.issuedBy'),
      value: capitalizeDisplayName(submittedByUser, t),
    },
    {
      title: t('trialOptionCRFDataEdits.sectionContent.issuedOn'),
      value: moment(submittedAt)
        .tz(VIEW_USER_TIMEZONE)
        .format(DATE_FORMAT_FULL_USER_TIMEZONE),
    },
  ];

  if (type === QUERY_CLOSED) {
    list.push(
      {
        title: t('trialOptionCRFDataEdits.sectionContent.resolvedAt'),
        value: capitalizeDisplayName(resolvedByUser, t),
      },
      {
        title: t('trialOptionCRFDataEdits.sectionContent.resolvedOn'),
        value: moment(resolvedOn)
          .tz(VIEW_USER_TIMEZONE)
          .format(DATE_FORMAT_FULL_USER_TIMEZONE),
      }
    );
  }

  return <BoldList list={list} />;
};

type EditSection = {
  type: 'EDIT';
  eventTime: any;
  author: any;
  editReason: string;
  priorValue: any;
  value: any;
  dataFieldName: string;
  isHiddenFromUser?: boolean;
};

const fileListToLinks = fileList =>
  fileList?.map(f => (
    // eslint-disable-next-line
    <a key={f.path} href={secureImageUriFromFileName(f.path)} target='_blank'>
      {f.name}{' '}
    </a>
  ));

const mapValue = value =>
  value && value[0]?.path ? fileListToLinks(value) : value;

const getEditSectionContent = (
  section: EditSection,
  t: TFunction<'translations'>
) => {
  const { isHiddenFromUser = false } = section || {};
  const dataList = isHiddenFromUser
    ? []
    : [
        {
          title: t('trialOptionCRFDataEdits.editSectionContent.question'),
          value: section.dataFieldName,
        },
        {
          title: t('trialOptionCRFDataEdits.editSectionContent.priorValue'),
          value: mapValue(section.priorValue),
        },
        {
          title: t('trialOptionCRFDataEdits.editSectionContent.newValue'),
          value: mapValue(section.value),
        },
        {
          title: t('trialOptionCRFDataEdits.editSectionContent.editReason'),
          value: section.editReason,
        },
      ];
  const list = [
    {
      title: t('trialOptionCRFDataEdits.editSectionContent.editDate'),
      value: moment(section.eventTime)
        .tz(VIEW_USER_TIMEZONE)
        .format(DATE_FORMAT_FULL_USER_TIMEZONE),
    },
    ...dataList,
  ];
  return <BoldList list={list} />;
};

const NoQueriesEditsPlaceHolder = () => {
  const { t } = useTranslation('translations');
  return (
    <DataSectionWrapper
      title={t('trialOptionCRFDataEdits.noQueryEditsTitle')}
    />
  );
};

const ExpansionPanelList = ({ sections }) => {
  const { t } = useTranslation('translations');
  if ((sections.length ?? 0) <= 0) {
    return <NoQueriesEditsPlaceHolder />;
  }
  // Most recent first
  sections.sort((a, b) => (a.eventTime < b.eventTime ? 1 : -1));

  return sections.map((section: Section | QuerySection, index: number) => {
    const { type, eventTime } = section;

    let content;
    let isHiddenField = false;
    if (type === EDIT) {
      // @ts-ignore
      content = getEditSectionContent(section, t);
      isHiddenField = Boolean(section.isHiddenFromUser);
      // @ts-ignore
    } else if (section.capturedData) {
      // @ts-ignore
      content = getQuerySectionContent(section, t);
      // @ts-ignore
      isHiddenField = section.capturedData?.dataField?.isHiddenFromUser;
    }

    return (
      <DataSectionWrapper
        time={moment(eventTime).format(localesFormat.moment.shortdateFormat)}
        title={getTitleForSection(section, t)}
        key={index}
        content={content}
        isHiddenField={isHiddenField}
      />
    );
  });
};

const TrialOptionCRFDataEditsView = (props: Props) => {
  const { queryResult } = props;
  if (!queryResult) return null;

  const { getCapturedDataChanges, getCapturedDataQueries } = queryResult;

  let edits: any = [];
  let queries = [];

  if (getCapturedDataChanges) {
    edits = makeChangeList(getCapturedDataChanges || []);
  }
  if (getCapturedDataQueries) {
    queries = makeQueryList(getCapturedDataQueries?.rows || []);
  }

  const allSections = [].concat(edits, queries);
  return (
    <div>
      <ExpansionPanelList sections={allSections} />
    </div>
  );
};

const TrialOptionCRFDataEdits: React.ComponentType<{
  trialOptionId: number;
}> = withQueryResult(
  TrialOptionCRFDataEditsView,
  TrialOptionCrfDataEdits,
  (props: any): TrialOptionCrfDataEditsQueryVariables => ({
    trialOptionId: props.trialOptionId,
  })
);

export default TrialOptionCRFDataEdits;
