import { Signature } from '@curebase/core/types';
import { DateTime } from 'luxon';
import React from 'react';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';

export const SignatureView = (props: { signature: Signature }) => {
  const {
    signature: { legalName, file, createdAt, uuid, reason },
  } = props;

  const fileName = `Files/${file?.id}/${file?.name}`;
  const imageURI = secureImageUriFromFileName(fileName);

  return (
    <div className='sig-content-container' style={{ marginBottom: 40 }}>
      <div className='sig-shield'>
        <svg
          preserveAspectRatio='none'
          width='35px'
          viewBox='0 0 24 24'
          height='35px'
          xmlns='http://www.w3.org/2000/svg'
          fill='#2a6d89'
        >
          <path d='M11.19 1.36l-7 3.11C3.47 4.79 3 5.51 3 6.3V11c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6.3c0-.79-.47-1.51-1.19-1.83l-7-3.11c-.51-.23-1.11-.23-1.62 0zM12 11.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z'></path>
        </svg>
      </div>
      {/* Below empty div has fancy css, do not delete */}
      <div className='sig-decorator' />
      <div className='signature-primary-contents'>
        <div className='signature-block signature-text'>
          <div>Electronically signed by:</div>
          <div className='signature'>
            <img className='sig-image' alt='' src={imageURI} />
          </div>
          <div>{`Signed Name: ${legalName}`}</div>
          {reason && <div>{reason}</div>}
          <div>{`Signing Time: ${DateTime.fromISO(createdAt).toFormat(
            'yyyy-MM-dd HH:mm:ss'
          )} (UTC)`}</div>
        </div>
        <div className='signature-uuid'>{uuid.toUpperCase()}</div>
      </div>
    </div>
  );
};

export default SignatureView;
