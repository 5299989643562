function forExport(showdown: any) {
  const variableRegex = /\{\{(.+?)\}\}/g;
  showdown.extension('variable', function () {
    return [
      {
        type: 'lang',
        filter: function (text: string, converter: any, options: any) {
          return text.replace(variableRegex, function (match, variableName) {
            const value = options.knowledgeContentVariables[variableName];
            if (!value && !['', 0].includes(value))
              throw new Error(
                `Markdown conversion error, missing variable: ${variableName}`
              );
            return value;
          });
        },
      },
    ];
  });
}

export default forExport;
