import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import {
  useForceTransitionParticipantMutation,
  useGetTrialStatesQuery,
} from '../../types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import { showAlertMessage } from 'src/store/actions';
import { StatusColor } from '../../shared/lib/colors';
import usePermission from 'src/hooks/usePermission';
import { RolePermissions } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';

interface Props {
  trialOptionId: number;
  trialConfigId: string;
  updateParent: () => void;
}

function ForceTransitionParticipant({
  trialOptionId,
  trialConfigId,
  updateParent,
}: Props) {
  const { t } = useTranslation('translations');
  const [targetState, setTargetState] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [mutation, { loading }] = useForceTransitionParticipantMutation();
  const hasPermission = usePermission(
    RolePermissions.ForceTransitionParticipant
  );
  const { data } = useGetTrialStatesQuery({
    variables: {
      trialConfigId,
    },
  });
  const targetStates = data?.getTrialStates || [];
  const shouldDisableConfirm = !(reason && targetState) || loading;

  const handleConfirm = async () => {
    try {
      await mutation({
        variables: {
          trialOptionId,
          reason,
          targetState,
        },
      });
      showAlertMessage(
        t('trialOption.edit.forceTransitionParticipant.successMessage'),
        StatusColor.Green
      );
      updateParent();
    } catch (e) {
      showAlertMessage(e.message, StatusColor.Red);
    } finally {
      setShowDialog(false);
    }
  };

  useEffect(() => {
    if (!showDialog) {
      setReason('');
      setTargetState('');
    }
  }, [showDialog]);

  if (!hasPermission) return null;

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        style={{ margin: 26 }}
        onClick={() => setShowDialog(true)}
      >
        {t('trialOption.edit.forceTransitionParticipant.buttonText')}
      </Button>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <ConfirmationInDialog
          title={t('trialOption.edit.forceTransitionParticipant.title')}
          message=''
          disableOnConfirm={shouldDisableConfirm}
          onConfirm={handleConfirm}
          onClose={() => setShowDialog(false)}
          confirmationText={t(
            'trialOption.edit.forceTransitionParticipant.buttonSaveText'
          )}
        >
          <Box width={500} padding={2}>
            <Box marginBottom={3}>
              <TextField
                fullWidth
                label={t(
                  'trialOption.edit.forceTransitionParticipant.reason.label'
                )}
                variant='outlined'
                value={reason}
                onChange={event => setReason(event.target.value)}
              />
            </Box>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id='target-state-label'>
                {t(
                  'trialOption.edit.forceTransitionParticipant.targetState.label'
                )}
              </InputLabel>
              <Select
                variant='outlined'
                label={t(
                  'trialOption.edit.forceTransitionParticipant.targetState.label'
                )}
                labelId='target-state-label'
                value={targetState}
                onChange={event => setTargetState(event.target.value as string)}
              >
                {targetStates.map(targetState => (
                  <MenuItem key={targetState} value={targetState as string}>
                    {targetState}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{t('')}</FormHelperText>
            </FormControl>
          </Box>
        </ConfirmationInDialog>
      </Dialog>
    </>
  );
}

export default ForceTransitionParticipant;
