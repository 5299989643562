import ConfigurationsView from '../ConfigurationsView';
import { useClinicConfigurationsQuery } from 'src/types';
import Loading from '../Loading';

interface ClinicConfigurationsProps {
  clinicId: string;
}

const ClinicConfigurations = (props: ClinicConfigurationsProps) => {
  const { clinicId } = props;

  const { data, loading } = useClinicConfigurationsQuery({
    variables: { id: clinicId },
  });

  if (!data || loading) {
    return <Loading />;
  }

  return (
    <ConfigurationsView configs={data.getSiteManagementConfigsForClinic} />
  );
};

export default ClinicConfigurations;
