import { isOptedOut, isWithdrawn } from '@curebase/core/lib/studyPlan';
import { Pii_Status } from '@curebase/core/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import {
  optoutPatient,
  updateTrialOptionState,
} from '../../controllers/trialOptionController';

import { getUser as getUserSelector } from '../../store/user/selectors';
import { useTrialOptionDetailsQuery } from '../../types';
import PatientDetailsEditor from '../basic/PatientDetailsEditor/PatientDetailsEditor';

import Subheader from '../basic/Subheader';
import UserDetailsEditor from '../basic/UserDetailsEditor/UserDetailsEditor';
import ActionConfirmDialog from './ActionConfirmDialog';
import ForceTransitionParticipant from './ForceTransitionParticipant';
import InviteUser from './InviteUser';
import { ParticipantBinderContainer } from './ParticipantBinderContainer';
import TrialOptionDetailsEditor from './TrialOptionDetailsEditor';
import WithdrawPatient from './WithdrawPatient';

interface Props {
  trialOptionId: number;
  trialId: number;
}

function TrialOptionDetails(props: Props) {
  const { t } = useTranslation('translations');
  const {
    data,
    refetch: refetchGetTrialOptionDetails,
  } = useTrialOptionDetailsQuery({
    variables: {
      trialOptionId: props.trialOptionId,
    },
  });

  const user = useSelector(getUserSelector);

  // @FIXME: not sure if this is right
  if (!data) return null;

  const trialOption = data.getTrialOption;
  const userIsManaged = trialOption.patient?.user.isStub;
  const userIsEnrolled = !!trialOption.enrollmentDate;
  const canOptOut = !userIsEnrolled && !isOptedOut(trialOption.status);
  const canWithdraw = userIsEnrolled && !isWithdrawn(trialOption.status);
  const canCollectPII = trialOption.piiStatus === Pii_Status.Enabled;
  const availableLocales = trialOption?.trial?.locales;

  const canForceUpdateTrialOptionState = user?.email?.includes('@curebase.com');

  const onOptedOut = async () => {
    const trialOption = data.getTrialOption;
    await optoutPatient(trialOption.id);
    refetchGetTrialOptionDetails();
  };

  const onForceUpdateTrialOption = async () => {
    const trialOption = data.getTrialOption;
    await updateTrialOptionState(trialOption.id);
    refetchGetTrialOptionDetails();
  };

  return (
    <>
      <ParticipantBinderContainer
        trialOptionId={props.trialOptionId}
        refetchGetTrialOptionDetails={refetchGetTrialOptionDetails}
        participantBinder={data?.getParticipantBinder}
      />
      {canCollectPII && (
        <>
          {userIsManaged && (
            <>
              <Subheader text={t('trialOptionDetails.subheader')} />
              <InviteUser
                trialOption={trialOption}
                refetch={refetchGetTrialOptionDetails}
              />
            </>
          )}

          <UserDetailsEditor
            userId={trialOption.patient.user.id}
            updateParent={() => refetchGetTrialOptionDetails()}
            trialOptionId={trialOption.id}
          />
          <PatientDetailsEditor
            patientId={trialOption.patient.id}
            updateParent={() => refetchGetTrialOptionDetails()}
            trialOptionId={trialOption.id}
          />
        </>
      )}
      <TrialOptionDetailsEditor
        trialOptionId={trialOption.id}
        trialId={trialOption.trialId}
        availableLocales={availableLocales}
      />
      <ForceTransitionParticipant
        trialOptionId={trialOption.id}
        trialConfigId={trialOption.trialConfigId}
        updateParent={() => refetchGetTrialOptionDetails()}
      />

      {canWithdraw && (
        <WithdrawPatient
          trialOption={trialOption}
          refetch={refetchGetTrialOptionDetails}
        />
      )}

      {canOptOut && (
        <ActionConfirmDialog
          title={t('trialOptionDetails.optOut.title')}
          message={t('trialOptionDetails.optOut.message')}
          confirmAction={onOptedOut}
        />
      )}

      {!!canForceUpdateTrialOptionState && (
        <ActionConfirmDialog
          title={t('trialOptionDetails.updateTrial.title')}
          message={t('trialOptionDetails.updateTrial.message')}
          confirmAction={onForceUpdateTrialOption}
        />
      )}
    </>
  );
}

export default TrialOptionDetails;
