import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { VisibiltyStatus } from './AudioVideo';
import Loading from '../Loading';

type Props = {
  videoRef?: any;
  isVisible: VisibiltyStatus;
  videoElementRef?: (instance: HTMLVideoElement | null) => void;
  fullWindowHeight?: boolean;
};

const visibilityTranslator = (isVisible: VisibiltyStatus) => {
  if (isVisible === VisibiltyStatus.CollapseBlock)
    return { window: 'collapse', video: 'collapse', img: 'collapse' };
  if (isVisible === VisibiltyStatus.ShowImage)
    return { window: '', video: 'collapse', img: '' };

  return {
    window: '',
    video: isVisible === VisibiltyStatus.ShowVideo ? '' : 'collapse',
    img: 'collapse',
  };
};

const VideoElement = React.memo((props: Props) => {
  const { isVisible, videoElementRef, videoRef, fullWindowHeight } = props;
  const { window, video, img } = visibilityTranslator(isVisible);
  return (
    <div
      className={`video-tile ${window} ${
        fullWindowHeight ? 'large-video' : ''
      }`}
    >
      <video
        className={`video-tile-video ${video} `}
        ref={videoRef ?? videoElementRef}
      />
      {isVisible === VisibiltyStatus.ShowLoading && (
        <div className='video-tile-video'>
          <Loading />
        </div>
      )}
      <AccountCircleIcon
        className={`video-tile-icon ${img}`}
        fontSize='inherit'
        htmlColor='white'
      />
    </div>
  );
});

export default VideoElement;
