import * as React from 'react';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

type Props = {
  dropdownObjects: Object[];
  dropdownTitle: string;
  currentlySelectedName: string;
  classes: any;
  onClose: (objectId?: number) => void;
};

type State = {
  menuAnchor?: any;
};

const styles = theme => ({
  materialButton: {
    color: 'white',
  },
});

class Dropdown extends React.Component<Props, State> {
  state = {
    menuAnchor: null,
  };

  showSelectMenu = e => {
    this.setState({ menuAnchor: e.currentTarget });
  };

  onClose = (objectId?: number) => {
    this.setState({ menuAnchor: null }, () => {
      this.props.onClose(objectId);
    });
  };

  render() {
    const {
      dropdownObjects,
      dropdownTitle,
      currentlySelectedName,
      classes,
    } = this.props;
    const { menuAnchor } = this.state;

    return (
      <>
        <div className='dropdown-container'>
          <div className='dropdown-title'>{dropdownTitle}</div>
          <div className='dropdown-row'>
            <div
              className='dropdown-name'
              onClick={e => this.showSelectMenu(e)}
            >
              {currentlySelectedName}
            </div>
            <div>
              <IconButton
                onClick={e => this.showSelectMenu(e)}
                className={classes.materialButton}
              >
                <MoreHorizIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => this.onClose()}
        >
          {dropdownObjects.map((c: any, i) => (
            <MenuItem key={i} value={c.id} onClick={() => this.onClose(c.id)}>
              {c.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(Dropdown);
