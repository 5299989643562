import type { Action } from '../actions';

type TFullStoryState = {
  isEnabled: boolean;
};

const defaultFullStoryState = {
  isEnabled: false,
};

export default function fullStory(
  state: TFullStoryState = defaultFullStoryState,
  action: Action
): TFullStoryState {
  switch (action.type) {
    case 'SET_IS_FULL_STORY_ENABLED':
      return { isEnabled: action.isEnabled };
    default:
      return state;
  }
}
