import {
  TrialOptionFilter,
  TrialOptionSortingOrder,
} from '@curebase/core/types';
import produce from 'immer';
import {
  CHANGE_PARTICIPANT_PICKER_FILTERS,
  CHANGE_PARTICIPANT_PICKER_SORTING_ORDER,
  CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER,
  CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER,
  CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER,
  CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER,
  ParticipantPickerActionTypes,
  ParticipantPickerState,
} from './types';

export const initialState: ParticipantPickerState = {
  filter: TrialOptionFilter.All,
  sortingOrder: TrialOptionSortingOrder.Asc,
  searchQuery: '',
  trialIds: [],
  statuses: [],
  epochs: [],
};

const participantPickerReducer = produce(
  (state: ParticipantPickerState, action: ParticipantPickerActionTypes) => {
    switch (action.type) {
      case CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER:
        state.trialIds = action.payload.trialIds;
        break;
      case CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER:
        state.searchQuery = action.payload.searchQuery?.toString().trim() ?? '';
        break;
      case CHANGE_PARTICIPANT_PICKER_FILTERS:
        state.filter = action.payload.filter;
        break;
      case CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER:
        state.statuses = action.payload.statuses;
        break;
      case CHANGE_PARTICIPANT_PICKER_SORTING_ORDER:
        state.sortingOrder = action.payload.sortingOrder;
        break;
      case CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER:
        state.epochs = action.payload.epochs;
        break;
      default:
    }
  },
  initialState
);

export default participantPickerReducer;
