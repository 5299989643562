import { useUserAnalyticsIdentityQuery } from '@curebase/web/src/types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../store/selectors/index';
import Analytics from './Analytics';
import { useHistory, useLocation } from 'react-router-dom';
import { getRolesHash, isInternalUser, isParticipant } from './tools';
import { UserRole } from '../../types';

const AppAnalytics = () => {
  const user = useSelector(getUserSelector);
  const { data } = useUserAnalyticsIdentityQuery({
    skip: !user?.userId,
    variables: {
      userId: user.userId,
    },
  });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    Analytics.load();
  }, []);

  useEffect(() => {
    if (data?.getUser && user?.email) {
      Analytics.identify({
        roles: getRolesHash(data.getUser.roles as UserRole[]),
        uuid: data.getUser.uuid,
        is_participant: isParticipant(data.getUser.roles as UserRole[]),
        is_internal_user: isInternalUser(user?.email as string),
        account_creation_date: data.getUser.createdAt,
      });
    }
  }, [data?.getUser, user?.email]);

  useEffect(() => {
    return history.listen((next, action) => {
      if (location.pathname !== next.pathname) {
        Analytics.page();
      }
    });
  }, [location, history]);

  return null;
};

export default AppAnalytics;
