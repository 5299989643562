import _capitalize from 'lodash/capitalize';
import {
  PatientIntegrationSourceDataType,
  StudyActivity,
} from '@curebase/core/types';
import { useTranslation } from 'react-i18next';
import ActionListItem from '../basic/ActionListItem';

interface ConnectFitbitActionListItemProps {
  activity: StudyActivity;
}

function ConnectFitbitActionListItem(_: ConnectFitbitActionListItemProps) {
  const { t } = useTranslation('translations');
  return (
    <ActionListItem
      title={t('participants.connectFitbit.button')}
      iconSrc={`/icons/actionCard${_capitalize(
        PatientIntegrationSourceDataType.Wellness
      )}.svg`}
    />
  );
}

export default ConnectFitbitActionListItem;
