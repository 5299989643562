import React from 'react';

interface ListItem {
  title: string;
  value: string;
}

interface BoldListProps {
  list: Array<ListItem>;
}

const BoldList = ({ list }: BoldListProps) => (
  <ol className='li-dense li-no-bullet'>
    {list.map((element, ix) => (
      <li key={ix}>
        <strong>{element.title}: </strong>
        {element.value}
      </li>
    ))}
  </ol>
);

export default BoldList;
