import { persistStore } from 'redux-persist';
import { createStore } from 'redux';
import { appReducer } from './store/reducers';
import persistReducer from './persistReducer';

function configureTestStore(preloadState: any = {}) {
  const reducer = persistReducer('root', appReducer, {
    blacklist: [
      'router',
      'ui',
      'currentDate',
      'trialBuilder',
      'fullStory',
      'participantPicker',
      'maintenanceMode',
    ],
  });

  const store = createStore(
    reducer,
    preloadState,
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  const persistor = persistStore(store);

  return { persistor, store };
}

const reducer = persistReducer('root', appReducer, {
  blacklist: [
    'router',
    'ui',
    'currentDate',
    'trialBuilder',
    'fullStory',
    'participantPicker',
    'maintenanceMode',
  ],
});

const store: any = createStore(
  reducer,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);

function getDispatch(): (arg0: any) => any {
  return store.dispatch;
}

function getUser() {
  //@ts-ignore
  return store.getState().user;
}

export { store, persistor, getDispatch, getUser, configureTestStore };
