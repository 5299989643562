import { Checkbox, TextField } from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';

export interface Option {
  name: string;
  value: any;
}

interface Props {
  placeholder?: string;
  options: Option[];
  loading?: boolean;
  value?: any;
  renderOptionLabel?: (option: Option) => string;
  onChange: (options: Option[]) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AutocompleteMultipleSelect = (props: Props): React.ReactElement => {
  const {
    loading,
    placeholder,
    onChange,
    options,
    renderOptionLabel,
    value,
  } = props;

  return (
    <>
      <MaterialAutocomplete
        multiple
        disableCloseOnSelect
        value={value}
        loading={loading}
        size='small'
        options={options}
        getOptionLabel={option =>
          renderOptionLabel ? renderOptionLabel(option) : option.name
        }
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={(_, values) => onChange(values)}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 10 }}
              checked={selected}
            />
            {renderOptionLabel ? renderOptionLabel(option) : option.name}
          </>
        )}
        renderInput={params => (
          <TextField {...params} variant='outlined' placeholder={placeholder} />
        )}
      ></MaterialAutocomplete>
    </>
  );
};

export default AutocompleteMultipleSelect;
