import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';

const ViewComponent = (props: any) => {
  const { routeProps, renderProps } = props;
  const { component: Component } = routeProps;

  return (
    <>
      {!routeProps.customHeader && <PageHeader />}
      <Component {...renderProps} />
      {!routeProps.hideFooter && <PageFooter />}
    </>
  );
};

export default ViewComponent;
