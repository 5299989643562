/* eslint-disable no-fallthrough */
import {
  CreateAccessCodeRequest,
  CreateAccessCodeResponse,
  CustomActionMarkAsComplete,
  GetExploreStatusRequest,
  GetExploreStatusResponse,
  SignupRequest,
  SignupResponse,
  UpdateHumanAPISourcesResponse,
  UpdatePatientRequest,
  UpdatePatientResponse,
  UpdateReferralCodeRequest,
  UpdateReferralCodeResponse,
  ErrorResponse,
} from '@curebase/core/decoders/patients';
import { jsonPost } from '../lib/fetchHelpers';
import { setUserDataInStore } from '../lib/auth';
import { ExploreStatus, StudyActivityType } from '@curebase/core/types';

export async function markCustomActionAsComplete(
  trialOptionId: number,
  configId: string
) {
  const request: CustomActionMarkAsComplete = { trialOptionId, configId };
  return await jsonPost('/patient/customaction', request);
}

export async function getNextExploreRoute(
  request: GetExploreStatusRequest
): Promise<string> {
  const { trialIdentifier } = request;

  const response: GetExploreStatusResponse = await jsonPost(
    '/patient/getExploreStatus',
    request
  );

  const baseRoute = `/explore/${trialIdentifier}`;

  switch (response.status) {
    case ExploreStatus.Deactivated:
      return `${baseRoute}/deactivated`;
    case ExploreStatus.Signup:
      return baseRoute;
    case ExploreStatus.NotLaunched:
      return `${baseRoute}/launching`;
    case ExploreStatus.StudyActivity:
      switch (response.type) {
        case StudyActivityType.ScheduleVisit: {
          const { configId } = response;
          return `${baseRoute}/schedule/${configId}`;
        }
        case StudyActivityType.DataCapture:
          return `${baseRoute}/capture/${response.dataCaptureId}`;
        case StudyActivityType.InformedConsent:
          return `${baseRoute}/consent/${response.trialOptionId}`;
        case StudyActivityType.CustomAction: {
          const { configId, trialOptionId } = response;
          return `${baseRoute}/customAction/${configId}/TO/${trialOptionId}`;
        }
        case StudyActivityType.Pluto: {
          return `${baseRoute}/pluto`;
        }
        default: {
          const neverCase: never = response;
          return neverCase;
        }
      }
    case ExploreStatus.Failed:
      return `${baseRoute}/failed`;
    case ExploreStatus.Passed:
      return `${baseRoute}/complete`;
    case ExploreStatus.Completed:
      return `/`;
    default: {
      const neverCase: never = response;
      return neverCase;
    }
  }
}

export async function signup(
  request: SignupRequest
): Promise<SignupResponse | ErrorResponse> {
  const res: SignupResponse = await jsonPost('/patient/signup', request);

  if (res.user) {
    await setUserDataInStore(res.user.id);
  }

  return res;
}

export async function updatePatient(
  request: UpdatePatientRequest
): Promise<UpdatePatientResponse> {
  const res: UpdatePatientResponse = await jsonPost('/patient/update', request);
  return res;
}

export async function generateAccessCode(
  request: CreateAccessCodeRequest
): Promise<CreateAccessCodeResponse> {
  const res: SignupResponse = await jsonPost(
    '/patient/generateAccessCode',
    request
  );

  // TODO(KPS): there is no code property on type SignupResponse
  // @ts-ignore
  return res;
}

export async function updatePatientReferralCode(
  request: UpdateReferralCodeRequest
): Promise<UpdateReferralCodeResponse> {
  const res: UpdateReferralCodeResponse = await jsonPost(
    '/patient/updateReferralCode',
    request
  );
  return res;
}

export async function updateHumanAPISources(): Promise<UpdateHumanAPISourcesResponse> {
  const response: UpdateHumanAPISourcesResponse = await jsonPost(
    '/patient/sourceDataIntegrations/humanapi/update',
    {}
  );
  return response;
}
