import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  DropdownSubElement,
  SubElementProps,
} from '@curebase/core/lib/dynamicform/types';
import { useTranslation } from 'react-i18next';
interface DropdownProps {
  data: Record<string, any>;
  readOnly: boolean;
  subEle: DropdownSubElement;
  onChange: (subEle: SubElementProps, value: any) => void;
  dataKey?: string;
  alt?: boolean;
  placeholder?: string;
}
function Dropdown(props: DropdownProps) {
  const { subEle, onChange, data, readOnly, dataKey, alt, placeholder } = props;
  const { t } = useTranslation('translations');
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const getValue = () => {
    if (dataKey && data[subEle.key]) {
      return data[subEle.key][dataKey];
    } else if (!dataKey && data[subEle.key]) {
      return data[subEle.key];
    }
  };

  useEffect(() => {
    if (dataKey && data[subEle.key] && data[subEle.key][dataKey]) {
      setShowPlaceholder(false);
    } else if (!dataKey && data[subEle.key]) {
      setShowPlaceholder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[subEle.key]]);

  const handleChange = event => {
    const { value } = event.target;
    onChange(subEle, value);
  };

  const handleFocus = _ => {
    setShowPlaceholder(false);
  };

  const handleClose = _ => {
    setShowPlaceholder(getValue() === 'none');
  };
  return (
    <div
      className={`dynamic-form-dropdown-sub-element ${
        alt ? 'dynamic-form-dropdown-alt' : ''
      } ${showPlaceholder ? 'dropdown-placeholder' : ''}`}
    >
      <Select
        fullWidth
        value={getValue() ?? 'none'}
        onChange={handleChange}
        disabled={readOnly}
        onFocus={handleFocus}
        onClose={handleClose}
      >
        <MenuItem
          value='none'
          className={`${
            showPlaceholder ? 'dropdown-item' : 'dropdown-placeholder-hide'
          }`}
        >
          {placeholder || t('common.selectValue')}
        </MenuItem>
        {subEle.options.map(({ text, value }, i) => (
          <MenuItem key={i} value={value} className='dropdown-item'>
            {text}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default Dropdown;
