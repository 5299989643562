import _assign from 'lodash/assign';
import _omit from 'lodash/omit';
import _transform from 'lodash/transform';
import _values from 'lodash/values';
import produce from 'immer';
import * as uuid from 'uuid';
import {
  ADD_CASEREPORT,
  EDIT_CASEREPORT,
  TOGGLE_CASEREPORT,
  EDIT_STUDYPLANVISIT,
  DELETE_STUDYPLANVISIT,
  INIT_TRIAL,
  Action,
  AddCaseReportAction,
  EditCaseReportAction,
  ToggleCaseReportAction,
  InitTrialAction,
  DELETE_ALL_INSTANCES_OF_CRF,
} from '../../actions/TrialBuilder/types';
import { caseReportSlugFrom } from '../../../lib/trialBuilderHelpers';
import { CaseReport } from '@curebase/core/types';

type CaseReportMap = Record<string, any>;

//gql returns casereports with an array of datafields and deprecated datafields, we don't need that in our redux store
const pruneCaseReport = caseReport => {
  delete caseReport.dataFields;
  delete caseReport.dataFieldsWithDeprecated;
  return caseReport;
};

//helper to find a caseReport with the same simpleSlug
const findSimilarCaseReport = (
  caseReports: CaseReportMap,
  simpleSlug: string
): CaseReport =>
  //[DT] - find better way to flow type this
  // @ts-ignore
  Object.values(caseReports).find(
    (cr: CaseReport) => cr.simpleSlug === simpleSlug
  );

const _addCaseReportReducer = (
  state: CaseReportMap = {},
  action: AddCaseReportAction
): CaseReportMap => {
  if (!action.payload) return state;
  const caseReport = pruneCaseReport(action.payload);
  if (state[caseReport.slug]) caseReport.slug += `_${uuid.v4().slice(0, 4)}`;
  return { ...state, [caseReport.slug]: caseReport };
};

const _editCaseReportReducer = (
  state: CaseReportMap = {},
  action: EditCaseReportAction
): CaseReportMap => {
  const { slug, updates }: any = action.payload;
  if (!_values(updates).some(x => x !== undefined)) return state;
  const nextState = { ...state };
  //if slug didn't change
  if (!updates.slug || slug === updates.slug) {
    _assign(nextState, {
      [slug]: { ...state[slug], ...updates, edited: true },
    });
  } else {
    _assign(
      nextState,
      {
        [updates.slug]: { ...state[slug], ...updates, new: true, edited: true },
      }, //copy over old CR
      {
        [slug]: {
          ...state[slug],
          deleted: true,
          isDeprecated: true,
          edited: true,
        },
      } //set the old to be 'deleted'
    );
  }
  return nextState;
};

const _toggleCaseReportReducer = produce(
  (state: CaseReportMap, action: ToggleCaseReportAction) => {
    const { caseReportSimpleSlug, studyPlanVisitSlug } = action.payload;
    const caseReportSlug = caseReportSlugFrom(
      studyPlanVisitSlug,
      caseReportSimpleSlug
    );
    if (state[caseReportSlug]) {
      delete state[caseReportSlug];
    } else {
      const template = findSimilarCaseReport(state, caseReportSimpleSlug);
      state[caseReportSlug] = _assign(
        _omit(template, ['id', 'studyPlanVisit']),
        {
          slug: caseReportSlug,
          studyPlanVisit: {
            slug: studyPlanVisitSlug,
          },
          isDeprecated: false,
          new: true,
        }
      );
    }
  }
);

export const _initTrial = (action: InitTrialAction) => {
  const nextState = {};
  const { studyPlanVisits } = (action.payload as any).trial;
  if (!studyPlanVisits) return nextState;
  studyPlanVisits.forEach(spv => {
    const { caseReports } = spv;
    caseReports.forEach(cr => {
      if (cr.isDeprecated ?? false) {
        return;
      }

      _assign(cr, { studyPlanVisit: { slug: spv.slug } });
      _assign(nextState, { [cr.slug]: _omit(cr, ['dataFields']) });
    });
  });
  return nextState;
};

export default function (state: CaseReportMap = {}, action: Action) {
  switch (action.type) {
    case INIT_TRIAL:
      return _initTrial(action);
    case ADD_CASEREPORT:
      return _addCaseReportReducer(state, action);
    case EDIT_CASEREPORT:
      return _editCaseReportReducer(state, action);
    case TOGGLE_CASEREPORT:
      return _toggleCaseReportReducer(state, action);
    case DELETE_ALL_INSTANCES_OF_CRF:
      return produce(state, draft => {
        for (const [crfName, crfValue] of Object.entries(draft)) {
          if (crfValue.simpleSlug === action.payload.caseReportSimpleSlug) {
            delete draft[crfName];
          }
        }
      });
    case EDIT_STUDYPLANVISIT:
      if (!action.payload) return state;
      const { updates, slug: spvSlug } = action.payload;
      if (!updates) return state;
      if (!updates.slug) return state;
      if (updates.slug === spvSlug) return state;
      //We update all caseReports for that studyPlanVisit to record the new slug
      return _transform(
        state,
        (result, value: any, key) => {
          if (value.studyPlanVisit.slug === spvSlug) {
            result[key] = {
              ...value,
              isDeprecated: true,
              deleted: true,
              edited: true,
            };
            const newCrSlug = value.slug.replace(spvSlug, updates.slug);
            result[newCrSlug] = {
              ...value,
              slug: newCrSlug,
              studyPlanVisit: {
                slug: updates.slug,
              },
              new: true,
              edited: true,
            };
          } else {
            result[key] = value;
          }
        },
        {}
      );
    case DELETE_STUDYPLANVISIT:
      return produce(state, draft => {
        for (const [slug, cr] of Object.entries(draft)) {
          // @ts-ignore
          if (cr.studyPlanVisit.slug === action.payload.slug)
            delete draft[slug];
        }
      });
    default:
      return state;
  }
}
