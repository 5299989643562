import {
  CreateAdHocPaymentRequest,
  CreateAdHocPaymentResponse,
  FetchPaypalDetailsIdRequest,
  ForcePayoutRequest,
  ForcePayoutResponse,
} from '@curebase/core/decoders/payments';

import { AcceptedCurrency } from '@curebase/core/types';

import { jsonPost } from '../lib/fetchHelpers';

export async function connectPaypalAccount(
  userId: number,
  code: string
): Promise<unknown> {
  const requestBody: FetchPaypalDetailsIdRequest = { userId, code };
  const response = await jsonPost('/payments/paypal/connect', requestBody);
  return response;
}

interface AdHocPayment {
  amount: number;
  currency: AcceptedCurrency;
}

export async function forcePayout(
  reason: string,
  paymentId: number,
  trialOptionId: number
): Promise<ForcePayoutResponse> {
  const requestBody: ForcePayoutRequest = { reason, paymentId, trialOptionId };
  const response: ForcePayoutResponse = await jsonPost(
    '/payments/forcepayout',
    requestBody
  );
  return response;
}

export async function createAdhocPayment(
  payment: AdHocPayment,
  reason: string,
  trialOptionId: number
): Promise<CreateAdHocPaymentResponse> {
  const requestBody: CreateAdHocPaymentRequest = {
    amount: payment.amount,
    currency: payment.currency,
    reason,
    trialOptionId,
  };

  const response: CreateAdHocPaymentResponse = await jsonPost(
    '/payments/createadhocpayment',
    requestBody
  );
  return response;
}
