import { Action } from '../actions';

export type TelehealthState = {
  meetingId?: string;
  openVideo?: boolean;
  recording?: boolean;
  attendee?: any;
  meetingName?: any;
  callLeft?: boolean;
  meetingTime?: any;
};

export default (state: TelehealthState = {}, action: Action) => {
  switch (action.type) {
    case 'SET_JOIN_INFO':
      return {
        ...state,
        meeting: { ...action.payload.meeting },
        attendee: { ...action.payload.attendee.Attendee },
      };
    case 'SET_MEETING_TITLE':
      return {
        ...state,
        meetingTitle: action.payload.meetingTitle,
      };
    case 'SET_MEETING_NAME':
      return {
        ...state,
        meetingName: action.payload.meetingName,
      };
    case 'SET_MEETING_TIME':
      return {
        ...state,
        meetingTime: action.payload.meetingTime,
      };
    case 'OPEN_VIDEO_CHAT':
      return {
        ...state,
        openVideo: true,
        callLeft: false,
      };
    case 'CLOSE_VIDEO_CHAT':
      return {
        ...state,
        openVideo: false,
      };
    case 'LEAVE_CALL':
      return {
        ...state,
        hasJoined: false,
        callLeft: true,
      };
    case 'RECORDING_ENABLED':
      return {
        ...state,
        recording: true,
      };
    case 'SET_PHOTO_MODE':
      return {
        ...state,
        addPhotos: action.payload.addPhotos,
      };
    case 'FOOTER_PORTAL_STATUS':
      return { ...state, signatureMountStatus: action.status };
    case 'CLEAR_TELEHEALTH':
      return {};
    default:
      return state;
  }
};
