export const SET_SELECTED_CLINIC_ID = 'SET_SELECTED_CLINIC_ID';
export const SET_SELECTED_PATIENT_ID = 'SET_SELECTED_PATIENT_ID';
export const SET_SELECTED_TRIALOPTION_ID = 'SET_SELECTED_TRIALOPTION_ID';

export type SelectClinicAction = {
  type: typeof SET_SELECTED_CLINIC_ID;
  payload: number | null;
};
export type SelectPatientAction = {
  type: typeof SET_SELECTED_PATIENT_ID;
  payload: number | null;
};
export type SelectTrialOptionAction = {
  type: typeof SET_SELECTED_TRIALOPTION_ID;
  payload: number | null;
};

export const SelectClinic = (id: number | null): SelectClinicAction => ({
  type: SET_SELECTED_CLINIC_ID,
  payload: id,
});

export const SelectPatient = (id: number | null): SelectPatientAction => ({
  type: SET_SELECTED_PATIENT_ID,
  payload: id,
});

export const SelectTrialOption = (
  id: number | null
): SelectTrialOptionAction => ({
  type: SET_SELECTED_TRIALOPTION_ID,
  payload: id,
});
