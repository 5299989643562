import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, { SafeDialogProps } from '../basic/SafeDialog';

interface UnlockConsentConfirmationDialogProps extends SafeDialogProps {
  onClose: () => any;
  onConfirm: () => any;
}
const UnlockConsentConfirmationDialog = (
  props: UnlockConsentConfirmationDialogProps
) => {
  const { t } = useTranslation('translations');
  const { onClose, onConfirm, ...dialogProps } = props;

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <div className='light-dialog'>
        <div className='booker booker-loading'>
          <div className='booker-text-container'>
            <div className='booker-title'>
              {t('unlockConsentConfirmationDialog.title')}
            </div>
            <div className='booker-text'>
              {t('unlockConsentConfirmationDialog.text')}
            </div>
            <div className='booker-button-container'>
              <div
                className='booker-button'
                role='button'
                id='booker-success-button'
                onClick={onConfirm}
              >
                {t('unlockConsentConfirmationDialog.button')}
              </div>
            </div>
          </div>
          <div className='close-dialog-icon-container' onClick={onClose}>
            <img
              className='close-dialog-icon'
              src='/icons/closeDialog.svg'
              alt='X'
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UnlockConsentConfirmationDialog;
