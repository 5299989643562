import Geocode from 'react-geocode';
import _includes from 'lodash/includes';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';
import { getDistance } from 'geolib';
import { AcceptedCountryCodes, VisitMethod, Maybe } from '@curebase/core/types';
import { formatAddress } from '@curebase/core/lib/clinics';
import { getLocale } from 'src/context/localeContext';

export const GOOGLE_PUBLIC_API_KEY = 'AIzaSyCjRjVT5TK0RLZjTtFV67ad3ZXagRGJn94';

// Set up geocoding
Geocode.setApiKey(GOOGLE_PUBLIC_API_KEY);
Geocode.setLanguage('en');

export type Address = {
  // Require a zipcode and country, for minimal gelocation
  zipcode: string;
  country?: Maybe<AcceptedCountryCodes>;
  // More location info is better, if we can get it
  addressLine1?: Maybe<string>;
  addressLine2?: Maybe<string>;
  city?: Maybe<string>;
  county?: Maybe<string>;
  state?: Maybe<string>;
};

export type VisitOption = {
  visitMethods: VisitMethod[];
  address: Address;
  siteName: string;
  visitSiteId: number;
  trialInstanceId: string;
  timezone: string;
  deactivated: boolean;
};

export type GeocodeState = {
  stateName: string;
  stateCode: string;
};

export type GeoCoordinates = {
  lat: number;
  lng: number;
};

export type VisitOptionWithCoordinates = VisitOption & GeoCoordinates;

export async function getFirstGeocodeFromAddress(
  zipcode: string
): Promise<object | null> {
  const { country } = getLocale() as Record<string, string>;
  const addressQuery = country
    ? `${zipcode}, ${country.toUpperCase()}`
    : zipcode;
  try {
    const response = await Geocode.fromAddress(addressQuery);
    //extract state for validation purposes, if it fails then it's invalid
    getStateFromGeocode(response.results[0]);
    return response.results[0] || null;
  } catch (e) {
    return null;
  }
}

export function getStateFromGeocode(geo: any): GeocodeState {
  for (const component of geo.address_components) {
    const { types, long_name, short_name } = component;

    if (
      _includes(types, 'administrative_area_level_1') &&
      _includes(types, 'political')
    ) {
      return {
        stateName: long_name,
        stateCode: short_name,
      };
    }
  }

  throw new Error('Geocode did not include a state!');
}

export function getLatAndLngFromGeocode(geo: any): GeoCoordinates {
  return geo.geometry.location;
}

export function isVirtualVisitAvailableInState(
  stateCode: string,
  visitOptions: VisitOption[]
) {
  for (const visitOption of visitOptions) {
    if (
      stateCode === visitOption.address.state
      // TODO: Need to add additional logic, and configurability, for clinics that don't do telemedicine
    )
      return true;
  }

  return false;
}

async function getCoordinateForVisitSite(option: VisitOption) {
  const { address } = option;
  const formattedAddress = formatAddress(address);
  const coordinates = getLatAndLngFromGeocode(
    await getFirstGeocodeFromAddress(formattedAddress)
  );
  try {
    return {
      ...option,
      ...coordinates,
    };
  } catch (e) {
    console.error(
      'Failed to get coordinates for visit site with address: ' +
        formattedAddress
    );
    return null;
  }
}

export async function addCoordinatesToVisitOptions(
  visitOptions: VisitOption[]
): Promise<VisitOptionWithCoordinates[]> {
  let visitOptionsWithCoordinates = await Promise.all(
    visitOptions.map(getCoordinateForVisitSite)
  );

  // compact removes falsy values
  return _compact(visitOptionsWithCoordinates);
}

export async function sortVisitSites(
  patientCoordinates: GeoCoordinates,
  visitSites: VisitOptionWithCoordinates[]
) {
  const options = visitSites.filter(option => !option.deactivated);

  return _sortBy(options, optionCoordinates =>
    getDistance(
      {
        latitude: patientCoordinates.lat,
        longitude: patientCoordinates.lng,
      },

      {
        latitude: optionCoordinates.lat,
        longitude: optionCoordinates.lng,
      }
    )
  );
}
