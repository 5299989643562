import {
  RolePermissions,
  UserRoleType,
  VisitStatus,
} from '@curebase/core/types';
import { VisitResults } from '@curebase/core/types/trialOption';
import { Divider } from '@material-ui/core';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Logger } from 'src/lib/logger';
import { getLocale, getLocaleFormatDates } from '../../context/localeContext';
import { verifyCaseReportInstance } from '../../controllers/sourceDataVerificationController';
import { getUserSelector } from '../../store/selectors';
import {
  SourceDataVerificationEventType,
  useGenerateSpvpdfMutation,
} from '../../types';
import PermissionedButton from '../basic/PermissionedButton';
import StandardButton from '../basic/StandardButton';
import Loading from '../Loading';
import { SDVChip } from '../TrialOption/SDV/SDVChip';
import CaseReportView from './CaseReport/CaseReportView';
import PISignature from './PISignature';
import { SourceDataVerificationLog } from './SourceDataVerificationLog';

interface Props {
  visit: VisitResults;
  withKnowledgeContent?: boolean;
  hasFollowingCompletedVisits: boolean;
  hasOccurrences: boolean;
  isSelectedOccurrence: boolean;
  editable?: boolean;
  refetch: () => Promise<any>;
  onExtendDueDate: (visit: VisitResults) => void;
  onBackFill: (visit: VisitResults) => void;
  onOpenSurvey: (visit: VisitResults) => void;
}

export const DataCaptureVisitResults = (props: Props) => {
  const {
    visit,
    editable = true,
    withKnowledgeContent = false,
    hasFollowingCompletedVisits,
    hasOccurrences,
    refetch,
    onExtendDueDate,
    onBackFill,
    onOpenSurvey,
    isSelectedOccurrence,
  } = props;

  const { locale } = getLocale();
  const localesFormat = getLocaleFormatDates();
  const { t } = useTranslation('translations');

  const [isVerifyingData, setVerifyingData] = useState(false);
  const [isSDVLogOpen, setSDVLogOpen] = useState(false);
  const user: any = useSelector(getUserSelector);

  let location = useLocation();
  const signature = queryString.parse(location.search);
  const renderPISignature = signature.legalName && signature.file;

  const [generateSPVPDF] = useGenerateSpvpdfMutation();

  const canDownloadPdf = () => {
    return [
      UserRoleType.ClinicalTrialManager,
      UserRoleType.ClinicalResearchCoordinator,
      UserRoleType.TechnicalTrialManager,
      UserRoleType.ReadOnlySupportAgent,
    ].includes(user.roles[0].type);
  }; // TODO: is this a tech debt? Should we have a permission for PDF downloading?

  const downloadPdf = async (visit, signed = false) => {
    const variables = {
      input: {
        visitId: visit.id,
      },
      isSigned: signed,
    };

    let updatedVisit;

    const { data } = await generateSPVPDF({
      variables,
    });

    updatedVisit = data?.generateSPVPDF;
    const url = updatedVisit.pdfDownloadUrl;

    if (!url) {
      Logger('visits').error('No pdfDownloadUrl received', {
        visitId: visit?.id,
        trialOptionId: visit?.trialOptionId,
      });
    } else {
      window.open(url, '_blank');
    }
  };

  const handleSourceDataVerification = async caseReportInstanceId => {
    setVerifyingData(true);
    try {
      await verifyCaseReportInstance(caseReportInstanceId);
    } catch (e) {
      console.error(e);
    } finally {
      await refetch();
      setVerifyingData(false);
    }
  };

  const handleOpenSDVLog = () => {
    setSDVLogOpen(true);
  };

  const { caseReportInstances, studyPlanVisit } = visit;
  const missingVisitPlaceHolder =
    caseReportInstances.length === 0 ? (
      <div className='dc-cri'>
        {t('dataCaptureResultsView.noDataSubmitted')}
      </div>
    ) : null;
  const formattedCompletedAt = [
    VisitStatus.Created,
    VisitStatus.InProgress,
    VisitStatus.BackFillOpen,
  ].includes(visit.status)
    ? ''
    : DateTime.fromISO(visit.completedAt!).toFormat(
        localesFormat.luxon.dataCaptureResults,
        { locale }
      );

  const backFillCreatedAtFormatted = visit.backFillCreatedAt
    ? DateTime.fromISO(visit.backFillCreatedAt).toFormat(
        localesFormat.luxon.dataCaptureResults,
        { locale }
      )
    : null;

  const shouldShowBackFillButton =
    [VisitStatus.MissedAll, VisitStatus.MissedPartial].includes(visit.status) &&
    (hasFollowingCompletedVisits || !visit.trialOption.isActive);
  const shouldShowExtendDueDateButton =
    [VisitStatus.MissedAll, VisitStatus.MissedPartial].includes(visit.status) &&
    !hasFollowingCompletedVisits &&
    visit.trialOption.isActive;
  const shouldShowOpenSurveyButton = visit.canOpenSurvey;

  const isMissed = [VisitStatus.MissedPartial, VisitStatus.MissedAll].includes(
    visit.status
  );

  const shouldShowDownloadPDFButton =
    visit.status === VisitStatus.Completed && studyPlanVisit.generatePdfReport;
  const shouldShowDownloadSignedPDFButton =
    visit.signedPdfSummary && studyPlanVisit.generateSignedPdfReport;

  return (
    <>
      <div
        className={`data-capture-results ${
          isSelectedOccurrence ? 'dc-selected' : ''
        }`}
        key={visit.id}
      >
        <div className={`dc-left ${isMissed ? 'red' : ''}`}>
          {hasOccurrences && (
            <div className='dc-occurence' id={`occurence-${visit.occurence}`}>
              {t('dataCaptureResultsView.visitOccurence', {
                visitOccurence: visit.occurence,
              })}
            </div>
          )}
          <div className='dc-status'>
            {backFillCreatedAtFormatted && (
              <div>
                <div>{t('dataCaptureResultsView.dataCreationDate')}</div>
                <div>{backFillCreatedAtFormatted}</div>
              </div>
            )}

            <div>
              <div>
                {visit.status === VisitStatus.Created &&
                  t('dataCaptureResultsView.status.pending')}
                {visit.status === VisitStatus.InProgress &&
                  t('dataCaptureResultsView.status.inProgress')}
                {visit.status === VisitStatus.MissedPartial &&
                  t('dataCaptureResultsView.status.leftComplete')}
                {visit.status === VisitStatus.MissedAll &&
                  t('dataCaptureResultsView.status.neverStarted')}
                {visit.status === VisitStatus.Completed &&
                  t('dataCaptureResultsView.status.submitted')}
                {visit.status === VisitStatus.BackFillOpen &&
                  t('dataCaptureResultsView.status.backFillOpen')}
              </div>
              <div>{formattedCompletedAt}</div>
            </div>

            {shouldShowOpenSurveyButton && (
              <p>
                <PermissionedButton
                  disabled={false}
                  permission={RolePermissions.SubmitInitialDataCapture}
                  variant='contained'
                  buttonText={t('dataCaptureResultsView.openSurveyBtn')}
                  onClick={() => onOpenSurvey(visit)}
                />
              </p>
            )}

            {shouldShowBackFillButton && (
              <p>
                <PermissionedButton
                  disabled={false}
                  permission={RolePermissions.SubmitInitialDataCapture}
                  variant='contained'
                  buttonText={t('dataCaptureResultsView.backfillSurveyBtn')}
                  onClick={() => onBackFill(visit)}
                />
              </p>
            )}

            {shouldShowExtendDueDateButton && (
              <p>
                <PermissionedButton
                  disabled={false}
                  permission={RolePermissions.CreateVisitExtension}
                  variant='contained'
                  buttonText={t('dataCaptureResultsView.extendDueDateBtn')}
                  onClick={() => onExtendDueDate(visit)}
                />
              </p>
            )}
            {shouldShowDownloadPDFButton && canDownloadPdf() && (
              <>
                {visit.pdfSummary && (
                  <>
                    <div className='dc-divider'>
                      <Divider variant={'fullWidth'} />
                    </div>
                    <p>
                      <span>{t('dataCaptureResultsView.downloadText')}</span>
                    </p>
                    <p>
                      <StandardButton
                        variant={'contained'}
                        text={t('dataCaptureResultsView.downloadBtn')}
                        onClick={() => downloadPdf(visit)}
                      />
                    </p>
                  </>
                )}
                {shouldShowDownloadSignedPDFButton && (
                  <>
                    <div className='dc-divider'>
                      <Divider variant={'fullWidth'} />
                    </div>
                    <p>
                      <span>
                        {t('dataCaptureResultsView.downloadSignedText')}
                      </span>
                    </p>
                    <p>
                      <StandardButton
                        variant={'contained'}
                        text={t('dataCaptureResultsView.downloadSignedBtn')}
                        onClick={() => downloadPdf(visit, true)}
                      />
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className='dc-right'>
          {missingVisitPlaceHolder}
          {caseReportInstances
            //@ts-ignore
            .sort((a, b) => a.caseReport.order - b.caseReport.order)
            .map(caseReportInstance => {
              const { caseReport, sourceDataVerification } = caseReportInstance;
              const needsSourceDataVerification =
                caseReport?.needsSourceDataVerification &&
                (!sourceDataVerification ||
                  sourceDataVerification?.eventType !==
                    SourceDataVerificationEventType.Verified);

              const hiddenFields: number =
                caseReportInstance?.hiddenFieldsCount || 0;

              return (
                <React.Fragment key={caseReportInstance.id}>
                  <div className='dc-cri'>
                    <div className='dc-cri-title-section'>
                      <div className='dc-cri-title'>
                        {t('dataCaptureResultsView.caseReportText', {
                          caseReportOrder: caseReport.order,
                          caseReportsLength: studyPlanVisit.caseReports.length,
                          caseReportName: caseReport.name,
                        })}
                        {Boolean(hiddenFields) && (
                          <div className='dc-cri-subtitle'>
                            {t('dataCaptureResultsView.hiddenFieldSubtext', {
                              count: hiddenFields,
                            })}
                          </div>
                        )}
                      </div>

                      {((sourceDataVerification &&
                        caseReport?.needsSourceDataVerification) ||
                        needsSourceDataVerification) && (
                        <div className='dc-cri-title-sdv-status'>
                          <SDVChip
                            isSourceDataVerified={
                              sourceDataVerification?.eventType ===
                              SourceDataVerificationEventType.Verified
                            }
                          />
                        </div>
                      )}
                    </div>

                    <div className='dc-cri-actions'>
                      {(needsSourceDataVerification ||
                        (sourceDataVerification &&
                          caseReport?.needsSourceDataVerification)) && (
                        <div>
                          <PermissionedButton
                            disabled={false}
                            permission={RolePermissions.SourceDataVerification}
                            variant='text'
                            buttonText={t(
                              'dataCaptureResultsView.sourceDataVerifyLog'
                            )}
                            onClick={() => handleOpenSDVLog()}
                          />

                          {isSDVLogOpen && (
                            <SourceDataVerificationLog
                              caseReportInstanceId={caseReportInstance.id}
                              open={isSDVLogOpen}
                              onClose={() => setSDVLogOpen(false)}
                            />
                          )}
                        </div>
                      )}

                      {needsSourceDataVerification && (
                        <>
                          <PermissionedButton
                            disabled={isVerifyingData}
                            permission={RolePermissions.SourceDataVerification}
                            variant='contained'
                            buttonText={t(
                              'dataCaptureResultsView.sourceDataVerify'
                            )}
                            onClick={() =>
                              handleSourceDataVerification(
                                caseReportInstance.id
                              )
                            }
                          >
                            {isVerifyingData && <Loading size={10} />}
                          </PermissionedButton>
                        </>
                      )}
                    </div>
                  </div>

                  <CaseReportView
                    visit={visit}
                    knowledgeContent={caseReport.knowledgeContent}
                    caseReportInstance={caseReportInstance}
                    editable={editable}
                    refetch={refetch}
                    withKnowledgeContent={withKnowledgeContent}
                  />
                </React.Fragment>
              );
            })}
        </div>
      </div>
      {renderPISignature && <PISignature />}
    </>
  );
};
