import _intersection from 'lodash/intersection';
import filter from 'lodash/fp/filter';
import mapValues from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import pipe from 'lodash/fp/pipe';
import reduce from 'lodash/fp/reduce';
import { permissionsMap } from './authorization';

import {
  DocumentAction,
  DocumentConfiguration,
  RolePermissions,
  SignedDocumentStatus,
  SigningMode,
  User,
} from '../types';

// https://stackoverflow.com/a/53276873
export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

function keysOfRecord<T extends string>(record: PartialRecord<T, any>): T[] {
  return (Object.keys(record) as unknown) as T[];
}

const {
  Created,
  NeedsParticipantSignature,
  NeedsCounterSignature,
  Completed,
  Declined,
  NotApplicable,
} = SignedDocumentStatus;

const {
  SignInformedConsent,
  CanProvideBothSignatures,
  CanProvideCounterSignature,
} = RolePermissions;

const { Countersignature, NoCountersignature } = SigningMode;

const {
  AcquireParticipantSignature,
  DeclinedParticipantSignature,
  ProvideCounterSignature,
  UnlockParticipantSigning,
  MakeNotApplicableToSign,
} = DocumentAction;

export interface DocumentConfigurationAppliesTo
  extends Pick<DocumentConfiguration, 'inPerson' | 'remote' | 'id'> {}

interface DocumentMachineAction {
  action: DocumentAction;
  target: SignedDocumentStatus;
  permissions: RolePermissions[];
  appliesTo: Partial<DocumentConfigurationAppliesTo>;
}
type DocumentMachineState = {
  actions: DocumentMachineAction[];
};
type DocumentMachine = Record<SignedDocumentStatus, DocumentMachineState>;

const SignatureActions = [
  {
    action: AcquireParticipantSignature,
    target: Completed,
    permissions: [CanProvideBothSignatures],
    appliesTo: {
      inPerson: NoCountersignature,
    },
  },
  {
    action: AcquireParticipantSignature,
    target: Completed,
    permissions: [SignInformedConsent],
    appliesTo: {
      remote: NoCountersignature,
    },
  },
  {
    action: AcquireParticipantSignature,
    target: NeedsCounterSignature,
    permissions: [CanProvideBothSignatures],
    appliesTo: {
      inPerson: Countersignature,
    },
  },
  {
    action: UnlockParticipantSigning,
    target: NeedsParticipantSignature,
    permissions: [CanProvideCounterSignature],
    appliesTo: {
      remote: Countersignature,
    },
  },
];

const machine: DocumentMachine = {
  [Created]: {
    actions: [
      ...SignatureActions,
      {
        action: DeclinedParticipantSignature,
        target: Declined,
        permissions: [CanProvideBothSignatures],
        appliesTo: {
          inPerson: NoCountersignature,
        },
      },
      {
        action: DeclinedParticipantSignature,
        target: Declined,
        permissions: [SignInformedConsent],
        appliesTo: {
          remote: NoCountersignature,
        },
      },
      {
        action: DeclinedParticipantSignature,
        target: Declined,
        permissions: [CanProvideBothSignatures],
        appliesTo: {
          inPerson: Countersignature,
        },
      },
      {
        action: MakeNotApplicableToSign,
        target: NotApplicable,
        permissions: [CanProvideBothSignatures],
        appliesTo: {
          inPerson: NoCountersignature,
        },
      },
      {
        action: MakeNotApplicableToSign,
        target: NotApplicable,
        permissions: [SignInformedConsent],
        appliesTo: {
          remote: NoCountersignature,
        },
      },
      {
        action: MakeNotApplicableToSign,
        target: NotApplicable,
        permissions: [CanProvideBothSignatures],
        appliesTo: {
          inPerson: Countersignature,
        },
      },
    ],
  },
  [NeedsParticipantSignature]: {
    actions: [
      {
        //participant requests remote signing but shows up in person
        action: AcquireParticipantSignature,
        target: Completed,
        permissions: [SignInformedConsent],
        appliesTo: {
          inPerson: NoCountersignature,
        },
      },
      {
        //participant provides signature in a two-signature flow
        action: AcquireParticipantSignature,
        target: NeedsCounterSignature,
        permissions: [SignInformedConsent],
        appliesTo: {
          inPerson: Countersignature,
          remote: Countersignature,
        },
      },
      {
        action: DeclinedParticipantSignature,
        target: Declined,
        permissions: [SignInformedConsent],
        appliesTo: {
          inPerson: Countersignature,
          remote: Countersignature,
        },
      },
      {
        action: MakeNotApplicableToSign,
        target: NotApplicable,
        permissions: [SignInformedConsent],
        appliesTo: {
          inPerson: Countersignature,
          remote: Countersignature,
        },
      },
    ],
  },
  [NeedsCounterSignature]: {
    actions: [
      {
        action: ProvideCounterSignature,
        target: Completed,
        permissions: [CanProvideCounterSignature, CanProvideBothSignatures],
        appliesTo: {
          inPerson: Countersignature,
          remote: Countersignature,
        },
      },
    ],
  },
  [Completed]: {
    actions: [],
  },
  [Declined]: {
    actions: SignatureActions,
  },
  [NotApplicable]: {
    actions: SignatureActions,
  },
};

const makeIsActionApplicableToConfig = <
  T extends DocumentConfigurationAppliesTo
>(
  config: T
) => ({ appliesTo }: DocumentMachineAction) =>
  keysOfRecord(appliesTo).some(key => config[key] === appliesTo[key]);

/** Make a filter function that grabs state that only exist in the passed in config
 */
const makeFilterByDocumentConfig = <T extends DocumentConfigurationAppliesTo>(
  config: T
) => (state: DocumentMachineState) => {
  const isActionApplicableToConfig = makeIsActionApplicableToConfig(config);
  return state.actions.some(isActionApplicableToConfig);
};

const makeFilterOutActionsByDocumentConfig = <
  T extends DocumentConfigurationAppliesTo
>(
  config: T
) => (state: DocumentMachineState) => {
  const isActionApplicableToConfig = makeIsActionApplicableToConfig(config);
  return {
    ...state,
    actions: state.actions.filter(isActionApplicableToConfig),
  };
};

/**
 * Get machine for given document config
 * @param documentSigningConfiguration get from Trial.documentSigningConfiguration[DocumentType]
 * @returns DocumentMachine that contains all states permitted in the configuration
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMachineForDocument = <T extends DocumentConfigurationAppliesTo>(
  documentSigningConfiguration: DocumentConfigurationAppliesTo
): Partial<DocumentMachine> => {
  const pickApplicableToConfig = makeFilterByDocumentConfig(
    documentSigningConfiguration
  );
  const mapStateToContainOnlyRelevantActions = makeFilterOutActionsByDocumentConfig(
    documentSigningConfiguration
  );

  return pipe(
    pickBy(pickApplicableToConfig),
    mapValues(mapStateToContainOnlyRelevantActions)
  )(machine);
};

const makeFilterByPermission = (user: Pick<User, 'roles'>) => (
  state: DocumentMachineAction
) => {
  const permissions = [];
  for (const role of user?.roles ?? []) {
    //@ts-ignore
    permissions.push(...permissionsMap[role.type]);
  }

  return _intersection(permissions, state.permissions).length > 0;
};

const makeFilterByConfiguration = (
  documentConfig: Pick<DocumentConfiguration, 'allowDecline'>
) => (state: DocumentMachineAction) => {
  if (state.target === Declined) {
    return !!documentConfig.allowDecline;
  }
  return true;
};

export const availableActions = (
  documentMachine: Partial<DocumentMachine>,
  documentStatus: SignedDocumentStatus,
  user: any, //filter out roles based on trialId
  documentConfig: Partial<DocumentConfiguration>
): PartialRecord<DocumentAction, SignedDocumentStatus> => {
  const actions = documentMachine[documentStatus]?.actions;

  if (!actions) {
    return {};
  }

  const byPermissions = makeFilterByPermission(user);
  const byConfiguration = makeFilterByConfiguration(documentConfig);
  return pipe(
    filter(byConfiguration),
    filter(byPermissions),
    reduce(
      (prev, cur) => ({
        ...prev,
        [cur.action]: cur.target,
      }),
      {}
    )
  )(actions);
};

export const availableActionsList = (
  documentMachine: Partial<DocumentMachine>,
  documentStatus: SignedDocumentStatus,
  user: any, //filter out roles based on trialId
  documentConfig: Parameters<typeof availableActions>[3]
): DocumentAction[] => {
  return Object.keys(
    availableActions(documentMachine, documentStatus, user, documentConfig)
  ) as DocumentAction[];
};
