import { jsonPost } from '../lib/fetchHelpers';
import {
  AddAvailabilityRequest,
  AxleSchedulingSearch,
  DeleteAvailabilityRequest,
  EditAvailabilityRequest,
  SchedulingSearchResponse,
} from '@curebase/modules/scheduling/dto/scheduling';
import { SchedulingSystem } from '@curebase/core/types';

export async function addVisitAvailability(
  data: AddAvailabilityRequest
): Promise<boolean> {
  const response: boolean = await jsonPost(
    '/scheduling/availability/new',
    data
  );
  return response;
}

export async function deleteVisitAvailability(id: number): Promise<boolean> {
  const requestBody: DeleteAvailabilityRequest = { id };
  const response: boolean = await jsonPost(
    '/scheduling/availability/delete',
    requestBody
  );
  return response;
}

export async function editVisitAvailability(
  data: EditAvailabilityRequest
): Promise<boolean> {
  const response: boolean | { error: string } = await jsonPost(
    '/scheduling/availability/edit',
    data
  );

  if (typeof response !== 'boolean') {
    throw new Error(response.error);
  }

  return response;
}

export async function getAxleHealthAvailability(
  trialOptionId: number,
  schedulingConfigurationId: string
) {
  const requestBody: AxleSchedulingSearch = {
    type: SchedulingSystem.AxleHealthScheduling,
    trialOptionId,
    schedulingConfigurationId,
  };
  const response: SchedulingSearchResponse = await jsonPost(
    '/scheduling/availability',
    requestBody
  );
  return response;
}
