import { buildSchedulerUrl } from '@curebase/modules/scheduling/acuity';
import { IS_PRODUCTION, IS_SANDBOX } from '@curebase/core/lib/env';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import { AcuityConfig, useEmbeddedSchedulerQuery } from 'src/types';
import { BookerRootContext } from '../ParticipantInterface/Booker/BookerDialog';

interface AcuitySchedulerProps {
  trialOptionId: number;
  schedulingConfigurationId: string;
  height?: number | null | undefined;
  onClose?: () => Promise<any>;
}

const DEFAULT_HEIGHT = 800;

function ErrorView() {
  const { t } = useTranslation('translations');

  return (
    <div className='embedded-scheduler-error-container'>
      <div className='title'>{t('embeddedScheduler.errorView.title')}</div>
      <div className='text-content'>
        {t('embeddedScheduler.errorView.content')}
      </div>
    </div>
  );
}

function getAppointmentType(acuityConfig: AcuityConfig | null | undefined) {
  if (!acuityConfig) return;
  if (IS_PRODUCTION) return acuityConfig.production;
  if (IS_SANDBOX) return acuityConfig.sandbox;
  return acuityConfig.testing;
}

function EmbeddedScheduler(props: AcuitySchedulerProps) {
  const { height, trialOptionId, schedulingConfigurationId } = props;
  const { data, loading } = useEmbeddedSchedulerQuery({
    pollInterval: 500,
    variables: { trialOptionId, schedulingConfigurationId },
  });
  const trialOption = data?.getTrialOption;
  const subjectIdentifier = trialOption?.subjectIdentifier;
  const trialName = trialOption?.trial.name;
  const user = trialOption?.patient?.user;
  const schedulingConfig = data?.getSchedulingConfiguration;
  const acuityConfig = schedulingConfig?.default.acuityConfig;
  const appointmentType = getAppointmentType(acuityConfig);

  const { config } = useContext(BookerRootContext);

  const url =
    appointmentType &&
    subjectIdentifier &&
    trialName &&
    user &&
    buildSchedulerUrl({
      appointmentType,
      schedulingConfigurationId,
      subjectIdentifier,
      trialName,
      trialOptionId,
      // @ts-expect-error
      user,
    });

  if (!data || loading) return <Loading />;
  if (!url) return <ErrorView />;
  return (
    <>
      <div className='booker-title'>Schedule your appointment</div>
      {config?.instructions?.patient &&
        config?.instructions?.patient !== '' && (
          <div className='booker-text'>{config.instructions.patient}</div>
        )}
      <iframe
        title='prescreen-complete-schedule-call'
        src={url}
        width='100%'
        height={height || DEFAULT_HEIGHT}
        frameBorder='0'
      />
    </>
  );
}

export default EmbeddedScheduler;
