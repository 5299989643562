import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import _map from 'lodash/map';

import { mdToHTML } from '@curebase/core/lib/markdown';

import {
  CaseReportDataType,
  DataCaptureReserved,
  RolePermissions,
} from '@curebase/core/types';
import { currentUserHasPermission } from '../../lib/auth';

import withPermission from '../hocs/WithPermission';
import { ReadOnlyFuzzyDate } from '../TrialBuilder/FuzzyDatePicker';

import FilePreview from './FilePreview';
import StandardButton from './StandardButton';

// Dialogs
import CreateQueryDialog from '../Queries/CreateQueryDialog';
import CreateQueryMessageDialog from '../Queries/CreateQueryMessageDialog';
import EditDataDialog from '../Queries/EditDataDialog';
import ResolveQueryDialog from '../Queries/ResolveQueryDialog';

import FormResultsEditSection from './FormResultsQuerySection/FormResultsEditSection';
import FormResultsQuerySection from './FormResultsQuerySection/FormResultsQuerySection';
import FormResultsListQuestion from './FormResultsListQuestion';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import ImageElement from './ImageElement';
import FormResultsMedicationQuestion from './FormResultsMedicationQuestion';
import { PII_FIELDS } from '@curebase/core/lib/constants';

const StandardButtonWithPermission = withPermission()(StandardButton);

export type FormResultsData = {
  title: string;
  dataType?: string;
  values: Array<any>;
  hasError?: boolean;
  capturedData?: {
    authoredByUser?: any;
    capturedDataChanges?: Array<any>;
    id: number;
    queries?: Array<any>;
    submittedOn?: any;
  };
  dataField?: any;
  trialOptionId?: number;
};

type Props = {
  data: FormResultsData[];
  editable?: boolean;
  showAuditTrail?: boolean;
  history: any;
  location: any;
  refetch?: () => any;
  t: any;
};

type State = {
  resolveQueryId: string | null;
  addMessageToQueryId: string | null;
  capturedDataIdForEdit: number | null;
  capturedDataIdForQuery: number | null;
  scrolledToQuery: boolean;
  defaultEditReason: string;
};

class FormResults extends React.Component<Props, State> {
  state = {
    addMessageToQueryId: null,
    capturedDataIdForEdit: null,
    capturedDataIdForQuery: null,
    resolveQueryId: null,
    scrolledToQuery: false,
    defaultEditReason: '',
  };

  constructor(props) {
    super(props);
    // @ts-ignore
    this.scrollToMe = React.createRef();
  }

  autoScrollToQuery = () => {
    if (
      // @ts-ignore
      this.scrollToMe &&
      // @ts-ignore
      this.scrollToMe.current &&
      !this.state.scrolledToQuery
    ) {
      // @ts-ignore
      this.scrollToMe.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.setState({ scrolledToQuery: true });
    }
  };

  componentDidMount = () => {
    this.autoScrollToQuery();
  };

  componentDidUpdate = () => {
    this.autoScrollToQuery();
  };

  getUrlSpecifiedQueryId = () => {
    const searchParams = queryString.parse(this.props.location.search);
    const targetQuery = searchParams.queryId;
    return targetQuery;
  };

  onCreateCapturedDataQueryMessage = (queryId: string) =>
    this.setState({ addMessageToQueryId: queryId });

  onEditCapturedData = (capturedDataId: number, defaultReason?: string) =>
    this.setState({
      capturedDataIdForEdit: capturedDataId,
      defaultEditReason: defaultReason ? defaultReason : '',
    });

  onResolveCapturedDataQuery = (queryId: string) =>
    this.setState({ resolveQueryId: queryId });

  onCloseCreateEdit = () =>
    this.setState({ capturedDataIdForEdit: null, defaultEditReason: '' });

  onCloseCreateQuery = () => this.setState({ capturedDataIdForQuery: null });

  onCloseCreateQueryMessage = () =>
    this.setState({ addMessageToQueryId: null });

  onCloseResolveQuery = () => this.setState({ resolveQueryId: null });

  renderSidebarButtonsForUser = (
    capturedDataId: number,
    isActive: boolean,
    value: any,
    isSpecialField: boolean
  ) => {
    const { t } = this.props;

    if (!capturedDataId) {
      return;
    }
    return (
      <div
        className={`fr-data-button ${isActive ? 'fr-data-button-active' : ''}`}
      >
        {!isSpecialField && (
          <StandardButtonWithPermission
            text={t('queries.buttons.editDataField')}
            permission={RolePermissions.EditCapturedData}
            onClick={() => this.onEditCapturedData(capturedDataId)}
          />
        )}
        <StandardButtonWithPermission
          text={t('queries.buttons.createQuery')}
          permission={RolePermissions.CreateCapturedDataQuery}
          onClick={() =>
            this.setState({ capturedDataIdForQuery: capturedDataId })
          }
        />
      </div>
    );
  };

  renderDialogs = () => {
    const {
      capturedDataIdForQuery,
      capturedDataIdForEdit,
      addMessageToQueryId,
      resolveQueryId,
    } = this.state;

    return (
      <>
        {capturedDataIdForEdit && (
          <EditDataDialog
            initialReason={this.state.defaultEditReason}
            capturedDataId={capturedDataIdForEdit}
            caseReportCapturedData={this.props.data}
            onClose={this.onCloseCreateEdit}
            onSubmit={this.props.refetch}
          />
        )}
        <CreateQueryDialog
          capturedDataId={capturedDataIdForQuery}
          onClose={this.onCloseCreateQuery}
          onSubmit={() => this.props.history.push('/u/queries')}
        />
        <CreateQueryMessageDialog
          capturedDataQueryId={addMessageToQueryId}
          onClose={this.onCloseCreateQueryMessage}
          onSubmit={this.props.refetch}
        />
        <ResolveQueryDialog
          capturedDataQueryId={resolveQueryId}
          onClose={this.onCloseResolveQuery}
          onSubmit={this.props.refetch}
        />
      </>
    );
  };

  renderFileTypeElement(element) {
    const { values } = element;
    const files = values[0]; // nested array
    // @ts-ignore
    return <FilePreview files={files} disabled />;
  }

  renderFileUpload(element) {
    const { values } = element;
    const files = values?.[0] ?? []; // nested array

    return (
      <div className='file-preview-container'>
        {React.Children.toArray(
          _map(files, (elem, index: number) => {
            const imageURI = secureImageUriFromFileName(elem.filePath);
            return (
              <div className='telehealth-preview-container'>
                <ImageElement imageURI={imageURI} />
              </div>
            );
          })
        )}
      </div>
    );
  }

  renderDrugbankAutocomplete = element => {
    const value = element[0];

    const medications = value
      ? value.medications.filter(m => Object.keys(m).length > 0)
      : [];

    return (
      <FormResultsMedicationQuestion
        medications={medications}
        isOptingOut={value?.isOptingOut}
      />
    );
  };

  renderSignature = value => {
    // @ts-ignore
    const imageURI = secureImageUriFromFileName(value);
    return <ImageElement imageURI={imageURI} />;
  };

  renderAutoComplete = element => {
    const { t } = this.props;

    if (
      !element ||
      element === '' ||
      (Array.isArray(element) && element.length === 1 && element[0] === 'null')
    ) {
      return '(None)';
    }
    const { predefinedValues, freeResponse } = JSON.parse(element);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{`${t('formResults.autoComplete.userSelected')}: ${
          !!predefinedValues && predefinedValues.length > 0
            ? predefinedValues.join(', ')
            : `(${t('formResults.autoComplete.none')})`
        }`}</div>
        <div>{`${t('formResults.autoComplete.userProvided')}: ${
          !!freeResponse && freeResponse.length > 0
            ? freeResponse.join(', ')
            : `(${t('formResults.autoComplete.none')})`
        }`}</div>
      </div>
    );
  };

  renderFuzzyDate = values => {
    return <ReadOnlyFuzzyDate value={values?.length > 0 ? values[0] : null} />;
  };

  renderAddress = values => {
    const { t } = this.props;
    // https://curebase.atlassian.net/browse/APP-4085
    const { address1, address2, city, state, zipCode } = values[0] || {};
    const PLACEHOLDER = t('formResults.address.placeholder');
    return (
      <div>
        <div>
          <b>{t('formResults.address.address')}</b>
          <p>{address1 ?? PLACEHOLDER}</p>
        </div>
        <div>
          <b>{t('formResults.address.apartment')}</b>
          <p>{address2 ?? PLACEHOLDER}</p>
        </div>
        <div>
          <b>{t('formResults.address.city')}</b>
          <p>{city ?? PLACEHOLDER}</p>
        </div>
        <div>
          <b>{t('formResults.address.state')}</b>
          <p>{state ?? PLACEHOLDER}</p>
        </div>
        <div>
          <b>{t('formResults.address.zipCode')}</b>
          <p>{zipCode ?? PLACEHOLDER}</p>
        </div>
      </div>
    );
  };

  renderElement = element => {
    const { dataField, values } = element;

    if (values?.[0] === PII_FIELDS.USER_WITHOUT_PERMISSIONS_TO_SEE_ANSWER) {
      return this.props.t('piiMessages.USER_WITHOUT_PERMISSIONS_TO_SEE_ANSWER');
    }
    const basecase = values.join(', ');

    if (!dataField) return basecase;

    const { dataType, options } = dataField;

    if (dataType === CaseReportDataType.File) {
      return this.renderFileTypeElement(element);
    }

    if (dataType === CaseReportDataType.TelehealthFileUpload) {
      return this.renderFileUpload(element);
    }

    if (dataType === CaseReportDataType.List) {
      return <FormResultsListQuestion values={values} options={options} />;
    }

    if (dataType === CaseReportDataType.Autocomplete) {
      return this.renderAutoComplete(values);
    }

    if (dataType === CaseReportDataType.FuzzyDatePicker) {
      return this.renderFuzzyDate(values);
    }

    if (dataType === CaseReportDataType.DrugbankAutocomplete) {
      return this.renderDrugbankAutocomplete(values);
    }

    if (dataType === CaseReportDataType.Signature) {
      return this.renderSignature(values);
    }

    if (dataType === CaseReportDataType.Address) {
      return this.renderAddress(values);
    }

    return basecase;
  };

  render() {
    const { data, editable, showAuditTrail } = this.props;

    const urlSpecifiedQueryId = this.getUrlSpecifiedQueryId();

    const enableRowHighlighting =
      editable &&
      [
        RolePermissions.EditCapturedData,
        RolePermissions.CreateCapturedDataQuery,
      ].some(currentUserHasPermission);

    const classname = enableRowHighlighting
      ? 'fr-data-section fr-data-section-highlight'
      : 'fr-data-section';

    return (
      <div className='form-results'>
        {editable && this.renderDialogs()}
        {data.map((element, i) => {
          const { hasError, title, capturedData = {} } = element;

          const {
            id: capturedDataId,
            queries = [],
            capturedDataChanges = [],
            value,
          }: any = capturedData;

          const isUrlSpecifiedQuery =
            urlSpecifiedQueryId &&
            queries.map(q => q.id).includes(urlSpecifiedQueryId);

          const isActive = isUrlSpecifiedQuery;

          let editQueryContent = null;
          if (queries.length > 0) {
            editQueryContent = queries.map((q, index) => (
              <FormResultsQuerySection
                // @ts-ignore
                query={q}
                key={index}
                element={element}
                onResolveCapturedDataQuery={this.onResolveCapturedDataQuery}
                onEditCapturedData={this.onEditCapturedData}
                onCreateCapturedDataQueryMessage={
                  this.onCreateCapturedDataQueryMessage
                }
              />
            ));
          } else if (capturedDataChanges.length > 0) {
            // @ts-ignore
            editQueryContent = (
              <FormResultsEditSection
                key={i}
                // @ts-ignore
                element={element}
                readonly={showAuditTrail}
                onEditCapturedData={this.onEditCapturedData}
              />
            );
          }

          if (
            editQueryContent === null &&
            value === DataCaptureReserved.NotApplicableDueToDependency
          )
            return null;

          return (
            <React.Fragment key={i}>
              <div
                // @ts-ignore
                ref={isUrlSpecifiedQuery ? this.scrollToMe : null}
                className={`${classname} ${
                  isActive ? 'fr-data-section-active' : ''
                }`}
              >
                <div key={i} className={'fr-item'}>
                  <div
                    className={`fr-item-question ${
                      hasError ? 'status-color-red' : ''
                    }`}
                  >
                    <div
                      className='markdown'
                      dangerouslySetInnerHTML={{ __html: mdToHTML(title) }}
                    />
                  </div>
                  <div
                    className={`fr-item-value ${
                      hasError ? 'status-color-red' : ''
                    }`}
                  >
                    {this.renderElement(element)}
                  </div>
                </div>
                {editable &&
                  this.renderSidebarButtonsForUser(
                    capturedDataId,
                    isActive,
                    value,
                    element.dataField?.isSpecialField
                  )}
              </div>
              {(editable || showAuditTrail) && editQueryContent}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default withTranslation('translations')(withRouter(FormResults) as any);
