import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getLocaleFormatDates } from '../../context/localeContext';

const localesFormat = getLocaleFormatDates();
const TIME_FORMAT = localesFormat.moment.shortTimeMeridiem;

type TimeInputProps = {
  value: string;
  onChange: (value: string | null) => void;
  readOnly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
};

const parseValue = value => {
  if (value) return moment(value, TIME_FORMAT);
  return null;
};

const TimeInput = ({
  placeholder,
  value,
  onChange,
  readOnly,
  hasError,
}: TimeInputProps) => {
  const [valueDate, setValueDate] = useState(parseValue(value));
  const disabled = readOnly || false;
  const inputClassName = hasError ? 'with-error' : '';

  const handleChange = (valueDate: moment.Moment | null) => {
    if (valueDate && valueDate.isValid()) {
      return onChange(valueDate.format(TIME_FORMAT));
    }
    onChange(null);
  };

  useEffect(() => {
    setValueDate(parseValue(value));
  }, [value]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        value={valueDate}
        className={inputClassName}
        inputProps={{
          className: inputClassName,
        }}
        InputProps={{ disableUnderline: true }}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(date: unknown) => {
          if (!date) {
            setValueDate(null);
          } else {
            setValueDate(date as moment.Moment);
          }

          handleChange(date as moment.Moment);
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimeInput;
