import { Weekdays } from '@curebase/core/types';
import { DateTime } from 'luxon';

export default function enforcedAllowedWeekdays(
  date: DateTime,
  weekdays: readonly Weekdays[]
): DateTime {
  if (weekdays.length === 0) {
    return date;
  }

  //this is how luxon encodes weekdays, monday is 1, sunday is 7
  const WeekdayAsNumber: Record<Weekdays, number> = {
    [Weekdays.Mo]: 1,
    [Weekdays.Tu]: 2,
    [Weekdays.We]: 3,
    [Weekdays.Th]: 4,
    [Weekdays.Fr]: 5,
    [Weekdays.Sa]: 6,
    [Weekdays.Su]: 7,
  };

  //https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-get-weekday
  const currentWeekday = date.weekday;
  const allowedWeekdayNumbers = weekdays.map(wd => WeekdayAsNumber[wd]).sort();

  if (allowedWeekdayNumbers.includes(currentWeekday)) {
    return date;
  }

  const higherWeekdays = allowedWeekdayNumbers
    .filter(wd => wd > currentWeekday)
    .sort();

  if (higherWeekdays.length > 0) {
    return date.plus({ days: higherWeekdays[0] - currentWeekday });
  }

  // +(7 - currentWeekday) gets us to start of next week, then add allowedWeekdayNumbers[0]
  return date.plus({ days: 7 - currentWeekday + allowedWeekdayNumbers[0] });
}
