import { useTranslation } from 'react-i18next';
import { useScheduleVisitListItemQuery } from 'src/types';
import ActionCard from '../basic/ActionCard';
import { useHistory } from 'react-router-dom';
import { getViewPatientBaseUrl } from 'src/lib/users';
import { getLocale } from 'src/context/localeContext';
import { StudyActivity } from '@curebase/core/types';

interface ScheduleVisitActionCardProps {
  trialOptionId: number;
  activity: StudyActivity;
}

function ScheduleVisitActionCard(props: ScheduleVisitActionCardProps) {
  const { trialOptionId, activity } = props;
  const history = useHistory();
  const { locale } = getLocale();
  const { t } = useTranslation('translations');

  const { data } = useScheduleVisitListItemQuery({
    fetchPolicy: 'cache-first',
    variables: {
      schedulingConfigurationId: activity.config.configId!,
      trialOptionId: trialOptionId!,
    },
  });

  const visit =
    data?.getSchedulingConfiguration?.locales?.[locale] ??
    data?.getSchedulingConfiguration;

  const title = t('scheduleVisitActionCard.title', {
    name: visit?.name ?? t('scheduleVisitActionCard.titleDefault'),
  });
  const iconSrc = '/icons/actionCardCalendar.svg';
  const description = visit?.summary;
  const buttons = [
    {
      disabled: visit?.default?.disableParticipantScheduling ?? false,
      text: t('scheduleVisitActionCard.button'),
      onClick: () =>
        history.push(
          `${getViewPatientBaseUrl(trialOptionId)}/${
            activity.config.configId
          }/booker/schedule`
        ),
    },
  ];

  return (
    <ActionCard
      title={title}
      iconSrc={iconSrc}
      buttons={buttons}
      description={description}
    />
  );
}

export default ScheduleVisitActionCard;
