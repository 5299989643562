import React from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DynamicForm from '../basic/DynamicForm';
import StatusText from '../basic/StatusText';
import { activateUser } from '../../controllers/authController';
import { withQueryResult } from '../hocs/WithQueryResult';
import {
  SignupWithAccessCode,
  SignupWithAccessCodeQuery,
  SignupWithAccessCodeQueryVariables,
  InvitationDataType,
} from '@curebase/core/types';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';
import { attemptLogin } from '../../lib/auth';
import Loading from '../Loading';
import {
  AuthenticateUserResponseError,
  AuthenticateUserResponseSuccess,
} from '@curebase/core/decoders/users';
import PasswordRequirements from './PasswordRequirements';
import { calculateStrength, Strength } from 'src/lib/password';
import PasswordStrength from './PasswordStrength';
import { testPassword } from 'src/lib/constants';
import owasp from 'owasp-password-strength-test';

type Props = {
  history: any;
  match: any;
  t: any;
  queryResult: SignupWithAccessCodeQuery;
  data: any;
};

type State = {
  loading: boolean;
  errorMessage: string | null | undefined;
  currentFormPage: number;
  data: Record<string, any>;
};

class SignupWithAccessCodeView extends React.Component<Props, State> {
  state: State = {
    data: {},
    currentFormPage: 0,
    errorMessage: null,
    loading: true,
  };

  componentDidMount() {
    const foundAccessCode = this.props.queryResult.getAccessCode;
    let knownUserData = {};
    if (
      !foundAccessCode ||
      (foundAccessCode.createdUser && !foundAccessCode.createdUser.isStub) ||
      moment().diff(foundAccessCode.expiresOn) > 0
    ) {
      return this.props.history.push('/');
    }

    if (foundAccessCode.createdUser) {
      knownUserData = {
        firstName: foundAccessCode.createdUser.firstName,
        lastName: foundAccessCode.createdUser.lastName,
        phoneNumber: foundAccessCode.createdUser.phoneNumber,
      };
    }

    if (
      foundAccessCode.createdUser &&
      foundAccessCode.invitationData.type ===
        InvitationDataType.ProviderInitiatedPrescreening
    ) {
      return this.props.history.push(`/signup/${foundAccessCode.code}`);
    }

    this.setState({
      loading: false,
      data: {
        ...this.state.data,
        ...knownUserData,
      },
    });
  }

  async attemptSignup() {
    const { t, queryResult, match, history } = this.props;
    const { data } = this.state;

    try {
      let userRoleType = data.providerUserType;

      const foundAccessCode = queryResult.getAccessCode;

      if (
        foundAccessCode?.invitationData.type ===
        InvitationDataType.ProviderInvitation
      ) {
        //@ts-ignore
        userRoleType = foundAccessCode?.invitationData.userRoleType;
      }

      const activationResponse = await activateUser({
        providerUserType: userRoleType,
        accessCode: match.params.accessCode,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        timezone: moment.tz.guess(),
      });

      if (
        (activationResponse as AuthenticateUserResponseError)?.error ===
        'UNSAFE'
      ) {
        this.setState({ errorMessage: t('auth.signup.errors.UNSAFE') });
        return;
      } else if (
        !!(activationResponse as AuthenticateUserResponseError)?.error
      ) {
        // @ts-ignore
        this.setState({ errorMessage: activationResponse.error });
        return;
      }

      if (queryResult.getAccessCode?.email) {
        const loginResponse = await attemptLogin(
          queryResult.getAccessCode.email,
          data.password
        );
        if ((loginResponse as AuthenticateUserResponseSuccess)?.user) {
          return history.push('/');
        } else {
          this.setState({ errorMessage: t(`auth.signup.errors.SERVER_ERROR`) });
        }
      }
    } catch (e) {
      this.setState({ errorMessage: t(`auth.signup.errors.${e.message}`) });
    }
  }

  render() {
    const { t, queryResult } = this.props;
    const { errorMessage, currentFormPage, data, loading } = this.state;
    if (loading) return <Loading />;
    const foundAccessCode = queryResult.getAccessCode;
    const pages: any = [];

    if (
      !data.firstName ||
      !data.lastName ||
      foundAccessCode?.invitationData.type !== 'PROVIDER_INITIATED_PRESCREENING' //For providers, sponsors, we don't have the name on render, so we want to wait for them to fill it out.
    ) {
      pages.push({
        elements: [
          {
            title: 'participants.remoteSignupForm.firstName',
            subElements: [
              {
                type: 'TEXT',
                key: 'firstName',
                placeholder: 'Jane',
              },
            ],
          },
          {
            title: 'participants.remoteSignupForm.lastName',
            subElements: [
              {
                type: 'TEXT',
                key: 'lastName',
                placeholder: 'Doe',
              },
            ],
          },
        ],
      });
    }

    if (
      !data.phoneNumber ||
      foundAccessCode?.invitationData.type !== 'PROVIDER_INITIATED_PRESCREENING' //For providers, sponsors, we don't have the name on render, so we want to wait for them to fill it out.
    ) {
      pages.push({
        elements: [
          {
            title: 'Phone number',
            note:
              'Curebase needs your phone number to send you important clinical trial reminders.',
            subElements: [
              {
                type: 'PHONE_NUMBER',
                key: 'phoneNumber',
                placeholder: 'Phone number',
                noAutocomplete: true,
                skipValidateOnChange: true,
              },
            ],
          },
        ],
      });
    }

    pages.push({
      elements: [
        {
          title: 'Set your password',
          header: <PasswordRequirements password={this.state.data.password} />,
          footer: (
            <>
              <StatusText color='red' text={errorMessage || ''} />
              <PasswordStrength password={this.state.data.password} />
            </>
          ),
          subElements: [
            {
              type: 'NEW_PASSWORD',
              key: 'password',
              placeholder: 'Password',
              skipValidateOnChange: true,
              validate: (password: string) => {
                if (DEVELOPER_FEATURES_ENABLED && password === testPassword)
                  return;

                const passwordStrength = calculateStrength(password);
                const owaspTest = owasp.test(password);

                if (passwordStrength !== Strength.Strong) {
                  return t('createPasswordSignup.passwordError');
                } else if (owaspTest.errors.length > 0) {
                  const error = owaspTest.errors[0];
                  return error;
                }
              },
            },
          ],
        },
      ],
    });

    return (
      <>
        <div className='auth-right-header'>
          Activate your account
          <StatusText
            color='gray'
            text='Almost there! Set your login credentials to finish activating your account.'
          />
        </div>

        <DynamicForm
          // @ts-ignore
          pages={pages}
          currentPage={currentFormPage}
          onPageChange={(newPage: number) => {
            this.setState({
              currentFormPage: newPage,
              errorMessage: null,
            });
          }}
          onChange={(key, value) => {
            this.setState({
              data: {
                ...data,
                [key]: value,
              },
              errorMessage: null,
            });
          }}
          onSubmit={() => this.attemptSignup()}
          data={data}
          preferences={{ hidePersonalInformation: true }}
        />

        {DEVELOPER_FEATURES_ENABLED && (
          <div className='bottom-button-container'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                const placeHolderData = {
                  password: 'test',
                  firstName: 'dev-firstname',
                  lastName: 'dev-lastName',
                  phoneNumber: '+10000000000',
                };
                this.setState(
                  { data: Object.assign(placeHolderData, this.state.data) },
                  this.attemptSignup
                );
              }}
            >
              Complete Signup [DEVELOPMENT ONLY]
            </Button>
          </div>
        )}
      </>
    );
  }
}

const SignupWithAccessCodeComponent: React.ComponentType<any> = withQueryResult(
  SignupWithAccessCodeView,
  SignupWithAccessCode,
  (props: any): SignupWithAccessCodeQueryVariables => ({
    accessCode: props.match.params.accessCode,
  })
);

export default withTranslation('translations')(SignupWithAccessCodeComponent);
