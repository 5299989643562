import { VisitResults } from '@curebase/core/types/trialOption';
import { useTranslation } from 'react-i18next';
import { useImmutableState } from '../../hooks/useImmutableState';
import DialogHeader from '../basic/DialogHeader';
import DynamicForm from '../basic/DynamicForm';
import Dialog from '../basic/SafeDialog';

type FormData = Readonly<{
  backFillCreatedAt: string;
}>;

interface Props {
  visit?: VisitResults;
  onClose: () => void;
  onSubmit: (data: FormData) => Promise<void>;
}

export const BackFillDialog = (props: Props) => {
  const { t } = useTranslation('translations');
  const { visit, onClose, onSubmit } = props;

  const [state, setState]: [FormData, any] = useImmutableState<any>({});

  const elements: any[] = [
    {
      title: t('dataCaptureResultsView.backFillDialog.backFillCreatedAtField'),
      subElements: [
        {
          key: 'backFillCreatedAt',
          type: 'DATETIME',
          rawValue: true,
          optional: false,
        },
      ],
    },
  ];

  return (
    <Dialog className='themed-dialog' open={!!visit} onClose={onClose}>
      <DialogHeader
        title={t('dataCaptureResultsView.backFillDialog.title')}
        subtitle={t('dataCaptureResultsView.backFillDialog.subtitle')}
        onClose={onClose}
      />

      <DynamicForm
        // @ts-ignore
        pages={[{ elements }]}
        onChange={(key, value) => setState({ [key]: value })}
        data={state}
        onSubmit={() => onSubmit(state)}
        submitText={t('dataCaptureResultsView.backFillDialog.submit')}
      />
    </Dialog>
  );
};
