import * as React from 'react';
import { withTranslation } from 'react-i18next';

import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import { withStyles } from '@material-ui/core';

import { RolePermissions } from '@curebase/core/types';
import { getChangeList } from '../../../lib/caseReports';
import withPermission from '../../hocs/WithPermission';

import StandardButton from '../StandardButton';
import DataEditSection from './DataEditSection';

const StandardButtonWithPermission = withPermission()(StandardButton);

export type FormResultsData = {
  title: string;
  dataType: string;
  values: string[];
  hasError?: boolean;
  capturedDataId?: number;
  capturedDataChanges?: Array<any>;
  editReason?: string;
  authoredByUser: any;
  submittedOn: any;
  dataField?: any;
};

type FormElement = {
  capturedData: any;
  dataField: any;
  values: Array<any>;
};

type Props = {
  t: any;
  readonly?: boolean;
  query: any;
  element: FormElement;
  onEditCapturedData: (capturedDataId: number, editReason?: string) => any;
};

const MassiveArrow = withStyles({
  root: {
    fontSize: 60,
    paddingLeft: 20,
  },
})(SubdirectoryArrowRightIcon);

class FormResultsEditSection extends React.Component<Props> {
  render() {
    const { t, element, readonly } = this.props;
    const { capturedData } = element;
    const { id: capturedDataId, dataField } = capturedData;
    const changeList = getChangeList(element);

    return (
      <div className='fr-query-container'>
        <MassiveArrow />
        <div className='fr-query-section fr-query-section-open'>
          <h3>Data Edited</h3>
          {changeList.map((element, i) => (
            <DataEditSection dataField={dataField} change={element} key={i} />
          ))}
          {!readonly && (
            <div className='fr-query-buttons'>
              <StandardButtonWithPermission
                text={t('queries.buttons.editDataField')}
                key='editDataField'
                permission={RolePermissions.EditCapturedData}
                onClick={() => this.props.onEditCapturedData(capturedDataId)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(FormResultsEditSection as any);
