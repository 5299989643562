import React from 'react';

export type TelemedIndicator = {
  value: boolean;
  update: (isMounted: boolean) => void;
};

const context = React.createContext<TelemedIndicator>({
  value: false,
  update: (isMounted: boolean) => {},
});

export default context;
