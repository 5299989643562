import queryString from 'query-string';
import { Maybe } from '@curebase/core/types';
import { baseUrl, IS_SANDBOX, IS_PRODUCTION } from '@curebase/core/lib/env';

export const ACUITY_SCHEDULING_URL_BASE =
  'https://app.acuityscheduling.com/schedule.php';

type AcuityEnvironment = 'PRODUCTION' | 'SANDBOX' | 'TESTING';

const environment: AcuityEnvironment = IS_PRODUCTION
  ? 'PRODUCTION'
  : IS_SANDBOX
  ? 'SANDBOX'
  : 'TESTING';

type IdRecord = Record<AcuityEnvironment, number>;

type CustomFields = {
  trialOptionId: number;
  trialName: number;
  subjectIdentifier: number;
  curebaseUrl: number;
  schedulingConfigurationId: number;
};

type CustomFieldIdsRecord = Record<AcuityEnvironment, CustomFields>;

const ownerIds: IdRecord = {
  PRODUCTION: 18950170,
  SANDBOX: 19093237,
  TESTING: 22008038,
};

export const ACUITY_SCHEDULING_URL_BASE_PARAMS = {
  owner: ownerIds[environment],
};

const customFieldIdsRecord: CustomFieldIdsRecord = {
  PRODUCTION: {
    trialOptionId: 7284464,
    trialName: 7284468,
    subjectIdentifier: 7284471,
    curebaseUrl: 7284472,
    schedulingConfigurationId: 8917619,
  },
  SANDBOX: {
    trialOptionId: 7283318,
    trialName: 7283432,
    subjectIdentifier: 7283434,
    curebaseUrl: 7283435,
    schedulingConfigurationId: 8883290,
  },
  TESTING: {
    trialOptionId: 9243459,
    trialName: 9243460,
    subjectIdentifier: 9243461,
    curebaseUrl: 9243462,
    schedulingConfigurationId: 9243463,
  },
};

export const ACUITY_CUSTOM_FIELDS = customFieldIdsRecord[environment];

const formIds: IdRecord = {
  PRODUCTION: 1327913,
  SANDBOX: 1327181,
  TESTING: 1671664,
};

export const ACUITY_CUSTOM_FIELDS_FORM_ID = formIds[environment];

type UrlSchedulerBuildOptions = {
  trialOptionId: number;
  subjectIdentifier: string;
  trialName: string;
  appointmentType: number;
  schedulingConfigurationId: string;
  user: {
    firstName: Maybe<string>;
    lastName: Maybe<string>;
    phoneNumber: Maybe<string>;
    email: Maybe<string>;
  };
};

export function buildSchedulerUrl(options: UrlSchedulerBuildOptions): string {
  const {
    appointmentType,
    schedulingConfigurationId,
    subjectIdentifier,
    trialName,
    trialOptionId,
    user,
  } = options;
  const { firstName, lastName, phoneNumber, email } = user;
  const url = ACUITY_SCHEDULING_URL_BASE;
  // The Acuity Test Account either is hard wired to map to staging, never localhost or sandbox
  const curebaseUrl = `${baseUrl}/${trialOptionId}`;
  const params = {
    ...ACUITY_SCHEDULING_URL_BASE_PARAMS,
    appointmentType,
    firstName,
    lastName,
    phone: phoneNumber,
    email,
    [`field:${ACUITY_CUSTOM_FIELDS['trialOptionId']}`]: trialOptionId,
    [`field:${ACUITY_CUSTOM_FIELDS['trialName']}`]: trialName,
    [`field:${ACUITY_CUSTOM_FIELDS['subjectIdentifier']}`]: subjectIdentifier,
    [`field:${ACUITY_CUSTOM_FIELDS['curebaseUrl']}`]: curebaseUrl,
    [`field:${ACUITY_CUSTOM_FIELDS['schedulingConfigurationId']}`]: schedulingConfigurationId,
  };
  const paramString = queryString.stringify(params);
  return `${url}?${paramString}`;
}
