import * as React from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';

import TextInput from '../basic/TextInput';
import StandardButton from '../basic/StandardButton';
import StatusText from '../basic/StatusText';
import { requestSignInMagicLink } from '../../controllers/remoteAccessController';
import useInterval from '../../hooks/useInterval';

interface Props extends WithTranslation {}

type SubmitState = {
  errorMessage: string | null;
  success: boolean;
};

const LoginMagicLink = (props: Props): React.ReactElement => {
  const [countDown, setCountdown] = React.useState(0);
  const [enteredEmail, setEmail] = React.useState('');
  const [submitState, setSubmitState] = React.useState<SubmitState>({
    errorMessage: null,
    success: false,
  });
  useInterval(() => setCountdown(countDown - 1), countDown > 0 ? 1000 : null);
  const { t } = useTranslation('translations');

  const onSubmit = async () => {
    if (countDown > 0) return;
    if (enteredEmail === '') {
      setSubmitState({
        errorMessage: t('auth.forgotPassword.emailEmpty'),
        success: false,
      });
      return;
    }

    const response: any = await requestSignInMagicLink({
      deliveryType: 'email',
      email: enteredEmail,
    });
    const errorMessage = response.error ? t('auth.login.errors.STUB') : null;

    setSubmitState({ success: !response.error, errorMessage });
    if (!response.error) setCountdown(20);
  };

  return (
    <>
      <div className='auth-right-header'>
        {t('auth.loginMagicLink.title')}

        <StatusText color='red' text={submitState.errorMessage || ''} />
        {submitState.success ? (
          <StatusText color='green' text={t('auth.loginMagicLink.success')} />
        ) : (
          <StatusText
            color='gray'
            text={t('auth.loginMagicLink.instructions')}
          />
        )}
      </div>

      <div className='auth-right-body'>
        <TextInput
          placeholder={t('auth.loginMagicLink.emailPlaceholder')}
          onChange={v => setEmail(v)}
          specialType='email'
          value={enteredEmail}
          noSpellcheck={true}
          onEnter={onSubmit}
          hidePersonalInformation
        />

        <StandardButton
          className='button'
          disabled={countDown > 0}
          fullWidth
          onClick={onSubmit}
          variant='contained'
          size='xlarge'
        >
          {countDown === 0
            ? t('auth.loginMagicLink.submitEmail')
            : `Sent. Try again in ${countDown}`}
        </StandardButton>
      </div>
    </>
  );
};

export default LoginMagicLink;
