import i18n from 'i18next';
import { IS_DEVELOPMENT, IS_DOCKER_ENV } from '@curebase/core/lib/env';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getLocale } from './context/localeContext';

const { language = 'en', country = 'us' } = getLocale() || {};

const loadPath = `/api/locales/translations?language={{lng}}&country=${country}`;
const developmentPath = `/locales/translations?language={{lng}}&country=${country}`;

const backend = {
  loadPath: IS_DEVELOPMENT && !IS_DOCKER_ENV ? developmentPath : loadPath,
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: language,
    preload: ['en', 'es', 'fr'],
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: { escapeValue: false }, // not needed for react!!
    react: { useSuspense: false },
    returnObjects: true,
    backend,
  });

export default i18n;
