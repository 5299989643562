import { NewUnscheduledActivityRequest } from '@curebase/core/decoders/unscheduledActivitiy';
import { jsonPost } from '../lib/fetchHelpers';

export async function newUnscheduledActivity(params: {
  trialOptionId: number;
  studyPlanVisitSlug?: string;
  type: string;
  schedulingConfigurationId?: string;
}): Promise<{ success: boolean }> {
  const requestBody: NewUnscheduledActivityRequest = {
    trialOptionId: params.trialOptionId,
    studyPlanVisitSlug: params.studyPlanVisitSlug,
    type: params.type,
    schedulingConfigurationId: params.schedulingConfigurationId,
  };
  const response: { success: boolean; error: string } = await jsonPost(
    '/unscheduledActivity/new',
    requestBody
  );

  if (!response.success) {
    throw new Error(response.error);
  }

  return response;
}
