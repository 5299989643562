import React from 'react';
import { withTranslation } from 'react-i18next';

import Dialog from '../basic/SafeDialog';
import DialogHeader from '../basic/DialogHeader';
import DynamicForm from '../basic/DynamicForm';
import ListItem from '../basic/ListItem';
import SignatureCaptureInDialog from '../basic/SignatureCaptureInDialog';
import {
  reverseEnrollment,
  submitEnrollment,
} from '../../controllers/enrollmentController';
import { currentUserHasPermission, currentUserHasRole } from '../../lib/auth';
import {
  RolePermissions,
  StudyActivity,
  StudyActivityStatus,
  TrialOption,
  UserRoleType,
} from '@curebase/core/types';
import ConfirmationDialog from '../basic/ConfirmationInDialog';
import { Button } from '@material-ui/core';
import { onDialogOpen } from '../../utils/mobileHelpers';

const { Passed, Active } = StudyActivityStatus;

type Props = {
  activity: StudyActivity;
  refetchData: () => any;
  t: any;
  trialOption: TrialOption;
};

type State = {
  showEnrollmentDialog: boolean;
  showSignatureDialog: boolean;
  willEnroll: string | null;
  reverseConfirmationDialogOpen: boolean;
  reversePending: boolean;
};

class EnrollmentListItem extends React.Component<Props, State> {
  state = {
    showEnrollmentDialog: false,
    showSignatureDialog: false,
    willEnroll: null,
    reverseConfirmationDialogOpen: false,
    reversePending: false,
  };

  enrollAndRandomize = async (signatureData?: any) => {
    const { trialOption } = this.props;
    await submitEnrollment(trialOption.id, true, signatureData);
    this.props.refetchData();
    this.setState({ showSignatureDialog: false });
  };

  renderEnrollmentDialog = () => {
    const { trialOption }: any = this.props;
    const patientName = trialOption.patient.user.firstName || 'the participant';

    return (
      <>
        <DialogHeader
          title='Enroll'
          onClose={() => {
            this.setState({ showEnrollmentDialog: false });
          }}
        />

        <DynamicForm
          // @ts-ignore
          pages={[
            {
              elements: [
                {
                  title: `To answer this question, you must have reviewed ${patientName}'s screening data. You may then indicate whether or not to enroll ${patientName}.`,
                  subElements: [
                    {
                      type: 'MULTISELECT',
                      key: 'willEnroll',
                      options: [
                        {
                          text: `Yes, I am enrolling the participant in ${trialOption.trial.name}`,
                          value: 'yes',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ]}
          onChange={(key, value) => {
            if (value === 'yes') {
              this.setState({ willEnroll: 'yes' });
            } else {
              this.setState({ willEnroll: 'no' });
            }
          }}
          data={{ willEnroll: this.state.willEnroll }}
          onSubmit={(data: any) => {
            this.setState({ showEnrollmentDialog: false }, () => {
              if (data.willEnroll === 'yes') {
                if (trialOption.trial.skipEnrollmentSignature) {
                  this.enrollAndRandomize();
                } else {
                  this.setState({ showSignatureDialog: true });
                }
              }
            });
          }}
        />
      </>
    );
  };

  renderSignatureDialog = () => {
    const { t } = this.props;
    return (
      // @ts-ignore
      <SignatureCaptureInDialog
        reason={t('signatureDialog.reason')}
        onSubmit={(data: Object) => this.enrollAndRandomize(data)}
        onClose={() => {
          this.setState({ showSignatureDialog: false });
        }}
      />
    );
  };

  showEnrollmentDialog = () => {
    this.setState({ showEnrollmentDialog: true });
  };

  currentUserCanEnrollParticipant = () => {
    const { trial } = this.props.trialOption;
    const rolesTypes = trial.allowedRolesToEnroll || [];

    if (rolesTypes.length > 0) {
      return rolesTypes.some((roleType: UserRoleType) =>
        currentUserHasRole(roleType)
      );
    }

    return currentUserHasPermission(RolePermissions.EnrollParticipant);
  };

  render() {
    const { t, trialOption, activity } = this.props;
    const isAlreadyEnrolled =
      trialOption.enrollmentDate || activity.status === Passed;
    const hasStepStatus = activity.status === Active;

    const canEnroll = this.currentUserCanEnrollParticipant();

    let onClick;
    let statusInfo;
    if (isAlreadyEnrolled) {
      statusInfo = { type: 'COMPLETED' };
    } else if (hasStepStatus) {
      statusInfo = { type: 'AVAILABLE' };
      onClick = canEnroll ? this.showEnrollmentDialog : null;
    } else {
      statusInfo = { type: 'BLANK' };
    }

    return (
      <>
        <Dialog
          open={this.state.reverseConfirmationDialogOpen}
          onClose={() =>
            this.setState({ reverseConfirmationDialogOpen: false })
          }
        >
          <ConfirmationDialog
            title='Confirm reversing enrollment'
            message='Are you sure you would like to un-enroll this participant?'
            onClose={() =>
              this.setState({ reverseConfirmationDialogOpen: false })
            }
            disableOnConfirm={this.state.reversePending}
            onConfirm={async () => {
              this.setState({ reversePending: true }, async () => {
                try {
                  await reverseEnrollment(trialOption.id);
                  await this.props.refetchData();
                } catch {}
                this.setState({
                  reversePending: false,
                  reverseConfirmationDialogOpen: false,
                });
              });
            }}
          />
        </Dialog>
        <ListItem
          // @ts-ignore
          status={statusInfo}
          middle={{
            title: t('enrollmentListItem.title'),
            text: t('trialOptionStudyPlan.enrollment.reviewDetailedScreening'),
            buttons: [
              statusInfo.type === 'COMPLETED' && canEnroll && (
                <Button
                  color='secondary'
                  onClick={() =>
                    this.setState({ reverseConfirmationDialogOpen: true })
                  }
                  variant='contained'
                  disabled={false}
                  disableElevation={true}
                >
                  Reverse Enrollment
                </Button>
              ),
            ],
          }}
          onClick={onClick}
        />

        <Dialog
          open={this.state.showEnrollmentDialog}
          onClose={() => {
            this.setState({ showEnrollmentDialog: false });
          }}
        >
          {this.renderEnrollmentDialog()}
        </Dialog>

        <Dialog
          maxWidth={false}
          open={onDialogOpen(this.state.showSignatureDialog)}
          onClose={() => {
            this.setState({ showSignatureDialog: false });
          }}
        >
          {this.renderSignatureDialog()}
        </Dialog>
      </>
    );
  }
}

export default withTranslation('translations')(EnrollmentListItem as any);
