import * as React from 'react';

import usePermission from '../../hooks/usePermission';
import { RolePermissions } from '@curebase/core/types';

type WithPermissionOptions = {
  allowNullPermission?: boolean;
  permission?: RolePermissions | null;
};

// This is a higher order function, that accepts some options
// if you provide it a required permission while invoking the HOC
// it will require the user have it.
// if you do not provide a required permission, it will expect
// the component to have a prop `permission`.
function withPermission(options?: WithPermissionOptions) {
  return (Wrapped: React.ComponentType<any>) => (props: any) => {
    const { permission: componentPropPermission } = props;

    const permissionToEvaluate = componentPropPermission
      ? componentPropPermission
      : options && options.permission;

    const noPermissionButThatsOkay =
      options && options.allowNullPermission && !permissionToEvaluate;
    if (
      noPermissionButThatsOkay ||
      (permissionToEvaluate && usePermission(permissionToEvaluate))
    ) {
      return <Wrapped {...props} />;
    } else {
      return null;
    }
  };
}

export default withPermission;
