import React, { useState } from 'react';
import Header from '../basic/Header';

import ClinicIntegrationsEditor from '../basic/ClinicIntegrationsEditor/ClinicIntegrationsEditor';
import ClinicConfigurations from './ClinicConfigurations';
import InvestigatorList from '../Settings/InvestigatorList';
import ResearchSiteList from '../Settings/ResearchSiteList';
import { currentUserHasPermission } from '../../lib/auth';
import { RolePermissions } from '@curebase/core/types';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useAssociateClinicQuery } from 'src/types';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';

const TAB_CONFIGURATION = 'TAB_CONFIGURATION';
const TAB_PROVIDERS = 'TAB_PROVIDERS';
const TAB_ENROLLMENT_CENTERS = 'TAB_ENROLLMENT_CENTERS';
const TAB_ATLAS_CONFIG = 'TAB_ATLAS_CONFIG';

type Tab =
  | typeof TAB_CONFIGURATION
  | typeof TAB_PROVIDERS
  | typeof TAB_ENROLLMENT_CENTERS
  | typeof TAB_ATLAS_CONFIG;

const AssociateClinic = () => {
  const [tab, selectTab] = useState<Tab>(TAB_CONFIGURATION);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('translations');
  const { clinicId }: { clinicId: string } = useParams();
  const match = useRouteMatch();

  const { data, loading } = useAssociateClinicQuery({
    variables: { clinicId },
  });

  if (!data || loading) {
    return <Loading />;
  }

  const config = data.getSiteManagementConfig;
  const clinic = config.clinic;
  const name = clinic?.name ?? config.name;
  const clinicDeployedInCurrentEnv = !!clinic;

  const canEditInvestigatorList = currentUserHasPermission(
    RolePermissions.EditInvestigatorList
  );

  const tabs: any = {
    items: [
      {
        text: t('associateClinic.tabs.configuration'),
        value: TAB_CONFIGURATION,
      },
      canEditInvestigatorList &&
        clinicDeployedInCurrentEnv && {
          text: t('associateClinic.tabs.providers'),
          value: TAB_PROVIDERS,
        },
      clinicDeployedInCurrentEnv && {
        text: t('associateClinic.tabs.enrollmentCenters'),
        value: TAB_ENROLLMENT_CENTERS,
      },
      clinicDeployedInCurrentEnv && {
        text: t('associateClinic.tabs.atlasConfig'),
        value: TAB_ATLAS_CONFIG,
      },
    ].filter(Boolean),
    selected: tab,
    onChange: selectTab,
  };

  const showConfiguration = tab === TAB_CONFIGURATION;
  const showProviders = tab === TAB_PROVIDERS;
  const showEnrollmentCenters = tab === TAB_ENROLLMENT_CENTERS;
  const showAtlasConfig = tab === TAB_ATLAS_CONFIG;

  return (
    <>
      <Header
        tabs={tabs}
        displayText={name}
        back={() => history.push('/u/clinic')}
      />
      {showConfiguration && <ClinicConfigurations clinicId={clinicId} />}
      {showEnrollmentCenters && (
        <ResearchSiteList
          history={history}
          location={location}
          match={match}
          subHeaderText={t('associateClinic.subHeaderText')}
        />
      )}
      {showProviders && canEditInvestigatorList && (
        <InvestigatorList clinicId={clinicId} />
      )}
      {showAtlasConfig && <ClinicIntegrationsEditor clinicId={clinicId} />}
    </>
  );
};

export default AssociateClinic;
