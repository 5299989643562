import produce from 'immer';
import { Action } from '../actions';
import {
  SET_SELECTED_CLINIC_ID,
  SET_SELECTED_PATIENT_ID,
  SET_SELECTED_TRIALOPTION_ID,
} from '../actions/navigation';

export type TNavigationState = {
  clinicId?: string;
  patientId?: number;
  trialOptionId?: number;
};

export default (state: TNavigationState = {}, action: Action) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case SET_SELECTED_CLINIC_ID:
        if (action.payload) draft.clinicId = action.payload;
        else delete draft.clinicId;
        return;
      case SET_SELECTED_PATIENT_ID:
        if (action.payload) draft.patientId = action.payload;
        else delete draft.patientId;
        return;
      case SET_SELECTED_TRIALOPTION_ID:
        if (action.payload) draft.trialOptionId = action.payload;
        else delete draft.trialOptionId;
        return;
      default:
        return;
    }
  });
