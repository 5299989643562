import { RolePermissions } from '../../types';

export const clinicianPermissions = [
  RolePermissions.ViewPatientPayments,
  RolePermissions.AddTrialOptionToUser,
  RolePermissions.CanWriteDocumentNotes,
  RolePermissions.CanUnlockTelehealthFileUpload,
  RolePermissions.ScheduleVisit,
  RolePermissions.EnrollParticipant,
  RolePermissions.WithdrawParticipant,
  RolePermissions.EditTrialOptionDetails,
  RolePermissions.SetLoginPinCode,
  RolePermissions.ScreenNewParticipant,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewCrf,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.CreateProtocolDeviations,
  RolePermissions.ViewPii,
  RolePermissions.ViewScheduling,
  RolePermissions.EditScheduling,
  RolePermissions.ViewProviderPayments,
  RolePermissions.ViewClinicSettings,
  RolePermissions.ViewClinicTrials,
  RolePermissions.ViewAdverseEvents,
  RolePermissions.ReportEvent,
  RolePermissions.SubmitInitialDataCapture,
  RolePermissions.ViewInformedConsent,
  RolePermissions.SignInformedConsent,
  RolePermissions.CanProvideBothSignatures,
  RolePermissions.CanProvideCounterSignature, // Data Capture Edits
  RolePermissions.EditCapturedData,
  RolePermissions.CreateCapturedDataQueryMessage,
  RolePermissions.ReadCapturedDataEditQueryMessage, // Create stub participants
  RolePermissions.CreateStubParticipant, // Create stub participants and SkipExternalPrescreening
  RolePermissions.SkipExternalPrescreening, // Referral Sources
  RolePermissions.GenerateReferralSources, // Editing Visit Sites
  RolePermissions.EditVisitSite, // View the list of clinic memberships and their users
  RolePermissions.ViewInvestigatorList, // Allow Group Assignment
  RolePermissions.RandomizeParticipants,
  // View PPT tag referral source
  RolePermissions.ViewParticipantReferralSource,
  //Can see PPTs group assignment if study is single blinded
  RolePermissions.ViewSingledBlindedness,
  // Needed for column level auth on clinics
  RolePermissions.ViewClinicAtlasConfig,
  RolePermissions.ViewSettings,
  RolePermissions.CreateParticipantNote,
  RolePermissions.ViewParticipantNote,
  RolePermissions.DownloadInformedConsent,
  RolePermissions.AddUnscheduledActivity,
  RolePermissions.CreateVisitExtension,
  RolePermissions.EditUserDetails,
  RolePermissions.BackfillDataCapture,
];
