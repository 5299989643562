import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Loading from '../Loading';
import { useViewDocumentPreviewQuery } from 'src/types';
type Props = {
  trialOption?: any;
  documentType?: string;
  documentVersion?: string | null;
};

export const ViewSignedDocumentDevelopment = (props: Props) => {
  const [htmlToHang, setHtmlToHang] = useState<null | string>(null);
  const { trialOption, documentVersion } = props;
  let { documentType } = useParams<{ documentType: string | undefined }>();
  if (!documentType) documentType = props.documentType;
  const { data, loading } = useViewDocumentPreviewQuery({
    variables: {
      trialOptionId: parseInt(trialOption.id),
      documentType: documentType || '',
      documentVersion: documentVersion || '',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.getSignedDocumentPreview?.documentCss && !loading) {
      const { documentCss, htmlToHang } = data?.getSignedDocumentPreview;
      const $style = document.createElement('style');
      document.head.appendChild($style);
      $style.innerHTML = documentCss;
      setHtmlToHang(htmlToHang || null);
    }
  }, [data, loading]);

  if (!htmlToHang) return <Loading />;

  return <div dangerouslySetInnerHTML={{ __html: htmlToHang }} />;
};
