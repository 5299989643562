import { Button, TextField } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import { formatNotesTime } from '../../utils/dates';
import { capitalizeDisplayName } from '../../lib/ui';
import {
  MutationAddSignedDocumentQuestionAnswerArgs,
  SignedDocumentQuestionAnswers,
} from '@curebase/core/types';
import {
  useAddConsentNoteMutation,
  useGetConsentNotesQuery,
} from '../../types';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';

type ConsentNotesForm = {
  question: string;
  answer: string;
  errors: string[];
};

interface ConsentNotesProps {
  trialOptionId: number;
  signedDocumentId: number;
}

function ConsentNotes(props: ConsentNotesProps): React.ReactElement {
  const { trialOptionId, signedDocumentId } = props;
  const initialFormValues: ConsentNotesForm = {
    question: '',
    answer: '',
    errors: [],
  };

  const [form, setForm] = useState<ConsentNotesForm>(initialFormValues);

  const {
    data,
    loading: getConsentNotesLoading,
    refetch: refetchGetConsentNotes,
  } = useGetConsentNotesQuery({
    variables: {
      signedDocumentId,
    },
  });

  const [
    addConsentNote,
    { loading: addConsentNoteLoading },
  ] = useAddConsentNoteMutation();

  const consentNotes = data?.getSignedDocumentQuestionAnswers ?? [];

  function validate(): boolean {
    let hasError: boolean = false;

    const errors: string[] = [...form.errors];

    for (const key in form) {
      const value: string = form[key];
      if (!value) {
        errors.push(`${key} field cannot be empty`);
        hasError = true;
      }
    }

    setForm(form => ({ ...form, errors }));

    return hasError;
  }

  function onChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const { target } = event;
    const { name, value } = target;

    setForm(form => ({ ...form, [name]: value, errors: [] }));
  }

  async function onSubmit(): Promise<void> {
    const hasError: boolean = validate();
    if (hasError) return;

    const { question, answer } = form;
    const variables: MutationAddSignedDocumentQuestionAnswerArgs = {
      input: { trialOptionId, signedDocumentId, question, answer },
    };

    await addConsentNote({ variables });
    await refetchGetConsentNotes();
    setForm(initialFormValues);
  }

  return (
    <div className='consent-notes'>
      <span className='cn-header'>Participant questions</span>
      <ConsentNoteForm
        form={form}
        onChange={onChange}
        onSubmit={onSubmit}
        loading={addConsentNoteLoading}
      />
      <ConsentNoteList
        list={consentNotes as SignedDocumentQuestionAnswers[]}
        loading={getConsentNotesLoading}
      />
    </div>
  );
}

interface ConsentNoteFormProps {
  form: ConsentNotesForm;
  loading: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onSubmit: () => Promise<void>;
}

function ConsentNoteForm(props: ConsentNoteFormProps): React.ReactElement {
  const { form, loading, onChange, onSubmit } = props;
  const { t } = useTranslation('translations');

  return (
    <div className='cn-form'>
      <span className='cn-label'>
        {t('consentNotes.form.whatParticipantAsk')}
      </span>
      <TextField
        multiline
        rowsMax='4'
        value={form.question}
        name='question'
        className='cn-input'
        variant='outlined'
        onChange={onChange}
      />

      <span className='cn-label'>{t('consentNotes.form.howDidAnswer')}</span>
      <TextField
        multiline
        rowsMax='4'
        value={form.answer}
        name='answer'
        className='cn-input'
        variant='outlined'
        onChange={onChange}
      />
      {React.Children.toArray(
        form.errors.map(
          (error: string): React.ReactNode => (
            <span className='cn-error-text'>{error}</span>
          )
        )
      )}
      <Button
        className='cn-submit'
        color='primary'
        size='small'
        variant='contained'
        onClick={onSubmit}
      >
        {loading ? t('common.submitting') : t('common.submit')}
      </Button>
    </div>
  );
}

interface ConsentNoteListProps {
  list: SignedDocumentQuestionAnswers[];
  loading: boolean;
}

function ConsentNoteList(props: ConsentNoteListProps): React.ReactElement {
  const { t } = useTranslation('translations');
  const { list, loading } = props;
  const totalQuestions: number = list.length;

  function renderList(
    item: SignedDocumentQuestionAnswers,
    index: number
  ): React.ReactNode {
    const { question, answer, askedByUser, submittedByUser, createdAt } = item;
    const time = formatNotesTime(createdAt);

    return (
      <div className='cn-list-item'>
        <span className='cn-item-number'>
          Question {index + 1} of {totalQuestions}
        </span>
        <span className='cn-item-time'>{time}</span>
        <span className='cn-item-label'>
          {capitalizeDisplayName(askedByUser, t)}{' '}
          {t('consentNotes.noteList.askedLabel')}
        </span>
        <p className='cn-item-text'>{question}</p>
        <span className='cn-item-label'>
          {capitalizeDisplayName(submittedByUser, t)}{' '}
          {t('consentNotes.noteList.answeredLabel')}
        </span>
        <p className='cn-item-text'>{answer}</p>
      </div>
    );
  }

  let content: React.ReactNode = React.Children.toArray(
    _sortBy(list, 'createdAt').map(renderList)
  );

  if (loading) {
    content = <Loading />;
  }

  return (
    <div className='cn-list'>
      <div className='cn-content-list'>{content}</div>
    </div>
  );
}

export default ConsentNotes;
