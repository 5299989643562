import { DateTime } from 'luxon';
import { getLocale, getLocaleFormatDates } from 'src/context/localeContext';
import i18n from '../i18n';
const { locale } = getLocale();
const localeFormats = getLocaleFormatDates();

export function formatNotesTime(dateToParse: Date | string): string {
  const luxonDate = DateTime.fromISO(dateToParse.toString());
  const time = luxonDate.toFormat(localeFormats.luxon.shortTime, { locale });
  const date = luxonDate.toFormat(localeFormats.luxon.longDate, { locale });
  return i18n.t('formatNotes.text', { time, date });
}
