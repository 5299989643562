import { TrialOption } from '@curebase/core/types';
import { getTrialOptionDisplayStatus } from '../../shared/lib/modelHelpers';
import { BaseTag } from './ParticipantTags';

type Props = {
  participant: TrialOption;
  accountDisabledOn?: boolean | null;
};

export const EpochStatusTag = props => {
  const epoch = props.trialOption?.epoch;
  const StatusTag = (props: Props) => {
    const { participant } = props;
    const { status } = participant;

    const tagText = `${getTrialOptionDisplayStatus(status)}`;
    return <BaseTag text={tagText} classes={['subtag']} />;
  };

  return epoch ? (
    <BaseTag text={epoch} classes={['subtag']} />
  ) : (
    <StatusTag participant={props.trialOption} />
  );
};
