import React from 'react';
import { DateTime } from 'luxon';
import Header from '../basic/Header';
import SiteAvailability from '../Settings/SiteAvailability';
import { currentUserHasPermission } from '../../lib/auth';
import { RolePermissions } from '@curebase/core/types';
import { useAssociateSchedulingQuery } from 'src/types';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';

const AssociateScheduling = () => {
  const { t } = useTranslation('translations');
  const params: { visitSiteId: string } = useParams();
  const history = useHistory();
  const visitSiteId = parseInt(params.visitSiteId);

  const { data, loading } = useAssociateSchedulingQuery({
    variables: { visitSiteId },
  });

  if (!data || loading) {
    return <Loading />;
  }

  const visitSite = data.getVisitSite;
  if (!visitSite) {
    return null;
  }

  const { name = '', timezone = DateTime.now().zoneName } = visitSite;
  const tableData = [
    { label: t('associateScheduling.timezoneLabel'), text: timezone },
  ];

  const withBookings = currentUserHasPermission(
    RolePermissions.ViewTrialOptionVisitBookings
  );

  return (
    <>
      <Header
        displayText={name}
        tableData={tableData}
        back={() => history.goBack()}
      />

      <SiteAvailability history={history} withBookings={withBookings} />
    </>
  );
};

export default AssociateScheduling;
