import React from 'react';
import usePermission from 'src/hooks/usePermission';
import { RolePermissions } from '@curebase/core/types';

interface PermissonedComponentProps {
  permission: RolePermissions;
  trialId?: number;
  children: React.ReactChild;
}
const PermissionedComponent = (props: PermissonedComponentProps) => {
  const hasPermission = usePermission(props.permission);

  if (!hasPermission) return null;
  return <>{props.children}</>;
};

export default PermissionedComponent;
