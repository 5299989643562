import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import gql from 'graphql-tag';
import { apiUrl } from '@curebase/core/lib/env';
import { clearUser } from './store/actions/index';
import { libExecuteQuery } from './shared/lib/queryHelpers';
import { getSessionExpiredRedirect } from './lib/auth';
import { getLocale } from './context/localeContext';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { message, locations, path } = error;
      if (message.startsWith('UNAUTHENTICATED')) {
        clearUser();
        window.location.href = getSessionExpiredRedirect();
      } else {
        const errorString = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`;
        console.error(errorString);
        window.location.href = '/error';
      }
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: `${apiUrl}/graphql`,
  fetch: fetch,
  credentials: 'include',
});

//omits "__typename" from the "variables" section of a graphql request
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      'x-curebase-locale': getLocale().locale,
    },
  }));
  return forward(operation).map(data => {
    return data;
  });
});

const link = ApolloLink.from([cleanTypeName, errorLink, httpLink]);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getDocumentConfig: {
          keyArgs: ['id'],
        },
        getDocumentConfigs: {
          keyArgs: ['ids'],
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link,
  cache,
});

export async function executeQuery(
  query: string,
  fragments?: string[]
): Promise<any> {
  const fragmentsSection = fragments && fragments.join('\n');
  const composedQuery = gql`query A { ${query.toString()} } ${
    fragmentsSection || ''
  }`;

  return await libExecuteQuery(client, {
    query: composedQuery,
    fetchPolicy: 'network-only',
  });
}

export async function executeOffSetQuery(queryFn: any, extractionKey) {
  // repeatable call until there are no more results returned
  const limit = 50;
  let offset = 0;

  let results = [];
  let nextResults = (await executeQuery(queryFn(limit, offset)))[extractionKey];
  while (nextResults.length > 0) {
    results = results.concat(nextResults);
    offset = limit + offset;
    nextResults = (await executeQuery(queryFn(limit, offset)))[extractionKey];
  }
  return results;
}
