import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import {
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { DocumentAction, DocumentType } from '@curebase/core/types';
import { SigningType } from '@curebase/core/decoders/signatures';
import { uploadDocumentAndSignature } from 'src/controllers/signatureController';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

interface MinorAssentDialogProps {
  onClose: () => void;
  trialOptionId: number;
  documentConfigurationId: string;
  onProceed: () => void;
}

const options = {
  1: 'minorAssentDialog.option1',
  2: 'minorAssentDialog.option2',
  3: 'minorAssentDialog.option3',
  4: 'minorAssentDialog.option4',
};
const skippingOptions = [1, 3, 4];
const statusJustificationOptions = [3];

const MinorAssentDialog = (props: MinorAssentDialogProps) => {
  const { t } = useOverridableTranslation();
  const { documentType } = useParams<{ documentType: DocumentType }>();
  const history = useHistory();

  const [option, setOption] = useState(0);
  const [statusJustification, setStatusJustification] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (option === 2) {
      setStatusJustification('');
    }
  }, [option]);

  useEffect(() => {
    if (option === 2) {
      setIsDisabled(false);
    } else if (skippingOptions.includes(option)) {
      if (statusJustificationOptions.includes(option)) {
        setIsDisabled(statusJustification.length === 0);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [option, statusJustification]);

  const handleSubmit = async () => {
    if (skippingOptions.includes(option)) {
      const request: Parameters<typeof uploadDocumentAndSignature>[0] = {
        action: DocumentAction.MakeNotApplicableToSign,
        statusJustification: statusJustificationOptions.includes(option)
          ? `${t(options[option])}: ${statusJustification}`
          : undefined,
        signingType: SigningType.remote,
        documentType,
        trialOptionId: props.trialOptionId,
        documentConfigurationId: props.documentConfigurationId,
      };

      const { nextSignableDocument } = await uploadDocumentAndSignature(
        request
      );

      props.onClose();

      if (!!nextSignableDocument) {
        history.push(
          `/u/${props.trialOptionId}/consent/${nextSignableDocument}`
        );
      } else {
        props.onProceed();
      }
    } else {
      props.onClose();
    }
  };

  return (
    <div className='light-dialog signature-capture'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '50px',
        }}
      >
        <SVG src='/icons/confirmation_dialog.svg' />
      </div>
      <div className='booker'>
        <div className='booker-title'>{t('minorAssentDialog.title')}</div>

        <div className='df-body'>
          <div className='df-page'>
            <div className='df-element'>
              <p>{t('minorAssentDialog.helperText')}</p>
            </div>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='age'
                name='radio-buttons-group'
                onChange={e => {
                  setOption(Number(e.target.value));
                }}
              >
                {Object.keys(options).map((value, ix) => {
                  return (
                    <FormControlLabel
                      key={ix}
                      value={value}
                      control={<Radio />}
                      label={t(options[value])}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            {statusJustificationOptions.includes(option) && (
              <TextField
                id='standard-multiline-static'
                label={t('minorAssentDialog.reasonNotCapable')}
                multiline
                variant='standard'
                onChange={e => setStatusJustification(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>

      <DialogActions>
        <Button disabled={isDisabled} onClick={handleSubmit} color='primary'>
          {t('common.submit')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default MinorAssentDialog;
