import { userHasPermission } from '@curebase/core/lib/authorization';
import { isBinderBeingGenerated } from '@curebase/core/lib/participantBinder';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  createParticipantBinder,
  downloadParticipantBinder,
} from '../../controllers/trialOptionController';
import { forceDownload } from '../../lib/downloads';
import { getUserSelector } from '../../store/selectors';
import {
  BinderStatus,
  ParticipantBinder,
  RolePermissions,
  useParticipantBinderQuery,
} from '../../types';
import { DownloadProgressCard } from '../basic/DownloadProgressCard';
import PermissionedButton from '../basic/PermissionedButton';

type Props = {
  trialOptionId: number;
  refetchGetTrialOptionDetails: () => void;
  participantBinder?: ParticipantBinder | null;
};

export const ParticipantBinderContainer = ({
  trialOptionId,
  refetchGetTrialOptionDetails,
  participantBinder,
}: Props) => {
  const flags = useFlags();
  const user: any = useSelector(getUserSelector);
  const { t } = useTranslation('translations');
  const [createBinderBtnLoading, setCreateBinderBtnLoading] = useState(false);
  const [userIsWaitingForBinder, setIsWaiting] = useState(false);

  const {
    data: participantBinderStatus,
    refetch,
    stopPolling,
    startPolling,
  } = useParticipantBinderQuery({
    variables: {
      trialOptionId: trialOptionId,
    },
  });

  useEffect(() => {
    setIsWaiting(false);
  }, []);

  useEffect(() => {
    if (isBinderBeingGenerated(participantBinder)) {
      refetch();
      startPolling(1000);
    }
  }, [participantBinder, refetch, startPolling]);

  const downloadBinder = async () => {
    const { downloadUrl } = await downloadParticipantBinder({
      trialOptionId: trialOptionId,
    });

    forceDownload({ downloadUrl });
    setIsWaiting(false);

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  useEffect(() => {
    if (
      participantBinderStatus?.getParticipantBinder &&
      !isBinderBeingGenerated(participantBinderStatus?.getParticipantBinder)
    ) {
      stopPolling();
      if (
        userIsWaitingForBinder &&
        participantBinderStatus?.getParticipantBinder?.status ===
          BinderStatus.Finished
      )
        downloadBinder();
    }
  }, [
    participantBinderStatus,
    stopPolling,
    userIsWaitingForBinder,
    downloadBinder,
  ]);

  const shouldShowCard =
    flags.participantBinder &&
    userHasPermission(user, RolePermissions.DownloadParticipantBinder);

  const binderHasBeenDownloaded =
    participantBinderStatus?.getParticipantBinder?.status ===
    BinderStatus.Downloaded;

  const failed =
    participantBinderStatus?.getParticipantBinder?.status ===
    BinderStatus.Failed;
  return (
    <>
      {shouldShowCard && (
        <DownloadProgressCard
          title={
            !failed
              ? t(
                  'trialOptionDetails.participantBinder.downloadProgressCard.title',
                  {
                    fileName:
                      participantBinderStatus?.getParticipantBinder?.file
                        ?.name ?? '',
                  }
                )
              : t(
                  'trialOptionDetails.participantBinder.downloadProgressCard.errorTitle',
                  {
                    fileName:
                      participantBinderStatus?.getParticipantBinder?.file
                        ?.name ?? '',
                  }
                )
          }
          description={t(
            'trialOptionDetails.participantBinder.downloadProgressCard.description'
          )}
          progress={
            participantBinderStatus?.getParticipantBinder?.progress ?? 0
          }
          timeToCompletion={5}
          downloadFileName={
            participantBinderStatus?.getParticipantBinder?.file?.name ?? ''
          }
          onDownloadClick={downloadBinder}
          hidden={
            !participantBinderStatus?.getParticipantBinder ||
            binderHasBeenDownloaded
          }
          failed={failed}
          failedMessage={t(
            'trialOptionDetails.participantBinder.downloadProgressCard.errorDescription',
            {
              fileName:
                participantBinderStatus?.getParticipantBinder?.file?.name ?? '',
              buttonTitle: `<strong>
                  ${t(
                    'trialOptionDetails.participantBinder.downloadCasebookBinder'
                  )}
                </strong>`,
            }
          )}
        />
      )}
      {flags.participantBinder && (
        <div className='participant-casebook-binder'>
          <PermissionedButton
            buttonText={t(
              'trialOptionDetails.participantBinder.downloadCasebookBinder'
            )}
            disabled={
              createBinderBtnLoading ||
              isBinderBeingGenerated(
                participantBinderStatus?.getParticipantBinder
              )
            }
            permission={RolePermissions.DownloadParticipantBinder}
            onClick={async () => {
              setCreateBinderBtnLoading(true);
              await createParticipantBinder({
                trialOptionId: trialOptionId,
              });
              if (refetchGetTrialOptionDetails)
                await refetchGetTrialOptionDetails();
              setCreateBinderBtnLoading(false);
              setIsWaiting(true);
              // For some reason in production polling would not start initially
              // so this is a redundant call to start polling to make sure it works
              if (startPolling) startPolling(1000);
            }}
          />
        </div>
      )}
    </>
  );
};
