import { userIsParticipant } from '@curebase/core/lib/user';
import { JoinStatus, TelehealthMeetingInfoQuery } from '@curebase/core/types';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { getLocale } from 'src/context/localeContext';
import useRoles from 'src/hooks/useRoles';
import { showAlertMessage } from 'src/store/actions';
import { BookingType, useTelehealthMeetingInfoQuery } from 'src/types';
import chimeContext from '../../context/getChimeContext';
import TelemedIndicator from '../../context/mainTelemedRoomOpenContext';
import CustomStyleProvider from '../../providers/CustomStyleProvider';
import { clearTelehealthDataInStore } from '../../store/telehealth/actions';
import StandardButton from '../basic/StandardButton';
import ErrorPage from '../ErrorPage';
import Loading from '../Loading';
import PageHeader from '../PageHeader';
import { createDashboardBg } from '../ParticipantInterface/helpers';
import { captureError } from 'src/lib/error';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const RECORDER_NAME = 'recorder';

const ButtonContent = (props: {
  meetingInfo: TelehealthMeetingInfoQuery;
  openVideo: boolean;
  requestAndCacheTelemedJoinInfo: () => Promise<void>;
  autoJoin?: boolean;
}) => {
  const { t } = useTranslation('translations');
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    requestAndCacheTelemedJoinInfo,
    openVideo,
    meetingInfo: {
      // @ts-expect-error
      getTelehealthMeetingInfo: { type, trialOptionId, meetingJoinStatus },
    },
  } = props;
  const roles = useRoles();

  const isPPT = userIsParticipant(roles as any);

  const inTimeCallSetup = async () => {
    if (!openVideo) {
      await requestAndCacheTelemedJoinInfo();
      dispatch({ type: 'OPEN_VIDEO_CHAT' });
    }

    if (!isPPT) {
      history.push(`/u/participants/${trialOptionId}`);
    } else {
      if (type === BookingType.InformedConsent) {
        history.push(`/u/${trialOptionId}/consent/INFORMED_CONSENT`);
      } else {
        history.push(`/u`);
      }
    }
  };

  useEffect(() => {
    if (props.autoJoin) {
      inTimeCallSetup();
    }
  }, [props.autoJoin]);

  if (props.autoJoin) {
    return <Loading />;
  }

  if (meetingJoinStatus === JoinStatus.TooEarly) {
    if (type === BookingType.InformedConsent) {
      return (
        <div className='discrete-block'>
          <StandardButton
            className='big-button telemed-room-button spacer'
            size='large'
            variant='contained'
            onClick={() => {
              history.push(`/u/${trialOptionId}/consent/INFORMED_CONSENT`);
            }}
            text={t('mainTelemedRoom.buttonContent.tooEarlyBtn')}
          />
        </div>
      );
    } else {
      return <div />;
    }
  } else {
    const isPPT = userIsParticipant(roles as any);
    const textObj = !openVideo
      ? {
          buttonText: t('mainTelemedRoom.buttonContent.joinNowBtn'),
          onClick: async () => {
            await inTimeCallSetup();
          },
        }
      : (() => {
          return {
            buttonText: !isPPT
              ? t('mainTelemedRoom.buttonContent.viewParticipantPortalBtn')
              : t('mainTelemedRoom.buttonContent.viewMyPortalBtn'),
            onClick: () => {
              history.push(!isPPT ? `/u/participants/${trialOptionId}` : '/u');
            },
          };
        })();

    return (
      <>
        <StandardButton
          className='telemed-room-button spacer'
          size='large'
          variant='contained'
          text={textObj.buttonText}
          onClick={textObj.onClick}
        />
      </>
    );
  }
};

const MeetingInfo = (
  props: TelehealthMeetingInfoQuery & { mockTime?: number }
) => {
  const {
    // @ts-ignore
    name,
    startTime,
    // @ts-ignore
    meetingJoinStatus,
    // @ts-ignore
    telemedInfo,
    // @ts-ignore
    locales,
    timeZone,
  } = props.getTelehealthMeetingInfo!;
  const { t } = useTranslation('translations');
  const { locale } = getLocale();
  const timeOfMeetingLuxon = DateTime.fromISO(startTime, { locale }).setZone(
    timeZone
  );
  const dispatch = useDispatch();
  const chime = React.useContext(chimeContext);
  const queryParam = useQuery();
  const addPhotosFlag = queryParam.get('add_photos');
  const recorderIsPresent = queryParam.get('record');
  const autoJoin = !!queryParam.get('autoJoin');
  const telehealthObj = useSelector((store: any) => store?.telehealth);
  const match = useRouteMatch<{ meetingUUID: string }>();
  const { openVideo } = useSelector((store: any) => store?.telehealth);
  const meetingTitle = match.params.meetingUUID;
  const finalTelemedInfo =
    locales?.[locale]?.default?.telemedInfo ?? telemedInfo;
  const requestAndCacheTelemedJoinInfo = React.useCallback(
    async (isRequestingPhotobooth?: boolean) => {
      const res = await chime.requestMeetingInfo(
        meetingTitle,
        recorderIsPresent ? RECORDER_NAME : 'placeholder',
        !!isRequestingPhotobooth,
        props.mockTime
      );
      if (res.error) {
        showAlertMessage(res.error);
      } else {
        dispatch({
          type: 'SET_JOIN_INFO',
          payload: { ...res },
        });
        dispatch({
          type: 'SET_MEETING_TITLE',
          payload: { meetingTitle },
        });
      }
    },
    [chime, dispatch, meetingTitle, recorderIsPresent, props.mockTime]
  );

  useEffect(() => {
    if (autoJoin && openVideo) {
      const url = document.location.href;
      window.history.pushState({}, '', url.split('?autoJoin')[0]);
    }

    const meetingInfoIsCached = !!telehealthObj.meeting?.MeetingId;
    if (!meetingInfoIsCached && recorderIsPresent) {
      requestAndCacheTelemedJoinInfo().then(() => {
        dispatch({ type: 'OPEN_VIDEO_CHAT' });
      });
    } else {
      if (addPhotosFlag) {
        requestAndCacheTelemedJoinInfo(true).then(() =>
          dispatch({
            type: 'SET_PHOTO_MODE',
            payload: { addPhotos: true },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    recorderIsPresent,
    requestAndCacheTelemedJoinInfo,
    addPhotosFlag,
    openVideo,
    autoJoin,
  ]);

  if (JoinStatus.Late === meetingJoinStatus) return <Redirect to='/u' />;

  return (
    <>
      <div className='title spacer'>{locales?.[locale]?.name ?? name}</div>
      <div className='discrete-block '>
        <div className='subtitle'>
          {t('mainTelemedRoom.meetingInfo.subtitle')}
        </div>
        <div className='small-header'>
          {t('mainTelemedRoom.meetingInfo.smallHeader')}
        </div>
        <div className='paragraph'>
          {t('mainTelemedRoom.meetingInfo.time', {
            date: timeOfMeetingLuxon.toLocaleString(DateTime.DATE_FULL),
            time: timeOfMeetingLuxon.toLocaleString(DateTime.TIME_SIMPLE),
          })}
        </div>
      </div>
      <div className='discrete-block'>
        <div className='small-header'>
          {t('mainTelemedRoom.meetingInfo.locationHeader')}
        </div>
        <div className='paragraph'>
          {t('mainTelemedRoom.meetingInfo.videoCallLabel')}
        </div>
      </div>
      <div className='discrete-block'>
        <div className='small-header'>
          {t('mainTelemedRoom.meetingInfo.attendeesSubtitle')}
        </div>
        <div className='paragraph'>{finalTelemedInfo?.telemedAttendeeDesc}</div>
      </div>
      <div className='discrete-block'>
        <div className='subtitle'>
          {t('mainTelemedRoom.meetingInfo.whoAttendSubtitle')}
        </div>
        <div className='paragraph'>{finalTelemedInfo?.telemedMemberDesc}</div>
      </div>
      <div className='discrete-block'>
        <div className='subtitle'>
          {t('mainTelemedRoom.meetingInfo.whatHappenSubtitle')}
        </div>
        <div className='paragraph'>{finalTelemedInfo?.telemedCallDesc}</div>
      </div>
      <div className='discrete-block'>
        <div className='subtitle'>
          {t('mainTelemedRoom.meetingInfo.howIPrepareSubtitle')}
        </div>
        <div className='paragraph'>{finalTelemedInfo?.telemedPrepareDesc}</div>
      </div>
      <div className='join-button-container'>
        <ButtonContent
          meetingInfo={props}
          openVideo={openVideo}
          requestAndCacheTelemedJoinInfo={requestAndCacheTelemedJoinInfo}
          autoJoin={autoJoin}
        />
      </div>
    </>
  );
};

const MainTelemedRoom = () => {
  const { update } = useContext(TelemedIndicator);

  useEffect(() => {
    update(true);

    return () => {
      update(false);
    };
  }, [update]);

  const dispatch = useDispatch();
  const match = useRouteMatch<{ meetingUUID: string }>();
  const { meetingUUID } = match.params;
  const { meetingTitle, meetingName, meetingTime } = useSelector(
    (store: any) => store?.telehealth
  );
  const mockTime = useSelector((store: any) => store?.currentDate);

  const { data, loading, error } = useTelehealthMeetingInfoQuery({
    skip: !meetingUUID,
    variables: {
      meetingUUID,
    },
  });

  if (error || !meetingUUID) {
    return <Redirect to='/u' />;
  }

  if (!!meetingTitle && meetingUUID !== meetingTitle)
    clearTelehealthDataInStore(`MeetingTitle in store doesn't match url`);

  if (loading || !data) {
    return <Loading />;
  }

  if (!meetingName) {
    dispatch({
      type: 'SET_MEETING_NAME',
      payload: { meetingName: data.getTelehealthMeetingInfo?.name },
    });
  }

  if (!meetingTime) {
    dispatch({
      type: 'SET_MEETING_TIME',
      payload: { meetingTime: data.getTelehealthMeetingInfo?.startTime },
    });
  }

  console.log(data);

  const customStyle =
    data.getTelehealthMeetingInfo?.trialOption.trial?.customStyle;
  const customCss =
    data.getTelehealthMeetingInfo?.trialOption.trial?.custom_css || '';

  if (!loading && isNil(data.getTelehealthMeetingInfo)) {
    const errorId = captureError(error);
    return <ErrorPage context={'telehealth.invalidUrl'} errorId={errorId} />;
  }

  return (
    <div
      className='outer-telemed'
      style={createDashboardBg(customStyle?.backgroundColor)}
    >
      <CustomStyleProvider customStyle={customStyle}>
        {customCss && <style dangerouslySetInnerHTML={{ __html: customCss }} />}
        <PageHeader />
        <div className='telemed-waiting-room'>
          <div>
            <MeetingInfo {...data} mockTime={mockTime} />
          </div>
        </div>
      </CustomStyleProvider>
    </div>
  );
};

export default MainTelemedRoom;
