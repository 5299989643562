import * as D from 'io-ts/Decoder';
import { pipe } from 'fp-ts/function';
import { SchedulingSystem } from '@curebase/core/types';

export const AddAvailabilityRequest = pipe(
  D.struct({
    start: D.string,
    end: D.string,
    duration: D.number,
    maxConfirmedConcurrency: D.number,
    maxUnconfirmedConcurrency: D.number,
    aptDuration: D.number,
    trialInstanceId: D.string,
    visitSiteId: D.number,
    participantEnabled: D.boolean,
  }),
  D.intersect(
    D.partial({
      rrule: D.UnknownRecord,
      schedulingConfigurationIds: D.array(D.string),
    })
  )
);
export type AddAvailabilityRequest = D.TypeOf<typeof AddAvailabilityRequest>;

export const DeleteAvailabilityRequest = D.struct({
  id: D.number,
});
export type DeleteAvailabilityRequest = D.TypeOf<
  typeof DeleteAvailabilityRequest
>;

export const EditAvailabilityRequest = pipe(
  D.struct({
    id: D.number,
    end: D.string,
  }),
  D.intersect(
    D.partial({
      aptDuration: D.number,
      maxConfirmedConcurrency: D.number,
      maxUnconfirmedConcurrency: D.number,
      participantEnabled: D.boolean,
      rrule: D.UnknownRecord,
      schedulingConfigurationIds: D.array(D.string),
      start: D.string,
    })
  )
);
export type EditAvailabilityRequest = D.TypeOf<typeof EditAvailabilityRequest>;

export const CBSchedulingSearch = pipe(
  D.struct({
    type: D.literal(SchedulingSystem.CurebaseScheduling),
    trialOptionId: D.number,
    visitSiteId: D.number,
    isAssociateOrProvider: D.boolean,
  }),
  D.intersect(
    D.partial({
      trialInstanceId: D.string,
      schedulingConfigurationId: D.string,
      monthVisibleToUser: D.string,
    })
  )
);
export type CBSchedulingSearch = D.TypeOf<typeof CBSchedulingSearch>;

export const AxleSchedulingSearch = D.struct({
  type: D.literal(SchedulingSystem.AxleHealthScheduling),
  schedulingConfigurationId: D.string,
  trialOptionId: D.number,
});
export type AxleSchedulingSearch = D.TypeOf<typeof AxleSchedulingSearch>;

export const SchedulingSearchRequest = D.sum('type')({
  [SchedulingSystem.CurebaseScheduling]: CBSchedulingSearch,
  [SchedulingSystem.AxleHealthScheduling]: AxleSchedulingSearch,
});
export type SchedulingSearchRequest = D.TypeOf<typeof SchedulingSearchRequest>;
interface TimeSlotUnix {
  start: number;
  end: number;
}
interface TimeSlotISO {
  start: string;
  end: string;
}
export type TimeSlotPure = TimeSlotUnix | TimeSlotISO;
export type TimeSlot = TimeSlotPure & {
  visitAvailabilityId: number;
  visitSiteId: number;
  isOutsideWindow: boolean;
};
export type SchedulingSearchResponse = TimeSlot[] | TimeSlotPure[];
