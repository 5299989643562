import { UserRole, UserRoleType } from '../../types';

const INTERNAL_EMAIL_DOMAINS = ['curebase.com'];

/**
 * @TODO: This data transformations could happen on Segment directly on data transformations.
 */
export const getRolesHash = (userRoles: Partial<UserRole>[]) => {
  const roles = userRoles.reduce((roles, role: Partial<UserRole>) => {
    roles[role.trial?.name || 'default'] = (
      roles[role.trial?.name || 'default'] || []
    ).concat([role.type]);
    return roles;
  }, {});
  const rolesHash = Object.keys(roles)
    .map((key: string) => `${key}: [${(roles[key] || []).join(', ')}]`)
    .join('; ');

  return `${rolesHash};`;
};

export const isParticipant = (userRoles: Partial<UserRole>[]) => {
  return !!(userRoles || []).find(role => {
    return role.type === UserRoleType.Patient;
  });
};

export const isInternalUser = (email: string | null | undefined) => {
  if (!email) return false;
  return !!INTERNAL_EMAIL_DOMAINS.find(domain => email.includes(domain));
};
