import React, { useState } from 'react';
import Dialog from '../../basic/SafeDialog';
import DialogHeader from '../../basic/DialogHeader';
import DynamicForm from '../../basic/DynamicForm';
import { useImmutableState } from '../../../hooks/useImmutableState';
import { TrialOption, Note } from '@curebase/core/types';
import { formatName } from 'src/shared/lib/uiHelpers';
import { ElementProps } from '@curebase/core/lib/dynamicform/types';
import { CaseReportDataType } from 'src/types';
import { FileUploadContext } from 'src/components/basic/FilePreview';
import { useTranslation } from 'react-i18next';

interface Props {
  trialOption: TrialOption;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: NoteForm, onSubmitCallBack?: () => void) => Promise<void>;
}

export type NoteForm = Readonly<
  Pick<Note, 'title' | 'content' | 'files' | 'isPII'>
>;

function CreateNoteDialog(props: Props) {
  const { t } = useTranslation('translations');
  const { trialOption, open, onClose, onSubmit } = props;
  const { patient } = trialOption;

  const defaultForm: NoteForm = {
    title: '',
    content: '',
    files: [],
    isPII: false,
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [state, setState] = useImmutableState<NoteForm>(defaultForm);

  const onSubmitCallBack = () => {
    setIsSubmitDisabled(false);
    setState(defaultForm);
  };

  if (!open) {
    return null;
  }

  // @ts-ignore
  const patientName = formatName({
    firstName: patient?.user.firstName,
    lastName: patient?.user.lastName,
  });

  const subtitle = (): string => {
    return `${t('createNoteDialog.dialogSubtitle', { patientName })}. 
    **${t('createNoteDialog.dataFieldWarning')}**`;
  };

  const elements: ElementProps[] = [
    {
      title: t('createNoteDialog.elements.title'),
      subElements: [
        {
          key: 'title',
          type: CaseReportDataType.Text,
          // @ts-ignore
          optional: false,
        },
      ],
    },
    {
      title: t('createNoteDialog.elements.content'),
      subElements: [
        {
          key: 'content',
          type: CaseReportDataType.Paragraph,
          // @ts-ignore
          optional: false,
        },
      ],
    },
    {
      title: t('createNoteDialog.elements.uploadFile'),
      subElements: [
        {
          key: 'files',
          type: CaseReportDataType.File,
          allowNull: true,
        },
      ],
    },
    {
      title: t('createNoteDialog.elements.piiRadio'),
      subElements: [
        {
          key: 'isPII',
          type: CaseReportDataType.YesNo,
          // @ts-ignore
          optional: false,
        },
      ],
    },
  ];

  return (
    <FileUploadContext.Provider value={{ trialOptionId: trialOption.id }}>
      <Dialog className='themed-dialog' open={open} onClose={onClose}>
        <DialogHeader
          title={t('createNoteDialog.dialogTitle')}
          subtitle={subtitle()}
          onClose={onClose}
        />
        <DynamicForm
          // @ts-ignore
          pages={[{ elements }]}
          onChange={(key, value) => {
            setState({ [key]: value });
          }}
          data={state}
          disableSubmit={isSubmitDisabled}
          onSubmit={() => {
            if (isSubmitDisabled) return;
            setIsSubmitDisabled(true);
            onSubmit(
              // @ts-ignore
              { ...state, isPII: state?.isPII === 'YES' },
              onSubmitCallBack
            );
          }}
        />
      </Dialog>
    </FileUploadContext.Provider>
  );
}

export default CreateNoteDialog;
