import { userHasPermission } from '@curebase/core/lib/authorization';
import { IS_PRODUCTION } from '@curebase/core/lib/env';
import {
  RolePermissions,
  StudyActivityStatus,
  TrialOption,
} from '@curebase/core/types';
import { isDataCaptureSubmittableAtTime } from '@curebase/modules/dataCapture/services';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getLocale, getLocaleFormatDates } from 'src/context/localeContext';
import {
  useDataCaptureListItemQuery,
  useDataCaptureListItemSdvQuery,
} from 'src/types';
import { getUser } from '../../store/user/selectors';
import ListItem from '../basic/ListItem';
import { SDVChip } from './SDV/SDVChip';
import { mapActivityStatusToListItemStatus } from './TrialOptionStudyPlan';

const { Passed, Failed, Missed, Active } = StudyActivityStatus;

interface Props {
  trialOption: TrialOption;
  activity: any;
}

const listItemStatus = (
  status: StudyActivityStatus,
  isDataCaptureSubmittable: boolean
) => {
  let listItemStatusType = mapActivityStatusToListItemStatus(status);
  if (isDataCaptureSubmittable) {
    listItemStatusType = 'AVAILABLE';
  }
  const listItemStatusAction = {
    FAILED: 'Failed',
    MISSED: 'Missed',
  }[listItemStatusType as any];

  return { type: listItemStatusType, action: listItemStatusAction };
};
// Selector to get the current time from state
const getCurrentTime = (store: any) =>
  IS_PRODUCTION ? null : store?.currentDate;

const subtitle = (activeTimes, t, mockTime) => {
  const { locale } = getLocale();
  if (activeTimes) {
    const {
      firstSubmittableTime,
      lastSubmittableTime,
      extendedLastSubmittableTime,
      dueTime,
    } = activeTimes;
    const localeFormat = getLocaleFormatDates();
    const from = DateTime.fromISO(firstSubmittableTime || dueTime);
    const to = DateTime.fromISO(
      extendedLastSubmittableTime || lastSubmittableTime || dueTime
    );
    const fromFormat = from.toFormat(localeFormat.luxon.dataCaptureList, {
      locale,
    });
    const toFormat = to.toFormat(localeFormat.luxon.dataCaptureList, {
      locale,
    });
    const currentTime = mockTime
      ? DateTime.fromMillis(mockTime)
      : DateTime.now();

    if (firstSubmittableTime === null) {
      // Null means submittable any time before dueTime
      return t('dataCaptureListItem.subtitleBefore', {
        before: fromFormat,
      });
    }
    if (lastSubmittableTime === null) {
      // Null means submittable any time after dueTime
      // If the "available after" time indicated is before the current time,
      // just return an empty string.
      if (to < currentTime) {
        return '';
      }
      // If the "available after" time is in the future, indicate this is the case
      return t('dataCaptureListItem.subtitleAfter', {
        after: toFormat,
      });
    }
    if (from.equals(to)) {
      // If 'from' and 'to' are truly the same date, there is no point
      // in showing them, so return an empty string:
      return '';
    }
    // By default return the range in which this is submitable/available
    return t('dataCaptureListItem.subtitle', {
      from: fromFormat,
      to: toFormat,
    });
  }
};

function DataCaptureListItem(props: Props) {
  const { t } = useTranslation();
  const { trialOption, activity } = props;
  const { id: trialOptionId, trialConfigId } = trialOption;
  const { status, config, activeTimes } = activity;
  const user = useSelector(getUser);
  const mockTime = useSelector(getCurrentTime);

  const { data, loading } = useDataCaptureListItemQuery({
    variables: {
      studyPlanVisitSlug: config.configId,
      trialConfigId,
    },
    fetchPolicy: 'network-only',
  });

  const hasSdvPermission = userHasPermission(
    user,
    RolePermissions.SourceDataVerification
  );
  const needsVerification = !!data?.getStudyPlanVisit
    ?.needsSourceDataVerification;
  const shouldLoadSDV = needsVerification && hasSdvPermission;

  const { data: sdvData } = useDataCaptureListItemSdvQuery({
    variables: {
      studyPlanVisitSlug: config.configId,
      trialConfigId,
      trialOptionId,
    },
    // This prevents the query of being made without the correct permission / need for it
    skip: !shouldLoadSDV,
    fetchPolicy: 'network-only',
  });

  const studyPlanVisit = data?.getStudyPlanVisit;
  const history = useHistory();

  const isComplete = [Passed, Failed, Missed].includes(status);

  const goToReview = () => {
    history.push(
      `/u/data/${trialOptionId}/${config.configId}/review${
        config.occurrence ? '?occurence=' + config.occurrence : ''
      }`
    );
  };

  const currentTime = mockTime ? DateTime.fromMillis(mockTime) : DateTime.now();
  // Check to see if the data capture is submittable. If it is, we want to be able to
  // go to the review page.
  const isDataCaptureSubmittable =
    activeTimes &&
    isDataCaptureSubmittableAtTime(activeTimes, currentTime.toISO());
  const canBeReviewed =
    status === Active || isComplete || isDataCaptureSubmittable;
  const onClick = canBeReviewed ? goToReview : null;

  if (loading || !data) {
    return (
      <div className='li-container'>
        <div className='li-status'>
          <div className='icon-container'>
            <Skeleton variant='circle' height={32} />
          </div>
        </div>

        <div className='li-middle'>
          <div className='li-title-container'>
            <Skeleton />
          </div>

          <div className='li-text'>
            <Skeleton />
          </div>
        </div>
      </div>
    );
  }

  const tags: React.ReactElement[] = [];

  if (
    studyPlanVisit?.needsSourceDataVerification &&
    typeof sdvData?.getStudyPlanVisitSourceDataVerification
      ?.isSourceDataVerified === 'boolean' &&
    isComplete
  ) {
    tags.push(
      <SDVChip
        key={0}
        isSourceDataVerified={
          sdvData?.getStudyPlanVisitSourceDataVerification.isSourceDataVerified
        }
      />
    );
  }

  return (
    <ListItem
      // @ts-ignore
      status={listItemStatus(status, isDataCaptureSubmittable)}
      middle={{
        title:
          studyPlanVisit?.summary.title +
          (config.occurrence ? ' - #' + config.occurrence : ''),
        text: studyPlanVisit?.summary.description,
        subtitle: subtitle(activeTimes, t, mockTime),
        tags: tags,
      }}
      onClick={onClick}
    />
  );
}

export default DataCaptureListItem;
