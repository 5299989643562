import React, { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../../controllers/dataCaptureController';
import CloseIcon from '@material-ui/icons/Close';
import { CallOverlayMode, VisibiltyStatus } from './AudioVideo';
import Loading from '../Loading';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DateTime } from 'luxon';
import VideoElement from './VideoElement';
import { useControlPanelMargin, useStyles } from './AudioVideoControlBar';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import chimeContext from '../../context/getChimeContext';
import { Button } from '@material-ui/core';
import { dataURItoFile } from '../basic/DynamicForm/Signature';
import DeviceSelector from './CallOverlayPanel';
import { uploadScreenshot } from '../../controllers/telemedController';
import { midWidthForMobileView } from './MessageCenterPanel';
import { showAlertMessage } from 'src/store/actions';
import { executeQuery } from 'src/ApolloClient';

export const captureImage = (videoRef: any) => {
  const canvas = document.createElement('canvas');
  canvas.width = videoRef.videoWidth;
  canvas.height = videoRef.videoHeight;
  const ctx = canvas.getContext('2d');
  ctx!.drawImage(videoRef, 0, 0, videoRef.videoWidth, videoRef.videoHeight);
  return dataURItoFile(
    canvas.toDataURL(),
    `telemed-photo-${DateTime.now().toFormat(
      "yyyy'-'MM'-'dd'T'HH':'mm':'ss"
    )}.png`
  );
};

export const uploadTelemedPhoto = async (
  meetingUUID: string,
  fileObj: File,
  dataFieldId: string,
  visitId: number,
  currentDate: number
) => {
  const data = await executeQuery(
    `getTelehealthMeetingInfo(meetingUUID: "${meetingUUID}") {
      trialOptionId
    }
  `
  );
  const { trialOptionId } = data.getTelehealthMeetingInfo!;

  const file = await uploadFile(
    fileObj,
    trialOptionId!,
    (a, b) => {
      const { percentDone } = b;
      showAlertMessage(`Uploading file: ${percentDone}% complete.`);
    },
    successStatus => {
      showAlertMessage(
        successStatus ? `File uploaded successfully` : `File upload failed.`
      );
    }
  );

  await uploadScreenshot(
    meetingUUID,
    file.path,
    file.fileId,
    dataFieldId!,
    visitId!,
    currentDate
  );
};

const PhotoBooth = (props: {
  photoBoothWithoutMeeting: boolean;
  meetingUUID: string;
  dataFieldId: string;
  visitId: number;
  meeting?: any;
  attendee?: any;
}) => {
  const {
    photoBoothWithoutMeeting,
    meetingUUID,
    meeting,
    attendee,
    dataFieldId,
    visitId,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const style = useStyles();
  const chime = useContext(chimeContext);
  const [showSettings, setShowSettings] = useState(false);
  const [flashCamera, setFlashCamera] = useState(false);
  const [captureButtonDisabled, setCaptureButtonDisabled] = useState(false);
  const ref = useRef(null);
  const isOnMobileSizeScreen = useMediaQuery(midWidthForMobileView);
  const { currentDate } = useSelector((store: any) => store);
  const marginBottom = useControlPanelMargin();

  useEffect(() => {
    let selectedRef;
    const startPhotoBooth = async currentVideoInputDevice => {
      if (ref && ref.current) {
        selectedRef = ref.current;
        await chime.chooseVideoInputDevice(currentVideoInputDevice);
        chime.audioVideo?.startVideoPreviewForVideoInput(ref.current!);
        chime.subscribeToDeviceChange(
          selectedDevice => startPhotoBooth(selectedDevice),
          'video'
        );
      }
    };

    if (photoBoothWithoutMeeting) {
      chime
        .initializeMeetingSession(meeting, attendee, meetingUUID)
        .then(() => startPhotoBooth(chime.currentVideoInputDevice))
        .then(() => setLoading(false));
    } else {
      startPhotoBooth(chime.currentVideoInputDevice).then(() =>
        setLoading(false)
      );
    }
    return () => {
      if (selectedRef) {
        chime.audioVideo?.stopVideoPreviewForVideoInput(selectedRef);
      }
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ display: loading ? 'none' : 'flex', height: '100%' }}>
      <div className='tile-container space-between'>
        <div
          className='video-window'
          style={flashCamera ? { backgroundColor: 'white' } : {}}
        >
          <div
            className={`${flashCamera ? 'blink_me' : ''}`}
            style={{ height: '100%' }}
          >
            <VideoElement
              videoRef={ref}
              isVisible={VisibiltyStatus.ShowVideo}
              fullWindowHeight={true}
            />
            {showSettings && (
              <DeviceSelector
                onClose={() => setShowSettings(false)}
                overlayMode={CallOverlayMode.DeviceChange}
              />
            )}
          </div>
        </div>
        <div className='small-screen-wrapper' style={{ marginBottom }}>
          <div className='options'>
            <Button
              className={`${style.button} telemed-button end-call`}
              disabled={captureButtonDisabled}
              style={{ backgroundColor: '#a9c6fa' }}
              onClick={async () => {
                if (!ref.current || flashCamera || captureButtonDisabled)
                  return;
                const fileObj = captureImage(ref.current!);
                setFlashCamera(true);
                setCaptureButtonDisabled(true);
                setTimeout(() => setFlashCamera(false), 500);
                await uploadTelemedPhoto(
                  meetingUUID,
                  fileObj,
                  dataFieldId,
                  visitId,
                  currentDate
                );
                setCaptureButtonDisabled(false);
              }}
            >
              <AddAPhotoIcon />
            </Button>

            {isOnMobileSizeScreen && chime.videoInputDevices.length === 2 ? (
              <Button
                className={`${style.button} telemed-button`}
                onClick={() => {
                  chime.flipVideoDevice();
                }}
              >
                <FlipCameraAndroidIcon className='control-icon' />
              </Button>
            ) : (
              <Button
                className={`${style.button} telemed-button`}
                onClick={() => {
                  setShowSettings(true);
                }}
              >
                <MoreVertIcon className='control-icon' />
              </Button>
            )}

            <Button
              className={`${style.button} telemed-button`}
              onClick={() => {
                chime.audioVideo?.stopVideoPreviewForVideoInput(ref.current!);
                dispatch({
                  type: 'SET_PHOTO_MODE',
                  payload: { addPhotos: false },
                });
                if (photoBoothWithoutMeeting) {
                  history.push('/u');
                }
              }}
            >
              <CloseIcon className='control-icon' />
            </Button>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default PhotoBooth;
