import React from 'react';
import { userIsParticipant } from 'src/lib/users';
import { BookerRootContext } from './BookerDialog';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import StandardButton from 'src/components/basic/StandardButton';
import { User } from '@curebase/core/types';

interface BookerVirtualVisitAddSpectatorProps {
  sponsorUsers: User[];
  onSelectVisitSpectator: (arg0?: number) => any;
  onConfirmVisitSpectator: () => any;
  onBack: () => void | Promise<void>;
  onOpen?: () => void | Promise<void>;
  backButtonText: string;
}

type Option = {
  text: string;
  value: number;
};

function BookerVirtualVisitAddSpectator({
  sponsorUsers,
  onSelectVisitSpectator,
  onConfirmVisitSpectator,
  onBack,
  backButtonText,
  onOpen,
}: BookerVirtualVisitAddSpectatorProps) {
  const { t } = useTranslation('translations');
  const { config } = React.useContext(BookerRootContext);
  // TODO: Fetch this from a table, no?!
  const supportContact = {
    email: 'support@mycurebase.com.',
  };

  const options = sponsorUsers.map(user => ({
    text: `${user.title} ${user.firstName} ${user.lastName} (${user.email})`,
    value: user.id,
  })) as Option[];

  return (
    <>
      <div className='booker-text-container'>
        <div className='booker-title'>
          {sponsorUsers.length === 0
            ? t('bookerVirtualVisitAddSpectator.title.noOptions')
            : config?.default.appointmentBookingInfo
                ?.appointmentBookingHeader ??
              (userIsParticipant()
                ? t('bookerVirtualVisitAddSpectator.title.participant')
                : t('bookerVirtualVisitAddSpectator.title.nonParticipant'))}
        </div>

        <div className='booker-back-button' onClick={onBack}>
          {backButtonText}
        </div>

        <div className='booker-text'>
          {sponsorUsers.length === 0
            ? t('bookerVirtualVisitAddSpectator.text.noOptions', {
                supportEmail: supportContact.email,
              })
            : config?.default.appointmentBookingInfo?.appointmentBookingDesc ??
              (userIsParticipant()
                ? t('bookerVirtualVisitAddSpectator.text.participant')
                : t('bookerVirtualVisitAddSpectator.text.nonParticipant'))}
        </div>

        <div className='booker-ocp-container'>
          <Autocomplete
            options={options}
            renderInput={params => <TextField {...params} variant='outlined' />}
            getOptionLabel={option => option.text}
            onOpen={onOpen}
            onChange={(e, option) => {
              onSelectVisitSpectator(option ? option.value : undefined);
            }}
          />
          <br></br>
          <StandardButton
            onClick={onConfirmVisitSpectator}
            variant='contained'
            fullWidth={true}
          >
            {t('bookerVirtualVisitAddSpectator.button.next')}
          </StandardButton>
        </div>
      </div>
    </>
  );
}

export default BookerVirtualVisitAddSpectator;
