import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PayPalConnectRedirect from './PayPalConnectRedirect';

class PaypalRoutes extends React.Component<any, any> {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path='/paypal/save-id'
            component={PayPalConnectRedirect}
          />
        </Switch>
      </div>
    );
  }
}

export default PaypalRoutes;
