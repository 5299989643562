import * as React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import AlertInDialog from '../basic/AlertInDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import Dialog from '../basic/SafeDialog';
import ListItem from '../basic/ListItem';
import { assignGroup } from '../../controllers/enrollmentController';
import {
  StudyActivityStatus,
  StudyActivity,
  TrialOption,
} from '@curebase/core/types';

const { Passed, Active } = StudyActivityStatus;

type Props = {
  activity: StudyActivity;
  refetchData: () => void;
  t: any;
  trialOption: TrialOption;
};

type State = {
  showAssignGroupDialog: boolean;
  groupAssignmentLabel: string | null;
  groupAssignmentMessage: string | null;
  showResultsDialog: boolean;
  xhrInProgress: boolean;
};

class GroupAssignmentListItem extends React.Component<Props, State> {
  state = {
    showAssignGroupDialog: false,
    showResultsDialog: false,
    groupAssignmentLabel: null,
    groupAssignmentMessage: null,
    xhrInProgress: false,
  };

  renderAssignGroupDialog = () => {
    const { trialOption, t } = this.props;

    return (
      <Dialog
        open={this.state.showAssignGroupDialog}
        onClose={() => {
          this.setState({ showAssignGroupDialog: false });
        }}
      >
        <ConfirmationInDialog
          title={t('trialOptionStudyPlan.assignGroup.areYouSureTitle')}
          message={t('trialOptionStudyPlan.assignGroup.areYouSureMsg')}
          confirmationText={t('trialOptionStudyPlan.assignGroup.assignBtn')}
          onConfirm={async () => {
            if (this.state.xhrInProgress) return;
            this.setState({ xhrInProgress: true }, async () => {
              let {
                groupAssignmentLabel,
                groupAssignmentMessage,
              } = await assignGroup(trialOption.id);
              await this.props.refetchData();

              this.setState({
                groupAssignmentLabel: groupAssignmentLabel,
                groupAssignmentMessage: groupAssignmentMessage,
                showResultsDialog: true,
                showAssignGroupDialog: false,
                xhrInProgress: false,
              });
            });
          }}
          onClose={() => this.setState({ showAssignGroupDialog: false })}
        />
      </Dialog>
    );
  };

  renderResultsDialog = () => {
    const groupAssignmentLabel = _.get(
      this.props.trialOption,
      'groupAssignment.label',
      this.state.groupAssignmentLabel
    );

    const groupAssignmentMessage = _.get(
      this.props.trialOption,
      'groupAssignment.message',
      this.state.groupAssignmentMessage
    );

    const messages = {
      none: `${this.props.t('trialOptionStudyPlan.assignGroup.messages.none')}
        ${groupAssignmentLabel || ''}.`,
      participant: `${this.props.t(
        'trialOptionStudyPlan.assignGroup.messages.participant'
      )}
        ${groupAssignmentLabel || ''}.
        ${this.props.t(
          'trialOptionStudyPlan.assignGroup.messages.participantRecall'
        )}`,
      participantAndResearcher: this.props.t(
        'trialOptionStudyPlan.assignGroup.messages.participantAndResearcher'
      ),
    };

    const blindness = this.props.trialOption.trial.blindness;

    let msg = blindness ? messages[blindness] : '';

    const closeDialog = () => {
      this.setState({ showResultsDialog: false });
    };
    return (
      <Dialog open={this.state.showResultsDialog} onClose={closeDialog}>
        <AlertInDialog
          title={this.props.t('trialOptionStudyPlan.assignGroup.modalTitle')}
          message={msg}
          markdown={
            groupAssignmentMessage ? groupAssignmentMessage.data : undefined
          }
          onConfirm={closeDialog}
        />
      </Dialog>
    );
  };

  render() {
    const { t, trialOption, activity } = this.props;
    const hasStepStatus = activity.status === Active;
    const isAlreadyRandomized =
      trialOption.groupAssignment || activity.status === Passed;

    let onClick;
    let statusInfo;

    if (isAlreadyRandomized) {
      statusInfo = { type: 'COMPLETED' };
      onClick = () => this.setState({ showResultsDialog: true });
    } else if (hasStepStatus) {
      statusInfo = { type: 'AVAILABLE' };
      onClick = () => this.setState({ showAssignGroupDialog: true });
    } else {
      statusInfo = { type: 'BLANK' };
    }

    return (
      <>
        <ListItem
          // @ts-ignore
          status={statusInfo}
          middle={{
            title: t('trialOptionStudyPlan.assignGroup.listItemTitle'),
            text: t('trialOptionStudyPlan.assignGroup.randomize'),
          }}
          onClick={onClick}
        />

        {this.renderAssignGroupDialog()}

        {this.renderResultsDialog()}
      </>
    );
  }
}

export default withTranslation('translations')(GroupAssignmentListItem as any);
