import {
  GenerateSignedDocumentRequest,
  GenerateSignedDocumentResponse,
} from '@curebase/core/decoders/informedConsents';
import { jsonPost } from '../lib/fetchHelpers';

export async function generateSignedDocument(
  requestBody: GenerateSignedDocumentRequest
): Promise<GenerateSignedDocumentResponse> {
  const response: GenerateSignedDocumentResponse = await jsonPost(
    '/document/generateSignedDocument/',
    requestBody
  );
  return response;
}
