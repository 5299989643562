import { HelpContentData } from '@curebase/core/types';
import IconButton from '@material-ui/core/IconButton';
import Healing from '@material-ui/icons/Healing';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import React, { useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';

import AlertInDialog from '../basic/AlertInDialog';
import Dialog from '../basic/SafeDialog';
import FileMedicalProblem from './FileMedicalProblem';

type Props = {
  t: TFunction;
  canReportMedicalProblem: boolean;
  helpContent?: HelpContentData | null;
};

const ReportNewEvent = (props: LDProps & Props) => {
  const [showMedicalProblemPage, setShowMedicalProblemPage] = useState<boolean>(
    false
  );
  const [
    openHelpContent,
    setOpenHelpContent,
  ] = useState<HelpContentData | null>(null);

  const { canReportMedicalProblem, t, helpContent, flags } = props;
  const hasHelpContentEnabled = helpContent && flags?.helpCard;
  return (
    (canReportMedicalProblem || hasHelpContentEnabled) && (
      <div className='report-new-event'>
        <div className='pd-small-header'>{t('reportNewEvent.title')}</div>

        <div className='report-new-event-main'>
          {canReportMedicalProblem && (
            <div className='item'>
              <IconButton
                aria-label='Medical Problem'
                color='primary'
                onClick={() => {
                  setShowMedicalProblemPage(true);
                }}
              >
                <div>
                  <div>
                    <Healing />
                  </div>
                  <div className='btn-text'>
                    {t('reportNewEvent.medicalProblem')}
                  </div>
                </div>
              </IconButton>
            </div>
          )}

          {hasHelpContentEnabled && helpContent && (
            <div className='item'>
              <IconButton
                aria-label={helpContent.title}
                color='primary'
                onClick={() => {
                  setOpenHelpContent({
                    title: helpContent.title,
                    body: helpContent.body,
                  });
                }}
              >
                <div>
                  <div>
                    <Healing />
                  </div>
                  <div className='btn-text'>{helpContent.title}</div>
                </div>
              </IconButton>
            </div>
          )}
        </div>

        <Dialog
          open={showMedicalProblemPage}
          onClose={() => {
            setShowMedicalProblemPage(false);
          }}
        >
          <FileMedicalProblem
            {...props}
            // @ts-ignore
            onClose={() => setShowMedicalProblemPage(false)}
          />
        </Dialog>

        <Dialog open={!!openHelpContent ?? false}>
          {openHelpContent && (
            <AlertInDialog
              title={openHelpContent?.title ?? ''}
              message=''
              onConfirm={() => setOpenHelpContent(null)}
              markdown={openHelpContent?.body}
            />
          )}
        </Dialog>
      </div>
    )
  );
};

export default withTranslation('translations')(
  withLDConsumer()(ReportNewEvent)
);
