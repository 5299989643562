import produce from 'immer';
import moment from 'moment-timezone';
import {
  TimezoneState,
  UPDATE_TIMEZONE,
  TimezoneActionTypes,
  CHANGE_TIMEZONE_WITHOUT_CLOCK_RESET,
} from './types';

const initialState: TimezoneState = {
  timezone: moment.tz.guess(),
  dateSet: 0,
};

const timezoneReducer = produce(
  (state: TimezoneState, action: TimezoneActionTypes) => {
    switch (action.type) {
      case UPDATE_TIMEZONE:
        state.timezone = action.payload.timezone;
        state.dateSet = moment().valueOf();
        break;
      case CHANGE_TIMEZONE_WITHOUT_CLOCK_RESET:
        state.timezone = action.payload.timezone;
        break;
      default:
    }
  },
  initialState
);

export default timezoneReducer;
