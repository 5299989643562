import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  PatientIntegrationSources,
  StudyActivityType,
  useConnectSourceDataQuery,
} from 'src/types';

import { getViewPatientBaseUrl } from '../../../lib/users';
import { ParticipantDashboardContext } from '../ParticipantDashboard';
import { TrialOptionStudyPlanContext } from '../../TrialOption/TrialOptionStudyPlan';

import ConnectHumanApi from './ConnectHumanApi';
import ConnectionNavigationDialog from './ConnectionNavigationDialog';

import LoaderDialog from '../../basic/LoaderDialog';
import { Route } from 'react-router-dom';

interface ConnectSourceDataDialogProps {
  isOpen: boolean;
  trialOptionId: number;
}

export enum ConnectRoutes {
  main = 'connectSourceData',
  HUMAN_API = 'humanapi',
  LOADING = 'loading',
  NEXT = 'next',
}

const { ConnectSourceData } = StudyActivityType;

const ConnectSourceDataRoutes = (props: ConnectSourceDataDialogProps) => {
  const { isOpen, trialOptionId } = props;
  const history = useHistory();
  const { refetch: trialOptionStudyPlanRefetch } = React.useContext(
    TrialOptionStudyPlanContext
  );
  const { refetch: participantDashboardRefetch } = React.useContext(
    ParticipantDashboardContext
  );
  const { data, loading, refetch } = useConnectSourceDataQuery({
    variables: {
      trialOptionId,
    },
  });

  if (loading || !data) return <LoaderDialog open={true} />;

  const {
    getTrialOption: { patient, studyActivities },
  } = data;
  if (!patient) throw new Error('must have patient');
  if (!isOpen) return null;

  const baseUrl = getViewPatientBaseUrl(trialOptionId);
  const mainPathUrl = `${baseUrl}/${ConnectRoutes.main}`;

  const sources =
    patient?.sourceDataIntegrations?.[PatientIntegrationSources.HumanApi]
      ?.sources;

  const requiredSources =
    studyActivities.find(act => act.config.type === ConnectSourceData)?.config
      ?.configId ?? '';

  const onClose = async (forceBack?: boolean) => {
    history.push(`${mainPathUrl}/${ConnectRoutes.LOADING}`);
    await Promise.all([
      trialOptionStudyPlanRefetch(),
      participantDashboardRefetch(),
      refetch(),
    ]);
    if (!requiredSources || forceBack) {
      history.push(`${baseUrl}`);
    } else {
      history.push(`${mainPathUrl}/${ConnectRoutes.NEXT}`);
    }
  };

  return (
    <Route
      path={`${mainPathUrl}/:path`}
      render={routeProps => {
        const { path } = routeProps.match.params;
        switch (path) {
          case ConnectRoutes.HUMAN_API:
            return (
              <ConnectHumanApi
                humanApiAccessToken={patient.humanApiAccessToken}
                onClose={onClose}
              />
            );
          case ConnectRoutes.NEXT:
            return (
              <ConnectionNavigationDialog
                goToNextSource={() =>
                  history.push(`${mainPathUrl}/${ConnectRoutes.HUMAN_API}`)
                }
                onClose={() => onClose(true)}
                requiredSources={requiredSources}
                sources={sources}
              />
            );

          case ConnectRoutes.LOADING:
          default:
            return <LoaderDialog open={true} onClose={onClose} />;
        }
      }}
    />
  );
};

export default ConnectSourceDataRoutes;
