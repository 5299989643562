import { RolePermissions } from '../../types';

export const deidentifiedDataManager = [
  RolePermissions.CreateCapturedDataQuery,
  RolePermissions.CreateCapturedDataQueryMessage,
  RolePermissions.CreateCapturedDataQueryReminder,
  RolePermissions.ViewParticipantNote,
  RolePermissions.ReadCapturedDataEditQueryMessage, // CRF Signoff
  RolePermissions.ResolveCapturedDataQuery,
  RolePermissions.ViewCrf, // Data Edits and Querying
  RolePermissions.ViewGroupAssignment,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewSettings,
];
