import React from 'react';
import { Alert as AlertMaterial, AlertTitle, Color } from '@material-ui/lab';

interface Props {
  title: string;
  description: string;
  severity: Color;
}

const Alert = ({ title, description, severity }: Props) => {
  return (
    <AlertMaterial variant='outlined' severity={severity}>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {description}
    </AlertMaterial>
  );
};

export default Alert;
