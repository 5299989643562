import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import styles from './CSlider.module.css';
import { Typography, withStyles } from '@material-ui/core';
import CSliderThumb, { CVerticalThumb } from './SliderThumb';
import { sliderOverrides } from './CSlider.overrides';

interface CSliderProps {
  showSelectionField?: boolean; // The slider value displayed in a left-floating field
  selectionLabel?: string; // Label for the slider value field
  defaultValue?: number;
  marks?: any; // needed due to the way the parent components pass marks
  majorLabel?: false | { maxLabel?: string; minLabel?: string };
  max?: number;
  min?: number;
  orientation?: 'vertical' | 'horizontal';
  onChange?: (event: object, newValue: number | number[]) => void;
  step?: number;
  value?: any; // Some passed values may be set to null
  disabled?: boolean;
  className?: string;
}

/**
 * A slider component. This component wraps the MUI slider component
 * and adds a field that shows the users selection and labels for the
 * max and min values. Also adds some functionality for dealing with
 * vertical sliders.
 *
 */
const CSlider = ({
  max = 100,
  min = 0,
  showSelectionField = true,
  selectionLabel,
  defaultValue,
  majorLabel,
  orientation = 'horizontal',
  onChange,
  value,
  disabled,
  className,
  ...rest
}: CSliderProps) => {
  const activeValue = value ? value : defaultValue || min;
  const [sliderValue, setSliderValue] = useState<number | number[]>(
    activeValue
  );
  const handleSliderChange = (event: object, newValue: number | number[]) => {
    setSliderValue(newValue);
    if (onChange) {
      onChange(event, newValue);
    }
  };
  const isVertical = orientation === 'vertical';
  const verticalOverrides = {
    root: styles['vertical-overrides'],
  };
  // This function prevents default behavior of arrow keys for vertical sliders
  // This helps usability so that users are not clicking left/right keys
  // to make the value go up/down.
  const preventHorizontalKeyboardNavigation = (event: React.KeyboardEvent) => {
    if (
      (event.key === 'ArrowLeft' || event.key === 'ArrowRight') &&
      isVertical
    ) {
      event.preventDefault();
    }
  };
  const majorLabelValues = {
    maxLabel: 'Very Likely',
    minLabel: 'Unlikely',
    ...majorLabel,
  };
  const wrapperClass = styles[`${orientation}-slider-wrapper`];
  // Adjust the height of the vertical slider if major label is present.
  const extraStyles = () =>
    majorLabel && orientation === 'vertical'
      ? {
          minHeight: '20rem',
        }
      : {};
  return (
    <div className={`${wrapperClass} ${className}`} style={extraStyles()}>
      {showSelectionField && (
        <div className={styles['value-label-wrapper']}>
          {selectionLabel && (
            <Typography variant='caption' className={styles['main-label']}>
              {selectionLabel}
            </Typography>
          )}
          <div className={styles['slider-value-label']}>
            <Typography>{sliderValue.toString()}</Typography>
          </div>
        </div>
      )}
      <div className={styles[`${orientation}-slider`]}>
        {majorLabel && (
          <Typography variant='caption' className={styles['slider-min-label']}>
            {majorLabelValues?.minLabel}
          </Typography>
        )}
        <Slider
          classes={isVertical ? verticalOverrides : {}}
          onChange={handleSliderChange}
          max={max}
          min={min}
          defaultValue={defaultValue}
          orientation={orientation}
          onKeyDown={preventHorizontalKeyboardNavigation}
          ThumbComponent={isVertical ? CVerticalThumb : CSliderThumb}
          disabled={disabled}
          value={sliderValue}
          {...rest}
        />
        {majorLabel && (
          <Typography variant='caption' className={styles['slider-max-label']}>
            {majorLabelValues?.maxLabel}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default withStyles(sliderOverrides)(CSlider);
