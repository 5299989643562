import { StudyActivity } from '@curebase/core/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from 'src/store/user/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import { setPlutoAcitivtyDone } from 'src/controllers/engageController';
import ActionCard from '../../basic/ActionCard';
import { ParticipantDashboardContext } from '../ParticipantDashboard';
import { actionCardShowSkipButton } from '../utils';
import { buildPlutoUrl } from './pluto';

interface PlutoActionCardProps {
  activity: StudyActivity;
}

function PlutoActionCard(props: PlutoActionCardProps) {
  const { t } = useTranslation('translations');
  const { trialOptionId } = useParams<any>();
  const { plutoUrl } = useFlags();
  const user = useSelector(getUser);

  const { refetch: participantDashboardRefetch } = useContext(
    ParticipantDashboardContext
  );
  const optional = actionCardShowSkipButton(props.activity);
  const buttons = [
    {
      text: t('participants.pluto.taskCard.primaryActionText'),
      onClick: () => {
        window.location.href = buildPlutoUrl(plutoUrl, user.userId);
      },
    },
    {
      text: t('participants.pluto.taskCard.secondaryActionText'),
      isSecondary: true,
      onClick: () => {
        markActivityAsDone();
      },
    },
  ];

  async function markActivityAsDone() {
    if (!trialOptionId) return;
    await setPlutoAcitivtyDone(parseInt(trialOptionId));
    participantDashboardRefetch();
  }

  return (
    <>
      <ActionCard
        optional={optional}
        showVerticalButtons={true}
        title={t('participants.pluto.taskCard.title')}
        description={t('participants.pluto.taskCard.description')}
        iconSrc={'/icons/pluto.svg'}
        buttons={buttons}
      />
    </>
  );
}

export default PlutoActionCard;
