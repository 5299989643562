import React from 'react';
import Dialog from './SafeDialog';
import Loading from '../Loading';

interface LoaderDialogProps {
  open: boolean;
  onClose?: () => void;
}

const LoaderDialog = (props: LoaderDialogProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <div className='light-dialog'>
      <Loading />
    </div>
  </Dialog>
);

export default LoaderDialog;
