import { StatusColor } from 'src/shared/lib/colors';
import type { Action } from '../actions';
import { isCurebaseMobileApp } from '../../lib/ui';

type TUiState = {
  alertMessage?: {
    visibleForSeconds: number;
    text: string;
    statusColor: StatusColor;
  };
  trialOptionId?: number;
  menuDrawer: {
    isVisible: boolean;
  };
  loading: {
    isVisible: boolean;
  };
  isCurebaseMobileApp: boolean;
};

const defaultUiState: TUiState = {
  menuDrawer: {
    isVisible: false,
  },
  loading: {
    isVisible: false,
  },
  isCurebaseMobileApp: isCurebaseMobileApp(),
};

export default function ui(
  state: TUiState = defaultUiState,
  action: Action
): TUiState {
  switch (action.type) {
    case 'SHOW_ALERT_MESSAGE':
      return {
        ...state,
        alertMessage: {
          visibleForSeconds: state.alertMessage
            ? Math.min(
                state.alertMessage.visibleForSeconds + action.visibleForSeconds,
                3
              )
            : action.visibleForSeconds,
          text: action.text,
          statusColor: action.statusColor || StatusColor.Gray,
        },
      };
    case 'TICK_ALERT_MESSAGE_TIMER':
      return {
        ...state,
        alertMessage: state.alertMessage
          ? {
              ...state.alertMessage,
              visibleForSeconds: Math.max(
                state.alertMessage.visibleForSeconds - 1,
                0
              ),
            }
          : undefined,
      };
    case 'SHOW_MENU_DRAWER':
      return {
        ...state,
        menuDrawer: {
          isVisible: true,
        },
      };
    case 'HIDE_MENU_DRAWER':
      return {
        ...state,
        menuDrawer: {
          isVisible: false,
        },
      };
    case 'SHOW_FULL_LOADING':
      return {
        ...state,
        loading: {
          isVisible: true,
        },
      };
    case 'HIDE_FULL_LOADING':
      return {
        ...state,
        loading: {
          isVisible: false,
        },
      };
    case 'CLEAR_USER':
      return {
        ...defaultUiState,
      };
    default:
      return state;
  }
}
