import { VisitStatus } from '@curebase/core/types';

export default function dataCaptureCanBeViewed(
  visitStatus: VisitStatus,
  canRetry: boolean
) {
  return (
    [
      VisitStatus.Created,
      VisitStatus.InProgress,
      VisitStatus.BackFillOpen,
    ].includes(visitStatus) || canRetry
  );
}
