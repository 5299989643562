import { Environments, HOSTNAME_ENVIRONMENT } from '@curebase/core/lib/env';
import TagManager from 'react-gtm-module';

export type GtmContainerBlob = Partial<Record<Environments, string[]>>;

export enum GtmCustomEvents {
  DATA_CAPTURE_COMPLETED_SUCCESS = 'DataCaptureCompletedSuccessfully',
  CASE_REPORT_COMPLETED = 'CaseReportCompleted',
  PRE_SCREENER_CASE_REPORT_COMPLETED = 'PrescreeningCaseReportCompleted',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  TRIAL_REGISTRATION = 'TrialRegistration',
  SIGNED_DOCUMENT = 'SignedDocument',
  SCHEDULED_VISIT = 'ScheduledVisit',
  SIGNED_ALL_DOCUMENTS = 'SignedAllDocuments',
}
//used to ensure the same container isn't loaded twice.
export let loadedGtmContainers: string[] = [];

export function gtmContainerInit(containerBlob?: GtmContainerBlob) {
  if (
    (!containerBlob || !containerBlob[HOSTNAME_ENVIRONMENT]) &&
    loadedGtmContainers.length === 0
  )
    return;

  let environmentContainers = containerBlob?.[HOSTNAME_ENVIRONMENT] ?? [];
  environmentContainers = environmentContainers.slice().sort();
  loadedGtmContainers = loadedGtmContainers.slice().sort();

  //handle trial changes
  if (
    JSON.stringify(environmentContainers) !==
      JSON.stringify(loadedGtmContainers) &&
    loadedGtmContainers.length > 0
  ) {
    window.location.reload();
  }

  environmentContainers.forEach((event: string) => {
    if (!loadedGtmContainers.includes(event)) {
      try {
        TagManager.initialize({ gtmId: event });
      } finally {
        loadedGtmContainers.push(event);
      }
    }
  });
}

type Layer<T> = {
  event: string;
  data?: T;
};

interface DataWindow<T> extends Window {
  dataLayer?: Layer<T>[];
}

export function attemptCustomGtmEvent<T extends any>(
  event: string,
  data?: T
): boolean {
  const dataWindow: DataWindow<T> = window;
  const dataLayer = dataWindow?.dataLayer;

  if (loadedGtmContainers.length > 0 && dataLayer) {
    const layer: Layer<T> = {
      event,
    };

    if (data) {
      layer.data = data;
    }

    dataLayer?.push(layer);
    return true;
  }
  return false;
}
