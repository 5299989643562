import 'diff2html/bundles/css/diff2html.min.css';
import * as diff2html from 'diff2html';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface DiffViewerProps {
  fromName: string;
  toName: string;
  patchDiff: string;
  configuration?: diff2html.Diff2HtmlConfig;
}

const defaultConfiguration: diff2html.Diff2HtmlConfig = {
  matching: 'none',
  inputFormat: 'diff',
  outputFormat: 'side-by-side',
  renderNothingWhenEmpty: false,
  drawFileList: false,
  showFiles: false,
  matchWordsThreshold: 0.25,
  matchingMaxComparisons: 2500,
  maxLineSizeInBlockForComparison: 200,
  maxLineLengthHighlight: 10000,
} as diff2html.Diff2HtmlConfig;

const DiffViewer = (props: DiffViewerProps) => {
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('translations');

  useEffect(() => {
    const configuration = {
      ...defaultConfiguration,
      ...props.configuration,
    } as diff2html.Diff2HtmlConfig;
    const html = diff2html.html(props.patchDiff, configuration);
    const fileDiffView = viewerRef.current;
    if (fileDiffView) fileDiffView.innerHTML = html;
  }, [props.patchDiff, props.fromName, props.toName, props.configuration]);

  return (
    <div className='diff-viewer' ref={viewerRef}>
      <div className='diff-viewer-loader'>
        <p>{t('diffViewer.viewerLoadingMsg')}</p>
      </div>
    </div>
  );
};

export default DiffViewer;
