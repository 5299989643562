import {
  ReverseEnrollmentRequest,
  ReverseEnrollmentResponse,
  SubmitEnrollmentRequest,
  SubmitEnrollmentResponse,
  SubmitGroupAssignmentRequest,
  SubmitGroupAssignmentResponse,
} from '@curebase/core/decoders/enrollments';
import { CreateSignature } from '@curebase/core/decoders/signatures';
import { jsonPost } from '../lib/fetchHelpers';

export async function submitEnrollment(
  trialOptionId: number,
  willEnroll: boolean,
  signature?: CreateSignature
): Promise<SubmitEnrollmentResponse> {
  const requestBody: SubmitEnrollmentRequest = {
    signature,
    trialOptionId,
    willEnroll,
  };
  const response: any = await jsonPost('/enrollment/create', requestBody);
  return response;
}

export async function reverseEnrollment(
  trialOptionId: number
): Promise<ReverseEnrollmentResponse> {
  const requestBody: ReverseEnrollmentRequest = {
    trialOptionId,
  };
  const response: any = await jsonPost('/enrollment/reverse', requestBody);
  return response;
}

export async function assignGroup(
  trialOptionId: number
): Promise<SubmitGroupAssignmentResponse> {
  const requestBody: SubmitGroupAssignmentRequest = {
    trialOptionId,
  };
  const response: SubmitGroupAssignmentResponse = await jsonPost(
    '/enrollment/assigngroup',
    requestBody
  );
  return response;
}
