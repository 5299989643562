import React, { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterListRounded';
import SortIcon from '@material-ui/icons/Sort';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

type Props = {
  title: string;
  icon: 'filter' | 'sort';
  initialValue: string;
  dropdownObjects: Record<string, any>[];
  onFilterClose: (filter: string) => void;
  className?: string;
};

const icons = {
  filter: <FilterListIcon />,
  sort: <SortIcon />,
};

function ParticipantDropdown(props: Props) {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const {
    title,
    dropdownObjects,
    className,
    initialValue,
    onFilterClose,
  } = props;
  const icon = icons[props.icon];

  function showSelectMenu(event): void {
    setMenuAnchor(event.currentTarget);
  }

  function onMenuClose(filter: string) {
    setMenuAnchor(null);
    onFilterClose(filter);
  }

  return (
    <>
      <div className={`filter ${className || ''}`} onClick={showSelectMenu}>
        {icon}
        {title}
      </div>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => onMenuClose(initialValue)}
      >
        {dropdownObjects.map((record, i) => (
          <MenuItem
            key={i}
            value={record.value}
            onClick={() => onMenuClose(record.value)}
            className={record.value === initialValue ? 'teal' : ''}
          >
            {record.key}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ParticipantDropdown;
