import { jsonPost } from '../lib/fetchHelpers';
import {
  UpdateTrialOptionRequest,
  UpdateTrialOptionResponse,
  WithdrawPatientRequest,
  WithdrawPatientResponse,
  OptoutPatientRequest,
  OptoutPatientResponse,
  AddTrialOptionResponse,
  AddTrialOptionRequest,
  UpdateParticipantTrialOptionRequest,
  UpdateParticipantTrialOptionResponse,
  CreateParticipantBinderRequest,
  CreateParticipantBinderResponse,
  DownloadParticipantBinderResponse,
} from '@curebase/core/decoders/trialOptions';

export async function updateTrialOption(
  requestBody: UpdateTrialOptionRequest
): Promise<UpdateTrialOptionResponse> {
  const response: UpdateTrialOptionResponse = await jsonPost(
    '/trialoption/update',
    requestBody
  );
  return response;
}

export async function withdrawPatient(
  trialOptionId: number
): Promise<WithdrawPatientResponse> {
  const requestBody: WithdrawPatientRequest = { trialOptionId };
  const response: WithdrawPatientResponse = await jsonPost(
    '/trialoption/withdraw',
    requestBody
  );
  return response;
}

export async function updateTrialOptionState(
  trialOptionId: number
): Promise<WithdrawPatientResponse> {
  const requestBody: WithdrawPatientRequest = { trialOptionId };
  const response: WithdrawPatientResponse = await jsonPost(
    '/trialoption/updateTrialOptionState',
    requestBody
  );
  return response;
}

export async function optoutPatient(
  trialOptionId: number
): Promise<OptoutPatientResponse> {
  const requestBody: OptoutPatientRequest = { trialOptionId };
  const response: OptoutPatientResponse = await jsonPost(
    '/trialoption/optout',
    requestBody
  );
  return response;
}

export async function addTrialOption(
  requestBody: AddTrialOptionRequest
): Promise<AddTrialOptionResponse> {
  const response: AddTrialOptionResponse = await jsonPost(
    '/trialoption/add',
    requestBody
  );
  return response;
}

export async function addTrialOptionForStub(
  requestBody: AddTrialOptionRequest
) {
  const response = await jsonPost('/trialoption/addforstubuser', requestBody);
  return response;
}

export async function updateParticipantTrialOption(
  requestBody: UpdateParticipantTrialOptionRequest
): Promise<UpdateParticipantTrialOptionResponse> {
  const response: UpdateParticipantTrialOptionResponse = await jsonPost(
    '/trialoption/updateLocale',
    requestBody
  );
  return response;
}

export async function createParticipantBinder(
  requestBody: CreateParticipantBinderRequest
): Promise<CreateParticipantBinderResponse> {
  const response: CreateParticipantBinderResponse = await jsonPost(
    '/trialoption/binder/create',
    requestBody
  );
  return response;
}

export async function downloadParticipantBinder(
  requestBody: CreateParticipantBinderRequest
): Promise<DownloadParticipantBinderResponse> {
  const response: DownloadParticipantBinderResponse = await jsonPost(
    '/trialoption/binder/download',
    requestBody
  );
  return response;
}
