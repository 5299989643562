import moment from 'moment-timezone';

export function createDashboardBg(color: string = '#00ADFF') {
  const svg = [
    `<svg width="360" height="498" viewBox="0 0 360 498" fill="none" xmlns="http://www.w3.org/2000/svg">`,
    `<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L361 109.603V465L0 497.304V0Z" fill="url(#pd_bg_gradient)"/>`,
    `<defs>`,
    `<linearGradient id="pd_bg_gradient" x1="0" y1="0" x2="0" y2="497" gradientUnits="userSpaceOnUse">`,
    `<stop stop-color="${color}" stop-opacity="0.1"/>`,
    `<stop offset="1" stop-color="#F4F2F3" stop-opacity="0.01"/>`,
    `</linearGradient>`,
    `</defs>`,
    `</svg>`,
  ].join('');

  let result = {
    background: '#fefefe',
    backgroundSize: '100%',
  };

  try {
    result.background = `url(data:image/svg+xml;base64,${window.btoa(
      svg
    )}) no-repeat, #fefefe`;
  } catch (err) {
    console.error('Error on encode the background!', { err });
  }

  return result;
}

export function whenStringFromBeginningAndMaybeEnd(
  currentDateInput: any,
  verbInput: string | null | undefined,
  beginInput: any,
  endInput: any,
  timeFormat: string,
  t: any
) {
  const begin = moment(beginInput);
  const end = endInput ? moment(endInput) : null;
  const currentDate = moment(currentDateInput);
  const verb = verbInput || t('manageVisitActionCard.dates.due');
  const diffWithBegin = begin
    .clone()
    .startOf('day')
    .diff(currentDate.clone().startOf('day'), 'd');

  // End exists
  if (end) {
    const diffWithEnd = end
      .clone()
      .startOf('day')
      .diff(currentDate.clone().startOf('day'), 'd');
    // Totally overdue
    if (currentDate > end) {
      if (diffWithEnd === 0)
        return t('manageVisitActionCard.dates.missedToday');
      return diffWithEnd === -1
        ? t('manageVisitActionCard.dates.missDay', { diffWithEnd })
        : t('manageVisitActionCard.dates.missDays', { diffWithEnd });
    }

    // Last day to submit
    if (diffWithEnd === 0) {
      // In window
      if (currentDate > begin)
        return t('manageVisitActionCard.dates.before', {
          verb,
          endDate: end.format(timeFormat),
        });
      // Before window, same day
      return t('manageVisitActionCard.dates.at', {
        verb,
        beginDate: begin.format(timeFormat),
      });
    }

    // Time to spare
    if (currentDate > begin)
      return t('manageVisitActionCard.dates.today', { verb });
  }

  // These DCs have no end time, or perhaps an end time that is equal to the begin
  if (currentDate.isSame(begin, 'd'))
    return t('manageVisitActionCard.dates.today', { verb });
  if (currentDate > begin)
    return diffWithBegin === -1
      ? t('manageVisitActionCard.dates.dueDay', { diffWithBegin })
      : t('manageVisitActionCard.dates.dueDays', { diffWithBegin });
  if (diffWithBegin === 1)
    return t('manageVisitActionCard.dates.tomorrow', { verb });
  return t('manageVisitActionCard.dates.inDays', { verb, diffWithBegin });
}
