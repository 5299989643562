import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';

interface SignatureFooterProps {
  attestations: any[];
  hasReviewedAllCrfs: any;
  openDialog: any;
  originalAssessment: any;
  setUserAttestsToReviewing: any;
  userAttestsToReviewing: any;
}

const SignatureFooter = (props: SignatureFooterProps) => {
  const { t } = useTranslation('translations');
  const {
    attestations,
    hasReviewedAllCrfs,
    openDialog,
    originalAssessment,
    setUserAttestsToReviewing,
    userAttestsToReviewing,
  } = props;
  const userFinishedAttestations =
    Object.values(userAttestsToReviewing).length === attestations.length &&
    Object.values(userAttestsToReviewing).every(Boolean);

  const footerContent = (
    <div className='signoff-footer'>
      <em className='instructions'>
        {!hasReviewedAllCrfs ? `(${t('signatureFooter.scrollBottomMsg')})` : ''}
      </em>
      <div className='attestation-container'>
        {originalAssessment && (
          <div className='originalAssessment'>{originalAssessment}</div>
        )}
        <div className='attestations'>
          {attestations.map(({ main, sub }, i) => (
            <div
              className='attestation'
              key={i}
              onClick={
                hasReviewedAllCrfs
                  ? () => setUserAttestsToReviewing(i)
                  : () => {}
              }
            >
              <div className='attestation-checkbox'>
                <Checkbox
                  color='primary'
                  // @ts-ignore
                  checked={userAttestsToReviewing?.[[i]] ?? false}
                  disabled={!hasReviewedAllCrfs}
                />
              </div>
              <div className='attestation-text'>
                <span>{main}</span>
                {sub}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Button
        variant='contained'
        color='primary'
        className='signoff-botton'
        onClick={openDialog}
        disabled={!hasReviewedAllCrfs || !userFinishedAttestations}
      >
        {t('signatureFooter.provideSignatureBtn')}
      </Button>
    </div>
  );
  const footerRef = document.getElementById('sticky-footer');

  return footerRef ? ReactDOM.createPortal(footerContent, footerRef) : null;
};

export { SignatureFooter };
