export interface TimezoneState {
  timezone: string;
  dateSet: number;
}

export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';
export const CHANGE_TIMEZONE_WITHOUT_CLOCK_RESET =
  'CHANGE_TIMEZONE_WITHOUT_CLOCK_RESET';

interface BasicTimezonActionInterface {
  payload: {
    timezone: string;
  };
}

//update timezone and reset the clock
export interface UpdateTimezoneAction extends BasicTimezonActionInterface {
  type: typeof UPDATE_TIMEZONE;
}

//change timezone in the redux store without resetting the clock
export interface ChangeTimezoneNoClockResetAction
  extends BasicTimezonActionInterface {
  type: typeof CHANGE_TIMEZONE_WITHOUT_CLOCK_RESET;
}

export type TimezoneActionTypes =
  | UpdateTimezoneAction
  | ChangeTimezoneNoClockResetAction;
