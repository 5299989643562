import React from 'react';
import {
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  details?: string | React.ReactNode;
  buttons?: React.ReactNode[];
  disabled?: boolean;
};

function ExpansionPanelItem(props: Props): React.ReactElement {
  const { title, subtitle, details, buttons, disabled } = props;

  return (
    <ExpansionPanel className='expansion-panel' square disabled={disabled}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className='summary-container'>
          <div className='summary-info'>
            <span className='title'>{title}</span>
            <span className='subtitle'>{subtitle}</span>
          </div>
          <div className='summary-actions'>
            {React.Children.toArray(buttons)}
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className='details-container'>
        {details}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default ExpansionPanelItem;
