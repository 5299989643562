import { Strength } from 'src/lib/password';

export const colors = {
  gray: {
    light: '#cccccc',
    regular: '#606060',
    dark: '#999999',
    darker: '#444444',
  },
  blue: {
    light: '#dff3fd',
    regular: '#00a2e5',
    dark: '#00c8f8',
    darker: '#006E8B',
  },
  yellow: {
    light: '#ffe3ae',
    dark: '#ffa834',
  },
};

export const blue1 = '#3b808e';
export const blue2 = '#00c8f8';
export const gray1 = '#f8f8f8';
export const gray2 = '#333333';
export const gray3 = '#929292';
export const curebaseGreen = '#3b808e';

export const lightBorder = '#cccccc';
export const darkBorder = '#929292';

export enum StatusColor {
  Gray = 'gray',
  Yellow = 'yellow',
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
}

export const statusBackgroundColors: Record<StatusColor, string> = {
  gray: '#d8d8d8',
  yellow: '#ffe3ae',
  red: '#ffcccc',
  green: '#72bb53',
  blue: '#00c8f8',
};

export const statusColors: Record<StatusColor, string> = {
  gray: '#929292',
  yellow: '#ffb040',
  red: '#ff3823',
  green: '#72bb53',
  blue: '#00a2e5',
};

export const strengthColors: Record<Strength, string> = {
  [Strength.None]: 'transparent',
  [Strength.Weak]: '#DA4240',
  [Strength.Decent]: '#D2600F',
  [Strength.Strong]: '#639950',
};

function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToHex(i: number): string {
  let c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

function hexToRGB(hex: string) {
  const result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

export function colorFromText(str: string) {
  const { r, g, b } = hexToRGB(intToHex(hashCode(str)));
  return `rgba(${r}, ${g}, ${b}, 0.15)`;
}
