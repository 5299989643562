import * as React from 'react';
import moment from 'moment-timezone';

import ListItem from '../basic/ListItem';
import { viewParticipantBaseRoute } from '../navigation/clinic';
import { useTranslation } from 'react-i18next';
import { getLocaleFormatDates } from '../../context/localeContext';

type VisitBookingLIProps = {
  getMoment: (unixTime: number) => moment.Moment;
  historyPush: (path: string) => any;
  hasConfirmed: boolean;
  clinicId: string;
  start: number;
  end: number;
  trialOption: {
    id: number;
    subjectIdentifier: string;
    patient: {
      id: number;
    };
    trial: {
      id: number;
      name: string;
    };
  };
};

const VisitBookingLI = ({
  getMoment,
  hasConfirmed,
  start,
  end,
  trialOption: {
    id: trialOptionId,
    subjectIdentifier,
    patient: { id: patientId },
    trial: { id: trialId, name: trialName },
  },
  historyPush,
  clinicId,
}: VisitBookingLIProps) => {
  const { t } = useTranslation('translations');
  const localesFormat = getLocaleFormatDates();
  return (
    <ListItem
      // @ts-ignore
      status={{
        type: hasConfirmed ? 'COMPLETED' : 'AVAILABLE',
        action: hasConfirmed ? null : 'Confirmation needed',
      }}
      middle={{
        title: subjectIdentifier,
        subtitle: trialName,
        text: t('visitBookingLI.listText', {
          start: getMoment(start).format(localesFormat.moment.longTimeMeridiem),
          end: getMoment(end).format(localesFormat.moment.longTimeMeridiemZone),
        }),
      }}
      onClick={() =>
        historyPush(`${viewParticipantBaseRoute}/${trialOptionId}`)
      }
    />
  );
};

export default VisitBookingLI;
