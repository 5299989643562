import {
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  IS_STAGING,
} from '@curebase/core/lib/env';
import { RolePermissions } from '@curebase/core/types';
import _isEqual from 'lodash/isEqual';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import Auth from './components/Auth/Auth';
import RemoteCapture from './components/DataCapture/RemoteCapture';
import ErrorPage from './components/ErrorPage';
import ExploreCreatePassword from './components/Explore/ExploreCreatePassword';
import GraphQLAssociateFetcher from './components/GraphQLAssociateFetcher';
import GraphQLFetcher from './components/GraphQLFetcher';
import Legal from './components/Legal/Legal';
import Loading from './components/Loading';
import PaypalRoutes from './components/PayPal/PayPalRouter';
import RevisionSummary from './components/RevisionSummary/RevisionSummary';
import MaintenanceDialog from './components/Utility/MaintenanceDialog';
import { getLocale } from './context/localeContext';
import { checkSession, signoutSession } from './controllers/authController';
import { getSessionExpiredRedirect, isAuthenticatedHelper } from './lib/auth';
import { captureError } from './lib/error';
import {
  ensureFullStoryIdentity,
  setFullStoryStatusFromPath,
} from './lib/fullStory';
import { handleUserChangeInStore } from './lib/users';
import {
  addListenerToZendeskLauncher,
  zendeskSetLocale,
} from './lib/zendeskWidget';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import RestrictedRoute from './RestrictedRoute';
import { USE_LOCAL_FULLSTORY } from './sentry';
import { Route } from 'react-router-dom';

// Chunked routes for faster page loads
// More information at https://reactjs.org/docs/code-splitting.html
const Explore = lazy(
  () =>
    import(
      /* webpackChunkName:"route-explore" */
      './components/Explore/Routes'
    )
);
const DownloadAppRouteHandler = lazy(
  () =>
    import(
      /* webpackChunkName:"route-explore" */
      './components/ParticipantInterface/DownloadApp/DownloadAppRouteHandler'
    )
);
const ComponentSandbox = lazy(
  () =>
    import(
      /* webpackChunkName:"route-component-sandbox" */
      './components/ComponentSandbox'
    )
);
const TrialBuilder = lazy(
  () =>
    import(
      /* webpackChunkName:"route-TrialBuilder" */
      './components/TrialBuilder/TrialBuilder'
    )
);

// Values that are watched by the store listeners;
let lastUserInStore;

// type Props = OwnProps & ReduxProps;
interface AppViewProps extends RouteComponentProps {
  isFullStoryEnabled: boolean;
  userId: number | null | undefined;
  userInStore: {
    [key: string]: any;
  };
  store: any;
  maintenanceModeEnabled: boolean;
}

type State = {
  storeIsUpToDate: boolean;
  hasChildError: boolean;
  currentUserId: number | null | undefined;
  errorId: string | null;
};

const VERIFY_SESSION_TIME_MILLISECONDS = 150000; // 2.5 minutes

class AppView extends Component<AppViewProps, State> {
  state = {
    storeIsUpToDate: false,
    hasChildError: false,
    currentUserId: null,
    errorId: null,
  };

  verifySession = async () => {
    const { store, location } = this.props;
    const storeUser = store.getState().user;
    if (
      storeUser &&
      storeUser.userId &&
      !location.pathname.startsWith('/auth')
    ) {
      try {
        await checkSession(storeUser.userId);
      } catch (e) {
        await signoutSession();
        window.location.href = getSessionExpiredRedirect(location.pathname);
      }
    }
  };

  // Ensure the FullStory is default to OFF whenever route changes, as a precaution
  // This forces individual components to turn it ON
  checkFullStory = location => {
    const { isFullStoryEnabled, userId } = this.props;
    // Special case to ensure that unauthenticated sessions, once authenticated, are tied to the user
    if (!this.state.currentUserId && !!userId) {
      ensureFullStoryIdentity(userId);
    }

    this.setState({ currentUserId: userId }, () => {
      setFullStoryStatusFromPath(location.pathname, isFullStoryEnabled, userId);
    });
  };

  // Event trigger when the user ID in the store changes
  handleChangeInStore = () => {
    const { userInStore } = this.props;
    // Deep comparison
    if (!_isEqual(lastUserInStore, userInStore)) {
      lastUserInStore = userInStore;
      handleUserChangeInStore(userInStore);
    }
  };

  // Fire store change listeners once at load time
  installStoreListeners = () => {
    const { store } = this.props;
    store.subscribe(this.handleChangeInStore);
    this.handleChangeInStore();
  };

  async componentDidMount() {
    await this.verifySession();
    setInterval(this.verifySession, VERIFY_SESSION_TIME_MILLISECONDS);
    this.setState({ storeIsUpToDate: true });

    // Install full story but ensure it is OFF by default (only in PROD or when testing analytics)
    const { history, isFullStoryEnabled, userId } = this.props;
    if (IS_PRODUCTION || USE_LOCAL_FULLSTORY) {
      setFullStoryStatusFromPath(
        history.location.pathname,
        isFullStoryEnabled,
        userId
      );
    }

    history.listen(this.checkFullStory);

    this.installStoreListeners();
    // exclude zendesk from FullStory
    addListenerToZendeskLauncher();

    //Setting the language to the Chat
    const { language, country } = getLocale();
    zendeskSetLocale(`${language}_${country.toLocaleUpperCase()}`);
  }

  componentDidCatch(error, info) {
    const errorId = captureError(error);
    this.setState({ hasChildError: true, errorId });
  }

  render() {
    const { store, maintenanceModeEnabled } = this.props;
    const { hasChildError, storeIsUpToDate, errorId } = this.state;

    if (hasChildError) {
      return <ErrorPage errorId={errorId} />;
    }

    if (!storeIsUpToDate) {
      return <Loading />;
    }

    const isAuthenticated = !!isAuthenticatedHelper(store.getState().user);

    return (
      <>
        {maintenanceModeEnabled && (
          <MaintenanceDialog open={maintenanceModeEnabled} />
        )}
        <Suspense fallback={null}>
          <Switch>
            <Route
              exact
              path={'/download'}
              render={() => <DownloadAppRouteHandler />}
            />
            <Route exact path='/query' component={GraphQLFetcher} />
            {IS_DEVELOPMENT || IS_STAGING ? (
              <Route
                exact
                path='/dev-query'
                render={() => <GraphQLAssociateFetcher endpoint='graphql' />}
              />
            ) : null}

            <PublicRoute path='/explore/:trialIdentifier' component={Explore} />
            <PrivateRoute path='/u' component={ComponentSandbox} />
            <Route
              path='/signup/:accessCode'
              render={() => {
                return (
                  <div className='signup-content'>
                    <ExploreCreatePassword />
                  </div>
                );
              }}
            />
            <Route path='/auth' component={Auth} />
            <Route path='/legal' component={Legal} />
            <Route path='/paypal' component={PaypalRoutes} />
            <Route path='/error' component={ErrorPage} />
            <RestrictedRoute
              permission={RolePermissions.TrialBuilder}
              path='/trialbuilder/:trialSlug/:configName'
              component={TrialBuilder}
              customHeader={true}
              remount={false}
            />
            <RestrictedRoute
              permission={RolePermissions.TrialBuilder}
              path='/revision/:trialIdentifier/:version'
              component={RevisionSummary}
              customHeader={true}
              remount={false}
            />
            <Route path='/remote/:token' component={RemoteCapture} />
            <Route
              render={() => {
                if (!isAuthenticated) {
                  return <Redirect to='/auth/login' />;
                }
                return <Redirect to='/u' />;
              }}
            />
          </Switch>
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isFullStoryEnabled: state.fullStory.isEnabled,
  userId: state.user.userId,
  userInStore: state.user, // Used for the store listener
  maintenanceModeEnabled: state.maintenanceMode,
});

export default connect(mapStateToProps)(withRouter(AppView));
