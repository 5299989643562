import React from 'react';
import Button from '@material-ui/core/Button';
import ScrollIcon from '@material-ui/icons/VerticalAlignBottom';
import { adoptLegalDocument } from '../../controllers/userController';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';
import { getUser } from '../../store';
import {
  DocumentHTML,
  queryLegalDocumentHTML,
} from 'src/shared/lib/queryHelpers';
import { executeQuery } from 'src/ApolloClient';
import { PolicyDocumentType } from '@curebase/core/types';
import { TFunction, withTranslation } from 'react-i18next';

type Props = {
  documentType: PolicyDocumentType;
  onAdopt: () => void;
  t: TFunction;
};

type LegalDoc = { documentHtml: DocumentHTML; policyVersion?: string };

type State = {
  documentHtmls?: LegalDoc[];
  updateCount: number;
};

class LegalDocument extends React.Component<Props, State> {
  endOfPage: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      updateCount: 0,
    };
    this.endOfPage = null;
  }

  loadMarkdown = async () => {
    const { documentType } = this.props;

    if (documentType === PolicyDocumentType.CookiePolicy) {
      const documentHtml = await queryLegalDocumentHTML(documentType);
      this.setState({ documentHtmls: [{ documentHtml }] });
    } else {
      const privacyPolicies = await executeQuery(`
        getUnacceptedVersionsForPolicyDocuments(documentType: ${documentType}) {
          policyVersionName
        }
      `);

      type PolicyVersion = {
        policyVersionName: string;
      };

      const htmls = await Promise.all(
        (privacyPolicies.getUnacceptedVersionsForPolicyDocuments as PolicyVersion[]).map(
          async ({ policyVersionName }) => {
            const documentHtml = await queryLegalDocumentHTML(
              documentType,
              policyVersionName
            );
            return { documentHtml, policyVersion: policyVersionName };
          }
        )
      );

      this.setState({ documentHtmls: htmls });
    }
  };

  async componentDidMount() {
    this.loadMarkdown();
  }

  async onSubmit(remainderHtmls: LegalDoc[], currentDocument: LegalDoc) {
    await adoptLegalDocument(
      this.props.documentType,
      currentDocument.policyVersion
    );
    if (remainderHtmls.length > 0) {
      this.setState({ documentHtmls: remainderHtmls });
    } else {
      this.props.onAdopt();
    }
  }

  render() {
    const { t } = this.props;
    const user = getUser();
    if (user?.additionalUserInfo?.isStub) {
      throw new Error(
        `Cannot adopt terms of service for stub user id: ${user?.userId}`
      );
    }
    const { documentHtmls } = this.state;
    if (!documentHtmls) {
      return null;
    }

    const currentElem = documentHtmls.pop();

    return (
      <div className='legal-document-page'>
        <div className='legal-document-container'>
          <div className='scroll-button-container'>
            {DEVELOPER_FEATURES_ENABLED ? (
              <Button
                variant='contained'
                color='secondary'
                onClick={async () => {
                  await adoptLegalDocument(PolicyDocumentType.TermsOfService);
                  await adoptLegalDocument(PolicyDocumentType.PrivacyPolicy);
                  this.props.onAdopt();
                }}
              >
                Accept TOS [DEVELOPMENT ONLY]
              </Button>
            ) : null}
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                this.endOfPage &&
                  this.endOfPage.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <ScrollIcon />
              {t('legalDocument.scrollBtn')}
            </Button>
          </div>

          <div dangerouslySetInnerHTML={currentElem!.documentHtml} />

          <div
            className='legal-document-accept-button'
            ref={el => {
              this.endOfPage = el;
            }}
          >
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => {
                this.onSubmit(documentHtmls, currentElem!);
              }}
            >
              {t('legalDocument.iAccept')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(LegalDocument);
