import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

export const getLastOrderNumber = (machine: any): number => {
  return Object.values(machine).reduce<number>((max: number, state: any) => {
    if (max < state.order) return state.order;
    return max;
  }, 0);
};

const defaultActions: Record<string, string> = {
  WITHDRAW: 'WITHDRAWN',
  OPT_OUT: 'OPTED_OUT',
};

const defaultStates: Record<string, Object> = {
  OPTED_OUT: {},
  WITHDRAWN: {},
};

export const extendTrialMachineDefault = (originalMachine: any) => {
  let machine = cloneDeep(originalMachine);
  let lastOrderNumber = getLastOrderNumber(originalMachine);

  Object.keys(machine).forEach(stateName => {
    const state = machine[stateName];

    if (defaultStates[stateName]) return;

    if (state.on) {
      state.on = merge(cloneDeep(defaultActions), state.on);
    } else {
      state.on = cloneDeep(defaultActions);
    }
  });

  machine = merge(cloneDeep(defaultStates), machine);

  Object.keys(machine).forEach(key => {
    if (!defaultStates[key]) return;
    machine[key].order = lastOrderNumber += 1;
  });

  return machine;
};

export const removeTrialMachineDefault = (originalMachine: any) => {
  const hasDataOnDefaultState = (state: any) => Object.keys(state).length > 1;
  const machine = cloneDeep(originalMachine);

  Object.keys(machine).forEach(key => {
    const state = machine[key];

    if (defaultStates[key] && !hasDataOnDefaultState(state)) {
      delete machine[key];
    }

    if (state.on) {
      Object.keys(defaultActions).forEach(action => {
        if (!state.on) return;
        if (state.on[action] === defaultActions[action]) {
          delete state.on[action];
        }

        if (isEmpty(state.on)) {
          delete state.on;
        }
      });
    }
  });

  return machine;
};
