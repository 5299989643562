import React from 'react';
import { useTranslation } from 'react-i18next';
import OneClickPicker from '../../basic/OneClickPicker';

type Props = {
  onScheduleForNow: () => void | Promise<void>;
  onScheduleForLater: () => void | Promise<void>;
};

function BookerNowOrLater({ onScheduleForNow, onScheduleForLater }: Props) {
  const { t } = useTranslation('translations');

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>{t('bookerNowOrLater.title')}</div>

      <div className='booker-text'>{t('bookerNowOrLater.text')}</div>

      <OneClickPicker
        options={[
          {
            title: t('bookerNowOrLater.pickOptions.conductVisit.title'),
            subtitle: t('bookerNowOrLater.pickOptions.conductVisit.subtitle'),
            value: 'SCHEDULE_FOR_NOW',
          },
          {
            title: t('bookerNowOrLater.pickOptions.scheduleVisit.title'),
            subtitle: t('bookerNowOrLater.pickOptions.scheduleVisit.subtitle'),
            value: 'SCHEDULE_FOR_LATER',
          },
        ]}
        onSelect={v => {
          if (v === 'SCHEDULE_FOR_NOW') return onScheduleForNow();
          onScheduleForLater();
        }}
      />
    </div>
  );
}

export default BookerNowOrLater;
