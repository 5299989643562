import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BookerRootContext } from './BookerDialog';

interface BookerCancelProps {
  onClose: () => void | Promise<void>;
  isRescheduling: Boolean;
}

function BookerManualCancel(props: BookerCancelProps) {
  const { t } = useTranslation('translations');
  const config = useContext(BookerRootContext);
  const { participantCancel, participantCancelButton } =
    config?.config?.default?.bookerSuccessText ?? {};

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>
        {props.isRescheduling
          ? t(
              'schedulingConfigDialog.form.partDoNotEffectiveCancel.dialogRescheduleTitle'
            )
          : t(
              'schedulingConfigDialog.form.partDoNotEffectiveCancel.dialogTitle'
            )}
      </div>
      <div className='booker-text'>{participantCancel}</div>
      <div className='booker-button-container'>
        <div className='booker-button' role='button' onClick={props.onClose}>
          {participantCancelButton ?? t('common.ok')}
        </div>
      </div>
    </div>
  );
}

export default BookerManualCancel;
