import {
  PatientIntegrationSourceDataType,
  StudyActivity,
} from '@curebase/core/types';
import ActionCard from '../basic/ActionCard';
import _capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

interface FitbitActionCardProps {
  activity: StudyActivity;
}

function FitbitActionCard(props: FitbitActionCardProps) {
  const { t } = useTranslation('translations');
  const redirectUri = props.activity.content.redirectUri;

  return (
    <ActionCard
      title='Connect Your Fitbit'
      iconSrc={`/icons/actionCard${_capitalize(
        PatientIntegrationSourceDataType.Wellness
      )}.svg`}
      buttons={[
        {
          text: t('participants.connectFitbit.button'),
          onClick: () => {
            window.location.href = redirectUri;
          },
        },
      ]}
    />
  );
}

export default FitbitActionCard;
