import { useTranslation } from 'react-i18next';

export default function PageFooter() {
  const { t } = useTranslation('translations');

  function manageCookiePreferences() {
    (window as any).OneTrust.ToggleInfoDisplay();
  }

  return (
    <footer className={'page-footer no-print'}>
      <nav>
        <ul>
          <li>
            <span>© {new Date().getUTCFullYear()} Curebase</span>
          </li>
          <li>
            <a
              href='https://www.curebase.com/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              {t('footer.privacyPolicy')}
            </a>
          </li>
          <li>
            <a
              href='https://www.curebase.com/terms-of-use'
              target='_blank'
              rel='noreferrer'
            >
              {t('footer.termsOfUse')}
            </a>
          </li>
          <li>
            <a id='ot-sdk-btn' href='##' onClick={manageCookiePreferences}>
              {t('footer.manageCookiePreferences')}
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
}
