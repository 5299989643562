import { Typography } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import React from 'react';

export function DocumentHeaderSubtext(props: {
  headerIcon?: {};
  headerSubtextTop: string;
  headerSubtextBottom: string;
}) {
  return (
    <div className='ic-header-subtext'>
      <Typography variant='h4'>
        {props.headerIcon ? props.headerIcon : <EditOutlined />}
      </Typography>
      <div className='ic-header-subtext-container'>
        <Typography variant='h4' className='ic-header-subtext-top'>
          {props.headerSubtextTop}
        </Typography>
        <Typography className='ic-header-subtext-bottom'>
          {props.headerSubtextBottom}
        </Typography>
      </div>
    </div>
  );
}
