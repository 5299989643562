import 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InputAdornment from '@material-ui/core/InputAdornment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import IconButton from '@material-ui/core/IconButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { FuzzyDatePickerSubElement } from '@curebase/core/lib/dynamicform/types';
import { FuzzyDate } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';

const now = new Date();

const WHEN = {
  I_KNOW_EXACTLY: 'I_KNOW_EXACTLY',
  I_AM_NOT_SURE: 'I_AM_NOT_SURE',
};

const YEARS = {
  BEGIN: 1969,
  END: now.getFullYear(),
};

const MONTHS = {
  BEGIN: 0,
  END: 11,
};

const MONTHS_MAP = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export const ReadOnlyFuzzyDate = ({ value }: { value: FuzzyDate }) => {
  const { t } = useTranslation('translations');
  if (!value) return <span>{`(${t('formResults.fuzzyDate.none')})`}</span>;
  const { date, year, month, isExactDate } = value;
  const fuzzyDate: number[] = [];
  if (typeof month === 'number') {
    fuzzyDate.push(month + 1);
  }
  if (typeof date === 'number') {
    fuzzyDate.push(date);
  }
  if (typeof year === 'number') {
    fuzzyDate.push(year);
  }
  const finalDate = fuzzyDate.join('/');
  return (
    <>
      {finalDate} (
      {isExactDate
        ? t('formResults.fuzzyDate.exact')
        : t('formResults.fuzzyDate.notExact')}
      )
    </>
  );
};

// https://stackoverflow.com/a/61481154
const StyledToggleButton = withStyles({
  root: {
    fontSize: '1rem',
    lineHeight: '16px',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    '&$selected': {
      backgroundColor: 'rgba(73, 146, 151, 0.14)',
      color: 'rgba(73, 146, 151, 1)',
      '&:hover': {
        backgroundColor: 'rgba(73, 146, 151, 0.14)',
        color: 'rgba(73, 146, 151, 1)',
      },
    },
  },
  selected: {},
})(ToggleButton);

// https://stackoverflow.com/a/61687893
const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 250,
  },
  inputAdornment: {
    backgroundColor: '#ffffff',
  },
}));

interface FuzzyDatePickerInputProps {
  data: any;
  pageIndex: number;
  subEle: FuzzyDatePickerSubElement;
  onChange: (
    subEle: FuzzyDatePickerSubElement,
    value: any,
    pageIndex: number
  ) => void;
}
export default function FuzzyDatePicker(props: FuzzyDatePickerInputProps) {
  const { t } = useTranslation('translations');
  const classes = useStyles();

  const [isVisible, setIsVisible] = useState(false);
  const [dateInput, setDateInput] = useState(WHEN.I_KNOW_EXACTLY);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  // const [lastMonthOfTheYear, setLastMonthOfTheYear] = useState(0); // @TODO: enable this to disable future dates
  const [lastDayOfTheMonth, setLastDayOfTheMonth] = useState(0);

  const [year, setYear] = useState<number | null>(null);
  const [month, setMonth] = useState<number | null>(null);
  const [date, setDate] = useState<number | null>(null);

  const { onChange, subEle, pageIndex } = props;

  useEffect(() => {
    const fullDate = props.data[subEle.key] as FuzzyDate;

    if (!!fullDate) {
      const { isExactDate, year, month, date } = fullDate;

      if (typeof isExactDate === 'boolean') {
        setDateInput(isExactDate ? WHEN.I_KNOW_EXACTLY : WHEN.I_AM_NOT_SURE);
      }
      if (typeof year === 'number') setYear(year);
      if (typeof month === 'number') {
        setMonth(month);
      }
      if (typeof date === 'number') setDate(date);
    }
    // eslint-disable-next-line
  }, []);

  const setFuzzyDate = useCallback(() => {
    const fuzzyDate: number[] = [];
    if (typeof month === 'number') {
      fuzzyDate.push(month + 1);
    }
    if (typeof date === 'number') {
      fuzzyDate.push(date);
    }
    if (typeof year === 'number') {
      fuzzyDate.push(year);
    }
    setSelectedDate(fuzzyDate.join('/'));
  }, [year, month, date]);

  useEffect(() => {
    setFuzzyDate();
  }, [year, month, date, setFuzzyDate]);

  const handleAlignment = (_, newAlignment) => {
    if (newAlignment !== null) {
      setDateInput(newAlignment);
    }
  };

  const handleSelectYear = value => {
    // @TODO: enable this to disable future dates
    // if (value === now.getFullYear()) {
    //   setLastMonthOfTheYear(now.getMonth() + 1);
    // } else {
    //   setLastMonthOfTheYear(MONTHS.END);
    // }
    setYear(value);
  };

  const handleSelectMonth = value => {
    setDate(null);
    setMonth(value);
  };

  const updateLastDayOfTheMonth = useCallback(
    value => {
      // @TODO: enable this to disable future dates
      // if (value === now.getMonth()) {
      //   setLastDayOfTheMonth(now.getDate());
      // } else {
      const total = getDaysInMonth(new Date(`${value + 1}/1/${year}`));
      setLastDayOfTheMonth(total);
      // }
    },
    [year]
  );

  useEffect(() => {
    if (typeof month === 'number') updateLastDayOfTheMonth(month);
  }, [month, updateLastDayOfTheMonth]);

  const handleDateChange = date => {
    setSelectedDate(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
    onChange(
      subEle,
      {
        isExactDate: dateInput === WHEN.I_KNOW_EXACTLY,
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
      },
      pageIndex
    );
    setIsVisible(false);
  };

  const handleSave = () => {
    onChange(
      subEle,
      {
        isExactDate: dateInput === WHEN.I_KNOW_EXACTLY,
        year,
        month,
        date,
      },
      pageIndex
    );
    setIsVisible(false);
  };

  const toggleDateInput = dateInput => {
    setDateInput(dateInput);
    setYear(null);
    setMonth(null);
    setDate(null);
    setSelectedDate('');
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <TextField
          fullWidth
          variant='outlined'
          value={selectedDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color='primary'
                  aria-label='calendar'
                  component='span'
                  onClick={() => setIsVisible(!isVisible)}
                >
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            ),
            classes: { adornedEnd: classes.inputAdornment },
            onKeyDown: event => {
              event.preventDefault();
            },
            onFocus: () => {
              setIsVisible(true);
            },
          }}
        />
        <Paper
          style={
            isVisible
              ? {
                  top: '100%',
                  left: 0,
                  zIndex: Number.MAX_SAFE_INTEGER,
                  width: '330px',
                }
              : { display: 'none' }
          }
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              zIndex: Number.MAX_SAFE_INTEGER,
            }}
          >
            <ToggleButtonGroup
              value={dateInput}
              exclusive
              onChange={handleAlignment}
              aria-label='fuzzy date picker'
              style={{ margin: '20px 0px 10px 0px' }}
            >
              <StyledToggleButton
                value={WHEN.I_KNOW_EXACTLY}
                aria-label='exact date'
                onClick={() => toggleDateInput(WHEN.I_KNOW_EXACTLY)}
              >
                <span>{t('fuzzyDatePicker.knowExactLabel')}</span>
              </StyledToggleButton>
              <StyledToggleButton
                value={WHEN.I_AM_NOT_SURE}
                aria-label='not sure date'
                onClick={() => toggleDateInput(WHEN.I_AM_NOT_SURE)}
              >
                <span>{t('fuzzyDatePicker.notSureLabel')}</span>
              </StyledToggleButton>
            </ToggleButtonGroup>
            <Divider
              variant='middle'
              style={{ width: '80%', margin: '10px' }}
            />
            {dateInput === WHEN.I_KNOW_EXACTLY ? (
              <KeyboardDatePicker
                disableToolbar
                variant='static'
                format='MM/dd/yyyy'
                margin='normal'
                value={selectedDate}
                onChange={handleDateChange}
                // maxDate={new Date()} // @TODO: enable this to disable future dates
                KeyboardButtonProps={{
                  'aria-label': 'select date',
                }}
              />
            ) : (
              <>
                <FormLabel
                  component='legend'
                  style={{ marginBottom: '10px', marginTop: '10px' }}
                >
                  {t('fuzzyDatePicker.tellWhatKnowLabel')}
                </FormLabel>
                <FormControl
                  variant='standard'
                  style={{ minWidth: 260, margin: '10px' }}
                  required
                >
                  <InputLabel>{t('fuzzyDatePicker.yearLabel')}</InputLabel>
                  <Select
                    value={year}
                    onChange={e => handleSelectYear(e.target.value)}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {new Array(YEARS.END - YEARS.BEGIN)
                      .fill(0)
                      .map((_, index) => (
                        <MenuItem value={YEARS.END - index}>
                          {YEARS.END - index}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant='standard'
                  style={{ minWidth: 260, margin: '10px' }}
                  disabled={typeof year !== 'number'}
                >
                  <InputLabel>{t('fuzzyDatePicker.monthLabel')}</InputLabel>
                  <Select
                    value={month}
                    onChange={e => handleSelectMonth(Number(e.target.value))}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {new Array(MONTHS.END + 1).fill(0).map((_, index) => (
                      <MenuItem value={index}>{MONTHS_MAP[index]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant='standard'
                  style={{ minWidth: 260, margin: '10px' }}
                  disabled={typeof month !== 'number'}
                >
                  <InputLabel>{t('fuzzyDatePicker.dayLabel')}</InputLabel>
                  <Select
                    value={date}
                    onChange={e => setDate(Number(e.target.value))}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {new Array(lastDayOfTheMonth).fill(0).map((_, index) => (
                      <MenuItem value={index + 1}>{index + 1}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <DialogActions>
                  <Button onClick={() => setIsVisible(false)} color='primary'>
                    {t('common.cancel')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => handleSave()}
                    color='primary'
                    disabled={!year} // @TODO: disable based on required inputs
                  >
                    {t('common.save')}
                  </Button>
                </DialogActions>
              </>
            )}
          </div>
        </Paper>
      </div>
    </MuiPickersUtilsProvider>
  );
}
