import gte from 'lodash/gte';
import isEqual from 'lodash/isEqual';

export enum Strength {
  None = 'None',
  Weak = 'Weak',
  Decent = 'Decent',
  Strong = 'Strong',
}

/**
 * We use point based strength system
 * Ref: https://uit.stanford.edu/service/accounts/passwords/quickguide
 */
export function calculateStrength(password: string): Strength {
  let points = 0;
  let strength: Strength = Strength.None;

  const { length } = password;

  switch (true) {
    case gte(length, 20):
      points += 4;
      break;

    case gte(length, 17):
      points += 3;
      break;

    case gte(length, 13):
      points += 2;
      break;

    case gte(length, 10):
      points += 1;
      break;

    default:
      break;
  }

  if (containsNumbers(password)) {
    points += 1;
  }

  if (containsUpperCase(password)) {
    points += 1;
  }

  if (containsSpecial(password)) {
    points += 1;
  }

  if (isEqual(points, 1)) {
    strength = Strength.Weak;
  }

  if (isEqual(points, 2)) {
    strength = Strength.Decent;
  }

  if (isEqual(points, 3)) {
    strength = Strength.Decent;
  }

  if (gte(points, 4)) {
    strength = Strength.Strong;
  }

  return strength;
}

export function containsNumbers(password: string): boolean {
  const expression = new RegExp(/\d/);
  return expression.test(password);
}

export function containsUpperCase(password: string): boolean {
  const expression = new RegExp(/[A-Z]/);
  return expression.test(password);
}

export function containsSpecial(password: string): boolean {
  const expression = new RegExp(/[^\da-zA-Z\s]/);
  return expression.test(password);
}
