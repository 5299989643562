import { Box } from '@material-ui/core';
import gte from 'lodash/gte';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  containsNumbers,
  containsSpecial,
  containsUpperCase,
} from 'src/lib/password';

interface Props {
  password: string | undefined;
}

function PasswordRequirements(props: Props): React.ReactElement {
  const { t } = useTranslation('translations');
  const { password = '' } = props;

  const requirements: Array<RequirementProps> = [
    {
      label: t('auth.passwordRequirements.messages.minChars'),
      valid: gte(password.length, 10),
    },
    {
      label: t('auth.passwordRequirements.messages.minUpper'),
      valid: containsUpperCase(password),
    },
    {
      label: t('auth.passwordRequirements.messages.minNum'),
      valid: containsNumbers(password),
    },
    {
      label: t('auth.passwordRequirements.messages.minSpecial'),
      valid: containsSpecial(password),
    },
  ];

  function renderRequirements(item: RequirementProps): React.ReactNode {
    return <Requirement {...item} />;
  }

  return (
    <Box className='password-requirements-container' marginBottom={1}>
      <strong className='requirements-header'>
        {t('auth.passwordRequirements.headerMessage')}
      </strong>
      <Box marginTop={1.5}>
        {React.Children.toArray(requirements.map(renderRequirements))}
      </Box>
    </Box>
  );
}

interface RequirementProps {
  label: string;
  valid: boolean;
}

function Requirement(props: RequirementProps): React.ReactElement {
  const { label, valid } = props;

  let icon: React.ReactNode = (
    <img
      className='requirement-icon'
      src='/icons/password/check-outline.svg'
      alt='check'
    />
  );

  if (valid) {
    icon = (
      <img
        className='requirement-icon'
        src='/icons/password/check.svg'
        alt='check-outline'
      />
    );
  }

  return (
    <div className='requirement-text'>
      {icon}
      {label}
    </div>
  );
}

export default PasswordRequirements;
