import {
  CreateClinicRequest,
  CreateClinicResponse,
  UpdateClinicRequest,
  UpdateClinicResponse,
} from '@curebase/core/decoders/clinics';
import {
  CBSchedulingSearch,
  TimeSlot,
} from '@curebase/modules/scheduling/dto/scheduling';
import { jsonPost } from '../lib/fetchHelpers';
import { SchedulingSystem } from '@curebase/core/types';

export async function createClinic(
  name: string
): Promise<CreateClinicResponse> {
  const requestBody: CreateClinicRequest = { name };
  const response: CreateClinicResponse = await jsonPost(
    '/clinic/create',
    requestBody
  );
  return response;
}

export async function updateClinic(
  clinicId: string,
  data: Object
): Promise<UpdateClinicResponse> {
  const requestBody: UpdateClinicRequest = { clinicId, data };
  const response: UpdateClinicResponse = await jsonPost(
    '/clinic/update',
    requestBody
  );
  return response;
}

export async function getTrialInstanceAvailability(
  trialOptionId: number,
  trialInstanceId: string,
  visitSiteId: number,
  isAssociateOrProvider: boolean,
  schedulingConfigurationId?: string,
  monthVisibleToUser?: string
): Promise<TimeSlot[]> {
  const requestBody: CBSchedulingSearch = {
    type: SchedulingSystem.CurebaseScheduling,
    trialOptionId,
    trialInstanceId,
    visitSiteId,
    isAssociateOrProvider,
    schedulingConfigurationId,
    monthVisibleToUser,
  };
  const response: TimeSlot[] = await jsonPost(
    '/scheduling/availability',
    requestBody
  );
  return response;
}
