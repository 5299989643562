import React from 'react';
import Button from '@material-ui/core/Button';
import DialogHeader from './DialogHeader';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  message: string | JSX.Element;
  onClose?: () => any;
  closeText?: string;
  onConfirm?: () => any;
  disableOnConfirm?: boolean;
  confirmationText?: string;
  children?: React.ReactNode;
  hideCancelButton?: boolean;
  hideCloseButton?: boolean;
};

const ConfirmationInDialog = (props: Props) => {
  const {
    title,
    onClose,
    message,
    onConfirm,
    confirmationText,
    disableOnConfirm = false,
    closeText,
    children,
    hideCloseButton = false,
    hideCancelButton = false,
  } = props;
  const { t } = useTranslation('translations');

  return (
    <>
      <DialogHeader
        title={title}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
      />
      <div className='confirmation-dialog-container'>
        <div className='confirmation-dialog-message'>{message}</div>
        {children}
        <div className='confirmation-dialog-buttons'>
          {!hideCancelButton && (
            <Button color='primary' onClick={onClose} disabled={!onClose}>
              {closeText || t('common.cancel')}
            </Button>
          )}

          {onConfirm && (
            <Button
              color='primary'
              onClick={() => onConfirm()}
              disabled={disableOnConfirm}
            >
              {confirmationText || t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ConfirmationInDialog;
