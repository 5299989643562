import {
  CreateVisitSiteRequest,
  CreateVisitSiteResponse,
  EditVisitSiteRequest,
  EditVisitSiteResponse,
  ActivateVisitSiteRequest,
  ActivateVisitSiteResponse,
  DeactivateVisitSiteRequest,
  DeactivateVisitSiteResponse,
} from '@curebase/core/decoders/visitsites';
import { jsonPost } from '../lib/fetchHelpers';

export async function createVisitSite(
  clinicId: string,
  siteInfo: CreateVisitSiteRequest
): Promise<CreateVisitSiteResponse> {
  return await jsonPost('/sites/create', { ...siteInfo, clinicId });
}

export async function editVisitSite(
  siteInfo: EditVisitSiteRequest
): Promise<EditVisitSiteResponse> {
  const requestBody = { ...siteInfo };
  // @ts-ignore
  delete requestBody.__typename;
  return await jsonPost('/sites/edit', requestBody);
}

export async function activateVisitSite(
  id: number
): Promise<ActivateVisitSiteResponse> {
  const requestBody: ActivateVisitSiteRequest = { id };
  return await jsonPost('/sites/activate', requestBody);
}

export async function deactivateVisitSite(
  id: number
): Promise<DeactivateVisitSiteResponse> {
  const requestBody: DeactivateVisitSiteRequest = { id };
  return await jsonPost('/sites/deactivate', requestBody);
}
