import { createStyles, Theme } from '@material-ui/core';

export const sliderOverrides = (theme: Theme) =>
  createStyles({
    markLabel: {
      fontSize: '.75rem',
      whiteSpace: 'pre-line' as const,
      textAlign: 'center' as const,
      lineHeight: '120%' as const,
    },
    markLabelActive: {
      color: 'rgba(12, 12, 13, 0.72)',
    },
    thumb: {
      backgroundColor: '#fefeff',
      width: '24px',
      height: '24px',
      border: '1px solid rgba(50, 95, 100, 0.4)',
      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(50, 95, 100, 0.12), rgba(50, 95, 100, 0.12)), #FEFEFF;',
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.main,
      },
      marginTop: '-10px',
    },
    rail: {
      height: '4px',
    },
    track: {
      height: '4px',
    },
    mark: {
      marginTop: '1px',
    },
    vertical: {
      '& .MuiSlider-markLabel': {
        whiteSpace: 'nowrap',
        left: '30px',
      },
      '& .MuiSlider-thumb': {
        marginLeft: '-10px',
        marginBottom: '-12px',
      },
      '& .MuiSlider-rail': {
        width: '4px',
      },
      '& .MuiSlider-track': {
        width: '4px',
      },
      '& .MuiSlider-mark': {
        marginLeft: '1px',
      },
    },
  });
