import {
  DataCaptureResultsQuery,
  DataCaptureResultsQueryVariables,
} from '@curebase/core/types';
import { Skeleton } from '@material-ui/lab';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createVisitExtension,
  openForBackFill,
} from '../../controllers/dataCaptureController';
import { DataCaptureResultsDocument, VisitStatus } from '../../types';
import { FileUploadContext } from '../basic/FilePreview';
import ExtendVisitDialog from './ExtendVisitDialog';
import { BackFillDialog } from './BackFillDialog';
import { DataCaptureVisitResults } from './DataCaptureVisitResults';
import { Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import usePaginatedQuery from 'src/hooks/usePaginatedQuery';
import { VisitResults } from '@curebase/core/types/trialOption';

type Props = {
  editable?: boolean;
  trialOptionId: number;
  studyPlanVisitSlug: string;
  shouldBeHidden?: (...rest: any) => any;
  timezone?: string;
  withKnowledgeContent?: boolean;
};

const DataCaptureResultsSkeleton = () => (
  <div className='data-capture-results'>
    <div className={`dc-left`}>
      <Skeleton />
    </div>

    <div className='dc-right'>
      <div className='dc-cri'>
        <Skeleton width={500} />
      </div>

      <div className='form-results'>
        <div className='fr-data-section' style={{ marginBottom: 10 }}>
          <Skeleton variant='rect' width={500} height={50} />
        </div>

        <div className='fr-data-section'>
          <Skeleton variant='rect' width={500} height={50} />
        </div>
      </div>
    </div>
  </div>
);

const DataCaptureResults = (props: Props) => {
  const {
    trialOptionId,
    studyPlanVisitSlug,
    editable = true,
    withKnowledgeContent = false,
    timezone = moment.tz.guess(),
    shouldBeHidden,
  } = props;

  const [visitToExtend, setVisitToExtend] = useState<VisitResults | undefined>(
    undefined
  );
  const [visitToBackFill, setVisitToBackFill] = useState<
    VisitResults | undefined
  >(undefined);

  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { data, loading, refetch, page } = usePaginatedQuery<
    DataCaptureResultsQuery,
    DataCaptureResultsQueryVariables
  >({
    query: 'getVisitsWithPagination',
    document: DataCaptureResultsDocument,
    options: {
      variables: {
        trialOptionId,
        studyPlanVisitSlug,
      },
      fetchPolicy: 'network-only',
    },
  });

  useEffect(() => {
    const occurence = params.get('occurence');
    if (occurence && !loading) {
      document.getElementById(`occurence-${occurence}`)?.scrollIntoView(true);
    }
  }, [params.get('occurence'), loading]);

  useEffect(() => {
    if (shouldBeHidden && data?.getVisitsWithPagination?.rows) {
      shouldBeHidden(data.getVisitsWithPagination.rows);
    }
  }, [loading]);

  if (loading) return <DataCaptureResultsSkeleton />;

  if (!data?.getVisitsWithPagination?.rows.length) return null;

  const visits = data?.getVisitsWithPagination?.rows;

  const hasFollowingCompletedVisits = visit => {
    return !!visits.find(
      v => v.occurence > visit.occurence && v.status === VisitStatus.Completed
    );
  };

  const onSubmitCreateVisitExtension = async (submitData: any) => {
    if (!visitToExtend) return;

    await createVisitExtension(
      visitToExtend.id,
      submitData.reason,
      submitData.dueDate,
      timezone ? timezone : moment.tz.guess()
    );

    setVisitToExtend(undefined);
    refetch();
  };

  const openSurvey = visitId => {
    history.push(`/u/data/${visitId}/capture`);
  };

  const onBackFillSubmit = async data => {
    if (!visitToBackFill?.id)
      throw new Error('Trying to open for back fill without selecting visit');
    const backFillCreatedAt = DateTime.fromISO(data?.backFillCreatedAt)
      .setZone(timezone)
      .toISO();
    await openForBackFill(visitToBackFill?.id, backFillCreatedAt);
    openSurvey(visitToBackFill.id);
  };

  const onPageChange = (_, nextPage) => {
    page.set(nextPage);
  };

  const isEmpty: boolean = data?.getVisitsWithPagination?.count <= 10;

  return (
    <>
      <FileUploadContext.Provider
        value={{
          trialOptionId: visits?.[0]?.trialOptionId,
        }}
      >
        {visits.map((visit: VisitResults) => {
          return (
            <DataCaptureVisitResults
              refetch={refetch}
              visit={visit}
              withKnowledgeContent={withKnowledgeContent}
              editable={editable}
              hasFollowingCompletedVisits={hasFollowingCompletedVisits(visit)}
              hasOccurrences={visits.length > 1}
              onExtendDueDate={visit => setVisitToExtend(visit)}
              onBackFill={visit => setVisitToBackFill(visit)}
              onOpenSurvey={visit => openSurvey(visit.id)}
              isSelectedOccurrence={
                params.get('occurence') === visit.occurence.toString()
              }
            />
          );
        })}
      </FileUploadContext.Provider>
      {!isEmpty && (
        <Box display='flex' justifyContent='center' p={3}>
          <Pagination
            page={page.current}
            size='large'
            shape='rounded'
            color='primary'
            onChange={onPageChange}
            count={page.total}
          />
        </Box>
      )}

      <ExtendVisitDialog
        visit={visitToExtend}
        timezone={timezone}
        onClose={() => setVisitToExtend(undefined)}
        onSubmit={onSubmitCreateVisitExtension}
      />

      <BackFillDialog
        visit={visitToBackFill}
        onClose={() => setVisitToBackFill(undefined)}
        onSubmit={onBackFillSubmit}
      />
    </>
  );
};
export default DataCaptureResults;
