import { SigningType } from '@curebase/core/decoders/signatures';
import {
  finishedInformedConsent,
  firstUnsignedConsentDocument,
  isDocumentType,
  signableDocumentForType,
} from '@curebase/core/lib/documents';
import {
  availableActions,
  availableActionsList,
  DocumentConfigurationAppliesTo,
  getMachineForDocument,
} from '@curebase/core/lib/documentSigningMachine';
import {
  DEVELOPER_FEATURES_ENABLED,
  IS_PRODUCTION,
} from '@curebase/core/lib/env';
import { nonNullable } from '@curebase/core/lib/tsHelpers';
import {
  DocumentAction,
  SignedDocumentStatus,
  SigningConfiguration,
  StudyActivityType,
} from '@curebase/core/types';
import Button from '@material-ui/core/Button';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Switch, useHistory, useParams } from 'react-router-dom';
import { useIsParticipant } from 'src/hooks/useIsParticipant';
import useRoles from 'src/hooks/useRoles';
import { Route } from 'react-router-dom';
import {
  DocumentConfiguration,
  DocumentType,
  Signature,
  useDocumentsOverviewQuery,
} from 'src/types';
import { autoCompleteDocuments } from '../../controllers/signatureController';
import CustomStyleProvider from '../../providers/CustomStyleProvider';
import { FileUploadContext } from '../basic/FilePreview';
import Header from '../basic/Header';
import Subheader from '../basic/Subheader';
import Loading from '../Loading';
import { viewParticipantBaseRoute } from '../navigation/clinic';
import PageHeader from '../PageHeader';
import PaperDocumentUpload from '../PaperDocumentUpload';
import { EpochStatusTag } from '../TrialOption/EpochStatusTag';
import { ParticipationTag } from '../TrialOption/ParticipantTags';
import DocumentListItem from './DocumentListItem';
import SignDocument from './SignDocument';
import SignDocumentActionDialog from './SignDocumentActionDialog';
import { ViewSignedDocumentDevelopment } from './ViewSignedDocumentDevelopment';
import { Logger } from 'src/lib/logger';
import { OverridableTranslationContext } from 'src/context/OverridableTranslationContext';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

interface ChildAssentInfo {
  childLegalName: string;
}

type DocumentsContextShape = {
  action: DocumentAction | null;
  signingType: SigningType | null;
  setContext: (type: SigningType | null, action: DocumentAction | null) => void;
  minorAssent: ChildAssentInfo | null;
  setAssent: (minorAssent: ChildAssentInfo | null) => void;
};

export const DocumentsContext = React.createContext<DocumentsContextShape>({
  action: null,
  signingType: null,
  setContext: () => {},
  minorAssent: null,
  setAssent: () => {},
});

interface DocumentsOverviewProps {
  baseUrl: string;
  baseRoute:
    | '/u/:trialOptionId/consent'
    | '/explore/:trialIdentifier/consent/:trialOptionId';
  exploreMode?: boolean;
  mountingFunc?: (configId: string) => any;
}

const DocumentsOverview = (props: DocumentsOverviewProps) => {
  const { baseUrl, baseRoute, exploreMode, mountingFunc } = props;

  const { trialOptionId } = useParams<{ trialOptionId: string }>();
  const { t, locale } = useOverridableTranslation();
  const history = useHistory();
  const { isParticipant } = useIsParticipant();

  /* Allows "componentDidMount"-like behavior. Currently used to set up the top bar. */
  useEffect(() => {
    if (mountingFunc) {
      mountingFunc(StudyActivityType.InformedConsent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //context for eventual signing
  const [action, setAction] = useState<DocumentsContextShape['action']>(null);
  const [signingType, setSigningType] = useState<
    DocumentsContextShape['signingType']
  >(null);
  const [minorAssent, setAssent] = useState<
    DocumentsContextShape['minorAssent']
  >(null);
  const setContext: DocumentsContextShape['setContext'] = (type, action) => {
    setSigningType(type);
    setAction(action);
  };

  //controls for SignableDocumentActionDialog
  const [open, setOpen] = useState(false);
  const [selectedDocumentType, setDocumentType] = useState<string>('');

  const { data, loading, refetch } = useDocumentsOverviewQuery({
    skip: !trialOptionId,
    variables: { trialOptionId: parseInt(trialOptionId) },
    fetchPolicy: 'network-only',
  });

  const trialOption = data?.getTrialOption;
  const userWithRoles = useRoles(data?.getTrialOption.trial.id);
  const documentConfigs = useMemo(() => {
    return (
      data?.getTrialOption.signedDocuments
        .map(sd => sd.documentConfiguration)
        .filter(nonNullable) ?? []
    );
  }, [data]);

  // Once the SignedDocument component is loaded, it will check whether this first
  // document type has been completed. If so, it will use the goToDocument method
  // to proceed to the nextDocument
  useEffect(() => {
    if (!trialOption) {
      return;
    }

    const signedDocument = trialOption.signedDocuments.find(
      sd => sd.documentType !== DocumentType.Enrollment
    )!;
    const machine = getMachineForDocument(
      signedDocument.documentConfiguration!
    );
    const actions = availableActionsList(
      machine,
      signedDocument.status,
      userWithRoles,
      signedDocument.documentConfiguration!
    );
    if (actions[0]) {
      setAction(actions[0]);
    }
    let docType = trialOption.signedDocuments[0].documentType;
    if (!isDocumentType(docType)) {
      Logger('consent').error(
        'SignedDocument record did not return valid docType value',
        {
          signedDocumentId: trialOption.signedDocuments[0].id,
        }
      );
      // If document type does not exist then look for it in the document configuration
      docType = (trialOption.signedDocuments[0]?.documentConfiguration
        ?.documentType as unknown) as DocumentType;
    }
    setDocumentType(docType);
  }, [userWithRoles, data, trialOption]);

  if (loading || !data || !selectedDocumentType) {
    return <Loading />;
  }

  if (!trialOption) {
    throw new Error('No TrialOption');
  }

  const autocompleteConsent = DEVELOPER_FEATURES_ENABLED
    ? async function () {
        await autoCompleteDocuments(trialOption.id);
        await refetch();
      }
    : async function () {};

  const goBackToStudyPlan = function () {
    history.push(
      isParticipant ? '/u' : `${viewParticipantBaseRoute}/${trialOption.id}`
    );
  };

  const goBack = () => {
    if (isParticipant) {
      history.push('/u');
    } else {
      history.goBack();
    }
  };

  const { patient, trial, signedDocuments } = trialOption;

  const document = signedDocuments.find(
    doc => doc.documentType === selectedDocumentType
  ) ?? { status: SignedDocumentStatus.Created };

  const documentConfig = signableDocumentForType(
    documentConfigs,
    selectedDocumentType
  );

  if (!documentConfig) {
    return <Redirect to={baseUrl} />;
  }

  const documentContents = documentConfig.content;
  const enablePaperMode =
    [
      SigningConfiguration.PaperScanNotRequired,
      SigningConfiguration.PaperScanRequired,
    ].includes(trialOption.trial.signingConfiguration) && !isParticipant;

  const goToDocument = (
    nextDocumentType: string | undefined,
    patientMode: boolean = false,
    path: string = ''
  ) => {
    if (!nextDocumentType) {
      history.push('/u');
      return;
    }
    const nextDocumentConfig = signableDocumentForType(
      documentConfigs,
      nextDocumentType
    );
    const nextDocument = signedDocuments.find(
      doc => doc.documentType === nextDocumentType
    ) ?? { status: SignedDocumentStatus.Created };
    const machine = getMachineForDocument(nextDocumentConfig!);
    const actions = availableActionsList(
      machine,
      nextDocument.status,
      userWithRoles,
      nextDocumentConfig as DocumentConfiguration
    );
    const selectedAction: DocumentAction | undefined = actions[0];
    setDocumentType(nextDocumentType);
    setContext(SigningType.remote, selectedAction);
    if (patientMode) {
      history.push(baseUrl + '/patientMode/' + nextDocumentType);
    } else {
      history.push(baseUrl + '/' + nextDocumentType + path);
    }
  };

  const trialOptionInfoHeader = () => {
    const displayName = patient?.user?.displayName || 'Your patient';
    const subjectIdentifier = trialOption?.subjectIdentifier!!;
    const trialName = trial?.name;
    const trialDeactivated = trial?.deactivated;

    return (
      <div className='trial-row'>
        {!isParticipant && (
          <div className='non-arrow'>
            {displayName && (
              <div className={`participant-name`}>{displayName}</div>
            )}
            <div className='participant-trial-container'>
              <ParticipationTag
                participant={{
                  subjectIdentifier,
                  trial: {
                    name: trialName,
                    deactivated: trialDeactivated,
                  },
                }}
              />
              <EpochStatusTag trialOption={trialOption} />
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   *
   * @returns It returns the document title with the proper language
   */
  function getDocumentTitle() {
    const localeFromService = documentConfig?.locales?.[locale]?.displayName;
    return localeFromService ?? documentConfig?.displayName;
  }

  return (
    <DocumentsContext.Provider
      value={{ action, minorAssent, signingType, setAssent, setContext }}
    >
      <CustomStyleProvider customStyle={trial?.customStyle}>
        <div
          className='informed-consent'
          style={{
            backgroundColor:
              trial?.customStyle?.consentPageBackgroundColor ?? undefined,
          }}
        >
          {!exploreMode && <PageHeader customStyle={trial.customStyle} />}

          {document && documentConfig && (
            <SignDocumentActionDialog
              open={open}
              onClose={() => setOpen(false)}
              document={document}
              documentConfig={documentConfig}
              documentType={selectedDocumentType}
              trialOption={trialOption}
              refetch={refetch}
            />
          )}
          <Switch>
            <Route
              exact
              path={`${baseRoute}/:documentType`}
              render={props => {
                if (selectedDocumentType !== props.match.params.documentType) {
                  goToDocument(props.match.params.documentType as string);
                }
                if (!documentContents || !documentConfig) {
                  return <Redirect to={baseUrl} />;
                }
                return (
                  <OverridableTranslationContext.Provider
                    value={{ locale: trialOption.locale! }}
                  >
                    {!exploreMode && !isParticipant && (
                      <Header
                        beforeTitleElement={trialOptionInfoHeader()}
                        displayText={getDocumentTitle()}
                        back={goBack}
                        sticky
                      />
                    )}
                    <SignDocument
                      baseUrl={baseUrl}
                      document={document}
                      documentConfig={documentConfig}
                      documentContents={documentContents}
                      goToDocument={goToDocument}
                      refetch={refetch}
                      setDocumentType={setDocumentType}
                      trialOption={trialOption}
                    />
                  </OverridableTranslationContext.Provider>
                );
              }}
            />
            <Route
              exact
              path={`${baseRoute}/:documentType/comment`}
              render={props => {
                if (selectedDocumentType !== props.match.params.documentType) {
                  setDocumentType(props.match.params.documentType as string);
                }
                if (!documentContents || !documentConfig)
                  return <Redirect to={baseUrl} />;
                return (
                  <>
                    {!exploreMode && (
                      <Header
                        beforeTitleElement={trialOptionInfoHeader()}
                        displayText={getDocumentTitle()}
                        back={goBack}
                        sticky
                      />
                    )}
                    <SignDocument
                      baseUrl={baseUrl}
                      onlyComment={true}
                      document={document}
                      documentConfig={documentConfig}
                      documentContents={documentContents}
                      goToDocument={goToDocument}
                      refetch={refetch}
                      setDocumentType={setDocumentType}
                      trialOption={trialOption}
                    />
                  </>
                );
              }}
            />
            <Route
              exact
              path={`${baseRoute}/:documentType/view`}
              render={() => {
                if (!documentContents || !documentConfig || IS_PRODUCTION)
                  return <Redirect to={baseUrl} />;
                return (
                  <ViewSignedDocumentDevelopment trialOption={trialOption} />
                );
              }}
            />
            <Route
              exact
              path={`${baseRoute}/:documentType/paper`}
              render={props => {
                const { match } = props;
                const { trialOptionId, documentType } = match.params;

                //check if the document is completed and that the user is not a participant
                const documentIsCompleted =
                  signedDocuments.find(d => d.documentType === documentType)
                    ?.status === SignedDocumentStatus.Completed;
                const shouldBlockThisRoute =
                  documentIsCompleted || !enablePaperMode;

                if (shouldBlockThisRoute) {
                  return <Redirect to={baseUrl} />;
                } else {
                  return (
                    <FileUploadContext.Provider
                      value={{
                        trialOptionId: parseInt(trialOptionId as string),
                      }}
                    >
                      <PaperDocumentUpload
                        refetch={refetch}
                        signingConfiguration={trial.signingConfiguration}
                      />
                    </FileUploadContext.Provider>
                  );
                }
              }}
            />
            <Route
              exact
              path={`${baseRoute}/patientMode/:documentType`}
              render={props => {
                if (selectedDocumentType !== props.match.params.documentType) {
                  setDocumentType(props.match.params.documentType as string);
                }

                if (
                  !action ||
                  !signingType ||
                  !documentContents ||
                  !documentConfig
                ) {
                  return <Redirect to={baseUrl} />;
                }
                return (
                  <>
                    <OverridableTranslationContext.Provider
                      value={{ locale: trialOption.locale! }}
                    >
                      {!exploreMode && (
                        <Header
                          beforeTitleElement={trialOptionInfoHeader()}
                          displayText={getDocumentTitle()}
                          back={goBack}
                          sticky
                        />
                      )}

                      <SignDocument
                        inPatientMode
                        baseUrl={baseUrl}
                        document={document}
                        documentConfig={documentConfig}
                        documentContents={documentContents}
                        goToDocument={goToDocument}
                        refetch={refetch}
                        setDocumentType={setDocumentType}
                        trialOption={trialOption}
                      />
                    </OverridableTranslationContext.Provider>
                  </>
                );
              }}
            />
            <Route
              exact
              path={baseRoute}
              render={() => {
                if (isParticipant) {
                  const hasUnsignedDocument = firstUnsignedConsentDocument(
                    trialOption
                  );

                  const firstDocument = trialOption.signedDocuments.find(
                    signedDocument =>
                      signedDocument.status !==
                        SignedDocumentStatus.Completed &&
                      signedDocument.status !==
                        SignedDocumentStatus.NotApplicable
                  );

                  if (hasUnsignedDocument && firstDocument) {
                    goToDocument(firstDocument.documentType);
                  }
                }

                return (
                  <>
                    {!exploreMode && (
                      <Header
                        beforeTitleElement={trialOptionInfoHeader()}
                        displayText={t('informedConsent.title')}
                        back={goBackToStudyPlan}
                        sticky
                      />
                    )}
                    <Subheader
                      text={t('informedConsent.subheader')}
                      buttons={
                        !finishedInformedConsent(trialOption) &&
                        DEVELOPER_FEATURES_ENABLED
                          ? [
                              <Button
                                variant='contained'
                                color='secondary'
                                onClick={autocompleteConsent}
                              >
                                COMPLETE CONSENT [DEVELOPMENT ONLY]
                              </Button>,
                            ]
                          : []
                      }
                    />

                    <div>
                      {trialOption.signedDocuments
                        .filter(
                          signedDocument =>
                            signedDocument.documentType !==
                            DocumentType.Enrollment
                        )
                        .map(
                          /* fixme - need to pass correct type of trial option */
                          document => {
                            const {
                              documentType,
                              documentConfiguration: documentConfig,
                            } = document;

                            const machine = getMachineForDocument(
                              (documentConfig as unknown) as DocumentConfigurationAppliesTo
                            );
                            const actions = availableActions(
                              machine,
                              document.status,
                              userWithRoles,
                              documentConfig as DocumentConfiguration
                            );
                            const { status, signatures } = document;
                            const signedAt =
                              signatures.length > 0
                                ? new Date(
                                    Math.max(
                                      // @ts-ignore
                                      ...signatures.map(
                                        (signature: Signature) =>
                                          new Date(signature.createdAt)
                                      )
                                    )
                                  )
                                : undefined;

                            let onClick: undefined | (() => void);
                            if (
                              ![SignedDocumentStatus.Completed].includes(status)
                            ) {
                              if (isParticipant) {
                                if (
                                  status !==
                                  SignedDocumentStatus.NeedsCounterSignature
                                )
                                  onClick = () => {
                                    setAction(null);
                                    setSigningType(null);
                                    setDocumentType(documentType);
                                    history.push(baseUrl + '/' + documentType);
                                  };
                              } else {
                                if (!_isEmpty(actions)) {
                                  onClick = () => {
                                    setDocumentType(documentType);
                                    setOpen(true);
                                  };
                                }
                              }
                            }

                            const versions = document?.history || [];

                            return (
                              <>
                                <Subheader text={documentConfig?.displayName} />

                                <div className='li-multi-container inset'>
                                  <DocumentListItem
                                    key={documentType}
                                    documentType={documentType}
                                    documentId={document.id}
                                    verified={document.isSourceDataVerified}
                                    documentStatus={status}
                                    signedAt={signedAt?.toISOString()}
                                    trialOption={trialOption}
                                    onClick={onClick}
                                    refetch={refetch}
                                    documentConfig={documentConfig!}
                                    enablePaperMode={enablePaperMode}
                                    showViewDocument
                                  />

                                  {versions.map((signedDocument: any) => {
                                    const {
                                      status: versionStatus,
                                      signatures: versionSignatures,
                                    } = signedDocument;
                                    const versionSignedAt =
                                      versionSignatures.length > 0
                                        ? new Date(
                                            Math.max(
                                              // @ts-ignore
                                              ...versionSignatures.map(
                                                (signature: Signature) =>
                                                  new Date(signature.createdAt)
                                              )
                                            )
                                          )
                                        : undefined;

                                    return (
                                      <DocumentListItem
                                        key={
                                          signedDocument.documentConfiguration
                                            .version ?? '1.0.0'
                                        }
                                        documentType={documentType}
                                        documentId={signedDocument.id}
                                        verified={
                                          signedDocument.isSourceDataVerified
                                        }
                                        documentStatus={versionStatus}
                                        signedAt={versionSignedAt?.toISOString()}
                                        trialOption={trialOption}
                                        refetch={refetch}
                                        documentConfig={
                                          signedDocument.documentConfiguration!
                                        }
                                        enablePaperMode={enablePaperMode}
                                        showViewDocument
                                      />
                                    );
                                  })}
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                  </>
                );
              }}
            />
          </Switch>
        </div>
      </CustomStyleProvider>
    </DocumentsContext.Provider>
  );
};

export default DocumentsOverview;
