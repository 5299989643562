import React from 'react';
import { StudyCustomStyle } from '@curebase/core/types/customStyle';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core';
import { safeGetColorsForTheme, DEFAULT_THEME_PROPS } from '../lib/colors';
import CustomStyleContext from '../context/customStyleContext';

export type Props = {
  customStyle: any;
  children?: any;
  defaultTheme?: any;
};

const TYPOGRAPHY_KEYS_TO_CUSTOMIZE = ['body1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export default function CustomStyleProvider({ customStyle, children }: Props) {
  const { colors, backgroundColor } = customStyle || {};
  let theme = createTheme(DEFAULT_THEME_PROPS);
  const textColor = colors?.text;

  if (colors?.primary || colors?.secondary || colors?.text) {
    const themeParams = {
      ...DEFAULT_THEME_PROPS,
    };

    for (let key of TYPOGRAPHY_KEYS_TO_CUSTOMIZE) {
      themeParams.typography[key].color = textColor;
    }

    theme = createTheme({
      ...themeParams,
      palette: safeGetColorsForTheme({
        palette: DEFAULT_THEME_PROPS?.palette,
        ...customStyle,
      }),
    });
  }
  const overrides = {
    backgroundColor: backgroundColor ? `${backgroundColor}10` : undefined,
  };

  const classes = makeStyles({
    backgroundOverrides: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1',
      '&': backgroundColor && { backgroundColor: '#fff' },
      '& .data-capture': overrides,
      '& .signup-content': overrides,
      '& .informed-consent': overrides,
      '& .df-body .df-page .df-element .df-sub-element input': {
        color: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element input[type="text"]': {
        color: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element input[type="text"]:focus': {
        borderColor: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element input[type="tel"]:focus': {
        borderColor: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element input[type="number"]:focus': {
        borderColor: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element input[type="password"]:focus': {
        borderColor: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element h1': {
        color: textColor,
      },
      '& .df-body .df-page .df-element .df-sub-element h2': {
        color: textColor,
      },
      '& .close-dialog-icon-svg': {
        color: textColor,
      },
    },
  })();

  return (
    <ThemeProvider theme={theme}>
      <CustomStyleContext.Provider value={customStyle}>
        <div className={classes.backgroundOverrides}>{children}</div>
      </CustomStyleContext.Provider>
    </ThemeProvider>
  );
}

//
// Helper functions
//

export const createTopBarTheme = (ctx: StudyCustomStyle) => {
  return createTheme({
    overrides: {
      MuiStepper: {
        root: {
          backgroundColor: `${ctx.colors?.secondary} !important`,
        },
      },
      MuiStepLabel: {
        root: {
          color: `${ctx.contrast?.secondary} !important`,
        },
        active: {
          color: `${ctx.contrast?.secondary} !important`,
        },
        completed: {
          color: `${ctx.contrast?.secondary} !important`,
        },
        label: {
          marginTop: '10px !important',
          color: `${ctx.contrast?.secondary} !important`,
        },
      },
      MuiSvgIcon: {
        colorDisabled: {
          color: `${ctx.contrast?.secondary} !important`,
        },
      },
      MuiStepIcon: {
        root: {
          color: '#fff',
        },
        text: {
          fill: '#000',
        },
        active: {
          color: `${
            ctx.colors?.primary || DEFAULT_THEME_PROPS.palette.primary.main
          } !important`,
          '& text': {
            fill: `${ctx.contrast?.primary} !important`,
          },
        },
        completed: {
          color: `${
            ctx.colors?.primary || DEFAULT_THEME_PROPS.palette.primary.main
          } !important`,
          background: ctx.contrast?.primary,
          borderRadius: '50%',
        },
      },
    },
  });
};
