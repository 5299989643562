import type { Action } from '../actions';

export function language(state: string = 'en', action: Action) {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return action.code;
    default:
      return state;
  }
}
