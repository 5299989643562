import { darken } from '@material-ui/core';
import React from 'react';
import { isWhite } from 'src/lib/colors';

type PrescreenClipartProps = {
  primaryColor?: string;
};

export const PrescreenClipart = ({
  primaryColor = '#DE9D92',
}: PrescreenClipartProps) => {
  const nomalized =
    primaryColor && isWhite(primaryColor)
      ? darken(primaryColor, 0.1)
      : primaryColor;
  return (
    <svg
      width='290'
      height='221'
      viewBox='0 0 290 221'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M29.4821 20.6301H177.171C177.963 20.6301 178.604 21.2709 178.604 22.0613V219.569C178.604 220.359 177.963 221 177.171 221H29.4821C28.6902 221 28.0482 220.359 28.0482 219.569V22.0613C28.0482 21.2709 28.6902 20.6301 29.4821 20.6301Z'
          fill='white'
        />
        <path
          d='M29.4821 221H177.171C177.551 220.999 177.915 220.849 178.184 220.58C178.453 220.312 178.604 219.948 178.604 219.569V22.0613C178.604 21.6819 178.453 21.3181 178.184 21.0498C177.915 20.7815 177.551 20.6306 177.171 20.6301H29.4821C29.102 20.6306 28.7375 20.7815 28.4687 21.0498C28.1999 21.3181 28.0487 21.6819 28.0482 22.0613V219.569C28.0487 219.948 28.1999 220.312 28.4687 220.58C28.7375 220.849 29.102 220.999 29.4821 221ZM177.171 21.3457C177.361 21.3459 177.543 21.4214 177.677 21.5556C177.812 21.6897 177.887 21.8716 177.888 22.0613V219.569C177.887 219.758 177.812 219.94 177.677 220.074C177.543 220.209 177.361 220.284 177.171 220.284H29.4821C29.292 220.284 29.1098 220.209 28.9754 220.074C28.841 219.94 28.7654 219.758 28.7652 219.569V22.0613C28.7654 21.8716 28.841 21.6897 28.9754 21.5556C29.1098 21.4214 29.292 21.3459 29.4821 21.3457H177.171Z'
          fill='#CACACA'
        />
        <path
          d='M50.6328 65.4886H156.022C156.735 65.4886 157.419 65.2059 157.923 64.7027C158.427 64.1994 158.711 63.5168 158.711 62.8051C158.711 62.0934 158.427 61.4108 157.923 60.9076C157.419 60.4043 156.735 60.1216 156.022 60.1216H50.6328C49.9197 60.1216 49.2359 60.4043 48.7317 60.9076C48.2275 61.4108 47.9443 62.0934 47.9443 62.8051C47.9443 63.5168 48.2275 64.1994 48.7317 64.7027C49.2359 65.2059 49.9197 65.4886 50.6328 65.4886Z'
          fill='#E6E6E6'
        />
        <path
          d='M50.6328 82.8421H156.022C156.735 82.8421 157.419 82.5594 157.923 82.0562C158.427 81.5529 158.711 80.8703 158.711 80.1586C158.711 79.4469 158.427 78.7643 157.923 78.2611C157.419 77.7578 156.735 77.4751 156.022 77.4751H50.6328C49.9197 77.4751 49.2359 77.7578 48.7317 78.2611C48.2275 78.7643 47.9443 79.4469 47.9443 80.1586C47.9443 80.8703 48.2275 81.5529 48.7317 82.0562C49.2359 82.5594 49.9197 82.8421 50.6328 82.8421Z'
          fill='#E6E6E6'
        />
        <path
          d='M50.6328 100.196H156.022C156.735 100.196 157.419 99.9129 157.923 99.4097C158.427 98.9064 158.711 98.2238 158.711 97.5121C158.711 96.8004 158.427 96.1179 157.923 95.6146C157.419 95.1113 156.735 94.8286 156.022 94.8286H50.6328C49.9197 94.8286 49.2359 95.1113 48.7317 95.6146C48.2275 96.1179 47.9443 96.8004 47.9443 97.5121C47.9443 98.2238 48.2275 98.9064 48.7317 99.4097C49.2359 99.9129 49.9197 100.196 50.6328 100.196Z'
          fill='#E6E6E6'
        />
        <path
          d='M50.6328 117.549H156.022C156.735 117.549 157.419 117.266 157.923 116.763C158.427 116.26 158.711 115.577 158.711 114.865C158.711 114.154 158.427 113.471 157.923 112.968C157.419 112.465 156.735 112.182 156.022 112.182H50.6328C49.9197 112.182 49.2359 112.465 48.7317 112.968C48.2275 113.471 47.9443 114.154 47.9443 114.865C47.9443 115.577 48.2275 116.26 48.7317 116.763C49.2359 117.266 49.9197 117.549 50.6328 117.549Z'
          fill='#E6E6E6'
        />
        <path
          d='M50.6328 134.902H156.022C156.735 134.902 157.419 134.62 157.923 134.116C158.427 133.613 158.711 132.931 158.711 132.219C158.711 131.507 158.427 130.825 157.923 130.321C157.419 129.818 156.735 129.535 156.022 129.535H50.6328C49.9197 129.535 49.2359 129.818 48.7317 130.321C48.2275 130.825 47.9443 131.507 47.9443 132.219C47.9443 132.931 48.2275 133.613 48.7317 134.116C49.2359 134.62 49.9197 134.902 50.6328 134.902Z'
          fill='#E6E6E6'
        />
        <path
          d='M156.166 98.9342C156.23 99.1582 156.277 99.3869 156.305 99.6182L174.985 109.029L179.243 106.256L184.275 112.122L177.216 117.762C176.646 118.218 175.943 118.477 175.213 118.499C174.483 118.522 173.765 118.308 173.167 117.889L153.684 104.236C152.859 104.594 151.945 104.693 151.063 104.52C150.18 104.347 149.371 103.909 148.744 103.266C148.117 102.623 147.7 101.804 147.55 100.919C147.4 100.034 147.524 99.1241 147.905 98.3108C148.285 97.4975 148.905 96.8191 149.682 96.366C150.458 95.9128 151.354 95.7064 152.251 95.7741C153.148 95.8417 154.003 96.1803 154.702 96.7448C155.402 97.3093 155.912 98.073 156.166 98.9342L156.166 98.9342Z'
          fill='#FFB6B6'
        />
        <path
          d='M175.598 109.385L181.79 116.693C181.966 116.901 182.185 117.069 182.432 117.184C182.679 117.3 182.948 117.362 183.221 117.364C183.494 117.367 183.764 117.311 184.013 117.2C184.263 117.089 184.485 116.926 184.665 116.721L190.981 109.558C192.042 108.652 192.7 107.363 192.811 105.974C192.921 104.585 192.475 103.209 191.571 102.147C190.666 101.086 189.376 100.426 187.985 100.312C186.593 100.198 185.213 100.64 184.147 101.541L176.047 106.55C175.815 106.694 175.617 106.886 175.466 107.112C175.315 107.339 175.215 107.596 175.172 107.865C175.13 108.134 175.146 108.409 175.219 108.671C175.292 108.934 175.422 109.177 175.598 109.385L175.598 109.385Z'
          fill={nomalized}
        />
        <path
          d='M198.914 216.513H194.519L192.429 199.593H198.915L198.914 216.513Z'
          fill='#FFB6B6'
        />
        <path
          d='M200.035 215.081L191.38 215.081H191.38C189.917 215.081 188.514 215.661 187.48 216.693C186.446 217.725 185.865 219.126 185.865 220.586V220.765L200.035 220.765L200.035 215.081Z'
          fill='#2F2E41'
        />
        <path
          d='M199.904 169.396L198.655 173.601L181.808 170.791L183.653 164.584L199.904 169.396Z'
          fill='#FFB6B6'
        />
        <path
          d='M198.848 167.915L196.386 176.197L196.386 176.198C195.97 177.598 196.129 179.105 196.826 180.388C197.524 181.672 198.704 182.626 200.106 183.041L200.278 183.092L204.307 169.532L198.848 167.915Z'
          fill='#2F2E41'
        />
        <path
          d='M49.7279 173.923H119.647C120.632 173.923 121.43 172.722 121.43 171.24C121.43 169.758 120.632 168.556 119.647 168.556H49.7279C48.7429 168.556 47.9442 169.758 47.9442 171.24C47.9442 172.722 48.7429 173.923 49.7279 173.923Z'
          fill='#E6E6E6'
        />
        <path
          d='M49.7279 189.309H119.647C120.632 189.309 121.43 188.107 121.43 186.625C121.43 185.143 120.632 183.942 119.647 183.942H49.7279C48.7429 183.942 47.9442 185.143 47.9442 186.625C47.9442 188.107 48.7429 189.309 49.7279 189.309Z'
          fill='#E6E6E6'
        />
        <path
          d='M28.0482 41.2611C16.6331 41.2611 7.37933 32.0245 7.37933 20.6306C7.37933 9.23662 16.6331 0 28.0482 0C39.4633 0 48.717 9.23662 48.717 20.6306C48.717 32.0245 39.4633 41.2611 28.0482 41.2611Z'
          fill={nomalized}
        />
        <path
          d='M36.6601 19.2687C37.0218 19.2687 37.3688 19.4121 37.6246 19.6674C37.8804 19.9227 38.024 20.269 38.024 20.6301C38.024 20.9912 37.8804 21.3375 37.6246 21.5928C37.3688 21.8481 37.0218 21.9915 36.6601 21.9915H29.4119V29.226C29.411 29.5865 29.267 29.9319 29.0113 30.1865C28.7556 30.4411 28.4092 30.5841 28.0481 30.5841C27.6869 30.5841 27.3405 30.4411 27.0848 30.1865C26.8291 29.9319 26.6851 29.5865 26.6843 29.226V21.9915H19.436C19.0743 21.9915 18.7274 21.8481 18.4716 21.5928C18.2158 21.3375 18.0721 20.9912 18.0721 20.6301C18.0721 20.269 18.2158 19.9227 18.4716 19.6674C18.7274 19.4121 19.0743 19.2687 19.436 19.2687H26.6843V12.0339C26.6843 11.6728 26.828 11.3266 27.0837 11.0713C27.3395 10.816 27.6864 10.6726 28.0481 10.6726C28.4098 10.6726 28.7566 10.816 29.0124 11.0713C29.2682 11.3266 29.4119 11.6728 29.4119 12.0339V19.2687H36.6601Z'
          fill='white'
        />
        <path
          d='M154.503 160.743C155.137 161.798 156.033 162.673 157.103 163.283C158.174 163.893 159.384 164.219 160.616 164.228L187.03 172.668L188.237 165.676L172.244 157.014L187.739 145.875L176.386 132.615L155.767 151.798C154.562 152.919 153.779 154.419 153.549 156.047C153.319 157.675 153.656 159.333 154.503 160.743Z'
          fill='#2F2E41'
        />
        <path
          d='M192.812 208.559H199.333C205.189 168.496 211.149 128.322 193.536 113.326L172.763 116.702L175.42 134.302L187.014 147.562L192.812 208.559Z'
          fill='#2F2E41'
        />
        <path
          d='M174.033 123.961C182.825 129.941 194.182 128.534 198.922 119.624C192.112 108.174 190.678 99.9449 196.16 96.1879C197.039 93.0608 196.711 89.7186 195.242 86.821C193.772 83.9235 191.267 81.6812 188.222 80.5369L177.836 84.8767C169.018 92.0521 167.281 99.8242 170.59 108.022L174.033 123.961Z'
          fill={nomalized}
        />
        <path d='M0 220.284H290V221H0V220.284Z' fill='#CACACA' />
        <path
          d='M235.032 203.968C233.699 204.436 232.267 204.553 230.874 204.308C229.482 204.063 228.176 203.465 227.083 202.57C224.299 200.238 223.427 196.395 222.716 192.838L220.615 182.313L225.013 185.335C228.176 187.509 231.41 189.753 233.6 192.901C235.79 196.05 236.746 200.348 234.986 203.755'
          fill='#E6E6E6'
        />
        <path
          d='M234.353 217.442C234.908 213.416 235.476 209.339 235.087 205.27C234.742 201.656 233.638 198.127 231.389 195.233C230.196 193.7 228.743 192.386 227.098 191.351C226.667 191.081 226.273 191.76 226.701 192.029C229.548 193.825 231.751 196.478 232.991 199.604C234.36 203.081 234.58 206.871 234.344 210.565C234.201 212.798 233.899 215.017 233.594 217.233C233.57 217.333 233.585 217.439 233.636 217.528C233.687 217.618 233.77 217.685 233.869 217.716C233.969 217.743 234.076 217.73 234.167 217.678C234.258 217.627 234.324 217.542 234.352 217.442H234.353Z'
          fill='#F2F2F2'
        />
        <path
          d='M269.631 211.428C268.952 212.229 268.077 212.842 267.092 213.208C266.106 213.573 265.043 213.679 264.005 213.516C261.34 213.065 259.332 210.927 257.535 208.911L252.221 202.948L256.182 203.237C259.03 203.444 261.95 203.669 264.542 204.867C267.133 206.065 269.366 208.463 269.521 211.309'
          fill='#E6E6E6'
        />
        <path
          d='M274.19 219.744C273.257 217.5 272.315 215.225 270.882 213.244C269.653 211.449 267.937 210.041 265.935 209.184C264.855 208.753 263.708 208.514 262.546 208.477C262.243 208.467 262.245 208.936 262.546 208.946C264.559 209.013 266.499 209.717 268.085 210.955C269.845 212.335 271.107 214.223 272.104 216.199C272.707 217.394 273.223 218.63 273.737 219.865C273.755 219.924 273.795 219.974 273.848 220.004C273.901 220.035 273.964 220.045 274.024 220.031C274.084 220.015 274.135 219.975 274.166 219.922C274.197 219.868 274.206 219.804 274.19 219.744Z'
          fill='#F2F2F2'
        />
        <path
          d='M230.369 210.983C229.797 211.85 229.01 212.556 228.086 213.033C227.161 213.509 226.129 213.739 225.089 213.702C222.416 213.575 220.188 211.713 218.182 209.945L212.25 204.717L216.176 204.529C219 204.394 221.896 204.268 224.586 205.138C227.275 206.007 229.755 208.098 230.247 210.877'
          fill='#E6E6E6'
        />
        <path
          d='M235.918 219.756C233.253 215.049 230.162 209.818 224.638 208.146C223.103 207.682 221.492 207.516 219.894 207.657C219.391 207.7 219.516 208.475 220.019 208.432C222.698 208.21 225.372 208.916 227.59 210.431C229.725 211.882 231.387 213.898 232.794 216.036C233.654 217.346 234.428 218.712 235.2 220.075C235.446 220.511 236.168 220.197 235.918 219.756Z'
          fill='#F2F2F2'
        />
        <path
          d='M6.94634 212.42C7.97552 212.781 9.08052 212.872 10.1549 212.683C11.2293 212.494 12.2368 212.032 13.0804 211.342C15.2291 209.542 15.9027 206.576 16.4501 203.83L18.0714 195.708L14.6771 198.041C12.2362 199.719 9.74025 201.45 8.05043 203.88C6.36061 206.31 5.62253 209.627 6.98041 212.256'
          fill='#E6E6E6'
        />
        <path
          d='M7.88347 220.129C7.58486 217.959 7.27802 215.76 7.48737 213.566C7.62349 211.61 8.3152 209.732 9.48153 208.154C10.1252 207.327 10.9083 206.619 11.7958 206.061C12.0274 205.915 12.2403 206.281 12.0109 206.426C10.4753 207.395 9.28762 208.825 8.61905 210.511C7.8806 212.386 7.76195 214.43 7.8892 216.421C7.96627 217.626 8.12937 218.822 8.29355 220.017C8.30644 220.071 8.29834 220.128 8.27086 220.176C8.24339 220.224 8.1986 220.26 8.14552 220.277C8.0913 220.291 8.03369 220.283 7.98495 220.256C7.9362 220.228 7.90018 220.183 7.88454 220.129L7.88347 220.129Z'
          fill='#F2F2F2'
        />
        <path
          d='M206.488 131.026C205.939 130.899 205.426 130.653 204.983 130.307C204.54 129.96 204.178 129.52 203.924 129.019C203.669 128.518 203.528 127.967 203.51 127.405C203.492 126.844 203.598 126.285 203.819 125.768L189.623 93.755L197.744 91.7134L209.145 123.823C209.989 124.253 210.647 124.976 210.993 125.857C211.339 126.738 211.349 127.715 211.021 128.603C210.694 129.49 210.052 130.228 209.216 130.674C208.38 131.121 207.41 131.246 206.488 131.026V131.026Z'
          fill='#FFB6B6'
        />
        <path
          d='M191.545 99.4804L199.443 95.7901C199.668 95.6851 199.867 95.533 200.028 95.3442C200.188 95.1553 200.306 94.9341 200.373 94.6957C200.44 94.4573 200.455 94.2072 200.417 93.9625C200.379 93.7178 200.288 93.4843 200.151 93.2778L195.351 86.042C194.806 84.8982 193.828 84.0168 192.633 83.5908C191.438 83.1648 190.123 83.229 188.975 83.7694C187.827 84.3097 186.941 85.2822 186.51 86.4736C186.079 87.665 186.139 88.9783 186.677 90.1258L189.159 98.4135C189.23 98.6508 189.351 98.87 189.515 99.0562C189.679 99.2423 189.881 99.3911 190.107 99.4923C190.333 99.5936 190.579 99.6449 190.827 99.6428C191.075 99.6408 191.32 99.5854 191.545 99.4804H191.545Z'
          fill={nomalized}
        />
        <path
          d='M174.025 78.1798H194.816C195.244 78.1793 195.654 78.0095 195.956 77.7077C196.259 77.4058 196.429 76.9966 196.429 76.5697V67.6246C196.429 64.4456 195.164 61.3968 192.912 59.1489C190.66 56.901 187.606 55.6382 184.421 55.6382C181.236 55.6382 178.181 56.901 175.929 59.1489C173.677 61.3968 172.412 64.4456 172.412 67.6246V76.5697C172.413 76.9966 172.583 77.4058 172.885 77.7077C173.188 78.0095 173.598 78.1793 174.025 78.1798Z'
          fill='#2F2E41'
        />
        <path
          d='M181.457 78.0438C176.595 78.0438 172.653 74.1093 172.653 69.2558C172.653 64.4023 176.595 60.4678 181.457 60.4678C186.32 60.4678 190.261 64.4023 190.261 69.2558C190.261 74.1093 186.32 78.0438 181.457 78.0438Z'
          fill='#FFB6B6'
        />
        <path
          d='M169.571 68.5191H182.284L182.415 66.6972L183.067 68.5191H185.024L185.283 64.9082L186.575 68.5191H190.362V68.3402C190.359 65.8263 189.358 63.4163 187.577 61.6387C185.796 59.8611 183.382 58.8612 180.863 58.8584H179.071C176.552 58.8612 174.138 59.8611 172.357 61.6387C170.576 63.4163 169.574 65.8263 169.571 68.3402V68.5191Z'
          fill='#2F2E41'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect
            width='290'
            height='221'
            fill='white'
            transform='matrix(-1 0 0 1 290 0)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
