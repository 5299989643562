import owasp from 'owasp-password-strength-test';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { calculateStrength, Strength } from 'src/lib/password';
import { resetPassword } from '../../controllers/authController';
import { attemptLogin } from '../../lib/auth';
import StandardButton from '../basic/StandardButton';
import StatusText from '../basic/StatusText';
import TextInput from '../basic/TextInput';
import PasswordRequirements from './PasswordRequirements';
import PasswordStrength from './PasswordStrength';

type Props = {
  history: any;
  match: any;
  t: any;
};

type State = {
  success?: boolean;
  errorMessage?: string;
  enteredPassword?: string;
};

class Signup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(): Promise<void> {
    const { t } = this.props;
    const params = this.props.match.params;
    const { enteredPassword } = this.state;
    const setError = (errorMessage: string) => this.setState({ errorMessage });

    if (!enteredPassword)
      return setError(t('auth.resetPassword.passwordEmpty'));

    const testResult = owasp.test(enteredPassword);
    if (testResult.errors && testResult.errors.length > 0)
      return setError(testResult.errors[0]);

    const res = await resetPassword(
      params.email,
      params.passwordResetHash,
      enteredPassword
    );

    if (res.error) return setError(t(`auth.resetPassword.${res.error}`));

    await attemptLogin(params.email, enteredPassword);
    this.props.history.push('/');
  }

  onChange = (enteredPassword: string): void => {
    this.setState({ enteredPassword, errorMessage: undefined });
  };

  render() {
    const { t } = this.props;
    const { errorMessage, enteredPassword } = this.state;

    const password = enteredPassword || '';
    const strength: Strength = calculateStrength(password);

    const disableSubmit = strength !== Strength.Strong;

    return (
      <div>
        <div className='auth-right-header'>
          {t('auth.resetPassword.title')}
          <StatusText
            color='gray'
            text={t('auth.resetPassword.instructions')}
          />
        </div>
        <div className='auth-right-text' />
        <div className='auth-right-header-inputs'>
          <PasswordRequirements password={enteredPassword} />
          <strong className='field-label'>
            {t('auth.resetPassword.passwordPlaceholder')}
          </strong>
          <TextInput
            placeholder={t('auth.resetPassword.passwordPlaceholder')}
            onChange={this.onChange}
            specialType='password'
            value={enteredPassword}
            noSpellcheck
            onEnter={this.onSubmit}
            hidePersonalInformation
          />
          {errorMessage && <p className='field-error'>{errorMessage}</p>}
          <PasswordStrength password={enteredPassword} />
          <StandardButton
            className='button'
            onClick={this.onSubmit}
            disabled={disableSubmit}
            variant='contained'
            fullWidth
            size='xlarge'
          >
            {t('auth.resetPassword.submitPassword')}
          </StandardButton>
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(Signup as any);
