import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { cancelAcuityScheduling } from 'src/controllers/bookingController';
import { Maybe } from 'src/types';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import Loading from '../Loading';

interface AcuityCancelRescheduleAppointmentModalProps {
  trialOptionId: number;
  acuityAppointmentId?: Maybe<number> | number;
  action: 'cancel' | 'reschedule';
  onClose?: () => Promise<any> | void;
}

export default function AcuityCancelRescheduleAppointmentModal(
  props: AcuityCancelRescheduleAppointmentModalProps
) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const { t } = useTranslation('translations');

  async function confirm() {
    setLoading(true);
    setCanceled(false);
    if (!props.acuityAppointmentId) return;

    await cancelAcuityScheduling(
      props.trialOptionId,
      props.acuityAppointmentId
    );
    setLoading(false);
    setCanceled(true);
  }

  async function ok() {
    if (props.action === 'reschedule') {
      history.push(window.location.pathname.replace('reschedule', 'schedule'));
    } else {
      props.onClose && props.onClose();
    }
  }

  return (
    <ConfirmationInDialog
      title={
        canceled
          ? `${t(`common.success`)}!`
          : t(`booker.acuity.${props.action}.dialog.title`)
      }
      message={''}
      confirmationText={
        canceled
          ? t(`common.ok`)
          : t(`booker.acuity.${props.action}.dialog.confirmText`)
      }
      closeText={t(`booker.acuity.${props.action}.dialog.cancelText`)}
      disableOnConfirm={loading}
      hideCancelButton={canceled}
      onConfirm={canceled ? ok : confirm}
      onClose={props.onClose}
      hideCloseButton
    >
      <div className='confirmation-dialog-message'>
        {loading && <Loading size={22} />}
        {!loading &&
          !canceled &&
          t(`booker.acuity.${props.action}.dialog.message`)}

        {!loading &&
          canceled &&
          t(`booker.acuity.${props.action}.dialog.canceledMessage`)}
      </div>
    </ConfirmationInDialog>
  );
}
