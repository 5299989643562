import { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  CurrencySubElement,
  SubElementProps,
} from '@curebase/core/lib/dynamicform/types';

import { AcceptedCurrency } from '@curebase/core/types';

interface CurrencyInputPros {
  data: Record<string, any>;
  readOnly: boolean;
  subEle: CurrencySubElement;
  pageIndex: number;
  hasError: boolean;
  onChange: (subEle: SubElementProps, value: any, pageIndex: number) => void;
}

function CurrencyInput(props: CurrencyInputPros) {
  const { data, readOnly, subEle, pageIndex, hasError, onChange } = props;
  const disabled = readOnly || subEle.readOnly || false;
  const payment = data[subEle.key] ?? {
    currency: AcceptedCurrency.Usd,
  };

  const [focus, setFocus] = useState(false);

  return (
    <div className='currency'>
      <div className='currency-input'>
        <input
          type='number'
          min='0'
          inputMode='numeric'
          pattern='[0-9]*'
          disabled={disabled}
          value={payment.amount}
          placeholder={disabled ? undefined : subEle.placeholder}
          className={
            hasError
              ? 'currency-input-field with-error'
              : 'currency-input-field'
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={async e => {
            const { value } = e.target;
            onChange(subEle, { ...payment, amount: value }, pageIndex);
          }}
        />
        <div
          className={`currency-input-adornment ${hasError && 'with-error'} ${
            focus && 'currency-input-adornment-focused'
          }`}
        >
          <span className='currency-input-adornment-text'>
            <Select
              fullWidth
              disableUnderline
              value={payment.currency}
              onChange={e => {
                const { value } = e.target;
                onChange(subEle, { ...payment, currency: value }, pageIndex);
              }}
            >
              {subEle.currency.map(({ text, value }, i) => (
                <MenuItem key={i} value={value}>
                  {text.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </span>
        </div>
      </div>
    </div>
  );
}

export { CurrencyInput };
