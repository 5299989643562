import React from 'react';
import OneClickPicker, { PickerOption } from '../../basic/OneClickPicker';
import { VisitMethod } from '@curebase/core/types';
import { userIsParticipant } from 'src/lib/users';
import { useTranslation } from 'react-i18next';

type Props = {
  stateName?: string;
  onSelectVisitMethod: (arg0: VisitMethod) => any;
  onBack: () => void | Promise<void>;
  availableMethods: VisitMethod[];
};

function BookerSelectVisitMethod({
  stateName,
  onSelectVisitMethod,
  onBack,
  availableMethods,
}: Props) {
  const { t } = useTranslation('translations');

  const options: PickerOption[] = [];
  if (availableMethods.includes(VisitMethod.Virtual))
    options.push({
      title: t('bookerSelectVisitMethod.options.virtual.title'),
      subtitle: userIsParticipant()
        ? t('bookerSelectVisitMethod.options.virtual.subtitleParticipant')
        : t('bookerSelectVisitMethod.options.virtual.subtitleNoParticipant'),
      value: VisitMethod.Virtual,
    });
  if (availableMethods.includes(VisitMethod.InPerson))
    options.push({
      title: t('bookerSelectVisitMethod.options.inPerson.title'),
      subtitle: userIsParticipant()
        ? t('bookerSelectVisitMethod.options.inPerson.subtitleParticipant', {
            place:
              stateName ||
              t('bookerSelectVisitMethod.options.inPerson.placeDefault'),
          })
        : t('bookerSelectVisitMethod.options.inPerson.subtitleNoParticipant'),
      value: VisitMethod.InPerson,
    });

  //If there is only 1 method, we autoselect it
  if (availableMethods.length === 1) onSelectVisitMethod(availableMethods[0]);

  return (
    <>
      <div className='booker-text-container'>
        <div className='booker-title'>
          {userIsParticipant()
            ? t('bookerSelectVisitMethod.titleParticipant')
            : t('bookerSelectVisitMethod.titleNoParticipant')}
        </div>

        <div className='booker-back-button' onClick={onBack}>
          {userIsParticipant()
            ? `← ${t('bookerSelectVisitMethod.goBackParticipant')}`
            : `← ${t('bookerSelectVisitMethod.goBackNoParticipant')}`}
        </div>

        <div className='booker-ocp-container'>
          <OneClickPicker
            options={options}
            onSelect={v => onSelectVisitMethod(v)}
          />
        </div>
      </div>
    </>
  );
}

export default BookerSelectVisitMethod;
