import StandardButton from '../basic/StandardButton';
import { IS_DEVELOPMENT } from '@curebase/core/lib/env';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

interface SignatureMismatchWarningProps {
  expectedName: string;
  enteredName: string;
  onBack: () => any;
  onSubmit: () => any;
}

export const SignatureMismatchWarning = (
  props: SignatureMismatchWarningProps
) => {
  const { t } = useOverridableTranslation('translations');
  const { expectedName, enteredName } = props;

  return (
    <div className='dynamic-form'>
      <div className='df-body'>
        <div className='df-page'>
          <div className='df-element'>
            {t('signatureMismatchWarning.title')}
          </div>
          <div className='df-element'>
            {t('signatureMismatchWarning.body', { enteredName, expectedName })}
          </div>
        </div>
      </div>
      <div className='df-footer'>
        <div className='df-button-container'>
          <StandardButton text={t('common.goBack')} onClick={props.onBack} />
          <StandardButton
            text={t('signatureMismatchWarning.button')}
            onClick={props.onSubmit}
            secondDelayUnlock={!IS_DEVELOPMENT ? 10000 : 3000}
            delayButtonUnlock={true}
          />
        </div>
      </div>
    </div>
  );
};
