import { useState, useEffect } from 'react';
import { previousConfigVersion } from 'src/controllers/trialBuilderController';

function getVersionTitle(version: string | null): string {
  return `Version ${version}`;
}

export interface PreviousVersion {
  title: string;
  version: string;
  content: string;
}

export const usePreviousVersion = (
  trialIdentifier: string,
  version: string
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [configs, setConfigs] = useState<PreviousVersion[]>([]);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      if (!trialIdentifier || !version) return null;
      const listOfConfigs = await previousConfigVersion(
        trialIdentifier,
        version
      );
      setConfigs(
        listOfConfigs.map(config => ({
          ...config,
          title: getVersionTitle(config.version),
        }))
      );
      setIsLoading(false);
    })();
  }, [trialIdentifier, version]);

  return { configs, isLoading };
};
