import {
  ActivateUserRequest,
  ActivateUserResponse,
  AddRoleToUserRequest,
  AddRoleToUserResponse,
  CheckEmailRequest,
  CheckEmailResponse,
  CheckSessionRequest,
  CheckSessionResponse,
  RemoveRoleFromUserRequest,
  RemoveRoleFromUserResponse,
  RequestPasswordResetRequest,
  RequestPasswordResetResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  VerifyIdentityRequest,
  VerifyIdentityResponse,
} from '@curebase/core/decoders/users';
import { UserRoleType } from '@curebase/core/types';
import { jsonPost } from '../lib/fetchHelpers';
import { clearUser } from '../store/actions';
import { sendEventsToMobile } from '../utils/mobileHelpers';

export async function checkSession(
  userId: number
): Promise<CheckSessionResponse> {
  const requestBody: CheckSessionRequest = { userId };
  const response: CheckSessionResponse = await jsonPost(
    '/user/checksession',
    requestBody
  );
  return response;
}

export async function checkEmail(
  email: string | null
): Promise<CheckEmailResponse> {
  const requestBody: CheckEmailRequest = { email };
  const response: CheckEmailResponse = await jsonPost(
    '/user/checkemail',
    requestBody
  );
  return response;
}

export async function verifyIdentity(
  password: string
): Promise<VerifyIdentityResponse> {
  const requestBody: VerifyIdentityRequest = { password };
  const response: VerifyIdentityResponse = await jsonPost(
    '/user/verifyidentity',
    requestBody
  );
  return response;
}

export async function requestPasswordReset(
  email: string
): Promise<RequestPasswordResetResponse> {
  const requestBody: RequestPasswordResetRequest = { email };
  const response: RequestPasswordResetResponse = await jsonPost(
    '/user/requestpasswordreset',
    requestBody
  );
  return response;
}

export async function resetPassword(
  email: string,
  passwordResetHash: string,
  password: string
): Promise<ResetPasswordResponse> {
  const requestBody: ResetPasswordRequest = {
    email,
    passwordResetHash,
    password,
  };
  const response: ResetPasswordResponse = await jsonPost(
    '/user/resetpassword',
    requestBody
  );
  return response;
}

export async function signoutSession(): Promise<{}> {
  clearUser();
  sendEventsToMobile('USER_LOGOUT');
  const response: {} = await jsonPost('/user/signout', {});
  return response;
}

export async function activateUser(
  requestBody: ActivateUserRequest
): Promise<ActivateUserResponse> {
  const response: ActivateUserResponse = await jsonPost(
    '/user/activate',
    requestBody
  );
  return response;
}

export const addRoleForUser = async (
  userRoleType: UserRoleType,
  trialId: number,
  userId: number
): Promise<AddRoleToUserResponse> => {
  const requestBody: AddRoleToUserRequest = {
    trialId,
    userRoleType,
    userId,
  };

  const response: AddRoleToUserResponse = await jsonPost(
    '/user/addrole',
    requestBody
  );
  return response;
};

export const removeRoleFromUser = async (
  userRoleType: UserRoleType,
  userId: number,
  trialId?: number | null,
  trialInstanceId?: string | null,
  clinicId?: string | null
): Promise<RemoveRoleFromUserResponse> => {
  const requestBody: RemoveRoleFromUserRequest = {
    userRoleType,
    userId,
    trialId: trialId ?? undefined,
    trialInstanceId: trialInstanceId == null ? undefined : trialInstanceId,
    clinicId: clinicId == null ? undefined : clinicId,
  };
  const response: RemoveRoleFromUserResponse = await jsonPost(
    '/user/removerole',
    requestBody
  );
  return response;
};
