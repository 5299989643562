import { ArrowForwardRounded } from '@material-ui/icons';

import { DocumentConfiguration } from 'src/types';
import { SignedDocument, SignedDocumentStatus } from '@curebase/core/types';
import StandardButton, { StandardButtonProps } from '../basic/StandardButton';
import { useTranslation } from 'react-i18next';

interface DocumentSignToolbarProps {
  document: SignedDocument;
  documentConfig: Pick<Readonly<DocumentConfiguration>, 'allowDecline'>;
  onClickToSign: () => void;
  onClickToSkip: () => void;
  onClickNext: () => void;
  onAutoComplete: null | (() => Promise<void>);
  enableSignature: boolean;
  hasNextDocument: boolean;
}

interface ButtonsComponentProps
  extends Pick<
    DocumentSignToolbarProps,
    | 'onClickToSign'
    | 'onClickToSkip'
    | 'onClickNext'
    | 'onAutoComplete'
    | 'enableSignature'
    | 'hasNextDocument'
  > {
  documentStatus: SignedDocumentStatus;
}

const ReadyToSignButton = ({ onClick, variant }: StandardButtonProps) => {
  const { t } = useTranslation('translations');
  return (
    <StandardButton
      onClick={onClick}
      variant={variant}
      className='sign-button'
      showLoading
      size={'large'}
    >
      {t('documentSignToolbar.readyToSignBtn')}
    </StandardButton>
  );
};

const DeclinedSignButton = ({ onClick }: StandardButtonProps) => {
  const { t } = useTranslation('translations');
  return (
    <StandardButton
      onClick={onClick}
      variant='outlined'
      className='declined-button'
      showLoading
      size={'large'}
    >
      {t('documentSignToolbar.skipForNowBtn')}
    </StandardButton>
  );
};

const NextDocumentButton = ({
  onClick,
  hasNextDocument,
}: StandardButtonProps & { hasNextDocument: boolean }) => {
  const { t } = useTranslation('translations');
  return (
    <StandardButton
      onClick={onClick}
      variant='contained'
      className='next-button'
      showLoading
      size={'large'}
    >
      {hasNextDocument
        ? t('documentSignToolbar.nextDocBtn')
        : t('documentSignToolbar.next')}
      <ArrowForwardRounded />
    </StandardButton>
  );
};

const AutoCompleteButton = ({ onClick }: StandardButtonProps) => {
  return (
    <StandardButton
      color='secondary'
      onClick={onClick}
      variant='contained'
      className='autocomplete-button'
      size={'large'}
      style={{
        marginRight: 8,
      }}
    >
      <img height={24} src='/logo/logo-transparent.png' alt='Curebase Logo' />
    </StandardButton>
  );
};

const ButtonsComponent = ({
  documentStatus: currentDocumentStatus,
  onClickToSign,
  onClickToSkip,
  onClickNext,
  onAutoComplete,
  enableSignature,
  hasNextDocument,
}: ButtonsComponentProps) => {
  let documentStatus = currentDocumentStatus;

  if (!enableSignature) {
    documentStatus = SignedDocumentStatus.Completed;
  }

  switch (documentStatus) {
    case SignedDocumentStatus.Declined:
    case SignedDocumentStatus.NotApplicable:
    case SignedDocumentStatus.Created:
    case SignedDocumentStatus.NeedsParticipantSignature:
      return (
        <>
          <DeclinedSignButton onClick={onClickToSkip} />
          <div className='document-sign-toolbar-autocomplete'>
            {/* @ts-ignore */}
            {onAutoComplete && <AutoCompleteButton onClick={onAutoComplete} />}
            <ReadyToSignButton variant={'contained'} onClick={onClickToSign} />
          </div>
        </>
      );
    case SignedDocumentStatus.NeedsCounterSignature:
      return (
        <>
          <div />
          <div className='document-sign-toolbar-autocomplete'>
            {/* @ts-ignore */}
            {onAutoComplete && <AutoCompleteButton onClick={onAutoComplete} />}
            <ReadyToSignButton variant={'contained'} onClick={onClickToSign} />
          </div>
        </>
      );
    case SignedDocumentStatus.Completed:
      return (
        <>
          <div />
          <NextDocumentButton
            onClick={onClickNext}
            hasNextDocument={hasNextDocument}
          />
        </>
      );
    default:
      return null;
  }
};

const DocumentSignToolbar = ({
  document,
  documentConfig,
  onClickToSign,
  onClickToSkip,
  onClickNext,
  onAutoComplete,
  enableSignature,
  hasNextDocument,
}: DocumentSignToolbarProps) => {
  if (!documentConfig.allowDecline) return null;

  return (
    <div className='document-sign-toolbar'>
      <ButtonsComponent
        hasNextDocument={hasNextDocument}
        enableSignature={enableSignature}
        documentStatus={document.status}
        onClickToSign={onClickToSign}
        onClickToSkip={onClickToSkip}
        onClickNext={onClickNext}
        onAutoComplete={onAutoComplete}
      />
    </div>
  );
};

export default DocumentSignToolbar;
