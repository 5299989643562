import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsEditor from '../../Settings/SettingsEditor';
import moment from 'moment-timezone';

import { updatePatient } from '../../../controllers/patientController';
import { usePatientDetailsEditorQuery } from '../../../types';
import { getLocaleFormatDates } from '../../../context/localeContext';

type Props = {
  patientId: number;
  trialOptionId: number;
  updateParent?: any;
};
const localeFormat = getLocaleFormatDates();

function PatientDetailsEditor(props: Props) {
  const { t } = useTranslation('translations');
  const { trialOptionId } = props;

  const {
    data,
    // loading,
    refetch: refetchGetPatientDetailsEditor,
  } = usePatientDetailsEditorQuery({
    variables: {
      patientId: props.patientId,
    },
  });

  function getSubElements() {
    return [
      {
        title: t('profile.patientBirthdateTitle'),
        subElements: [
          {
            key: 'birthday',
            type: 'DATE',
          },
        ],
      },
    ];
  }

  // @FIXME: not sure if this is right
  if (!data) return null;

  const patient = data.getPatient;

  if (!patient) return null;

  const defaultData = {
    birthday: patient?.birthday ?? null,
  };

  const pages = [
    {
      elements: getSubElements(),
    },
  ];

  return (
    // @ts-ignore
    <SettingsEditor
      title={t('profile.patientDetailsSubheader')}
      defaultData={defaultData}
      validate={() => undefined} // Handled by Date Picker
      pages={pages}
      onSubmit={async (formData, onCompleted) => {
        const birthday = moment(formData.birthday).format(
          localeFormat.moment.defaultFormat
        );
        const response = await updatePatient({
          trialOptionId,
          birthday,
        });

        onCompleted(response);

        // @TODO: understand why we need this and then remove it
        // @ts-ignore
        if (!Boolean(response?.error) && !!props.updateParent) {
          props.updateParent();
        }
      }}
      onFinish={() => refetchGetPatientDetailsEditor()}
    />
  );
}

export default PatientDetailsEditor;
