import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import { getTrialInstanceAvailability } from '../../../controllers/clinicController';
import { userIsParticipant } from '../../../lib/users';
import CurebaseScheduler from '../../basic/CurebaseScheduler';
import { TimeSlot } from '@curebase/modules/scheduling/dto/scheduling';
import { currentUserHasPermission } from '../../../lib/auth';
import { RolePermissions } from '../../../types';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/Loading';
import { BookerRootContext } from './BookerDialog';

const ABBR_TO_TIMEZONE = {
  EST: 'Eastern Time',
  EDT: 'Eastern Time',
  CST: 'Central Time',
  CDT: 'Central Time',
  MST: 'Mountain Time',
  MDT: 'Mountain Time',
  PST: 'Pacific Time',
  PDT: 'Pacific Time',
};

interface BookerSelectSlotProps {
  trialOptionId: number;
  trialInstanceId: string;
  visitSiteId: number;
  visitSiteTimezone: string;
  onSelectSlot: (p: TimeSlot) => Promise<void>;
  onRequestManualScheduling: () => void | Promise<void>;
  onBack: () => void | Promise<void>;
  schedulingWindow?: { start: number; end: number } | null;
}

export function NoAvailabilityView(props: { onRequestManualScheduling: any }) {
  const { onRequestManualScheduling } = props;
  const { t } = useTranslation('translations');

  return (
    <>
      <div className='booker-text'>
        {userIsParticipant()
          ? t('axleBooker.noCalendarForLocationText')
          : currentUserHasPermission(RolePermissions.EditScheduling)
          ? t('axleBooker.noCalendarAvailableText')
          : t('axleBooker.contactCalendarConfigText')}
      </div>

      <div
        className='booker-button'
        role='button'
        onClick={onRequestManualScheduling}
      >
        {t('axleBooker.findMeTimeLabel')}
      </div>
    </>
  );
}

function BookerSelectSlot(props: BookerSelectSlotProps) {
  const params: { configId: string } = useParams();
  const [availability, setAvailability] = useState<TimeSlot[] | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<number | null>(
    null
  );
  const [monthVisibleToUser, setMonthVisibleToUser] = useState<string>();
  const { config } = useContext(BookerRootContext);
  const { t } = useTranslation('translations');
  const {
    trialOptionId,
    trialInstanceId,
    visitSiteId,
    onSelectSlot,
    onBack,
  } = props;
  const timezone = moment.tz(moment.tz.guess(true)).format('z');
  const timezoneString = ABBR_TO_TIMEZONE[timezone]
    ? `${ABBR_TO_TIMEZONE[timezone]} - US & Canada`
    : t('bookerSelector.timezone', { timezone });

  //fetch availability
  useEffect(() => {
    const fetchAvailability = async () => {
      const { configId } = params;
      const slots: TimeSlot[] = await getTrialInstanceAvailability(
        trialOptionId,
        trialInstanceId,
        visitSiteId,
        !userIsParticipant(),
        configId,
        monthVisibleToUser
      );

      setAvailability(slots);
    };
    fetchAvailability();
    return () => setAvailability(null);
  }, [params, trialInstanceId, visitSiteId, trialOptionId, monthVisibleToUser]);

  if (!availability)
    return (
      <>
        <Loading>
          <p className='booker-loading-message'>
            {t('bookerSelectorSlot.loadingMessage')}
          </p>
        </Loading>
      </>
    );
  const appointmentName =
    config?.default?.appointmentBookingInfo?.appointmentBookingHeader ?? '';

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>
        {userIsParticipant()
          ? availability.length === 0
            ? t('axleBooker.requestManualBooking')
            : t('axleBooker.scheduleYourAppointment', {
                name: appointmentName,
              })
          : availability.length === 0
          ? t('axleBooker.haventTimeBlocksLocation')
          : t('axleBooker.scheduleAppointment', {
              name: appointmentName,
            })}
      </div>

      <div className='booker-back-button' onClick={onBack}>
        ← {t('bookerSelectorSlot.backBtn')}
      </div>

      {config?.instructions?.patient &&
        config?.instructions?.patient !== '' && (
          <div className='booker-text'>{config.instructions.patient}</div>
        )}

      {availability.length === 0 ? (
        <NoAvailabilityView
          onRequestManualScheduling={props.onRequestManualScheduling}
        />
      ) : (
        <>
          <div
            className='booker-text'
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <LanguageIcon fontSize='inherit' style={{ marginRight: '0.3em' }} />
            <span>{timezoneString}</span>
          </div>
          <CurebaseScheduler
            onSelectSlot={onSelectSlot}
            selectedStartTime={selectedStartTime}
            setSelectedStartTime={setSelectedStartTime}
            slots={availability}
            visitSiteTimezone={moment.tz.guess()}
            setMonthVisibleToUser={setMonthVisibleToUser}
            monthVisibleToUser={monthVisibleToUser}
          />
        </>
      )}
    </div>
  );
}

export default BookerSelectSlot;
