import {
  GenerateAccessCodeForSponsorRequest,
  GenerateAccessCodeForSponsorResponse,
  GenerateAccessCodeForUserRoleRequest,
  GenerateAccessCodeForUserRoleResponse,
  GenerateProviderAccessCodeRequest,
  GenerateProviderAccessCodeResponse,
  InviteParticipantRequest,
  InviteParticipantResponse,
} from '@curebase/core/decoders/accessCodes';

import { UserRoleType } from '@curebase/core/types';
import { jsonPost } from '../lib/fetchHelpers';

export async function generateAccessCodeForProvider(
  clinicId: string,
  email: string,
  userRoleType: UserRoleType
): Promise<GenerateProviderAccessCodeResponse> {
  const requestBody: GenerateProviderAccessCodeRequest = {
    clinicId,
    email,
    userRoleType,
  };
  const response: GenerateProviderAccessCodeResponse = await jsonPost(
    '/accesscode/generateprovider',
    requestBody
  );
  return response;
}

export async function inviteParticipant(
  trialOptionId: number
): Promise<InviteParticipantResponse> {
  const requestBody: InviteParticipantRequest = { trialOptionId };

  return await jsonPost('/accesscode/inviteparticipant', requestBody);
}

export async function generateAccessCodeForSponsor(
  sponsorId: number,
  email: string
): Promise<GenerateAccessCodeForSponsorResponse> {
  const requestBody: GenerateAccessCodeForSponsorRequest = { sponsorId, email };
  const response: GenerateAccessCodeForSponsorResponse = await jsonPost(
    '/accesscode/generatesponsor',
    requestBody
  );
  return response;
}

export async function generateAccessCodeForUserRoleType(
  trialId: number,
  userRoleType: UserRoleType,
  email: string
): Promise<GenerateAccessCodeForUserRoleResponse> {
  const requestBody: GenerateAccessCodeForUserRoleRequest = {
    trialId,
    userRoleType,
    email,
  };

  const response: GenerateAccessCodeForUserRoleResponse = await jsonPost(
    '/accesscode/generateSignupLinkForUserRole',
    requestBody
  );

  return response;
}
