import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { useTranslation } from 'react-i18next';

type Props = {
  confirmAction: () => Promise<void>;
  title: string;
  message: string;
};

function ActionConfirmDialog(props: Props) {
  const { t } = useTranslation('translations');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(
    false
  );

  const { title, confirmAction, message } = props;

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        style={{ margin: '26px' }}
        onClick={() => setShowConfirmationDialog(true)}
      >
        {title}
      </Button>

      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <ConfirmationInDialog
          title={t('actionConfirmDialog.title')}
          message={message}
          onConfirm={async () => {
            await confirmAction();
            setShowConfirmationDialog(false);
          }}
          onClose={() => setShowConfirmationDialog(false)}
        />
      </Dialog>
    </>
  );
}

export default ActionConfirmDialog;
