import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'src/context/localeContext';
import { OverridableTranslationContext } from 'src/context/OverridableTranslationContext';

/**
 *
 *
 * This hook allows to easily use the `OverridableTranslationContext` and be able to have a
 * Component tree in which can ignore the app's locale.
 *
 * Basically you will use this hook if:
 * 1. You are creating a component that will be used in the participant experience and the CRC views
 *    - examples: consent, data capture, etc.
 *
 */
export const useOverridableTranslation = (namespace?: string) => {
  const values = useContext(OverridableTranslationContext);
  const { i18n } = useTranslation();
  const language = values.locale?.split('-')[0] || getLocale().language;
  const locale = values.locale || getLocale().locale;
  return {
    t: i18n.getFixedT(locale, namespace),
    i18n,
    locale,
    language,
  };
};
