import { setTrialOptions } from '../store/actions/index';
import { executeQuery } from 'src/ApolloClient';

export async function setTrialOptionsInStore(userId: number) {
  const fetchedPatients = (
    await executeQuery(`getPatients(userId : ${userId}){ id }`)
  ).getPatients;

  if (!fetchedPatients || fetchedPatients.length < 1) {
    // @ts-expect-error
    setTrialOptions(null, []);
    return;
  }
  const fetchedPatient = fetchedPatients[0]; // [JR] - fixme

  const fetchedTrialOptions = (
    await executeQuery(`getTrialOptions(patientId : ${fetchedPatient.id}) {
         id
        trial {
          id
          trialIdentifier
        }
      }`)
  ).getTrialOptions;

  const pptsTrialOptions = fetchedTrialOptions.map(x => {
    return { trialOptionId: x.id, trialIdentifier: x.trial.trialIdentifier };
  });
  setTrialOptions(fetchedPatient.id, pptsTrialOptions);
}
