import { userHasPermission } from '@curebase/core/lib/authorization';
import {
  DEVELOPER_FEATURES_ENABLED,
  IS_DEVELOPMENT,
  IS_STAGING,
} from '@curebase/core/lib/env';
import { LeftNavbarQuery, RolePermissions } from '@curebase/core/types';
import { withStyles } from '@material-ui/core';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import AccessibilityIcon from '@material-ui/icons/AccessibilityRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import BuildIcon from '@material-ui/icons/Build';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FlagIcon from '@material-ui/icons/OutlinedFlag';
import PaymentIcon from '@material-ui/icons/Payment';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { getUser as getUserSelector } from 'src/store/user/selectors';
import { useLeftNavbarQuery } from 'src/types';
import { signoutSession } from '../controllers/authController';
import {
  currentUserClinicIds,
  getUserPatientIds,
  getUserSponsorTrialIds,
  getUserSponsorTrials,
} from '../lib/auth';
import { PRIVACY_CENTER_URL } from '../lib/env';
import { interfaceType } from '../lib/users';
import { hideMenuDrawer } from '../store/actions';
import {
  getPatientModeSelector,
  getResourcesSelector,
} from '../store/selectors';
import GitCommit from '../_git_commit';
import DateMocking from './DateMocking';
import { withBreakpoints } from './hocs/WithBreakpoints';
import Loading from './Loading';
import ClinicDropdown from './ResearcherInterface/ClinicDropdown';
import TrialDropdown from './SponsorInterface/TrialDropdown';

type NavDivider = {
  type: 'DIVIDER';
};

type NavButton = {
  type: 'BUTTON';
  displayText: string;
  icon: JSX.Element;
  endChip?: ChipProps;
  tooltip?: string;
  route?: string;
  action?: () => any;
  permission?: RolePermissions;
};

export type NavItem = NavDivider | NavButton;

type Props = {
  extraSmallScreen?: boolean;
  tabletSizedScreen?: boolean;
  classes?: any;
  onClickToDownloadApp?: () => void;
};

const styles = theme => ({
  materialButton: {
    color: 'white',
  },
});

function navItemsForPatient(
  tempResourcesRemove: any,
  data: LeftNavbarQuery | null | undefined,
  t: any,
  trialOptionIds: number[],
  dispatch: any,
  selectedTrialOptionId: number
): NavItem[] | null | undefined {
  const patientId = getUserPatientIds()[0];
  const paymentsEnabled = data?.getTrialOption.participantPayments;

  if (patientId) {
    const buttons: NavItem[] = [
      {
        type: 'BUTTON',
        displayText: t('leftNavbar.patient.dashboard'),
        icon: <HomeIcon />,
        route: `/u/dashboard/patient/${selectedTrialOptionId}`,
      },
    ];

    if (paymentsEnabled) {
      buttons.push({
        type: 'BUTTON',
        displayText: t('leftNavbar.patient.payments'),
        icon: <PaymentIcon />,
        route: `/u/payments/patient/${selectedTrialOptionId}`,
      });
    }

    const icfButton: NavButton = {
      type: 'BUTTON',
      displayText: t('leftNavbar.patient.informedConsent'),
      icon: <AssignmentIcon />,
      route: `/u/${data?.getTrialOption?.id}/consent`,
    };
    if (data?.getTrialOption?.signedDocuments?.length !== 0)
      buttons.splice(1, 0, icfButton);

    const switchTrialButton: NavButton = {
      type: 'BUTTON',
      displayText: t('leftNavbar.patient.switchTrial'),
      icon: <CallMadeIcon />,
      action: () => {
        dispatch({ type: 'DELETE_SELECTED_TRIAL' });
      },
      route: '/u/trialSelector',
    };

    if (trialOptionIds.length > 1) buttons.push(switchTrialButton);

    return buttons.filter((x): x is NavItem => !!x);
  }
}

function navItemsUsingPermissions(
  user: any,
  t: any,
  location,
  data: LeftNavbarQuery | null | undefined,
  trialOptionIds: number[],
  dispatch: any,
  selectedTrialOptionId: number
): NavItem[] {
  const list: NavItem[] = [
    {
      type: 'BUTTON',
      displayText: 'OpenAPI Docs',
      icon: <AccessibilityIcon />,
      route: '/u/openapi',
      tooltip: 'The OpenAPI documentation',
      permission: RolePermissions.CanViewSwaggerDocs,
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.provider.patients'),
      icon: <AccessibilityIcon />,
      route: '/u/participants',
      permission: RolePermissions.ViewParticipants,
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.investigators'),
      icon: <LocalHospitalIcon />,
      route: '/u/clinic',
      permission: RolePermissions.ViewResearchSites,
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.sponsors'),
      icon: <MonetizationOnIcon />,
      route: '/u/sponsors',
      permission: RolePermissions.ViewSponsors,
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.trials'),
      icon: <BuildIcon />,
      route: '/u/trial',
      permission: RolePermissions.ViewTrials,
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.adverseEvents'),
      icon: <WarningIcon />,
      permission: RolePermissions.ViewAdverseEvents,
      route: '/u/reportevent/adverseevents',
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.caseReportSignoff'),
      icon: <BorderColorIcon />,
      permission: RolePermissions.ViewCaseReportSignoff,
      route: '/u/crfsignoff/',
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.protocolDeviations'),
      icon: <FlagIcon />,
      route: '/u/protocoldeviations',
      permission: RolePermissions.ViewProtocolDeviations,
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.associate.queries'),
      icon: <QuestionAnswerIcon />,
      permission: RolePermissions.ReadCapturedDataEditQueryMessage,
      route: `/u/queries`,
    },
  ];

  if (IS_STAGING || IS_DEVELOPMENT) {
    list.push({
      type: 'BUTTON',
      displayText: 'Query Editor',
      endChip: {
        label: 'Dev',
        size: 'small',
        color: 'secondary',
      },
      tooltip: 'GraphQL Query Editor',
      icon: <InsertChartIcon />,
      action: () => window.open('/dev-query', '_blank'),
    });
    list.push({
      type: 'BUTTON',
      displayText: 'Arena Queue',
      endChip: {
        label: 'Dev',
        size: 'small',
        color: 'secondary',
      },
      tooltip: 'Arena Frontend for Bull Queue',
      icon: <InsertChartIcon />,
      action: () => window.open('/arena/dashboard', '_blank'),
    });
  }

  if (IS_DEVELOPMENT) {
    list.push({
      type: 'BUTTON',
      displayText: 'Redis Commander',
      endChip: {
        label: 'Dev',
        size: 'small',
        color: 'secondary',
      },
      tooltip: 'Redis Commander',
      icon: <InsertChartIcon />,
      action: () =>
        window.open('http://' + document.location.hostname + ':8081', '_blank'),
    });
  }

  list.push({
    type: 'BUTTON',
    displayText: t('leftNavbar.associate.referrals'),
    icon: <CardMembershipIcon />,
    permission: RolePermissions.GenerateReferralSources,
    route: `/u/referralSources`,
  });

  const match = matchPath<{ clinicId?: string }>(location.pathname, {
    path: '/u/clinic/:clinicId',
    exact: false,
  });

  let clinicId = match?.params?.clinicId;
  if (!clinicId) {
    clinicId = currentUserClinicIds()?.[0];
  }

  if (clinicId) {
    const otherList: NavItem[] = [
      {
        type: 'BUTTON',
        displayText: t('leftNavbar.provider.payments'),
        icon: <PaymentIcon />,
        permission: RolePermissions.ViewSelfClinicPayments,
        route: `/u/clinic/${clinicId}/payments`,
      },
      {
        type: 'DIVIDER',
      },
      {
        type: 'BUTTON',
        displayText: t('leftNavbar.settings'),
        icon: <SettingsIcon />,
        permission: RolePermissions.ViewSelfClinicSettings,
        route: `/u/clinic/${clinicId}/settings`,
      },
    ];
    list.push(...otherList);
  }

  const userSponsorTrialIds = getUserSponsorTrialIds();
  if (userSponsorTrialIds?.length > 0) {
    const trialId = userSponsorTrialIds[0];
    const sponsorLinks: NavItem[] = [
      {
        type: 'BUTTON',
        displayText: t('leftNavbar.sponsor.reports'),
        icon: <AssignmentIcon />,
        permission: RolePermissions.SponsorDashboard,
        route: `/u/reports/trial/${trialId}`,
      },
      {
        type: 'BUTTON',
        displayText: t('leftNavbar.sponsor.clinics'),
        icon: <MeetingRoomIcon />,
        route: `/u/clinic/trial/${trialId}`,
        permission: RolePermissions.SponsorDashboard,
      },
    ];
    list.push(...sponsorLinks);
  }

  const navItemsForPpt = navItemsForPatient(
    null,
    data,
    t,
    trialOptionIds,
    dispatch,
    selectedTrialOptionId
  );
  if (navItemsForPpt) {
    list.push(...navItemsForPpt);
  }

  // add common elements
  list.push(
    {
      type: 'DIVIDER',
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.settings'),
      icon: <SettingsIcon />,
      route: '/u/settings',
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.privacyCenter'),
      icon: <PolicyOutlinedIcon />,
      action: () => window.open(PRIVACY_CENTER_URL, '_blank'),
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.adminTools'),
      icon: <DynamicFeedIcon />,
      permission: RolePermissions.ViewAdminTools,
      route: '/u/adminTools',
    },
    {
      type: 'BUTTON',
      displayText: t('leftNavbar.signOut'),
      icon: <ExitToAppIcon />,
      action: () => signoutSession(),
    }
  );

  return list;
}

const LeftNavbar = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const user = useSelector(getUserSelector);
  const resources = useSelector(getResourcesSelector);
  let { trialOptionId } = user;
  let insideTrialOption = false;
  const match = matchPath<{ clinicId?: string }>(location.pathname, {
    path: '/u/clinic/:clinicId',
    exact: false,
  });
  const clinicId = match?.params.clinicId;
  const patientInTrialOptionMatch = matchPath<{ trialOptionId?: string }>(
    location.pathname,
    {
      path: '/u/dashboard/patient/:trialOptionId',
      exact: true,
    }
  );

  if (
    patientInTrialOptionMatch &&
    patientInTrialOptionMatch.params.trialOptionId &&
    !trialOptionId
  )
    trialOptionId = parseInt(patientInTrialOptionMatch.params.trialOptionId);

  const {
    additionalUserInfo: { clinics },
  }: any = user;

  const trialOptionIds = (resources as any).patientsTrialOptions
    ?.trialOptionIds;

  const nonPatientInTrialOptionMatch = matchPath<{ trialOptionId?: string }>(
    location.pathname,
    {
      path: '/u/participants/:trialOptionId',
      exact: false,
    }
  );

  if (
    nonPatientInTrialOptionMatch &&
    nonPatientInTrialOptionMatch.params.trialOptionId &&
    !trialOptionId
  )
    trialOptionId = parseInt(nonPatientInTrialOptionMatch.params.trialOptionId);

  const { data, loading } = useLeftNavbarQuery({
    skip: !trialOptionId,
    variables: {
      trialOptionId: trialOptionId!,
    },
  });

  insideTrialOption =
    !!patientInTrialOptionMatch || !!nonPatientInTrialOptionMatch;
  const patientMode: any = useSelector(getPatientModeSelector);
  if (['IN_PATIENT_MODE', 'SHOW_EXIT_DIALOG'].includes(patientMode.state))
    return null;
  if (loading) return <Loading />;

  const navItems: NavItem[] = navItemsUsingPermissions(
    user,
    t,
    location,
    data,
    trialOptionIds,
    dispatch,
    trialOptionId!
  ).filter(item =>
    item.type === 'DIVIDER'
      ? true
      : !item.permission || userHasPermission(user, item.permission)
  );

  const sponsorTrialIds = getUserSponsorTrials();
  return (
    <>
      <div className='left-navbar'>
        {clinics && (
          <ClinicDropdown
            // @ts-ignore
            clinics={clinics}
            currentClinicId={clinicId}
          />
        )}

        {sponsorTrialIds && <TrialDropdown trials={sponsorTrialIds} />}

        {navItems.map((navItem, i) => {
          if (navItem.type === 'BUTTON') {
            const isSelected = navItem.route
              ? location.pathname.endsWith(navItem.route)
              : false;
            return (
              <Tooltip
                disableFocusListener={isNil(navItem.tooltip)}
                disableHoverListener={isNil(navItem.tooltip)}
                disableTouchListener={isNil(navItem.tooltip)}
                title={navItem.tooltip || false}
                arrow
                enterDelay={1000}
                leaveDelay={150}
                key={i}
              >
                <div
                  className={`item ${isSelected ? 'is-selected' : ''}`}
                  onClick={() => {
                    if (navItem.action) {
                      navItem.action();
                    }

                    hideMenuDrawer();

                    if (navItem.route) {
                      history.push(navItem.route);
                    }
                  }}
                >
                  <div className='icon'>{navItem.icon}</div>
                  {`${navItem.displayText}`}
                  {!isNil(navItem.endChip) ? (
                    <>
                      {' '}
                      <Chip {...navItem.endChip} />
                    </>
                  ) : null}
                </div>
              </Tooltip>
            );
          } else if (navItem.type === 'DIVIDER') {
            return <div key={i} className='divider' />;
          } else {
            return null;
          }
        })}
        {DEVELOPER_FEATURES_ENABLED && insideTrialOption && trialOptionId && (
          <DateMocking
            trialOptionId={trialOptionId}
            dateOffset={data?.getTrialOption.dateOffset || 0}
          />
        )}
        <div className='item small'>
          <p>
            {(user?.additionalUserInfo?.firstName ?? '') +
              ' ' +
              (user?.additionalUserInfo?.lastName ?? '')}
          </p>
          <p>{user.email}</p>
        </div>
        <div className='divider' />
        <div className='item small bottom'>
          <p>
            Version: {GitCommit.version} - #{GitCommit.sha?.substring(0, 6)}
          </p>
          <p>{interfaceType(t)}</p>
        </div>
      </div>
    </>
  );
};

// @ts-ignore
export default withBreakpoints(
  withStyles(styles)(LeftNavbar) as any
) as React.FC<Props>;
