import React from 'react';
import DynamicForm from './basic/DynamicForm';
import { useImmutableState } from 'src/hooks/useImmutableState';
import { createPaperDocument } from 'src/controllers/signatureController';
import { useParams } from 'react-router-dom';
import { SigningConfiguration } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';

interface PaperConsentParams {
  trialOptionId: string;
  documentType: string;
}

interface PaperConsentProps {
  refetch: () => Promise<any>;
  signingConfiguration: SigningConfiguration;
}

function PaperDocumentUpload(props: PaperConsentProps) {
  const { t } = useTranslation('translations');
  const { refetch, signingConfiguration } = props;
  const [data, setData] = useImmutableState<Record<string, any>>({});
  const params = useParams<PaperConsentParams>();

  const elements: any = [
    {
      note: t('paperDocumentUpload.elements.note'),
      title: t('paperDocumentUpload.elements.title'),
      subElements: [
        {
          key: 'files',
          type: 'FILE',
          optional:
            signingConfiguration === SigningConfiguration.PaperScanNotRequired,
        },
      ],
    },
  ];

  return (
    <DynamicForm
      // @ts-ignore
      pages={[
        {
          headerText: t('paperDocumentUpload.headerText'),
          elements,
        },
      ]}
      data={data}
      onChange={(key: string, value: any) => {
        setData({ [key]: value });
      }}
      onSubmit={async () => {
        const success = await createPaperDocument(
          data?.files.map(f => f.fileId),
          params.documentType,
          params.trialOptionId
        );
        if (success) await refetch();
        else {
          console.error('Error during paper consent', data);
        }
      }}
      submitText={t('common.submit')}
    />
  );
}

export default PaperDocumentUpload;
