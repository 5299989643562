import React from 'react';
import { StudyPlanVisitSummary } from '../../types';
import Header from '../basic/Header';
import { ParticipationTag } from '../TrialOption/ParticipantTags';
import { EpochStatusTag } from '../TrialOption/EpochStatusTag';

export const DataCaptureVisitHeader = (props: {
  studyPlanVisitSummary: StudyPlanVisitSummary;
  displayName: string | null;
  subjectIdentifier: string | null;
  status: string;
  trialName: string;
  trialDeactivated: boolean;
  onBack: () => void;
  history: any;
  trialOption: any;
}) => {
  const {
    studyPlanVisitSummary,
    subjectIdentifier,
    displayName,
    trialName,
    trialDeactivated,
    trialOption,
    onBack,
  } = props;

  const beforeTitle = (
    <div className='trial-row'>
      <div className='non-arrow'>
        {displayName && <div className={`participant-name`}>{displayName}</div>}
        <div className='participant-trial-container'>
          <ParticipationTag
            participant={{
              subjectIdentifier,
              trial: {
                name: trialName,
                deactivated: trialDeactivated,
              },
            }}
          />
          <EpochStatusTag trialOption={trialOption} />
        </div>
      </div>
    </div>
  );
  return (
    <Header
      beforeTitleElement={beforeTitle}
      // @ts-ignore
      displayText={studyPlanVisitSummary.title}
      // @ts-ignore
      subtext={studyPlanVisitSummary.description}
      back={() => onBack && onBack()}
      sticky
    />
  );
};
