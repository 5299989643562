import * as React from 'react';
import { withTranslation } from 'react-i18next';

import FormDialog from '../basic/FormDialog';
import { createCapturedDataQuery } from '../../controllers/queryEditsController';

import withPermission from '../hocs/WithPermission';
import { RolePermissions } from '@curebase/core/types';
type Props = {
  capturedDataId?: number;
  onClose: () => any;
  onSubmit: () => any;
  t: any;
};

type DynamicFormState = {
  typeOfQuery: string;
  message: string;
};

const initialState: DynamicFormState = {
  typeOfQuery: '',
  message: '',
};
class CreateQueryDialog extends React.Component<Props> {
  onSubmit = async (formData: DynamicFormState) => {
    const { capturedDataId, onClose, onSubmit } = this.props;
    const { typeOfQuery, message } = formData;

    if (!capturedDataId) return;
    await createCapturedDataQuery(capturedDataId, typeOfQuery, message);

    onClose();
    onSubmit();
  };

  render() {
    const { capturedDataId, t } = this.props;
    if (!capturedDataId) {
      return null;
    }

    const queryTypes = ['Formatting Issue', 'Out of range', 'Other'];

    const elements = [
      {
        title: 'Type of Query',
        subElements: [
          //$FlowFixMe
          {
            key: 'typeOfQuery',
            type: 'MULTISELECT',
            options: queryTypes.map(q => {
              return {
                text: q,
                value: q,
              };
            }),
          },
        ],
      },
      {
        title: t('queries.dialogs.createQuery.describe'),
        note: t('queries.dialogs.blindingWarning'),
        subElements: [
          {
            key: 'message',
            type: 'PARAGRAPH',
            placeholder: 'Example: Why is the data out of the reference range',
          },
        ],
      },
    ];
    return (
      <FormDialog
        open={!!capturedDataId}
        onClose={this.props.onClose}
        title='Issue a Query'
        initialState={initialState}
        // @ts-ignore
        onSubmit={this.onSubmit}
        // @ts-ignore
        dynamicFormPages={[{ elements }]}
      />
    );
  }
}

export default withPermission({
  permission: RolePermissions.CreateCapturedDataQuery,
})(withTranslation('translations')(CreateQueryDialog as any));
