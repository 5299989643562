type Site = {
  name: string;
  id: number;
} & any;

export const getSiteOptions = (
  key: string,
  title: string,
  sites: Site[] | null
) => {
  if (!sites) {
    throw new Error('Cannot get research sites for empty visit sites');
  }

  const options: Array<any> = sites.map(site => ({
    text: site.name,
    value: site.id,
  }));

  return {
    title: title,
    subElements: [
      {
        key: key,
        type: 'DROPDOWN',
        options: options,
        allowNull: true,
      },
    ],
  };
};
