import { apiUrl, webBaseUrl } from '@curebase/core/lib/env';
import { FetchError } from './error';
import { clearUser, setMaintenanceModeEnabled } from '../store/actions/index';
import { getSessionExpiredRedirect } from './auth';
import { HTTP_STATUS_CODE } from './constants';
import { getLocale } from '../context/localeContext';

function reportError(error: Object) {
  const fetchError = new FetchError(error);
  console.error(fetchError);
  throw error;
}

export const FETCH_PATH_OPTIONS = {
  RELATIVE_FRONTEND: 'RELATIVE_FRONTEND',
  RELATIVE_BACKEND: 'RELATIVE_BACKEND', // The default
  ABSOLUTE: 'ABSOLUTE',
};

type FetchInterceptor = (resp: Response) => Promise<Response>;

const interceptMaintenance: FetchInterceptor = async (resp: Response) => {
  const url = new URL(resp.url);
  // Make sure that the service is unavailable and that the host is curebase
  if (
    resp.status === HTTP_STATUS_CODE.SERVICE_UNAVAILABLE &&
    webBaseUrl.includes(url.hostname)
  ) {
    // HTTP 503: Service unavailable. This meansl
    // the application is down and we should tell the app
    // to redirect the user to the maintenance page.
    setMaintenanceModeEnabled(true);
  }
  return resp;
};
// Overwrite the orignal fetch method so that we can build custom interceptors
const { fetch: origFetch } = window;
/**
 * This method overwrites the fetch method so that
 * interceptors (or other features) can be built into
 * the responses.
 */
window.fetch = async (...args) => {
  const response = await origFetch(...args);

  // Insert the interceptors for fetch calls here
  // Each interceptor must take the response as the sole argument
  // and return the response.
  const interceptors: FetchInterceptor[] = [interceptMaintenance];

  // Using the cloned response
  let interceptedResponse = await response.clone();
  for (const interceptor of interceptors) {
    interceptedResponse = await interceptor(interceptedResponse);
  }
  // Return the original response so as not to interfere with current methods.
  return response;
};

export async function fetchHelper(
  route: string,
  options: any = {},
  pathOption: string = FETCH_PATH_OPTIONS.RELATIVE_BACKEND
): Promise<any> {
  let url = `${apiUrl}${route}`; // RELATIVE_BACKEND

  if (pathOption === FETCH_PATH_OPTIONS.ABSOLUTE) {
    url = route;
  } else if (pathOption === FETCH_PATH_OPTIONS.RELATIVE_FRONTEND) {
    url = `${webBaseUrl}${route}`;
  }

  let headers: any = {};

  if (!options.dontForceContentType) {
    headers = { Accept: 'application/json' };
    headers['Content-Type'] = 'application/json';
  }

  const obj = {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
      credentials: 'include',
      'x-curebase-locale': getLocale().locale,
    },
    credentials: 'include',
  };

  return fetch(url, obj).catch(reportError);
}

export function unpackJSON(fetchPromise: Promise<any>): Promise<any> {
  return fetchPromise
    .then(res => res.json())
    .then(res => {
      if (res.error && res.error.startsWith('UNAUTHENTICATED')) {
        clearUser();
        window.location.href = getSessionExpiredRedirect();
      }
      return res;
    })
    .catch(reportError);
}

export async function jsonPost<Request, Response>(
  route: string,
  body: Request,
  options: any = {}
): Promise<Response> {
  const result = await unpackJSON(
    fetchHelper(route, {
      method: 'POST',
      body: options.dontForceContentType ? body : JSON.stringify(body),
      ...options,
    })
  );
  return result;
}

export async function jsonGet<Response>(
  route: string,
  options: any = {}
): Promise<Response> {
  const result = await unpackJSON(
    fetchHelper(route, {
      method: 'GET',
      ...options,
    })
  );
  return result;
}
