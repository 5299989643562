import {
  CaseReportSignoff,
  CaseReportSignoffType,
  TrialOption,
} from '../types';

export const getCaseReportSignoffMaxDate = <
  C extends Pick<CaseReportSignoff, 'createdAt' | 'type'>,
  T extends Pick<TrialOption, 'enrollmentDate'>
>(
  caseReportSignoff: C,
  trialOption: T
) => {
  const { enrollmentDate } = trialOption;
  const { createdAt: crfSignoffCreatedAt } = caseReportSignoff;
  const maxDate =
    caseReportSignoff.type === CaseReportSignoffType.EligibilityAssessment &&
    enrollmentDate
      ? enrollmentDate
      : crfSignoffCreatedAt;

  return maxDate;
};
