export function formatAddress(data: any, t: any = (a: any, b: any) => b || a) {
  const basecase = t(
    'errors.clinicNoAddress',
    "This clinic doesn't have an address defined in Curebase."
  );
  if (!data) return basecase;
  const s = `${data.addressLine1 || ''} ${data.addressLine2 || ''} ${
    data.city || ''
  } ${data.state || data.county || ''} ${data.zipcode || ''} ${(
    data.country || ''
  ).toUpperCase()}`.trim();
  if (s === '') {
    return basecase;
  }
  return s;
}
