import React from 'react';
import {
  CircularProgress,
  Divider,
  Popover,
  withStyles,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import { timeTravelTrialOption } from '../controllers/dataCaptureController';
import { getLocale } from 'src/context/localeContext';

type Props = {
  classes: any;
  setDate: (date: number) => void;
  resetDate: (offset: number) => void;
  currentDate: number;
  trialOptionId: number;
  dateOffset: number;
  t: any;
};

enum InvalidDates {
  INVALID = 'invalidDate',
  ONLY_FUTURE = 'onlyDatesInFuture',
}

type State = {
  languageAnchor?: any;
  accountAnchor?: any;
  dateMockingAnchor: any;
  dateOffset: number;
  dateValue: string;
  invalidDate: InvalidDates | null;
  loading: boolean;
};

const styles = () => ({
  iconLeft: {
    marginBottom: 1,
  },
  username: {
    marginLeft: 10,
  },
  button: {
    color: 'white',
  },
});

const dateTimeLocalFormat = "y-LL-dd'T'HH:mm";
const { locale } = getLocale();

class DateMocking extends React.Component<Props, State> {
  state = {
    dateMockingAnchor: null,
    dateOffset: this.props.dateOffset,
    invalidDate: null,
    loading: false,
    dateValue: DateTime.fromMillis(this.props.currentDate).toFormat(
      dateTimeLocalFormat
    ),
  };

  reset = async () => {
    const { resetDate, trialOptionId } = this.props;
    this.setState({ loading: true });
    await timeTravelTrialOption(0, trialOptionId);
    this.setState({ dateOffset: 0, loading: false });
    resetDate(0);
    this.cancel();
  };

  cancel = () => {
    this.setState({
      invalidDate: null,
      dateMockingAnchor: null,
      dateValue: DateTime.fromMillis(this.props.currentDate).toFormat(
        dateTimeLocalFormat
      ),
    });
  };

  setOffset = async () => {
    const { resetDate, currentDate, trialOptionId } = this.props;
    const { dateOffset } = this.state;

    const offsetDate = DateTime.fromFormat(
      this.state.dateValue,
      dateTimeLocalFormat
    );
    if (!offsetDate.isValid) {
      this.setState({ invalidDate: InvalidDates.INVALID });
      return;
    }
    const offset =
      offsetDate.diff(
        DateTime.fromMillis(currentDate).set({ second: 0, millisecond: 0 }),
        'seconds'
      ).seconds + dateOffset;

    if (offset < 0) {
      this.setState({ invalidDate: InvalidDates.ONLY_FUTURE });
      return;
    }
    this.setState({
      dateMockingAnchor: null,
      dateOffset: offset,
      loading: true,
    });

    await timeTravelTrialOption(offset, trialOptionId);
    this.setState({ loading: false });
    resetDate(offset);
  };

  render() {
    const { classes, currentDate, t } = this.props;
    const {
      dateMockingAnchor,
      dateValue,
      dateOffset,
      invalidDate,
      loading,
    } = this.state;
    const minDate = DateTime.fromMillis(currentDate)
      .minus({ second: dateOffset })
      .toFormat(dateTimeLocalFormat);
    const icon = loading ? (
      <CircularProgress size={20} />
    ) : (
      // @ts-ignore
      <QueryBuilderIcon nativecolor='white' className={classes.iconLeft} />
    );

    return (
      <>
        {/* @ts-ignore */}
        <Button
          style={{ paddingLeft: 0 }}
          aria-owns={dateMockingAnchor ? 'simple-menu' : null}
          aria-haspopup='true'
          disabled={loading}
          onClick={e => {
            this.setState({ dateMockingAnchor: e.currentTarget });
          }}
          className={classes.button}
        >
          {icon}
          <span className={classes.username}>
            {DateTime.fromMillis(currentDate).toFormat('EEE, ff', { locale })}
          </span>
        </Button>
        <Popover
          className={'time-travel'}
          open={Boolean(dateMockingAnchor)}
          anchorEl={dateMockingAnchor}
          style={{ zIndex: 999999 }}
          onClose={() => {
            this.cancel();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className='time-travel-container'>
            <h4>{t('dateMocking.title')}</h4>
            <div className='buttons'>
              <Button
                variant={'contained'}
                size={'small'}
                className={'reset'}
                onClick={() => {
                  this.reset();
                }}
              >
                {t('dateMocking.button.reset')}
              </Button>
              <Button
                variant={'contained'}
                size={'small'}
                onClick={() => {
                  this.cancel();
                }}
              >
                {t('dateMocking.button.cancel')}
              </Button>
              <Button
                variant={'contained'}
                size={'small'}
                color={'primary'}
                className={'save'}
                onClick={() => this.setOffset()}
              >
                {t('dateMocking.button.save')}
              </Button>
            </div>
            <Divider light={true} />
            <input
              type='datetime-local'
              min={minDate}
              value={dateValue}
              onChange={async (e: any) => {
                this.setState({
                  dateValue: e.target.value,
                  invalidDate: null,
                });
              }}
            />
            {invalidDate && (
              <div className={'error'}>
                {t(`leftNavbar.timeTravel.${invalidDate}`)}
              </div>
            )}
          </div>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentDate: state.currentDate,
});

const mapDispatchToProps = dispatch => ({
  setDate: (date: number) => {
    dispatch({
      type: 'SET_DATE',
      date,
    });
  },
  resetDate: (dateOffset: number) => {
    dispatch({
      type: 'RESET_DATE',
      dateOffset,
    });
  },
});

export default withTranslation('translations')(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DateMocking))
);
