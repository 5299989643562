import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SingleSelect from '../../basic/SingleSelect';
import { userIsParticipant } from '../../../lib/users';

interface BookerCancelProps {
  onCancelVisit: (shouldRemoveData: boolean) => void | Promise<void>;
  visitSiteName?: string;
  atHomeVisit?: boolean;
}

type BookerCancelOption = 'KEEP_CLINIC_ACCESS' | 'REMOVE_CLINIC_ACCESS';

function BookerCancel(props: BookerCancelProps) {
  const { t } = useTranslation('translations');

  const { visitSiteName = '', onCancelVisit, atHomeVisit = false } = props;
  const [
    dataAccessSelection,
    setDataAccessSelection,
  ] = useState<BookerCancelOption>('KEEP_CLINIC_ACCESS');

  const [xhrInProgress, setXhrInProgress] = useState(false);

  const onCancelClicked = useCallback(async () => {
    if (xhrInProgress) {
      return;
    }

    setXhrInProgress(true);
    await onCancelVisit(dataAccessSelection === 'REMOVE_CLINIC_ACCESS');
    setXhrInProgress(false);
  }, [onCancelVisit, dataAccessSelection, xhrInProgress]);

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>{t('bookerCancel.questionTitle')}</div>
      <div className='booker-text'>
        {userIsParticipant()
          ? t('bookerCancel.participantText')
          : t('bookerCancel.noParticipantText')}
      </div>
      {!atHomeVisit && (
        <SingleSelect
          options={
            userIsParticipant()
              ? [
                  {
                    text: t('bookerCancel.singleSelect.yesLabel', {
                      visitSiteName,
                    }),
                    value: 'KEEP_CLINIC_ACCESS',
                  },
                  {
                    text: t('bookerCancel.singleSelect.noLabel', {
                      visitSiteName,
                    }),
                    value: 'REMOVE_CLINIC_ACCESS',
                  },
                ]
              : [
                  {
                    text: t('bookerCancel.singleSelect.cancelLabel'),
                    value: 'KEEP_CLINIC_ACCESS',
                  },
                ]
          }
          selectedValue={dataAccessSelection}
          onChange={(v: BookerCancelOption) => setDataAccessSelection(v)}
        />
      )}
      <div className='booker-button-container'>
        <div className='booker-button' role='button' onClick={onCancelClicked}>
          {xhrInProgress
            ? t('common.loadingLabel')
            : t('bookerCancel.cancelRequestBtn')}
        </div>
      </div>
    </div>
  );
}

export default BookerCancel;
