export interface WebViewData {
  type: Required<
    | 'USER_LOGIN'
    | 'USER_LOGOUT'
    | 'FILE_DOWNLOAD'
    | 'ZENDESK_SHOW_CLICKED'
    | 'ZENDESK_HIDE_CLICKED'
    | 'DIALOG_OPENED'
    | 'DIALOG_CLOSED'
  >;
  data?: Record<string, any>;
}

export const sendEventsToMobile = (
  type: WebViewData['type'],
  data?: WebViewData['data']
) => {
  const reactNativeWebView = window['ReactNativeWebView'];
  if (reactNativeWebView) {
    reactNativeWebView.postMessage(JSON.stringify({ type, data }));
  }
};

export const onDialogOpen = (isDialogOpen): boolean => {
  if (isDialogOpen) {
    sendEventsToMobile('DIALOG_OPENED');
  }
  return isDialogOpen;
};

export const onDialogClose = (onClose: () => void) => {
  sendEventsToMobile('DIALOG_CLOSED');
  onClose();
};
