import * as _ from 'lodash';
import { Trial, Us_State } from '@curebase/core/types';
import i18n from '../../i18n';

const getMap = () => ({
  CREATED: i18n.t('status.created'),
  LONG_TERM_EXTENSION_1: i18n.t('status.longTermExtension'),
  LONG_TERM_EXTENSION_2: i18n.t('status.longTermExtension'),
  PASSED_RUN_IN_PERIOD: i18n.t('status.passedRunIn'),
  RUN_OUT_PERIOD: i18n.t('status.runOutPeriod'),
  WITHDRAWN: i18n.t('status.withdrawn'),
  ENROLLED: i18n.t('status.enrolled'),
  PASSED_PRESCREENING: i18n.t('status.passedPrescreening'),
  FINISHED_DETAILED_SCREENING: i18n.t('status.finishedDetailedScreening'),
  FINISHED_PREBASELINE: i18n.t('status.finishedPrebaseline'),
  OPTED_OUT: i18n.t('status.optedOut'),
  COMPLETED: i18n.t('status.completed'),
  FAILED_SCREENING: i18n.t('status.failedScreening'),
  CONSENTED: i18n.t('status.consented'),
  HOSPITALIZED: i18n.t('status.hospitalized'),
  NEEDS_CONSENT: i18n.t('status.needsConsent'),
  POSITIVE: i18n.t('status.positive'),
  NEEDS_ENROLLMENT: i18n.t('status.needsEnrollment'),
  PRESYMPTOMATIC: i18n.t('status.presymptomatic'),
  POST_HOSPITAL: i18n.t('status.postHospital'),
  PASSED_TERTIARY_SCREENING: i18n.t('status.passedTertiaryScreening'),
  TREATMENT_PERIOD: i18n.t('status.treatmentPeriod'),
  PASSED_SECONDARY_SCREENING: i18n.t('status.passedSecondaryScreening'),
  PHLEBOTOMY_CONFIRMATION: i18n.t('status.phlebotomyConfirmation'),
  IMEDIDATA_CONFIRMATION: i18n.t('status.imedidataConfirmation'),
  COLONOSCOPY: i18n.t('status.colonoscopy'),
  ELIGIBILITY_STATUS: i18n.t('status.eligibilityStatus'),
  PHLEBOTOMY_BLOOD_COLLECTION: i18n.t('status.phlebotomyBloodCollection'),
  ENROLLMENT: i18n.t('status.enrollment'),
  COLONOSCOPY_DATE: i18n.t('status.colonoscopyDate'),
  FOLLOW_UP_1: i18n.t('status.followUp1'),
  QUESTIONNAIRES: i18n.t('status.questionnaires'),
  IMEDIDATA_SUBJECT_NUMBER: i18n.t('status.imedidataSubjectNumber'),
  FOLLOW_UP_2: i18n.t('status.followUp2'),
  '15MIN_VRC_CALL': i18n.t('status.15minVrcCall'),
  SCHEDULE: i18n.t('status.schedule'),
  INCLUSION_EXCLUSION_CRITERIA: i18n.t('status.inclusionExclusionCriteria'),
  INFORMED_CONSENT: i18n.t('status.informedConsent'),
  MEDICAL_RELEASE_CONFIRMATION: i18n.t('status.medicalReleaseConfirmation'),
  STUDY_COMPLETION: i18n.t('status.studyCompletion'),
  INJECTIONS: i18n.t('status.injections'),
  RANDOMIZATION: i18n.t('status.randomization'),
  READY_TO_ENROLL: i18n.t('status.readyToEnroll'),
  FOLLOWUP: i18n.t('status.followup'),
  PASSED_SCREENING: i18n.t('status.passedScreening'),
});

export function getTrialOptionDisplayStatus(status: any): string {
  const TrialOptionStatusMap = getMap();
  return _.get(
    TrialOptionStatusMap,
    status,
    _.capitalize(status.replace(/_/g, ' '))
  );
}

const UsStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export function getAllStateCodes(): Us_State[] {
  // @ts-ignore
  return Object.keys(UsStates);
}

type UsStateDisplayName = any;
export function getUsStateDisplayName(value: Us_State): UsStateDisplayName {
  return UsStates[value];
}

export function getUserAllowedTrials(user, allPossibleTrials?): any[] {
  if (allPossibleTrials) {
    // If allPossibleTrials is present, select the trials that are
    // only included in both the allowedTrials and allPossibleTrials
    let inclusive: Array<Trial> = [];
    user?.additionalUserInfo?.allowedTrials.forEach(el => {
      const includedElement = allPossibleTrials.filter(
        all => all.id === el.id
      )[0];
      if (includedElement) {
        inclusive.push(includedElement);
      }
    });
    return inclusive;
  }
  return user?.additionalUserInfo?.allowedTrials;
}
