import { useState, useRef, useCallback, useEffect } from 'react';
import produce from 'immer';

//  useState alternative made to function like "setState" from class components with an optional callback
//  https://stackoverflow.com/a/61842546 -- how to implement useState with an optional callback

export function useImmutableState<T extends object>(
  initialState: T
): [Readonly<T>, (updates: Partial<T>, cb?: (prevState?: T) => any) => void] {
  const [state, setState] = useState(initialState);

  type callbackFN = (prevState?: T) => any;
  const callbackRef = useRef<callbackFN | null>(null);

  const updateState = useCallback(
    (updates: Partial<T>, cb?: callbackFN) => {
      //save the provided callback
      if (cb) {
        callbackRef.current = cb;
      }

      const nextState = produce(state, draft => {
        Object.keys(updates).forEach(key => {
          const updatedValue = updates[key];
          //mimic this.setState behavior
          if (updatedValue !== undefined) draft[key] = updates[key];
        });
      });
      setState(nextState);
    },
    [state]
  );

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  return [state, updateState];
}
