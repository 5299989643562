import produce from 'immer';
import {
  UserActionTypes,
  UserState,
  CLEAR_USER,
  FULLY_ADOPT_LEGAL,
  SAVE_TRACKING_DATA,
  DONT_ADOPT_LEGAL,
  SET_PIN_CODE,
  SET_USER,
  DELETE_SELECTED_TRIAL,
  SELECT_TRIAL,
  SET_GTM_CONTAINERS,
} from './types';

const initialState: UserState = {} as any;
const userReducer = produce((state: UserState, action: UserActionTypes) => {
  switch (action.type) {
    case CLEAR_USER:
      return initialState;
    case FULLY_ADOPT_LEGAL:
      state.fullLegalAdoption = true;
      break;
    case DONT_ADOPT_LEGAL:
      state.fullLegalAdoption = false;
      break;
    case SAVE_TRACKING_DATA:
      state.trackingData = {
        ...state.trackingData,
        ...action.data,
      };
      break;
    case SET_PIN_CODE:
      state.pinCode = action.pinCode;
      break;
    case SELECT_TRIAL:
      state.trialOptionId = action.payload.trialOptionId;
      break;
    case SET_GTM_CONTAINERS:
      state.gtmContainers = action.payload.gtmContainers;
      break;
    case DELETE_SELECTED_TRIAL:
      state.trialOptionId = undefined;
      break;
    case SET_USER:
      state.isStub = action.isStub;
      state.userId = action.userId;
      state.userType = action.userType;
      state.email = action.email;
      state.phoneNumber = action.phoneNumber;
      state.pinCode = action.pinCode;
      state.additionalUserInfo = action.additionalUserInfo;
      state.roles = action.additionalUserInfo
        ? action.additionalUserInfo.roles
        : action.roles;

      break;
  }
}, initialState);

export default userReducer;
