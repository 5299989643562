import React from 'react';
import SignatureView from './SignatureView';
import CapturedDataView from './CapturedDataView';
import KnowledgeContentView from './KnowledgeContentView';

interface Props {
  visit: any;
  caseReportInstance: any;
  knowledgeContent: any;
  refetch?: any;
  editable?: boolean;
  showAuditTrail?: boolean;
  withKnowledgeContent: boolean;
}

const CaseReportView = ({
  visit,
  knowledgeContent,
  caseReportInstance,
  editable,
  refetch,
  showAuditTrail,
  withKnowledgeContent,
}: Props) => {
  return (
    <>
      {withKnowledgeContent && (
        <KnowledgeContentView knowledgeContent={knowledgeContent} />
      )}

      <CapturedDataView
        visit={visit}
        caseReportInstance={caseReportInstance}
        editable={editable}
        refetch={refetch}
        showAuditTrail={showAuditTrail}
      />

      {caseReportInstance?.signature && (
        <SignatureView signature={caseReportInstance.signature} />
      )}
    </>
  );
};

export default CaseReportView;
