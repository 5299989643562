import React from 'react';
import {
  ApolloQueryResult,
  useQuery,
  OperationVariables,
} from '@apollo/client';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  RouteComponentProps,
} from 'react-router-dom';

import Loading from '../Loading';
import { DocumentNode } from 'graphql';

interface Refetch<V, Q> {
  readonly refetch: (variables?: V) => Promise<ApolloQueryResult<Q>>;
}

export interface InjectedProps<V, Q, Params extends Record<string, any> = {}>
  extends Refetch<V, Q>,
    RouteComponentProps<Params> {
  data: Q & Refetch<V, Q>;
  queryResult: Q & Refetch<V, Q>;
}

interface WithQueryResultOptions {
  noDismountOnRefetch: boolean;
}

// Props - OwnProps, Q - QueryResult, V - Variables, Params - match params expected
export function withQueryResult<
  V extends OperationVariables,
  Q,
  Props = {},
  Params extends Record<string, any> = {}
>(
  TargetComponent: React.ComponentType<InjectedProps<V, Q> & Props>,
  query: DocumentNode,
  propsToVariables?: (props: Props & RouteComponentProps<Params>) => V,
  options?: WithQueryResultOptions
): React.ComponentType<Props> {
  const WrappedComponent = (props: Props) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<Params>();
    const [firstLoadDone, setFirstLoad] = React.useState(false);

    const routerProps = { history, location, match };
    const preFetchProps = { ...routerProps, ...props };

    const { data, loading, error, refetch } = useQuery<Q, V>(query, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: propsToVariables ? propsToVariables(preFetchProps) : undefined,
      errorPolicy: 'all',
    });
    if (data && !loading && !firstLoadDone) setFirstLoad(true);

    const shouldNotDismount =
      firstLoadDone && options?.noDismountOnRefetch === true;

    if (error) console.error(error?.message, error?.stack);

    if (!data || (loading && !shouldNotDismount)) return <Loading />;

    return (
      <TargetComponent
        {...routerProps}
        {...props}
        refetch={refetch}
        data={{ ...data, refetch }}
        queryResult={{ ...data, refetch }}
      />
    );
  };

  return WrappedComponent;
}
