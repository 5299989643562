import { HOSTNAME_ENVIRONMENT } from '@curebase/core/lib/env';
// @TODO: move to back-end
import DeviceDetector from 'device-detector-js';
import { SEGMENT_KEY } from 'src/lib/env';
import GitCommit from '../../_git_commit';
import { getLocale } from '../../context/localeContext';
import { Identity } from './types';

export enum AnalyticsEventType {
  TRIAL_OPEN_STUDY = 'trial-open-study',
  TRIAL_OPEN_NEW_STUDY_FORM = 'trial-open-new-study-form',
  TRIAL_SUBMIT_NEW_STUDY_FORM = 'trial-submit-new-study-form',
  TRIAL_SEARCH = 'trial-search',
  TRIAL_IMPORT_OPEN_DIALOG = 'trial-import-open-dialog',
  TRIAL_IMPORT_CONFIRM = 'trial-import-confirm',
  PARTICIPANT_LIST_VIEW = 'participant-list-view',
  PARTICIPANT_LIST_PAGINATION = 'participant-list-pagination',
  PARTICIPANT_LIST_FILTER_STUDY = 'participant-list-filter-study',
  PARTICIPANT_LIST_FILTER_STATE = 'participant-list-filter-state',
  PARTICIPANT_LIST_FILTER_OPERATION = 'participant-list-filter-operation',
  PARTICIPANT_LIST_SEARCH_MATCH = 'participant-list-search-match',
  PARTICIPANT_LIST_OPEN_PROFILE = 'participant-list-open-profile',
  PARTICIPANT_LIST_CREATE_PARTICIPANT = 'participant-list-create-participant',
  PARTICIPANT_LIST_AUTOGENERATE_PARTICIPANT = 'participant-list-autogenerate-participant',
}

export enum AnalyticsEventProperty {
  // The name of the study
  STUDY_NAME = 'studyName',
  // A list of study names
  STUDY_NAME_LIST = 'studyNameList',
  PARTICIPANT_STATUS_LIST = 'participantStatusFilterList',
  PARTICIPANT_STATUS = 'participantStatus',
  OPERATIONS_FILTER_DESCRIPTION = 'operationsFilterDescription',
  NUMBER_PARTICIPANTS_VISIBLE = 'numberOfParticipantsFetched',
  PAGINATION_NAVIGATION_NEXT = 'paginationNextPage',
  PAGINATION_NAVIGATION_CURRENT = 'paginationCurrentPage',
  SEARCH_QUERY_CHANGED = 'updatedWithSearchQuery',
  PARTICIPANTS_AUTOGENERATED = 'numberOfParticipantsAutogenerated',
}

const getContext = () => {
  const deviceDetector = new DeviceDetector();
  const { client, device } = deviceDetector.parse(window.navigator.userAgent);
  const { country = 'us', language = 'en' } = getLocale() || {};
  const deviceInfo = {
    locale: `${language}-${country.toLocaleUpperCase()}`,
    app: {
      version: `${GitCommit.version} - #${GitCommit.sha?.substring(0, 6)}`,
    },
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
      density: window.devicePixelRatio,
    },
    platform: 'Web',
    os: {
      name: client?.name,
      version: client?.version,
    },
    device: {
      type: device?.type,
      brand: device?.brand,
      model: device?.model,
    },
  };
  return deviceInfo;
};

class Analytics {
  static load() {
    // @ts-ignore
    window.analytics._writeKey = SEGMENT_KEY;
    // @ts-ignore
    window.analytics.load?.(SEGMENT_KEY);
  }

  static async identify(identity: Identity) {
    const context = getContext();
    window.analytics.identify(
      identity.uuid,
      {
        environment: HOSTNAME_ENVIRONMENT,
        is_participant: identity.is_participant,
        is_internal_user: identity.is_internal_user,
        account_creation_date: identity.account_creation_date,
        roles: identity.roles,
        screen: context.screen,
      },
      {
        context,
      }
    );
  }

  static async page() {
    const context = getContext();
    // @ts-ignore
    window.analytics.page(
      // @ts-ignore
      {},
      {
        context,
      }
    );
  }

  static async track(eventName: AnalyticsEventType, additionalProperties = {}) {
    const context = getContext();
    // @ts-ignore
    window.analytics.track(
      // @ts-ignore
      eventName,
      {
        context,
        ...additionalProperties,
      }
    );
  }
}

export default Analytics;
