import isAutoComplete from '../helpers/isAutoComplete';

export default function evaluateValue(
  answerIsAnArray: boolean,
  valueToTest: any,
  value: string
): boolean {
  if (answerIsAnArray) {
    // MULTICHECKBOX gives an array back
    if (!valueToTest.includes(value)) {
      return false;
    }
  } else if (isAutoComplete(valueToTest)) {
    const compound = [
      ...(valueToTest.predefinedValues ?? []),
      ...(valueToTest.freeResponse ?? []),
    ];
    if (!compound.includes(value)) {
      return false;
    }
  } else if (valueToTest !== value) {
    return false;
  }
  return true;
}
