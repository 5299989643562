import * as React from 'react';
import { Player } from 'video-react';
import { Us_State } from '@curebase/core/types';
import { secureVideoUriFromFileName } from '@curebase/core/lib/env';
import Bullets from './Bullets';
import { mdToHTML } from '@curebase/core/lib/markdown';
import 'video-react/dist/video-react.css';
import { TFunction, withTranslation } from 'react-i18next';

type LinkProps = {
  label: string;
  uri: string;
};

type SectionProps = {
  title: string;
  videoPath?: string | null;
  limitToStates?: Us_State[] | null;
  bullets?: string[] | null;
  text?: string | null;
  links?: LinkProps[] | null;
};

type Props = {
  sections: SectionProps[];
  variables?: Object;
  t: TFunction;
};

type State = {};

class KnowledgeContent extends React.Component<Props, State> {
  render() {
    const sections = this.props.sections;
    return (
      <div className='knowledge-content-container'>
        {sections.map((section, i) => (
          <div key={i} className='knowledge-content-section'>
            <div className='knowledge-content-section-title'>
              {section.title}
            </div>
            {section.videoPath && this.renderVideo(section.videoPath)}
            {section.text && this.renderText(section.text)}
            {section.bullets && this.renderBullets(section.bullets)}
            {section.links && this.renderLinks(section.links)}
          </div>
        ))}
      </div>
    );
  }

  renderVideo(videoPath: string) {
    return (
      <div className='knowledge-content-video'>
        <Player>
          <source src={secureVideoUriFromFileName(videoPath)} />
        </Player>
      </div>
    );
  }

  renderText(text: string) {
    return (
      <div
        className='knowledge-content-text'
        dangerouslySetInnerHTML={{
          __html: mdToHTML(text),
        }}
      />
    );
  }

  renderBullets(bullets: string[]) {
    return (
      <div className='knowledge-content-bullets'>
        <Bullets items={bullets} />
      </div>
    );
  }

  renderLinks(links: LinkProps[]) {
    const { t } = this.props;

    return (
      <div className='knowledge-content-link-section'>
        {t('knowledgeContent.linkSectionText')}
        {links.map((link, i) => (
          <div key={i} className='knowledge-content-link'>
            <a
              href={link.uri}
              className='knowledge-content-link-a'
              target='_blank'
              rel='noopener noreferrer'
            >
              {link.label}
            </a>
          </div>
        ))}
      </div>
    );
  }
}

export default withTranslation('translations')(KnowledgeContent);
