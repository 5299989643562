import React, { useState } from 'react';
import { merge } from 'lodash';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TRIAL_SUMMARY_TEMPLATE } from './TrialBuilder/types';
import { getUser } from '../store';
import { hideMenuDrawer, showMenuDrawer } from '../store/actions/index';
import { inPatientMode } from '../lib/patientMode';
import { getIsMenuOpen, getPatientModeSelector } from '../store/selectors';
import TestModeIndicator from './basic/TestModeIndicator';
import { Drawer } from '@material-ui/core';
import LeftNavbar from './LeftNavbar';
import { StudyCustomStyle } from '@curebase/core/types/customStyle';
import { getUserStyles } from '../store/user/selectors';
import { createPageHeaderStyle } from '../lib/customStyle';
import PageBrand from './PageBrand';
import DownloadAppModal from './ParticipantInterface/DownloadApp/DownloadAppModal';

type Props = {
  trialName?: string;
  shortDescription?: string;
  customStyle?: StudyCustomStyle;
};

const PageHeader = ({ trialName, shortDescription, customStyle }: Props) => {
  const history = useHistory();
  const user = getUser();
  const [downloadAppModalIsOpen, setDownloadAppModalIsOpen] = useState(false);
  const patientMode: any = useSelector(getPatientModeSelector);
  const isMenuOpen: any = useSelector(getIsMenuOpen);
  const userCustomStyle = useSelector(getUserStyles);
  const style = merge({}, userCustomStyle || {}, customStyle || {});
  const classes = createPageHeaderStyle(style);

  if (!user) return null;

  const isPatientMode = inPatientMode(patientMode.state);

  return (
    <div className={`page-header no-print ${classes.header}`}>
      <div className='ph-left'>
        <div className='ph-left-line-1'>
          <PageBrand
            onClick={() => history.push('/u')}
            customLogo={style?.logo?.default}
          />
          <TestModeIndicator />
        </div>
      </div>
      <div className='ph-right'>
        {trialName && (
          <div className='ph-right-trial-info'>
            <div className={`trial-name ${classes.headerText}`}>
              {trialName}
            </div>
            {shortDescription &&
              shortDescription !==
                TRIAL_SUMMARY_TEMPLATE.content.data.shortDescription && (
                <div className={`short-description ${classes.headerText}`}>
                  {shortDescription}
                </div>
              )}
          </div>
        )}
        {isPatientMode && (
          <div className='patient-mode-indicator-container'>
            <div className='patient-mode-indicator-inner-container'>
              Patient Mode
            </div>
          </div>
        )}
        {!isPatientMode && (
          <>
            <div
              className='ph-right-menu-icon-container'
              onClick={() => showMenuDrawer()}
            >
              <SVG src='/icons/burger.svg' className={classes.menu} />
            </div>
            <Drawer
              open={isMenuOpen}
              onClose={() => hideMenuDrawer()}
              anchor='right'
              style={{ zIndex: 999999 }}
              classes={{
                paperAnchorRight: 'navbar-drawer-anchor-right',
              }}
            >
              <LeftNavbar
                onClickToDownloadApp={() => {
                  hideMenuDrawer();
                  setDownloadAppModalIsOpen(true);
                }}
              />
            </Drawer>
            <DownloadAppModal
              open={downloadAppModalIsOpen}
              onClose={() => setDownloadAppModalIsOpen(false)}
              dontMarkActivityAsDone
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
