import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onCancelRequest: () => void | Promise<void>;
};

function BookerCancelRequest({ onCancelRequest }: Props) {
  const { t } = useTranslation('translations');

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>{t('bookerCancelRequest.title')}</div>
      <div className='booker-text'>{t('bookerCancelRequest.text')}</div>
      <div className='booker-button-container'>
        <div
          className='booker-button'
          role='button'
          onClick={() => onCancelRequest()}
        >
          {t('bookerCancelRequest.cancelBtn')}
        </div>
      </div>
    </div>
  );
}

export default BookerCancelRequest;
