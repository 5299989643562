import moment, { Moment } from 'moment-timezone';
import produce, { Draft } from 'immer';
import { VisitAvailability, VisitBooking } from '../types';

type VisitAvailabilityGenerated = VisitAvailability & {
  originalStart: number;
  originalEnd: number;
};

export const rruleToHumanReadableString = (
  rrule: { byDay: string[] },
  originalEnd: number,
  getMoment?: any
) => {
  const endDate = getMoment
    ? getMoment(originalEnd).format('MMMM Do, YYYY')
    : moment(originalEnd).format('MMMM Do, YYYY');
  return `Repeating on ${rrule?.byDay?.join(', ')} until ${endDate}`;
};

export type VisitAvailabilityMixed =
  | VisitAvailability
  | VisitAvailabilityGenerated;

type UnixTimestampSlot = { start: number; end: number };

const bookingFilterFunc = ({ start, end }: UnixTimestampSlot) => ({
  start: s,
  end: e,
}: UnixTimestampSlot) => s >= start && s < end && e > start && e <= end;

//takes in an VisitAvailability with rrule and returns an array of windows generated from the rrule between the dates given
export const parseRecurringAvailabilityForInterval = (
  window: VisitAvailability,
  startDate: Moment,
  endDate: Moment
): (VisitAvailabilityMixed | null | undefined)[] => {
  //if no rrule, return copy of window with filtered bookings
  if (!window.rrule?.byDay) {
    return [
      produce(window, draft => {
        if (!draft.visitBookings) {
          draft.visitBookings = [];
          return;
        }
        const comparisonFunc = bookingFilterFunc({
          start: new Date(draft.start).valueOf(),
          end: new Date(draft.end).valueOf(),
        });
        draft.visitBookings = draft.visitBookings.filter(vb =>
          comparisonFunc({
            start: new Date(vb.start).valueOf(),
            end: new Date(vb.end).valueOf(),
          })
        );
      }),
    ];
  }

  const timezone = startDate.tz() as string; //grab the relevant timezone
  const {
    rrule: { byDay },
    start: windowStart,
    end: windowEnd,
  } = window;

  //this spans the entire range the window is active for
  const windowStartMoment = moment.tz(windowStart, timezone);
  const windowEndMoment = moment.tz(windowEnd, timezone);

  //start time and end
  const startTime = {
    hours: windowStartMoment.hours(),
    minutes: windowStartMoment.minutes(),
    seconds: 0,
    milliseconds: 0,
  };
  const endTime = {
    hour: windowEndMoment.hours(),
    minutes: windowEndMoment.minutes(),
    seconds: 0,
    milliseconds: 0,
  };

  //to fix off-by-one bugs on first/last weeks
  const paddedStart = startDate.clone().subtract(1, 'week');
  const paddedEnd = endDate.clone().add(1, 'week');

  //start the counter with the latest of window or request time period
  let counter: Moment;
  if (windowStartMoment.isSameOrAfter(paddedStart))
    counter = windowStartMoment.clone();
  else counter = paddedStart.clone();

  if (window.visitBookings) {
    // @ts-ignore
    window.visitBookings.sort(
      // @ts-ignore
      (a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf()
    );
  }

  let windows: any[] = [];
  while (counter.isBefore(paddedEnd)) {
    const instances =
      byDay?.map(dayOfWeek => {
        const start = counter.clone().day(dayOfWeek).set(startTime);
        const end = counter.clone().day(dayOfWeek).set(endTime);
        return produce(window, (draft: Draft<any>) => {
          draft.originalStart = draft.start;
          draft.originalEnd = draft.end;
          draft.start = start.valueOf();
          draft.end = end.valueOf();

          if (!draft.visitBookings) {
            draft.visitBookings = [];
            return;
          }

          const filteringFunction = bookingFilterFunc(draft);
          const visitBookings: VisitBooking[] = [];

          for (let i = 0; i < draft.visitBookings.length; i++) {
            const visitBooking = {
              ...draft.visitBookings[i],
              start: new Date(draft.visitBookings[i].start).valueOf(),
              end: new Date(draft.visitBookings[i].end).valueOf(),
            };
            if (visitBooking.start < draft.start) continue;

            if (filteringFunction(visitBooking)) {
              visitBookings.push(visitBooking);
            } else {
              if (visitBooking.start > draft.end) {
                break;
              }
            }
          }

          draft.visitBookings = visitBookings;
        });
      }) ?? [];
    windows = windows.concat(instances);
    counter.add(1, 'week');
  }

  windows = windows.filter(
    ({ start, end }) =>
      moment(start).isBetween(
        windowStartMoment,
        windowEndMoment,
        undefined,
        '[)'
      ) &&
      moment(end).isBetween(
        windowStartMoment,
        windowEndMoment,
        undefined,
        '(]'
      ) &&
      moment(start).isBetween(startDate, endDate, 'day', '[)') &&
      moment(end).isBetween(startDate, endDate, undefined, '(]')
  );
  return windows;
};
