import {
  CancelAcuitySchedulingRequest,
  CancelAcuitySchedulingResponse,
  CancelBookingRequestRequest,
  CancelBookingRequestResponse,
  CancelVisitRequest,
  CancelVisitResponse,
  ScheduleVisitRequest,
  ScheduleVisitResponse,
  SendBookingRequestMessageRequest,
  SendBookingRequestMessageResponse,
} from '@curebase/modules/scheduling/dto/bookings';

import { VisitMethod } from '@curebase/core/types';
import { jsonPost } from '../lib/fetchHelpers';

export async function sendBookingRequestMessage(
  trialOptionId: number,
  visitSiteId: number,
  method: VisitMethod
): Promise<SendBookingRequestMessageResponse> {
  const requestBody: SendBookingRequestMessageRequest = {
    trialOptionId,
    visitSiteId,
    method,
  };
  const response: SendBookingRequestMessageResponse = await jsonPost(
    '/booking/sendbookingrequestmessage',
    requestBody
  );
  return response;
}

export async function cancelBookingRequest(
  trialOptionId: number
): Promise<CancelBookingRequestResponse> {
  const requestBody: CancelBookingRequestRequest = {
    trialOptionId,
  };
  const response: CancelBookingRequestResponse = await jsonPost(
    '/booking/cancelbookingrequest',
    requestBody
  );
  return response;
}

export async function scheduleVisit(
  trialOptionId: number,
  baselineVisitStartDate: string,
  visitSiteId: number | null,
  method: VisitMethod,
  schedulingConfigurationId: string,
  visitAvailabilityId?: number,
  spectatorId?: number
): Promise<ScheduleVisitResponse> {
  const requestBody: ScheduleVisitRequest = {
    trialOptionId,
    baselineVisitStartDate,
    visitSiteId,
    method,
    visitAvailabilityId,
    schedulingConfigurationId,
    spectatorId,
  };
  const response: ScheduleVisitResponse = await jsonPost(
    '/booking/schedulevisit',
    requestBody
  );
  return response;
}

export async function scheduleAxleVisit(
  trialOptionId: number,
  visit_datetime: string,
  schedulingConfigurationId: string
): Promise<{ success: boolean }> {
  return await jsonPost('/booking/scheduleAxleVisit', {
    trialOptionId,
    visit_datetime,
    schedulingConfigurationId,
  });
}

export async function cancelVisit(
  trialOptionId: number,
  schedulingConfigurationId: string,
  removeFromClinic: boolean
): Promise<CancelVisitResponse> {
  const requestBody: CancelVisitRequest = {
    trialOptionId,
    schedulingConfigurationId,
    removeFromClinic,
  };

  const response: CancelVisitResponse = await jsonPost(
    '/booking/cancelvisit',
    requestBody
  );
  return response;
}

export async function cancelAcuityScheduling(
  trialOptionId: number,
  acuityAppointmentId: number
): Promise<CancelAcuitySchedulingResponse> {
  const requestBody: CancelAcuitySchedulingRequest = {
    acuityAppointmentId,
    trialOptionId,
  };

  const response: CancelAcuitySchedulingResponse = await jsonPost(
    '/booking/cancelacuityscheduling',
    requestBody
  );
  return response;
}
