import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { mdToHTML } from '@curebase/core/lib/markdown';

type Props = {
  title: string;
  subtitle?: string;
  body?: string;
  button?: any;
  shouldWarnOnClose?: () => string | null;
  onClose?: () => any;
  classes: any;
  hideCloseButton?: boolean;
};

const styles = theme => ({
  materialIcon: {
    width: 28,
    height: 28,
  },
});

class DialogHeader extends React.Component<Props, {}> {
  close = () => {
    const { shouldWarnOnClose, onClose } = this.props;
    if (!onClose) return;
    if (!shouldWarnOnClose) {
      onClose();
      return;
    }

    const warningMessage = shouldWarnOnClose();
    if (!warningMessage) {
      onClose();
      return;
    }

    if (warningMessage && window.confirm(warningMessage)) {
      onClose();
    }
  };

  render() {
    const { title, subtitle, button, body } = this.props;
    return (
      <div className='custom-dialog-header'>
        <div className='custom-dialog-header-left'>
          <div className='custom-dialog-header-title'>{title}</div>
          {subtitle && (
            <div
              className='custom-dialog-header-subtitle'
              dangerouslySetInnerHTML={{
                __html: mdToHTML(subtitle),
              }}
            />
          )}
          {button && (
            <div className='custom-dialog-header-button'>{button}</div>
          )}
          {body && (
            <div
              className='custom-dialog-header-subtitle'
              dangerouslySetInnerHTML={{
                __html: mdToHTML(body),
              }}
            />
          )}
        </div>
        {!this.props.hideCloseButton && (
          <div className='custom-dialog-header-right'>
            <div
              className='custom-dialog-close-button-container'
              onClick={this.close}
            >
              {/* @ts-ignore */}
              <CloseIcon
                nativecolor='white'
                className={this.props.classes.materialIcon}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DialogHeader);
