import { jsonPost } from '../lib/fetchHelpers';
import {
  CreateNewTrialRequest,
  CreateNewTrialResponse,
  DeployTrialResponse,
  ForkConfigRequest,
  ForkConfigResponse,
  ImportTrialIntoEnvironmentRequest,
  ImportTrialIntoEnvironmentResponse,
  S3TrialListResponse,
  SaveConfigRequest,
  SaveConfigResponse,
  StateMachineComparisonRequest,
} from '@curebase/core/decoders/trialbuilder';
import { TrialConfigurationFork } from '@curebase/core/types';
import { generateTrialConfig } from '../lib/trialBuilderHelpers';

export async function approveTrialConfig(
  trialIdentifier: string,
  version: string,
  note: string | null = null
): Promise<any> {
  const requestBody = {
    trialIdentifier,
    version,
    note,
  };
  const response: any = await jsonPost('/trialbuilder/approve', requestBody);
  if (response.error)
    throw new Error('Error while attempting to approve a trial config');
  else return response;
}

export async function exportTrial(
  trialSlug: string,
  configName: string
): Promise<DeployTrialResponse> {
  if (!trialSlug || !configName)
    throw new Error('Cannot deploy trial. Missing parameters');
  const requestBody = {
    trialSlug,
    configName,
  };
  return jsonPost('/trialbuilder/export', requestBody);
}

export async function initTrial(
  trialName: string
): Promise<ImportTrialIntoEnvironmentResponse> {
  const requestBody: ImportTrialIntoEnvironmentRequest = {
    trialName,
  };
  return await jsonPost('/trialbuilder/init', requestBody);
}

export async function newTrial(
  trialName: string,
  sponsorName: string
): Promise<CreateNewTrialResponse> {
  const requestBody: CreateNewTrialRequest = {
    trialName,
    sponsorName,
    config: generateTrialConfig(trialName, sponsorName),
  };
  return await jsonPost('/trialbuilder/new', requestBody);
}

export async function getTrialsFromS3(): Promise<S3TrialListResponse> {
  return await jsonPost('/trialbuilder/triallist', {});
}

export async function forkConfig(
  trialIdentifier: string,
  version: string,
  description: string,
  type: TrialConfigurationFork
): Promise<ForkConfigResponse> {
  const body: ForkConfigRequest = {
    description,
    trialIdentifier,
    type,
    version,
  };
  return await jsonPost('/trialbuilder/fork', body);
}

export async function saveConfig(
  version: string,
  config: any
): Promise<SaveConfigResponse> {
  const body: SaveConfigRequest = {
    config,
    name: version,
  };
  return await jsonPost('/trialbuilder/save', body);
}

export async function previousConfigVersion(
  trialIdentifier: any,
  version: string
): Promise<any> {
  const body = {
    trialIdentifier,
    version,
  };
  return await jsonPost('/trialbuilder/previousconfigversion', body);
}

export async function generateCasebook(
  trialIdentifier: string,
  version: string
): Promise<any> {
  const requestBody = {
    trialIdentifier,
    version,
  };
  const response: any = await jsonPost(
    '/trialbuilder/generate-casebook',
    requestBody
  );
  if (response.error)
    throw new Error(
      'Error while attempting to queue the generation of a casebook'
    );
  else return response;
}

export const validateStateMachines = (
  stateMachines: StateMachineComparisonRequest
): Promise<string[]> =>
  jsonPost('/trialbuilder/validate', stateMachines)
    .then(response => response as string[])
    .catch(() => ['API Error']);
