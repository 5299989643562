import { Button } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListItem from 'src/components/basic/ListItem';
import { getLocale } from 'src/context/localeContext';
import usePermission from 'src/hooks/usePermission';
import { getViewPatientBaseUrl } from 'src/lib/users';
import { RolePermissions, useAttendVisitListItemQuery } from 'src/types';

interface AttendVisitListItemProps {
  trialOptionId: number;
  schedulingConfigurationId: string;
}

function AttendVisitListItem(props: AttendVisitListItemProps) {
  const { t } = useTranslation('translations');
  const { locale } = getLocale();
  const { trialOptionId, schedulingConfigurationId } = props;
  const { data, refetch } = useAttendVisitListItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      trialOptionId,
      schedulingConfigurationId,
    },
  });
  const history = useHistory();
  const canModifyAxleVisit = usePermission(RolePermissions.EditAxleScheduling);
  const hasPermissionToSchedule = usePermission(RolePermissions.ScheduleVisit);

  const visitBooking = data?.getTrialOption.visitBooking;

  useEffect(() => {
    const handleDialogClosed = (e: CustomEvent) => {
      refetch && refetch();
    };
    window.addEventListener('booker-dialog-closed', handleDialogClosed, false);
    return () => {
      window.removeEventListener('booker-dialog-closed', handleDialogClosed);
    };
  }, [refetch]);

  if (!visitBooking) {
    return null;
  }

  const canCancelOrReschedule =
    hasPermissionToSchedule && //needs permission
    (!visitBooking.axleHealthId || //needs to either be non-Axle or if Axle with conditions
      (visitBooking.axleHealthId &&
        canModifyAxleVisit &&
        DateTime.fromISO(visitBooking.start).diff(DateTime.now(), ['hours'])
          .hours >= 24));

  const schedulingConfigName =
    visitBooking.schedulingConfiguration?.locales?.[locale]?.name ??
    visitBooking.schedulingConfiguration?.name;

  return (
    <ListItem
      status={{
        type: 'COMPLETED',
      }}
      middle={{
        title: `${t(
          'attendVisitListItem.titlePrefix'
        )}: ${schedulingConfigName}`,
        text: `${t('attendVisitListItem.textPrefix')} ${DateTime.fromISO(
          visitBooking.start
        )
          .setZone(data?.getTrialOption.patient?.user.safeTimezone ?? 'system')
          .toFormat('fff', { locale })}`,
        buttons: canCancelOrReschedule
          ? [
              <Button
                key='cancel'
                color='primary'
                onClick={() =>
                  history.push(
                    `${getViewPatientBaseUrl(
                      trialOptionId
                    )}/${schedulingConfigurationId}/booker/cancel`
                  )
                }
              >
                {t('common.cancel')}
              </Button>,
              <Button
                key='reschedule'
                color='primary'
                onClick={() =>
                  history.push(
                    `${getViewPatientBaseUrl(
                      trialOptionId
                    )}/${schedulingConfigurationId}/booker/reschedule`
                  )
                }
              >
                {t('common.reschedule')}
              </Button>,
            ]
          : undefined,
      }}
    />
  );
}

export default AttendVisitListItem;
