import * as React from 'react';

import { capitalizeDisplayName } from '../../../lib/ui';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

type OpenQuerySectionProps = {
  query: {
    initialMessage: string;
    createdAt: string;
    submittedByUser: {
      displayName: string;
    };
  };
};

const OpenQuerySection = (props: OpenQuerySectionProps) => {
  const { t } = useTranslation('translations');
  const { query } = props;

  return (
    <React.Fragment>
      <div className='fr-query-message-header'>
        {capitalizeDisplayName(query.submittedByUser, t)} (
        {moment(query.createdAt).fromNow()})
      </div>
      <div className='fr-query-message-content'>
        <em>{query.initialMessage}</em>
      </div>
    </React.Fragment>
  );
};

export default OpenQuerySection;
