import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import { useHistory } from 'react-router';
import {
  AttachMoneyRounded,
  Code as CodeIcon,
  EmailRounded,
  EventRounded,
  Forward as ForwardIcon,
  HelpRounded,
  HomeRounded,
  InvertColorsRounded,
  LocationOnRounded,
  PhoneRounded,
  WatchLaterRounded,
  WatchRounded,
} from '@material-ui/icons';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import EventIcon from '@material-ui/icons/Event';
import { colorFromText } from '../../shared/lib/colors';
import { IS_PRODUCTION } from '@curebase/core/lib/env';
import { currentUserHasPermission } from '../../lib/auth';
import {
  Pii_Status,
  RolePermissions,
  TrialOption,
  VisitMethod,
} from '@curebase/core/types';
import {
  productionZendeskBaseURL,
  sandboxZendeskBaseURL,
} from '@curebase/core/lib/constants';
import { isPayerPaypal } from '@curebase/core/lib/payments';
import { Button } from '@material-ui/core';
import {
  JoinStatus,
  useTelehealthMeetingInfoQuery,
  VisitBooking,
} from 'src/types';
import { getViewPatientBaseUrl } from 'src/lib/users';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'src/context/localeContext';
import { EpochStatusTag } from './EpochStatusTag';
import Sticky from 'react-sticky-el';

type Props = {
  participant: any;
  accountDisabledOn?: boolean | null;
};

type BaseTagProps = {
  style?: Object;
  text: string;
  classes?: string[];
};

const JoinTelehealthCallButtons = (props: {
  meetingUUID: string;
  onReschedule: () => void;
}) => {
  const { meetingUUID } = props;
  const history = useHistory();
  const { t } = useTranslation('translations');

  const { data, loading } = useTelehealthMeetingInfoQuery({
    fetchPolicy: 'network-only',
    skip: !meetingUUID,
    variables: {
      meetingUUID,
    },
  });

  if (data?.getTelehealthMeetingInfo && !loading) {
    const {
      meetingJoinStatus: joinStatus,
      telemedInfo,
    } = data.getTelehealthMeetingInfo;
    const { telemedMeetingCanBeJoinedEarly } = telemedInfo ?? {};
    if (
      (joinStatus === JoinStatus.TooEarly && !telemedMeetingCanBeJoinedEarly) ||
      joinStatus === JoinStatus.Late
    ) {
      return <div />;
    } else {
      let config;
      if (
        joinStatus === JoinStatus.TooEarly &&
        telemedMeetingCanBeJoinedEarly
      ) {
        config = {
          color: 'grey',
          text: t('participantTags.joinTelehealth.opens30MinText'),
          disabled: true,
        };
      } else if (joinStatus === JoinStatus.JoinEarly) {
        config = {
          color: '#2396f3',
          text: t('participantTags.joinTelehealth.joinEarlyText'),
          disabled: false,
        };
      } else {
        config = {
          color: '#2396f3',
          text: t('participantTags.joinTelehealth.joinCallText'),
          disabled: false,
        };
      }

      return (
        <>
          <Button
            style={{ color: config.color }}
            onClick={() => {
              history.push(`/u/telemed/${meetingUUID}?autoJoin=true`);
            }}
            disabled={config.disabled}
          >
            {[JoinStatus.InTime, JoinStatus.JoinEarly].includes(joinStatus) && (
              <VoiceChatIcon />
            )}
            {config.text}
          </Button>
          <Button style={{ color: '#2396f3' }} onClick={props.onReschedule}>
            <EventIcon />
            {t('participantTags.joinTelehealth.rescheduleBtn')}
          </Button>
          <Button
            style={{ color: '#2396f3' }}
            onClick={() => {
              history.push(`/u/telemed/${meetingUUID}`);
            }}
          >
            {t('participantTags.joinTelehealth.detailsBtn')}
          </Button>
        </>
      );
    }
  } else {
    return <div />;
  }
};

export const BaseTag = (props: BaseTagProps) => (
  <span
    className={`participant-tag ${
      props.classes ? props.classes.join(' ') : ''
    }`}
    style={props.style}
  >
    {props.text}
  </span>
);

export function ParticipationTag(props: Props) {
  const { t } = useTranslation('translations');
  const { participant, accountDisabledOn } = props;
  const tagColor = participant ? colorFromText(participant.trial.name) : null;

  return (
    <>
      <BaseTag
        style={{ background: tagColor }}
        text={`${participant.trial.name} (${participant.subjectIdentifier})`}
      />
      {participant.trial.deactivated && (
        <BaseTag
          style={{ background: '#e8998f' }}
          text={t('participantTags.participationTag.trialDisabledText')}
        />
      )}
      {accountDisabledOn && (
        <BaseTag
          style={{ background: '#ce9a8f' }}
          text={t('participantTags.participationTag.participantDisabledText')}
        />
      )}
    </>
  );
}

export const ParticipantInfo = (props: {
  trialOption: TrialOption;
  clickable?: boolean;
  sticky?: boolean;
}) => {
  const { locale } = getLocale();
  const { t } = useTranslation('translations');

  const {
    id,
    closestVisitSite,
    devices,
    patient,
    piiStatus,
    referralCode,
    subjectIdentifier,
    utmCode,
    visitBookings,
    visitSite,
  } = props.trialOption;

  const {
    phoneNumber,
    email,
    displayName,
    zendeskUserId,
    timeZone,
    referralClinic,
    accountDisabledOn,
  } = patient?.user ?? {};

  const history = useHistory();
  const { zipcode } = patient ?? {};

  // @TODO: move to env variables?
  const zendeskBaseUrl = IS_PRODUCTION
    ? `https://${productionZendeskBaseURL}`
    : `https://${sandboxZendeskBaseURL}`;

  const canSeePII = piiStatus === Pii_Status.Enabled;

  const friendlyDisabledPiiStatus = ((): string => {
    if (piiStatus === Pii_Status.DisabledLostToFollowUp)
      return t('participantTags.participantInfo.piiStatus.lostToFollowUp');

    if (piiStatus === Pii_Status.DisabledDeIdentifiedPatient)
      return t('participantTags.participantInfo.piiStatus.deIdentifiedPatient');

    return t('participantTags.participantInfo.piiStatus.deceased');
  })();

  const StickyWrapper = ({ children }) =>
    props?.sticky ? (
      <Sticky dontUpdateHolderHeightWhenSticky={true}>{children}</Sticky>
    ) : (
      <>{children}</>
    );

  return (
    <>
      <StickyWrapper>
        <div className='trial-row'>
          <div className='non-arrow'>
            <div
              className={`participant-name ${
                props.clickable ? 'clickable' : ''
              }`}
            >
              {displayName ? displayName : subjectIdentifier}
            </div>
            <div className='participant-trial-container'>
              <ParticipationTag
                participant={props.trialOption}
                accountDisabledOn={accountDisabledOn ? true : false}
              />
              <EpochStatusTag trialOption={props.trialOption} />
            </div>
          </div>

          {props.clickable && (
            <div className='img-container small'>
              <img src='/icons/right-arrow.svg' alt='arrow' />
            </div>
          )}
        </div>
      </StickyWrapper>

      <div className='identifier-row'>
        {!canSeePII && (
          <div className='participant-identifier'>
            <InvertColorsRounded />
            {t('participantTags.participantInfo.contactStatusLabel')}{' '}
            {friendlyDisabledPiiStatus}
          </div>
        )}

        {canSeePII && phoneNumber && (
          <div className='participant-identifier'>
            <PhoneRounded />
            {formatPhoneNumberIntl(phoneNumber)}
          </div>
        )}

        {canSeePII && email && (
          <div className='participant-identifier'>
            <EmailRounded />
            {email}
          </div>
        )}

        {visitSite && (
          <div className='participant-identifier'>
            <HomeRounded />
            {visitSite.name}
          </div>
        )}

        {isPayerPaypal(patient?.user) && (
          <div className='participant-identifier'>
            <AttachMoneyRounded />
            <span>
              {t('participantTags.participantInfo.paypalConnectedLabel')}
            </span>
          </div>
        )}

        {zendeskUserId &&
          currentUserHasPermission(RolePermissions.ViewZendeskProfile) && (
            <div className='participant-identifier'>
              <HelpRounded />
              <a
                href={`${zendeskBaseUrl}/agent/users/${zendeskUserId}/requested_tickets`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('participantTags.participantInfo.zendeskProfileLabel')}
              </a>
            </div>
          )}

        {zipcode &&
          currentUserHasPermission(RolePermissions.ViewParticipantLocation) && (
            <div className='participant-identifier'>
              <LocationOnRounded />
              <a
                href={`https://google.com/maps?q=${zipcode}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {zipcode}
              </a>
            </div>
          )}

        {timeZone &&
          currentUserHasPermission(RolePermissions.ViewParticipantLocation) && (
            <div className='participant-identifier'>
              <WatchLaterRounded />
              {t('participantTags.participantInfo.timezoneLabel')} {timeZone}
            </div>
          )}

        {closestVisitSite &&
          currentUserHasPermission(RolePermissions.ViewParticipantLocation) && (
            <div className='participant-identifier'>
              <LocationOnRounded />
              {t('participantTags.participantInfo.closestSiteLabel')}{' '}
              {closestVisitSite.name}
            </div>
          )}

        {referralCode &&
          currentUserHasPermission(
            RolePermissions.ViewParticipantReferralSource
          ) && (
            <div className='participant-identifier'>
              <ForwardIcon />
              {t('participantTags.participantInfo.referredByLabel')}{' '}
              {referralClinic ? referralClinic.name : referralCode}
            </div>
          )}

        {utmCode && currentUserHasPermission(RolePermissions.ViewUtmCodes) && (
          <div className='participant-identifier'>
            <CodeIcon />
            {t('participantTags.participantInfo.utmCodeLabel')} {utmCode}
          </div>
        )}

        {((visitBookings as VisitBooking[]) || [])
          .sort(
            (a, b) =>
              DateTime.fromISO(a.start).valueOf() -
              DateTime.fromISO(b.start).valueOf()
          )
          .map((vb, index) => {
            const { method, meetingUUID } = vb;
            let visitMethod;
            if (method === VisitMethod.InPerson)
              visitMethod = t('participantTags.visit.onsite');
            if (method === VisitMethod.Virtual)
              visitMethod = t('participantTags.visit.virtual');
            const visitDate = DateTime.fromISO(vb.start);
            const time = visitDate.toFormat('fff', { locale });

            return (
              <div key={index} className='participant-identifier'>
                <EventRounded />
                {`${
                  vb.schedulingConfiguration?.name
                } ${t('participantTags.visit.calendar', { time })}`}
                {visitMethod && (
                  <div className='visit-method'>{visitMethod}</div>
                )}
                {method === VisitMethod.Virtual && meetingUUID && (
                  <JoinTelehealthCallButtons
                    meetingUUID={meetingUUID}
                    onReschedule={() => {
                      history.push(
                        `${getViewPatientBaseUrl(id)}/${
                          vb.schedulingConfiguration?.id
                        }/booker/reschedule`
                      );
                    }}
                  />
                )}
              </div>
            );
          })}

        {devices?.map((d, ix) => {
          return (
            <div key={ix} className='participant-identifier'>
              <WatchRounded />
              {t('participantTags.participantInfo.fitbitLabel', {
                deviceVersion: d.deviceVersion,
                date: d.lastSyncTime
                  ? DateTime.fromISO(d.lastSyncTime).toLocaleString(
                      DateTime.DATETIME_FULL
                    )
                  : '????',
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};
