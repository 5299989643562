import { DateTime } from 'luxon';
import { getLocale, getLocaleFormatDates } from 'src/context/localeContext';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import ImageElement from '../basic/ImageElement';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const PISignature = () => {
  const { t } = useTranslation('translations');
  const { locale } = getLocale();
  const localesFormat = getLocaleFormatDates();
  let location = useLocation();
  const signature = queryString.parse(location.search);

  const legalName = signature.legalName;
  // @ts-ignore
  const imageURI = secureImageUriFromFileName(signature.file);

  // @ts-ignore
  const signedAt = DateTime.fromISO(signature.createdAt).toFormat(
    localesFormat.luxon.dataCaptureResults,
    { locale }
  );

  return (
    <div className='data-capture-results signature'>
      <div className={`dc-left`}>
        <div className='dc-status'>
          <span>{t('dataCaptureResultsView.status.signed')}</span>
          <br />
          <span>{signedAt}</span>
          <div style={{ margin: '15px 0' }}>
            <Divider variant={'fullWidth'} />
          </div>
        </div>
      </div>

      <div className='dc-right'>
        <div className='dc-cri'>
          {t('dataCaptureResultsView.PISignoff.title')}
        </div>
        <div>
          <p>
            {t('dataCaptureResultsView.PISignoff.signedBy')}: {legalName}
          </p>
          <p style={{ width: 300 }}>
            <ImageElement imageURI={imageURI} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PISignature;
