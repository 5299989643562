import {
  StudyActivity,
  StudyActivityStatus,
  VisitMethod,
} from '@curebase/core/types';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListItem from 'src/components/basic/ListItem';
import { getLocale } from 'src/context/localeContext';
import { scheduleVisit } from 'src/controllers/bookingController';
import { getViewPatientBaseUrl } from 'src/lib/users';
import { RolePermissions, useScheduleVisitListItemQuery } from 'src/types';
import PermissionedButton from '../basic/PermissionedButton';
import {
  mapActivityStatusToListItemStatus,
  TrialOptionStudyPlanContext,
} from './TrialOptionStudyPlan';

interface ScheduleVisitListItemProps {
  trialOptionId: number;
  activity: StudyActivity;
  trialOption: any;
}
function ScheduleVisitListItem(props: ScheduleVisitListItemProps) {
  const { t } = useTranslation();
  const { activity, trialOptionId, trialOption } = props;
  const { data } = useScheduleVisitListItemQuery({
    variables: {
      schedulingConfigurationId: activity.config.configId!,
      trialOptionId: trialOptionId!,
    },
    fetchPolicy: 'network-only',
  });
  const history = useHistory();
  const { locale } = getLocale();
  const { refetch } = useContext(TrialOptionStudyPlanContext);
  const [busy, setBusy] = useState(false);
  const buttons: JSX.Element[] = [
    <PermissionedButton
      onClick={() => {
        history.push(
          `${getViewPatientBaseUrl(trialOptionId)}/${
            activity.config.configId
          }/booker/schedule`
        );
      }}
      key='schedule'
      color='primary'
      disabled={busy}
      variant='text'
      permission={RolePermissions.ScheduleVisit}
    >
      {t('scheduleVisitListItem.scheduleButton')}
    </PermissionedButton>,
    <PermissionedButton
      key='cancel'
      color='secondary'
      disabled={busy}
      permission={RolePermissions.ScheduleVisit}
      variant='text'
      onClick={async () => {
        setBusy(true);
        try {
          await scheduleVisit(
            trialOptionId,
            DateTime.now().toISO(),
            trialOption.visitSiteId,
            VisitMethod.InPerson,
            activity.config.configId!
          );
          await refetch();
          setBusy(false);
        } catch {
          setBusy(false);
        }
      }}
    >
      {t('scheduleVisitListItem.skipButton')}
    </PermissionedButton>,
  ];

  const visit =
    data?.getSchedulingConfiguration?.locales?.[locale] ??
    data?.getSchedulingConfiguration;
  return (
    <ListItem
      status={{
        type: mapActivityStatusToListItemStatus(activity.status),
      }}
      middle={{
        title: visit?.name
          ? t('scheduleVisitListItem.scheduleText', { name: visit.name })
          : t('scheduleVisitListItem.scheduleTextVisit'),
        text: visit?.summary ?? t('scheduleVisitListItem.schedulePhysician'),
        buttons:
          activity.status === StudyActivityStatus.Active ? buttons : undefined,
      }}
    />
  );
}

export default ScheduleVisitListItem;
