import React from 'react';
import randomstring from 'randomstring';
import DynamicForm from '../basic/DynamicForm';
import {
  getDigitZipcodeValidationError,
  getEmailValidationError,
} from '@curebase/core/lib/validators';
import Dialog from '../basic/SafeDialog';
import AlertInDialog from '../basic/AlertInDialog';
import {
  DEVELOPER_FEATURES_ENABLED,
  IS_PRODUCTION,
} from '@curebase/core/lib/env';
import {
  CaseReportDataType,
  Pii_Status,
  PolicyDocumentType,
  RolePermissions,
  Trial,
} from '@curebase/core/types';
import { currentUserHasPermission } from '../../lib/auth';
import { DocumentHTML } from '../../shared/lib/queryHelpers';
import { TFunction, withTranslation } from 'react-i18next';
import { DEFAULT_LOCALE_JSON } from '@curebase/core/lib/constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';

type OwnProps = {
  setDisablePersonalDetails?: (x: any) => any;
  formContext?: any;
  trials?: any[];
  onSignup: (x: any) => any;
  errors?: Record<string, any>;
  t: TFunction;
};

type StateProps = {};

type Props = OwnProps & StateProps & LDProps;

export type ContactFormData = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  zipcode: string | null | undefined;
  trialId: number | null | undefined;
  customTrialId: string | null | undefined;
  clinicId: number | null | undefined;
  visitSiteId: number | null | undefined;
  skipExternalPrescreening: string;
  referralCode: string;
  piiStatus: Pii_Status | null | undefined;
};

type State = {
  showWarningDialogWithText: string | null | undefined;
  contactFormData: ContactFormData;
  dialogDocumentType: PolicyDocumentType;
  tosHtmlDocument?: DocumentHTML;
};

class SignupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showWarningDialogWithText: null,
      dialogDocumentType: PolicyDocumentType.PrivacyPolicy,
      contactFormData: {
        piiStatus: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        zipcode: null,
        trialId: null,
        customTrialId: null,
        skipExternalPrescreening: 'NO',
        referralCode: '',
        clinicId: null,
        visitSiteId: null,
      },
    };
  }

  getTrialInstanceDetails = () => {
    const currentTrial = this.props.trials?.find(
      ({ id }) => id === this.state.contactFormData.trialId
    );
    const possibleClinics = (currentTrial?.instances || []).map(
      ({ clinic }) => clinic
    );
    return { currentTrial, possibleClinics };
  };

  stubParticipantFormElements = () => {
    const { t, flags } = this.props;

    const elements: any = [];
    // if we are not selecting trial then there is no additional elements to render.
    if (!this.props.trials || this.props.trials?.length <= 0) {
      return [];
    }

    const { currentTrial, possibleClinics } = this.getTrialInstanceDetails();

    const validReferralSources = currentTrial?.validReferralSources || [];
    possibleClinics.sort((a, b) => {
      if (a.id.startsWith('~')) return -1;
      return a.name.localeCompare(b.name);
    });

    const possibleVisitSites =
      possibleClinics.find(
        ({ id }) => id === this.state.contactFormData.clinicId
      )?.visitSites || [];

    elements.push({
      title: t('common.trial'),
      subElements: [
        {
          type: CaseReportDataType.Autocomplete,
          key: 'trialId',
          options: this.props.trials
            .filter(t => !IS_PRODUCTION || !t.deactivated)
            .map(t => ({
              text: t.name,
              value: t.id,
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
          additionalSettings: {
            formatAsId: true,
          },
        },
      ],
    });

    const trial: Trial = this.props.trials.find(
      t => t.id === this.state.contactFormData.trialId
    );
    if (trial?.allowCustomSubjectId) {
      elements.push({
        title: 'SUBJID',
        subElements: [
          {
            key: 'customSubjectIdentifier',
            type: 'TEXT',
            placeholder: t('common.customSubjectIdentifier'),
            allowNull: true,
          },
        ],
      });
    }

    elements.push(
      {
        title: t('common.clinic'),
        subElements: [
          {
            type: 'DROPDOWN',
            key: 'clinicId',
            options: possibleClinics.map(t => ({
              text: t.name,
              value: t.id,
            })),
          },
        ],
      },
      {
        title: t('common.enrollmentCenter'),
        subElements: [
          {
            type: 'DROPDOWN',
            key: 'visitSiteId',
            options: possibleVisitSites
              .map(t => ({
                text: t.name,
                value: t.id,
              }))
              .sort((a, b) => a.text.localeCompare(b.text)),
          },
        ],
      }
    );

    const locales =
      currentTrial?.locales?.length > 0
        ? currentTrial.locales
        : [DEFAULT_LOCALE_JSON];

    if (locales.length > 1) {
      elements.push({
        title: t('signupForm.formElements.language'),
        subElements: [
          {
            type: 'DROPDOWN',
            key: 'locale',
            options: locales
              .map(v => ({
                text: t(`languages.${v.country}.${v.language}`),
                value: v.locale,
              }))
              .sort((a, b) => a.text.localeCompare(b.text)),
          },
        ],
      });
    }

    if (currentUserHasPermission(RolePermissions.SkipExternalPrescreening)) {
      if (currentUserHasPermission(RolePermissions.SelectStudyReferralSource)) {
        elements.push({
          title: t('signupForm.participantForm.referralSource'),
          subElements: [
            {
              key: 'referralCode',
              type: 'DROPDOWN',
              options: validReferralSources.map(v => ({ value: v, text: v })),
              allowNull: true,
            },
          ],
        });
      }
      if (!flags?.hideSkipPrescreening) {
        elements.push({
          title: t('signupForm.participantForm.skipInitial'),
          subElements: [
            {
              type: 'YES_NO',
              key: 'skipExternalPrescreening',
            },
          ],
        });
      }
    }
    return elements;
  };

  formElements = () => {
    const { t } = this.props;

    const elements = [
      {
        title: t('signupForm.formElements.firstName'),
        subElements: [
          {
            key: 'firstName',
            type: 'TEXT',
            placeholder: 'Jane',
          },
        ],
      },
      {
        title: t('signupForm.formElements.lastName'),
        subElements: [
          {
            key: 'lastName',
            type: 'TEXT',
            placeholder: 'Doe',
          },
        ],
      },
      {
        title: t('signupForm.formElements.email'),
        subElements: [
          {
            key: 'email',
            type: 'TEXT',
            placeholder: 'email@site.com',
            skipValidateOnChange: true,
            validate: (v: string) => {
              return getEmailValidationError(v, t);
            },
          },
        ],
      },
      {
        title: t('signupForm.formElements.phone'),
        subElements: [
          {
            type: 'PHONE_NUMBER',
            key: 'phoneNumber',
            placeholder: 'Phone number',
            noAutocomplete: true,
            skipValidateOnChange: true,
          },
        ],
      },
      {
        title: t('signupForm.formElements.zipCode'),
        subElements: [
          {
            key: 'zipcode',
            type: 'NUMBER',
            placeholder: '94118',
            skipValidateOnChange: true,
            validate: (v: string) => {
              return getDigitZipcodeValidationError(v, t);
            },
            allowNull: currentUserHasPermission(
              RolePermissions.CreateStubParticipant
            ),
          },
        ],
      },
    ];

    elements.push(...this.stubParticipantFormElements());
    return elements;
  };

  submitData = async dfData => {
    if (!dfData) return;
    const { piiStatus, firstName, lastName, email, phoneNumber } = dfData;

    if (piiStatus === Pii_Status.Enabled) {
      if (!(firstName && lastName && email && phoneNumber)) return null;
    }

    // auto select the referral code
    const { possibleClinics, currentTrial } = this.getTrialInstanceDetails();

    if (possibleClinics?.length > 0) {
      const clinic =
        dfData.clinicId !== null
          ? possibleClinics.find(clinic => clinic.id === dfData.clinicId)
          : possibleClinics[0];
      if (clinic) {
        if (!dfData.clinicId) {
          dfData.clinicId = clinic.id;
        }
        dfData.referralCode = clinic.slug;
        if (!dfData.visitSiteId && clinic.visitSites?.length > 0) {
          dfData.visitSiteId = clinic.visitSites[0]?.id;
        }
      }
    }

    if (!dfData.locale) {
      const defaultLocale = currentTrial?.locales?.find(
        ({ isDefault }) => isDefault
      );
      dfData.locale = defaultLocale?.locale;
    }

    await this.props.onSignup(dfData);
  };

  render() {
    const { t } = this.props;
    const { formContext, setDisablePersonalDetails } = this.props;
    const { contactFormData } = this.state;

    const internalPrescreeningOnlyQuestions: any[] = [
      {
        title: t('signupForm.internal.title'),
        subElements: [
          {
            type: 'MULTISELECT',
            key: 'piiStatus',
            allowNull: false,
            options: [
              {
                text: t('signupForm.internal.aliveAndAvailableOpt'),
                value: Pii_Status.Enabled,
              },
              {
                text: t('signupForm.internal.deIdentifiedOpt'),
                value: Pii_Status.DisabledDeIdentifiedPatient,
              },
              {
                text: t('signupForm.internal.lostToFollowupOpt'),
                value: Pii_Status.DisabledLostToFollowUp,
                selectionNote: t('signupForm.internal.lostToFollowupNote'),
              },
              {
                text: t('signupForm.internal.deceasedOpt'),
                value: Pii_Status.DisabledPatientDeceased,
                selectionNote: t('signupForm.internal.deceasedNote'),
              },
            ],
          },
        ],
      },
    ];

    const assentInstructions: any[] = [
      {
        title: t('signupForm.alert.title'),
        alert: {
          description: t('signupForm.assentInstruction'),
          severity: 'warning',
        },
        subElements: [],
      },
    ];

    return (
      <>
        <DynamicForm
          // @ts-ignore
          pages={[
            {
              headerText: t('signupForm.headerText'),
              elements: [
                ...assentInstructions,
                ...internalPrescreeningOnlyQuestions,
                ...(![
                  Pii_Status.DisabledLostToFollowUp,
                  Pii_Status.DisabledPatientDeceased,
                  Pii_Status.DisabledDeIdentifiedPatient,
                ].includes(contactFormData.piiStatus as any)
                  ? this.formElements()
                  : this.stubParticipantFormElements()),
              ],
            },
          ]}
          preferences={{
            hidePersonalInformation: true,
          }}
          data={this.state.contactFormData}
          onChange={(key, value) => {
            if (key === 'piiStatus' && setDisablePersonalDetails)
              setDisablePersonalDetails(value);

            this.setState({
              contactFormData: {
                ...this.state.contactFormData,
                [key]: value,
              },
            });
          }}
          onSubmit={dfData => this.submitData(dfData)}
          errors={this.props.errors}
          context={formContext}
          submitText={t('signupForm.signUpBtn')}
          disableSubmit={false}
          autocomplete={
            DEVELOPER_FEATURES_ENABLED
              ? () => {
                  const placeHolderData = {
                    ...this.state.contactFormData,
                    firstName: this.state.contactFormData.firstName || 'test',
                    lastName: this.state.contactFormData.lastName || 'dev',
                    email:
                      this.state.contactFormData.email ||
                      `test+${randomstring.generate(8)}@curebase.com`,
                    phoneNumber:
                      this.state.contactFormData.phoneNumber || '+10000000000',
                    zipcode: this.state.contactFormData.zipcode || '94115',
                    trialId:
                      this.state.contactFormData.trialId ||
                      this.props.trials?.[0]?.id,
                    skipExternalPrescreening: this.state.contactFormData
                      .skipExternalPrescreening,
                    referralCode: this.state.contactFormData.referralCode,
                    piiStatus: Pii_Status.Enabled,
                  };
                  this.setState(
                    {
                      contactFormData: placeHolderData,
                    },
                    () => this.submitData(placeHolderData)
                  );
                }
              : null
          }
        />

        <Dialog
          open={!!this.state.showWarningDialogWithText}
          onClose={() => this.setState({ showWarningDialogWithText: null })}
        >
          <AlertInDialog
            title='Alert'
            message={this.state.showWarningDialogWithText || ''}
            onConfirm={() => this.setState({ showWarningDialogWithText: null })}
          />
        </Dialog>
      </>
    );
  }
}

export default withTranslation('translations')(withLDConsumer()(SignupForm));
