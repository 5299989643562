import * as React from 'react';
import Loadable from 'react-loadable';
import 'whatwg-fetch'; // fetch polyfill

import { apiUrl } from '@curebase/core/lib/env';
import Loading from './Loading';
import { getLocale } from '../context/localeContext';

type Props = {};

const LoadableGraphiQL = Loadable({
  loader: () => import('graphiql'),
  loading: () => <Loading />,
});

class GraphQLFetcher extends React.Component<Props> {
  graphQLFetcher = graphQLParams => {
    return fetch(`${apiUrl}/graphql`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
        'x-curebase-locale': getLocale().locale,
      },
      credentials: 'include',
      body: JSON.stringify(graphQLParams),
    }).then(response => response.json());
  };

  render() {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <LoadableGraphiQL
          fetcher={this.graphQLFetcher}
          editorTheme='solarized light'
        />
      </div>
    );
  }
}

export default GraphQLFetcher;
