import React, { useContext } from 'react';
import SVG from 'react-inlinesvg';
import CustomStyleContext from '../../../context/customStyleContext';
import { createActionCardStyle } from '../../../lib/customStyle';

//
// Action Card Icon
//

export default function ActionCardIcon({ iconSrc }: any) {
  const context = useContext(CustomStyleContext);
  const classes = createActionCardStyle(context);
  return (
    <div className='ac-icon-section-container'>
      <div className={`ac-icon-large-radial ${classes.largeRadial}`} />
      <div className={`ac-icon-small-radial ${classes.radial}`}>
        <div className='ac-icon-container'>
          <SVG src={iconSrc} className={`ac-icon ${classes.icon}`} />
        </div>
      </div>
    </div>
  );
}
