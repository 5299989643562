import * as React from 'react';

import { capitalizeDisplayName } from '../../../lib/ui';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

type QueryMessageSectionProps = {
  message: {
    message: string;
    createdAt: string;
    submittedByUser: {
      displayName: string;
    };
  };
};

const QueryMessageSection = (props: QueryMessageSectionProps) => {
  const { t } = useTranslation('translations');
  const { message } = props;

  return (
    <div className='fr-query-message'>
      <div className='fr-query-message-header'>
        {`${capitalizeDisplayName(message.submittedByUser, t)} (${moment(
          message.createdAt
        ).fromNow()})`}
      </div>
      <div className='fr-query-message-content'>
        <em>{message.message}</em>
      </div>
    </div>
  );
};

export default QueryMessageSection;
