import { RolePermissions } from '@curebase/core/types';
import RestrictedRoute from '../../RestrictedRoute';
import AssociateClinic from '../SiteManagement/AssociateClinic';
import SiteConfigEditor from '../SiteManagement/SiteConfigEditor';
import ResearchTeamList from '../SiteManagement/ResearchTeamList';
import AssociateScheduling from '../SchedulingManagement/AssociateScheduling';
import ParticipantsView from '../views/ParticipantsView';
import SponsorClinics from '../SponsorInterface/Clinics/Clinics';
import TrialOptionView from '../TrialOption/TrialOptionView';
import DataCapture from '../DataCapture/DataCapture';
import DataCaptureReview from '../DataCapture/DataCaptureReview';
import ParticipantDataCaptureReview from '../ParticipantInterface/ParticipantDataCaptureReview';
import { RouteConfig } from './types';

export const viewParticipantBaseRoute = '/u/participants';

const ClinicSubRoutes: RouteConfig[] = [
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/clinic',
      component: ResearchTeamList,
      permission: RolePermissions.ViewResearchSites,
      exact: true,
    },
  },
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/clinic/trial/:trialId',
      component: SponsorClinics,
      permission: RolePermissions.ViewSponsorClinics,
      exact: true,
    },
  },
  /* List of participants */
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: viewParticipantBaseRoute,
      component: ParticipantsView,
      permission: RolePermissions.ViewParticipants,
      exact: true,
    },
  },
  /* Patient study plan */
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: `${viewParticipantBaseRoute}/:trialOptionId/:viewType?`,
      component: TrialOptionView,
      permission: RolePermissions.ViewParticipants,
      exact: false,
    },
  },
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/clinic/:clinicId/config/:configName',
      component: SiteConfigEditor,
      permission: RolePermissions.LegacyAssociates,
      exact: true,
    },
  },
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/clinic/:clinicId',
      component: AssociateClinic,
      permission: RolePermissions.ViewResearchSites,
      exact: true,
    },
  },
  /* DataCapture */
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/data/:visitId/capture',
      component: DataCapture,
      permission: RolePermissions.SubmitInitialDataCapture,
      exact: true,
      customHeader: true,
    },
  },
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/data/:visitId/review',
      component: ParticipantDataCaptureReview,
      permission: RolePermissions.SubmitInitialDataCapture,
      exact: true,
      customHeader: true,
    },
  },
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/data/:trialOptionId/:studyPlanVisitSlug/review',
      component: DataCaptureReview,
      permission: RolePermissions.ViewParticipants,
      exact: true,
    },
  },
  /* Scheduling */
  {
    type: 'restricted',
    routeComponent: RestrictedRoute,
    props: {
      path: '/u/clinic/:clinicId/site/:visitSiteId/scheduling',
      component: AssociateScheduling,
      permission: RolePermissions.ViewScheduling,
      exact: true,
    },
  },
];

export default ClinicSubRoutes;
