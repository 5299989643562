import { AcceptedCurrency, Payment, TrialOption } from '@curebase/core/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatName } from 'src/shared/lib/uiHelpers';
import DialogHeader from '../basic/DialogHeader';
import DynamicForm from '../basic/DynamicForm';
import Dialog from '../basic/SafeDialog';

type FormData = Readonly<{
  payment: Object;
}>;

interface Props {
  trialOption: TrialOption;
  payment?: Payment;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => Promise<void>;
}

function CreatePaymentDialog(props: Props) {
  const { t } = useTranslation('translations');
  const { trialOption, open, onClose, onSubmit, payment } = props;
  const { patient } = trialOption;
  const [state, setState]: [FormData, any] = useState<any>({});

  if (!open) {
    return null;
  }

  //@ts-ignore
  const patientName = formatName({
    firstName: patient?.user.firstName,
    lastName: patient?.user.lastName,
  });

  const currencies = [
    AcceptedCurrency.Usd,
    AcceptedCurrency.Gbp,
    AcceptedCurrency.Cad,
    AcceptedCurrency.Eur,
  ].map(currency => ({ text: currency, value: currency }));

  const subtitle = (): string => {
    if (payment) {
      return t('createPaymentDialog.subtitleForcePayout', {
        displayAmount: payment.displayAmount,
        patientName,
      });
    }

    return t('createPaymentDialog.subtitleAdHoc', {
      patientName,
    });
  };

  const elements = (): any[] => {
    const reasonTitle = payment
      ? t('createPaymentDialog.paymentReasonPaymentTitle')
      : t('createPaymentDialog.paymentReasonAdHocTitle');

    const elements: any[] = [
      {
        title: reasonTitle,
        subElements: [
          {
            key: 'reason',
            type: 'PARAGRAPH',
            optional: false,
          },
        ],
      },
    ];

    if (payment) {
      return elements;
    }

    const amountElement = {
      title: t('createPaymentDialog.amountTitle'),
      subElements: [
        {
          key: 'payment',
          type: 'CURRENCY',
          currency: currencies,
          placeholder: t('createPaymentDialog.amountPlaceholder'),
          max: 300,
          optional: false,
        },
      ],
    };

    return [amountElement, ...elements];
  };

  return (
    <Dialog className='themed-dialog' open={open} onClose={onClose}>
      <DialogHeader
        title={t('createPaymentDialog.dialogTitle')}
        subtitle={subtitle()}
        onClose={onClose}
      />
      <DynamicForm
        // @ts-ignore
        pages={[{ elements: elements() }]}
        onChange={(key, value) =>
          setState({
            ...state,
            [key]: value,
          })
        }
        data={state}
        onSubmit={() => {
          onSubmit({ ...state });
          setState({});
        }}
      />
    </Dialog>
  );
}

export default CreatePaymentDialog;
