import { AppTheme } from './AppTheme';

const curebaseTheme: AppTheme = {
  colors: {
    primary: '#499297',
    secondary: '#E9998F',
  },
  contrast: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
  },
};

export default curebaseTheme;
