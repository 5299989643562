import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { getUserSelector } from './store/selectors';
import { userHasPermission } from '@curebase/core/lib/authorization';
import ViewComponent from './ViewComponent';
import { isAuthenticatedHelper } from './lib/auth';
import { CustomRouteProps } from './PrivateRoute';
import { RolePermissions } from './types';
import { Route } from 'react-router-dom';

export type RestrictedRouteProps = CustomRouteProps & {
  permission: RolePermissions;
  remount?: boolean; //remount on route change
  customHeader?: boolean;
  hideFooter?: boolean;
};

const RestrictedRoute = (props: RestrictedRouteProps) => {
  const { component, render, remount, ...rest } = props;

  const user: any = useSelector(getUserSelector);

  const renderFunc = (renderProps: RouteComponentProps<any>) => {
    const attemptedRoute = (props.location || {}).pathname;

    if (!isAuthenticatedHelper(user)) {
      const to: LocationDescriptor = {
        pathname: '/auth/login',
      };
      if (attemptedRoute) to.search = `?redirect=${encodeURI(attemptedRoute)}`;
      return <Redirect to={to} />;
    } else if (props.permission && !userHasPermission(user, props.permission)) {
      const error = new Error(
        `User does not have permission ${props.permission} for the route ${attemptedRoute}`
      );
      console.error(error); // So it gets logged to sentry
      return <Redirect to='/u' />;
    } else {
      return (
        <ViewComponent
          routeProps={{ ...props, component: component ?? render }}
          renderProps={renderProps}
        />
      );
    }
  };

  return (
    <Route
      {...rest}
      render={remount ? undefined : renderFunc}
      component={remount ? renderFunc : undefined}
    />
  );
};

export default RestrictedRoute;
