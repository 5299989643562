import * as React from 'react';

type Props = {
  readOnly?: boolean;
  specialType?: 'number' | 'password' | 'email';
  value?: string;
  min?: number;
  max?: number;
  error?: string;
  onChange?: (value: string) => any;
  placeholder?: string;
  noSpellcheck?: boolean;
  onEnter?: () => any;
  hidePersonalInformation?: boolean;
};

class TextInput extends React.Component<Props> {
  render() {
    const hasError = !!this.props.error;
    const onEnter = this.props.onEnter;

    return (
      <div className='text-input-container'>
        <input
          autoCapitalize='none'
          spellCheck={this.props.noSpellcheck ? 'false' : undefined}
          disabled={this.props.readOnly || false}
          type={this.props.specialType ? this.props.specialType : 'text'}
          value={this.props.value || ''}
          min={this.props.min || ''}
          max={this.props.max || ''}
          className={`
            text-input
            ${hasError ? 'text-input-with-error' : ''}
            ${this.props.hidePersonalInformation ? 'fs-block' : ''}
          `}
          onChange={e => {
            if (this.props.onChange && !this.props.readOnly) {
              this.props.onChange(e.target.value);
            }
          }}
          onKeyUp={
            !onEnter
              ? undefined
              : e => {
                  if (e.keyCode === 13) {
                    onEnter();
                  }
                }
          }
          placeholder={this.props.placeholder}
        />
        {hasError && (
          <div className='text-input-error-message'>{this.props.error}</div>
        )}
      </div>
    );
  }
}

export default TextInput;
