import evaluators from '../evaluators';

export function getSharedEvaluatorFromString(evaluatorString: string) {
  const evaluatorPathParts = evaluatorString.split('.');
  //@ts-ignore
  const evaluatorModule = evaluators[evaluatorPathParts[1]];
  if (!evaluatorModule) {
    throw new Error(
      'Could not find evaluator module: ' + evaluatorPathParts[1]
    );
  }
  const evaluator = evaluatorModule[evaluatorPathParts[2]];
  if (!evaluator) {
    throw new Error('Could not find evaluator: ' + evaluatorPathParts[2]);
  }
  return evaluator;
}

export function getAllSharedEvaluators() {
  const res: string[] = [];
  for (const key in evaluators) {
    //@ts-ignore
    for (const evaluator in evaluators[key]) {
      res.push(`${key}.${evaluator}`);
    }
  }
  return [...res, 'shared.age_over_18'];
}
