import React, { useMemo, useRef } from 'react';
import { orderBy } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import flags from 'react-phone-number-input/flags';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import { makeStyles, Typography } from '@material-ui/core';
import { getPhoneInputLocales } from '../../../context/localeContext';

const useSelectStyles = makeStyles(theme => ({
  outlined: {
    paddingTop: 12,
    paddingBottom: 11,
    paddingLeft: 10,

    '& $notchedOutline': {
      borderColor: 'red !important',
      borderRadius: 5,
    },
  },
  root: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
    backgroundColor: theme.palette.background.paper,
    '& .CSelect-startIcon': {
      marginRight: '8px',
    },
  },
  select: {
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: '#e1e1e1',
      borderRadius: 5,
      boxShadow: '0px 1px 3px rgb(0 0 0 / 10%)',
    },
    '&:hover fieldset': {
      borderColor: '#e1e1e1 !important',
    },
  },
}));

export interface CountryCodeSelectProps {
  variant?: 'filled' | 'outlined';
  name?: string;
  label?: string;
  helperText?: string;
  hasError?: boolean;
  fullWidth?: boolean;
  options?: {
    value: any;
    label: string;
    startIcon?: any;
    endIcon?: any;
  }[];
  disabled?: boolean;
  color?: string;
  size?: 'small' | 'medium';
  minWidth?: number | undefined;
  autoWidth?: boolean | undefined;
  multiple?: boolean | undefined;
  startAdornment?: any;
  testId?: string | undefined;
  renderValue?: (value?: string | number) => any;
  value?: string;
  onChange: (code: string) => void;
}

function getFlag(country: any): any {
  return React.createElement((flags as any)[country], {
    style: { height: 14.29, borderRadius: 3 },
  });
}

export default function CountryCodeSelect({
  label,
  name,
  value = 'US',
  onChange,
  minWidth = 118,
  helperText,
  hasError,
  variant,
  disabled,
  autoWidth,
  color,
  startAdornment,
  ...props
}: CountryCodeSelectProps) {
  const { current: fieldId } = useRef(
    'cbsel-' + (Math.random().toString(36) + '00000000000000000').slice(2, 7)
  );

  const lng = getPhoneInputLocales();

  const options = useMemo(() => {
    return orderBy(getCountries(), c => lng[c], 'asc').map(country => ({
      value: country,
      label: `(+${getCountryCallingCode(country)}) ${lng[country]}`,
      startIcon: getFlag(country),
    }));
  }, []);

  function selfOnChange(event: any) {
    onChange(event.target.value);
  }

  function selfRenderValue(country: any) {
    return country ? (
      <>
        <span className='CSelect-startIcon'>{getFlag(country)}</span>
        <Typography
          display='inline'
          style={{ margin: 0, padding: 0, lineHeight: 0 }}
          variant='h6'
        >
          {`+${getCountryCallingCode(country)}`}
        </Typography>
      </>
    ) : (
      ''
    );
  }

  const classes = useSelectStyles();

  return (
    <Select
      id={fieldId}
      name={name}
      label={label}
      value={value}
      onChange={selfOnChange}
      disabled={disabled}
      variant={variant}
      error={hasError}
      autoWidth={autoWidth}
      multiple={false}
      color={color as any}
      fullWidth={props.fullWidth}
      startAdornment={
        startAdornment && (
          <InputAdornment position='start'>{startAdornment}</InputAdornment>
        )
      }
      renderValue={selfRenderValue}
      classes={classes}
      className={classes.select}
    >
      {options.map(({ value, label, startIcon }, idx) => (
        <MenuItem key={`sel-${value}-${idx}`} value={value}>
          {startIcon && <span style={{ marginRight: 8 }}>{startIcon}</span>}
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}
