import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import type { SiteManagementConfigMeta } from '@curebase/core/types';
import ListItem from './basic/ListItem';
import Subheader from './basic/Subheader';
import { useTranslation } from 'react-i18next';

type Props = {
  configs: ReadonlyArray<SiteManagementConfigMeta>;
};

const ConfigList = ({ configs }: Props) => {
  const { t } = useTranslation('translations');
  const history = useHistory();
  return (
    <div className='li-multi-container inset'>
      {configs.length !== 0 ? (
        configs.map((c, i) => (
          <ListItem
            key={i}
            // @ts-ignore
            middle={{
              title: c.configName,
              subtitle: t('configList.subtitle', {
                lastModified: moment(c.LastModified).format('LLLL'),
              }),
            }}
            onClick={() =>
              history.push(
                history.location.pathname + '/config/' + c.configName
              )
            }
          />
        ))
      ) : (
        <ListItem
          // @ts-ignore
          middle={{
            title: t('configList.emptyListLabel'),
          }}
        />
      )}
    </div>
  );
};

const ConfigurationsView = (props: Props) => {
  const { t } = useTranslation('translations');
  const { configs } = props;
  return (
    <>
      <Subheader text={t('configList.liveTitle')} />
      <ConfigList configs={configs} />
    </>
  );
};

export default ConfigurationsView;
