import * as React from 'react';
import Dialog from '../basic/SafeDialog';
import DialogHeader from '../basic/DialogHeader';
import DynamicForm from '../basic/DynamicForm';
import { useImmutableState } from '../../hooks/useImmutableState';
import { useTranslation } from 'react-i18next';

type FormData = Readonly<{
  clinicName: string;
}>;

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (data: FormData) => Promise<void>;
};

const CreateClinicDialog = (props: Props) => {
  const { t } = useTranslation('translations');
  const { open, onClose, onSubmit } = props;
  const [state, setState]: [FormData, any] = useImmutableState<any>({});
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        title={t('createClinicDialog.dialogTitle')}
        onClose={onClose}
      />
      <DynamicForm
        // @ts-ignore
        pages={[
          {
            elements: [
              {
                title: t('createClinicDialog.inputTitle'),
                subElements: [
                  {
                    key: 'clinicName',
                    type: 'TEXT',
                    placeholder: t('createClinicDialog.inputPlaceholder'),
                  },
                ],
              },
            ],
          },
        ]}
        onChange={(key, value) => setState({ [key]: value })}
        data={state}
        onSubmit={() => onSubmit(state)}
      />
    </Dialog>
  );
};

export default CreateClinicDialog;
