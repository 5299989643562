import * as React from 'react';

export interface PickerOption {
  title: string;
  subtitle?: string;
  value: any;
  iconSrc?: string;
  onClick?: (p: any) => any;
}

export interface OneClickPickerProps {
  options: PickerOption[];
  onSelect?: (p: any) => any;
}

const OneClickPicker = (props: OneClickPickerProps) => {
  const { options, onSelect } = props;
  return (
    <div className='one-click-picker'>
      {options.map((option, i) => {
        const { title, subtitle, value, iconSrc, onClick } = option;
        const callback = () =>
          onClick ? onClick(value) : onSelect ? onSelect(value) : undefined;
        return (
          <div className='ocp-option' onClick={callback} key={i}>
            {!!iconSrc && (
              <div className='ocp-left'>
                <img src={iconSrc} alt='Icon' />
              </div>
            )}
            <div className='ocp-right'>
              <div className='ocp-right-top'>{title}</div>
              {subtitle && <div className='ocp-right-bottom'>{subtitle}</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OneClickPicker;
