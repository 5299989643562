import { combineReducers } from 'redux';
import undoable from 'redux-undo';
import UI, { UIState } from './uiState';
import caseReports from './caseReports';
import dataFields from './dataFields';
import documentConfigurationIds from './documentConfigurationIds';
import machine from './machine';
import refs from './refs';
import sponsor from './sponsor';
import studyPlanVisits from './studyPlanVisits';
import trial from './trial';
import scheduling from 'src/store/trialBuilder/scheduling/reducers';
import gtmContainers from 'src/store/trialBuilder/gtmContainers/reducers';
import sites from 'src/store/trialBuilder/sites/reducers';

import type {
  CaseReport,
  DataField,
  Sponsor,
  StudyPlanVisit,
  Trial,
} from '@curebase/core/types';

export type EditStatus = {
  deleted?: boolean;
  edited?: boolean;
  new?: boolean;
};

export type DataFieldMap = {
  [slug: string]: DataField & EditStatus;
};

export type CaseReportMap = Readonly<{
  [slug: string]: CaseReport & EditStatus;
}>;

export type StudyPlanVisitMap = {
  [slug: string]: StudyPlanVisit & EditStatus;
};

export type RefsMap = Readonly<{
  [name: string]: Array<Object>;
}>;

export type DeploymentInfo = {
  deployed: boolean;
  latest?: boolean;
  deployedVersion?: string;
  latestVersion?: string;
};

export type DataState = {
  refs: RefsMap;
  sponsor: Sponsor;
  dataFields: DataFieldMap;
  caseReports: CaseReportMap;
  studyPlanVisits: StudyPlanVisitMap;
  trial: Trial & EditStatus;
  version: string;
  revision: number;
  description: string;
  deploymentInfo: DeploymentInfo;
  casebook: any;
  machine: Object;
};

export type TrialBuilderStore = Readonly<{
  data: {
    future: Array<DataState>;
    present: DataState;
    past: Array<DataState>;
  };
  UI: UIState;
}>;

const data = combineReducers({
  scheduling,
  caseReports,
  gtmContainers,
  sites,
  dataFields,
  documentConfigurationIds,
  machine,
  refs,
  sponsor,
  studyPlanVisits,
  trial,
  version: (state = '(unknown version)', action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.version ?? state;
      default:
        return state;
    }
  },
  revision: (state = '(unknown revision)', action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.revision ?? state;
      default:
        return state;
    }
  },
  description: (state = '(no description)', action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.description ?? state;
      default:
        return state;
    }
  },
  approvalInfo: (state = null, action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.approvalInfo ?? null;
      default:
        return state;
    }
  },
  forkedFrom: (state = null, action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.forkedFrom ?? null;
      default:
        return state;
    }
  },
  deploymentInfo: (state = null, action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.deploymentInfo;
      default:
        return state;
    }
  },
  casebook: (state = null, action) => {
    switch (action.type) {
      case 'INIT_TRIAL':
        return action.payload.casebook ?? null;
      default:
        return state;
    }
  },
});

export default combineReducers({
  data: undoable(data),
  UI,
});
