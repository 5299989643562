import _includes from 'lodash/includes';

export default function evaluateValues(
  answerIsAnArray: boolean,
  valueToTest: any,
  values?: readonly string[]
): boolean {
  // MULTICHECKBOX gives an array back
  if (answerIsAnArray) {
    const atLeastOneAnswerInValues = values?.some(v => valueToTest.includes(v));
    if (!atLeastOneAnswerInValues) return false;
  } else if (!_includes(values, valueToTest)) {
    return false;
  }
  return true;
}
