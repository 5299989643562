import * as React from 'react';
import withSizes from 'react-sizes';

export const isExtraSmallMaxWidth = 400;
export const isSmallMaxWidth = 660;
export const isTabletSizedMaxWidth = 800;

export function withBreakpoints<SourceProps>(
  TargetComponent: React.Component<
    {
      extraSmallScreen: boolean;
      smallScreen: boolean;
      tabledSizedScreen: boolean;
    } & SourceProps
  >
): React.ComponentType<SourceProps> {
  const mapSizesToProps = ({ width }) => ({
    extraSmallScreen: width < isExtraSmallMaxWidth,
    smallScreen: width < isSmallMaxWidth,
    tabletSizedScreen: width < isTabletSizedMaxWidth,
  });
  // @ts-ignore
  return withSizes(mapSizesToProps)(TargetComponent);
}
