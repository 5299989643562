import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import {
  unlockDataFieldForUpload,
  deleteScreenshot,
} from '../../controllers/telemedController';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _map from 'lodash/map';
import {
  useTelehealthUnlockedDataFieldQuery,
  useTelehealthuploadImagesQuery,
  RolePermissions,
} from '../../types';
import { currentUserHasPermission } from '../../lib/auth';
import ImageElement from '../basic/ImageElement';
import { showAlertMessage } from 'src/store/actions';

type FileBlob = {
  fileId: string;
  filePath: string;
};

const UnlockPanel = (props: {
  visitId: number;
  currentDataFieldId: string;
  meetingUUID: string;
  isUnlocked: boolean;
  hasImages: boolean;
}) => {
  const {
    meetingUUID,
    currentDataFieldId,
    visitId,
    isUnlocked,
    hasImages,
  } = props;
  const [disabledButton, setDisabledButton] = useState(false);
  return (
    <div className='telehealth-unlock-row'>
      <Button
        disabled={disabledButton}
        onClick={() => {
          setDisabledButton(true);
          unlockDataFieldForUpload(
            meetingUUID,
            currentDataFieldId,
            visitId
          ).then(res => {
            if (res?.error) {
              showAlertMessage(res.error);
            }
            setDisabledButton(false);
          });
        }}
      >
        {!isUnlocked ? (
          <>
            <CameraEnhanceIcon />
            <div className='telehealth-upload-text'>UNLOCK IMAGE CAPTURE</div>
          </>
        ) : (
          <>
            <CheckCircleIcon />
            <div className='telehealth-upload-text'>
              {!hasImages ? 'CANCEL IMAGE CAPTURE' : 'SAVE IMAGES'}
            </div>
          </>
        )}
      </Button>
    </div>
  );
};

const TelehealthFileUpload = (props: {
  visitId: number;
  subEle: any;
  data: any;
  onChange: (files: FileBlob[]) => void;
}) => {
  const { visitId, data: dynamicFormData, subEle } = props;
  const { openVideo, addPhotos, meetingTitle } = useSelector(
    (store: any) => store?.telehealth
  );

  const { currentDate } = useSelector((store: any) => store);

  const dataFieldId = subEle.key;
  const { data } = useTelehealthUnlockedDataFieldQuery({
    skip: !meetingTitle,
    variables: { meetingUUID: meetingTitle },
    pollInterval: 2000,
  });

  const { data: telehealthImageData } = useTelehealthuploadImagesQuery({
    skip: !visitId || !dataFieldId,
    variables: { visitId, dataFieldId },
    pollInterval: 2000,
  });

  let capturedData = (telehealthImageData?.getTelehealthUploadImages ??
    dynamicFormData[dataFieldId] ??
    subEle.value ??
    []) as FileBlob[];

  const res = data?.getTelehealthUnlockedDataField;
  const isUnlocked =
    res?.visitId === visitId && res?.dataFieldId === dataFieldId;
  useEffect(() => {
    const remoteValue = telehealthImageData?.getTelehealthUploadImages;
    if (remoteValue && remoteValue !== dynamicFormData[dataFieldId]) {
      props.onChange(remoteValue as FileBlob[]);
    }
    // eslint-disable-next-line
  }, [telehealthImageData?.getTelehealthUploadImages]);

  return (
    <div className='telehealth-file-upload-container'>
      <div>
        <div className='file-preview-container'>
          {isUnlocked && capturedData.length === 0 ? (
            <div className='no-image-block'>
              <span style={{ marginLeft: '10px' }}>NO IMAGES CAPTURED YET</span>
            </div>
          ) : (
            <>
              {React.Children.toArray(
                _map(capturedData, (elem, index: number) => {
                  const imageURI = secureImageUriFromFileName(elem.filePath);
                  return (
                    <div className='telehealth-preview-container'>
                      <ImageElement
                        imageURI={imageURI}
                        onDelete={() =>
                          deleteScreenshot(
                            elem.fileId,
                            dataFieldId,
                            visitId,
                            currentDate
                          )
                        }
                        permissionToDelete={
                          RolePermissions.CanUnlockTelehealthFileUpload
                        }
                      />
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
        {openVideo || addPhotos ? (
          <>
            {currentUserHasPermission(
              RolePermissions.CanUnlockTelehealthFileUpload
            ) && (
              <>
                <UnlockPanel
                  visitId={visitId}
                  currentDataFieldId={dataFieldId}
                  isUnlocked={isUnlocked}
                  meetingUUID={meetingTitle}
                  hasImages={capturedData.length > 0}
                />
              </>
            )}
            <div>
              All participants in the video call will be able to capture images
              on their device.
            </div>
          </>
        ) : (
          <div>QUESTION ONLY ACCESSIBLE DURING TELEMEDICINE CALL</div>
        )}
      </div>
    </div>
  );
};

export default TelehealthFileUpload;
