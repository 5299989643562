import {
  isComplete,
  isFailed,
  isOnHold,
  isPending,
} from '@curebase/core/lib/payments';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {
  Payment,
  PaymentStatus,
  RolePermissions,
  TrialOption,
} from '@curebase/core/types';

import Loading from '../Loading';
import ListItem from '../basic/ListItem';
import Subheader from '../basic/Subheader';
import StandardButton from '../basic/StandardButton';
import CreatePaymentDialog from './CreateManualPaymentDialog';
import {
  createAdhocPayment,
  forcePayout,
} from '../../controllers/paymentsController';
import PermissionedButton from '../basic/PermissionedButton';
import { showAlertMessage } from 'src/store/actions';
import { StatusColor } from 'src/shared/lib/colors';
import {
  useTrialOptionPaymentsQuery,
  useResetUserPaymentCredentialsMutation,
} from '../../types';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import SafeDialog from '../basic/SafeDialog';
import { PAYPAL_URL } from 'src/lib/env';
import { useTranslation } from 'react-i18next';

interface Props {
  trialOptionId: number;
}

const linkToPaypalTx = (paypalTransactionId: string) => {
  return `${PAYPAL_URL}/activity/payment/${paypalTransactionId}`;
};

const paymentInfo = (payment: Payment, t: any) => {
  const { context } = payment;

  const reason = context.reason ?? '';

  if (isOnHold(payment.status as PaymentStatus)) {
    return reason;
  }

  if (isPending(payment.status as PaymentStatus)) {
    return t('trialOptionPayments.paymentInfoWaiting', {
      reason,
    });
  }

  if (isComplete(payment.status as PaymentStatus)) {
    return t('trialOptionPayments.paymentInfoProcessed', {
      reason,
      paidOn: DateTime.fromISO(payment.paidOn!).toLocaleString(
        DateTime.DATE_MED
      ),
    });
  }

  if (payment.status === PaymentStatus.Error) {
    return t('trialOptionPayments.paymentInfoError', {
      reason,
    });
  }

  return reason;
};

const title = (payment: Payment, t: any): string => {
  if (payment.visit) {
    return t('trialOptionPayments.titleVisit', {
      displayAmount: payment.displayAmount,
      studyPlanVisit: payment.visit.studyPlanVisit.summary.title,
    });
  }

  return t('trialOptionPayments.titleMilestone', {
    displayAmount: payment.displayAmount,
  });
};

const mapPaymentStatusToListItemStatus = (status: PaymentStatus) => {
  if (isComplete(status)) {
    return 'COMPLETED';
  }

  if (isPending(status) || isOnHold(status)) {
    return 'PENDING';
  }

  if (isFailed(status)) {
    return 'FAILED';
  }

  return 'BLANK';
};

const MakeAddHocPaymentButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('translations');
  return (
    <PermissionedButton
      disabled={false}
      permission={RolePermissions.CreateAdHocPayment}
      variant='contained'
      color='primary'
      buttonText={t('trialOptionPayments.makeAddHoc.makeBtn')}
      onClick={onClick}
    >
      <ArrowRightAltIcon />
    </PermissionedButton>
  );
};

const ResetPayerIdPaymentButton = ({
  trialOptionId,
}: {
  trialOptionId?: number;
}) => {
  const { t } = useTranslation('translations');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [mutation, { loading }] = useResetUserPaymentCredentialsMutation();

  if (!trialOptionId) return null;

  const handleResetPayerCredentials = async () => {
    const resp = await mutation({
      variables: {
        trialOptionId,
      },
    });

    setShowConfirmationDialog(false);
    if (resp.data?.resetUserPaymentCredentials.success) {
      showAlertMessage(
        t('trialOptionPayments.reset.successAlert'),
        StatusColor.Green
      );
    } else {
      showAlertMessage(
        t('trialOptionPayments.reset.errorResetAlert'),
        StatusColor.Red
      );
    }
  };

  return (
    <>
      <SafeDialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <ConfirmationInDialog
          title={t('trialOptionPayments.reset.confirmTitle')}
          message={t('trialOptionPayments.reset.confirmMsg')}
          onConfirm={handleResetPayerCredentials}
          onClose={() => setShowConfirmationDialog(false)}
        />
      </SafeDialog>
      <PermissionedButton
        disabled={loading}
        permission={RolePermissions.ResetParticipantPayerCredentials}
        variant='outlined'
        color='primary'
        buttonText={t('trialOptionPayments.reset.resetBtn')}
        onClick={() => setShowConfirmationDialog(true)}
      />
    </>
  );
};

function TrialOptionPayments(props: Props) {
  const { t } = useTranslation('translations');
  const { data, loading, refetch } = useTrialOptionPaymentsQuery({
    variables: { trialOptionId: props.trialOptionId },
  });

  const [
    showManualPaymentDialog,
    setShowManualPaymentDialog,
  ] = useState<boolean>(false);

  const [selectedPayment, setSelectedPayment] = useState<Payment | undefined>();
  const history = useHistory();

  const openVisit = (payment: Payment) => {
    history.push(
      `/u/data/${props.trialOptionId}/${payment.studyPlanVisitSlug}/review`
    );
  };

  const openInPaypal = (context: any) => {
    window.open(linkToPaypalTx(context.paypalTransactionId), '_blank');
  };

  const getListItemProps = (payment: Payment) => {
    const buttons: React.ReactNode[] = [];
    const { context } = payment;

    if (payment.visitId && payment.studyPlanVisitSlug) {
      buttons.push(
        <StandardButton onClick={() => openVisit(payment)}>
          {t('trialOptionPayments.openVisitBtn')}
        </StandardButton>
      );
    }

    if (context?.paypalTransactionId) {
      buttons.push(
        <StandardButton onClick={() => openInPaypal(context)}>
          {t('trialOptionPayments.openInPaypalBtn')}
        </StandardButton>
      );
    }

    if (isFailed(payment.status as PaymentStatus)) {
      buttons.push(
        <PermissionedButton
          disabled={false}
          permission={RolePermissions.CreateAdHocPayment}
          variant='text'
          color='primary'
          buttonText={t('trialOptionPayments.forcePayoutBtn')}
          onClick={() => {
            setSelectedPayment(payment);
            setShowManualPaymentDialog(true);
          }}
        />
      );
    }

    return {
      status: {
        type: mapPaymentStatusToListItemStatus(payment.status as PaymentStatus),
      },
      middle: {
        title: title(payment, t),
        text: paymentInfo(payment, t),
        buttons,
      },
    };
  };

  if (loading || !data) {
    return <Loading />;
  }

  const trialOption = data.getTrialOption;
  const { payments } = trialOption;

  const showPaymentFeedback = (response, successMessage: string) => {
    if (Object.keys(response).includes('error')) {
      showAlertMessage(response.error, StatusColor.Red);
    } else {
      refetch();
      showAlertMessage(successMessage, StatusColor.Green);
    }

    setShowManualPaymentDialog(false);
  };

  const onSubmitCreatePayment = async (submitData: any) => {
    if (selectedPayment) {
      const response = await forcePayout(
        submitData.reason,
        selectedPayment.id,
        props.trialOptionId
      );

      showPaymentFeedback(
        response,
        t('trialOptionPayments.paymentScheduledAlert')
      );
    } else {
      const response = await createAdhocPayment(
        submitData.payment,
        submitData.reason,
        props.trialOptionId
      );

      showPaymentFeedback(response, t('trialOptionPayments.adHocCreatedAlert'));
    }
  };

  const handleAdHocPayment = () => {
    setSelectedPayment(undefined);
    setShowManualPaymentDialog(true);
  };

  return (
    <>
      <Subheader
        text={t('trialOptionPayments.allParticipantPaymentsText')}
        buttons={[
          <ResetPayerIdPaymentButton trialOptionId={props.trialOptionId} />,
          <MakeAddHocPaymentButton onClick={handleAdHocPayment} />,
        ]}
      />
      <div className='li-multi-container inset'>
        {payments?.map((payment, index) => {
          //A status of CANCELED means this payment was invalidly triggered, so do not display it in the Web App.
          if (payment.status === PaymentStatus.Canceled) return null;
          const { context } = payment;
          return (
            // @ts-ignore
            <ListItem key={index} {...getListItemProps(payment)}>
              {context.paypalBatchId && (
                <p>
                  <strong>{t('trialOptionPayments.paypalIdLabel')}</strong>{' '}
                  {context.paypalBatchId}
                </p>
              )}
              {context.paypalTransactionId && (
                <p className='payment-transaction-link'>
                  <strong>{t('trialOptionPayments.paypalTransLabel')}</strong>{' '}
                  {context.paypalTransactionId}
                </p>
              )}
            </ListItem>
          );
        })}

        {!(payments && payments.length > 0) && (
          <ListItem
            // @ts-ignore
            middle={{
              title: t('trialOptionPayments.noPaymentMessage'),
            }}
          />
        )}
      </div>

      <CreatePaymentDialog
        trialOption={trialOption as TrialOption}
        payment={selectedPayment}
        open={showManualPaymentDialog}
        onClose={() => setShowManualPaymentDialog(false)}
        onSubmit={onSubmitCreatePayment}
      />
    </>
  );
}

export default TrialOptionPayments;
