import { DocumentConfiguration } from '../types';
import {
  DocumentAction,
  DocumentSigningRole,
  DocumentType,
  Pii_Status,
  SignedDocument,
  SignedDocumentStatus,
  DocumentAttestation,
} from '../types';
import { getStateFromZipcode, States } from './zipcode';

export function displayTypeForDocument(
  resourceType: string,
  t: any = (a: any, b: any) => b || a
): string {
  // @ts-ignore
  return t(
    `documentsDisplayType.${resourceType}`,
    displayTypeForDocumentMapping[resourceType] || ''
  );
}

export function displayDescriptionForDocument(
  resourceType: string,
  t: any = (a: any, b: any) => b || a
): string {
  return t(
    `documentDescription.${resourceType}`,
    descriptionForDocumentMapping[resourceType] || ''
  );
}

export function shouldDoBoR(zipcode?: string | null): boolean {
  return !!zipcode && getStateFromZipcode(zipcode).abbreviation === States.CA;
}

/**
 * Extract the document(s) that match a certain version.
 *
 * @param signedDocuments
 * @param documentVersion
 * @returns
 */
export const signedDocumentOfVersion = (
  signedDocuments: ReadonlyArray<any>,
  documentVersion: string | null
) => {
  return (
    signedDocuments.find(
      doc => !!doc && doc?.documentConfiguration?.version === documentVersion
    ) ?? null
  );
};

export function isDocumentType(value: any) {
  return Object.values(DocumentType).includes(value);
}

export const signedDocumentOfType = <T extends { documentType: string }>(
  signedDocuments: ReadonlyArray<T> | undefined | null,
  documentType: string
): T | null =>
  signedDocuments?.find(doc => !!doc && doc.documentType === documentType) ??
  null;

export function signableDocumentForType<T extends { documentType: string }>(
  documentsForTrial: ReadonlyArray<T>,
  requestedDocumentType: string
) {
  return documentsForTrial.find(
    ({ documentType }) => documentType === requestedDocumentType
  );
}

export const documentOrderDefault: string[] = [
  DocumentType.InformedConsent,
  DocumentType.OpenLabelExtensionInformedConsent,
  DocumentType.Hipaa,
  DocumentType.MedicalRecordRelease,
  DocumentType.BillOfRights,
  DocumentType.Enrollment,
  DocumentType.MinorAssent,
];

export function signingActionToSigningRole(
  action: DocumentAction
): DocumentSigningRole {
  switch (action) {
    case DocumentAction.AcquireParticipantSignature:
    case DocumentAction.DeclinedParticipantSignature:
      return DocumentSigningRole.Participant;
    case DocumentAction.MakeNotApplicableToSign:
      return DocumentSigningRole.Participant;
    case DocumentAction.ProvideCounterSignature:
      return DocumentSigningRole.Countersigner;
    case DocumentAction.UnlockParticipantSigning:
      throw new Error('No DocumentSigningAction for DocumentAction: ' + action);
    default: {
      throw new Error('Invalid DocumentAction: ' + action);
    }
  }
}

const displayTypeForDocumentMapping: Record<string, string> = {
  INFORMED_CONSENT: 'Informed consent',
  OPEN_LABEL_EXTENSION_INFORMED_CONSENT:
    'Open-Label Extension Informed Consent',
  ENROLLMENT: 'Enrollment',
  HIPAA: 'HIPAA Authorization',
  MEDICAL_RECORD_RELEASE: 'Medical Record Release',
  BILL_OF_RIGHTS: "Experimental Subject's Bill of Rights",
  MINOR_ASSENT: 'Minor Assent',
};

const descriptionForDocumentMapping: Record<string, string> = {
  INFORMED_CONSENT:
    'Information about the study for the participant. A healthcare provider/researcher must be available to answer any questions about the trial.',
  HIPAA:
    'Details about handling of protected health information related to the study.',
  MEDICAL_RECORD_RELEASE:
    'Medical record release document that will be used to obtain information about the participants medical history',
  BILL_OF_RIGHTS:
    'A list of rights for medical research participants in the state of California.',
  MINOR_ASSENT: '',
};

export function unsignedConsentDocuments(trialOption: {
  signedDocuments: ReadonlyArray<
    Pick<SignedDocument, 'documentType' | 'status'>
  >;
  piiStatus: Pii_Status;
}): string[] {
  switch (trialOption.piiStatus) {
    case Pii_Status.DisabledLostToFollowUp:
    case Pii_Status.DisabledPatientDeceased:
    case Pii_Status.DisabledDeIdentifiedPatient:
      return [];
    case Pii_Status.Enabled: {
      const { Completed, Declined, NotApplicable } = SignedDocumentStatus;
      const documents = trialOption.signedDocuments.map(
        signedDocument => signedDocument.documentType
      );
      return documents.filter(documentType => {
        const signedDocument = signedDocumentOfType(
          trialOption.signedDocuments,
          documentType
        );
        return (
          !signedDocument ||
          (signedDocument.status !== Completed &&
            signedDocument.status !== Declined &&
            signedDocument.status !== NotApplicable)
        );
      });
    }
    default: {
      const n: never = trialOption.piiStatus;
      throw new Error(`Bad PII_STATUS: ${n}`);
    }
  }
}

export function firstUnsignedConsentDocument(
  trialOption: Parameters<typeof unsignedConsentDocuments>[0] //type of first argument to unsignedConsentDocuments
): string | null | undefined {
  const documents = unsignedConsentDocuments(trialOption);
  return documents[0];
}
export function finishedInformedConsent(
  trialOption: Parameters<typeof unsignedConsentDocuments>[0] //type of first argument to unsignedConsentDocuments
): boolean {
  return !firstUnsignedConsentDocument(trialOption);
}

export function getAttestationsByRole(
  documentAttestations:
    | Array<DocumentAttestation>
    | ReadonlyArray<DocumentAttestation>,
  role: DocumentSigningRole
) {
  return documentAttestations
    .filter(at => at.roles.includes(role))
    .reduce<string[]>(
      (attestations, attestation) =>
        attestations.concat(attestation.attestations),
      []
    );
}

export function getAttestationsByAction(
  documentAttestations:
    | Array<DocumentAttestation>
    | ReadonlyArray<DocumentAttestation>,
  action: DocumentAction
) {
  return getAttestationsByRole(
    documentAttestations,
    signingActionToSigningRole(action)
  );
}

export function isMinorAssentDocument(documentType: string) {
  return documentType === DocumentType.MinorAssent;
}

export function hasMinorAssentDocument<
  T extends
    | Pick<DocumentConfiguration, 'documentType'>
    | Readonly<Pick<DocumentConfiguration, 'documentType'>>
>(documentsForTrial: T[]) {
  return !!documentsForTrial.find(
    doc => doc.documentType === DocumentType.MinorAssent
  );
}
