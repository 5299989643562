import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import Analytics, { AnalyticsEventType } from '../../Analytics/Analytics';
import SearchBar from '../SearchBar';
import Subheader from '../Subheader';
import ListItem from '../ListItem';
import { fetchTrialSearchResults } from '../../../controllers/trialController';
import { useTrialPickerResultsQuery } from 'src/types';
import { TFunction, useTranslation, withTranslation } from 'react-i18next';
import { Tab, Tabs } from '@material-ui/core';

interface TrialPickerResultsViewProps {
  trialIds: number[] | null | undefined;
  onPicked: (
    trialId: number,
    trialObject?: {
      [key: string]: any;
    }
  ) => any;
  preferences?: {
    inset?: boolean;
    showDeactivated?: boolean;
  };
}

const TrialPickerResults: React.FC<TrialPickerResultsViewProps> = props => {
  const { t } = useTranslation('translations');
  const { preferences, trialIds } = props;
  const { data, loading } = useTrialPickerResultsQuery({
    variables: {
      trialIds,
      deactivated: props.preferences?.showDeactivated,
    },
  });

  if (!data || loading) {
    return (
      <div className='li-multi-container inset'>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
          <div className='li-container'>
            {' '}
            <Skeleton className='li-middle' height={60} />
          </div>
        ))}
      </div>
    );
  }

  const trials = [...(data?.getTrials ?? [])].sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  return (
    <div
      className={`li-multi-container ${
        preferences && preferences?.inset ? 'inset' : ''
      }`}
    >
      {!(trials && trials.length > 0) && (
        <ListItem middle={{ title: t('pickers.trialPicker.notFoundLabel') }} />
      )}
      {trials.map((trial, i) => (
        <ListItem
          key={i}
          middle={{
            title: trial.name,
            text: trial.trialSummary.shortDescription,
          }}
          onClick={() => props.onPicked(trial.id, trial)}
        />
      ))}
    </div>
  );
};

type Props = {
  onPicked: (trialId: number) => any;
  restrictToClinicId?: string;
  preferences?: {
    inset?: boolean;
  };
  subheaderButton?: React.ReactElement<any>;
  hideSubHeaderButtonForDeactivateTrials?: boolean;
  subheaderTextOverride?: string;
  t: TFunction;
};

const TrialPicker: React.FC<Props> = props => {
  const {
    onPicked,
    preferences,
    subheaderButton,
    subheaderTextOverride,
    restrictToClinicId,
    t,
  } = props;
  const [trialIds, setTrialIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [currentTab, setCurrenTab] = useState<number>(0);
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
  const [hideButtons, setHideButtons] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      Analytics.track(AnalyticsEventType.TRIAL_SEARCH);
    }
    fetchTrialSearchResults(searchQuery || '', restrictToClinicId).then(
      trials => {
        setTrialIds(trials.map(trial => trial.trialId));
      }
    );
  }, [showDeactivated, searchQuery, restrictToClinicId]);

  return (
    <div className='trial-picker-container'>
      <div className='search-bar-container'>
        <SearchBar
          placeholder={t('pickers.trialPicker.searchPlaceholder')}
          onChange={newValue => setSearchQuery(newValue)}
        />
      </div>

      <Tabs
        indicatorColor={showDeactivated ? 'secondary' : 'primary'}
        value={currentTab}
        onChange={(event, newTab) => {
          setCurrenTab(newTab);
          setShowDeactivated(newTab !== 0);
          if (props.hideSubHeaderButtonForDeactivateTrials)
            setHideButtons(!showDeactivated);
        }}
      >
        <Tab label={t('associateTrialView.activateBtn')} />
        <Tab label={t('associateTrialView.deactivatedLabel')} />
      </Tabs>

      <Subheader
        text={subheaderTextOverride || t('pickers.trialPicker.resultsLabel')}
        buttons={
          subheaderButton && !hideButtons ? [subheaderButton] : undefined
        }
      />
      <TrialPickerResults
        trialIds={trialIds}
        onPicked={onPicked}
        preferences={{
          inset: preferences?.inset,
          showDeactivated: showDeactivated,
        }}
      />
    </div>
  );
};

export default withTranslation('translations')(TrialPicker);
