import _find from 'lodash/find';
import filter from 'lodash/fp/filter';
import flatMap from 'lodash/fp/flatMap';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import _pick from 'lodash/pick';
import React, { useMemo } from 'react';

import { VisitOption } from '../../../lib/booker';
import { getViewPatientBaseUrl } from '../../../lib/users';
import { ParticipantDashboardContext } from '../../ParticipantInterface/ParticipantDashboard';
import { TrialOptionStudyPlanContext } from '../../TrialOption/TrialOptionStudyPlan';
import Booker, { NULL_ZIPCODE } from './Booker';

import {
  AcceptedCountryCodes,
  SchedulingConfiguration,
  User,
  VisitMethod,
  VisitSiteAssignmentMethod,
} from '@curebase/core/types';
import { useHistory, useParams } from 'react-router-dom';
import AcuityCancelRescheduleAppointmentModal from 'src/components/Acuity/AcuityCancelRescheduleAppointmentModal';
import EmbeddedScheduler from 'src/components/Acuity/EmbeddedScheduler';
import LightDialog from 'src/components/basic/LightDialog';
import { getLocale } from 'src/context/localeContext';
import { SchedulingSystem } from 'src/types';
import { currentUserClinicIds } from '../../../lib/auth';
import { useBookerRootQuery } from '../../../types';
import Loading from '../../Loading';
import { AxleHealthBooker } from './AxleHealthBooker';

interface BookerRootProps {
  asModal?: boolean;
  open?: boolean;
  trialOptionId: number;
  refetchDashboards: () => Promise<void>;
  onClose?: () => Promise<void>;
}

interface BookerRootContext {
  config: Omit<SchedulingConfiguration, 'visitBookings'> | null;
}
export const BookerRootContext = React.createContext<BookerRootContext>({
  config: null,
});

export const BookerRoot = (props: BookerRootProps) => {
  const { trialOptionId, refetchDashboards } = props;
  const { locale } = getLocale();
  const { configId, action } = useParams<{
    configId: string;
    action: 'cancel' | 'reschedule';
  }>();
  const { data, refetch } = useBookerRootQuery({
    fetchPolicy: 'cache-first',
    skip: !configId,
    variables: {
      trialOptionId,
      schedulingConfigurationId: configId!,
    },
  });
  const refetchAll = async () =>
    void (await Promise.all([refetchDashboards(), refetch()]));

  // Calculate the Booker Props
  const trialOption = data?.getTrialOption;
  const schedulingConfig =
    data?.getSchedulingConfiguration.locales?.[locale] ||
    data?.getSchedulingConfiguration;
  const trialInstances = useMemo(
    () => data?.getTrialOption.trial.instances ?? [],
    [data]
  );
  const acuityAppointmentId = trialOption?.visitBooking?.acuityAppointmentId;

  const existingBookingVisitSiteId = trialOption?.visitSite?.id;

  const limitToClinicIds = (() => {
    if (
      existingBookingVisitSiteId &&
      trialOption &&
      trialOption.trial.visitSiteAssignmentMethod ===
        VisitSiteAssignmentMethod.Zipcode
    ) {
      const trialInstance = trialInstances.find(trialInstance => {
        const foundVisitSite = trialInstance.clinic.visitSites?.find(
          visitSite => visitSite.id === existingBookingVisitSiteId
        );

        return foundVisitSite;
      });

      return [trialInstance?.clinic.id] || [];
    }

    return currentUserClinicIds();
  })();

  type TrialInstance = typeof trialInstances[number];

  const visitMethods: VisitMethod[] = Object.entries(
    schedulingConfig?.default.methods ?? {}
  ).reduce<VisitMethod[]>((acc, cur) => {
    const [key, value] = cur;
    switch (key) {
      case 'virtual':
        return value ? [...acc, VisitMethod.Virtual] : acc;
      case 'inPersonClinic':
        return value ? [...acc, VisitMethod.InPerson] : acc;
      case 'atHome':
        return value ? [...acc, VisitMethod.AtHome] : acc;
      default:
        return acc;
    }
  }, []);

  const visitOptions: VisitOption[] = useMemo(
    () =>
      pipe(
        filter<TrialInstance>(trialInstance =>
          limitToClinicIds.length > 0
            ? limitToClinicIds.includes(trialInstance.clinic.id)
            : true
        ),
        flatMap(
          x =>
            x.clinic.visitSites?.map(v => ({ ...v, trialInstanceId: x.id })) ??
            []
        ),
        filter(
          visitSite =>
            !!visitSite?.visitAvailabilities &&
            visitSite?.visitAvailabilities?.length > 0
        ),
        map(x => ({
          visitMethods,
          address: _pick(x, [
            'zipcode',
            'country',
            'addressLine1',
            'addressLine2',
            'city',
            'county',
            'state',
          ]),
          siteName: x.name,
          visitSiteId: x.id,
          trialInstanceId: x.trialInstanceId,
          timezone: x.timezone,
          deactivated: x.deactivated,
        }))
      )(trialInstances),
    [limitToClinicIds, trialInstances, visitMethods]
  );

  if (!data || !(trialOption && trialOption.patient)) {
    return <Loading />;
  }

  const { schedulingSystem } = data.getSchedulingConfiguration.default;

  switch (schedulingSystem) {
    case SchedulingSystem.AcuityScheduling: {
      return (
        <BookerRootContext.Provider
          value={{
            config: schedulingConfig ?? null,
          }}
        >
          {action === 'cancel' && (
            <AcuityCancelRescheduleAppointmentModal
              trialOptionId={trialOptionId}
              acuityAppointmentId={acuityAppointmentId}
              action={'cancel'}
              onClose={props.onClose}
            />
          )}
          {action === 'reschedule' && (
            <AcuityCancelRescheduleAppointmentModal
              trialOptionId={trialOptionId}
              acuityAppointmentId={acuityAppointmentId}
              action={'reschedule'}
              onClose={props.onClose}
            />
          )}
          {action !== 'cancel' && action !== 'reschedule' && (
            <div className='booker'>
              <div className='booker-text-container'>
                <EmbeddedScheduler
                  trialOptionId={trialOptionId}
                  schedulingConfigurationId={data.getSchedulingConfiguration.id}
                  onClose={props.onClose}
                />
              </div>
            </div>
          )}
        </BookerRootContext.Provider>
      );
    }
    case SchedulingSystem.CurebaseScheduling: {
      const existingBookingVisitOption = existingBookingVisitSiteId
        ? _find(
            visitOptions,
            vo => vo.visitSiteId === existingBookingVisitSiteId
          )
        : undefined;

      return (
        <BookerRootContext.Provider
          value={{
            config: schedulingConfig ?? null,
          }}
        >
          <Booker
            availableMethods={visitMethods}
            trialOptionId={trialOption.id}
            trialSlug={trialOption.trial.slug}
            patientAddress={{
              zipcode: trialOption.patient.zipcode ?? NULL_ZIPCODE,
              country:
                (trialOption.patient.user
                  .countryCode as AcceptedCountryCodes) ??
                AcceptedCountryCodes.Us,
            }}
            sponsorUsers={
              trialOption.trial.sponsor?.roles?.map(role => role.user) as User[]
            }
            refetch={refetchAll}
            visitOptions={visitOptions}
            onVisitStatusUpdate={refetchAll}
            existingBookingVisitOption={existingBookingVisitOption}
            onClose={props.onClose}
            allowSpectator={
              schedulingConfig?.default?.allowSpectator || undefined
            }
          />
        </BookerRootContext.Provider>
      );
    }

    case SchedulingSystem.AxleHealthScheduling: {
      const base = getViewPatientBaseUrl(trialOptionId);
      return (
        <BookerRootContext.Provider
          value={{
            config: schedulingConfig ?? null,
          }}
        >
          <div className='booker'>
            <AxleHealthBooker
              trialOptionId={trialOptionId}
              refetch={refetchAll}
              baseUrl={base}
            />
          </div>
        </BookerRootContext.Provider>
      );
    }

    default: {
      const n: never = schedulingSystem;
      throw new Error(`[BookerDialog] - unhandled SchedulingSystem ${n}`);
    }
  }
};

interface BookerDialogProps {
  isOpen: boolean;
  trialOptionId: number;
}

const BookerDialog = (props: BookerDialogProps) => {
  const { isOpen, trialOptionId } = props;
  const { configId } = useParams<{
    configId: string;
  }>();
  const history = useHistory();
  const { refetch: trialOptionStudyPlanRefetch } = React.useContext(
    TrialOptionStudyPlanContext
  );
  const { refetch: participantDashboardRefetch } = React.useContext(
    ParticipantDashboardContext
  );
  const refetchDashboards = async () =>
    void (await Promise.all([
      trialOptionStudyPlanRefetch(),
      participantDashboardRefetch(),
    ]));
  const onClose = async () => {
    const event = new CustomEvent<{ configId: string }>(
      'booker-dialog-closed',
      { detail: { configId } }
    );
    window.dispatchEvent(event);
    refetchDashboards();
    history.push(`${getViewPatientBaseUrl(trialOptionId)}`);
  };

  return (
    <LightDialog open={isOpen} onClose={onClose}>
      <BookerRoot
        asModal
        open={isOpen}
        trialOptionId={trialOptionId}
        refetchDashboards={refetchDashboards}
        onClose={onClose}
      />
    </LightDialog>
  );
};

export default BookerDialog;
