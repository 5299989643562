import type { Action } from '../actions';

export type PatientModeState =
  | 'NOT_IN_PATIENT_MODE'
  | 'SHOW_ENTER_DIALOG'
  | 'IN_PATIENT_MODE'
  | 'SHOW_EXIT_DIALOG';

export type PatientModeSettings = {
  baseRoute: string;
  successRoute: string;
} | null;

export type TPatientModeState = {
  state: PatientModeState;
  enforcedSettings: PatientModeSettings;
  pendingSettings: PatientModeSettings;
  exitCallback: null | (() => void);
};

const defaultPatientModeState = {
  state: 'NOT_IN_PATIENT_MODE',
  enforcedSettings: null,
  pendingSettings: null,
  exitCallback: null,
};

export function patientMode(
  // @ts-ignore
  state: TPatientModeState = defaultPatientModeState,
  action: Action
) {
  switch (action.type) {
    case 'PATIENT_MODE_PENDING_SET':
      return {
        state: 'SHOW_ENTER_DIALOG',
        pendingSettings: action.pendingSettings,
        enforedSettings: null,
        exitCallback: null,
      };
    case 'PATIENT_MODE_PENDING_CLEAR':
      return {
        state: 'SHOW_EXIT_DIALOG',
        enforcedSettings: state.enforcedSettings,
        pendingSettings: null,
        exitCallback: action.exitCallback,
      };
    case 'SET_PATIENT_MODE':
      return {
        state: 'IN_PATIENT_MODE',
        enforcedSettings: state.pendingSettings || state.enforcedSettings,
        pendingSettings: state.pendingSettings,
        exitCallback: null,
      };
    case 'CLEAR_PATIENT_MODE':
      return defaultPatientModeState;
    case 'CLEAR_USER':
      return defaultPatientModeState;
    default:
      return state;
  }
}
