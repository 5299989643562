import { useParams } from 'react-router-dom';
import Header from '../../basic/Header';
import Subheader from '../../basic/Subheader';
import ListItem from '../../basic/ListItem';
import { formatAddress } from '@curebase/core/lib/clinics';
import { useClinicsQuery } from 'src/types';
import Loading from 'src/components/Loading';

function ClinicsView() {
  const params: { trialId: string } = useParams();
  const { data, loading } = useClinicsQuery({
    variables: { trialId: parseInt(params.trialId) },
  });

  if (!data || loading) {
    return <Loading />;
  }
  const trial = data.getTrial;

  return (
    <div>
      <Header displayText={`Clinics participating in ${trial.name}`} />
      <Subheader text='Participating clinics' />
      {!(trial.instances && trial.instances.length > 0) && (
        <ListItem
          middle={{
            title: 'There are no clinics participating in this trial',
            text:
              'Contact Curebase to invite clinics to join your trial so they can begin screening patients',
          }}
        />
      )}
      {trial.instances &&
        trial.instances.map((instance, i) => {
          const clinic = instance.clinic;
          return (
            <ListItem
              key={i}
              middle={{
                title: clinic.name,
                subtitle: formatAddress(clinic),
              }}
            />
          );
        })}
    </div>
  );
}

export default ClinicsView;
