// import { SliderThumb } from '@material-ui/core/Slider';
import { ReactComponent as ThumbIcon } from './thumb-icon.svg';
import { ReactComponent as ThumbIconLight } from './thumb-icon-light.svg';
import styles from './CSliderThumb.module.css';

interface CThumbProps extends React.HTMLAttributes<unknown> {
  orientation: 'horizontal' | 'vertical';
}
/**
 * This is custom thumb for the slider which uses a custom curebase icon.
 * This wrapper allows the icon to be dynamically rotated depending
 * on whether the slider is horizontally or vertically oriented.
 */
export const CSliderThumb = (props: CThumbProps) => {
  const { children, orientation, ...rest } = props;
  const isFocused = rest?.className?.includes('Mui-focusVisible');
  const ThumbComponent = isFocused ? ThumbIconLight : ThumbIcon;
  return (
    <div {...props}>
      {children}
      <ThumbComponent
        className={orientation === 'vertical' ? styles.thumbVertical : ''}
      />
    </div>
  );
};

interface CVerticalThumbComponentProps extends React.HTMLAttributes<unknown> {}
const CVerticalThumb = (props: CVerticalThumbComponentProps) => (
  <CSliderThumb orientation='vertical' {...props} />
);
export { CVerticalThumb };

export default CSliderThumb;
