import { Skeleton } from '@material-ui/lab';
import React, { useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Either';
import ListItem from '../basic/ListItem';
import Dialog from '../basic/SafeDialog';
import DialogWithChildren from '../basic/DialogWithChildren';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import Subheader from '../basic/Subheader';
import VisitBookingLI from './VisitBookingLI';
import VisitAvailabilityLI from './VisitAvailabilityLI';
import CreateAvailabilityDialog from './CreateAvailabilityDialog';
import EditAvailabilityDialog from './EditAvailability';
import {
  addVisitAvailability,
  deleteVisitAvailability,
} from '../../controllers/schedulingController';
import {
  parseRecurringAvailabilityForInterval,
  rruleToHumanReadableString,
} from '@curebase/core/lib/scheduling';
import { AddAvailabilityRequest } from '@curebase/modules/scheduling/dto/scheduling';
import { useSiteAvailabilityQuery } from 'src/types';
import { useTranslation } from 'react-i18next';

type Props = {
  history: any;
  withBookings: boolean;
};

const DeleteWindowConfirmationDialog = (props: any) => {
  const { t } = useTranslation('translations');
  const {
    open,
    onClose,
    refetch,
    visitAvailability: { visitBookings: bookings },
    visitAvailability: window,
  } = props;
  const message = (
    <>
      <p>{t('siteAvailability.deleteWindow.questionText')}</p>
      {window.rrule ? (
        <h5>
          {t('siteAvailability.deleteWindow.warningText', {
            rrule: rruleToHumanReadableString(window.rrule, window.originalEnd),
          })}
        </h5>
      ) : bookings.length > 0 ? (
        <p>
          {bookings.length}{' '}
          {t('siteAvailability.deleteWindow.appointmentsRescheduleText')}
        </p>
      ) : (
        <p>{t('siteAvailability.deleteWindow.noAppointmentsText')}</p>
      )}
    </>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <ConfirmationInDialog
        title={t('siteAvailability.deleteWindow.title')}
        message={message}
        onClose={onClose}
        onConfirm={async () => {
          await deleteVisitAvailability(window.id);
          refetch();
          onClose();
        }}
      />
    </Dialog>
  );
};

const instancesToWindows = (
  instances: ReadonlyArray<any> | null | undefined,
  start,
  end
) =>
  (instances ?? [])
    .reduce(
      (windows, { visitAvailabilities, trial: { name: trialName } }) =>
        windows.concat(
          visitAvailabilities.reduce((arr, window) => {
            if (!window.rrule?.byDay) {
              arr.push({ ...window, trialName });
              return arr;
            }

            const windowWithUnix = {
              ...window,
              start: DateTime.fromISO(window.start).toMillis(),
              end: DateTime.fromISO(window.end).toMillis(),
            };

            const generatedWindows = parseRecurringAvailabilityForInterval(
              windowWithUnix,
              start,
              end
            ).map(parsedWindow => ({
              ...parsedWindow,
              trialName,
            }));

            return arr.concat(generatedWindows);
          }, [])
        ),
      []
    )
    .sort(({ start: a }, { start: b }) => a - b);

/**
 * MAIN COMPONENT
 *
 */
const SiteAvailability = (props: Props) => {
  const { t } = useTranslation('translations');
  const [selectedTab, selectTab] = useState('THIS_WEEK');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [availabilityDialogOpen, setAvailabilityDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bookings, setBookings] = useState<any[]>([]);
  const { visitSiteId } = useParams<{ visitSiteId: string }>();

  const { data, refetch, loading } = useSiteAvailabilityQuery({
    skip: !visitSiteId,
    variables: {
      visitSiteId: parseInt(visitSiteId ?? ''),
      withVisitBooking: props.withBookings,
    },
    fetchPolicy: 'network-only',
  });

  const visitSite = data?.getVisitSite;
  const timezone = visitSite?.timezone ?? moment.tz.guess();
  const instances = useMemo(() => visitSite?.clinic.instances ?? [], [
    visitSite,
  ]);

  //return timezone-aware (clinic perspective) moment obj
  const momentTz = (time?: number) => moment(time).tz(timezone);

  const startTime = useRef(moment().tz(timezone).startOf('week'));
  const endTime = useRef(startTime.current.clone().endOf('week'));
  const [selectedWindow, setSelectedWindow] = useState<any>(null);
  const schedulingConfig = useMemo(
    () =>
      (selectedWindow &&
        data?.getVisitSite?.clinic?.instances?.find(
          x => x.trial.name === selectedWindow?.trialName
        )?.trial.schedulingConfigurations) ??
      [],
    [data, selectedWindow]
  );

  const windowsInTime = useMemo(() => {
    const windows = instancesToWindows(
      instances,
      startTime.current,
      endTime.current
    );
    return windows;
  }, [instances, startTime.current, endTime.current]);

  if (loading)
    return (
      <div className='li-multi-container inset'>
        {[1, 2, 3, 4].map(() => (
          <Skeleton height={100} />
        ))}
      </div>
    );

  const filteredWindows = windowsInTime
    .filter(
      ({ start, end }) =>
        startTime.current.isSameOrBefore(start) &&
        endTime.current.isSameOrAfter(start)
    )
    .sort(({ start: a }, { start: b }) => a - b);

  const tabs = {
    items: [
      { text: t('siteAvailability.tabs.TODAY'), value: 'TODAY' },
      { text: t('siteAvailability.tabs.THIS_WEEK'), value: 'THIS_WEEK' },
      { text: t('siteAvailability.tabs.THIS_MONTH'), value: 'THIS_MONTH' },
      { text: t('siteAvailability.tabs.NEXT_WEEK'), value: 'NEXT_WEEK' },
      { text: t('siteAvailability.tabs.NEXT_MONTH'), value: 'NEXT_MONTH' },
      { text: t('siteAvailability.tabs.ALL'), value: 'ALL' },
    ],
    value: selectedTab,
    onChange: (newValue: string) => {
      const now = moment().tz(timezone);
      switch (newValue) {
        case 'ALL':
          startTime.current = now.startOf('day');
          endTime.current = now.clone().add(10, 'years').endOf('day');
          break;
        case 'NEXT_MONTH':
          now.add(1, 'month');
          startTime.current = now.startOf('month');
          endTime.current = now.clone().endOf('month');
          break;
        case 'THIS_MONTH':
          startTime.current = now.startOf('day');
          endTime.current = now.clone().endOf('month');
          break;
        case 'NEXT_WEEK':
          now.add(1, 'week');
          startTime.current = now.startOf('week');
          endTime.current = now.clone().endOf('week');
          break;
        case 'THIS_WEEK':
          startTime.current = now.startOf('day');
          endTime.current = now.clone().endOf('week');
          break;
        case 'TODAY':
          startTime.current = now.startOf('day');
          endTime.current = now.clone().endOf('day');
          break;
        default:
          return;
      }
      selectTab(newValue);
    },
  };

  if (!visitSite) return null;

  return (
    <>
      {deleteDialogOpen && (
        <DeleteWindowConfirmationDialog
          visitAvailability={selectedWindow}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          refetch={refetch}
        />
      )}
      {editDialogOpen && (
        <EditAvailabilityDialog
          visitAvailability={selectedWindow}
          schedulingConfig={schedulingConfig}
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          timezone={timezone}
          refetch={refetch}
        />
      )}
      {availabilityDialogOpen && (
        <CreateAvailabilityDialog
          open={availabilityDialogOpen}
          onClose={() => setAvailabilityDialogOpen(false)}
          timezone={timezone}
          title={t('siteAvailability.createDialogTitle')}
          trials={instances.map(
            ({ id, trial: { name, schedulingConfigurations } }) => ({
              text: name,
              value: id,
              scheduledEvents: schedulingConfigurations.map(x => ({
                text: x.name,
                value: x.id,
              })),
            })
          )}
          onSubmit={(form: Record<string, any>) =>
            pipe(
              {
                ...form,
                maxConfirmedConcurrency: parseInt(form.maxConfirmedConcurrency),
                maxUnconfirmedConcurrency: parseInt(
                  form.maxUnconfirmedConcurrency
                ),
                aptDuration: parseInt(form.aptDuration),
                visitSiteId: visitSite.id,
              },
              AddAvailabilityRequest.decode,
              fold(
                async left => {
                  return false;
                },
                async right => {
                  return await addVisitAvailability(right);
                }
              ),
              async successPromise => {
                const success = await successPromise;
                if (success) setTimeout(() => refetch(), 500);
                return success;
              }
            )
          }
        />
      )}
      <DialogWithChildren
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={t('siteAvailability.appointmentsTitle')}
      >
        {dialogOpen &&
          bookings.map((booking, index) => (
            <VisitBookingLI
              {...booking}
              getMoment={momentTz}
              historyPush={props.history.push}
              clinicId={visitSite.clinic.id}
              key={index}
            />
          ))}
      </DialogWithChildren>

      <Subheader
        text={t('siteAvailability.availabilitySubheader')}
        subText={t('siteAvailability.subText')}
        menu={tabs}
        buttons={[
          <Button
            variant='contained'
            color='primary'
            onClick={() => setAvailabilityDialogOpen(true)}
          >
            {t('siteAvailability.addAvailabilityBtn')}
          </Button>,
        ]}
      />

      <div className='li-multi-container inset'>
        {filteredWindows.length > 0 ? (
          orderBy(filteredWindows, ['start'], ['asc']).map((window, index) => {
            const onClick =
              window.visitBookings?.length > 0
                ? () => {
                    const { visitBookings } = window;
                    setBookings(visitBookings);
                    setDialogOpen(true);
                  }
                : undefined;
            return (
              <VisitAvailabilityLI
                key={index}
                {...window}
                getMoment={momentTz}
                onClick={onClick}
                editFunc={() => {
                  setSelectedWindow(window);
                  setEditDialogOpen(true);
                }}
                deleteFunc={() => {
                  setSelectedWindow(window);
                  setDeleteDialogOpen(true);
                }}
              />
            );
          })
        ) : (
          <ListItem
            middle={{
              title: t('siteAvailability.noAavailabilityMsg'),
            }}
          />
        )}
      </div>
    </>
  );
};

export default SiteAvailability;
