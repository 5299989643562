import React from 'react';

interface CardOption {
  title: string;
  lines: (string | null | undefined)[];
  value: string;
}

interface CardRollProps {
  options: CardOption[];
  selectedValue: string;
  onChange: (arg0: string) => any;
}

class CardRoll extends React.Component<CardRollProps> {
  cardRollRef: any;
  cardRefs: any;

  constructor(props: CardRollProps) {
    super(props);
    const cardRefs: any[] = [];
    Array(props.options.length)
      .fill(undefined)
      .map(() => cardRefs.push(React.createRef()));
    this.cardRollRef = React.createRef();
    this.cardRefs = cardRefs;
  }

  onChangeCard = (v: string, i: number): void => {
    const { onChange } = this.props;
    const cardRollElement = this.cardRollRef.current;
    const cardElement = this.cardRefs[i].current;
    if (!(cardElement && cardRollElement)) {
      console.error(
        'Not autoscrolling the card roll because a reference was not found.'
      );
      return;
    }
    cardRollElement.scrollTo({
      top: 0,
      left: cardElement.offsetLeft - 18,
      behavior: 'smooth',
    });
    onChange(v);
  };

  render() {
    const { options, selectedValue } = this.props;
    return (
      <div className='card-roll' ref={this.cardRollRef}>
        {options.map((option: CardOption, i: number) => (
          <div
            key={i}
            ref={this.cardRefs[i]}
            className='cr-card'
            onClick={() => this.onChangeCard(option.value, i)}
          >
            <div className='cr-left'>
              <img
                className='cr-icon'
                src={
                  option.value === selectedValue
                    ? '/cardRollChecked.svg'
                    : '/cardRollNotChecked.svg'
                }
                alt='Icon'
              />
            </div>
            <div className='cr-right'>
              <div className='cr-right-top'>{option.title}</div>
              <div className='cr-right-bottom'>
                {option.lines.map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className='card-roll-right-buffer' />
      </div>
    );
  }
}

export default CardRoll;
