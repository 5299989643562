import {
  REMOTE_ACCESS_ITEM_MAGIC_LINK,
  REMOTE_ACCESS_ITEM_PRINT_VIEW,
} from '@curebase/core/lib/remoteAccessSharedHelpers';
import {
  RemoteCapture,
  RemoteCaptureQuery,
  RemoteCaptureQueryVariables,
} from '@curebase/core/types';
import React from 'react';
import { signin } from 'src/controllers/remoteAccessController';
import { setUserDataInStore } from '../../lib/auth';
import DynamicForm from '../basic/DynamicForm';
import { withBreakpoints } from '../hocs/WithBreakpoints';
import { InjectedProps, withQueryResult } from '../hocs/WithQueryResult';
import Loading from '../Loading';
import PageHeader from '../PageHeader';

interface Props
  extends InjectedProps<RemoteCaptureQueryVariables, RemoteCaptureQuery> {}

type State = {
  type?: string;
  loading: boolean;
  dynamicFormPages?: Array<Object>;
};

class RemoteCaptureComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { getRemoteAccessItem } = props.queryResult;
    if (!getRemoteAccessItem) {
      this.state = {
        loading: true,
      };
      return;
    }

    const { type } = getRemoteAccessItem.results;
    switch (type) {
      case REMOTE_ACCESS_ITEM_MAGIC_LINK:
      case REMOTE_ACCESS_ITEM_PRINT_VIEW:
        this.state = {
          loading: true,
          type,
        };
        break;
      default:
        break;
    }
  }

  componentDidMount = async () => {
    if (!this.state.type) return this.props.history.push('/');
    const { getRemoteAccessItem } = this.props.queryResult;
    if (getRemoteAccessItem) {
      const signinResponse = await signin(getRemoteAccessItem.token);
      if (signinResponse) {
        await setUserDataInStore(signinResponse.userId);
        const { redirectUrl } = signinResponse;
        this.props.history.push(redirectUrl ? redirectUrl : '/');
      }
    }
  };

  render() {
    if (this.state.loading) return <Loading />;
    const { type } = this.state;
    return (
      <>
        <PageHeader />
        {[
          REMOTE_ACCESS_ITEM_MAGIC_LINK,
          REMOTE_ACCESS_ITEM_PRINT_VIEW,
          // @ts-ignore
        ].includes(type) && <DynamicForm pages={this.state.dynamicFormPages} />}
      </>
    );
  }
}

export default withQueryResult(
  withBreakpoints(RemoteCaptureComponent as any),
  RemoteCapture,
  (props: any) => ({
    token: props.match.params.token,
  })
);
