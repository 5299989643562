import React from 'react';
import LabelIcon from '@material-ui/icons/Label';

interface BulletsProps {
  items: string[];
}

function Bullets(props: BulletsProps) {
  return (
    <div className='bullets-container'>
      {props.items.map((item, ix) => (
        <div key={ix} className='bullets-row'>
          <LabelIcon htmlColor='#444444' />
          <div className='bullets-row-right'>{item}</div>
        </div>
      ))}
    </div>
  );
}

export default Bullets;
