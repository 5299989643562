import { secureVideoUriFromFileName } from '../env';

const buildHtml = (src: string, label?: string) => {
  return `

<div style="display: flex; flex-direction: column; width: 300px; margin-top: 48px;">
  <img width="300" alt="${label || src}" src="${src}" />
  ${
    label
      ? `<div style="margin-top: 18px; font-weight: bold">${label}</div>`
      : ''
  }
</div>

  `;
};

function forExport(showdown: any) {
  const imgRegex = /!\[s3StaticImage\]\((.+?)\)/g;
  const imgWithLabelRegex = /!\[s3StaticImage (.+?)\]\((.+?)\)/g;
  showdown.extension('s3StaticImage', function () {
    return [
      {
        type: 'lang',
        filter: function (text: string) {
          return text
            .replace(imgWithLabelRegex, function (match, label, url) {
              const src = secureVideoUriFromFileName(url);
              return buildHtml(src, label);
            })
            .replace(imgRegex, function (match, url) {
              console.log(match);
              const src = secureVideoUriFromFileName(url);
              return buildHtml(src);
            });
        },
      },
    ];
  });
}

export default forExport;
