import { jsonPost } from '../lib/fetchHelpers';
import {
  SaveSiteConfigRequest,
  DeploySiteConfigRequest,
} from '@curebase/core/decoders/changemanagement';
import { SiteManagementConfig } from '../types';

export const saveSiteConfig = async (
  config: SiteManagementConfig,
  configName: string | null
) => {
  const body: SaveSiteConfigRequest = {
    config,
    configName,
  };
  try {
    const response = await jsonPost(
      '/changemanagement/sitemanagement/save',
      body
    );
    return Boolean(response);
  } catch (e) {
    return false;
  }
};

export const deploySiteConfig = async (siteId: string, configName: string) => {
  const body: DeploySiteConfigRequest = {
    configName,
    siteId,
  };
  try {
    const response = await jsonPost(
      '/changemanagement/sitemanagement/deploy',
      body
    );
    return Boolean(response);
  } catch (e) {
    return false;
  }
};
