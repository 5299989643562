import React, { useMemo, useState } from 'react';
import produce from 'immer';
import _pickBy from 'lodash/pickBy';
import { useHistory } from 'react-router-dom';
import DialogHeader from '../basic/DialogHeader';
import SignupForm, { ContactFormData } from '../basic/SignupForm';
import Dialog from '../basic/SafeDialog';
import Loading from '../Loading';
import { DuplicateEmailWarning } from './DuplicateEmailWarning';
import { addTrialOption } from '../../controllers/trialOptionController';
import {
  createStubUser,
  createDeceasedUser,
} from '../../controllers/userController';
import { getProviderClinicIds } from '../../lib/users';
import {
  CreateStubUserResponse,
  CreateNoPIIStubUserResponse,
} from '@curebase/core/decoders/users';
import { getUser } from '../../store';
import { useCreateStubParticipantQuery } from 'src/types';
import { SignupMethods, Pii_Status } from '@curebase/core/types';
import Maybe from 'graphql/tsutils/Maybe';
import { checkEmail } from '../../controllers/authController';
import { AddTrialOptionRequest } from '@curebase/core/decoders/trialOptions';
import { maybeNumber, maybeString } from 'src/utils/types.utils';
import { showAlertMessage } from '../../store/actions';
import { getUserAllowedTrials } from 'src/shared/lib/modelHelpers';
import { StatusColor } from 'src/shared/lib/colors';
import { useTranslation } from 'react-i18next';
import Analytics, {
  AnalyticsEventProperty,
  AnalyticsEventType,
} from '../Analytics/Analytics';
import { useSelector } from 'react-redux';
import { getTrialNameById } from 'src/store/user/selectors';

type Props = {
  onClose: () => void;
  open: boolean;
};

const sentinelId: string = '~NONE';
const getSignupConfig = (allPossibleTrials, user) => {
  let trialsToReturn: any[] = [];

  // if user has permissions for clinics, only return trials they have active trial instances for
  const usersClinicIds = getProviderClinicIds();
  if (usersClinicIds?.length > 0) {
    trialsToReturn = allPossibleTrials
      .filter(({ instances }) =>
        instances.some(({ clinic }) => usersClinicIds.includes(clinic.id))
      )
      .map(
        produce((trial: any) => {
          trial.instances = trial.instances.filter(({ clinic }) =>
            usersClinicIds.includes(clinic.id)
          );
          return trial;
        })
      );
  } else {
    trialsToReturn = getUserAllowedTrials(user, allPossibleTrials).map(
      produce(trial => {
        trial.instances.push({
          id: sentinelId,
          clinic: {
            id: sentinelId,
            name: 'None',
            visitSites: [{ name: 'None', id: sentinelId }],
          },
        });
        return trial;
      })
    );
  }

  return trialsToReturn.filter(trial =>
    trial?.allowedSignupMethods?.includes(SignupMethods.Internal)
  );
};

const CreateParticipantDialog = ({ onClose, open }: Props) => {
  const { t } = useTranslation('translations');
  const [piiStatus, setPiiStatus] = useState<Pii_Status>(Pii_Status.Enabled);
  const history = useHistory();
  const { data } = useCreateStubParticipantQuery({});
  const [showDuplicateEmailWarning, setShowDuplicateEmailWarning] = useState(
    false
  );
  const [existingUserId, setExistingUserId] = useState<Maybe<number>>();
  const [cachedSubmitData, setCachedSubmitData] = useState<
    Maybe<ContactFormData>
  >();
  const [errors, setErrors] = useState<Record<string, any> | undefined>();

  const user = getUser();
  const trials = data?.getTrials ?? [];
  const allPossibleTrials =
    piiStatus === Pii_Status.Enabled
      ? trials
      : trials.filter(t => !t.requiresPII);

  const trialsForSignup = useMemo(
    () => getSignupConfig(allPossibleTrials, user),
    [allPossibleTrials, user]
  );

  const getTrialById = useSelector(getTrialNameById);

  if (!data) return <Loading />;

  const onSignup = async data => {
    const { piiStatus, email } = data;

    const pushToNewDashOrClose = (
      response: CreateStubUserResponse | CreateNoPIIStubUserResponse
    ) => {
      if (response?.error) {
        if (response.errorDetails) {
          setErrors(response.errorDetails);
        } else {
          showAlertMessage(response?.error, StatusColor.Red);
        }
        return;
      }
      if (response.success) {
        // Event for when a user creates a new participant manually
        Analytics.track(
          AnalyticsEventType.PARTICIPANT_LIST_CREATE_PARTICIPANT,
          {
            [AnalyticsEventProperty.STUDY_NAME]: getTrialById(data.trialId),
          }
        );
        history.push(`/u/participants/${response.trialOptionId}`);
      } else {
        onClose();
      }
    };

    let response: CreateStubUserResponse | CreateNoPIIStubUserResponse;

    if (piiStatus !== Pii_Status.Enabled) {
      response = await createDeceasedUser(
        _pickBy(data, val => val && val !== sentinelId)
      );
      pushToNewDashOrClose(response);
    } else {
      const checkEmailResponse = await checkEmail(email);

      if (checkEmailResponse?.error) {
        showAlertMessage(checkEmailResponse?.error, StatusColor.Red);
        return;
      }

      const { isAvailable, userId } = checkEmailResponse;

      if (isAvailable) {
        const request = _pickBy(data, val => val && val !== sentinelId);
        response = await createStubUser(request);
        pushToNewDashOrClose(response);
      } else if (userId) {
        setExistingUserId(userId);
        setCachedSubmitData(data);
        setShowDuplicateEmailWarning(true);
      }
    }
  };

  const onSubmit = async () => {
    const filteredDetails = _pickBy(
      cachedSubmitData,
      val => val && val !== sentinelId
    );
    const {
      clinicId,
      visitSiteId,
      referralCode,
      trialId,
      zipcode,
      phoneNumber,
      locale,
    } = filteredDetails;

    if (trialId && zipcode && existingUserId && phoneNumber) {
      const requestBody: AddTrialOptionRequest = {
        trialId: parseInt(trialId.toString()),
        zipcode: zipcode.toString(),
        phoneNumber: phoneNumber.toString(),
        addToUserId: existingUserId,
        referralCode: maybeString(referralCode),
        visitSiteId: maybeNumber(visitSiteId),
        clinicId: maybeString(clinicId),
        locale: maybeString(locale),
      };

      const { trialOptionId } = await addTrialOption(
        requestBody as AddTrialOptionRequest
      );
      if (trialOptionId) {
        history.push(`/u/participants/${trialOptionId}`);
      } else {
        onClose();
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogHeader
          title={t('createParticipantDialog.dialogHeader')}
          onClose={onClose}
        />
        {!showDuplicateEmailWarning ? (
          <SignupForm
            setDisablePersonalDetails={setPiiStatus}
            onSignup={onSignup}
            errors={errors}
            trials={trialsForSignup}
          />
        ) : (
          <DuplicateEmailWarning
            userId={existingUserId}
            trialId={parseInt(cachedSubmitData?.trialId?.toString() ?? '0')}
            onBack={() => setShowDuplicateEmailWarning(false)}
            onSubmit={onSubmit}
          />
        )}
      </Dialog>
    </>
  );
};

export default CreateParticipantDialog;
