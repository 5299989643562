import React from 'react';

function CheckedIcon(props) {
  return (
    <img
      src='/icons/checked.svg'
      style={{
        width: 12,
        height: 12,
        marginRight: 4,
      }}
      alt='checked'
      {...props}
    />
  );
}

export default CheckedIcon;
