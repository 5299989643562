import React from 'react';
import moment from 'moment-timezone';
import produce from 'immer';
import _pick from 'lodash/pick';
import FormDialog from '../basic/FormDialog';
import { useTranslation } from 'react-i18next';

interface TrialForAvailabilityCreation {
  text: string; //trial name
  value: string; //trialInstance id
  scheduledEvents: {
    text: string; //SchedulingConfiguration name
    value: string; //SchedulingConfiguration id (uuid)
  }[];
}

const buildAddAvailabilityForm = (
  trials: TrialForAvailabilityCreation[],
  t: any
) => {
  const trialSelectionOptions = trials.map(x => _pick(x, 'text', 'value'));
  const elements = [
    {
      title: t('createAvailabilityDialog.pages.availabilityType.title'),
      subElements: [
        {
          key: 'availabilityType',
          type: 'MULTISELECT',
          options: [
            {
              text: t(
                'createAvailabilityDialog.pages.availabilityType.oneoffText'
              ),
              value: 'oneoff',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.availabilityType.recurrText'
              ),
              value: 'recurr',
            },
          ],
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.startDate.title'),
      note: t('createAvailabilityDialog.pages.startDate.note'),
      subElements: [
        {
          key: 'start',
          type: 'DATETIME',
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.endDate.title'),
      note: t('createAvailabilityDialog.pages.endDate.note'),
      subElements: [
        {
          key: 'end',
          type: 'DATETIME',
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.selectTrial.title'),
      subElements: [
        {
          key: 'trialInstanceId',
          type: 'MULTISELECT',
          options: trialSelectionOptions,
        },
      ],
    },
    ...trials
      .filter(trial => trial.scheduledEvents.length > 0)
      .map(trial => ({
        title: t('createAvailabilityDialog.pages.selectEvent.title'),
        dependsOn: {
          key: 'trialInstanceId',
          value: trial.value,
        },
        subElements: [
          {
            key: 'schedulingConfigurationIds',
            type: 'MULTICHECKBOX',
            options: trial.scheduledEvents,
          },
        ],
      })),
    {
      title: t('createAvailabilityDialog.pages.aptDuration.title'),
      subElements: [
        {
          key: 'aptDuration',
          type: 'NUMBER',
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.maxConfirmedConcurrency.title'),
      subElements: [
        {
          key: 'maxConfirmedConcurrency',
          type: 'NUMBER',
        },
      ],
    },
    {
      title: t(
        'createAvailabilityDialog.pages.maxUnconfirmedConcurrency.title'
      ),
      subElements: [
        {
          key: 'maxUnconfirmedConcurrency',
          type: 'NUMBER',
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.selectDaysRepeat.title'),
      dependsOn: {
        key: 'availabilityType',
        value: 'recurr',
      },
      subElements: [
        {
          key: 'byDay',
          type: 'MULTICHECKBOX',
          options: [
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.mo'
              ),
              value: 'Mo',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.tu'
              ),
              value: 'Tu',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.we'
              ),
              value: 'We',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.th'
              ),
              value: 'Th',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.fr'
              ),
              value: 'Fr',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.sa'
              ),
              value: 'Sa',
            },
            {
              text: t(
                'createAvailabilityDialog.pages.selectDaysRepeat.options.su'
              ),
              value: 'Su',
            },
          ],
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.weeksToRepeat.title'),
      dependsOn: {
        key: 'availabilityType',
        value: 'recurr',
      },
      subElements: [
        {
          key: 'count',
          type: 'NUMBER',
        },
      ],
    },
    {
      title: t('createAvailabilityDialog.pages.participantEnabled.title'),
      subElements: [
        {
          key: 'participantEnabled',
          type: 'YES_NO',
        },
      ],
    },
  ];
  return [{ elements }];
};

type Props = {
  open: boolean;
  title: string;
  onClose: () => any;
  onSubmit: (state: any) => Promise<any>;
  timezone: string;
  trials: TrialForAvailabilityCreation[];
};

const CreateAvailabilityDialog = (props: Props) => {
  const { t } = useTranslation('translations');
  const { open, title, timezone, onClose, onSubmit, trials } = props;
  const dynamicFormPages = buildAddAvailabilityForm(trials, t);

  return (
    <FormDialog
      open={open}
      title={title}
      onClose={onClose}
      // @ts-ignore
      dynamicFormPages={dynamicFormPages}
      timezone={timezone}
      validationFunc={form => {
        const startMoment = moment.tz(form.start, timezone);
        const endMoment = moment.tz(form.end, timezone);

        const errors: Record<string, string> = {};

        if (
          form.availabilityType === 'recurr' &&
          !endMoment.isSame(startMoment, 'day')
        ) {
          errors.end = t('siteAvailability.errors.recurringDayMismatch');
        }

        return errors;
      }}
      onChange={<T extends {}>(
        key: string,
        value: any,
        form: T,
        updateForm: (data: T) => any
      ) => {
        const next = produce(form, draft => {
          //remove null-like values
          if (!value || value === '') delete draft[key];
          //if a trialInstance is selected, reset scheduledEvents selection
          if (key === 'start') {
            draft['end'] = value;
          }
          if (key === 'trialInstanceId')
            delete draft['schedulingConfigurationIds'];
          draft[key] = value;
        });
        updateForm(next);
      }}
      onSubmit={async (form: Record<string, any>) => {
        const data: any = _pick(form, [
          'maxConfirmedConcurrency',
          'maxUnconfirmedConcurrency',
          'aptDuration',
          'trialInstanceId',
          'schedulingConfigurationIds',
        ]);
        const startMoment = moment.tz(form.start, timezone);
        data.start = startMoment.toISOString();
        data.duration = moment(form.end).diff(form.start);
        data.participantEnabled = form.participantEnabled === 'YES';
        if (form.availabilityType === 'recurr') {
          const endMoment = moment.tz(form.end, timezone);
          data.end = endMoment.clone().add(form.count, 'weeks').toISOString();
          data.rrule = _pick(form, ['byDay', 'count']);

          if (!endMoment.isSame(startMoment, 'day')) {
            return;
          }
        } else {
          data.end = moment.tz(form.end, timezone).toISOString();
        }
        const success = await onSubmit(data);
        if (success) onClose();
      }}
    />
  );
};

export default CreateAvailabilityDialog;
