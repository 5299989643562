import { formatAddress } from '@curebase/core/lib/clinics';
import { VisitMethod } from '@curebase/core/types';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useScheduleVisitListItemQuery } from 'src/types';
import { getLocale, getLocaleFormatDates } from '../../context/localeContext';
import { redirectToMaps } from '../../lib/maps';
import { getViewPatientBaseUrl } from '../../lib/users';
import { ButtonProps } from '../basic/ActionCard';
import ActionListItem from '../basic/ActionListItem';
import { StudyActivity } from '@curebase/core/types';

interface ScheduleVisitActionListItemProps {
  activity: StudyActivity;
  trialOptionId: number;
}

const ScheduleVisitActionListItem = ({
  activity,
  trialOptionId,
}: ScheduleVisitActionListItemProps) => {
  const history = useHistory();
  const { locale } = getLocale();
  const { data, refetch } = useScheduleVisitListItemQuery({
    variables: {
      schedulingConfigurationId: activity.config.configId!,
      trialOptionId: trialOptionId,
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    const handleDialogClosed = (e: CustomEvent) => {
      if (activity.config.configId === e.detail?.configId) {
        if (refetch) refetch();
      }
    };

    window.addEventListener('booker-dialog-closed', handleDialogClosed, false);

    // cleanup this component
    return () => {
      window.removeEventListener('booker-dialog-closed', handleDialogClosed);
    };
  }, [refetch]);

  const { t } = useTranslation('translations');

  const buttons: ButtonProps[] = [];

  let bookingDescription = null;
  if (data?.getSchedulingConfiguration?.visitBookings?.length) {
    const getMethodLabel = (method, params) => {
      switch (method) {
        case VisitMethod.InPerson:
          return t('scheduleVisitActionListItem.methods.inPerson', params);
        case VisitMethod.Virtual:
          return t('scheduleVisitActionListItem.methods.virtual', params);
        case VisitMethod.AtHome:
          return t('scheduleVisitActionListItem.methods.atHome', params);
      }
    };

    const localesFormat = getLocaleFormatDates();
    const DATE_FORMAT_FULL_USER_TIMEZONE = localesFormat.luxon.bookingFormat;
    const VIEW_USER_TIMEZONE = moment.tz.guess();

    const visitBooking = data?.getSchedulingConfiguration?.visitBookings[0];
    const { start, method, visitAvailability } = visitBooking;
    bookingDescription = t('scheduleVisitActionListItem.bookingDescription', {
      method: getMethodLabel(method, {
        siteName: visitAvailability?.visitSite?.name,
      }),
      start: DateTime.fromISO(start, {
        zone: VIEW_USER_TIMEZONE,
        locale,
      }).toFormat(DATE_FORMAT_FULL_USER_TIMEZONE),
    });

    const disableParticipantScheduling =
      data?.getSchedulingConfiguration?.default?.disableParticipantScheduling ??
      false;
    const cannotCancel =
      data?.getSchedulingConfiguration?.default?.bookerSuccessText
        ?.participantDoNotEffectiveCancel;

    if (method === VisitMethod.InPerson) {
      buttons.push({
        text: t('common.viewOnMap'),
        onClick: () =>
          redirectToMaps(formatAddress(visitAvailability?.visitSite)),
      });
    }

    const cancelUrl = cannotCancel
      ? '/manual-cancel?isRescheduling=true'
      : '/cancel';

    buttons.push({
      isSecondary: true,
      text: t('common.reschedule'),
      onClick: () =>
        history.push(
          `${getViewPatientBaseUrl(trialOptionId)}/${
            data?.getSchedulingConfiguration?.id
          }/booker${disableParticipantScheduling ? cancelUrl : '/schedule'}`
        ),
    });
  }

  const visit =
    data?.getSchedulingConfiguration.locales?.[locale] ??
    data?.getSchedulingConfiguration;

  return (
    <ActionListItem
      title={t('scheduleVisitActionListItem.actionTitle', {
        name:
          visit?.name ?? t('scheduleVisitActionListItem.actionTitleDefault'),
      })}
      iconSrc={'/icons/actionCardCalendar.svg'}
      description={bookingDescription ?? visit?.summary}
      buttons={buttons.length > 0 ? buttons : null}
    />
  );
};

export default ScheduleVisitActionListItem;
