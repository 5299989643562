import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userHasPermission } from '@curebase/core/lib/authorization';
import { getUser } from 'src/store/user/selectors';
import { RolePermissions } from '@curebase/core/types';

//Hook to check if the user has a certain permission
const usePermission = (permission: RolePermissions) => {
  const user = useSelector(getUser);
  return useMemo(() => (!user ? false : userHasPermission(user, permission)), [
    user,
    permission,
  ]);
};

export default usePermission;
