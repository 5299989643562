import {
  dependencyMet,
  DependsOnConfig,
} from '@curebase/modules/dataCapture/services';
import { maxValidDate, minValidDate } from '@curebase/core/lib/dates';
import { defaultHeightValue } from '@curebase/core/lib/height';
import { mdToHTML } from '@curebase/core/lib/markdown';
import { CaseReportDataType, DependsOnOperator } from '@curebase/core/types';
import MomentUtils from '@date-io/moment';
import { Divider, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  TimerRounded,
} from '@material-ui/icons';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import withSizes from 'react-sizes';
import Alert from 'src/components/basic/Alert';
import AutoCompleteDropdown from 'src/components/basic/DynamicForm/AutoCompleteDropdown';
import { CurrencyInput } from 'src/components/basic/DynamicForm/Currency';
import Dropdown from 'src/components/basic/DynamicForm/Dropdown';
import { ParagraphInput } from 'src/components/basic/DynamicForm/Paragraph';
import { SignatureInput } from 'src/components/basic/DynamicForm/Signature';
import {
  useAddressAutocompleteLazyQuery,
  useAddressLazyQuery,
} from 'src/types';
import CustomStyleContext from '../../context/customStyleContext';
import { validateDynamicFormPage } from '@curebase/core/lib/dynamicform/dynamicForms';
import { SliderAdditionalSettings } from '@curebase/core/lib/dynamicform/types';
import { html5InputTypeSupported } from '../../lib/ui';
import { PrescreenClipart } from '../clipart/PrescreenClipart';
import DateInput from '../DynamicForm/DateInput';
import HeightInput from '../DynamicForm/HeightInput';
import MultipleChoiceInput from '../DynamicForm/MultipleChoiceInput';
import CSlider from '../DynamicForm/Slider';
import TimeInput from '../DynamicForm/TimeInput';
import { withBreakpoints } from '../hocs/WithBreakpoints';
import TelehealthFileUpload from '../Telemed/TelehealthFileUpload';
import FuzzyDatePicker from '../TrialBuilder/FuzzyDatePicker';
import Autocomplete from './Autocomplete';
import Scheduler from './CurebaseScheduler';
import { DrugbankAutocomplete } from './DynamicForm/DrugbankAutocomplete';
import FilePreview from './FilePreview';
import { ListElem, listElemOptions } from './ListElem';
import PinInput from './PinInput';
import StandardButton from './StandardButton';
import CPhoneInput from '../DynamicForm/PhoneInput';

export type GenericOption = {
  text: string;
  value: string;
};

type GenericElementProps = {
  key: string;
  style?: string;
  allowNull?: boolean;
  skipValidateOnChange?: boolean;
  validate?: (value: string, data: Object) => string | null;
  readOnly?: boolean;
};

type MultiselectSubElement = GenericElementProps & {
  type: 'MULTISELECT';
  options: {
    text: string;
    value: string;
    url: string;
  }[];
  isHorizontal?: boolean;
  horizontalAxisMarkers?: Object;
};

type MulticheckboxSubElement = GenericElementProps & {
  type: 'MULTICHECKBOX';
  options: GenericOption[];
  isHorizontal?: boolean;
  horizontalAxisMarkers?: Object;
};

type DropdownSubElement = GenericElementProps & {
  type: 'DROPDOWN';
  placeholder?: string;
  options: GenericOption[];
};

type AutocompleteSubElement = GenericElementProps & {
  type: 'AUTOCOMPLETE';
  placeholder?: string;
  options: GenericOption[];
  additionalSettings?: AutocompleteAdditionalSettings;
};

export type AutocompleteAdditionalSettings =
  | {
      freeSolo?: false;
      multiple?: false;
      formatAsId?: boolean;
    }
  | {
      freeSolo?: boolean;
      multiple?: boolean;
      formatAsId?: false;
    };

type YesNoSubElement = GenericElementProps & {
  type: 'YES_NO';
};

export type TextSubElement = GenericElementProps & {
  type:
    | 'TEXT'
    | 'PASSWORD'
    | 'DATETIME'
    | 'NEW_PASSWORD'
    | 'PHONE_NUMBER'
    | 'EMAIL'
    | 'ADDRESS'
    | 'PASSWORD_WITH_RESET';
  placeholder?: string;
  min?: string;
  magicLinkClick?: () => void;
  max?: string;
  noSpellcheck?: boolean;
};

type ListSubElement = GenericElementProps & {
  options: listElemOptions;
};

type PinSubElement = GenericElementProps & {
  type: 'PIN';
  fields?: number;
};

type NumberSubElement = GenericElementProps & {
  type: 'NUMBER';
  min?: number;
  max?: number;
  allowFloat?: boolean;
};

type SliderSubElement = GenericElementProps & {
  type: 'SLIDER';
  min?: number;
  max?: number;
  allowFloat?: boolean;
  additionalSettings: SliderAdditionalSettings;
};

type CurrencySubElement = GenericElementProps & {
  type: 'CURRENCY';
  currency: string;
};

type CheckboxSubElement = GenericElementProps & {
  type: 'CHECKBOX';
  text: string;
  uncheckedValue?: string;
  checkedValue?: string;
};

type SignatureSubElement = GenericElementProps & {
  type: 'SIGNATURE';
};

type FilePickerSubElement = GenericElementProps & {
  type: 'FILE';
  disabled?: boolean;
};

type SubElementProps =
  | ListSubElement
  | MultiselectSubElement
  | MulticheckboxSubElement
  | YesNoSubElement
  | AutocompleteSubElement
  | DropdownSubElement
  | TextSubElement
  | NumberSubElement
  | CheckboxSubElement
  | SignatureSubElement
  | FilePickerSubElement
  | PinSubElement
  | CurrencySubElement;

export type Alert = {
  description: string;
  severity: string;
};
export type ElementProps = {
  title: string;
  alert?: Alert;
  subElements: SubElementProps[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
  note?: string;
  noteBullets?: string[];
  tooltip?: string;
  dependsOn?: DependsOnConfig | DependsOnConfig[];
  dependsOnOperator?: DependsOnOperator;
};

export type PageProps = {
  headerText?: string;
  headerDescription?: string;
  elements: ElementProps[];
  knowledgeContent?: Object;
  shallowGutter?: boolean;
};

type State = {
  errors?: Object; // key -> error string
  submitError?: string;
  explainValues: Object;
  timezone: string | null;
  view: 'CONTEXT' | 'FORM';
};

type OwnProps = {
  pages: PageProps[];
  subElementValues?: {
    key: string;
    value: string;
  }[];
  currentPage?: number;
  onPageChange?: (newPage: number) => any;
  onSubmit?: (arg0: Object) => any;
  onChange?: (
    key: string,
    value: any,
    pageIndex: number,
    optionalMeta?: { type: string }
  ) => any;
  onSkipActivity?: () => void;
  pageZeroOnBack?: () => any;
  data?: any;
  errors?: any;
  classes: any;
  readOnly?: boolean;
  noSubmit?: boolean;
  visitId?: number;
  t: any;
  screenWidth: number;
  screenHeight: number;
  className?: string;
  preferences?: {
    hidePersonalInformation?: boolean;
  };
  timezone?: string;
  context?: {
    title: string;
    body: string;
  };
  affirmation?: React.ReactNode;
  leftSideFooterSlot?: React.ReactNode;
  nextText?: string;
  submitText?: string;
  autocomplete?: () => void | null;
  history?: any;
  disableSubmit?: boolean;
};

type DerivedProps = {
  tabletSizedScreen: boolean;
};

type Props = OwnProps & DerivedProps;

class DynamicForm extends React.Component<Props, State> {
  static contextType = CustomStyleContext;
  scrollToMe: any;

  state: any = {
    view: 'CONTEXT',
    explainValues: {},
    timezone: null,
  };

  UNSAFE_componentWillMount() {
    this.validatePage(this.props.data, true, this.props.errors, true);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.validatePage(props.data, true, props.errors, true);
    if (props.errors !== this.props.errors) {
      this.setState({ errors: props.errors });
    }
  }

  async componentDidMount() {
    const timezone = this.props.timezone || moment.tz.guess();
    moment.tz.setDefault(timezone);
  }

  componentWillUmount() {
    moment.tz.setDefault();
  }

  autoScrollToTop = () => {
    setTimeout(() => {
      this.scrollToMe && this.scrollToMe.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  renderPage = (pageIndex: number, affirmation) => {
    const { pages, className, t } = this.props;
    const { submitError } = this.state;

    const page = pages[pageIndex];
    // [DT] - caused crashes in provider consent
    if (!page) return null;
    const { headerText, headerDescription, elements, knowledgeContent } = page;
    const data = this.props.data || {};

    return (
      <div key={pageIndex} className={`df-page ${className || ''}`}>
        {headerText && (
          <Typography variant='h2' className='df-header-text'>
            {headerText}
          </Typography>
        )}
        {headerDescription && (
          <div className='df-header-description'>{headerDescription}</div>
        )}
        {headerDescription && <Divider className='df-header-divider' />}

        {knowledgeContent && (
          <div className='df-knowledge-content'>
            <div className='kc-title'>{t('dynamicForm.instructionsLabel')}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: mdToHTML(knowledgeContent as any),
              }}
            />
          </div>
        )}

        {elements.map((ele: any, i) => {
          if (!dependencyMet(ele.dependsOn, ele.dependsOnOperator, data)) {
            return null;
          }
          const { isHiddenFromUser = false } = ele;
          const dataType =
            Array.isArray(ele.subElements) && ele.subElements.length > 0
              ? ele.subElements[0].type
              : null;
          return (
            <div
              key={i}
              className={`df-element ${ele.cssClass ? ele.cssClass : ''} ${
                isHiddenFromUser ? 'df-hidden' : ''
              }`}
            >
              {dataType !== 'CONTENT_BLOCK' && ele.header}

              {dataType !== 'CONTENT_BLOCK' && !ele.alert && (
                <div
                  className='markdown'
                  dangerouslySetInnerHTML={{
                    __html: mdToHTML(t(ele.title, ele.title)),
                  }}
                />
              )}
              {ele.alert && dataType !== 'CONTENT_BLOCK' && (
                <Alert
                  severity={ele.alert.severity}
                  title={ele.title}
                  description={ele.alert.description}
                />
              )}

              {ele.note && dataType !== 'CONTENT_BLOCK' && (
                <div className='df-element-note'>{ele.note}</div>
              )}

              {ele.noteBullets && (
                <ul>
                  {ele.noteBullets.map((noteBullet, j) => (
                    <li key={j} className='df-element-note-bullet'>
                      {noteBullet}
                    </li>
                  ))}
                </ul>
              )}

              {_.flatMap(ele.subElements, (subEle, j) => {
                const classes = [
                  `${subEle.key} ${this.personalInformationClass()} ${
                    ele.keySlug ? ele.keySlug : ''
                  }`,
                ];

                if (subEle.cssClass) {
                  classes.push(subEle.cssClass);
                }

                if (this.applyDfStyle(subEle)) classes.push('df-sub-element');
                return [
                  <div key={j} className={classes.join(' ')}>
                    {this.renderSubElement(subEle, pageIndex)}
                  </div>,
                ];
              })}
              {ele.footer}
            </div>
          );
        })}
        {affirmation && <div className='affirmation'>{affirmation}</div>}

        {submitError && <div className='error-message' />}
      </div>
    );
  };

  renderContext = () => {
    const { context, t } = this.props;
    if (!context) return null;

    const { colors } = this.context;

    return (
      <div className='df-context'>
        <div className='df-context-image-container'>
          <PrescreenClipart primaryColor={colors?.primary} />
        </div>
        <div className='df-context-title'>{context.title}</div>
        <div className='df-context-estimated-time'>
          <TimerRounded />
          5-10 {t('participants.minutes')}
        </div>

        <p>{context.body}</p>
      </div>
    );
  };

  render() {
    const {
      pages,
      context,
      pageZeroOnBack,
      tabletSizedScreen,
      noSubmit,
      readOnly,
      affirmation,
      leftSideFooterSlot,
      data,
      onPageChange,
      onSubmit,
      nextText,
      submitText,
      t,
      autocomplete,
      disableSubmit,
      onSkipActivity,
    } = this.props;
    const currentPage = this.props.currentPage || 0;
    const showSubmit =
      !noSubmit && !readOnly && currentPage === pages.length - 1;
    const { view } = this.state;
    const viewContext = context && (!tabletSizedScreen || view === 'CONTEXT');
    const viewForm = !context || !tabletSizedScreen || view === 'FORM';

    const realOnSubmit = onSubmit
      ? async () => {
          const hasErrors = this.validatePage(data);
          if (!hasErrors) {
            try {
              await onSubmit(this.getVisibleData(data) || {});
            } catch (e) {
              console.error(e);
              this.setState({
                submitError: typeof e === 'string' ? e : 'Unknown error',
              });
            }
          }
        }
      : null;

    return (
      <div className='dynamic-form'>
        <div
          ref={el => {
            this.scrollToMe = el;
          }}
        />
        <div className='df-body'>
          {viewContext && this.renderContext()}
          {viewForm && this.renderPage(currentPage, affirmation)}
        </div>

        {viewContext && tabletSizedScreen && (
          <div className='df-footer'>
            <div className='df-button-container'>
              <div
                className='forward-button'
                onClick={() => {
                  this.setState({ view: 'FORM' }, () => {
                    this.autoScrollToTop();
                  });
                }}
              >
                {t('participants.startSurvey')}
                <ArrowForwardRounded />
              </div>
            </div>
          </div>
        )}

        {!(viewContext && tabletSizedScreen) && (onPageChange || showSubmit) && (
          <div className='df-footer'>
            <div className='df-button-container'>
              {leftSideFooterSlot && (
                <div className={'df-left-side-footer-slot'}>
                  {leftSideFooterSlot}
                </div>
              )}
              {onPageChange && (
                <>
                  {currentPage > 0 && (
                    <div
                      className='backward-button'
                      onClick={e => {
                        e.preventDefault();
                        onPageChange(currentPage - 1);
                      }}
                    >
                      <ArrowBackRounded />
                    </div>
                  )}

                  {!showSubmit && (
                    <StandardButton
                      onClick={e => {
                        const hasErrors = this.validatePage(data);
                        //need to return here incase onPageChange is async
                        if (!hasErrors) return onPageChange(currentPage + 1);
                      }}
                      variant='contained'
                      className='forward-button'
                      showLoading={true}
                    >
                      {nextText || t('dynamicForm.next')}
                      <ArrowForwardRounded />
                    </StandardButton>
                  )}
                </>
              )}

              {currentPage === 0 && pageZeroOnBack && (
                <StandardButton
                  onClick={pageZeroOnBack}
                  color='secondary'
                  variant='contained'
                  className='pagezero-back-button'
                  showLoading
                >
                  <ArrowBackRounded />
                </StandardButton>
              )}

              {showSubmit && (
                <StandardButton
                  onClick={realOnSubmit as any}
                  variant='contained'
                  className='forward-button'
                  showLoading
                  disabled={disableSubmit || false}
                >
                  {submitText || t('dynamicForm.submit')}
                  <ArrowForwardRounded />
                </StandardButton>
              )}

              {onSkipActivity && (
                <StandardButton
                  color='primary'
                  onClick={onSkipActivity}
                  variant='outlined'
                  className='skip-button'
                >
                  {t('participants.skipTask', 'Skip task')}
                </StandardButton>
              )}

              {autocomplete && (
                <StandardButton
                  color='secondary'
                  onClick={autocomplete}
                  variant='contained'
                  className='autocomplete-button'
                >
                  <img src='/logo/logo-transparent.png' alt='Curebase Logo' />
                </StandardButton>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  getVisibleData = (data: Object = {}) => {
    const currentPage = this.props.currentPage || 0;
    const page = this.props.pages[currentPage];
    const keysToHide: string[] = [];
    const keysToConvertToDate = [];
    const keysToConvertToDateTime: string[] = [];

    for (const ele of page.elements) {
      for (const subEle of ele.subElements) {
        const { type }: any = subEle;

        if (!dependencyMet(ele.dependsOn, ele.dependsOnOperator, data)) {
          keysToHide.push(subEle.key);
        }

        if (type === 'DATETIME') {
          keysToConvertToDateTime.push(subEle.key);
        }
      }
    }

    const submittableData = {};

    for (const k of Object.keys(data)) {
      if (!_.includes(keysToHide, k)) {
        let v = data[k];
        if (_.includes(keysToConvertToDate, k)) {
          v = moment(v)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .utc()
            .valueOf();
        } else if (_.includes(keysToConvertToDateTime, k)) {
          v = moment(v).utc().format(moment.HTML5_FMT.DATETIME_LOCAL);
        }
        submittableData[k] = v;
      }
    }
    return submittableData;
  };

  validatePage(
    data: Object = {},
    skipNullCheck?: boolean,
    knownErrors: any = {},
    isOnChange?: boolean
  ) {
    const currentPage = this.props.currentPage || 0;
    const page: any = this.props.pages[currentPage];
    const errors = validateDynamicFormPage(
      page,
      data,
      skipNullCheck,
      knownErrors,
      isOnChange,
      this.props.t
    );
    let submitError;

    this.setState({ errors, submitError: submitError || '' });
    const hasErrors = _.filter(_.values(errors), ele => ele).length > 0;

    if (hasErrors) {
      // Log form errors to better debugging on FullStory, no PI should be log.
      console.info('Form errors:', errors);
      const firstError = Object.keys(errors)[0];
      const element = document.querySelector(`[class^='${firstError}']`);
      if (Boolean(element)) {
        const top = (element?.parentElement as any).offsetTop;
        setTimeout(() => {
          window.scrollTo({ top, behavior: 'smooth' });
        }, 20);
      }
    }

    return hasErrors;
  }

  personalInformationClass = () => {
    const { preferences } = this.props;
    // [JR] .fs-block is for Full Story: https://help.fullstory.com/hc/en-us/articles/360020623574#code-first
    return preferences && preferences.hidePersonalInformation ? 'fs-block' : '';
  };

  applyDfStyle(subEle: SubElementProps & any) {
    switch (subEle.type) {
      case CaseReportDataType.Autocomplete:
        return false;
      case CaseReportDataType.DrugbankAutocomplete:
        return false;
      default:
        return true;
    }
  }

  renderSubElement(subEle: SubElementProps & any, pageIndex: number) {
    const { t } = this.props;
    const data = this.props.data ?? {};
    const readOnly = this.props.readOnly ?? false;
    const errors = this.state.errors ?? {};
    const errorMessage = errors[subEle.key];
    const hasError = !!errorMessage;
    let userInput;

    switch (subEle.type) {
      case CaseReportDataType.Multiselect:
        userInput = this.renderMultiselectSubElement(
          subEle,
          hasError,
          pageIndex,
          undefined
        );
        break;
      case CaseReportDataType.Multicheckbox:
        userInput = this.renderMulticheckboxSubElement(
          subEle,
          hasError,
          pageIndex,
          undefined
        );
        break;
      case CaseReportDataType.YesNo:
        userInput = this.renderMultiselectSubElement(
          subEle,
          hasError,
          pageIndex,
          [
            {
              text: t('common.yes'),
              value: 'YES',
            },
            {
              text: t('common.no'),
              value: 'NO',
            },
          ]
        );
        break;
      case CaseReportDataType.Dropdown:
        userInput = (
          <Dropdown
            data={data}
            readOnly={readOnly}
            subEle={subEle}
            onChange={(subEle: any, value) =>
              this.safeOnChange(subEle, value, pageIndex)
            }
          />
        );
        break;
      case CaseReportDataType.Autocomplete:
        userInput = (
          <AutoCompleteDropdown
            data={data}
            readOnly={readOnly}
            subEle={subEle}
            onChange={(subEle: any, value) =>
              this.safeOnChange(subEle, value, pageIndex)
            }
          />
        );
        break;
      case CaseReportDataType.DrugbankAutocomplete:
        userInput = (
          <DrugbankAutocomplete
            data={data}
            subEle={subEle}
            onChange={(subEle: any, value) => {
              this.safeOnChange(subEle, value, pageIndex);
            }}
          />
        );
        break;
      case CaseReportDataType.Text:
        userInput = this.renderTextSubElement(
          subEle,
          hasError,
          pageIndex,
          undefined
        );
        break;
      case 'PASSWORD':
        userInput = this.renderTextSubElement(
          subEle,
          hasError,
          pageIndex,
          'password'
        );
        break;
      case 'NEW_PASSWORD':
        userInput = this.renderTextSubElement(
          subEle,
          hasError,
          pageIndex,
          'new_password'
        );
        break;
      case 'PASSWORD_WITH_RESET':
        userInput = this.renderTextSubElement(
          subEle,
          hasError,
          pageIndex,
          'password_with_reset'
        );
        break;
      case CaseReportDataType.Time:
        userInput = this.renderTimeSubElement(subEle, hasError, pageIndex);
        break;
      case CaseReportDataType.Date:
      case CaseReportDataType.MonthPicker:
        userInput = (
          <DateInput
            subEle={subEle}
            hasError={hasError}
            pageIndex={pageIndex}
            data={this.props.data ?? {}}
            onChange={this.safeOnChange}
            // @ts-ignore
            readOnly={this.props.readOnly}
            noDay={subEle.type === CaseReportDataType.MonthPicker}
          />
        );
        break;
      case CaseReportDataType.Paragraph:
        userInput = (
          <ParagraphInput
            subEle={subEle}
            hasError={hasError}
            pageIndex={pageIndex}
            data={this.props.data ?? {}}
            onChange={this.safeOnChange as any}
            readOnly={this.props.readOnly as any}
          />
        );
        break;
      case CaseReportDataType.Datetime:
        userInput = html5InputTypeSupported('datetime-local')
          ? this.renderTextSubElement(
              subEle,
              hasError,
              pageIndex,
              'datetime-local'
            )
          : this.renderDateTimeSubElement(subEle, hasError, pageIndex);
        break;
      case CaseReportDataType.Number:
        userInput = this.renderNumberSubElement(subEle, hasError, pageIndex);
        break;
      case 'CURRENCY':
        userInput = (
          <CurrencyInput
            subEle={subEle}
            hasError={hasError}
            pageIndex={pageIndex}
            data={this.props.data ?? {}}
            onChange={this.safeOnChange as any}
            readOnly={this.props.readOnly as any}
          />
        );
        break;
      case CaseReportDataType.PhoneNumber:
        userInput = this.renderPhoneNumberSubElement(
          subEle,
          hasError,
          pageIndex
        );
        break;
      case CaseReportDataType.Address:
        userInput = this.renderAddressSubElement(subEle, hasError, pageIndex);
        break;
      case 'PIN':
        userInput = this.renderPinNumberSubElement(subEle, hasError, pageIndex);
        break;
      case 'EMAIL':
        userInput = this.renderTextSubElement(
          subEle,
          hasError,
          pageIndex,
          'email'
        );
        break;
      case CaseReportDataType.Checkbox:
        const text = subEle.text ?? t('dynamicForm.checkbox');
        userInput = this.renderCheckboxSubElement(
          subEle,
          hasError,
          pageIndex,
          text
        );
        break;
      case CaseReportDataType.Signature:
        userInput = (
          <SignatureInput
            data={this.props.data ?? {}}
            onChange={this.safeOnChange as any}
            pageIndex={pageIndex}
            screenWidth={this.props.screenWidth}
            subEle={subEle}
          />
        );
        break;
      case CaseReportDataType.File:
        userInput = this.renderFilePickerSubElement(
          subEle,
          hasError,
          pageIndex
        );
        break;
      case CaseReportDataType.TelehealthFileUpload:
        //Special component doesnt save to captured data normally. requires visitId.
        userInput = (
          <TelehealthFileUpload
            visitId={this.props.visitId!}
            subEle={subEle}
            data={(this.props.data as any) ?? {}}
            onChange={files => {
              this.safeOnChange(subEle, files, pageIndex);
            }}
          />
        );
        break;
      case CaseReportDataType.Slider:
        userInput = this.renderSliderSubElement(subEle, hasError, pageIndex);
        break;
      case CaseReportDataType.Height:
        userInput = this.renderHeightSubElement(subEle, hasError, pageIndex);
        break;
      case 'SCHEDULER':
        userInput = (
          <Scheduler
            // @ts-ignore
            data={this.props.data as any}
            subEle={subEle}
            onChange={this.props.onChange}
            pageIndex={pageIndex}
          />
        );
        break;
      case CaseReportDataType.List:
        userInput = this.renderListSubElement(subEle, hasError, pageIndex);
        break;
      case CaseReportDataType.ContentBlock:
        userInput = (
          <div
            className='markdown'
            dangerouslySetInnerHTML={{ __html: mdToHTML(subEle.contentBlock) }}
          />
        );
        break;
      case CaseReportDataType.FuzzyDatePicker:
        userInput = (
          <FuzzyDatePicker
            // @ts-ignore
            data={this.props.data ?? {}}
            onChange={this.safeOnChange as any}
            pageIndex={pageIndex}
            subEle={subEle}
          />
        );
        break;
      default:
        break;
    }

    return (
      <>
        {userInput}
        {hasError && <div className='error-message'>{errorMessage}</div>}
      </>
    );
  }

  renderMultiselectSubElement(
    subEle: MultiselectSubElement,
    hasError: boolean,
    pageIndex: number,
    overrideOptions?: Object[]
  ) {
    const data = this.props.data || {};
    const selectedValue = data[subEle.key];
    const { onChange } = this.props;
    const options: any = overrideOptions || subEle.options;
    const readOnly = this.props.readOnly || subEle.readOnly;
    const { isHorizontal, horizontalAxisMarkers }: any = subEle;
    const selectionNote =
      selectedValue !== undefined
        ? options.find((o: any) => o.value === selectedValue)?.selectionNote
        : undefined;

    return (
      <div className='df-horizontal-multiple-choice'>
        <div
          className={
            isHorizontal ? 'df-horizontal-multiple-choice-container' : ''
          }
        >
          {options.map((option, i) => {
            const isChecked = selectedValue === option.value;
            const optionId = `${subEle.key}-${i}`;
            const onClick = () => {
              if (onChange && !readOnly) {
                onChange(subEle.key, option.value, pageIndex);
              }
            };

            return (
              <MultipleChoiceInput
                key={optionId}
                id={optionId}
                type='radio'
                isChecked={isChecked}
                readOnly={this.props.readOnly || false}
                onClick={onClick}
                option={option as any}
              />
            );
          })}
        </div>

        {isHorizontal && horizontalAxisMarkers && (
          <div className='df-horizontal-axis-markers'>
            <span className='df-ham-left'>{horizontalAxisMarkers.start}</span>
            <span className='df-ham-spacer' />
            <span className='df-ham-right'>{horizontalAxisMarkers.end}</span>
          </div>
        )}

        {selectionNote ? (
          <div className='df-selection-note'>
            <p>
              <span>{selectionNote}</span>
            </p>
          </div>
        ) : null}
      </div>
    );
  }

  renderMulticheckboxSubElement(
    subEle: MultiselectSubElement,
    hasError: boolean,
    pageIndex: number,
    overrideOptions?: Object[]
  ) {
    const { readOnly, onChange } = this.props;
    const data = this.props.data || {};
    const options = overrideOptions || subEle.options;

    return (
      <>
        {options.map((option: any, i) => {
          const triggerOnChange = () => {
            if (onChange && !readOnly) {
              const currentList = data[subEle.key] || [];
              onChange(
                subEle.key,
                !_.includes(currentList, option.value)
                  ? [...currentList, option.value]
                  : _.without(data[subEle.key], option.value),
                pageIndex
              );
            }
          };
          const optionId = `${subEle.key}-${i}`;
          const isChecked = _.includes(data[subEle.key] || [], option.value);

          return (
            <MultipleChoiceInput
              id={optionId}
              key={optionId}
              type='checkbox'
              isChecked={isChecked}
              readOnly={readOnly || false}
              onClick={triggerOnChange}
              option={option as any}
            />
          );
        })}
      </>
    );
  }

  renderFilePickerSubElement(
    subEle: FilePickerSubElement,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};

    return (
      <div key={subEle.key}>
        <FilePreview
          disabled={subEle.disabled || this.props.readOnly || false}
          files={data[subEle.key]}
          onChange={files => this.safeOnChange(subEle, files, pageIndex)}
        />
      </div>
    );
  }

  safeOnChange = (
    subEle: SubElementProps & any,
    data: any,
    pageIndex: number
  ) => {
    const { onChange, readOnly } = this.props;
    const { disabled, key, type } = subEle;

    if (!!onChange && !readOnly && !disabled)
      onChange(key, data, pageIndex, { type });
  };

  renderPinNumberSubElement(
    subEle: PinSubElement,
    hasError: boolean,
    pageIndex
  ) {
    const data = this.props.data || {};
    const value = data[subEle.key];
    const fields = subEle.fields || 6;
    return (
      // @ts-ignore
      <PinInput
        // @ts-ignore
        type='number'
        fields={fields}
        value={value}
        onChange={async v => this.safeOnChange(subEle, v, pageIndex)}
        hasError={hasError}
        onEnter={async () => {
          const { pages } = this.props;
          const currentPage = this.props.currentPage || 0;
          const { onSubmit, onPageChange } = this.props;
          const hasErrors = this.validatePage(this.props.data);
          if (
            !hasErrors &&
            onSubmit &&
            (currentPage === pages.length - 1 || pages.length === 1)
          ) {
            try {
              await onSubmit(this.getVisibleData(data) || {});
              this.safeOnChange(subEle, '', pageIndex);
            } catch (e) {
              console.error(e);
              this.setState({
                submitError: typeof e === 'string' ? e : 'Unknown error',
              });
            }
          }
          if (
            !hasErrors &&
            onPageChange &&
            pages.length > 1 &&
            currentPage < pages.length - 1
          ) {
            onPageChange(currentPage + 1);
          }
        }}
      />
    );
  }

  renderPhoneNumberSubElement(
    subEle: TextSubElement,
    hasError: boolean,
    pageIndex
  ) {
    const data = this.props.data || {};
    const value = data[subEle.key];

    return (
      <CPhoneInput
        label=''
        value={value}
        disabled={this.props.readOnly || false}
        onChange={v => this.safeOnChange(subEle, v, pageIndex)}
        variant='outlined'
        size='small'
        fullWidth={true}
      />
    );
  }

  renderAddressSubElement(
    subEle: TextSubElement & any,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};
    const { t } = this.props;
    const disabled = this.props.readOnly || subEle.readOnly || false;
    const value = data[subEle.key];
    const inputType = 'text';

    const getUsStates = () => {
      const states: Array<Record<string, string>> = [];
      for (const state of Object.values(t('enums.US_STATE'))) {
        states.push({
          text: state as string,
          value: state as string,
        });
      }
      return states;
    };

    const placeholderDropdown = {
      ...subEle,
      options: getUsStates(),
    };

    const handleAutocompleteChange = (key: string) => (input: string) => {
      const newValue = {
        ...value,
        [key]: input,
      };
      this.safeOnChange(subEle, newValue, pageIndex);
    };

    const buildAutocompleteQuery = (): [Function, string, string] => [
      useAddressAutocompleteLazyQuery,
      'address',
      'getAddressSuggestion',
    ];

    const buildSelectQuery = (): [Function, string, string] => [
      useAddressLazyQuery,
      'address',
      'getAddress',
    ];

    const selectTransform = (selected: any = {}) => {
      if (_.isNil(selected) || _.isEmpty(selected)) {
        return null;
      }
      const { address, city, state, zipCode } = selected;
      return {
        address1: address || '',
        city: city || '',
        zipCode: zipCode || '',
        state: state || '',
      };
    };

    const autoCompleteTransform = (
      newOptions: Array<{ address: string; placeId: string }>
    ) => {
      if (_.isNil(newOptions) || _.isEmpty(newOptions)) {
        return [];
      }
      return newOptions.map(option => ({
        text: option.address,
        value: option.placeId,
      }));
    };

    const handleTextFieldChange = (key: string) => (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue = {
        ...value,
        [key]: e?.target?.value,
      };
      this.safeOnChange(subEle, newValue, pageIndex);
    };

    const handleAutocompleteSelect = selection => {
      this.safeOnChange(subEle, selection, pageIndex);
    };

    const handleDropdownChange = (key: string) => (
      subEle: any,
      selectedValue
    ) => {
      const newValue = {
        ...value,
        [key]: selectedValue,
      };
      this.safeOnChange(subEle, newValue, pageIndex);
    };

    return (
      <div className={'dynamic-form-address'}>
        <Autocomplete
          transformNewOptions={autoCompleteTransform}
          value={value?.address1 || ''}
          readOnly={false}
          onChange={handleAutocompleteChange('address1')}
          buildAutocompleteLazyQuery={buildAutocompleteQuery}
          buildSelectLazyQuery={buildSelectQuery}
          transformSelection={selectTransform}
          onSelect={handleAutocompleteSelect}
          placeholder={t('dynamicForm.address.address1')}
        />
        <input
          disabled={disabled}
          type={inputType}
          value={value?.address2 || ''}
          className={hasError ? 'with-error' : ''}
          onChange={handleTextFieldChange('address2')}
          placeholder={t('dynamicForm.address.address2')}
        />
        <input
          disabled={disabled}
          type={inputType}
          value={value?.city || ''}
          className={hasError ? 'with-error' : ''}
          onChange={handleTextFieldChange('city')}
          placeholder={t('dynamicForm.address.city')}
        />
        <div>
          <Dropdown
            data={data}
            readOnly={false}
            subEle={placeholderDropdown}
            onChange={handleDropdownChange('state')}
            dataKey={'state'}
            placeholder={t('dynamicForm.address.state')}
            alt
          />
          <input
            disabled={disabled}
            type={inputType}
            value={value?.zipCode || ''}
            className={hasError ? 'with-error' : ''}
            onChange={handleTextFieldChange('zipCode')}
            placeholder={t('dynamicForm.address.zipCode')}
          />
        </div>
      </div>
    );
  }

  renderTimeSubElement(
    subEle: TextSubElement,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};
    const value = data[subEle.key];

    return (
      <TimeInput
        hasError={hasError}
        value={value}
        placeholder={subEle.placeholder}
        readOnly={subEle.readOnly}
        onChange={v => this.safeOnChange(subEle, v, pageIndex)}
      />
    );
  }

  renderDateTimeSubElement(
    subEle: TextSubElement,
    hasError: boolean,
    pageIndex: number
  ) {
    const { onChange, readOnly } = this.props;
    const data = this.props.data || {};
    const dateValue = moment(data[subEle.key]);
    const value = dateValue && dateValue.isValid() ? dateValue : moment();
    const dateTimeFormat = 'MM/DD/YYYY hh:mm A';

    const dateOnChange = value => {
      if (onChange && !readOnly) {
        onChange(subEle.key, value.valueOf(), pageIndex);
      }
    };

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDateTimePicker
          value={value}
          onChange={dateOnChange}
          format={dateTimeFormat}
        />
      </MuiPickersUtilsProvider>
    );
  }

  dateTimeToFormattedString = (dateTime: any, timeType: string): string => {
    if (!dateTime) {
      return '';
    }
    if (
      typeof dateTime === 'number' &&
      moment(dateTime).valueOf() !== dateTime
    ) {
      throw new Error(
        `dateTimeToFormattedString: argument 'dateTime' (${dateTime}) is not a valid date`
      );
    }

    const timeFormat =
      timeType === 'DATETIME'
        ? moment.HTML5_FMT.DATETIME_LOCAL
        : moment.HTML5_FMT.DATE;

    return moment(dateTime).format(timeFormat);
  };

  renderListSubElement(
    subEle: ListSubElement,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};
    const { options, style } = subEle;
    let value = data[subEle.key] || [];

    const onClick = updatedValue => {
      this.safeOnChange(subEle, updatedValue, pageIndex);
    };

    return (
      <ListElem
        listData={value}
        readOnly={this.props.readOnly}
        options={options}
        style={style}
        onClick={onClick}
      />
    );
  }

  renderTextSubElement(
    subEle: TextSubElement & any,
    hasError: boolean,
    pageIndex: number,
    specialType?:
      | 'email'
      | 'password'
      | 'datetime-local'
      | 'new_password'
      | 'phone_number'
      | 'password_with_reset'
  ) {
    const data = this.props.data ?? {};
    const disabled = this.props.readOnly || subEle.readOnly || false;
    const rawValue = subEle.rawValue || false;
    let value = data[subEle.key] || subEle.value;
    let inputType = specialType || 'text';

    const { t } = this.props;

    if (
      specialType === 'new_password' ||
      specialType === 'password_with_reset'
    ) {
      inputType = 'password';
    }

    if (['DATETIME'].includes(subEle.type)) {
      subEle.min = this.dateTimeToFormattedString(minValidDate, subEle.type);
      subEle.max = this.dateTimeToFormattedString(maxValidDate, subEle.type);

      if (value) {
        if (rawValue) {
          value = moment(value).valueOf();
        }

        value = this.dateTimeToFormattedString(value, subEle.type);
      }
    }

    return (
      <>
        <input
          disabled={disabled}
          spellCheck={subEle.noSpellcheck ? false : undefined}
          autoComplete={
            specialType === 'new_password' ? 'new-password' : undefined
          }
          data-lpignore={specialType === 'new_password' ? true : undefined}
          type={inputType}
          value={value || ''}
          min={subEle.min || ''}
          max={subEle.max || ''}
          data-testid={subEle.key}
          className={hasError ? 'with-error' : ''}
          onChange={async (e: any) => {
            let v: any = e.target.value;
            if (specialType === 'phone_number') v = v.replace(/\s/g, '');
            if (['datetime-local'].includes(specialType as any)) {
              if (!rawValue) v = moment(v).valueOf();
            }

            this.safeOnChange(subEle, v, pageIndex);
          }}
          placeholder={disabled ? undefined : subEle.placeholder}
          onKeyUp={async e => {
            const { pages } = this.props;
            const currentPage = this.props.currentPage || 0;
            if (e.keyCode !== 13) return null;

            const hasErrors = this.validatePage(this.props.data);
            const { onSubmit, onPageChange } = this.props;
            if (
              !hasErrors &&
              onSubmit &&
              (currentPage === pages.length - 1 || pages.length === 1)
            ) {
              try {
                await onSubmit(this.getVisibleData(data) || {});
              } catch (e) {
                console.error(e);
                this.setState({
                  submitError: typeof e === 'string' ? e : 'Unknown error',
                });
              }
            }
            if (
              !hasErrors &&
              onPageChange &&
              pages.length > 1 &&
              currentPage < pages.length - 1
            ) {
              onPageChange(currentPage + 1);
            }
          }}
        />
        {specialType === 'password_with_reset' && this.props.history && (
          <div className='reset-container'>
            <div
              className='df-password-reset'
              onClick={() => {
                this.props.history.push('/auth/forgot-password');
              }}
            >
              {t('dynamicForm.forgotPwdClickHere')} →
            </div>

            {!subEle.hasClicked ? (
              <div
                className='df-password-reset'
                onClick={() => {
                  const { magicLinkClick }: any = subEle;
                  magicLinkClick();
                }}
              >
                {t('dynamicForm.authWithEmail')} →
              </div>
            ) : (
              <div className='df-password-reset'>
                {t('dynamicForm.sentLabel')}
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  renderNumberSubElement(
    subEle: NumberSubElement & any,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};
    const value = data[subEle.key];

    const disabled = this.props.readOnly || subEle.readOnly || false;
    return (
      <input
        type='number'
        min='0'
        inputMode='numeric'
        pattern='[0-9]*'
        disabled={disabled}
        value={value || ''}
        placeholder={disabled ? undefined : subEle.placeholder}
        className={hasError ? 'with-error' : ''}
        onChange={e => this.safeOnChange(subEle, e.target.value, pageIndex)}
      />
    );
  }

  renderCheckboxSubElement(
    subEle: CheckboxSubElement,
    hasError: boolean,
    pageIndex: number,
    text: string
  ) {
    const data = this.props.data || {};
    const checkedValue = subEle.checkedValue ?? 'checked';
    const uncheckedValue = subEle.uncheckedValue ?? 'unchecked';

    const value = data[subEle.key] || uncheckedValue;
    return (
      <div className='dynamic-form-checkbox-sub-element'>
        <div className='dynamic-form-checkbox-sub-element-left'>
          <Checkbox
            disabled={this.props.readOnly || false}
            color='primary'
            checked={value === checkedValue}
            onChange={e =>
              this.safeOnChange(
                subEle,
                e.target.checked ? checkedValue : uncheckedValue,
                pageIndex
              )
            }
            value={`${uncheckedValue}`}
          />
        </div>

        <div
          className='dynamic-form-checkbox-sub-element-right'
          onClick={() => {
            this.safeOnChange(
              subEle,
              value === checkedValue ? uncheckedValue : checkedValue,
              pageIndex
            );
          }}
        >
          {text}
        </div>
      </div>
    );
  }

  renderSliderSubElement(
    subEle: SliderSubElement & any,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};
    const value = data[subEle.key];

    const disabled = this.props.readOnly || subEle.readOnly || false;
    return (
      <CSlider
        min={subEle.min}
        max={subEle.max}
        step={subEle?.additionalSettings?.step || undefined}
        marks={subEle.horizontalAxisMarkers}
        disabled={disabled}
        value={value}
        selectionLabel={subEle.additionalSettings?.slider?.selectionLabel || ''}
        orientation={
          subEle?.additionalSettings?.slider?.orientation || 'horizontal'
        }
        showSelectionField={
          subEle?.additionalSettings?.slider?.showSelection || false
        }
        majorLabel={subEle?.additionalSettings?.slider?.majorLabel || false}
        className={hasError ? 'with-error' : ''}
        onChange={(e, newValue) =>
          this.safeOnChange(subEle, newValue, pageIndex)
        }
      />
    );
  }

  private renderHeightSubElement(
    subEle: NumberSubElement & any,
    hasError: boolean,
    pageIndex: number
  ) {
    const data = this.props.data || {};
    const value = data[subEle.key] ?? defaultHeightValue;

    const disabled = this.props.readOnly || subEle.readOnly || false;

    return (
      <HeightInput
        min={subEle.min}
        max={subEle.max}
        disabled={disabled}
        value={value}
        hasError={hasError}
        onChange={value => this.safeOnChange(subEle, value, pageIndex)}
      />
    );
  }
}

const mapSizesToProps = ({ width, height }) => ({
  screenWidth: width,
  screenHeight: height,
});

export default withBreakpoints(
  withSizes(mapSizesToProps)(
    withTranslation('translations')(DynamicForm)
  ) as any
) as any;
