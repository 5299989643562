import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import withSizes from 'react-sizes';

interface Sizes {
  isSmallScreen?: boolean;
  fullScreen?: boolean;
}

export interface SafeDialogProps extends DialogProps, Sizes {}

const SafeDialog = (props: SafeDialogProps) => {
  const { className, isSmallScreen, fullScreen, ...rest } = props;

  return (
    <Dialog
      fullScreen={isSmallScreen || fullScreen}
      {...rest}
      className={`material-dialog ${className}`}
    />
  );
};

const mapSizesToProps = ({ width }) => ({
  isSmallScreen: width < 600,
});

export default withSizes<Sizes, SafeDialogProps>(mapSizesToProps)(SafeDialog);
