import { StudyActivity, StudyActivityStatus } from '../types';

const { Active, ActiveWrongTime } = StudyActivityStatus;

// Study activity status helpers
export const isStudyActivityActive = ({
  status,
}: Pick<StudyActivity, 'status'>): boolean => {
  return [Active, ActiveWrongTime].includes(status);
};

export const TERMINAL_STATES = [
  'OPTED_OUT',
  'WITHDRAWN',
  'COMPLETED',
  'FAILED_SCREENING',
  'FAILED_PRESCREENING',
];

export const isTerminalState = (status: string): boolean => {
  return TERMINAL_STATES.includes(status);
};

export const isWithdrawn = (status: string): boolean => {
  return 'WITHDRAWN' === status;
};

export const isOptedOut = (status: string): boolean => {
  return 'OPTED_OUT' === status;
};
