import type { Action } from '../actions';

export default function maintenanceMode(
  state: boolean = false,
  action: Action
): any {
  switch (action.type) {
    case 'SET_MAINTENANCE_MODE_ENABLED':
      return action.isEnabled;
    default:
      return state;
  }
}
