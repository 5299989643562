import * as React from 'react';
import { withTranslation } from 'react-i18next';

import FormDialog from '../basic/FormDialog';
import { createCapturedDataQueryMessage } from '../../controllers/queryEditsController';
import withPermission from '../hocs/WithPermission';
import { RolePermissions } from '@curebase/core/types';

type Props = {
  capturedDataQueryId?: string;
  onClose: () => any;
  onSubmit: () => any;
  t: any;
};

type DynamicFormState = {
  message: string;
};

const initialState: DynamicFormState = {
  message: '',
};
class CreateQueryMessageDialog extends React.Component<Props> {
  onSubmit = async (formData: DynamicFormState) => {
    const { capturedDataQueryId, onClose, onSubmit } = this.props;
    const { message } = formData;

    if (!capturedDataQueryId) return;
    await createCapturedDataQueryMessage(capturedDataQueryId, message);

    onClose();
    onSubmit();
  };

  render() {
    const { capturedDataQueryId, t } = this.props;

    if (!capturedDataQueryId) {
      return null;
    }

    let elements = [
      {
        title: t('queries.dialogs.message.createMessage'),
        note: t('queries.dialogs.blindingWarning'),
        subElements: [
          {
            key: 'message',
            type: 'PARAGRAPH',
            placeholder: t('queries.dialogs.message.placeholder'),
          },
        ],
      },
    ];
    return (
      <FormDialog
        open={!!capturedDataQueryId}
        onClose={this.props.onClose}
        title={t('queries.dialogs.message.title')}
        initialState={initialState}
        // @ts-ignore
        onSubmit={this.onSubmit}
        // @ts-ignore
        dynamicFormPages={[{ elements }]}
      />
    );
  }
}

export default withPermission({
  permission: RolePermissions.CreateCapturedDataQueryMessage,
})(withTranslation('translations')(CreateQueryMessageDialog as any));
