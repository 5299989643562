import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { getLocale, getLocaleFormatDates } from '../../context/localeContext';
import { forceDownload } from '../../lib/downloads';
import { userIsParticipant } from '../../lib/users';
import {
  DocumentConfiguration,
  SignedDocumentStatus,
  User,
  RolePermissions,
} from '@curebase/core/types';
import { TrialOption } from '@curebase/core/types';
import ListItem from '../basic/ListItem';
import { generateSignedDocument } from '../../controllers/informedConsentController';
import StandardButton from '../basic/StandardButton';
import { useSelector } from 'react-redux';
import { getUser } from 'src/store/user/selectors';
import { UserState } from 'src/store/user/types';
import { useHistory, useLocation } from 'react-router-dom';
import PermissionedButton from '../basic/PermissionedButton';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { currentUserHasPermission } from '../../lib/auth';
import { SDVChip } from '../TrialOption/SDV/SDVChip';
import { ViewSignedDocumentDevelopment } from './ViewSignedDocumentDevelopment';
import Dialog from '../basic/SafeDialog';
import { Button, Chip, DialogActions } from '@material-ui/core';
import {
  useVerifyDocumentConfigurationMutation,
  VerifyDocumentConfigurationMutationVariables,
} from '../../types';

interface DocumentListItemProps {
  enablePaperMode: boolean;
  documentId: number | null;
  verified: boolean;
  documentType: string;
  documentStatus: SignedDocumentStatus;
  documentConfig: Pick<
    DocumentConfiguration,
    | 'displayName'
    | 'description'
    | 'allowNotes'
    | 'needsSourceDataVerification'
    | 'version'
  >;
  showViewDocument: boolean;
  trialOption: Pick<TrialOption, 'id'> & {
    patient?: { user: Pick<User, 'firstName'> } | null;
  };
  refetch?: () => any;
  onClick?: () => any;
  signedAt?: string;
}

const getStatusLinkTextFromAction = (
  status: SignedDocumentStatus,
  user: UserState,
  t: TFunction
): {
  type: string;
  subtitle?: string;
} => {
  const {
    Created,
    NeedsParticipantSignature,
    NeedsCounterSignature,
    Completed,
    Declined,
    NotApplicable,
  } = SignedDocumentStatus;
  const isParticipant = userIsParticipant();

  switch (status) {
    case Completed:
      return {
        type: 'COMPLETED',
      };
    case NeedsCounterSignature:
      return {
        type: 'AVAILABLE',
        subtitle: t('documentListItem.textFromAction.waitingStaffSignature'),
      };
    case NeedsParticipantSignature:
      return isParticipant
        ? {
            type: 'AVAILABLE',
            subtitle: t(
              'documentListItem.textFromAction.participantClickHereToSign'
            ),
          }
        : {
            type: 'AVAILABLE',
            subtitle: t(
              'documentListItem.textFromAction.waitingParticipantSignature'
            ),
          };
    case Created:
      return {
        type: 'AVAILABLE',
      };
    case Declined:
      return {
        type: 'DECLINED',
      };
    case NotApplicable:
      return {
        type: 'NOT_APPLICABLE',
      };
  }
};

const DocumentListItem = (props: DocumentListItemProps) => {
  /** REACT HOOKS **/
  const history = useHistory();
  const location = useLocation();
  const { locale } = getLocale();
  const { t } = useTranslation('translations');
  const {
    documentId,
    verified,
    documentConfig,
    documentStatus,
    documentType,
    enablePaperMode,
    onClick,
    refetch,
    showViewDocument,
    trialOption,
    signedAt,
  } = props;
  const user = useSelector(getUser);

  const [verifySignedDocument] = useVerifyDocumentConfigurationMutation();

  const documentStatusText = documentConfig.description;

  const { type, subtitle } = getStatusLinkTextFromAction(
    documentStatus,
    user,
    t
  );

  const buttons: JSX.Element[] = [];
  const [
    isSignedDocumentDialogOpen,
    toggleSignedDocumentDialog,
  ] = useState<boolean>(false);

  const renderDialog = () => {
    return (
      <Dialog
        open={isSignedDocumentDialogOpen}
        fullWidth
        maxWidth='lg'
        scroll='body'
        aria-labelledby='preview-document-dialog-title'
      >
        <ViewSignedDocumentDevelopment
          trialOption={trialOption}
          documentType={documentType}
          documentVersion={documentConfig?.version}
        />
        <DialogActions>
          <Button
            onClick={() => toggleSignedDocumentDialog(false)}
            color='primary'
          >
            {t('common.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const isCompleted = documentStatus === SignedDocumentStatus.Completed;

  if (isCompleted && showViewDocument) {
    buttons.push(
      <StandardButton
        key='1'
        text={t('documentListItem.viewSignedDocumentBtn')}
        onClick={async () => {
          const canUserDownloadDoc = currentUserHasPermission(
            RolePermissions.DownloadInformedConsent
          );
          if (canUserDownloadDoc) {
            const response = await generateSignedDocument({
              trialOptionId: trialOption.id,
              documentType,
              version: documentConfig.version || '',
            });

            if (response.downloadUrl) forceDownload(response);
            if (response.multipleFiles) {
              for (let file of response.multipleFiles) {
                forceDownload(file);
              }
            }
          } else {
            toggleSignedDocumentDialog(true);
          }
        }}
        loadingLabel={t('common.loadingLabel')}
      />
    );

    if (documentConfig.needsSourceDataVerification && !verified) {
      buttons.push(
        <StandardButton
          key='4'
          disabled={
            !currentUserHasPermission(RolePermissions.SourceDataVerification)
          }
          variant='contained'
          text={t('documentListItem.markSDVVerifiedBtn')}
          onClick={async () => {
            if (documentId) {
              const variables: VerifyDocumentConfigurationMutationVariables = {
                input: {
                  signedDocumentId: documentId,
                },
              };

              verifySignedDocument({
                variables,
              });

              if (refetch) await refetch();
            }
          }}
          loadingLabel={t('common.loadingLabel')}
        />
      );
    }
  }

  if (!isCompleted && enablePaperMode) {
    buttons.push(
      <StandardButton
        key='2'
        text={t('documentListItem.signOutsideCurebaseBtn')}
        onClick={() => {
          history.push(location.pathname + '/' + documentType + '/paper');
        }}
      />
    );
  }
  if (!isCompleted && !onClick) {
    buttons.push(
      <PermissionedButton
        key='3'
        variant='text'
        permission={RolePermissions.CanWriteDocumentNotes}
        disabled={false}
        buttonText={t('common.previewDocument')}
        onClick={() => {
          history.push(location.pathname + '/' + documentType + '/comment');
        }}
      />
    );
  }

  const tags: React.ReactElement[] = [];

  let versionTag: React.ReactElement | null = null;
  if (documentConfig.version) {
    versionTag = (
      <div className='version-tag'>
        <Chip label={`v${documentConfig.version}`} />
      </div>
    );
  }

  if (documentConfig.needsSourceDataVerification && isCompleted) {
    tags.push(
      <div>
        <SDVChip isSourceDataVerified={verified} />
      </div>
    );
  }

  const VIEW_USER_TIMEZONE = moment.tz.guess();
  const localesFormat = getLocaleFormatDates();
  const DATE_FORMAT_FULL_USER_TIMEZONE = localesFormat.luxon.bookingFormat;
  const signedAtFormatted =
    signedAt &&
    DateTime.fromISO(signedAt, {
      zone: VIEW_USER_TIMEZONE,
      locale,
    }).toFormat(DATE_FORMAT_FULL_USER_TIMEZONE);

  return (
    <>
      <ListItem
        key={documentType}
        // @ts-ignore
        status={{ type }}
        middle={{
          title: (
            <div className='document-list-item-title'>
              {documentConfig.displayName} {versionTag}
            </div>
          ),
          subtitle,
          text:
            type !== 'COMPLETED'
              ? documentStatusText
              : signedAtFormatted
              ? `Signed ${signedAtFormatted}`
              : '',
          buttons,
          tags,
        }}
        onClick={onClick}
      />
      {renderDialog()}
    </>
  );
};

export default DocumentListItem;
