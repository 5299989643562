import * as React from 'react';
import { withTranslation } from 'react-i18next';

import Dialog from '../basic/SafeDialog';
import ConfirmationDialog from '../basic/ConfirmationInDialog';
import { resolveCapturedDataQuery } from '../../controllers/queryEditsController';

import withPermission from '../hocs/WithPermission';
import { RolePermissions } from '@curebase/core/types';

type Props = {
  capturedDataQueryId?: string | null;
  onClose: () => any;
  onSubmit?: () => any;
  t: any;
};

class ResolveQueryDialog extends React.Component<Props> {
  onConfirm = async () => {
    const { capturedDataQueryId, onClose, onSubmit } = this.props;
    if (!capturedDataQueryId) return;

    await resolveCapturedDataQuery(capturedDataQueryId);
    if (onSubmit) onSubmit();
    onClose();
  };

  render() {
    const { capturedDataQueryId, t } = this.props;
    if (!capturedDataQueryId) {
      return null;
    }

    const closeQuery = t('queries.dialogs.resolve.title');
    return (
      <Dialog open={!!capturedDataQueryId} onClose={this.props.onClose}>
        <ConfirmationDialog
          onClose={this.props.onClose}
          title={closeQuery}
          confirmationText={closeQuery}
          message={t('queries.dialogs.resolve.message')}
          onConfirm={this.onConfirm}
        />
      </Dialog>
    );
  }
}

export default withPermission({
  permission: RolePermissions.ResolveCapturedDataQuery,
})(withTranslation('translations')(ResolveQueryDialog as any));
