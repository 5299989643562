import React from 'react';
import {
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import ActionCardButton from './ActionCardButton';
import { useTranslation } from 'react-i18next';

const FONT_STYLE = {
  marginBottom: '2em',
  fontSize: 14,
  fontFamily: 'Lato',
  fontWeight: 'normal',
  fontStyle: 'normal',
};

const FONT_TITLE = {
  fontFamily: 'Lora',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 28,
  marginBottom: '1em',
};

export interface Props {
  title?: string;
  show?: boolean;
  onConfirmSkip?: () => void;
  onCancel?: () => void;
  showVerticalStyle?: boolean;
}

export default function SkipTaskBody({
  title,
  onConfirmSkip,
  onCancel,
  show = false,
  showVerticalStyle,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return show ? (
    <Box
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: matches ? '0px' : 'unset',
        color: '#0C0C0D',
        zIndex: 99,
        padding: '18px 28px',
        background: '#fff',
      }}
    >
      <Grid direction='column' container>
        <Grid>
          {matches && title && (
            <Typography variant='h4' style={FONT_TITLE as any}>
              {title}
            </Typography>
          )}
          <Typography variant='body1' style={FONT_STYLE as any}>
            {t(
              'participants.skipConfirmTitle',
              'Are you sure you want to skip this task?'
            )}
          </Typography>
          <Typography variant='body2' style={FONT_STYLE as any}>
            {t(
              'participants.skipConfirmMsg',
              'This task is optional. Skipping will not affect your participation in this study but you will not receive any additional compensation associated with completing this task.'
            )}
          </Typography>
        </Grid>
        <Grid
          className='ac-button-container'
          direction={showVerticalStyle ? 'column' : 'row'}
          container
        >
          <ActionCardButton
            label={t('participants.skipBack', 'Back')}
            onClick={onCancel}
          />
          <ActionCardButton
            label={t('participants.yesSkip', 'Yes, Skip')}
            onClick={onConfirmSkip}
            variant='secondary'
          />
        </Grid>
      </Grid>
    </Box>
  ) : null;
}
