import * as React from 'react';
import { TFunction, withTranslation } from 'react-i18next';

import DialogHeader from '../basic/DialogHeader';
import DynamicForm from '../basic/DynamicForm';
import { userIsParticipant } from '../../lib/users';
import { submitMedicalProblem } from '../../controllers/adverseEventController';

type Props = {
  data: any;
  trialOption: {
    readonly id: number;
  };
  match: any;
  history: any;
  t: TFunction;
  onClose(): () => {};
};

type State = {
  formData: any;
};

class FileMedicalProblem extends React.Component<Props, State> {
  state = {
    formData: {} as any,
  };

  async submitFormData() {
    await submitMedicalProblem(this.props.trialOption.id, this.state.formData);
    this.props.onClose();
    this.props.data.refetch();
  }

  addStartDateElement(elements) {
    const { t } = this.props;

    elements.push({
      title: t('reportEvent.medicalProblem.problemStart.question'),
      subElements: [
        {
          key: 'startDate',
          type: 'DATE',
        },
      ],
    });
  }

  addOngoingElements(elements) {
    const { t } = this.props;

    elements.push({
      title: t('reportEvent.medicalProblem.problemOngoing.question'),
      subElements: [
        {
          key: 'ongoing',
          type: 'MULTISELECT',
          placeholder: t(
            'reportEvent.medicalProblem.problemOngoing.placeholder'
          ),
          options: [
            {
              name: t('reportEvent.medicalProblem.problemOngoing.optYes'),
              id: 'yes',
            },
            {
              name: t('reportEvent.medicalProblem.problemOngoing.optNo'),
              id: 'no',
            },
          ].map(t => {
            return {
              text: t.name,
              value: t.id,
            };
          }),
        },
      ],
    });

    if (this.state.formData.ongoing === 'no') {
      elements.push({
        title: t('reportEvent.medicalProblem.problemOngoing.onNoQuestion'),
        subElements: [
          {
            key: 'endDate',
            type: 'DATE',
          },
        ],
      });
    }
  }

  addDescriptionElement(elements) {
    const { t } = this.props;

    elements.push({
      title: t('reportEvent.medicalProblem.description.title'),
      subElements: [
        {
          key: 'description',
          type: 'PARAGRAPH',
          placeholder: t('reportEvent.medicalProblem.description.placeholder'),
        },
      ],
    });
  }

  render() {
    const { formData } = this.state;
    const elements = [];
    const { t } = this.props;
    this.addStartDateElement(elements);
    this.addOngoingElements(elements);
    this.addDescriptionElement(elements);

    return (
      <div>
        <DialogHeader
          title={t('reportEvent.medicalProblem.title')}
          subtitle={
            userIsParticipant()
              ? t('reportEvent.medicalProblem.patient')
              : t('reportEvent.medicalProblem.other')
          }
          onClose={() => {
            this.props.onClose();
          }}
        />

        <div className='medical-problem-background'>
          <DynamicForm
            // @ts-ignore
            pages={[{ elements: elements }]}
            onChange={(key, value) => {
              this.setState({
                formData: {
                  ...formData,
                  [key]: value,
                },
              });
            }}
            data={formData}
            onSubmit={this.submitFormData.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(FileMedicalProblem as any);
