import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { IconButton } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import StandardButton from '../StandardButton';
import SearchBar from '../SearchBar';
import ParticipantDropdown from '../ParticipantDropdown';
import ParticipantList from './ParticipantList';
import {
  changeParticipantPickerFilters,
  changeParticipantPickerSortingOrderFilters,
  changeParticipantSearchQueryFilter,
} from '../../../store/actions';
import { executeQuery } from 'src/ApolloClient';
import { createAndDownloadCSV } from '../../../lib/csv';
import Subheader from '../Subheader';
import Dialog from '../SafeDialog';
import CreateMockTrialOptionsDialog from '../../ViewParticipant/CreateMockTrialOptionsDialog';
import CreateParticipantDialog from '../../ViewParticipant/CreateParticipantDialog';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';
import {
  RolePermissions,
  TrialOptionFilter,
  TrialOptionSortingOrder,
} from '@curebase/core/types';
import { currentUserHasPermission } from '../../../lib/auth';
import SearchFilters from './SearchFilters';
import { TReduxState } from '../../../store/types';
import { isSchedulingFilter } from '@curebase/core/lib/trialOptionHelpers';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import Analytics, {
  AnalyticsEventProperty,
  AnalyticsEventType,
} from 'src/components/Analytics/Analytics';

type Props = {
  onPicked: (trialOptionId: number) => void;
  embedded: boolean;
};

// Track when a user puts a filter on the participant list
const trackFilter = (filter: string) => {
  Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_FILTER_OPERATION, {
    [AnalyticsEventProperty.OPERATIONS_FILTER_DESCRIPTION]: filter,
  });
};

const ParticipantPickerView = ({ embedded, onPicked }: Props) => {
  const { t } = useTranslation('translations');
  const selector = (state: TReduxState) => ({
    participantPicker: state.participantPicker,
  });

  const { participantPicker } = useSelector(selector);
  const { searchQuery, filter, sortingOrder } = participantPicker;
  const [search, setSearch] = useState(searchQuery);

  const searchQueryFilterDebounceLong = useMemo(
    () => debounce(changeParticipantSearchQueryFilter, 3000),
    []
  );
  const searchQueryFilterDebounceShort = useMemo(
    () => debounce(changeParticipantSearchQueryFilter, 1000),
    []
  );

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // debounce search filter by 3 seconds if regular text, or 1 second if only numbers or blank
    const isShortDebounce = /^\d+$|^$/.test(search);
    if (isShortDebounce) {
      searchQueryFilterDebounceLong.cancel();
      searchQueryFilterDebounceShort(search);
    } else {
      searchQueryFilterDebounceShort.cancel();
      searchQueryFilterDebounceLong(search);
    }
  }, [search, searchQueryFilterDebounceShort, searchQueryFilterDebounceLong]);

  const download = async () => {
    const participants = await executeQuery(`
      getTrialOptions(searchQuery: "${searchQuery}", filter: ${filter}, mustHavePatient: true) {
        id
        status
        subjectIdentifier
        trial {
          id
          name
          slug
        }
        patient {
          id
          user {
            id
            displayName
            phoneNumber
            email
          }
        }
        visitSite {
          id
          name
          timezone
        }
        visitBooking {
          id
          start
          end
          needsRescheduling
        }
      }
    `);

    const tableColumns = [
      { name: t('pickers.participantPicker.tableColumns.name'), key: 'name' },
      {
        name: t('pickers.participantPicker.tableColumns.id'),
        key: 'subjectIdentifier',
      },
      { name: t('pickers.participantPicker.tableColumns.email'), key: 'email' },
      {
        name: t('pickers.participantPicker.tableColumns.phone'),
        key: 'phoneNumber',
      },
      {
        name: t('pickers.participantPicker.tableColumns.visitLocation'),
        key: 'location',
      },
      {
        name: t('pickers.participantPicker.tableColumns.visitTime'),
        key: 'time',
      },
      {
        name: t('pickers.participantPicker.tableColumns.visitNeedRescheduling'),
        key: 'needsRescheduling',
      },
    ];

    const tableRows = participants.getTrialOptions.map(participant => {
      const {
        patient,
        visitBooking,
        subjectIdentifier,
        visitSite,
      } = participant;
      const { displayName, email, phoneNumber } = patient.user;
      const { start, end, needsRescheduling } = visitBooking;

      return {
        name: displayName ? displayName : subjectIdentifier,
        subjectIdentifier,
        email,
        phoneNumber,
        location: visitSite.name,
        time: `${moment(start).tz(visitSite.timezone).format('LLL')} - ${moment(
          end
        )
          .tz(visitSite.timezone)
          .format('h:mm A')}`,
        needsRescheduling,
      };
    });

    createAndDownloadCSV(
      t('pickers.participantPicker.participantDownloadName'),
      tableRows,
      tableColumns
    );
  };
  const [
    showCreateManyParticipantsDialog,
    setShowCreateManyParticipantsDialog,
  ] = useState(false);
  const [
    showCreateParticipantDialog,
    setShowCreateParticipantDialog,
  ] = useState(false);

  const actions: JSX.Element[] = [];

  if (!embedded) {
    if (isSchedulingFilter(filter)) {
      actions.push(
        <IconButton onClick={download}>
          <SaveAltIcon />
        </IconButton>
      );
    }

    if (currentUserHasPermission(RolePermissions.CreateStubParticipant))
      actions.push(
        <StandardButton
          onClick={() => {
            setShowCreateParticipantDialog(true);
          }}
          size='xlarge'
          variant='contained'
        >
          {t('pickers.participantPicker.signUpParticipant')}
        </StandardButton>
      );

    if (DEVELOPER_FEATURES_ENABLED)
      actions.push(
        <StandardButton
          color='secondary'
          onClick={() => setShowCreateManyParticipantsDialog(true)}
          size='xlarge'
          variant='contained'
        >
          <img
            height='26px'
            style={{ paddingRight: '6px' }}
            className='curebase-button-logo'
            src='/logo/logo-transparent.png'
            alt='Curebase Logo'
          />
          AUTOGENERATE PARTICIPANTS
        </StandardButton>
      );
  }

  return (
    <>
      <SearchFilters />
      <div className='search-bar-container'>
        <ParticipantDropdown
          title={t('pickers.participantPicker.filterTitle')}
          icon={'filter'}
          initialValue={filter}
          dropdownObjects={[
            {
              key: t('pickers.participantPicker.searchDropdown.all'),
              value: TrialOptionFilter.All,
            },
            {
              key: t('pickers.participantPicker.searchDropdown.withSchedule'),
              value: TrialOptionFilter.ScheduledAll,
            },
            {
              key: t(
                'pickers.participantPicker.searchDropdown.withScheduleToday'
              ),
              value: TrialOptionFilter.ScheduledToday,
            },
            {
              key: t(
                'pickers.participantPicker.searchDropdown.withSchedule7Days'
              ),
              value: TrialOptionFilter.ScheduledWeek,
            },
            {
              key: t('pickers.participantPicker.searchDropdown.needSchedule'),
              value: TrialOptionFilter.NeedsScheduling,
            },
            {
              key: t('pickers.participantPicker.searchDropdown.needReschedule'),
              value: TrialOptionFilter.NeedsRescheduling,
            },
            {
              key: t('pickers.participantPicker.searchDropdown.needConsent'),
              value: TrialOptionFilter.NeedsCall,
            },
          ]}
          onFilterClose={(filter: string) => {
            trackFilter(filter);
            changeParticipantPickerFilters(filter);
          }}
          className={filter !== 'ALL' && searchQuery.length === 0 ? 'teal' : ''}
        />

        <ParticipantDropdown
          title={t('pickers.participantPicker.sortingTitle')}
          icon={'sort'}
          initialValue={sortingOrder}
          dropdownObjects={[
            {
              key: t('pickers.participantPicker.sortDropdown.ascending'),
              value: TrialOptionSortingOrder.Asc,
            },
            {
              key: t('pickers.participantPicker.sortDropdown.descending'),
              value: TrialOptionSortingOrder.Desc,
            },
          ]}
          onFilterClose={(sortingOrder: string) => {
            changeParticipantPickerSortingOrderFilters(sortingOrder);
          }}
          className={sortingOrder !== TrialOptionSortingOrder.Asc ? 'teal' : ''}
        />

        <SearchBar
          defaultValue={search}
          placeholder={t('pickers.participantPicker.searchPlaceholder')}
          className={searchQuery.length > 0 ? 'teal' : ''}
          onChange={val => setSearch(val)}
        />
      </div>

      <Subheader text={t('common.results')} buttons={actions} />
      <ParticipantList embedded={embedded} onPicked={onPicked} />

      {showCreateParticipantDialog && (
        <CreateParticipantDialog
          open={showCreateParticipantDialog}
          onClose={() => setShowCreateParticipantDialog(false)}
        />
      )}

      <Dialog
        open={showCreateManyParticipantsDialog}
        onClose={() => setShowCreateManyParticipantsDialog(false)}
      >
        <CreateMockTrialOptionsDialog
          onClose={() => {
            setShowCreateManyParticipantsDialog(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default ParticipantPickerView;
