import { extendTrialMachineDefault } from '@curebase/core/lib/trialMachineDefault';
import { orderMachineStates } from 'src/lib/trialBuilderHelpers';
import {
  EDIT_MACHINE,
  INIT_TRIAL,
  Action,
} from '../../actions/TrialBuilder/types';

export default function (state: Object = {}, action: Action) {
  switch (action.type) {
    case INIT_TRIAL:
      return action.payload.machine || {};
    case EDIT_MACHINE:
      return orderMachineStates(extendTrialMachineDefault(action.payload));
    default:
      return state;
  }
}
