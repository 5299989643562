import {
  Pii_Status,
  StudyActivity,
  StudyActivityStatus,
  StudyActivityType,
  TrialOptionStudyPlan,
  TrialOptionStudyPlanQuery,
  TrialOptionStudyPlanQueryVariables,
} from '@curebase/core/types';
import AddIcon from '@material-ui/icons/Add';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getViewPatientBaseUrl } from 'src/lib/users';
import { Route } from 'react-router-dom';
import { RolePermissions } from 'src/types';
import { currentUserHasPermission } from '../../lib/auth';
import ListItem, { OwnProps as ListItemProps } from '../basic/ListItem';
import PermissionedButton from '../basic/PermissionedButton';
import Subheader from '../basic/Subheader';

import { withBreakpoints } from '../hocs/WithBreakpoints';
import { InjectedProps, withQueryResult } from '../hocs/WithQueryResult';
import BookerDialog from '../ParticipantInterface/Booker/BookerDialog';
import AttendVisitListItem from './AttendVisitListItem';
import DataCaptureListItem from './DataCaptureListItem';
import EnrollmentListItem from './EnrollmentListItem';
import GroupAssignmentListItem from './GroupAssignmentListItem';
import { InformedConsentListItem } from './InformedConsentListItem';
import ScheduleVisitListItem from './ScheduleVisitListItem';
import AddNewUnscheduledActivityDialog from './UnscheduledVisits/AddNewUnscheduledActivityDialog';

const { Inactive, Active, Passed, Failed, Missed } = StudyActivityStatus;
const {
  DataCapture,
  Enrollment,
  GroupAssignment,
  InformedConsent,
  ScheduleVisit,
  WaitForScheduling,
} = StudyActivityType;

interface TrialOptionStudyPlanProps
  extends InjectedProps<
      TrialOptionStudyPlanQueryVariables,
      TrialOptionStudyPlanQuery
    >,
    WithTranslation {
  trialOptionId: number;
  currentDate?: any;
  history: any;
}

export function mapActivityStatusToListItemStatus(
  activityStatus: StudyActivityStatus
) {
  switch (activityStatus) {
    case Active:
      return 'AVAILABLE';
    case Inactive:
      return 'BLANK';
    case Missed:
      return 'FAILED';
    case Failed:
      return 'FAILED';
    case Passed:
      return 'COMPLETED';
  }
}

export const TrialOptionStudyPlanContext = React.createContext<{
  refetch: (variables?: TrialOptionStudyPlanQueryVariables) => Promise<any>;
}>({
  refetch: async () => {},
});

const DialogRoutes = (props: { trialOptionId: number }) => {
  const { trialOptionId } = props;
  return (
    <>
      <Route
        path={`${getViewPatientBaseUrl(trialOptionId)}/unscheduledActivity/new`}
        render={() => {
          return (
            <AddNewUnscheduledActivityDialog trialOptionId={trialOptionId} />
          );
        }}
      />

      <Route
        path={`${getViewPatientBaseUrl(
          trialOptionId
        )}/:configId/booker/:action`}
        render={() => <BookerDialog isOpen trialOptionId={trialOptionId} />}
      />
    </>
  );
};

class TrialOptionStudyPlanView extends React.Component<TrialOptionStudyPlanProps> {
  getInformedConsentDisplayProps = (a: StudyActivity) => {
    const { queryResult, t } = this.props;
    const trialOption = queryResult.getTrialOption;
    const { piiStatus } = trialOption;

    return {
      status: {
        type: mapActivityStatusToListItemStatus(a.status),
      },
      middle: {
        title: t('trialOptionStudyPlan.consent.title'),
        text: t(`trialOptionStudyPlan.consent.listItemText.${piiStatus}`),
      },
      onClick:
        [Active, Passed].includes(a.status) &&
        piiStatus === Pii_Status.Enabled &&
        currentUserHasPermission(RolePermissions.ViewInformedConsent)
          ? () => this.props.history.push(`/u/${trialOption.id}/consent`)
          : null,
    };
  };

  // Helper to get the URL root for building sub-urls
  getBaseUrl = () => {
    const { queryResult } = this.props;
    const trialOption = queryResult.getTrialOption;
    return getViewPatientBaseUrl(trialOption.id);
  };

  getWaitForSchedulingDisplayProps = (a: StudyActivity) => {
    const { history, t } = this.props;
    return {
      status: {
        type: 'AVAILABLE',
      },
      middle: {
        title: t('trialOptionStudyPlan.scheduleVisit.title'),
        text: t('trialOptionStudyPlan.scheduleVisit.text'),
      },
      onClick: () => history.push(`${this.getBaseUrl()}/booker/schedule`),
    };
  };

  getListItemPropsFromAction = (
    action: StudyActivity,
    actionType: typeof InformedConsent | typeof WaitForScheduling
  ): ListItemProps => {
    const mappers: Record<typeof actionType, any> = {
      [InformedConsent]: this.getInformedConsentDisplayProps,
      [WaitForScheduling]: this.getWaitForSchedulingDisplayProps,
    };
    return mappers[actionType](action);
  };

  render() {
    const { queryResult, trialOptionId, t, history } = this.props;
    const trialOption = queryResult.getTrialOption;

    const sdvRequiredDocuments = trialOption.signedDocuments.filter(
      doc => doc?.documentConfiguration?.needsSourceDataVerification
    );

    // check if we have at least one document that requires SDV
    const isSDVRequired = sdvRequiredDocuments.length > 0;
    // check if we have at least one document
    const isAllVerified = sdvRequiredDocuments.every(
      doc => doc.isSourceDataVerified
    );

    const availableUnscheduledActivities =
      trialOption.trialConfiguration.unscheduledStudyActivities;

    const subheaderButton: React.ReactElement = (
      <PermissionedButton
        permission={RolePermissions.AddUnscheduledActivity}
        disabled={false}
        variant='contained'
        color='primary'
        buttonText={t('trialOptionUnscheduledActivities.addButton')}
        onClick={() =>
          history.push(
            `${getViewPatientBaseUrl(trialOptionId)}/unscheduledActivity/new`
          )
        }
      >
        <AddIcon />
      </PermissionedButton>
    );

    const studyActivities = trialOption.studyActivities.filter(
      act => !act.isUnscheduled
    );
    const unscheduledStudyActivities = trialOption.studyActivities.filter(
      act => act.isUnscheduled
    );

    const renderStudyActivities = () => {
      const mapper = {};
      for (let studyActivity of studyActivities) {
        const epoch = studyActivity?.epoch;
        if (epoch) {
          if (!mapper[epoch]) {
            mapper[epoch] = [];
          }
          mapper[epoch].push(studyActivity);
        }
      }
      if (!isEmpty(mapper)) {
        return renderStudyActivityWithEpoch(mapper);
      } else {
        return renderStudyActivityWithoutEpoch();
      }
    };

    const renderStudyActivityWithoutEpoch = () => {
      return (
        <div className='li-multi-container inset without-epoch'>
          {studyActivities.map(renderStudyActivity)}
        </div>
      );
    };

    const renderStudyActivityWithEpoch = mapper => {
      return Object.keys(mapper).map(epochStudyActivity => {
        return (
          <>
            <div className='participant-epoch-group-container'>
              <label className='participant-epoch-group-label'>
                {epochStudyActivity}
              </label>
            </div>
            <div className='li-multi-container inset'>
              {mapper[epochStudyActivity].map(renderStudyActivity)}
            </div>
          </>
        );
      });
    };

    const renderStudyActivity = (studyActivity, index) => {
      // Certain list item types are only for providers, so we preserve the old code
      switch (studyActivity.config.type) {
        case Enrollment: {
          return (
            <EnrollmentListItem
              key={index}
              refetchData={() => this.props.data.refetch()}
              trialOption={trialOption}
              activity={studyActivity}
            />
          );
        }
        case GroupAssignment: {
          return (
            <GroupAssignmentListItem
              key={index}
              refetchData={() => this.props.data.refetch()}
              trialOption={trialOption}
              activity={studyActivity}
            />
          );
        }
        case DataCapture: {
          return (
            <DataCaptureListItem
              key={index}
              // @ts-ignore
              trialOption={trialOption}
              activity={studyActivity}
            />
          );
        }
        case ScheduleVisit: {
          if (studyActivity.activeId) {
            return (
              <AttendVisitListItem
                key={index}
                trialOptionId={trialOptionId}
                schedulingConfigurationId={studyActivity.config.configId!}
              />
            );
          } else {
            return (
              <ScheduleVisitListItem
                key={index}
                activity={studyActivity}
                trialOptionId={trialOptionId}
                trialOption={trialOption}
              />
            );
          }
        }
        case InformedConsent: {
          const listItemProps = this.getListItemPropsFromAction(
            studyActivity,
            studyActivity.config.type
          );

          return (
            <InformedConsentListItem
              key={index}
              isComplete={
                studyActivity.status === StudyActivityStatus.Passed ||
                studyActivity.status === StudyActivityStatus.Failed
              }
              isSDVRequired={isSDVRequired}
              isAllVerified={isAllVerified}
              listItemProps={listItemProps}
            />
          );
        }
        case WaitForScheduling: {
          const listItemProps = this.getListItemPropsFromAction(
            studyActivity,
            studyActivity.config.type
          );
          if (listItemProps) {
            return <ListItem key={index} {...listItemProps} />;
          } else {
            return null;
          }
        }
        default: {
          return null;
        }
      }
    };

    return (
      <TrialOptionStudyPlanContext.Provider
        value={{ refetch: queryResult.refetch }}
      >
        {(availableUnscheduledActivities.length > 0 ||
          unscheduledStudyActivities.length > 0) && (
          <Subheader
            text={
              unscheduledStudyActivities?.length > 0
                ? t('trialOptionUnscheduledActivities.subheader')
                : ''
            }
            buttons={
              availableUnscheduledActivities?.length > 0
                ? [subheaderButton]
                : []
            }
          />
        )}

        {unscheduledStudyActivities?.length > 0 && (
          <div className='li-multi-container inset'>
            {unscheduledStudyActivities?.length > 0
              ? unscheduledStudyActivities.map(renderStudyActivity)
              : null}
          </div>
        )}
        {renderStudyActivities()}
        <DialogRoutes trialOptionId={trialOptionId} />
      </TrialOptionStudyPlanContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  currentDate: state.currentDate,
});

const TrialOptionStudyPlanComponent = withQueryResult(
  TrialOptionStudyPlanView,
  TrialOptionStudyPlan,
  (props): TrialOptionStudyPlanQueryVariables => ({
    trialOptionId: props.trialOptionId,
    currentDate: DateTime.fromMillis(props.currentDate).toISO(),
  }),
  {
    noDismountOnRefetch: true,
  }
);
export default connect(mapStateToProps)(
  withBreakpoints(
    withTranslation('translations')(
      withRouter(TrialOptionStudyPlanComponent)
    ) as any
  )
);
