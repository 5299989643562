import {
  TrialOptionFilter,
  TrialOptionSortingOrder,
} from '@curebase/core/types';
import {
  CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER,
  CHANGE_PARTICIPANT_PICKER_FILTERS,
  CHANGE_PARTICIPANT_PICKER_SORTING_ORDER,
  CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER,
  CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER,
  CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER,
  ChangeParticipantEpochsPickerFiltersAction,
  ChangeParticipantPickerFiltersAction,
  ChangeParticipantSearchQueryFilterAction,
  ChangeParticipantSortingOrderilterAction,
  ChangeParticipantStatusesPickerFiltersAction,
  ChangeParticipantStudiesPickerFiltersAction,
} from './types';

export const changeParticipantStudiesPickerFilters = (
  trialIds: number[]
): ChangeParticipantStudiesPickerFiltersAction => ({
  type: CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER,
  payload: { trialIds },
});

export const changeParticipantSearchQueryPickerFilters = (
  searchQuery: string
): ChangeParticipantSearchQueryFilterAction => ({
  type: CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER,
  payload: { searchQuery },
});

export const changeParticipantPickerFilters = (
  filter: TrialOptionFilter
): ChangeParticipantPickerFiltersAction => ({
  type: CHANGE_PARTICIPANT_PICKER_FILTERS,
  payload: { filter },
});

export const changeParticipantPickerSortingOrderFilters = (
  sortingOrder: TrialOptionSortingOrder
): ChangeParticipantSortingOrderilterAction => ({
  type: CHANGE_PARTICIPANT_PICKER_SORTING_ORDER,
  payload: { sortingOrder },
});

export const changeParticipantStatusesPickerFilters = (
  statuses: string[]
): ChangeParticipantStatusesPickerFiltersAction => ({
  type: CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER,
  payload: { statuses },
});

export const changeParticipantEpochPickerFilters = (
  epochs: string[]
): ChangeParticipantEpochsPickerFiltersAction => ({
  type: CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER,
  payload: { epochs },
});
