export const EDIT_SITE = 'EDIT_SITE';
export const INIT_TRIAL = 'INIT_TRIAL';

export type TBSitesConfiguration = {
  relatedZipCodes: string[];
};

declare namespace TBSiteEvent {
  interface Payload {}

  export interface Action {
    type: typeof EDIT_SITE | typeof INIT_TRIAL;
    payload: Payload;
  }
}

export interface EditSiteAction extends TBSiteEvent.Action {
  type: typeof EDIT_SITE;
  payload: {
    slug: string;
    config: TBSitesConfiguration;
  };
}

export interface InitTrialAction extends TBSiteEvent.Action {
  type: typeof INIT_TRIAL;
  payload: {
    sites: TBSites;
  };
}

export type TBSites = { [slug: string]: TBSitesConfiguration };

export type SiteEventAction = EditSiteAction | InitTrialAction;
