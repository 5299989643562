type BrowserOS = 'android' | 'ios' | 'other';

export function getMobileOS(): BrowserOS {
  const ua = window?.navigator?.userAgent;
  if (/android/i.test(ua)) {
    return 'android';
  } else if (
    /iPad|iPhone|iPod/i.test(ua) ||
    (window?.navigator?.platform === 'MacIntel' &&
      window?.navigator?.maxTouchPoints > 1)
  ) {
    return 'ios';
  }
  return 'other';
}
