import { isUuidV4 } from '@curebase/core/lib/validators';
import {
  RecurrenceFrequency,
  StudyActivityType,
  StudyPlanVisit,
  StudyPlanVisitRecurrence,
  Visit,
  VisitExtension,
} from '@curebase/core/types';
import { DateTime } from 'luxon';
import enforcedAllowedWeekdays from './enforcedAllowedWeekdays';

type VisitExtensionDueDate = Pick<
  VisitExtension,
  'dueDate' | 'timezone'
> | null;
type SPVWithRrule = Pick<StudyPlanVisit, 'rrule'>;
type SPVWithDueDateFields = Pick<
  StudyPlanVisit,
  | 'startDay'
  | 'startHour'
  | 'endHour'
  | 'isSubmittableAfterFlex'
  | 'earlyFlexMinutes'
  | 'lateFlexMinutes'
  | 'studyActivityAnchorSlug'
>;
interface DueTimeFields
  extends Pick<
    StudyPlanVisit,
    | 'startHour'
    | 'endHour'
    | 'isSubmittableAfterFlex'
    | 'earlyFlexMinutes'
    | 'lateFlexMinutes'
  > {}

interface SubmissionTimes {
  firstSubmittable: DateTime;
  lastSubmittable: DateTime | null;
  due: DateTime;
}

type VisitWithOccurence = Pick<Visit, 'occurence'>;
interface SPVWithDueDataInfo extends SPVWithDueDateFields, SPVWithRrule {}
interface SPVWithDueDataInfo extends SPVWithDueDateFields, SPVWithRrule {}

export interface ExtendedSubmissionTimes extends SubmissionTimes {
  extendedLastSubmittable: DateTime | null;
}
interface SPVWithRecurrence extends SPVWithDueDataInfo {
  rrule: StudyPlanVisitRecurrence;
}

function getExtendedLastSubmittableTime(
  extension: VisitExtensionDueDate
): DateTime | undefined {
  if (extension) {
    return DateTime.fromISO(extension.dueDate, {
      zone: extension.timezone,
    });
  }
}

function submissionTimesForSingleVisit<SPV extends SPVWithDueDateFields>(
  spv: SPV,
  anchorDate: DateTime,
  visitExtension: VisitExtensionDueDate
): ExtendedSubmissionTimes {
  const startDay = spv.startDay
    ? anchorDate.plus({ days: spv.startDay })
    : anchorDate;

  const dueTimeFields = applyDueTimeFields(startDay, spv);

  const extendedLastSubmittable: DateTime | null =
    getExtendedLastSubmittableTime(visitExtension) ??
    dueTimeFields.lastSubmittable;

  return {
    ...dueTimeFields,
    extendedLastSubmittable: extendedLastSubmittable,
  };
}

function occurrenceToDaysOffset(
  visitOccurence: number,
  interval: number,
  freq: RecurrenceFrequency
) {
  const intervalTimesOccurrence = interval * (visitOccurence - 1);
  switch (freq) {
    case RecurrenceFrequency.Daily:
      return intervalTimesOccurrence;
    case RecurrenceFrequency.Weekly:
      return intervalTimesOccurrence * 7;
    case RecurrenceFrequency.Monthly:
      return intervalTimesOccurrence * 30;
    default: {
      const neverCase: never = freq;
      throw new Error('rrule with invalid freq passed in' + neverCase);
    }
  }
}

function spvAnchoredToVisitBooking<
  SPV extends Pick<StudyPlanVisit, 'studyActivityAnchorSlug'>
>(spv: SPV) {
  const { studyActivityAnchorSlug } = spv;
  const [activityType, activityUuid] = studyActivityAnchorSlug.split(':');
  if (!activityType || !activityUuid) {
    return false;
  }

  return (
    activityType === StudyActivityType.ScheduleVisit && isUuidV4(activityUuid)
  );
}

function sameExtendedLastSubmittableTime(
  submissionTimes: SubmissionTimes
): ExtendedSubmissionTimes {
  return {
    ...submissionTimes,
    extendedLastSubmittable: submissionTimes.lastSubmittable,
  };
}

function applyDueTimeFields(
  start: DateTime,
  options: DueTimeFields
): SubmissionTimes {
  const {
    startHour,
    endHour,
    isSubmittableAfterFlex,
    earlyFlexMinutes,
    lateFlexMinutes,
  } = options;

  //dueTime is the time the visit is supposed to first become available (w/out earlyFlex)
  const due = start.startOf('day').plus({ hours: startHour ?? 0 });

  //endTime is the end of normal due hours, before visit becomes "late" (w/out lateFlex)
  const endTime = endHour //we use the fact that 0 is falsy
    ? due.set({
        hour: endHour - 1,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
    : start.endOf('day');

  //firstSubmittableTime is dueTime minus the earlyFlex
  const firstSubmittable = due.minus({
    minutes: earlyFlexMinutes,
  });

  //lastSubmittableTime is endTime minus the earlyFlex
  const lastSubmittable = isSubmittableAfterFlex
    ? null
    : endTime.plus({ minutes: lateFlexMinutes });

  return { firstSubmittable, lastSubmittable, due };
}

function submissionTimesForRecurringVisit<SPV extends SPVWithRecurrence>(
  currentOccurrence: number,
  spv: SPV,
  lastSubmission: DateTime | null,
  anchorDate: DateTime, //should be in the correct timezone
  visitExtension: VisitExtensionDueDate
): ExtendedSubmissionTimes {
  const { rrule, startDay } = spv;
  const { anchorOffset, restrictToDaysOfWeek, interval, freq } = rrule;

  //anchorOffset case
  if (anchorOffset) {
    const sorted = [...anchorOffset].sort((a, b) => a - b);
    const dayOffset: number | undefined = sorted[currentOccurrence - 1]; //occurrence is 1-indexed
    if (!dayOffset) {
      throw new Error(`[Submission Times]: missing dayOffset`);
    }
    const fullOffset = startDay + dayOffset;
    const startDate = anchorDate.plus({ days: fullOffset }).startOf('day');
    return sameExtendedLastSubmittableTime(
      applyDueTimeFields(
        restrictToDaysOfWeek
          ? enforcedAllowedWeekdays(startDate, restrictToDaysOfWeek)
          : startDate,
        spv
      )
    );
  } else {
    const startingOffset = lastSubmission ? 0 : startDay;
    const finalOffest =
      startingOffset +
      occurrenceToDaysOffset(
        lastSubmission ? 2 : currentOccurrence,
        interval!,
        freq
      );
    const anchor = lastSubmission ?? anchorDate;
    const startDate = anchor.plus({ days: finalOffest });

    // EXTEND LOGIC
    const dueTimeFields = applyDueTimeFields(
      restrictToDaysOfWeek
        ? enforcedAllowedWeekdays(startDate, restrictToDaysOfWeek)
        : startDate,
      spv
    );

    const extendedLastSubmittable: DateTime | null =
      getExtendedLastSubmittableTime(visitExtension) ??
      dueTimeFields.lastSubmittable;

    return {
      ...dueTimeFields,
      extendedLastSubmittable: extendedLastSubmittable,
    };
  }
}

export default function submissionTimesForVisit<
  V extends VisitWithOccurence,
  SPV extends SPVWithDueDataInfo
>(
  visit: V,
  spv: SPV,
  previousSubmissionTime: DateTime | null,
  anchorDate: DateTime,
  visitExtension: VisitExtensionDueDate
): ExtendedSubmissionTimes {
  if (spvAnchoredToVisitBooking(spv)) {
    const { earlyFlexMinutes, lateFlexMinutes, isSubmittableAfterFlex } = spv;
    const dueTime = anchorDate;

    //firstSubmittableTime is dueTime minus the earlyFlex
    const firstSubmittableTime = dueTime.minus({
      minutes: earlyFlexMinutes,
    });

    //lastSubmittableTime is endTime minus the earlyFlex
    const lastSubmittableTime = isSubmittableAfterFlex
      ? null
      : dueTime.plus({ hours: 4 }).plus({ minutes: lateFlexMinutes });

    return {
      firstSubmittable: firstSubmittableTime,
      lastSubmittable: lastSubmittableTime,
      due: dueTime,
      extendedLastSubmittable: lastSubmittableTime,
    };
  }

  if (spv.rrule) {
    return submissionTimesForRecurringVisit(
      visit.occurence,
      //@ts-ignore
      spv,
      previousSubmissionTime,
      anchorDate,
      visitExtension
    );
  } else {
    return submissionTimesForSingleVisit(spv, anchorDate, visitExtension);
  }
}
