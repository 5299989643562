import { removeTrialMachineDefault } from '@curebase/core/lib/trialMachineDefault';
import { DataField, StudyPlanVisit } from '@curebase/core/types';
import { useSelector } from 'react-redux';
import {
  SPONSOR_TEMPLATE,
  SPONSOR_SUMMARY_TEMPLATE,
  TRIAL_TEMPLATE,
  TRIAL_SUMMARY_TEMPLATE,
  NOTIFICATIONS_TEMPLATE,
} from '../components/TrialBuilder/types';
import { slugify } from './caseReports';
import { CaseReport } from '../types';

type StudyPlanVisitWithName = StudyPlanVisit & { name: string };

/* Filter functions */
export const isDataField = (item: any): boolean =>
  Boolean(
    typeof item === 'object' &&
      item !== null &&
      item.belongsToCaseReportSlug &&
      !item.temp
  );

export const isDataFieldInStudyVisit = (studyVisitSlug: string) => (
  item: any
): boolean =>
  isDataField(item) && item.belongsToStudyVisitSlug === studyVisitSlug;

export const isDataFieldInCaseReport = (caseReportSlug: string) => (
  item: any
): boolean =>
  isDataField(item) && item.belongsToCaseReportSlug === caseReportSlug;

export const isCaseReport = (item: any): boolean =>
  Boolean(
    item && typeof item === 'object' && item['__typename'] === 'CaseReport'
  );

export const isStudyPlanVisit = (item: any): boolean =>
  Boolean(
    item && typeof item === 'object' && item['__typename'] === 'StudyPlanVisit'
  );

export const notDeprecated = (item: any): boolean =>
  Boolean(!item.isDeprecated);

export const isUniqueName = (
  item: any,
  index: number,
  self: Array<any>
): boolean => self.findIndex(val => val.name === item.name) === index;

export const isUniqueSimpleSlug = (
  item: any,
  index: number,
  self: Array<any>
): boolean =>
  self.findIndex(val => val.simpleSlug === item.simpleSlug) === index;

export const isNotSignupSimpleSlug = (item: CaseReport): boolean =>
  item.simpleSlug !== 'signup_case_rep';

export const isNotSignupSPVSlug = (item: StudyPlanVisit): boolean =>
  !item.slug.endsWith('-signup-config');

/* Comparison functions for sorting */

type WithOrder = { order: number } & any;
export const byOrder = (a: WithOrder, b: WithOrder) => a.order - b.order;
type WithStartDay = { startDay: number } & any;
export const byStartDay = (a: WithStartDay, b: WithStartDay): number =>
  a.startDay - b.startDay;
export const byStartDayThenAlphabetically = (
  a: StudyPlanVisitWithName,
  b: StudyPlanVisitWithName
) => (byStartDay(a, b) !== 0 ? byStartDay(a, b) : b.name.localeCompare(a.name));

export const caseReportSlugFrom = (
  studyPlanVisitSlug: string,
  caseReportSimpleSlug: string
): string => `${studyPlanVisitSlug}_${caseReportSimpleSlug}`;

export const sponsorSummaryKey = (name: string) =>
  `sponsors/${slugify(name)}/sponsorSummary.yaml`;

const generateNewSponsor = (name: string) => {
  const slug = slugify(name);
  return {
    ...SPONSOR_TEMPLATE,
    sponsorSummary: sponsorSummaryKey(name),
    slug,
    name,
  };
};

const generateNewTrial = (name: string) => {
  const slug = slugify(name);
  return {
    ...TRIAL_TEMPLATE,
    trialSummary: `trials/${slug}/trialSummary.yaml`,
    trialIdentifier: slug,
    notifications: `trials/${slug}/notifications`,
    slug,
    name,
  };
};

const generateSponsorSummaryRef = (name: string) => {
  const key = sponsorSummaryKey(name);
  return {
    [key]: [SPONSOR_SUMMARY_TEMPLATE],
  };
};

const generateTrialSummaryRef = (name: string) => {
  return {
    [`trials/${slugify(name)}/trialSummary.yaml`]: [TRIAL_SUMMARY_TEMPLATE],
  };
};

const generateNotificationsRef = (name: string) => {
  return {
    [`trials/${slugify(name)}/notifications`]: [NOTIFICATIONS_TEMPLATE],
  };
};

export const generateTrialConfig = (trialName: string, sponsorName: string) => {
  const config = {
    version: '1.0.0',
    description: 'base config',
    frozen: false,
    machine: {},
    sponsor: generateNewSponsor(sponsorName),
    trial: generateNewTrial(trialName),
    refs: {
      ...generateSponsorSummaryRef(sponsorName),
      ...generateTrialSummaryRef(trialName),
      ...generateNotificationsRef(trialName),
    },
  };

  return config;
};

export const dataFieldsWithBrokenDependencies = (
  dataField: DataField,
  dataFieldsWithDependencies?: DataField[]
) => {
  dataFieldsWithDependencies = dataFieldsWithDependencies ?? [];
  const { order } = dataField;
  const dataFieldsThatWillBeBroken: DataField[] = [];
  dataFieldsWithDependencies.forEach(df => {
    if (df.order <= order) dataFieldsThatWillBeBroken.push(df);
  });

  return dataFieldsThatWillBeBroken;
};

export const getValidatedJsonContent = (content: string) => {
  let jsonContent;
  try {
    jsonContent = JSON.parse(content);
  } catch (e) {
    console.log('Error: Cannot parse invalid JSON.');
    jsonContent = null;
  }
  return jsonContent;
};

export const orderMachineStates = (machine: Object) => {
  const transformMachineToSortableObject = stateName => {
    const stateValue = machine[stateName];
    return {
      stateName,
      stateValue,
      order: stateValue.order,
    };
  };
  const machineSortableArray = Object.keys(machine).map(
    transformMachineToSortableObject
  );
  const reduceMachineSortableToMachine = (
    result,
    { stateName, stateValue }: any
  ) => ({
    ...result,
    [stateName]: stateValue,
  });

  return machineSortableArray
    .sort(byOrder)
    .reduce(reduceMachineSortableToMachine, {});
};

export const useCleanEditableTrialMachine = () => {
  return useSelector<Object, any>((store: any) =>
    orderMachineStates(
      removeTrialMachineDefault(store.trialBuilder.data.present.machine)
    )
  );
};
