import React from 'react';
import { VisitMethod } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';

type Props = {
  method: VisitMethod;
  visitSiteName: string;
  onSubmit: () => void;
};

export function BookerManualSuccess({ onSubmit }: Props) {
  const { t } = useTranslation('translations');

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>
        {t('bookerManualSuccess.ourTeamCoordinatingTitle')}
      </div>

      <div className='booker-text'>
        {t('bookerManualSuccess.manualSuccessDesc')}
      </div>

      <div className='booker-button-container'>
        <div className='booker-button' role='button' onClick={() => onSubmit()}>
          {t('bookerManualSuccess.thanksBtn')}
        </div>
      </div>
    </div>
  );
}

export default BookerManualSuccess;
