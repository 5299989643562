import React from 'react';
import { StudyCustomStyle } from '@curebase/core/types/customStyle';

type CustomStyleExtended = StudyCustomStyle & {
  backgroundOverrides?: any;
};

const context = React.createContext<CustomStyleExtended>({});

export default context;
