import React, { useContext } from 'react';
import Dialog, { SafeDialogProps } from '../basic/SafeDialog';
import OneClickPicker, { PickerOption } from '../basic/OneClickPicker';
import { useParams, useHistory } from 'react-router-dom';
import { DocumentsContext } from './DocumentsOverview';
import { resetDocument } from '../../controllers/signatureController';
import {
  DocumentAction,
  DocumentType,
  SignedDocumentStatus,
} from '@curebase/core/types';
import { SigningType } from '@curebase/core/decoders/signatures';
import { useTranslation } from 'react-i18next';

interface ParticipantHelpRequestDialogProps extends SafeDialogProps {
  status: SignedDocumentStatus;
  onDeny: () => any;
  onConfirm: () => any;
  onClose: () => any;
  refetch?: () => any;
}
const ParticipantHelpRequestDialog = (
  props: ParticipantHelpRequestDialogProps
) => {
  const { onDeny, onConfirm, onClose, refetch, status, ...dialogProps } = props;
  const { trialOptionId, documentType } = useParams<{
    trialOptionId: string;
    documentType: string;
  }>();
  const { setContext } = useContext(DocumentsContext);
  const history = useHistory();
  const { t } = useTranslation('translations');

  const options: PickerOption[] = [
    {
      value: 'speaking_with_study_staff',
      title: t('participantHelpRequestDialog.speakWithStaff'),
      onClick: () => {
        setContext(
          SigningType.remote,
          DocumentAction.AcquireParticipantSignature
        );
        onConfirm();
      },
    },
    {
      value: 'have_not_spoken_with_study_staff',
      title: t('participantHelpRequestDialog.haveNotSpokenStaff'),
      onClick: async () => {
        //if doc in created status, no need to reset it
        if (status === SignedDocumentStatus.Created) {
          history.push(`/u`);
        } else {
          const { success } = await resetDocument(
            documentType as DocumentType,
            parseInt(trialOptionId)
          );
          if (success) {
            refetch && refetch();
            onDeny();
            history.push(`/u/${trialOptionId}/consent`);
          }
        }
      },
    },
  ];

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <div className='light-dialog'>
        <div className='booker booker-loading'>
          <div className='booker-text-container'>
            <div className='booker-title'>
              {t('participantHelpRequestDialog.dialogTitle')}
            </div>
            <div className='booker-text'>
              {t('participantHelpRequestDialog.dialogText')}
            </div>
            <OneClickPicker options={options} />
          </div>
          <div className='close-dialog-icon-container' onClick={onClose}>
            <img
              className='close-dialog-icon'
              src='/icons/closeDialog.svg'
              alt='X'
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ParticipantHelpRequestDialog;
