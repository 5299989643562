import {
  CreateCapturedDataEditRequest,
  CreateCapturedDataQueryMessageRequest,
  CreateCapturedDataQueryReminderRequest,
  CreateCapturedDataQueryRequest,
  ResolveCapturedDataQueryRequest,
} from '@curebase/core/decoders/data';
import { jsonPost } from '../lib/fetchHelpers';

export async function sendCapturedDataQueryReminder(
  capturedDataQueryId: string
): Promise<CreateCapturedDataQueryReminderRequest> {
  const requestBody: CreateCapturedDataQueryReminderRequest = {
    capturedDataQueryId,
  };
  return await jsonPost('/data/query/reminder/create', requestBody);
}

export async function editCapturedData(
  capturedDataId: number,
  newValues: any,
  newEditReason: string,
  trialOptionId?: number
): Promise<CreateCapturedDataEditRequest> {
  const requestBody: CreateCapturedDataEditRequest = {
    capturedDataId,
    newValues,
    newEditReason,
    trialOptionId: trialOptionId ?? null,
  };
  return await jsonPost('/data/edit/create', requestBody);
}

export async function createCapturedDataQuery(
  capturedDataId: number,
  type: string,
  initialMessage: string
): Promise<CreateCapturedDataQueryRequest> {
  const requestBody: CreateCapturedDataQueryRequest = {
    capturedDataId,
    type,
    initialMessage,
  };
  return await jsonPost('/data/query/create', requestBody);
}

export async function createCapturedDataQueryMessage(
  capturedDataQueryId: string,
  message: string
): Promise<CreateCapturedDataQueryMessageRequest> {
  const requestBody: CreateCapturedDataQueryMessageRequest = {
    capturedDataQueryId,
    message,
  };
  const response: CreateCapturedDataQueryMessageRequest = await jsonPost(
    '/data/query/message/create',
    requestBody
  );
  return response;
}

export async function resolveCapturedDataQuery(
  capturedDataQueryId: string
): Promise<ResolveCapturedDataQueryRequest> {
  const requestBody: ResolveCapturedDataQueryRequest = {
    capturedDataQueryId,
  };
  const response: ResolveCapturedDataQueryRequest = await jsonPost(
    '/data/query/resolve',
    requestBody
  );
  return response;
}
