import * as React from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';

import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import { withStyles } from '@material-ui/core';

import { RolePermissions } from '@curebase/core/types';
import { getChangeList } from '../../../lib/caseReports';
import withPermission from '../../hocs/WithPermission';

import StandardButton from '../StandardButton';

import OpenQuerySection from './OpenQuerySection';
import QueryMessageSection from './QueryMessageSection';
import ResolvedQuerySection from './ResolvedQuerySection';
import DataEditSection from './DataEditSection';
import { getLocaleFormatDates } from '../../../context/localeContext';

const StandardButtonWithPermission = withPermission()(StandardButton);

export type FormResultsData = {
  title: string;
  dataType: string;
  values: string[];
  hasError?: boolean;
  capturedDataId?: number;
  queries?: Array<any>;
  capturedDataChanges?: Array<any>;
  editReason?: string;
  authoredByUser: any;
  submittedOn: any;
  dataField?: any;
};

type FormElement = {
  capturedData: any;
  dataField: any;
  values: Array<any>;
};
type Props = {
  t: any;
  query: any;
  element: FormElement;
  onCreateCapturedDataQueryMessage: (queryId: string) => any;
  onEditCapturedData: (capturedDataId: number, editReason?: string) => any;
  onResolveCapturedDataQuery: (queryId: string) => any;
};

const MassiveArrow = withStyles({
  root: {
    fontSize: 60,
    paddingLeft: 20,
  },
})(SubdirectoryArrowRightIcon);

const localeFormat = getLocaleFormatDates();

const DATE_FORMAT = localeFormat.moment.dateFormat;
class FormResultsQuerySection extends React.Component<Props> {
  renderChangeListAndMessages(changeList, dataField, messages, query) {
    const totalList = [
      {
        type: 'queryOpened',
        createdAt: query.createdAt,
      },
      ...(changeList || []),
      ...messages,
    ];

    if (query.resolvedOn) {
      totalList.push({
        type: 'queryResolved',
        createdAt: query.resolvedOn,
      });
    }

    totalList.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));

    return totalList.map((element, i) => {
      if (element.editReason) {
        return (
          <DataEditSection dataField={dataField} change={element} key={i} />
        );
      }
      if (element.type === 'queryOpened') {
        return <OpenQuerySection query={query} key={i} />;
      }
      if (element.type === 'queryResolved') {
        return <ResolvedQuerySection query={query} key={i} />;
      }
      return <QueryMessageSection message={element} key={i} />;
    });
  }

  render() {
    const { t, query, element } = this.props;
    const { capturedData } = element;
    const { id: capturedDataId, dataField } = capturedData;
    const dateOpened = moment(query.createdAt).format(DATE_FORMAT);
    const changeList = getChangeList(element);

    const possibleButtons = query.resolvedOn
      ? []
      : [
          <StandardButtonWithPermission
            text={t('queries.buttons.respondToQuery')}
            key='respondToQuery'
            permission={RolePermissions.CreateCapturedDataQueryMessage}
            onClick={() =>
              this.props.onCreateCapturedDataQueryMessage(query.id)
            }
          />,
          <StandardButtonWithPermission
            text={t('queries.buttons.editDataField')}
            key='editDataField'
            permission={RolePermissions.EditCapturedData}
            onClick={() =>
              this.props.onEditCapturedData(
                capturedDataId,
                'In response to query'
              )
            }
          />,
          <StandardButtonWithPermission
            text={t('queries.buttons.closeQuery')}
            key='closeQuery'
            permission={RolePermissions.ResolveCapturedDataQuery}
            onClick={() => this.props.onResolveCapturedDataQuery(query.id)}
          />,
        ];

    const dateClosed = moment(query.resolvedOn).format(DATE_FORMAT);

    const title = query.resolvedOn
      ? `Resolved Query (${dateOpened} - ${dateClosed})`
      : `Open Query (${dateOpened})`;

    return (
      <div className='fr-query-container'>
        <MassiveArrow />
        <div
          className={`fr-query-section fr-query-section-${
            query.resolvedOn ? 'resolved' : 'open'
          }`}
        >
          <h3>{title}</h3>
          {this.renderChangeListAndMessages(
            changeList,
            dataField,
            query.messages,
            query
          )}
          {possibleButtons.length > 0 && (
            <div className='fr-query-buttons'>{possibleButtons}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(FormResultsQuerySection as any);
