import { StudyActivity } from '@curebase/core/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionCard from '../../basic/ActionCard';
import { actionCardShowSkipButton } from '../utils';
import DownloadAppModal from './DownloadAppModal';

interface DownloadAppActionCardProps {
  activity: StudyActivity;
}

function DownloadAppActionCard(props: DownloadAppActionCardProps) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('translations');

  const optional = actionCardShowSkipButton(props.activity);
  const buttons = [
    {
      text: t('participants.downloadApp.callToActionText'),
      onClick: () => {
        setOpenModal(true);
      },
    },
  ];

  return (
    <>
      <DownloadAppModal open={openModal} onClose={() => setOpenModal(false)} />
      <ActionCard
        optional={optional}
        title={t('participants.downloadApp.title')}
        description={t('participants.downloadApp.description')}
        iconSrc={'/icons/download-app.svg'}
        buttons={buttons}
      />
    </>
  );
}

export default DownloadAppActionCard;
