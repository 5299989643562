import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { VisitMethod } from '@curebase/core/types';
import { VisitOption } from 'src/lib/booker';
import { getLocale, getLocaleFormatDates } from 'src/context/localeContext';
import { DateTime } from 'luxon';

interface BookerFinalConfirmationProps {
  method: VisitMethod;
  visitOption: VisitOption;
  startTime: string;
  endTime: string;
  onConfirm: () => void | Promise<void>;
  onBack: () => void | Promise<void>;
}

function BookerText(props: {
  method: VisitMethod;
  date: string;
  time: { start: string; end: string };
  siteName: string;
}) {
  const { t } = useTranslation('translations');

  const {
    method,
    date,
    time: { start, end },
    siteName,
  } = props;
  let place = '';
  switch (method) {
    case VisitMethod.AtHome:
      return (
        <div className='booker-text'>
          {t('bookerFinalConfirmation.bookerText.atHome', { start, end, date })}
        </div>
      );
    case VisitMethod.InPerson:
      place = t('bookerFinalConfirmation.bookerText.inPerson', { siteName });
      break;
    case VisitMethod.Virtual:
      place = t('bookerFinalConfirmation.bookerText.virtual', { siteName });
      break;
    default:
      const n: never = method;
      throw new Error('Bad VisitMethod ' + n);
  }
  return (
    <div className='booker-text'>
      {t('bookerFinalConfirmation.bookerText.resultMessage', {
        place,
        date,
        start,
      })}
    </div>
  );
}

function BookerFinalConfirmation(props: BookerFinalConfirmationProps) {
  const { t } = useTranslation('translations');
  const localesFormat = getLocaleFormatDates();
  const { locale } = getLocale();
  const { method, visitOption, startTime, endTime, onConfirm, onBack } = props;
  const start = moment(startTime).tz(moment.tz.guess());
  const end = moment(endTime).tz(moment.tz.guess());

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>{t('bookerFinalConfirmation.title')}</div>

      <div className='booker-back-button' onClick={onBack}>
        ← {t('bookerFinalConfirmation.backBtn')}
      </div>

      <BookerText
        method={method}
        date={DateTime.fromISO(startTime)
          .setZone(moment.tz.guess())
          .toFormat(localesFormat.luxon.scheduleDate, { locale })}
        time={{
          start: start.format(localesFormat.moment.shortTime),
          end: end.format(localesFormat.moment.timeWithZone),
        }}
        siteName={visitOption.siteName}
      />

      <div className='booker-button-container'>
        <div
          className='booker-button'
          role='button'
          id='confirm-appointment-button'
          onClick={() => onConfirm()}
        >
          {t('common.confirm')}
        </div>
      </div>
    </div>
  );
}

export default BookerFinalConfirmation;
