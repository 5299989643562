import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BookerRootContext } from './BookerDialog';
import moment from 'moment-timezone';
import { TimeSlotPure } from '@curebase/modules/scheduling/dto/scheduling';
import { getAxleHealthAvailability } from 'src/controllers/schedulingController';
import Loading from 'src/components/Loading';
import LanguageIcon from '@material-ui/icons/Language';
import CurebaseScheduler from '../../basic/CurebaseScheduler';
import { NoAvailabilityView } from './BookerSelectSlot';
import { userIsParticipant } from 'src/lib/users';
import { DateTime } from 'luxon';
import {
  cancelVisit,
  scheduleAxleVisit,
} from 'src/controllers/bookingController';
import BookerSuccess from './BookerSuccess';
import BookerCancel from './BookerCancel';
import { Route } from 'react-router-dom';

const ABBR_TO_TIMEZONE = {
  EST: 'Eastern Time',
  EDT: 'Eastern Time',
  CST: 'Central Time',
  CDT: 'Central Time',
  MST: 'Mountain Time',
  MDT: 'Mountain Time',
  PST: 'Pacific Time',
  PDT: 'Pacific Time',
};

interface AxleSelectSlotProps {
  trialOptionId: number;
  refetch: (...args: any) => any;
  onRequestManualScheduling: (...args: any) => any;
}

function AxleSelectSlot(props: AxleSelectSlotProps) {
  const { trialOptionId, refetch } = props;
  const { configId }: { configId: string } = useParams();
  const [availability, setAvailability] = useState<TimeSlotPure[] | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<number | null>(
    null
  );
  const { t } = useTranslation('translations');
  const { config } = useContext(BookerRootContext);
  const history = useHistory();
  const location = useLocation();
  const timezone = moment.tz(moment.tz.guess(true)).format('z');
  const timezoneString = ABBR_TO_TIMEZONE[timezone]
    ? ABBR_TO_TIMEZONE[timezone] + ' - US & Canada'
    : 'Time Zone: ' + timezone;

  const onSelectSlot = useCallback(
    async (timeslot: TimeSlotPure) => {
      const start =
        typeof timeslot.start === 'number'
          ? DateTime.fromMillis(timeslot.start).toISO()
          : timeslot.start;
      const { success } = await scheduleAxleVisit(
        trialOptionId,
        start,
        configId
      );

      if (success) {
        refetch();
        history.push(location.pathname.replace('/selectslot', '/success'));
      } else {
        throw new Error('request failed');
      }
    },
    [history, location, configId, trialOptionId, refetch]
  );

  useEffect(() => {
    const fetchAvailability = async () => {
      const slots: TimeSlotPure[] = await getAxleHealthAvailability(
        trialOptionId,
        configId
      );

      setAvailability(slots);
    };
    fetchAvailability();
    return () => setAvailability(null);
  }, [trialOptionId, configId]);

  if (!availability) {
    return <Loading />;
  }

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>
        {userIsParticipant()
          ? availability.length === 0
            ? t('axleBooker.requestManualBooking')
            : t('axleBooker.scheduleYourAppointment')
          : availability.length === 0
          ? t('axleBooker.haventTimeBlocksLocation')
          : t('axleBooker.scheduleAppointment')}
      </div>

      {config?.instructions?.patient &&
        config?.instructions?.patient !== '' && (
          <div className='booker-text'>{config.instructions.patient}</div>
        )}

      {availability.length === 0 ? (
        <NoAvailabilityView
          onRequestManualScheduling={props.onRequestManualScheduling}
        />
      ) : (
        <>
          <div
            className='booker-text'
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <LanguageIcon fontSize='inherit' style={{ marginRight: '0.3em' }} />
            <span>{timezoneString}</span>
          </div>
          <CurebaseScheduler
            onSelectSlot={onSelectSlot}
            selectedStartTime={selectedStartTime}
            setSelectedStartTime={setSelectedStartTime}
            slots={availability}
            visitSiteTimezone={moment.tz.guess()}
          />
        </>
      )}
    </div>
  );
}

interface AxleHealthBookerProps {
  baseUrl: string;
  refetch: () => Promise<any>;
  trialOptionId: number;
}

function AxleHealthBooker(props: AxleHealthBookerProps) {
  const { baseUrl, trialOptionId, refetch } = props;
  const history = useHistory();
  const params: { configId: string } = useParams();
  const onSuccessSubmit = useCallback(() => {
    refetch();
    history.push(baseUrl);
  }, [refetch, baseUrl, history]);

  const onCancelSubmit = useCallback(async () => {
    await cancelVisit(trialOptionId, params.configId, false);
    await refetch();
    history.push(baseUrl);
  }, [trialOptionId, params, refetch, history, baseUrl]);

  return (
    <div className='booker'>
      <Switch>
        <Route
          exact
          path={`${baseUrl}/:configId/booker/selectslot`}
          render={() => {
            return (
              <AxleSelectSlot
                trialOptionId={trialOptionId}
                refetch={refetch}
                onRequestManualScheduling={() => {}}
              />
            );
          }}
        />
        <Route
          exact
          path={`${baseUrl}/:configId/booker/success`}
          render={() => {
            return <BookerSuccess onSubmit={onSuccessSubmit} />;
          }}
        />
        <Route
          exact
          path={`${baseUrl}/:configId/booker/cancel`}
          render={() => {
            return <BookerCancel atHomeVisit onCancelVisit={onCancelSubmit} />;
          }}
        />
        <Route
          render={prps => {
            return (
              <Redirect
                to={`${baseUrl}/${prps.match.params.configId}/booker/selectslot`}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
}

export { AxleHealthBooker };
