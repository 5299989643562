import {
  AuthenticateUserRequest,
  AuthenticateUserResponse,
  AuthenticateUserResponseSuccess,
} from '@curebase/core/decoders/users';
import { clearTelehealthDataInStore } from '../store/telehealth/actions';
import {
  dontAdoptLegal,
  fullyAdoptLegal,
  setUser,
} from '../store/actions/index';
import { fetchHelper, unpackJSON } from './fetchHelpers';
import { setTrialOptionsInStore } from './resources';
import { getDispatch, getUser } from '../store';
import { userHasPermission } from '@curebase/core/lib/authorization';
import { getPatientIdsFromRoles } from '@curebase/core/lib/user';
import { RolePermissions, UserRoleType } from '@curebase/core/types';
import { executeQuery } from 'src/ApolloClient';
import { getLocale } from '../context/localeContext';
import { isCurebaseMobileApp } from './ui';
import { sendEventsToMobile } from '../utils/mobileHelpers';

export async function setUserDataInStore(userId: number) {
  // @ts-ignore email cannot be passed as undefined
  setUser(userId, undefined, undefined, undefined, {
    sponsorId: 0,
    trialIdsAndLabels: [],
    isStub: true,
  }); // needed for authentication

  const fetchedUser = (
    await executeQuery(`getUser(id : ${userId}){
        id
        firstName
        lastName
        pinCode
        email
        phoneNumber
        isStub
        hasAdoptedAllLegal
        allowedTrials {
          id
          name
          slug
          allowedSignupMethods
          availableStatuses
          availableEpochs
          instances {
            id
          }
        }
        roles {
          id
          type
          trial {
            id
          }
          clinic {
            id
          }
          trialInstance {
            id
          }
          patient {
            id
            trialOptions {
              id
              trial {
                id
                name
                slug
                customStyle
              }
              clinic {
                id
                name
              }
            }
          }
          sponsor {
            id
            trials {
              id
              name
            }
          }
        }
      }`)
  ).getUser;

  const {
    firstName,
    lastName,
    roles,
    email,
    phoneNumber,
    isStub,
    hasAdoptedAllLegal,
    allowedTrials,
  } = fetchedUser;
  setUser(userId, fetchedUser.pinCode, email, phoneNumber, {
    firstName,
    lastName,
    roles,
    // @ts-expect-error
    isStub,
    allowedTrials,
  });

  if (hasAdoptedAllLegal) {
    fullyAdoptLegal();
  } else {
    dontAdoptLegal();
  }
  clearTelehealthDataInStore(`auth - Setting user in store`);
  await setTrialOptionsInStore(userId);
  return;
}

export const setAuthenticatedUserData = async (
  userId: number,
  sessionId: number
) => {
  await setUserDataInStore(userId);
  getDispatch()({ type: 'DELETE_SELECTED_TRIAL' });
};

export async function attemptLogin(
  email: string,
  password: string
): Promise<AuthenticateUserResponse> {
  const body: AuthenticateUserRequest = {
    email,
    password,
    isCurebaseMobileApp: isCurebaseMobileApp(),
  };
  const res = await unpackJSON(
    fetchHelper('/user/authenticate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        credentials: 'include',
        'x-curebase-locale': getLocale().locale,
      },
      credentials: 'include',
      body: JSON.stringify(body),
    })
  );

  if (!res.error) {
    await setAuthenticatedUserData(res.user.id, res.user.sessionId);
    const successResponse = res as AuthenticateUserResponseSuccess;
    sendEventsToMobile('USER_LOGIN', {
      userUniqueId: successResponse.user.userUniqueId,
    });
  } else {
    console.error('Error while logging in: ', res.error);
  }
  return res;
}

export function currentUserHasPermission(permission: RolePermissions): boolean {
  return userHasPermission(getUser(), permission);
}

export function currentUserHasRole(roleType: UserRoleType): boolean {
  return (getUser().roles ?? []).some(role => role.type === roleType);
}

export function currentUserClinicIds(): string[] {
  const ids = (getUser().roles ?? []).map(r => r.clinic?.id).filter(Boolean);

  return ids;
}

export function getUserSponsorTrialIds() {
  const ids = getUser()
    .roles?.map(r => r.sponsor?.trials.map(x => x.id))
    .flat();

  return ids;
}

export function getUserSponsorTrials() {
  const ids = getUser()
    .roles?.map(r => r.sponsor?.trials)
    .flat();

  return ids;
}

export function getUserSponsorIds() {
  const ids = getUser()
    .roles?.map(r => r.sponsor?.id)
    .flat();

  return ids;
}

export function getUserPatientIds() {
  return getPatientIdsFromRoles(getUser());
}

export function getUnauthenticatedRedirect(props: any): object {
  const attemptedRoute = (props.location || {}).pathname;
  const to = {
    pathname: '/auth/login',
    search: '',
  };
  if (attemptedRoute) to.search = `?redirect=${encodeURI(attemptedRoute)}`;

  return to;
}

export function isAuthenticatedHelper(user: any) {
  const isAuthenticated = user && user.userId && !!(user.roles?.length > 0);

  return isAuthenticated;
}

export function getSessionExpiredRedirect(pathname?: string): string {
  const currentUrl: string = window.location.href;
  const fallbackRoute: string = new URL(currentUrl).pathname;

  let attemptedRoute: string = fallbackRoute;

  if (pathname) {
    attemptedRoute = pathname;
  }

  return `/auth/sessionexpired?redirect=${encodeURI(attemptedRoute)}`;
}
