import * as React from 'react';
import { withRouter } from 'react-router-dom';

import Dropdown from '../Dropdown';

type Props = {
  trials: Object[];
  history: any;
  match: any;
  location: any;
};

class TrialDropdown extends React.Component<Props> {
  onClose = (trialId?: number) => {
    const { history } = this.props;

    if (trialId) {
      const currentLocation = window.location.pathname;
      const currentPathArray = currentLocation.split('/');
      if (currentPathArray) {
        currentPathArray.pop();
        history.push(`${currentPathArray.join('/')}/${trialId}`);
      }
    }
  };

  render() {
    const { trials, location } = this.props;

    const currentTrialId = parseInt(location.pathname.split('/').pop());
    const currentTrial: any = trials?.find(
      (t: any) => t?.id === currentTrialId
    );
    if (!currentTrial || trials.length <= 1) {
      return null;
    }

    return (
      <Dropdown
        dropdownObjects={trials}
        dropdownTitle='Current trial'
        currentlySelectedName={currentTrial.name}
        onClose={this.onClose}
      />
    );
  }
}

export default withRouter(TrialDropdown);
