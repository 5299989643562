import {
  EDIT_GTM_CONTAINERS,
  TBGtmContainer,
  GtmEventAction,
  INIT_TRIAL,
} from './types';
import produce from 'immer';

export default produce((state: TBGtmContainer, action: GtmEventAction) => {
  switch (action.type) {
    case INIT_TRIAL:
    case EDIT_GTM_CONTAINERS:
      return { ...action.payload.gtmContainers };
  }
}, {});
