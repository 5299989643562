import { CaseReportInstanceSourceDataVerifyRequest } from '@curebase/modules/dataCapture/dto/caseReportInstance';
import { jsonPost } from '../lib/fetchHelpers';

export async function verifyCaseReportInstance(
  caseReportInstanceId: number
): Promise<{ success: boolean }> {
  const requestBody: CaseReportInstanceSourceDataVerifyRequest = {
    caseReportInstanceId,
  };
  const response: { success: boolean } = await jsonPost(
    '/caseReportInstance/sourceDataVerify',
    requestBody
  );
  return response;
}
