import { DateTime } from 'luxon';
import type { Action } from '../actions';

export default function currentDate(
  state: any = DateTime.now().toMillis(),
  action: Action
): any {
  switch (action.type) {
    case 'SET_DATE':
      return action.date;
    case 'RESET_DATE':
      return DateTime.now().plus({ seconds: action.dateOffset }).toMillis();
    default:
      return state;
  }
}
