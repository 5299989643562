import {
  CBox,
  CButton,
  CFlex,
  CIcon,
  CLink,
  CModal,
  CModalContent,
  CModalFooter,
  CModalTitle,
  CPhoneInput,
  CTabs,
  CTypography,
  useTheme,
} from '@curebase/design-system';
import DeviceDetector from 'device-detector-js';
import QRCode from 'qrcode';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import {
  sendAppDownloadLinkViaSms,
  setDownloadAppAcitivtyDone,
} from 'src/controllers/engageController';
import { getMobileOS } from 'src/shared/lib/deviceHelpers';
import styled from 'styled-components';
import { ParticipantDashboardContext } from '../ParticipantDashboard';

interface DownloadAppModalProps {
  open: boolean;
  onClose: () => void;
  dontMarkActivityAsDone?: boolean;
}

const StyledBox = styled.div<any>`
  border: 1px solid ${props => props.borderColor};
  border-radius: 16px;
  margin-top: 15px;
`;

const TabPanel = styled.div`
  min-height: 210px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    min-width: 380px;
  }
`;

const QRCodeImage = styled.img`
  width: 150px;
  height: 150px;
`;

function DownloadAppModal(props: DownloadAppModalProps) {
  let componentIsMounted = true;
  const { t } = useTranslation('translations');

  const tabs = {
    qrCode: {
      index: 0,
      key: 'qrCode',
      label: t('participants.downloadApp.modal.qrCode.tabTitle', 'QR Code'),
    },
    directLink: {
      index: 1,
      key: 'directLink',
      label: t(
        'participants.downloadApp.modal.directLink.tabTitle',
        'Direct link'
      ),
    },
    sms: {
      index: 2,
      key: 'sms',
      label: t('participants.downloadApp.modal.sms.tabTitle', 'Text me a link'),
    },
  };

  const { refetch: participantDashboardRefetch } = useContext(
    ParticipantDashboardContext
  );
  const deviceDetector = new DeviceDetector();
  const { device } = deviceDetector.parse(window.navigator.userAgent);
  const theme = useTheme();
  const { trialOptionId } = useParams<any>();
  const locationOrigin = window?.location?.origin;
  const [tab, setTab] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formSubmited, setFormSubmited] = useState(false);
  const [sendingSms, setSendingSms] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [qrCode, setQRCode] = useState('');
  const os = getMobileOS();
  const downloadUrl = `${locationOrigin}/download`;
  const tabsList = useMemo(getTabsList, [device?.type]);
  const currentTab = tabsList[tab];

  function getTabsList() {
    if (device?.type === 'smartphone') {
      return [tabs.directLink, tabs.sms];
    }

    if (device?.type === 'tablet') {
      return [tabs.qrCode, tabs.directLink, tabs.sms];
    }
    return [tabs.qrCode, tabs.sms];
  }

  async function generateQRCode() {
    let qrCodeImg = await QRCode.toDataURL(downloadUrl);
    if (!componentIsMounted) return;
    setQRCode(qrCodeImg);
  }

  async function send() {
    setFormSubmited(true);
    if (!phoneNumber) return;

    setSendingSms(true);
    setSmsSent(false);
    await sendAppDownloadLinkViaSms(phoneNumber);
    setSendingSms(false);
    setSmsSent(true);
    setFormSubmited(false);
  }

  async function markActivityAsDone() {
    await setDownloadAppAcitivtyDone(parseInt(trialOptionId));
    participantDashboardRefetch();
  }

  function done() {
    if (!trialOptionId || props.dontMarkActivityAsDone) {
      return props.onClose();
    }
    markActivityAsDone();
    props.onClose();
  }

  function sendToDifferentNumber() {
    setSmsSent(false);
    setFormSubmited(false);
    setPhoneNumber('');
  }

  function resendLink() {
    setSmsSent(false);
    setFormSubmited(false);
  }

  function phoneNumberIsValid() {
    return !!phoneNumber && phoneNumber.length >= 9;
  }

  function openStore() {
    window.open(downloadUrl, '_blank');
  }

  function getStoreName() {
    return os === 'android' ? 'PlayStore' : 'App Store';
  }

  useEffect(() => {
    generateQRCode();
    return () => {
      componentIsMounted = false;
    };
  }, []);

  return (
    <CModal open={props.open} onClose={props.onClose}>
      <CModalTitle
        title={t(
          'participants.downloadApp.modal.title',
          'Get the Curebase app'
        )}
      />
      <CModalContent>
        <CTypography type='body-regular'>
          {t(
            'participants.downloadApp.modal.description',
            'Choose a method to get the download link'
          )}
        </CTypography>
        <StyledBox borderColor={theme.palette.divider}>
          <CTabs tabs={tabsList} value={tab} onChange={setTab} fullWidth />
          {currentTab?.key === tabs.qrCode.key && (
            <TabPanel>
              <CFlex spaceY={12} direction={'column'} alignItems={'center'}>
                <CBox style={{ textAlign: 'center' }}>
                  <CTypography type='label-large'>
                    {t(
                      'participants.downloadApp.modal.qrCode.instructions',
                      'Scan the QR code with your mobile device:'
                    )}
                  </CTypography>
                </CBox>
                <CBox>
                  {!!qrCode && (
                    <QRCodeImage
                      src={qrCode}
                      alt={'QRCode to download the app'}
                    />
                  )}
                </CBox>
              </CFlex>
            </TabPanel>
          )}
          {currentTab?.key === tabs.directLink.key && (
            <TabPanel>
              <CFlex spaceY={12} direction={'column'} alignItems={'center'}>
                <CBox style={{ textAlign: 'center' }}>
                  <CTypography type='label-large'>
                    {t(
                      'participants.downloadApp.modal.directLink.instructions',
                      'Click on the button below to download the app:'
                    )}
                  </CTypography>
                </CBox>
                <CBox>
                  <CButton variant='contained' onClick={openStore}>
                    {t('participants.downloadApp.modal.buttons.openStore', {
                      storeName: getStoreName(),
                      defaultValue: `Open ${getStoreName()}`,
                    })}
                  </CButton>
                </CBox>
              </CFlex>
            </TabPanel>
          )}
          {currentTab?.key === tabs.sms.key && (
            <TabPanel>
              {smsSent && (
                <CFlex
                  spaceY={12}
                  direction={'column'}
                  justifyContent={'center'}
                >
                  <CBox style={{ textAlign: 'center' }}>
                    <CFlex spaceX={6} direction={'row'}>
                      <CBox style={{ textAlign: 'center' }}>
                        <CIcon
                          name='CheckCircle'
                          size={24}
                          color={theme.palette.success.main}
                        />
                      </CBox>
                      <CBox>
                        <CTypography type='body-large'>
                          {t(
                            'participants.downloadApp.modal.sms.successMessage',
                            {
                              phoneNumber: formatPhoneNumberIntl(phoneNumber),
                              defaultValue: `Link sent to ${formatPhoneNumberIntl(
                                phoneNumber
                              )}`,
                            }
                          )}
                        </CTypography>
                      </CBox>
                    </CFlex>
                    <CLink onClick={resendLink}>
                      {t(
                        'participants.downloadApp.modal.sms.fallbackMessage',
                        `Didn't get it? Resend the link`
                      )}
                    </CLink>
                  </CBox>
                </CFlex>
              )}
              {!smsSent && (
                <CFlex spaceY={12} direction={'column'} alignItems={'center'}>
                  <CBox style={{ textAlign: 'center' }}>
                    <CTypography type='label-large' align={'center'}>
                      {t(
                        'participants.downloadApp.modal.sms.instructions',
                        `Enter a phone number for your mobile device:`
                      )}
                    </CTypography>
                  </CBox>
                  <CBox style={{ marginTop: 12 }}>
                    <CPhoneInput
                      value={phoneNumber}
                      onChange={phone => setPhoneNumber(phone || '')}
                      fullWidth
                      hasError={formSubmited && !phoneNumberIsValid()}
                      helperText={
                        formSubmited && !phoneNumberIsValid()
                          ? t(
                              'participants.downloadApp.modal.sms.phoneValidation',
                              'Please, insert a valid phone number'
                            )
                          : ''
                      }
                      countryLabel='Country'
                      variant='outlined'
                    />
                  </CBox>
                </CFlex>
              )}
            </TabPanel>
          )}
        </StyledBox>
      </CModalContent>
      <CModalFooter>
        <CButton
          onClick={
            currentTab?.key === tabs.sms.key && smsSent
              ? sendToDifferentNumber
              : props.onClose
          }
        >
          {currentTab?.key === tabs.sms.key && smsSent
            ? t(
                'participants.downloadApp.modal.buttons.sendToDifferentNumber',
                'Send to a different number'
              )
            : t('participants.downloadApp.modal.buttons.close', 'Close')}
        </CButton>
        <CButton
          variant='contained'
          disabled={sendingSms}
          onClick={currentTab?.key === tabs.sms.key && !smsSent ? send : done}
        >
          {currentTab?.key === tabs.sms.key && !smsSent
            ? sendingSms
              ? t(
                  'participants.downloadApp.modal.buttons.sending',
                  'Sending...'
                )
              : t('participants.downloadApp.modal.buttons.send', 'Send')
            : t('participants.downloadApp.modal.buttons.done', 'Done')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}

export default DownloadAppModal;
