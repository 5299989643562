import {
  QueryHookOptions,
  QueryResult,
  useQuery,
  OperationVariables,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { PaginationInput } from '@curebase/core/types';
import _merge from 'lodash/merge';

/**
 * usePaginatedQuery
 *
 * Ref: https://curebase.slab.com/posts/pagination-spec-vokkwtfo
 */

const initialPage: Page = 1;

const DEFAULT_PAGE_SIZE: number = 10;

export type Page = number;

export type QueryKey = string;

export type PageOptions = {
  initial?: Page;
  size?: number;
};

export type PaginatedQueryArgs<Q, V extends OperationVariables> = {
  pageOptions?: PageOptions;
  query: QueryKey;
  document: DocumentNode;
  options?: QueryHookOptions<Q, V>;
};

export type PaginatedQueryResult<Q, V extends OperationVariables> = QueryResult<
  Q,
  V
> & {
  page: {
    initial: Page;
    current: Page;
    total: number;
    set: React.Dispatch<React.SetStateAction<Page>>;
  };
  resultsCount: number;
};

export default function usePaginatedQuery<Q, V extends OperationVariables>({
  pageOptions,
  query,
  document,
  options,
}: PaginatedQueryArgs<Q, V>): PaginatedQueryResult<Q, V> {
  const initial = pageOptions?.initial ?? initialPage;
  const [currentPage, setCurrentPage] = useState<Page>(initial);

  const size: number = pageOptions?.size ?? DEFAULT_PAGE_SIZE;

  const pagination: PaginationInput = {
    page: currentPage,
    size,
  };

  const paginatedOptions = _merge(options, {
    variables: {
      pagination,
    },
  });

  const result = useQuery<Q, V>(document, paginatedOptions);

  const count = result?.data?.[query]?.count;
  const total = Math.ceil(count / size);

  const page = {
    initial,
    current: currentPage,
    total,
    set: setCurrentPage,
  };

  return {
    ...result,
    resultsCount: count,
    page,
  };
}
