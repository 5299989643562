import { RolePermissions } from '../../types';

export const sponsorReadOnlyPermissions = [
  RolePermissions.ReadCapturedDataEditQueryMessage, // Referral Sources
  RolePermissions.SkipExternalPrescreening, // Data Capture Edits
  RolePermissions.ViewAdverseEvents,
  RolePermissions.ViewCrf,
  RolePermissions.ViewGroupAssignmentBlindedLabel,
  RolePermissions.ViewInvestigatorList,
  RolePermissions.ViewParticipantLocation, // Create stub participants
  RolePermissions.ViewParticipantReferralSource,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewPatientPayments,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.ViewReferralSources,
  RolePermissions.ViewScheduling,
  RolePermissions.ViewSettings,
  RolePermissions.ViewUtmCodes,
  RolePermissions.ViewZendeskProfile,
];

export const sponsorApiAdministrator = [
  RolePermissions.CanViewSwaggerDocs,
  RolePermissions.ViewSponsorClinics,
  RolePermissions.SponsorDashboard,
  RolePermissions.ViewDataReporting,
  RolePermissions.ViewSettings,
  RolePermissions.ViewGroupAssignment,
  RolePermissions.ViewSponsorApiKeys,
  RolePermissions.CanJoinTelehealthMeeting,
];
