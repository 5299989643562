import produce from 'immer';
import type { Action } from '../actions';

export type TResourcesState = {
  patientsTrialOptions?: {
    patientId: number;
    trialOptionIds: number[];
  };
};

const defaultResourcesState = {};

export default function resources(
  state: TResourcesState = defaultResourcesState,
  action: Action & any
): TResourcesState {
  return produce(state, draft => {
    switch (action.type) {
      case 'SET_PATIENTS_TRIAL_OPTIONS':
        draft.patientsTrialOptions = {
          patientId: action.data.patientId,
          trialOptionIds: action.data.pptTrialOptions,
        };
        return;
      default:
        return;
    }
  });
}
