// Learn about custom errors here:
// https://blog.bugsnag.com/anatomy-of-a-javascript-error/
import { datadogRum } from '@datadog/browser-rum';

class CustomError extends Error {
  cause: any;
  constructor(cause: any) {
    super(cause.message);
    this.cause = cause;
  }
}

export class FetchError extends CustomError {
  constructor(cause: Object) {
    super(cause);
    this.name = 'FetchError';
  }
}

export function captureError(error: any, customAttributes: object = {}) {
  const errorId = uuidv4();
  datadogRum.addError(error, { errorId, ...customAttributes });

  return errorId;
}

function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
