import React, { useCallback } from 'react';
import _findIndex from 'lodash/findIndex';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withBreakpoints } from '../hocs/WithBreakpoints';
import { mdToHTML } from '@curebase/core/lib/markdown';
import Tooltip from '@material-ui/core/Tooltip';
import Sticky from 'react-sticky-el';

type IconButtonProps = {
  icon: React.ReactElement<any>;
  text?: string;
  onClick: () => any;
};

type TableDataProps = {
  label: string;
  text: string | React.ReactElement<any>;
  iconButton?: IconButtonProps;
};

export type TabItem<T = string> = {
  text: string;
  value: T;
  disabledText?: string;
};

export type TabProps<TabItemType = string> = {
  items: TabItem<TabItemType>[];
  onChange: (newValue: TabItemType) => any;
  selected: TabItemType;
  disabledText?: string;
};

interface MenuItemProps {
  text: string;
  value: string;
}

interface MenuProps {
  onChange: (newValue: string) => any;
  value: string;
  items: MenuItemProps[];
  classes?: any;
}

function Menu(props: MenuProps) {
  const { items, value, onChange, classes } = props;
  if (!items || items.length <= 1) {
    return null;
  }
  return (
    <div className='header-menu'>
      <Select
        fullWidth
        value={value}
        className={classes.materialSelect}
        onChange={event => {
          onChange(event.target.value as string);
        }}
      >
        {items.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

function EmphasisText({ text }: { text: string }) {
  return <div className='header-emphasis-text'>{text}</div>;
}

interface HeaderProps {
  beforeTitleElement?: string | React.ReactElement<any>;
  displayText?: string | React.ReactElement<any>;
  emphasisText?: string;
  tabs?: TabProps;
  menu?: MenuProps;
  back?: () => void;
  subtext?: string;
  textContent?: string;
  rightContent?: React.ReactElement<any>;
  classes?: any;
  tabletSizedScreen?: boolean;
  tableData?: TableDataProps[];
  children?: React.ReactElement<any>;
  sticky?: boolean;
}

const blue = '#00a2e5';

const styles = theme => ({
  backButtonIcon: {
    width: 36,
    height: 36,
  },
  button: {
    color: blue,
  },
});

function Header(props: HeaderProps) {
  const {
    beforeTitleElement,
    emphasisText,
    displayText,
    tableData,
    tabletSizedScreen,
    tabs,
    menu,
    rightContent,
    back,
    subtext,
    textContent,
    sticky,
  } = props;

  const StickyWrapper = useCallback(
    ({ children }) => {
      return sticky ? (
        <Sticky dontUpdateHolderHeightWhenSticky={true}>{children}</Sticky>
      ) : (
        <>{children}</>
      );
    },
    [sticky]
  );

  return (
    <div className='header'>
      <div className='header-title'>
        <StickyWrapper>
          {beforeTitleElement && (
            <div className={'before-title-wrapper'}>
              <div className='header-back-button' onClick={back}>
                <ArrowBackIcon
                  htmlColor='#444444'
                  className={props.classes.backButtonIcon}
                  fontSize={'large'}
                />
              </div>
              {beforeTitleElement}
            </div>
          )}

          {displayText && (
            <div className='header-title-text'>
              {!beforeTitleElement && (
                <div className='header-back-button' onClick={back}>
                  <ArrowBackIcon
                    htmlColor='#444444'
                    className={props.classes.backButtonIcon}
                    fontSize={'large'}
                  />
                </div>
              )}
              <div className='header-title-text-left'>
                <div>{displayText}</div>
              </div>

              <div className='header-title-text-right'>
                {menu && !tabletSizedScreen && (
                  <Menu {...menu} classes={props.classes} />
                )}
                {rightContent && !tabletSizedScreen && <>{rightContent}</>}
              </div>
            </div>
          )}
        </StickyWrapper>

        {emphasisText && <EmphasisText text={emphasisText} />}

        {tableData && (
          <div className='header-tabledata'>
            {tableData.map((item, i) => {
              const iconButton = item.iconButton;
              return (
                <div key={i} className='header-tabledata-row'>
                  <div className='header-tabledata-row-left'>{item.label}</div>

                  <div className='header-tabledata-row-right'>{item.text}</div>

                  {iconButton && (
                    <div className='header-tabledata-row-button'>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          iconButton.onClick();
                        }}
                      >
                        <div className='header-tabledata-row-icon'>
                          {iconButton.icon}
                        </div>
                        <div className='header-tabledata-row-icon-text'>
                          {iconButton.text}
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {subtext && typeof subtext === 'string' && (
          <div
            className='header-subtext'
            dangerouslySetInnerHTML={{
              __html: mdToHTML(subtext),
            }}
          />
        )}

        {subtext && typeof subtext !== 'string' && (
          <div className='header-subtext'>{subtext}</div>
        )}

        {textContent && (
          <div className='header-text-content'>{textContent}</div>
        )}

        {menu && tabletSizedScreen && (
          <Menu {...menu} classes={props.classes} />
        )}
        {rightContent && tabletSizedScreen && <>{rightContent}</>}
        {props.children}

        {tabs && (
          <div className='header-tabs'>
            <Tabs
              textColor='primary'
              indicatorColor='primary'
              variant='scrollable'
              value={
                _findIndex(tabs.items, item => item.value === tabs.selected) ||
                0
              }
              onChange={(e, v) => tabs.onChange(tabs.items[v].value as any)}
            >
              {tabs.items.map((tabItem, i) => (
                <Tab
                  key={i}
                  style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                  label={
                    tabItem.disabledText ? (
                      <Tooltip title={tabItem.disabledText}>
                        <span>{tabItem.text}</span>
                      </Tooltip>
                    ) : (
                      tabItem.text
                    )
                  }
                  disabled={!!tabItem.disabledText}
                />
              ))}
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

export default withBreakpoints<HeaderProps>(
  withStyles(styles as any)(Header as any) as any
);
