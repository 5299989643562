import { StudyActivityType } from '../types';
import { InformedConsent, Machine } from './machine';

export function getOrderedStatesForMachine<
  T extends { [stateName: string]: { order: number } }
>(machine: T) {
  return Object.keys(machine).sort((stateA, stateB) => {
    return machine[stateA].order - machine[stateB].order;
  });
}

export function getStateWithEnrollmentActionFromMachine(
  machine: Machine
): string | null {
  const machineEntries = Object.entries(machine);

  const enrollmentState = machineEntries.find(([, stateConfig]) => {
    return (stateConfig.meta?.studyActivities ?? []).findIndex(
      act => act.type === StudyActivityType.Enrollment
    ) === -1
      ? false
      : true;
  });

  return enrollmentState?.[0] ?? null;
}

export function getInformConsentFromMachine(machine: Machine): Array<string> {
  const orderedMachineStateNames = getOrderedStatesForMachine(machine);
  return orderedMachineStateNames.reduce<string[]>((documents, stateName) => {
    const stateMachine = machine[stateName];
    const informedConsentActivity = (
      stateMachine.meta?.studyActivities ?? []
    ).find(
      act => act.type === StudyActivityType.InformedConsent
    ) as InformedConsent;

    return documents.concat(informedConsentActivity?.documents || []);
  }, []);
}

export function getStateAfterEnrollmentActionFromMachine(
  machine: Machine
): string | null {
  const enrollmentState = getStateWithEnrollmentActionFromMachine(machine);

  if (!enrollmentState) {
    return null;
  }

  const nextState = machine[enrollmentState].on?.ADVANCE ?? null;

  return nextState;
}

export function getStateBeforeEnrollmentActionFromMachine(
  machine: Machine
): string | null {
  const enrollmentState = getStateWithEnrollmentActionFromMachine(machine);

  if (!enrollmentState) {
    return null;
  }

  const previousState = Object.entries(machine).find(
    ([, stateConfig]) => stateConfig.on?.ADVANCE === enrollmentState
  );

  return previousState?.[0] ?? null;
}

export function getEpochStateMapping<
  T extends { [stateName: string]: { epoch: string } }
>(machine: T) {
  const mapper: any = {};
  const stateNames = Object.keys(machine);
  stateNames.forEach(stateName => {
    const epoch = machine[stateName]?.epoch;
    if (epoch) {
      if (!mapper[epoch]) {
        mapper[epoch] = [];
      }
      mapper[epoch].push(stateName);
    }
  });
  return mapper;
}
