import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

export default function isAutoComplete(value: any) {
  return (
    _isObject(value) &&
    !_isArray(value) &&
    ('predefinedValues' in value || 'freeResponse' in value)
  );
}
