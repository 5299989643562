import * as React from 'react';
import moment from 'moment-timezone';
import ListItem from '../basic/ListItem';
import StandardButton from '../basic/StandardButton';
import { rruleToHumanReadableString } from '@curebase/core/lib/scheduling';
import { getLocaleFormatDates } from '../../context/localeContext';
import { useTranslation } from 'react-i18next';

type VisitAvailabilityLIProps = {
  onClick: () => any;
  getMoment: (time: number) => moment.Moment;
  rrule: Object;
  start: number;
  end: number;
  editFunc: () => any;
  deleteFunc: () => any;
  originalEnd: number;
  visitBookings: Object[];
  trialName: string;
};

const VisitAvailabilityLI = (props: VisitAvailabilityLIProps) => {
  const localesFormat = getLocaleFormatDates();
  const { t } = useTranslation('translations');
  const {
    onClick,
    getMoment,
    rrule,
    start,
    end,
    editFunc,
    deleteFunc,
    originalEnd,
    visitBookings,
    trialName,
  }: any = props;
  const startMoment = getMoment(start);
  const endMoment = getMoment(end);
  const buttons: any = [];
  if (editFunc)
    buttons.push({
      text: t('common.edit'),
      onClick: editFunc,
    });
  if (deleteFunc) {
    //DT - [TODO] - Implementing deleting a single instance of the recurring window
    buttons.push({
      text: t('common.delete'),
      onClick: deleteFunc,
    });
  }
  if (visitBookings?.length > 0)
    buttons.push({
      text: `${visitBookings.length} ${t(
        'visitAvailabilityLI.appointmentsLabel'
      )}`,
      onClick: onClick,
    });
  return (
    <ListItem
      // @ts-ignore
      left={{
        subtitle: `${trialName}`,
      }}
      middle={{
        title: startMoment.format(localesFormat.moment.availabilityFormat),
        subtitle: rrule?.byDay
          ? rruleToHumanReadableString(rrule, originalEnd, getMoment)
          : t('visitAvailabilityLI.noRepeatingLabel'),
        text: `${startMoment.format(
          localesFormat.moment.longTimeMeridiem
        )} - ${endMoment.format(
          localesFormat.moment.longTimeMeridiem
        )} (${startMoment.format('z')})`,
        buttons: buttons.map((b, ix) => <StandardButton {...b} key={ix} />),
      }}
    />
  );
};

export default VisitAvailabilityLI;
