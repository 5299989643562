import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { CSSProperties } from '@material-ui/styles';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

const xlarge = 'xlarge';

export type StandardButtonProps = {
  children?: React.ComponentType | React.ReactElement | React.ReactNode;
  className?: any;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  fullWidth?: boolean;
  onClick: (event?: any) => any;
  showLoading?: boolean;
  delayButtonUnlock?: boolean;
  secondDelayUnlock?: number;
  style?: CSSProperties;
  text?: any;
  size?: 'small' | 'medium' | 'large' | 'xlarge'; // override to add new xlarge size
  variant?: 'text' | 'outlined' | 'contained';
  loadingLabel?: string;
};

const getCountDownTime = (
  delayButtonUnlock?: boolean,
  secondDelayUnlock?: number
) => {
  return delayButtonUnlock
    ? Math.max(Math.round((secondDelayUnlock ?? 1000) / 1000) * 1000, 1000)
    : 0;
};

const StandardButton = (props: StandardButtonProps) => {
  const { t } = useOverridableTranslation('translations');
  const {
    children,
    className,
    color = 'primary',
    showLoading = false,
    size,
    style,
    variant,
    delayButtonUnlock,
    fullWidth = false,
    disabled,
    onClick = () => null,
    secondDelayUnlock,
    text,
    loadingLabel,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [countDown, setCountDown] = useState(
    getCountDownTime(delayButtonUnlock, secondDelayUnlock)
  );
  const [isDelayButtonUnlock, setDelayButtonUnlock] = useState(
    delayButtonUnlock
  );
  const extraLargeStyle = size === xlarge ? { height: '57px' } : {};
  const isDisabled = disabled || isDelayButtonUnlock;
  const timeoutRef = useRef<any>(null);

  const onButtonClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setLoading(true);
      onClick && (await onClick());
      setLoading(false);
    },
    [setLoading, onClick]
  );

  const renderText = useMemo((): string => {
    if (isDisabled && countDown) {
      return text + ` (${countDown / 1000})`;
    }
    return text;
  }, [text, countDown, isDisabled]);

  useEffect(() => {
    if (countDown < 1) return;
    let nextSec = countDown - 1000;
    setDelayButtonUnlock(!!nextSec);
    timeoutRef.current = setTimeout(() => {
      setCountDown(nextSec);
    }, 1000);
    return () => clearTimeout(timeoutRef.current);
  }, [countDown]);

  let content: React.ReactNode = <em>Loading</em>;
  if (!(showLoading && isLoading)) {
    content = (
      <>
        {renderText}
        {children}
      </>
    );
  }

  return (
    <Button
      color={color}
      className={`${classnames(style)} ${className}`}
      disabled={isDisabled || isLoading}
      onClick={onButtonClick}
      size={size === xlarge ? 'large' : size}
      style={Object.assign(
        { color, opacity: isDisabled ? 0.5 : undefined },
        style,
        extraLargeStyle
      )}
      variant={variant}
      fullWidth={fullWidth}
    >
      {isLoading ? loadingLabel || t('common.saving') : content}
    </Button>
  );
};

export default StandardButton;
