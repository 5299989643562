import { getSharedEvaluatorFromString } from '@curebase/core/lib/evaluators';
import { DataFieldDependsOn, DependsOnOperator } from '@curebase/core/types';
import evaluateValue from './evaluateValue';
import evaluateValues from './evaluateValues';

export type DependsOnConfig = DataFieldDependsOn;

function satisfiesSingleDependency(dependsOn: DependsOnConfig, data: any) {
  //use dataFieldId if specified, otherwise use key
  const key = dependsOn.dataFieldId ?? dependsOn.key;
  const valueToTest = data[key];
  const answerIsAnArray = Array.isArray(valueToTest);

  if (!valueToTest) {
    return false;
  } else if (dependsOn.value) {
    return dependsOn.isNotEqualTo
      ? !evaluateValue(answerIsAnArray, valueToTest, dependsOn.value)
      : evaluateValue(answerIsAnArray, valueToTest, dependsOn.value);
  } else if (dependsOn.values) {
    return dependsOn.isNotEqualTo
      ? !evaluateValues(answerIsAnArray, valueToTest, dependsOn?.values)
      : evaluateValues(answerIsAnArray, valueToTest, dependsOn?.values);
  } else if (
    dependsOn.evaluator &&
    !getSharedEvaluatorFromString(dependsOn.evaluator)(valueToTest)
  ) {
    return false;
  }
  return true;
}

export default function dependencyMet(
  dependsOn: DependsOnConfig | DependsOnConfig[] | undefined,
  operator: DependsOnOperator | undefined,
  data: any
) {
  const dependsOnConfigs = !dependsOn
    ? []
    : Array.isArray(dependsOn)
    ? dependsOn
    : [dependsOn];

  if (dependsOnConfigs?.length === 0) {
    return true;
  }

  operator = operator ?? DependsOnOperator.And;

  const results = dependsOnConfigs.map(dependsOn =>
    satisfiesSingleDependency(dependsOn, data)
  );

  return operator === DependsOnOperator.Or
    ? results.some(Boolean)
    : results.every(Boolean);
}
