import produce from 'immer';
import _ from 'lodash';
import {
  INIT_TRIAL,
  EDIT_TRIAL,
  ADD_STUDYPLANVISIT,
  EDIT_STUDYPLANVISIT,
  InitTrialAction,
  EditTrialAction,
  AddStudyPlanVisitAction,
  EditStudyPlanVisit,
  Action,
} from '../../actions/TrialBuilder/types';
import { Trial } from '@curebase/core/types';
import { TRIAL_REFS_FIELDS } from '../../../components/TrialBuilder/types';

const initialTrialState: Trial & any = {}; //[DT] - do something here for when we create a new trial

export const _initTrial = (action: InitTrialAction) => {
  const { payload }: any = action;
  const trial = _.omit(payload.trial, ['studyPlanVisits']);
  trial.sponsor = _.pick(payload.sponsor, ['slug', 'name']);
  return trial;
};

const _editTrial = (state: Trial, action: EditTrialAction) => {
  let updates: any = _.chain(action.payload.updates)
    .mapValues((value: any, key) => {
      if (TRIAL_REFS_FIELDS.includes(key)) {
        return value.key;
      }
      return value;
    })
    .value();
  if (updates.groups) {
    let updatedGroups = produce(updates.groups, draft => {
      draft.forEach(grp => {
        // Remove these from the trial object in the state
        // since they are saved in the refs object instead. (see refs.ts)
        delete grp.overrideMessage;
        delete grp.overrideMessages;
      });
    });

    updates.groups = updatedGroups;
  }

  let nextState = {
    ...state,
    ...updates,
    edited: true,
  };

  return nextState;
};

const _updateSpvFields = (
  state: Trial,
  action: AddStudyPlanVisitAction | EditStudyPlanVisit
) => {
  const nextState = { ...state };
  const updates = (action.payload as any).updates
    ? (action.payload as any).updates
    : action.payload;
  if (updates.slug.includes('prescreening'))
    nextState.prescreeningSurveySlug = updates.slug;
  return nextState;
};

export default function (state: Trial = initialTrialState, action: Action) {
  switch (action.type) {
    case ADD_STUDYPLANVISIT:
    case EDIT_STUDYPLANVISIT:
      return _updateSpvFields(state, action);
    case INIT_TRIAL:
      return _initTrial(action);
    case EDIT_TRIAL:
      return _editTrial(state, action);
    default:
      return state;
  }
}
