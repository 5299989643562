import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface MenuItemProps {
  text: string;
  value: string;
}

interface MenuProps {
  onChange: (newValue: string) => any;
  value: string;
  items: MenuItemProps[];
}

interface SubheaderProps {
  text?: string;
  menu?: MenuProps | null | undefined;
  buttons?: React.ReactElement<any>[];
  subText?: string;
}

const Subheader = (props: SubheaderProps) => {
  const { buttons, menu, text, subText } = props;
  return (
    <div className='subheader-container'>
      <div className='subheader-title'>
        <div className='subheader-text'>{text}</div>
        {subText && <div className='subheader-subtext'>{subText}</div>}
      </div>

      {menu && (
        <div className='subheader-menu'>
          <Select
            fullWidth
            value={menu.value}
            onChange={event => {
              menu.onChange(event.target.value as string);
            }}
          >
            {menu.items.map((item, i) => (
              <MenuItem key={i} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      {buttons &&
        buttons.map((button, index) => {
          return (
            <div className='subheader-button' key={index}>
              {button}
            </div>
          );
        })}
    </div>
  );
};

export default Subheader;
