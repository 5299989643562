import moment from 'moment';

export function age_between_60_84(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 60 && years <= 84;
}

export function colonoscopy_7_120_days_in_future(dateOfColonoscopy: string) {
  const days = moment().diff(moment(dateOfColonoscopy), 'days');
  return days <= -7 && days >= -120;
}
