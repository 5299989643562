/**
 * THIS FILE SHOULD NOT IMPORT ANYTHING FROM THE REST OF THE APP WITH THE EXCEPTION OF ENV/GIT_COMMIT FILES
 */

import { createBrowserHistory } from 'history';
import { showExitPatientModeDialog } from './store/actions';

const USE_LOCAL_FULLSTORY = false;

function getUserConfirmation(message, callback) {
  if (message === 'ATTEMPT_TO_EXIT_PATIENT_MODE') {
    showExitPatientModeDialog(() => {
      callback(true);
    });
  } else {
    callback(window.confirm(message));
  }
}
const history = createBrowserHistory({ getUserConfirmation });

export { USE_LOCAL_FULLSTORY, history };
