import { GroupConfig, HelpContentData } from '@curebase/core/types';
export const SPONSOR_REFS = ['sponsorSummary'];

//Properties on Trial model that represent refs in object form
export const TRIAL_OBJECT_REFS = ['helpContent', 'notifications'];

//Properties on Trial model that represent refs in string (markdown) form
export const TRIAL_STRING_REFS = [
  'schedulingMessageTemplate',
  'prescreeningPassedTemplate',
  'prescreeningFailedTemplate',
];

//All properties on Trial model that are refs
export const TRIAL_REFS_FIELDS = [...TRIAL_OBJECT_REFS, ...TRIAL_STRING_REFS];

export const SPONSOR_TEMPLATE = {
  branding: {
    logo: '/Demo_Pharma_Logo.png',
    primaryColor: 'ffffff',
    secondaryColor: '444444',
  },
  pricing: { curebaseCharge: 0 },
};

export const SPONSOR_SUMMARY_TEMPLATE = {
  language: 'en',
  content: {
    data: {
      aboutText:
        'Irving St. Pharmaceuticals is a global drug company, using proprietary biotechnology methods to engineer new, life-changing medical products for diabetes patients.\n',
    },
  },
};

export const NOTIFICATIONS_TEMPLATE = {
  language: 'en',
  content: {
    data: {},
  },
};

export const TRIAL_TEMPLATE = {
  groups: [
    {
      size: 10,
      label: 'Control',
      slug: 'control',
    },
    {
      size: 10,
      label: 'Treatment',
      slug: 'treatment',
    },
  ],
};

export const TRIAL_SUMMARY_TEMPLATE = {
  language: 'en',
  content: {
    data: {
      shortDescription:
        'Change me! This is a template short description for the trial summary',
      sections: [
        {
          title: 'The Quit Genius App as a suitable intervention',
          text:
            'Quit Genius (QG) is the world’s first gamified mobile application developed to deliver highly personalised, high-intensity behavioural support to smokers wanting to quit.',
        },
      ],
    },
  },
};

export interface NotificationData {
  name?: string;
  subject?: string;
  body?: string;
}
export interface NotificationRefs {
  data: Array<NotificationData>;
  language: string;
}

export interface GroupConfigDialogRefs
  extends Omit<GroupConfig, 'overrideMessage'> {
  overrideMessages?: Array<{ language: string; overrideMessage: string }>;
}
interface TextRef {
  data: Array<{ language: string; content: string }>;
  key: string;
}

type HelpContents = {
  data: HelpContentData | null;
  language: string;
};
export interface TrialDialogRefs {
  groups?: Array<GroupConfigDialogRefs>;
  notifications?: Array<NotificationRefs>;
  schedulingMessageTemplate?: TextRef;
  prescreeningPassedTemplate?: TextRef;
  prescreeningFailedTemplate?: TextRef;
  helpContent?: HelpContents[];
}
