import _chunk from 'lodash/chunk';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _isNumber from 'lodash/isNumber';
import _range from 'lodash/range';
import { DateTime } from 'luxon';
import {
  CapturedData,
  CaseReportDataType,
  DataField,
} from '@curebase/core/types';
import {
  DrugBankOptions,
  SliderAdditionalSettings,
  SliderMajorLabel,
} from '@curebase/core/lib/dynamicform/types';
import { flattenCapturedData } from '@curebase/modules/dataCapture/services';
import {
  CaseReport,
  CaseReportDisplayMode,
  DataCaptureReserved,
  OptionalityVisibility,
} from 'src/types';
import { getLocale, getLocaleFormatDates } from 'src/context/localeContext';
import i18n from 'src/i18n';
import { getReadableValueFromCapturedData as coreGetReadableValueFromCapturedData } from '@curebase/core/lib/dynamicform/caseReport';

const translate = value => i18n.t(value, value);

const {
  Autocomplete,
  Checkbox,
  Computed,
  Date: TDate, //not to confuse with the Date object
  MonthPicker,
  Time,
  Datetime,
  Dropdown,
  File,
  List,
  Multicheckbox,
  Multiselect,
  Number: TNumber,
  Paragraph,
  PhoneNumber,
  Signature,
  Slider,
  Text,
  YesNo,
  TelehealthFileUpload,
  ContentBlock,
  FuzzyDatePicker,
  Height,
  DrugbankAutocomplete,
  Address,
} = CaseReportDataType;

type FormElement = {
  capturedData: any;
  dataField: any;
  values: Array<any>;
};

export function getReadableValueFromCapturedData(
  dataFieldConfig: Pick<DataField, 'dataType' | 'options'>,
  value: Pick<CapturedData, 'value'>
) {
  const { locale } = getLocale();
  const localesFormat = getLocaleFormatDates();
  if (!value) return '';

  if (
    dataFieldConfig.dataType === CaseReportDataType.Datetime &&
    value !== DataCaptureReserved.NotApplicableDueToDependency
  ) {
    return DateTime.fromMillis(value as number).toFormat(
      localesFormat.luxon.dataCaptureResults,
      { locale }
    );
  }
  return coreGetReadableValueFromCapturedData(dataFieldConfig, value);
}

export function getChangeList(element: FormElement) {
  const { capturedData, dataField, values } = element;
  const {
    // value, author, submittedOn->at
    capturedDataChanges,
    editReason,
    authoredByUser,
    submittedOn,
  } = capturedData;
  let edits = capturedDataChanges.map(
    ({ value, authorUser, submittedOn, editReason }) => ({
      // if there are no changes, then dont add the current value
      value: getReadableValueFromCapturedData(dataField, value),
      author: authorUser,
      createdAt: submittedOn,
      editReason,
    })
  );

  if (edits.length > 0) {
    // oldest first
    edits.push({
      // set the prior value
      value: values[0],
      editReason,
      author: authoredByUser,
      createdAt: submittedOn,
    });
  }

  edits.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  edits = edits.map((m, i) => {
    // remove the initial data submission
    if (i > 0) {
      m.priorValue = edits[i - 1].value;
    }

    return m;
  });
  edits.shift();
  return edits;
}

export type DataFieldImportantProperties = Pick<
  DataField,
  | 'id'
  | 'allowFloat'
  | 'checkboxText'
  | 'dataType'
  | 'dependsOn'
  | 'dependsOnOperator'
  | 'description'
  | 'horizontalAxisMarkers'
  | 'isHorizontal'
  | 'order'
  | 'max'
  | 'min'
  | 'name'
  | 'optional'
  | 'options'
  | 'slug'
  | 'contentBlock'
  | 'additionalSettings'
  | 'locales'
  | 'isHiddenFromUser'
>;

export function dataFieldToSubElement(
  dataField: DataFieldImportantProperties,
  caseReport: Pick<CaseReport, 'optionalityVisibility'>,
  dataFieldSubElemKeyOverride?: Record<string, string>
) {
  const { optionalityVisibility } = caseReport;
  const { optional, isHiddenFromUser } = dataField;
  const subElement = getElementSpecificAttributes(
    dataField,
    dataFieldSubElemKeyOverride
  );
  const optionalityText =
    optionalityVisibility === OptionalityVisibility.ShowOptional
      ? optional
        ? ` ${translate('dataCapture.participants.optional')}`
        : ''
      : optionalityVisibility === OptionalityVisibility.ShowAsterix
      ? optional
        ? ''
        : ' *'
      : '';
  const { locale } = getLocale();
  const { locales } = dataField;
  const title = locales?.[locale]?.name ?? dataField.name;
  const description = locales?.[locale]?.description ?? dataField.description;
  return {
    key: subElement.key,
    keySlug: dataField.slug,
    title: title + optionalityText,
    note: description,
    dependsOn: dataField.dependsOn,
    dependsOnOperator: dataField.dependsOnOperator,
    subElements: [subElement],
    isHiddenFromUser,
  };
}

//[DT]: import into DynamicForm
type DynamicFormSubElement = {
  allowFloat?: boolean;
  allowNull: boolean;
  horizontalAxisMarkers?: any[];
  isHorizontal?: boolean;
  key: string;
  max?: number | null;
  min?: number | null;
  noAutocomplete?: boolean;
  options?: {
    text: string;
    value?: any;
    required?: boolean;
    placeholder?: string | null;
  }[];
  placeholder?: string;
  skipValidateOnChange?: boolean;
  validate?: (value: string) => any;
  text?: string | null;
  type: CaseReportDataType;
  contentBlock?: string | null;
  additionalSettings?: {
    freeSolo?: boolean | null;
    multiple?: boolean | null;
    formatAsId?: boolean | null;
    step?: number | null;
    slider?: SliderAdditionalSettings;
    medications?: DrugBankOptions;
  };
};

/**
 * This function translates the information for a particular configured data field
 * in  the DataField table and returns an object that is consumable
 *  as props for the component it corresponds to.
 *
 * For example, in the case that the dataType of the element is "SLIDER",
 * then this function will accept the database entry for this particular datafield
 * and map each of the columns to the appropriate corresponding values for a slider.
 * You can check those out in the SliderAdditionalSettings type. This data will then
 * be used to pass the correct props to the <CSlider /> component. See the renderSliderSubElement()
 * function in the DynamicForm.tsx file.
 */
function getElementSpecificAttributes(
  dataField: DataFieldImportantProperties,
  dataFieldSubElemKeyOverride?: Record<string, string>
): DynamicFormSubElement {
  const { locale } = getLocale();
  const key = !dataFieldSubElemKeyOverride
    ? dataField.id
    : dataFieldSubElemKeyOverride[dataField.id] ?? dataField.id;
  switch (dataField.dataType) {
    case Checkbox:
      return {
        type: Checkbox,
        text: dataField.checkboxText,
        allowNull: !!dataField.optional,
        key,
      };
    case File:
      return {
        type: File,
        allowNull: !!dataField.optional,
        key,
      };
    case TelehealthFileUpload:
      return {
        type: TelehealthFileUpload,
        allowNull: !!dataField.optional,
        key,
      };
    case Text:
      return {
        type: Text,
        allowNull: !!dataField.optional,
        key,
      };
    case TNumber:
      return {
        type: TNumber,
        allowNull: !!dataField.optional,
        key,
        min: dataField.min,
        max: dataField.max,
        allowFloat: !!dataField.allowFloat,
      };
    case PhoneNumber:
      return {
        type: PhoneNumber,
        allowNull: !!dataField.optional,
        key,
        placeholder: 'Phone number',
        noAutocomplete: true,
        skipValidateOnChange: true,
      };
    case Multiselect:
      const multiSelectOptions =
        dataField?.locales[locale]?.options ?? dataField.options;
      return {
        type: Multiselect,
        allowNull: !!dataField.optional,
        isHorizontal: !!dataField.isHorizontal,
        horizontalAxisMarkers: dataField.horizontalAxisMarkers,
        key,
        options: multiSelectOptions?.map(option => ({
          text: option.text,
          value: option.value,
          url: option.url,
        })),
      };
    case Multicheckbox:
      const multicheckboxOptions =
        dataField?.locales[locale]?.options ?? dataField.options;
      return {
        type: Multicheckbox,
        allowNull: !!dataField.optional,
        isHorizontal: !!dataField.isHorizontal,
        horizontalAxisMarkers: dataField.horizontalAxisMarkers,
        key,
        options: multicheckboxOptions?.map(option => ({
          text: option.text,
          value: option.value,
          url: option.url,
        })),
      };
    case YesNo:
      return {
        type: YesNo,
        allowNull: !!dataField.optional,
        key,
      };
    case TDate:
      return {
        type: TDate,
        allowNull: !!dataField.optional,
        key,
      };
    case MonthPicker:
      return {
        type: MonthPicker,
        allowNull: !!dataField.optional,
        key,
      };
    case Time:
      return {
        type: Time,
        allowNull: !!dataField.optional,
        key,
      };
    case Datetime:
      return {
        type: Datetime,
        allowNull: !!dataField.optional,
        key,
      };
    case Dropdown:
      return {
        type: Dropdown,
        allowNull: !!dataField.optional,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          value: option.value,
        })),
      };
    case Autocomplete:
      const options = dataField?.locales[locale]?.options ?? dataField.options;
      return {
        type: Autocomplete,
        allowNull: !!dataField.optional,
        key,
        options: options?.map(option => ({
          text: option.text,
          value: option.value,
        })),
        additionalSettings: {
          freeSolo: dataField?.additionalSettings?.freeSolo,
          multiple: dataField?.additionalSettings?.multiple,
          formatAsId: dataField?.additionalSettings?.formatAsId,
        },
      };
    case DrugbankAutocomplete:
      return {
        type: DrugbankAutocomplete,
        allowNull: dataField?.optional ?? false,
        key,
        additionalSettings: {
          medications: {
            showIndication:
              dataField?.additionalSettings?.medications?.showIndication ||
              undefined,
            showFrequency:
              dataField?.additionalSettings?.medications?.showFrequency ||
              undefined,
          },
        },
      };
    case List:
      return {
        type: List,
        allowNull: false,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          placeholder: option.placeholder,
          required: !!option.required,
        })),
      };
    case Paragraph: {
      return {
        type: Paragraph,
        allowNull: dataField.optional ?? false,
        key,
      };
    }
    case Signature: {
      return {
        type: Signature,
        allowNull: dataField.optional ?? false,
        key,
      };
    }
    case Slider:
      const additionalSettings =
        dataField?.locales[locale]?.additionalSettings ??
        dataField?.additionalSettings;
      const horizontalAxisMarkers =
        dataField?.locales[locale]?.horizontalAxisMarkers ??
        dataField?.horizontalAxisMarkers;
      return {
        type: Slider,
        allowNull: !!dataField.optional,
        horizontalAxisMarkers: horizontalAxisMarkers,
        key,
        min: dataField.min,
        max: dataField.max,
        additionalSettings: {
          step: additionalSettings?.step,
          slider: {
            orientation:
              (additionalSettings?.slider?.orientation as
                | 'vertical'
                | 'horizontal') || undefined,
            showSelection:
              additionalSettings?.slider?.showSelection || undefined,
            majorLabel:
              (additionalSettings?.slider?.majorLabel as SliderMajorLabel) ||
              undefined,
            selectionLabel:
              additionalSettings?.slider?.selectionLabel || undefined,
          },
        },
      };
    case ContentBlock:
      return {
        type: ContentBlock,
        allowNull: true,
        contentBlock:
          dataField?.locales[locale]?.contentBlock ?? dataField.contentBlock,
        key,
      };
    case FuzzyDatePicker:
      return {
        type: FuzzyDatePicker,
        allowNull: !!dataField.optional,
        key,
      };
    case Height:
      return {
        type: Height,
        allowNull: dataField.optional ?? false,
        key,
        min: dataField.min,
        max: dataField.max,
      };
    case Address:
      return {
        type: Address,
        allowNull: false,
        key,
      };
    default:
      throw new Error(
        `[lib/caseReports:getElementSpecificAttributes]: unknown DataField dataType {${dataField?.dataType}}`
      );
  }
}

export type CaseReportDynamicForm = {
  dataFields: ReadonlyArray<DataFieldImportantProperties>;
  knowledgeContent?: any;
} & Pick<CaseReport, 'optionalityVisibility' | 'displayMode'>;

export function generateDynamicFormPagesFromCaseReport(
  caseReport: CaseReportDynamicForm,
  dataFieldSubElemKeyOverride?: Record<string, string>
) {
  const elements: ReturnType<typeof dataFieldToSubElement>[] = [];
  const sortedDFs = [...caseReport.dataFields].sort(
    (a, b) => a.order - b.order
  );
  _forEach(sortedDFs, dataField => {
    if (dataField.dataType !== Computed) {
      try {
        elements.push(
          dataFieldToSubElement(
            dataField,
            caseReport,
            dataFieldSubElemKeyOverride
          )
        );
      } catch (err) {
        console.error(err);
      }
    }
  });

  let elementsPerPage: number;
  switch (caseReport.displayMode) {
    case CaseReportDisplayMode.Default:
      elementsPerPage = 4;
      break;
    case CaseReportDisplayMode.OneQuestionPerPage:
      elementsPerPage = 1;
      break;
    case CaseReportDisplayMode.EntireCrfOnSinglePage:
      elementsPerPage = elements.length;
      break;
    default:
      const n: any = caseReport.displayMode;
      throw new Error(
        `[DynamicForm]: unhandled case of CaseReportDisplayMode ${n}`
      );
  }

  const elementsByPage = _filter(
    _chunk(elements, elementsPerPage),
    eles => eles.length > 0
  );

  const keyToPageMap: { [subElementKey: string]: number } = {};
  let processedElementsByPage: typeof elementsByPage = [];

  //Ensure dependencies are on the same page
  for (const i of _range(elementsByPage.length)) {
    const pageEles = elementsByPage[i];
    processedElementsByPage[i] = [];

    for (const ele of pageEles) {
      const { dependsOn } = ele;
      //if there is a dependency, add to the page with the dependency
      if (dependsOn && dependsOn.length > 0) {
        //find the last dependent and insert the element into the same page
        const pageOfParentDataFieldArr = dependsOn
          .map(({ dataFieldId: id }) => keyToPageMap[id])
          .filter(_isNumber);

        if (pageOfParentDataFieldArr?.length > 0) {
          const pageOfParentDataField = pageOfParentDataFieldArr.reduce(
            (a, b) => Math.max(a, b)
          ); //find the biggest page number or set to i as fallback
          processedElementsByPage[pageOfParentDataField].push(ele);
          keyToPageMap[ele.key] = pageOfParentDataField;
        }
      } else {
        keyToPageMap[ele.key] = i;
        processedElementsByPage[i].push(ele);
      }
    }
  }

  processedElementsByPage = _filter(
    processedElementsByPage,
    eles => eles.length > 0
  );

  return processedElementsByPage.map(elements => ({
    elements,
    knowledgeContent: caseReport.knowledgeContent,
  }));
}

export function buildFormDataForSubmit(
  visitId: number,
  capturedData: Record<string, Record<string, any>>,
  currentDate?: number
) {
  const formData = {};
  formData['capturedData'] = flattenCapturedData(capturedData);
  formData['visitId'] = visitId;

  if (currentDate) {
    formData['currentDate'] = currentDate;
  }

  return formData;
}

export function slugify(name: string, trimTo: boolean | number = true): string {
  trimTo = trimTo === false ? false : trimTo === true ? 15 : trimTo;
  if (name === null) return '';
  if (trimTo) name = name.slice(0, trimTo);
  if (name[name.length - 1] === ' ') name = name.slice(0, name.length - 1);
  return name
    .replace(/\n|\r/g, '') // remove linebreaks
    .split(' ')
    .join('_')
    .toLowerCase();
}

export function superSlugify(name: string): string {
  return name.trim().replace(/([\n\r ]){1,}/g, '_');
}
