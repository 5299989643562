import { AppTheme } from './AppTheme';

const walgreensTheme: AppTheme = {
  logo: {
    default: '/logo/walgreens.png',
  },
  colors: {
    primary: '#E62600',
    secondary: '#323264',
  },
  contrast: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
  },
  texts: {
    hover: '#284B9B',
    headers: '#323264',
    body: '#0A1414',
  },
};

export default walgreensTheme;
