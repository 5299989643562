import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import produce from 'immer';
import Header from '../basic/Header';
import Subheader from '../basic/Subheader';
import DynamicForm from '../basic/DynamicForm';
import ListItem from '../basic/ListItem';
import StandardButton from '../basic/StandardButton';

import { withQueryResult } from '../hocs/WithQueryResult';
import {
  SiteConfigEditor,
  SiteConfigEditorQuery,
  SiteConfigEditorQueryVariables,
  SiteManagementConfig,
} from '@curebase/core/types';
import TrialInstanceManagement from './TrialInstanceManagement';
import { formatSiteManagementConfig } from '@curebase/core/lib/changemanagement';
import {
  saveSiteConfig,
  deploySiteConfig,
} from '../../controllers/changemanagementController';

import Dialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { useTranslation } from 'react-i18next';

type SourceProps = {
  match: any;
};

type Props = SourceProps & {
  queryResult: SiteConfigEditorQuery;
  data: any;
};

type DialogAction = 'Close' | 'Save' | 'Deploy' | 'Error' | 'Success';
type DialogState = {
  open: boolean;
  message: string;
  confirmationText?: string;
  onConfirm?: (configName: string, data: Object) => any;
};
const initialDialogState: DialogState = {
  open: false,
  message: '',
};

const SiteConfigEditorView = (props: Props) => {
  const { t } = useTranslation('translations');
  const { getSiteManagementConfig: config } = props.queryResult;

  const { id, name, trialInstances } = config;
  const clinicName = config.clinic?.name ?? name;
  const slug = config.clinic?.slug ?? config.slug;
  const [data, setData] = React.useState({ id, name, slug, trialInstances });
  const params = useParams<Record<string, string>>();
  const [configName, setConfigName] = React.useState(params.configName);
  const [xhrInProgress, setXhrInProgress] = React.useState(false);
  const history = useHistory();

  const saveConfig = async (configName, data) => {
    setXhrInProgress(true);
    const result = await saveSiteConfig(
      formatSiteManagementConfig(data) as SiteManagementConfig,
      configName
    );
    setXhrInProgress(!result);
    return result;
  };

  const deployConfig = async (configName, data) => {
    setXhrInProgress(true);
    const result = await deploySiteConfig(data.id, configName);
    setXhrInProgress(!result);
    return result;
  };

  const [dialogState, dispatch] = React.useReducer(
    (state: DialogState, action: DialogAction) => {
      switch (action) {
        case 'Save':
          return {
            open: true,
            message: t('siteConfigEditorView.saveMsg'),
            confirmationText: t('common.save'),
            onConfirm: saveConfig,
          };
        case 'Deploy':
          return {
            open: true,
            message: t('siteConfigEditorView.deployMsg'),
            confirmationText: t('common.deploy'),
            onConfirm: deployConfig,
          };
        case 'Close':
          return {
            ...state,
            open: false,
          };
        case 'Error':
          return {
            ...state,
            confirmationText: t('siteConfigEditorView.errorText'),
            message: t('siteConfigEditorView.errorMsg'),
          };
        case 'Success':
          return {
            open: true,
            message: t('siteConfigEditorView.sucessMsg'),
          };
        default:
          return initialDialogState;
      }
    },
    initialDialogState
  );
  const { open: dialogOpen, onConfirm, ...confirmationProps } = dialogState;

  const addTrialInstance = React.useCallback(
    (
      trialId: number,
      {
        id,
        name,
        trialIdentifier,
      }: { id: number; name: string; trialIdentifier: string }
    ) => {
      setData(data =>
        produce(data, data => {
          if (
            data.trialInstances.find(x => x.trialIdentifier === trialIdentifier)
          )
            return;
          data.trialInstances.push({
            deactivated: false,
            trialIdentifier,
            trial: { id, name, trialIdentifier },
          });
        })
      );
    },
    [setData]
  );

  const deactivateTrialInstance = React.useCallback(
    (trialIdentifier: string) =>
      setData(data =>
        produce(data, data => {
          const trialInstance = data.trialInstances.find(
            x => x.trialIdentifier === trialIdentifier
          );
          if (!trialInstance) return;
          trialInstance.deactivated = !trialInstance.deactivated;
        })
      ),
    [setData]
  );

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => dispatch('Close')}>
        <ConfirmationInDialog
          {...confirmationProps}
          onConfirm={
            onConfirm
              ? async () => {
                  const success = await onConfirm(configName, data);
                  if (success) dispatch('Success');
                  else dispatch('Error');
                }
              : undefined
          }
          disableOnConfirm={xhrInProgress}
          title={t('siteConfigEditorView.dialogTitle')}
          onClose={() => dispatch('Close')}
        />
      </Dialog>

      <Header
        displayText={clinicName}
        textContent={t('siteConfigEditorView.headerEdit', {
          configName: params.configName,
        })}
        back={() => history.goBack()}
      />
      <div className='li-multi-container inset'>
        <ListItem
          middle={{
            title: 'Change Management',
            buttons: [
              <StandardButton
                key='save-button'
                text={t('common.save')}
                color='primary'
                onClick={() => dispatch('Save')}
              />,
              <StandardButton
                key='deploy-button'
                text={t('common.deploy')}
                color='secondary'
                onClick={() => dispatch('Deploy')}
              />,
            ],
          }}
        />
      </div>

      <Subheader text='Research Team details' />
      <div className='li-multi-container inset'>
        <ListItem>
          <DynamicForm
            pages={[
              {
                elements: [
                  {
                    title: t('siteConfigEditorView.pages.configName.title'),
                    subElements: [
                      {
                        key: 'configName',
                        type: 'TEXT',
                      },
                    ],
                  },
                  {
                    title: t('siteConfigEditorView.pages.name.title'),
                    subElements: [
                      {
                        key: 'name',
                        type: 'TEXT',
                        placeholder: t(
                          'siteConfigEditorView.pages.name.placeholder'
                        ),
                      },
                    ],
                  },
                  {
                    title: t('siteConfigEditorView.pages.slug.title'),
                    subElements: [
                      {
                        key: 'slug',
                        type: 'TEXT',
                        placeholder: t(
                          'siteConfigEditorView.pages.slug.placeholder'
                        ),
                      },
                    ],
                  },
                ],
              },
            ]}
            onChange={(key, value) => {
              if (key === 'configName') setConfigName(value);
              else
                setData(data =>
                  produce(data, data => {
                    data[key] = value;
                  })
                );
            }}
            data={{ ...data, configName }}
            noSubmit
          />
        </ListItem>
      </div>

      <TrialInstanceManagement
        callbacks={{
          addTrialInstance,
          deactivateTrialInstance,
        }}
        // @ts-ignore
        instances={data.trialInstances}
        refetch={props.data.refetch}
      />
    </>
  );
};

const SiteConfigEditorComponent = withQueryResult<
  SiteConfigEditorQueryVariables,
  SiteConfigEditorQuery,
  SourceProps
>(SiteConfigEditorView, SiteConfigEditor, (props: any): any => ({
  clinicId: props.match.params.clinicId,
  configName: props.match.params.configName,
}));

export default SiteConfigEditorComponent;
