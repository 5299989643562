import {
  DUPLICATED_SCHEDULED_CONFIGURATION_ERROR_MESSAGE,
  SPV_WITH_OPEN_VISIT_ERROR_MESSAGE,
} from '@curebase/core/lib/unscheduledActivities';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { newUnscheduledActivity } from '../../../controllers/unscheduledActivitiyController';
import Dialog from '../../basic/SafeDialog';
import DialogHeader from '../../basic/DialogHeader';
import DynamicForm from '../../basic/DynamicForm';
import {
  CaseReportDataType,
  StudyActivityConfig,
  StudyActivityType,
  TrialOptionUnscheduledActivitiesQueryVariables,
  useTrialOptionUnscheduledActivitiesQuery,
} from 'src/types';
import { TrialOptionStudyPlanContext } from '../TrialOptionStudyPlan';
import { useTranslation } from 'react-i18next';

interface Props {
  trialOptionId: number;
}

function AddNewUnscheduledActivityDialog(props: Props) {
  const history = useHistory();
  const { t } = useTranslation('translations');
  const { trialOptionId } = props;

  const [state, setState] = useState<Record<string, any>>({});
  const [error, setError] = useState<string | null>(null);

  const { refetch: trialOptionStudyPlanRefetch } = React.useContext(
    TrialOptionStudyPlanContext
  );

  const variables: TrialOptionUnscheduledActivitiesQueryVariables = {
    trialOptionId: trialOptionId,
  };

  const result = useTrialOptionUnscheduledActivitiesQuery({
    variables,
  });

  const { loading, data } = result;

  const trialOption = data?.getTrialOption;
  const unscheduledStudyActivities =
    trialOption?.trialConfiguration?.unscheduledStudyActivities;

  const subtitle = () => {
    return (
      <div className='unscheduled-activities-instructions'>
        {t('addUnscheduledActivityDialog.dialogSubtitle')}
      </div>
    );
  };

  const assentInstructions: any[] = [
    {
      header: subtitle(),
      subElements: [],
    },
  ];

  const elements = [
    ...assentInstructions,
    {
      title: t('addUnscheduledActivityDialog.elements.type.title'),
      readOnly: true,
      subElements: [
        {
          key: 'type',
          type: CaseReportDataType.Autocomplete,
          options: [
            {
              value: StudyActivityType.DataCapture,
              text: t('addUnscheduledActivityDialog.elements.type.options.spv'),
            },
            {
              value: StudyActivityType.ScheduleVisit,
              text: t(
                'addUnscheduledActivityDialog.elements.type.options.scheduleVisit'
              ),
            },
          ],
          optional: false,
          allowNull: false,
        },
      ],
    },
    {
      title: t('addUnscheduledActivityDialog.elements.slug.title'),
      subElements: [
        {
          key: 'slug',
          type: CaseReportDataType.Autocomplete,
          options: () => [
            ...(unscheduledStudyActivities ?? [])
              .filter(
                (config: StudyActivityConfig) =>
                  config.type === state?.type?.predefinedValues?.[0]
              )
              .map(config => ({
                value: config?.configId,
                text: config?.label,
              })),
          ],
          optional: false,
          allowNull: false,
        },
      ],
    },
  ];

  const onClose = () => {
    history.goBack();
    trialOptionStudyPlanRefetch();
  };

  const dialogTitle = t('addUnscheduledActivityDialog.dialogTitle');

  if (loading) {
    return (
      <Dialog className='themed-dialog' open onClose={onClose}>
        <DialogHeader title={dialogTitle} onClose={onClose}>
          <Skeleton />
        </DialogHeader>

        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Dialog>
    );
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogHeader title={dialogTitle} onClose={onClose} />
      <DynamicForm
        // @ts-ignore
        pages={[{ elements }]}
        onChange={(key, value) => {
          setState({ ...state, [key]: value } as any);
        }}
        data={state}
        errors={{ slug: error }}
        onSubmit={async () => {
          try {
            const params = {
              trialOptionId,
              type: state?.type?.predefinedValues[0],
              studyPlanVisitSlug: undefined,
              schedulingConfigurationId: undefined,
            };

            if (!state?.slug?.predefinedValues?.[0]) {
              throw new Error(t('dynamicForm.validation.pages.cannotEmpty'));
            }

            if (params.type === StudyActivityType.DataCapture) {
              params.studyPlanVisitSlug = state?.slug.predefinedValues[0];
            }

            if (params.type === StudyActivityType.ScheduleVisit) {
              params.schedulingConfigurationId =
                state?.slug.predefinedValues[0];
            }

            await newUnscheduledActivity(params);

            trialOptionStudyPlanRefetch();
            history.goBack();
          } catch (e) {
            if (
              e.message === DUPLICATED_SCHEDULED_CONFIGURATION_ERROR_MESSAGE
            ) {
              setError(
                t(
                  'addUnscheduledActivityDialog.errors.duplicatedSchedulingConfig'
                )
              );
              return;
            }

            if (e.message === SPV_WITH_OPEN_VISIT_ERROR_MESSAGE) {
              setError(
                t('addUnscheduledActivityDialog.errors.spvWithOpenVisit')
              );
              return;
            }

            setError(e.message);
          }
        }}
      />
    </Dialog>
  );
}

export default AddNewUnscheduledActivityDialog;
