/**
 * Reducer associated with actions that impact the state of DataFields.
 * For now, it works as a single exported function that takes care of all
 * actions, but in the future will need to be broken up into several methods and
 * eventually files.
 */

import { v4 as uuidv4 } from 'uuid';
import _assign from 'lodash/assign';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _transform from 'lodash/transform';
import produce from 'immer';
import {
  Action,
  ADD_CASEREPORT,
  ADD_DATAFIELD,
  AddCaseReportAction,
  AddDataFieldAction,
  APPLY_CHANGES_FROM_DB,
  ApplyChangeFromDBAction,
  DELETE_ALL_INSTANCES_OF_CRF,
  DELETE_DATAFIELD,
  DELETE_STUDYPLANVISIT,
  DeleteDataFieldAction,
  EDIT_CASEREPORT,
  EDIT_DATAFIELD,
  EDIT_STUDYPLANVISIT,
  EditCaseReportAction,
  EditDataFieldAction,
  EditStudyPlanVisit,
  INIT_TRIAL,
  InitTrialAction,
  TOGGLE_CASEREPORT,
  ToggleCaseReportAction,
} from '../../actions/TrialBuilder/types';
import { DataField } from '@curebase/core/types';
import { caseReportSlugFrom } from '../../../lib/trialBuilderHelpers';
import { getDataFieldDependsOn } from '../../selectors/TrialBuilder';

type DataFieldMap = Record<string, any>;

//searches for all dataField with the given caseReport slug (or simpleSlug)
const dataFieldsForCaseReport = (
  dataFields: DataFieldMap,
  caseReportSlug: string,
  simpleSlug: boolean
): Array<DataField> =>
  //[DT] - find better way to flow type Object.values() and .filter/.map
  // @ts-ignore
  Object.values(dataFields).filter(
    simpleSlug
      ? (df: any) => df.caseReport.simpleSlug === caseReportSlug
      : (df: any) => df.caseReport.slug === caseReportSlug
  );

//helper to format a new dataField from the same dataField but for a different caseReport
const newDataFieldFromOld = ({
  newDataFieldSimpleSlug,
  newDataFieldSlug,
  oldDataField,
  newCaseReportSlug,
  newCaseReportSimpleSlug,
  newStudyPlanVisitSlug,
}): {
  newDataFieldSimpleSlug: string;
  newDataFieldSlug: string;
  oldDataField: DataField;
  newCaseReportSlug: string;
  newCaseReportSimpleSlug: string;
  newStudyPlanVisitSlug: string;
} => {
  const df: any = _assign(
    _omit(oldDataField, [
      'id',
      'dependsOn',
      'simpleSlug',
      'slug',
      'caseReport',
      'studyPlanVisit',
      'isDeprecated',
    ]),
    {
      simpleSlug: newDataFieldSimpleSlug,
      slug: newDataFieldSlug,
      caseReport: {
        slug: newCaseReportSlug,
        simpleSlug: newCaseReportSimpleSlug,
      },
      studyPlanVisit: {
        slug: newStudyPlanVisitSlug,
      },
      dependsOn: (oldDataField.dependsOn || []).map(
        ({ key, value, values, evaluator, isNotEqualTo }) => {
          return {
            key: key.replace(oldDataField.caseReport.slug, newCaseReportSlug),
            value,
            values,
            evaluator,
            isNotEqualTo,
          };
        }
      ),
      isDeprecated: false,
      edited: true,
      new: true,
    }
  );
  return df;
};

const _addDataField = (state: DataFieldMap, action: AddDataFieldAction) => {
  const nextState = { ...state };
  const { payload }: any = action;
  if (!payload.id) _assign(payload, { new: true });
  if (!!nextState[payload.slug]) {
    const append = `_${uuidv4().slice(0, 4)}`;
    payload.slug += append;
    payload.simpleSlug += append;
  }
  _assign(nextState, { [payload.slug]: payload });
  return nextState;
};

const editDataField = produce(
  (state: DataFieldMap, action: EditDataFieldAction) => {
    const { slug, updates }: any = action.payload;
    let newSlug = updates.slug;

    //hack
    const dataFieldsWithDependents = getDataFieldDependsOn(
      {
        // @ts-expect-error
        trialBuilder: { data: { present: { dataFields: state } } },
      },
      slug
    );

    //slug didn't change
    if (slug === newSlug) {
      state[slug] = updates;
      state[slug].edited = true;

      //slug changed
    } else {
      state[slug].isDeprecated = true;
      state[slug].deleted = true;

      //there is a clash
      if (state[newSlug]) {
        const suffix = `_${uuidv4().slice(0, 4)}`;
        newSlug += suffix;
        state[newSlug] = updates;
        state[newSlug].slug += suffix;
        state[newSlug].simpleSlug += suffix;
        state[newSlug].new = true;
        state[newSlug].edited = true;

        //no clash
      } else {
        state[newSlug] = updates;
        state[newSlug].new = true;
        state[newSlug].edited = true;
      }
    }

    //enforce the order of the dependsOn
    dataFieldsWithDependents.forEach(df => {
      if (df.order < updates.order) {
        // @ts-expect-error
        df.dependsOn = df.dependsOn.filter(
          dependency => dependency.key !== slug
        );
      }
      if (slug !== newSlug) {
        // @ts-expect-error
        df.dependsOn.forEach(item => {
          // @ts-expect-error
          if (item.key === slug) item.key = newSlug;
        });
      }
    });
  }
);

const _deleteDataField = (
  state: DataFieldMap,
  action: DeleteDataFieldAction
) => {
  return produce(state, draft => {
    for (const [slug] of Object.entries(draft)) {
      if (slug === action.payload.slug) {
        delete draft[slug];
      }
    }
  });
};

const _applyChangesFromDB = (
  state: DataFieldMap,
  action: ApplyChangeFromDBAction
) => {
  const { dataFields } = action.payload;
  if (!dataFields) return state;
  return { ...state, ...dataFields };
};

export const _initTrial = (action: InitTrialAction & any) => {
  const nextState = {};
  const { studyPlanVisits } = action.payload.trial;
  if (!studyPlanVisits) return nextState;
  studyPlanVisits.forEach(spv =>
    spv.caseReports.forEach(cr =>
      cr.dataFields.forEach(df => {
        if (df.isDeprecated ?? false) {
          return;
        }

        _assign(df, {
          caseReport: { slug: cr.slug, simpleSlug: cr.simpleSlug },
          studyPlanVisit: { slug: spv.slug },
        });
        _assign(nextState, { [df.slug]: df });
      })
    )
  );
  return nextState;
};

const _editCaseReport = (
  state: DataFieldMap = {},
  action: EditCaseReportAction
) => {
  let nextState;
  const { slug, updates }: any = action.payload;
  if (slug !== updates.slug) {
    nextState = {};
    const prevState: any = Object.entries(state);
    for (const [dfSlug, df] of prevState) {
      if (df.caseReport.slug !== slug) nextState[dfSlug] = df;
      else {
        nextState[dfSlug] = {
          ...df,
          deleted: true,
          edited: true,
          isDeprecated: true,
        };
        const nextDfSlug = `${updates.slug}_${df.simpleSlug}`;
        nextState[nextDfSlug] = newDataFieldFromOld({
          newDataFieldSimpleSlug: df.simpleSlug,
          newDataFieldSlug: nextDfSlug,
          oldDataField: df,
          newCaseReportSlug: updates.slug,
          newCaseReportSimpleSlug: updates.simpleSlug,
          newStudyPlanVisitSlug: updates.studyPlanVisit.slug,
        });
      }
    }
  } else nextState = { ...state };
  const { computedValues }: any = action.payload.updates;
  //for now assume only one computedValue
  for (const computedValueConfig of computedValues) {
    const { slug: computedValueSlug } = computedValueConfig;

    // hack to allow entering eval values as string for computers
    const evalValues = Array.isArray(computedValueConfig.evalValues)
      ? computedValueConfig.evalValues
      : computedValueConfig.evalValues?.split(',');

    nextState[`${updates.slug}_${computedValueSlug}`] = {
      name: computedValueConfig.name,
      order: 0,
      dataType: 'COMPUTED',
      dependsOn: [],
      slug: `${updates.slug}_${computedValueSlug}`,
      simpleSlug: computedValueSlug,
      superSlug: computedValueConfig.superSlug,
      isDeprecated: false,
      evaluator: computedValueConfig.evaluator,
      evaluatorMockValue: computedValueConfig.evaluatorMockValue,
      evalValue: computedValueConfig.evalValue,
      evalValues,
      evalFailMessage: computedValueConfig.evalFailMessage,
      studyPlanVisit: {
        slug: updates.studyPlanVisit.slug,
      },
      caseReport: {
        slug: updates.slug,
        simpleSlug: updates.simpleSlug,
      },
      edited: true,
      new: true,
    };
  }

  return nextState;
};

const _addCaseReport = (
  state: DataFieldMap = {},
  action: AddCaseReportAction
) => {
  const {
    computedValues,
    procedureInstructions,
    uploadInstructions,
    slug,
    simpleSlug,
    noUploads,
    name,
  } = action.payload;
  const nextState = { ...state };
  const sharedDependencies: any = [];
  let lastIndex = 0;
  procedureInstructions &&
    procedureInstructions.forEach((procedure: string, index: number) => {
      lastIndex = index + 1;
      const dataField = {
        order: index + 1,
        description: null,
        min: null,
        max: null,
        evalValue: null,
        evalFailMessage: null,
        options: null,
        dataType: 'CHECKBOX',
        checkboxText: 'I have completed this procedure.',
        name: procedure,
        slug: `procedure_${index}_${slug}`,
        simpleSlug: `procedure_${simpleSlug}`,
        caseReport: {
          slug,
          simpleSlug,
        },
        isDeprecated: false,
        dependsOn: _cloneDeep(sharedDependencies),
        edited: true,
        new: true,
      };
      _assign(nextState, {
        [dataField.slug]: dataField,
      });
      sharedDependencies.push({
        key: dataField.slug,
        value: 'checked',
      });
    });
  // @ts-ignore
  for (const computedValueConfig of computedValues) {
    const { slug: computedValueSlug } = computedValueConfig;
    nextState[`${slug}_${computedValueSlug}`] = {
      name: computedValueConfig.name,
      order: lastIndex + 1,
      dataType: 'COMPUTED',
      dependsOn: [],
      slug: `${slug}_${computedValueSlug}`,
      simpleSlug: computedValueSlug,
      isDeprecated: false,
      evaluator: computedValueConfig.evaluator,
      evaluatorMockValue: computedValueConfig.evaluatorMockValue,
      evalValue: computedValueConfig.evalValue,
      evalValues: computedValueConfig.evalValues,
      evalFailMessage: computedValueConfig.evalFailMessage,
      caseReport: {
        slug,
        simpleSlug,
      },
      edited: true,
      new: true,
    };
    lastIndex += 1;
  }
  if (!noUploads) {
    const df = {
      order: lastIndex + 1,
      dependsOn: _cloneDeep(sharedDependencies),
      description: null,
      dataType: 'FILE',
      min: null,
      max: null,
      evalValue: null,
      evalFailMessage: null,
      options: null,
      name: uploadInstructions
        ? uploadInstructions
        : `Upload source data for ${name}.`,
      slug: `file_${slug}`,
      simpleSlug: `file_${simpleSlug}`,
      caseReport: {
        slug,
        simpleSlug,
      },
      isDeprecated: false,
    };
    _assign(nextState, {
      [df.slug]: df,
    });
  }
  return nextState;
};

const _toggleCaseReport = produce(
  (state: DataFieldMap, action: ToggleCaseReportAction) => {
    const {
      caseReportSimpleSlug,
      studyPlanVisitSlug,
      caseReportSlug: caseReportSlugOld,
    } = action.payload;
    const caseReportSlug = caseReportSlugFrom(
      studyPlanVisitSlug,
      caseReportSimpleSlug
    );
    const relevantDataFields = dataFieldsForCaseReport(
      state,
      caseReportSlug,
      false
    );

    //if we already have dataFields for a given caseReport, we delete them
    if (!_isEmpty(relevantDataFields)) {
      relevantDataFields.forEach(({ slug }) => {
        delete state[slug];
      });
      //otherwise we have to create them
    } else {
      //find 'template' dataFields (for a similar caseReport)
      const templates = dataFieldsForCaseReport(
        state,
        caseReportSlugOld,
        false
      ).filter(df => !df.isDeprecated);

      templates.forEach(dataField => {
        let newSimpleSlug = dataField.simpleSlug;
        const dataFieldSlug = dataField.slug.replace(
          dataField.caseReport.slug,
          caseReportSlug
        );
        //we use caseReportSlugFrom func to generate a datafield slug but switching out spv for cr
        state[dataFieldSlug] = newDataFieldFromOld({
          newDataFieldSimpleSlug: newSimpleSlug,
          newDataFieldSlug: dataFieldSlug,
          oldDataField: dataField,
          newCaseReportSlug: caseReportSlug,
          newCaseReportSimpleSlug: caseReportSimpleSlug,
          newStudyPlanVisitSlug: studyPlanVisitSlug,
        });
      });
    }
  }
);

const _editStudyPlanVisit = (
  state: DataFieldMap,
  action: EditStudyPlanVisit
) => {
  const { updates, slug: spvSlug }: any = action.payload;
  if (!updates || !updates.slug || updates.slug === spvSlug) return state;
  return _transform(
    state,
    (result, value: any, key: any) => {
      if (key.includes(spvSlug)) {
        result[key] = {
          ...value,
          isDeprecated: true,
          edited: true,
          deleted: true,
        };
        const newDfSlug = key.replace(spvSlug, updates.slug);
        const newCrSlug = value.caseReport.slug.replace(spvSlug, updates.slug);
        const newCrSimpleSlug = value.caseReport.simpleSlug;
        result[newDfSlug] = newDataFieldFromOld({
          newDataFieldSimpleSlug: value.simpleSlug,
          newDataFieldSlug: newDfSlug,
          oldDataField: value,
          newCaseReportSimpleSlug: newCrSimpleSlug,
          newCaseReportSlug: newCrSlug,
          newStudyPlanVisitSlug: updates.slug,
        });
      } else {
        result[key] = value;
      }
    },
    {}
  );
};

export default function (state: DataFieldMap = {}, action: Action) {
  switch (action.type) {
    case INIT_TRIAL:
      return _initTrial(action);
    case ADD_DATAFIELD:
      return _addDataField(state, action);
    case EDIT_DATAFIELD:
      return editDataField(state, action);
    case DELETE_DATAFIELD:
      return _deleteDataField(state, action);
    case APPLY_CHANGES_FROM_DB:
      return _applyChangesFromDB(state, action);
    case ADD_CASEREPORT:
      return _addCaseReport(state, action);
    case EDIT_CASEREPORT:
      return _editCaseReport(state, action);
    case TOGGLE_CASEREPORT:
      return _toggleCaseReport(state, action);
    case DELETE_ALL_INSTANCES_OF_CRF:
      return produce(state, draft => {
        for (const [dfName, dfValue] of Object.entries(draft)) {
          if (
            dfValue.caseReport.simpleSlug ===
            action.payload.caseReportSimpleSlug
          ) {
            delete draft[dfName];
          }
        }
      });
    case EDIT_STUDYPLANVISIT:
      return _editStudyPlanVisit(state, action);
    case DELETE_STUDYPLANVISIT:
      return produce(state, draft => {
        for (const [slug, df] of Object.entries(draft)) {
          if ((df as any).studyPlanVisit.slug === action.payload.slug)
            delete draft[slug];
        }
      });
    default:
      return state;
  }
}
