import { VisitStatus } from '@curebase/core/types';

const { Completed, Skipped } = VisitStatus;

export default function isDataCaptureCompleted({
  status,
}: {
  status: VisitStatus;
}): boolean {
  return status === Completed || status === Skipped;
}
