import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../basic/SafeDialog';
import SignatureCaptureInDialog from '../basic/SignatureCaptureInDialog';
import { onDialogOpen } from '../../utils/mobileHelpers';

const SignatureDialog = ({
  attestations,
  dialogOpen,
  closeDialog,
  onSubmit,
}) => {
  const { t } = useTranslation('translations');

  return (
    <Dialog
      maxWidth={false}
      open={onDialogOpen(dialogOpen)}
      onClose={closeDialog}
    >
      <SignatureCaptureInDialog
        alreadyAcknowledgedQuestions={attestations}
        reason={t('signatureDialog.reason')}
        onSubmit={onSubmit}
        onClose={closeDialog}
        inPatientMode={false}
      />
    </Dialog>
  );
};

export { SignatureDialog };
