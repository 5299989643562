import React from 'react';
import {
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const NoSignOffsView = () => {
  const { t } = useTranslation('translations');

  return (
    <ExpansionPanel expanded={true} key='no-signoff'>
      <ExpansionPanelSummary>
        <div className='expansion-summary-content'>
          <span className='summary-title'>{t('noSignOffsView.title')}</span>
        </div>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
};

export { NoSignOffsView };
