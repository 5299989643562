import React from 'react';
import { withStyles } from '@material-ui/core';

import { withBreakpoints } from '../hocs/WithBreakpoints';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

export type OwnProps = {
  onClick?: () => any;
  children?: React.ReactElement<any>;
  status?: {
    type:
      | 'BLANK'
      | 'AVAILABLE'
      | 'AVAILABLE_WARNING'
      | 'COMPLETED'
      | 'FAILED'
      | 'DECLINED'
      | 'PENDING'
      | 'NOT_APPLICABLE';
    action?: string;
  };
  left?: {
    title: string;
    subtitle: string;
  };
  middle?: {
    title: string;
    titleAddon?: React.ReactElement<any>[];
    subtitle?: string;
    text?: string;
    followUpText?: string;
    buttons?: React.ReactElement<any>[];
    tags?: React.ReactElement<any>[];
  };
  hidePersonalInformation?: boolean;
};

type HOCProps = {
  classes: any;
  tabletSizedScreen?: boolean;
};

type ListItemProps = OwnProps & HOCProps;

const styles = theme => ({
  materialIcon: {
    width: 28,
    height: 28,
  },
});

function ListItem(props: ListItemProps) {
  const {
    status,
    onClick,
    left,
    middle,
    tabletSizedScreen,
    hidePersonalInformation,
  } = props;
  const containerClass = `li-container ${onClick ? 'clickable' : ''} ${
    tabletSizedScreen ? 'mobile' : ''
  } ${hidePersonalInformation ? 'fs-block' : ''}`;

  return (
    <div onClick={onClick} className={containerClass}>
      {status && (
        <div className='li-status'>
          <div className='icon-container'>
            {status.type === 'COMPLETED' && (
              <CheckCircleOutlinedIcon
                className='icon-status'
                aria-label='status completed'
                style={{ color: '#32c73c' }}
              />
            )}
            {status.type === 'FAILED' && (
              <CancelOutlinedIcon
                className='icon-status'
                aria-label='status failed'
                style={{ color: '#ff2828' }}
              />
            )}
            {status.type === 'AVAILABLE' && (
              <RadioButtonUncheckedRoundedIcon
                className='icon-status'
                aria-label='status available'
                style={{ color: '#ffe100f5' }}
              />
            )}
            {status.type === 'AVAILABLE_WARNING' && (
              <ErrorOutlineRoundedIcon
                className='icon-status'
                aria-label='status available_warning'
                style={{ color: '#ff2828' }}
              />
            )}
            {status.type === 'PENDING' && (
              <ScheduleOutlinedIcon
                className='icon-status'
                aria-label='status pending'
                style={{ color: '#ffe100f5' }}
              />
            )}
            {status.type === 'DECLINED' && (
              <BlockOutlinedIcon
                className='icon-status'
                aria-label='status declined'
                style={{ color: '#ffe100f5' }}
              />
            )}
            {status.type === 'NOT_APPLICABLE' && (
              <NotInterestedIcon
                className='icon-status'
                aria-label='status declined'
                style={{ color: '#ffe100f5' }}
              />
            )}
          </div>

          <div className='li-status-text'>{status.action}</div>
        </div>
      )}
      {left && (
        <div className='li-left'>
          {left.title}
          {left.subtitle && (
            <div className='li-left-subtitle'>{left.subtitle}</div>
          )}
        </div>
      )}
      <div className='li-middle'>
        {middle && (
          <>
            {middle.title && (
              <div className='li-header'>
                <div className='li-title-container'>
                  <div className='li-title'>
                    {middle.title}
                    {!!middle.titleAddon && (
                      <div className='li-title-addon'>{middle.titleAddon}</div>
                    )}
                  </div>
                  {middle.subtitle && (
                    <div className='li-subtitle'>{middle.subtitle}</div>
                  )}
                </div>

                {middle.tags}

                {onClick && (
                  <div className='img-container small'>
                    <img src='/icons/right-arrow.svg' alt='arrow' />
                  </div>
                )}
              </div>
            )}
            {middle.text && <div className='li-text'>{middle.text}</div>}

            {middle.followUpText && (
              <div className='li-followUpText'>{middle.followUpText}</div>
            )}
          </>
        )}

        {props.children}

        {middle && middle.buttons && middle.buttons.length > 0 && (
          <div className='li-buttons-container'>{middle.buttons}</div>
        )}
      </div>
    </div>
  );
}

export default withBreakpoints(withStyles(styles)(ListItem) as any) as any;
