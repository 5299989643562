import { useMemo } from 'react';
import { VisitResults } from '@curebase/core/types/trialOption';
import { DateTime, Interval } from 'luxon';
import {
  HumanizeDurationLanguage,
  HumanizeDuration,
} from 'humanize-duration-ts';

import Dialog from '../basic/SafeDialog';
import DialogHeader from '../basic/DialogHeader';
import DynamicForm from '../basic/DynamicForm';
import { useImmutableState } from 'src/hooks/useImmutableState';

import { useTranslation } from 'react-i18next';

type FormData = Readonly<{
  reason: string;
  dueDate: string;
}>;

interface Props {
  visit?: VisitResults;
  timezone: string;
  onClose: () => void;
  onSubmit: (data: FormData) => Promise<void>;
}

function ExtendVisitDialog(props: Props) {
  const { visit, onClose, onSubmit, timezone } = props;
  const { t } = useTranslation('translations');
  const [state, setState]: [FormData, any] = useImmutableState<any>({});

  const humanizer = useMemo(() => {
    const service: HumanizeDurationLanguage = new HumanizeDurationLanguage();
    const h: HumanizeDuration = new HumanizeDuration(service);

    return h;
  }, []);

  const lastSubmittableTimeDateTime = useMemo(() => {
    if (!visit) {
      return;
    }

    //@ts-ignore -- this probably should not be nullable?
    return DateTime.fromISO(visit?.submissionTimes.lastSubmittableTime);
  }, [visit]);

  if (!visit) {
    return null;
  }

  const submitButton = () => {
    if (lastSubmittableTimeDateTime && state.dueDate) {
      const dueDateDateTime = DateTime.fromISO(state.dueDate);
      const dueDateInterval = Interval.fromDateTimes(
        lastSubmittableTimeDateTime,
        dueDateDateTime
      );

      const humanizedDuration = humanizer.humanize(dueDateInterval.length(), {
        units: ['d', 'h', 'm'],
        round: true,
        conjunction: ' and ',
        serialComma: false,
      });

      return `Add ${humanizedDuration}`;
    }
  };

  const elements: any[] = [
    {
      title: `New Date (${timezone})`,
      subElements: [
        {
          key: 'dueDate',
          type: 'DATETIME',
          rawValue: true,
          optional: false,
        },
      ],
    },
    {
      title: 'Reason',
      subElements: [
        {
          key: 'reason',
          type: 'PARAGRAPH',
          optional: false,
        },
      ],
    },
  ];

  const isRecurrent = visit => !!visit.studyPlanVisit?.rrule;

  const subtitle = isRecurrent(visit)
    ? t('dataCaptureResultsView.extendVisitDialog.subtitleRecurrentVisit')
    : t('dataCaptureResultsView.extendVisitDialog.subtitleVisit');

  return (
    <Dialog className='themed-dialog' open={!!visit} onClose={onClose}>
      <DialogHeader
        title={`Extend ${visit.studyPlanVisit.summary.title} Due Date`}
        subtitle={subtitle}
        onClose={onClose}
      />

      <DynamicForm
        // @ts-ignore
        pages={[{ elements }]}
        onChange={(key, value) => setState({ [key]: value })}
        data={state}
        onSubmit={() => onSubmit(state)}
        submitText={submitButton()}
      />
    </Dialog>
  );
}

export default ExtendVisitDialog;
