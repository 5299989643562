import _omit from 'lodash/omit';
import {
  MarkPaymentManualRequest,
  MarkPaymentManualResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  AdoptLegalDocumentResponse,
  CreateStubUserResponse,
  UpdateUserTimezoneRequest,
  UpdateUserTimezoneResponse,
  CreateNoPIIStubUserRequest,
  CreateNoPIIStubUserResponse,
} from '@curebase/core/decoders/users';
import { jsonPost } from '../lib/fetchHelpers';
import { PolicyDocumentType } from '@curebase/core/types';

export async function updateUserTimezone(
  timezone: string
): Promise<UpdateUserTimezoneResponse> {
  const body: UpdateUserTimezoneRequest = { timezone };
  return await jsonPost('/user/timezone', body);
}

export async function disableAccount(params: { userId: number }) {
  const response = await jsonPost('/user/disableorenable', params);
  return response;
}

export async function createStubUser(params): Promise<CreateStubUserResponse> {
  const request = {
    ..._omit(params, 'piiStatus'),
    skipExternalPrescreening: params.skipExternalPrescreening === 'YES',
  };
  const res: CreateStubUserResponse = await jsonPost(
    '/user/stubuser/create',
    request
  );

  return res;
}

export async function createDeceasedUser(
  params
): Promise<CreateNoPIIStubUserResponse> {
  const request: CreateNoPIIStubUserRequest = {
    ...params,
    skipExternalPrescreening: params.skipExternalPrescreening === 'YES',
  };
  const response: CreateStubUserResponse = await jsonPost(
    '/user/stubuser/nopii/create',
    request
  );
  return response;
}

export async function updateUserPin(
  userId: number,
  pinCode: string
): Promise<UpdateUserResponse> {
  const requestBody: UpdateUserRequest = { userId, pinCode };
  const response: UpdateUserResponse = await jsonPost(
    '/user/update',
    requestBody
  );
  return response;
}

export async function updateUser(
  userId: number,
  trialOptionId: number,
  firstName?: string,
  lastName?: string,
  countryCode?: string,
  email?: string,
  phoneNumber?: string
): Promise<UpdateUserResponse> {
  const requestBody: UpdateUserRequest = {
    userId,
    firstName,
    lastName,
    countryCode,
    email,
    phoneNumber,
    trialOptionId,
  };
  const response: UpdateUserResponse = await jsonPost(
    '/user/update',
    requestBody
  );
  return response;
}

export async function adoptLegalDocument(
  documentType: PolicyDocumentType,
  documentVersion?: string
): Promise<AdoptLegalDocumentResponse> {
  const requestBody = {
    documentType,
    documentVersion: documentVersion ?? null,
  };
  const response: AdoptLegalDocumentResponse = await jsonPost(
    '/user/adoptlegaldocument',
    requestBody
  );
  return response;
}

export async function markPaymentAsManual(
  userId: number,
  visitId: number
): Promise<MarkPaymentManualResponse> {
  const requestBody: MarkPaymentManualRequest = {
    userId,
    visitId,
    type: 'PATIENT',
  };

  const response: MarkPaymentManualResponse = await jsonPost(
    '/payments/markasmanual',
    requestBody
  );
  return response;
}
