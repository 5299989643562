import React from 'react';
import { Button } from '@material-ui/core';
import { RolePermissions } from '@curebase/core/types';
import PermissionedComponent from './PermissionedComponent';

type PermissionedButtonProps = {
  children?: React.ComponentType | React.ReactElement | React.ReactNode;
  permission: RolePermissions;
  disabled: boolean;
  onClick: () => any;
  buttonText?: string;
  color?: 'secondary' | 'primary';
  variant?: 'text' | 'outlined' | 'contained';
  disableElevation?: boolean;
};

const PermissionedButton = (props: PermissionedButtonProps) => {
  const {
    color,
    disabled,
    onClick,
    permission,
    buttonText,
    children,
    variant = 'contained',
    disableElevation,
  } = props;

  return (
    <PermissionedComponent permission={permission}>
      <Button
        color={color ?? 'primary'}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        disableElevation={disableElevation}
      >
        {buttonText}
        {children}
      </Button>
    </PermissionedComponent>
  );
};

export default PermissionedButton;
