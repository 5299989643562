import _assign from 'lodash/assign';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _values from 'lodash/values';
import * as uuid from 'uuid';
import produce from 'immer';
import {
  ADD_STUDYPLANVISIT,
  EDIT_STUDYPLANVISIT,
  DELETE_STUDYPLANVISIT,
  DELETE_DATAFIELD,
  APPLY_CHANGES_FROM_DB,
  INIT_TRIAL,
  Action,
  ApplyChangeFromDBAction,
  InitTrialAction,
  DeleteStudyPlanVisit,
} from '../../actions/TrialBuilder/types';
// @ts-ignore
import { StudyPlanVisitMap } from '../../../components/TrialBuilder/TrialBuilder';

const pruneStudyPlanVisit = studyPlanVisit => {
  const spv = _omit(studyPlanVisit, 'summary');
  spv.summary = studyPlanVisit.summary.key || null;
  return spv;
};

export const _initTrial = (action: InitTrialAction) => {
  const nextState = {};
  const { trial }: any = action.payload;
  const { studyPlanVisits } = trial;
  if (!studyPlanVisits) return nextState;
  studyPlanVisits.forEach(spv => {
    if (spv.isDeprecated ?? false) {
      return;
    }

    const toAssign = _omit(spv, ['caseReports']);
    _assign(toAssign, {
      trial: { slug: trial.slug },
    });
    _assign(nextState, {
      [spv.slug]: toAssign,
    });
  });
  return nextState;
};

const deleteStudyPlanVisit = (
  state: StudyPlanVisitMap,
  action: DeleteStudyPlanVisit
) => {
  const nextState = { ...state };
  const { slug } = action.payload;
  delete nextState[slug];
  return nextState;
};

const _applyChangesFromDB = (
  state: StudyPlanVisitMap,
  action: ApplyChangeFromDBAction
): StudyPlanVisitMap => {
  const { studyPlanVisits } = action.payload;
  if (!studyPlanVisits) return state;
  return { ...state, ...studyPlanVisits };
};

//$FlowFixMe
export default produce((state: StudyPlanVisitMap, action: Action) => {
  switch (action.type) {
    case DELETE_DATAFIELD:
      const { superId } = action.payload;
      if (!superId) break;
      for (const spvSlug in state) {
        const evaluators = state[spvSlug].evaluators;
        if (!evaluators) continue;
        const index = evaluators.findIndex(elem =>
          elem.dependsOnSuperIds.includes(superId)
        );
        if (index !== -1) evaluators.splice(index, 1);
        if (_isEmpty(evaluators)) state[spvSlug].evaluators = null;
      }
      break;
    case INIT_TRIAL:
      return _initTrial(action);
    case ADD_STUDYPLANVISIT:
      let { slug } = action.payload;
      if (state[slug]) slug += `_${uuid.v4().slice(0, 4)}`;
      state[slug] = pruneStudyPlanVisit(action.payload);
      break;
    case APPLY_CHANGES_FROM_DB:
      return _applyChangesFromDB(state, action);
    case EDIT_STUDYPLANVISIT:
      const { slug: spvSlug } = action.payload;
      const updates = _omit(action.payload.updates, 'summary');
      updates.summary = action.payload.updates.summary.key;
      //Did the user even make an update?
      if (!_values(updates).some(x => x !== undefined)) break;
      //Have the slugs changed?
      if (updates.slug && updates.slug !== spvSlug) {
        if (state[updates.slug]) updates.slug += `_${uuid.v4().slice(0, 4)}`;
        state[updates.slug] = _assign({}, state[spvSlug], updates, {
          edited: true,
          new: true,
        });
        delete state[spvSlug];
      } else {
        state[spvSlug] = updates;
        state[spvSlug].edited = true;
      }
      break;
    case DELETE_STUDYPLANVISIT:
      return deleteStudyPlanVisit(state, action);
    default:
      break;
  }
}, {});
