import { PayerType, PaymentStatus, User } from '../types';

export const isPayerPaypal = (user?: User): boolean => {
  if (!user || !user.payerId || !user.payerType) return false;

  return user.payerType === PayerType.Paypal;
};

export const isPending = (status: PaymentStatus) => {
  return [
    PaymentStatus.Created,
    PaymentStatus.Qualified,
    PaymentStatus.Queued,
  ].includes(status);
};

export const isOnHold = (status: PaymentStatus) => {
  return [PaymentStatus.QualifiedPendingApproval].includes(status);
};

export const isComplete = (status: PaymentStatus) => {
  return [
    PaymentStatus.Paid,
    PaymentStatus.Withdrawn,
    PaymentStatus.Completed,
  ].includes(status);
};

export const isFailed = (status: PaymentStatus) => {
  return [
    PaymentStatus.NotQualified,
    PaymentStatus.Error,
    PaymentStatus.Canceled,
  ].includes(status);
};
