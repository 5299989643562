import _capitalize from 'lodash/capitalize';
import _snakeCase from 'lodash/snakeCase';
import _trim from 'lodash/trim';

export const slugName = (name: string): string => _snakeCase(_trim(name));

// NOT_APPLICABLE_DUE_TO_DEPENDENCY -> Not Applicable Due To Dependency
export const humanReadableSchemaFlowValue = (val: string) =>
  val
    .split('_')
    .map(word => _capitalize(word))
    .join(' ');
