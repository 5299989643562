export enum DistanceUnit {
  Inches = 'inches',
  Feet = 'feet',
}

export type Height = Record<DistanceUnit, number>;

export function toInches(height: Height): number {
  return height.feet * 12 + height.inches;
}

export function toFeet(inches: number): Height {
  if (isNaN(inches)) {
    return {
      [DistanceUnit.Feet]: 0,
      [DistanceUnit.Inches]: 0,
    };
  }

  const feet = Math.floor(inches / 12);
  const inch = inches % 12;

  return {
    [DistanceUnit.Feet]: feet,
    [DistanceUnit.Inches]: inch,
  };
}

export const defaultHeightValue: Height = {
  [DistanceUnit.Feet]: 0,
  [DistanceUnit.Inches]: 0,
};

export function parseHeight(feet: number, inches: number): Height {
  const parsedFeet = isNaN(feet) ? 0 : feet;
  const parsedInches = isNaN(inches) ? 0 : inches;

  return {
    [DistanceUnit.Feet]: parsedFeet,
    [DistanceUnit.Inches]: parsedInches,
  };
}

export function isInRange(height: Height, min: number, max: number): boolean {
  const value = toInches(height);
  return value >= min && value <= max;
}
