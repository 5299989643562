import { useTranslation } from 'react-i18next';
import ActionConfirmDialog from '../../TrialOption/ActionConfirmDialog';
import usePermission from 'src/hooks/usePermission';
import { useUserDetailsEditorQuery } from '../../../types';
import {
  disableAccount,
  updateUser,
} from '../../../controllers/userController';
import SettingsEditor from '../../Settings/SettingsEditor';
import {
  getEmailValidationError,
  getPhoneValidationError,
} from '@curebase/core/lib/validators';
import { currentUserHasPermission } from '../../../lib/auth';
import { RolePermissions } from '@curebase/core/types';

interface Props {
  userId: number;
  trialOptionId: number;
  updateParent?: any;
}

const DisableParticipantButton = (props: {
  accountDisabled?: boolean;
  onConfirm: () => Promise<void>;
}) => {
  const { t } = useTranslation('translations');
  const { accountDisabled, onConfirm } = props;
  const hasPermission = usePermission(RolePermissions.CanDisableAccounts);
  return hasPermission ? (
    <ActionConfirmDialog
      title={
        !accountDisabled
          ? t('profile.deactivateUserTitle')
          : t('profile.reactivateUserTitle')
      }
      message={
        !accountDisabled
          ? t('profile.deactivateUserMsg')
          : t('profile.reactivateUserMsg')
      }
      confirmAction={async () => {
        await onConfirm();
      }}
    />
  ) : null;
};

function UserDetailsEditor(props: Props) {
  const { t } = useTranslation('translations');

  const {
    data,
    // loading,
    refetch: refetchGetUserDetailsEditor,
  } = useUserDetailsEditorQuery({
    variables: {
      userId: props.userId,
    },
  });

  function getSubElements() {
    const res: any[] = [
      {
        title: t('profile.userDetails.firstNameTitle'),
        subElements: [
          {
            type: 'TEXT',
            key: 'firstName',
            placeholder: t('profile.userDetails.firstNamePlaceholder'),
          },
        ],
      },
      {
        title: t('profile.userDetails.lastNameTitle'),
        subElements: [
          {
            type: 'TEXT',
            key: 'lastName',
            placeholder: t('profile.userDetails.lastNamePlaceholder'),
          },
        ],
      },
    ];

    if (currentUserHasPermission(RolePermissions.EditUserDetails)) {
      res.push(
        {
          title: t('profile.userDetails.emailTitle'),
          subElements: [
            {
              type: 'TEXT',
              key: 'email',
              placeholder: t('profile.userDetails.emailPlaceHolder'),
            },
          ],
        },
        {
          title: t('profile.userDetails.phoneNumberTitle'),
          subElements: [
            {
              type: 'PHONE_NUMBER',
              key: 'phoneNumber',
              placeholder: t('profile.userDetails.phoneNumberPlaceHolder'),
            },
          ],
        }
      );
    }

    return res;
  }

  const validate = (key: string, value: any) => {
    if (['firstName', 'lastName'].includes(key) && !value) {
      return t('profile.cannotBeEmpty');
    }

    if (key === 'email' && value) {
      return getEmailValidationError(value, t);
    }

    if (key === 'phoneNumber' && value) {
      return getPhoneValidationError(value, t);
    }
    return null;
  };

  // @FIXME: not sure if this is right
  if (!data) return null;
  const user = data.getUser;

  const onSubmitEdition = async (formData, onCompleted) => {
    if (data.getUser?.id) {
      const response = await updateUser(
        data.getUser.id,
        props.trialOptionId,
        formData.firstName,
        formData.lastName,
        formData.countryCode,
        formData.email,
        formData.phoneNumber
      );
      onCompleted(response);

      // @TODO: understand why we need this and then remove it
      // @ts-ignore
      if (!Boolean(response?.error) && !!props.updateParent) {
        props.updateParent();
      }
    }
  };

  const defaultData = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    countryCode: user?.countryCode,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };

  return (
    <>
      <SettingsEditor
        //@ts-ignore
        title={t('profile.userDetailsSubheader')}
        defaultData={defaultData}
        // @ts-ignore
        validate={validate}
        pages={[
          {
            elements: getSubElements(),
          },
        ]}
        onSubmit={onSubmitEdition}
        onFinish={() => refetchGetUserDetailsEditor()}
      />
      <DisableParticipantButton
        accountDisabled={user?.accountDisabledOn ? true : false}
        onConfirm={async () => {
          await disableAccount({ userId: data.getUser!.id });
          refetchGetUserDetailsEditor();
        }}
      />
    </>
  );
}

export default UserDetailsEditor;
