export const formatSiteManagementConfig = (config: Readonly<any>) => {
  let error = !config;
  const { id, name, clinic, trialInstances } = config;
  if (!id || typeof id !== 'string') error = true;
  if (!name || typeof name !== 'string') error = true;
  if (!trialInstances || !(trialInstances instanceof Array)) error = true;
  if (error) return null;

  const formatted = { ...config };
  if (clinic) delete formatted.clinic;
  formatted.trialInstances = trialInstances
    .map((instance: Readonly<any>) => {
      const instanceCopy = { ...instance };
      const { trialIdentifier } = instance;

      if (!trialIdentifier || typeof trialIdentifier !== 'string') return false;

      instanceCopy.deactivated = !!instance.deactivated;
      delete instanceCopy.trial;
      delete instanceCopy.__typename;
      return instanceCopy;
    })
    .filter(Boolean);
  return formatted;
};
