import { SubmittableTimeRange } from '@curebase/core/types';
import moment from 'moment';

export default function isDataCaptureDueAtTime(
  submissionTimes: SubmittableTimeRange,
  currentDate: number
): boolean {
  const {
    dueTime,
    lastSubmittableTime,
    extendedLastSubmittableTime,
  } = submissionTimes;

  if (extendedLastSubmittableTime) {
    return moment(currentDate).isBetween(
      dueTime,
      extendedLastSubmittableTime,
      undefined,
      '[]' // inclusive
    );
  }

  if (lastSubmittableTime) {
    return moment(currentDate).isBetween(
      dueTime,
      lastSubmittableTime,
      undefined,
      '[]' // inclusive
    );
  } else {
    return moment(currentDate).isAfter(dueTime);
  }
}
