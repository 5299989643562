import { useMediaQuery, useTheme } from '@material-ui/core';
import { useContext } from 'react';
import TopBarContext from '../../context/topBarContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ExploreStepProgressProps {
  mobileOnly?: boolean;
}

function ExploreStepProgress(props: ExploreStepProgressProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { items, currentItemIndex } = useContext(TopBarContext);
  const { legacyMobileStepper } = useFlags();

  // If the feature flag to present the legacy mobile stepper is enabled, this component should not be presented
  if (legacyMobileStepper) return null;
  if (props.mobileOnly && !isMobile) return null;
  if (!items?.length) return null;

  return (
    <div className={'explore-step-progress'}>
      <span>
        {(currentItemIndex || 0) + 1}/{items?.length || '0'}
      </span>
    </div>
  );
}

export default ExploreStepProgress;
