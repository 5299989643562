import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading({
  children = null,
  size = undefined,
}: {
  size?: number;
  children?: React.ReactNode | null | undefined;
}): React.ReactElement {
  const style: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <div style={style}>
      <CircularProgress size={size} />
      {children}
    </div>
  );
}

export default Loading;

export function LoadingOverlay() {
  const loading = useSelector((state: any) => state.ui.loading ?? {});
  if (!loading.isVisible) return null;

  const style: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <div style={style}>
      <CircularProgress />
    </div>
  );
}
