import { UserRoleType } from '@curebase/core/types';
import { getUser } from 'src/store/user/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useIsParticipant() {
  const user = useSelector(getUser);
  const participantRoles = useMemo(
    () => user?.roles?.filter(r => r.type === UserRoleType.Patient) ?? [],
    [user]
  );

  return {
    isParticipant: participantRoles.length > 0,
  };
}
