import { RolePermissions } from '../../types';

export const investigatorPermissions = [
  RolePermissions.ViewPatientPayments,
  RolePermissions.ViewPii,
  RolePermissions.ViewSettings,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.CreateProtocolDeviations,
  RolePermissions.PrivilegedProvider,
  RolePermissions.ViewParticipants,
  RolePermissions.ReportEvent,
  RolePermissions.SubmitInitialDataCapture,
  RolePermissions.ViewCrf, // CRF Signoff
  RolePermissions.ViewCaseReportSignoff,
  RolePermissions.SignCaseReport,
  RolePermissions.WithdrawParticipant,
  RolePermissions.EditTrialOptionDetails,
  RolePermissions.CreateParticipantNote,
  RolePermissions.ViewParticipantNote,
  RolePermissions.AddUnscheduledActivity,
  RolePermissions.BackfillDataCapture,
];
