import 'react-phone-number-input/style.css';

import React, { useState } from 'react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input/input';
import CountryCodeSelect from '../CountryCodeSelect';

export interface CPhoneInputProps {
  label?: string;
  countryLabel?: string;
  value?: string;
  onChange: (phone?: string) => void;
  color?: string;
  size?: 'small' | 'medium';
  variant?: 'filled' | 'outlined';
  fullWidth?: boolean;
  helperText?: string;
  hasError?: boolean;
  disabled?: boolean;
  testId?: string;
}

const InputWrapper = React.forwardRef(
  (
    { placeholder, value, onChange, onFocus, onBlur, fullWidth, ...rest }: any,
    ref: any
  ) => {
    return (
      <input
        ref={ref}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        style={{
          display: fullWidth ? 'block' : undefined,
        }}
        {...rest}
      />
    );
  }
);

export default function CPhoneInput({
  label = 'Phone number',
  countryLabel,
  value,
  onChange,
  color,
  size,
  variant,
  fullWidth,
  hasError,
  helperText,
  disabled,
  testId,
}: CPhoneInputProps) {
  const phoneInfo = parsePhoneNumber(value || '');
  const [country, setCountry] = useState<any>(phoneInfo?.country || 'US');

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 8 }}>
        <CountryCodeSelect
          label={countryLabel}
          value={country}
          onChange={setCountry}
          color={color}
          size={size}
          variant={variant}
          hasError={hasError}
          disabled={disabled}
          fullWidth={true}
        />
      </div>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <PhoneInput
          country={country as any}
          placeholder={label}
          value={(value as any) || ''}
          onChange={onChange}
          inputComponent={InputWrapper as any}
          size={size}
          color={color}
          variant={variant}
          fullWidth={fullWidth}
          helperText={helperText}
          hasError={hasError}
          disabled={disabled}
          testId={testId}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}
