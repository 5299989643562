import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { PolicyDocumentType, useExploreCreatePasswordQuery } from 'src/types';
import { useImmutableState } from 'src/hooks/useImmutableState';
import DynamicForm from '../basic/DynamicForm';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@material-ui/icons';
import { activateUser } from 'src/controllers/authController';
import { attemptLogin } from 'src/lib/auth';
import {
  AuthenticateUserResponseError,
  AuthenticateUserResponseSuccess,
} from '@curebase/core/decoders/users';
import useHideZendeskWhileMounted from 'src/hooks/useHideZendeskWhileMounted';
import { InvitationDataType } from '@curebase/core/types';
import { CREATE_PASSWORD_TOP_BAR_ITEM } from '@curebase/core/machines/topbar';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';
import { testPassword } from 'src/lib/constants';
import PasswordRequirements from '../Auth/PasswordRequirements';
import PasswordStrength from '../Auth/PasswordStrength';
import StatusText from '../basic/StatusText';
import { calculateStrength, Strength } from 'src/lib/password';
import { DocumentHTML } from 'src/shared/lib/queryHelpers';
import ExternalTOSAffirmation from '../basic/ExternalTOSAffirmation';
import Dialog from '../basic/SafeDialog';
import { getNextExploreRoute } from 'src/controllers/patientController';
import { adoptLegalDocument } from 'src/controllers/userController';
import { useLocale } from '../../hooks/useLocale';
import ExploreStepProgress from './ExploreStepProgress';
import owasp from 'owasp-password-strength-test';

interface ExploreCreatePasswordProps {
  mountingFunc?: (configId: string) => any;
  inviteId?: string;
  headerText?: string;
  descriptionText?: string;
  continueOnExploreRoutes?: boolean;
}
/**
 * Component for setting up a password.
 *
 * @param props.mountingFunc
 * @param props.inviteId | If included will override the accessCode value
 * @param props.headerText | Override header text
 * @param props.descriptionText | Override description
 * @param props.continueOnExploreRoutes | When password setup is complete, get the next explore route to go to
 * @returns
 */
function ExploreCreatePassword(props: ExploreCreatePasswordProps) {
  const {
    mountingFunc,
    inviteId = '',
    headerText = '',
    descriptionText = '',
    continueOnExploreRoutes = false,
  } = props;
  const [redirect, setRedirect] = useState(false);

  useHideZendeskWhileMounted();
  useEffect(() => {
    if (mountingFunc) {
      mountingFunc(CREATE_PASSWORD_TOP_BAR_ITEM);
    }
  }, [mountingFunc]);

  const history = useHistory();
  const { t } = useTranslation('translations');
  const [userData, setUserData] = useImmutableState<{
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    password?: string;
    confirm_password?: string;
  }>({});
  const [error, setError] = useState<string>('');
  const { accessCode = inviteId } = useParams<{ accessCode: string }>();
  const dataDoneLoading = useRef(false);
  const { data } = useExploreCreatePasswordQuery({
    skip: !accessCode,
    variables: { accessCode },
  });

  const [TOSState, setTOSState] = useImmutableState<{
    document: DocumentHTML | null;
    showDialog: boolean;
  }>({ document: null, showDialog: false });

  const [
    acceptedToSAndPrivacyPolicy,
    setAcceptedToSAndPrivacyPolicy,
  ] = useState(false);

  const [
    showToSAndPrivacyPolicyCheckbox,
    setShowToSAndPrivacyPolicyCheckbox,
  ] = useState(true);

  const toggleTOSDialog = useCallback(
    () => setTOSState({ showDialog: !TOSState.showDialog }),
    [TOSState, setTOSState]
  );

  useEffect(() => {
    const { password, confirm_password } = userData;
    if (
      DEVELOPER_FEATURES_ENABLED &&
      password === confirm_password &&
      testPassword === password
    ) {
      attemptSignup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (data && !dataDoneLoading.current) {
      dataDoneLoading.current = true;
      if (data.getAccessCode) {
        const { createdUser } = data.getAccessCode;
        setUserData({
          firstName: createdUser?.firstName ?? undefined,
          lastName: createdUser?.lastName ?? undefined,
          phoneNumber: createdUser?.phoneNumber ?? undefined,
        });
      }
    }
  }, [data, setUserData]);

  useEffect(() => {
    const shouldRedirect =
      dataDoneLoading.current &&
      data?.getAccessCode &&
      data?.getAccessCode.invitationData.type !==
        InvitationDataType.ProviderInitiatedPrescreening;

    if (shouldRedirect) {
      setRedirect(true);
    }
  }, [dataDoneLoading, data]);

  useEffect(() => {
    setError('');
  }, [userData?.password, userData?.confirm_password]);

  const foundAccessCode = data?.getAccessCode;

  // Safe because previous if statement redirection the page
  // if the InvitationDataType isn't ProviderInitiatedPrescreening
  // which is the only one right now with trial.
  // @ts-ignore
  const trialOption = data?.getAccessCode?.invitationData?.trialOption;

  useEffect(() => {
    if (!dataDoneLoading.current) return;

    const isAlreadyAcceptedToSAndPrivacyPolicy = !!(
      data?.getAccessCode?.createdUser?.termsOfServicesAdopted.find(
        element =>
          element.versionAccepted === trialOption.trial.termsOfServiceVersion
      ) &&
      data?.getAccessCode?.createdUser?.privacyPoliciesAdopted.find(
        element =>
          element.versionAccepted === trialOption?.trial?.privacyPolicyVersion
      )
    );
    if (isAlreadyAcceptedToSAndPrivacyPolicy && trialOption) {
      setAcceptedToSAndPrivacyPolicy(true);
      setShowToSAndPrivacyPolicyCheckbox(false);
    }
  }, [dataDoneLoading.current]);

  useLocale(trialOption?.locale);

  if (!accessCode || (dataDoneLoading.current && !foundAccessCode)) {
    return <Redirect to='/' />;
  }

  if (redirect) {
    return <Redirect to={`/auth/signup/${accessCode}`} />;
  }

  if (!foundAccessCode) {
    return null;
  }

  async function attemptSignup() {
    try {
      const activationResponse = await activateUser({
        accessCode: accessCode,
        password: userData.password!,
        firstName: userData.firstName!,
        lastName: userData.lastName!,
        phoneNumber: userData.phoneNumber!,
        timezone: moment.tz.guess(),
      });

      if (
        (activationResponse as AuthenticateUserResponseError)?.error ===
        'UNSAFE'
      ) {
        setError(t('auth.signup.errors.UNSAFE'));
        return;
      } else if (
        !!(activationResponse as AuthenticateUserResponseError)?.error
      ) {
        // @ts-ignore
        setError(activationResponse.error);
        return;
      }

      if (trialOption) {
        await Promise.all([
          adoptLegalDocument(
            PolicyDocumentType.TermsOfService,
            trialOption?.trial?.termsOfServiceVersion!
          ),
          adoptLegalDocument(
            PolicyDocumentType.PrivacyPolicy,
            trialOption?.trial?.privacyPolicyVersion!
          ),
        ]);
      }

      if (foundAccessCode?.email) {
        const loginResponse = await attemptLogin(
          foundAccessCode.email,
          userData.password!
        );
        if ((loginResponse as AuthenticateUserResponseSuccess)?.user) {
          if (
            (trialOption && trialOption.trial.trialIdentifier === 'adap-009') ||
            (trialOption && continueOnExploreRoutes)
          ) {
            const route = await getNextExploreRoute({
              trialIdentifier: trialOption.trial.trialIdentifier,
              trialOptionId: trialOption.id,
            });
            return history.push(route);
          } else {
            return history.push('/');
          }
        } else {
          console.error(t(`auth.signup.errors.SERVER_ERROR`));
        }
      }
    } catch (e) {
      console.error(t(`auth.signup.errors.${e.message}`));
    }
  }

  const pages: any[] = [
    {
      headerText: headerText
        ? headerText
        : t('createPasswordSignup.headerText'),
      headerDescription: descriptionText
        ? descriptionText
        : t('createPasswordSignup.headerDescription'),
      elements: [
        {
          title: t('createPasswordSignup.password'),
          header: <PasswordRequirements password={userData.password} />,
          subElements: [
            {
              type: 'NEW_PASSWORD',
              key: 'password',
              placeholder: t('createPasswordSignup.passwordPlaceholder'),
              skipValidateOnChange: true,
              validate: (password: string) => {
                if (DEVELOPER_FEATURES_ENABLED && password === testPassword)
                  return;

                const passwordStrength = calculateStrength(password);
                const owaspTest = owasp.test(password);

                if (passwordStrength !== Strength.Strong) {
                  const error = t('createPasswordSignup.passwordError');
                  return error;
                } else if (owaspTest.errors.length > 0) {
                  return owaspTest.errors[0];
                }
              },
            },
          ],
        },
        {
          title: t('createPasswordSignup.confirmPassword'),
          footer: (
            <>
              <StatusText color='red' text={error || ''} />
              <PasswordStrength password={userData.password} />
            </>
          ),
          subElements: [
            {
              type: 'NEW_PASSWORD',
              key: 'confirm_password',
              placeholder: t('createPasswordSignup.confirmPasswordPlaceholder'),
              skipValidateOnChange: true,
              validate: (password: string) => {
                if (password !== userData.password)
                  return t('createPasswordSignup.passwordNotMatch');
              },
            },
          ],
        },
      ],
    },
  ];

  const autocomplete = DEVELOPER_FEATURES_ENABLED
    ? async (): Promise<void> => {
        const placeHolderData = {
          ...userData,
          password: testPassword,
          confirm_password: testPassword,
        };

        setUserData(placeHolderData);
      }
    : null;

  return (
    <>
      <DynamicForm
        // @ts-ignore
        pages={pages}
        onChange={(key, value) => {
          setUserData({ [key]: value });
        }}
        onSubmit={() => attemptSignup()}
        data={userData}
        preferences={{ hidePersonalInformation: true }}
        autocomplete={autocomplete}
        disableSubmit={trialOption && !acceptedToSAndPrivacyPolicy}
        affirmation={
          showToSAndPrivacyPolicyCheckbox ? (
            <ExternalTOSAffirmation
              acceptedTOS={acceptedToSAndPrivacyPolicy}
              toggleAcceptedTOS={() =>
                setAcceptedToSAndPrivacyPolicy(state => !state)
              }
              setState={setTOSState}
              privacyPolicyVersion={trialOption?.trial?.privacyPolicyVersion}
              termsOfServiceVersion={trialOption?.trial?.termsOfServiceVersion}
            />
          ) : undefined
        }
        leftSideFooterSlot={<ExploreStepProgress mobileOnly />}
      />

      {trialOption && TOSState.document && TOSState.showDialog && (
        <Dialog open={TOSState.showDialog} onClose={() => toggleTOSDialog()}>
          <div className='tos-dialog-content'>
            <div className='tos-dialog-close-icon'>
              <CloseOutlined onClick={toggleTOSDialog} />
            </div>
            <div dangerouslySetInnerHTML={TOSState.document} />
          </div>
        </Dialog>
      )}
    </>
  );
}

export default ExploreCreatePassword;
