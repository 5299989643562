import React from 'react';
import { withTranslation } from 'react-i18next';

import { withQueryResult } from '../../hocs/WithQueryResult';
import Subheader from '../Subheader';
import {
  ClinicIntegrationsEditor,
  ClinicIntegrationsEditorQuery,
  ClinicIntegrationsEditorQueryVariables,
} from '@curebase/core/types';
import SettingsEditor from '../../Settings/SettingsEditor';

type Props = {
  queryResult: ClinicIntegrationsEditorQuery;
  data: any;
  t: any;
  clinicId: string;
};

class ClinicIntegrationsEditorView extends React.Component<Props> {
  render() {
    const { t, queryResult, data } = this.props;
    const atlasConfig = queryResult.getClinic?.atlasConfig;

    const formTypeForAttribute = name => {
      if (
        name.startsWith('is') ||
        ['autoAddPatient', 'loadToCurebaseCalendar'].includes(name)
      ) {
        return 'YES_NO';
      }
      if (name === 'password') {
        return 'PASSWORD';
      }
      return 'TEXT';
    };

    const pages = [
      {
        elements: [
          'isEnabled',
          'atlasMdurl',
          'username',
          'password',
          'maxAppointmentConcurrency',
          'aptDuration',
          'curebaseCalendar',
          'enrolledProviders',
          'autoAddPatient',
        ].map(k => {
          return {
            title: t(`clinicIntegrations.${k}Title`),
            subElements: [
              {
                type: formTypeForAttribute(k),
                key: k,
                placeholder:
                  k === 'password'
                    ? null
                    : t(`clinicIntegrations.${k}Placeholder`),
              },
            ],
          };
        }),
      },
    ];

    if (!atlasConfig) {
      return <Subheader text={t('clinicIntegrations.noAtlasFoundText')} />;
    }

    return (
      <SettingsEditor
        // @ts-ignore
        title={t('clinicIntegrations.settingsEditTitle')}
        defaultData={atlasConfig}
        // @ts-ignore
        validate={(key, value) => {
          return null;
        }}
        pages={pages}
        onSubmit={data => {}}
        onFinish={() => data.refetch()}
      />
    );
  }
}

const ClinicIntegrationsEditorComponent: React.ComponentType<{
  clinicId: string;
}> = withQueryResult(
  withTranslation('translations')(ClinicIntegrationsEditorView as any),
  ClinicIntegrationsEditor,
  (props: any): ClinicIntegrationsEditorQueryVariables => ({
    clinicId: props.clinicId,
  })
);

export default ClinicIntegrationsEditorComponent;
