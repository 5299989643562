import React, { useEffect, useState } from 'react';
import * as HumanConnect from 'humanapi-connect-client';
import hideZendeskWhileMounted from '../../../hooks/useHideZendeskWhileMounted';
import LoaderDialog from '../../basic/LoaderDialog';
import { updateHumanAPISources } from '../../../controllers/patientController';

interface ConnectSourceDataProps {
  humanApiAccessToken: string;
  onClose: () => void;
}

const hideHumanApiIframeFromFullStory = () => {
  const iframe = document.getElementById('human-api');
  if (!iframe) {
    return;
  }
  const fsBlock = 'fs-block';
  if (!iframe.classList.contains(fsBlock)) {
    iframe.classList.add(fsBlock);
  }
};

const ConnectHumanApi = (props: ConnectSourceDataProps) => {
  // HumanAPI has their own zendesk widget, so we should hide ours when the iframe is open
  hideZendeskWhileMounted();

  const { onClose: parentOnClose, humanApiAccessToken } = props;
  const [iframeOpen, setIframeOpen] = useState(false);

  const openHumanConnect = (
    token: string,
    onClose: (sessionResults: any) => void
  ) => {
    setIframeOpen(true);
    HumanConnect.open({
      token,
      onClose,
    });
    hideHumanApiIframeFromFullStory();
  };

  // Essentially componentDidMount
  useEffect(() => {
    if (humanApiAccessToken) {
      const onClose = async () => {
        await updateHumanAPISources();
        parentOnClose();
      };

      openHumanConnect(humanApiAccessToken, onClose);
    }
    // [JR] We want this hook to only fire on initial mount, so we don't want to keep track of dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* We dont want to render the dialog, while the iframe is open
    even though the iframe is above it, because the z index on the dialog
    will take over and stop inputs in the iframe from functioning as expected */
  if (!iframeOpen) {
    return <LoaderDialog open={true} onClose={parentOnClose} />;
  } else return null;
};
export default ConnectHumanApi;
