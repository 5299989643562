export const shouldBlockParticipantsEnrolledInOtherStudies = (
  trial: {
    readonly id: number;
    readonly blockParticipantsEnrolledInOtherStudies?: boolean | null;
  },
  trialOptions: ReadonlyArray<{
    readonly id: number;
    readonly isActive?: boolean | null;
    readonly trial: {
      readonly id: number;
    };
  }>
): boolean => {
  const blockParticipantsEnrolledInOtherStudies = !!trial.blockParticipantsEnrolledInOtherStudies;

  const isParticipantActiveInOtherStudies = !!trialOptions?.find(
    trialOption => trialOption.isActive && trialOption.trial.id !== trial.id
  );

  return (
    isParticipantActiveInOtherStudies && blockParticipantsEnrolledInOtherStudies
  );
};
