import qs from 'query-string';
import _isNull from 'lodash/isUndefined';
import _isUndefined from 'lodash/isNull';
import { ParticipantPickerState } from 'src/store/participantPicker/types';
import { Maybe } from '@curebase/core/types';
import { initialState as participantPickerInitialState } from '../store/participantPicker/reducers';

export function stringifyQuery(
  data: Parameters<typeof qs.stringify>[0]
): string {
  return qs.stringify(data, { arrayFormat: 'comma' });
}

export function parseParticipantFilter(search: string): ParticipantPickerState {
  type QueryResultType = Maybe<ParticipantPickerState> | undefined;

  // @ts-ignore
  const parsed: QueryResultType = qs.parse(search, { arrayFormat: 'comma' });

  const filter: ParticipantPickerState = {
    searchQuery:
      parsed?.searchQuery ?? participantPickerInitialState.searchQuery,
    trialIds: transformArrayQueryField<number>(parsed?.trialIds).map(Number),
    statuses: transformArrayQueryField<string>(parsed?.statuses),
    epochs: transformArrayQueryField<string>(parsed?.epochs),
    filter: parsed?.filter ?? participantPickerInitialState.filter,
    sortingOrder:
      parsed?.sortingOrder ?? participantPickerInitialState.sortingOrder,
  };

  return filter;
}

function transformArrayQueryField<T>(
  value: Maybe<T | T[]> | undefined
): Array<T> {
  if (_isNull(value) || _isUndefined(value)) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}
