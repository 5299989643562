import { SubmittableTimeRange } from '@curebase/core/types';
import moment from 'moment';

export default function isDataCaptureSubmittableAtTime(
  submissionTimes: SubmittableTimeRange,
  currentDate: number | string
): boolean {
  const {
    firstSubmittableTime,
    lastSubmittableTime,
    extendedLastSubmittableTime,
  } = submissionTimes;

  const endBound = extendedLastSubmittableTime ?? lastSubmittableTime;
  if (firstSubmittableTime && endBound) {
    return moment(currentDate).isBetween(
      firstSubmittableTime,
      endBound,
      undefined,
      '[]' // inclusive
    );
  } else if (!firstSubmittableTime) {
    return moment(currentDate).isBefore(lastSubmittableTime);
  } else if (!lastSubmittableTime) {
    return moment(currentDate).isAfter(firstSubmittableTime);
  }

  return false;
}
