import React from 'react';
import participantManagement from '@curebase/modules/participantManagement';
import { useTranslation } from 'react-i18next';
import { useDuplicateEmailWarningQuery } from 'src/types';
import StandardButton from '../basic/StandardButton';
import Loading from '../Loading';
import moment from 'moment-timezone';
import { IS_DEVELOPMENT } from '@curebase/core/lib/env';
import Alert from 'src/components/basic/Alert';
import { getLocaleFormatDates } from '../../context/localeContext';

const {
  services: { trialOption: trialOptionService },
} = participantManagement;

type DuplicateEmailWarningProps = {
  userId: any;
  trialId: number;
  onBack: () => void;
  onSubmit: () => void;
};

export const DuplicateEmailWarning = (props: DuplicateEmailWarningProps) => {
  const { t } = useTranslation('translations');
  const { userId, trialId } = props;
  const { data, loading } = useDuplicateEmailWarningQuery({
    variables: { userId, trialId },
  });

  if (!data || loading) return <Loading />;

  const localesFormat = getLocaleFormatDates();
  const { firstName, lastName, email, phoneNumber, patient } = data.getUser!;
  const { birthday, zipcode, trialOptions } = patient!;
  const names = trialOptions?.map(e => e.trial.name) ?? [];

  const shouldBlockBecauseActiveInOtherStudies = trialOptionService.shouldBlockParticipantsEnrolledInOtherStudies(
    data.getTrial!,
    trialOptions!
  );

  return (
    <div className='existing-body'>
      {shouldBlockBecauseActiveInOtherStudies && (
        <div className='blocked-ppt-from-other-studies-alert'>
          <Alert
            title={t('duplicateEmailWarning.blockTitle')}
            description={t('duplicateEmailWarning.blockDescription', {
              trialName: data.getTrial.name,
            })}
            severity='warning'
          />
        </div>
      )}

      {!shouldBlockBecauseActiveInOtherStudies && (
        <div className='title'>
          We have a participant record that looks similar to this one! Is this
          the same person?
        </div>
      )}
      <div>{`Name:    ${firstName} ${lastName}`}</div>
      <div>{`Email:   ${email}`}</div>
      <div>{`Phone:   ${phoneNumber}`}</div>
      {birthday && (
        <div>{`Date of birth:   ${moment(parseInt(birthday)).format(
          localesFormat.moment.dateFormat
        )}`}</div>
      )}
      <div>{`Zipcode:   ${zipcode}`}</div>
      <div>{`Participant in:   ${names.join(', ')}`}</div>
      {!shouldBlockBecauseActiveInOtherStudies && (
        <div className='info'>
          Contact info will be updated to match the form you just completed.
        </div>
      )}

      <div className='action-buttons'>
        <StandardButton text='Go Back' onClick={props.onBack} />
        {!shouldBlockBecauseActiveInOtherStudies && (
          <StandardButton
            text='Yes, this is the same person.'
            onClick={props.onSubmit}
            secondDelayUnlock={!IS_DEVELOPMENT ? 10000 : 3000}
            delayButtonUnlock={true}
          />
        )}
      </div>
    </div>
  );
};
