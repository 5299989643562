import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import CustomStyleContext from '../../../context/customStyleContext';
import { useImmutableState } from '../../../hooks/useImmutableState';
import CustomStyleProvider from '../../../providers/CustomStyleProvider';
import { getUser } from '../../../store';
import DynamicForm from '../DynamicForm';
import { SignatureMismatchWarning } from '../SignatureMismatchWarning';
import { FileUploadContext } from '../FilePreview';
import { useCreateAttestationsFields, useCreatePages } from './hooks';
import { SignatureDialogProps } from './types';
import { signatureIsLikelyCorrect, submitSignatureData } from './tools';
import { useParams } from 'react-router-dom';
import { DocumentsContext } from 'src/components/InformedConsent/DocumentsOverview';
import { DocumentType } from '@curebase/core/types';
import { onDialogClose } from '../../../utils/mobileHelpers';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

const SignatureCaptureInDialog = (props: SignatureDialogProps) => {
  const [errors] = useState({});
  const [currentFormPage, setCurrentFormPage] = useState<number>();
  const [
    showSignatureSimilarityPage,
    setShowSignatureSimilarityPage,
  ] = useState(false);
  const [expectedName, setExpectedName] = useState();

  const [data, setData] = useImmutableState<any>({
    date: DateTime.now().toMillis(),
  });
  const { trialOptionId = props.trialOptionId } = useContext(FileUploadContext);
  const { minorAssent, setAssent } = useContext(DocumentsContext);
  const customStyle = useContext(CustomStyleContext);
  const { documentType } = useParams<any>();
  const [reason, setReason] = useState(props.reason);
  const { t } = useOverridableTranslation();
  const { patient, inPatientMode }: any = props;
  const { additionalUserInfo } = getUser();
  const attestationsFields = useCreateAttestationsFields({
    attestations: props.attestations,
    isMinorAssentSignature: props.isMinorAssentSignature,
    showMinorAssentCheckbox: props.showMinorAssentCheckbox,
  });
  const pages = useCreatePages({
    attestationsFields,
    reason,
    isMinorAssentSignature: props.isMinorAssentSignature,
  });
  const signatureProps = {
    ...props,
    reason,
  };

  useEffect(() => {
    // Set default reason when signing on behalf of minor
    if (data.parent_signature_on_behalf_of_minor === 'checked') {
      setReason(t('signDocuments.approveThisDoc'));
    } else if (data.parent_signature_on_behalf_of_minor === 'unchecked') {
      setReason(props.reason);
    }
  }, [data.parent_signature_on_behalf_of_minor, t, props.reason]);

  return (
    <CustomStyleProvider customStyle={customStyle}>
      <div className='light-dialog signature-capture'>
        <div className='booker'>
          <div className='booker-title'>
            <Typography variant='h4'>
              {props.title || t('signatureCaptureInDialog.defaultTitle')}
            </Typography>
          </div>

          {!showSignatureSimilarityPage ? (
            <DynamicForm
              // @ts-ignore
              pages={pages}
              onChange={(key: string, value: any) => {
                setData({
                  [key]: value,
                  ...(documentType === DocumentType.MinorAssent &&
                  key === 'childLegalName'
                    ? { legalName: value }
                    : {}),
                });
              }}
              currentPage={currentFormPage}
              onPageChange={(newPage: number) => {
                setCurrentFormPage(newPage);
              }}
              data={data}
              errors={errors}
              onSubmit={async () => {
                if (!data || (!data.legalName && !data.childLegalName)) {
                  return;
                }

                if (
                  data.childLegalName &&
                  documentType !== DocumentType.MinorAssent
                ) {
                  const { childLegalName } = data;
                  setAssent({ childLegalName });
                }

                if (
                  signatureIsLikelyCorrect(
                    inPatientMode,
                    data.legalName,
                    patient,
                    additionalUserInfo,
                    trialOptionId as any,
                    documentType === DocumentType.MinorAssent,
                    minorAssent?.childLegalName
                  ) ||
                  documentType === DocumentType.MinorAssent
                ) {
                  await submitSignatureData(
                    signatureProps,
                    trialOptionId as any,
                    data,
                    attestationsFields
                  );
                  window.scrollTo({ top: 0 });
                } else {
                  const expectedName =
                    documentType === DocumentType.MinorAssent && minorAssent
                      ? minorAssent.childLegalName
                      : inPatientMode
                      ? patient?.user.displayName
                      : `${additionalUserInfo.firstName} ${additionalUserInfo.lastName}`;
                  setExpectedName(expectedName);
                  setShowSignatureSimilarityPage(true);
                }
              }}
              preferences={{ hidePersonalInformation: true }}
            />
          ) : (
            <SignatureMismatchWarning
              enteredName={data.legalName} // @ts-ignore
              expectedName={expectedName}
              onBack={() => setShowSignatureSimilarityPage(false)}
              onSubmit={() =>
                submitSignatureData(
                  signatureProps,
                  trialOptionId as any,
                  data,
                  attestationsFields
                )
              }
            />
          )}
        </div>

        <div
          className='close-dialog-icon-container'
          onClick={() => onDialogClose(props.onClose)}
        >
          <CloseIcon className='close-dialog-icon-svg' />
        </div>
      </div>
    </CustomStyleProvider>
  );
};

export default SignatureCaptureInDialog;
