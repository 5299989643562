import { SchedulingConfigurationInterface } from '@curebase/core/types';

export const NEW_SCHEDULED_EVENT = 'NEW_SCHEDULED_EVENT';
export const EDIT_SCHEDULED_EVENT = 'EDIT_SCHEDULED_EVENT';
export const REMOVE_SCHEDULED_EVENT = 'REMOVE_SCHEDULED_EVENT';

declare namespace TBScheduledEvent {
  interface Payload {}

  export interface Action {
    type:
      | typeof NEW_SCHEDULED_EVENT
      | typeof EDIT_SCHEDULED_EVENT
      | typeof REMOVE_SCHEDULED_EVENT;
    payload: Payload;
  }
}

export interface NewScheduledEventAction extends TBScheduledEvent.Action {
  type: typeof NEW_SCHEDULED_EVENT;
  payload: {
    config: SchedulingConfigurationInterface;
  };
}
export interface EditScheduledEventAction extends TBScheduledEvent.Action {
  type: typeof EDIT_SCHEDULED_EVENT;
  payload: {
    id: string;
    config: SchedulingConfigurationInterface;
  };
}

export interface RemoveScheduledEventAction extends TBScheduledEvent.Action {
  type: typeof REMOVE_SCHEDULED_EVENT;
  payload: {
    id: string;
  };
}

export type ScheduledEventAction =
  | NewScheduledEventAction
  | EditScheduledEventAction
  | RemoveScheduledEventAction;
export type TBScheduling = { [uuid: string]: SchedulingConfigurationInterface };
export type TBSchedulingConfiguration = SchedulingConfigurationInterface;
