import { combineReducers } from 'redux';
import ui from './ui';
import { language } from './language';
import { patientMode } from './patientMode';
import resources from './resources';
import currentDate from './currentDate';
import trialBuilder from './TrialBuilder';
import navigation from './navigation';
import fullStory from './fullStory';
import timezone from '../timezone/reducers';
import user from '../user/reducers';
import telehealth from '../telehealth/reducers';
import participantPicker from '../participantPicker/reducers';
import maintenanceMode from './maintenanceMode';

export const appReducer = combineReducers({
  user,
  ui,
  telehealth,
  language,
  patientMode,
  resources,
  currentDate,
  trialBuilder,
  navigation,
  fullStory,
  timezone,
  participantPicker,
  maintenanceMode,
});
