import { PolicyDocumentType } from '../types';

export const defaultPrivacyPolicy = 'PP-default-v2.md';
export const defaultTermsOfService = 'TOS-default-v1.md';

export const AvailablePolicies: Record<PolicyDocumentType, Array<string>> = {
  [PolicyDocumentType.PrivacyPolicy]: [
    'privacypolicy-walgreens.md',
    'privacypolicy-adaptive.md',
    'privacypolicy-heor.md',
    'PP-default-v1.md',
    defaultPrivacyPolicy,
  ],
  [PolicyDocumentType.TermsOfService]: [
    'termsofservice-walgreens.md',
    'termsofservice-alira.md',
    defaultTermsOfService,
  ],
  [PolicyDocumentType.CookiePolicy]: [],
};
