import { useFlags } from 'launchdarkly-react-client-sdk';
import { WithTranslationProps } from 'react-i18next';

import {
  ParticipantDashboard,
  ParticipantDashboardQuery,
  ParticipantDashboardQueryVariables,
  StudyActivity,
  StudyActivityStatus,
  StudyActivityType,
} from '@curebase/core/types';
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import _groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { hideZendeskWidget, showZendeskWidget } from 'src/lib/zendeskWidget';
import { Route } from 'react-router-dom';
import { useLocale } from '../../hooks/useLocale';
import { GtmContainerBlob, gtmContainerInit } from '../../lib/analytics-gtm';
import { getViewPatientBaseUrl } from '../../lib/users';
import CustomStyleProvider from '../../providers/CustomStyleProvider';
import ActionCard from '../basic/ActionCard';
import ActionListItem from '../basic/ActionListItem';
import DialogHeader from '../basic/DialogHeader';
import Dialog from '../basic/SafeDialog';
import { InjectedProps, withQueryResult } from '../hocs/WithQueryResult';
import PageHeader from '../PageHeader';
import ReportNewEventHomepage from '../ReportEvent/ReportNewEventHomepage';
import BookerDialog from './Booker/BookerDialog';
import ConnectFitbitActionListItem from './ConnectFitbitActionListItem';
import ConnectSourceDataRoutes, {
  ConnectRoutes,
} from './ConnectSourceData/ConnectSourceDataRoutes';
import FitbitActionCard from './FitbitActionCard';
import { createDashboardBg } from './helpers';
import ManageVisitActionCard from './ManageVisitActionCard';
import ScheduleVisitActionCard from './ScheduleVisitActionCard';
import ScheduleVisitActionListItem from './ScheduleVisitActionListItem';
import { activitySelector } from './utils';
import { generatePaypalConnectURL } from '../../lib/payments';
import DownloadAppActionCard from './DownloadApp/DownloadAppActionCard';
import DownloadAppActionListItem from './DownloadApp/DownloadAppActionListItem';
import PlutoActionCard from './Pluto/PlutoActionCard';
import PlutoActionListItem from './Pluto/PlutoActionListItem';

const { Active, ActiveWrongTime, Inactive } = StudyActivityStatus;
const { ConnectFitbit, DownloadApp, Pluto, ScheduleVisit } = StudyActivityType;

interface URLParams {
  trialOptionId: string;
}

interface PropsFromRedux {
  currentDate: number;
  resetDate: (dateOffset: number) => void;
  gtmContainers?: GtmContainerBlob;
}

interface Props
  extends WithTranslationProps,
    PropsFromRedux,
    InjectedProps<
      ParticipantDashboardQueryVariables,
      ParticipantDashboardQuery,
      URLParams
    > {}

export const ParticipantDashboardContext = React.createContext<{
  refetch: (variables?: ParticipantDashboardQueryVariables) => Promise<any>;
}>({
  refetch: async () => {},
});

const ParticipantDashboardView = (props: Props) => {
  const { t } = useTranslation('translations');
  const { hideActivitiesNumber, mobileFriendlyActionCards } = useFlags();

  const { getTrialOption: trialOption, refetch } = props.queryResult;
  const { gtmContainers, resetDate, currentDate: currDt, history } = props;
  const currentDate = DateTime.now()
    .plus({ seconds: trialOption?.dateOffset || 0 })
    .toMillis();

  const { locale } = useLocale(trialOption?.locale);

  const activitySel = activitySelector({ ...props, trialOption, t } as any);

  function handleZendeskIntegration() {
    const shouldPresentZendeskWidget = trialOption?.trial?.participantIntegrations?.some(
      integration => integration === 'ZENDESK'
    );

    if (!shouldPresentZendeskWidget) {
      hideZendeskWidget();
    } else {
      showZendeskWidget();
    }
  }

  useEffect(() => {
    if (!trialOption) return;
    handleZendeskIntegration();
  }, [trialOption]);

  useEffect(() => {
    // Only update the redux state date if it the difference between the current time
    // and what is in the state is greater than 2 minutes.
    if (
      typeof trialOption?.dateOffset === 'number' &&
      currentDate - currDt > 120000
    ) {
      resetDate(trialOption.dateOffset);
    }
  }, [trialOption, resetDate]);
  gtmContainerInit(gtmContainers);

  const renderDialogRoutes = () => {
    return (
      <Switch>
        <Route
          path={`${getViewPatientBaseUrl(
            trialOption.id
          )}/:configId/booker/:action`}
          render={() => {
            return <BookerDialog isOpen trialOptionId={trialOption.id} />;
          }}
        />

        <Route
          path={`${getViewPatientBaseUrl(trialOption.id)}/paypal-instructions`}
          render={() => {
            const goBackToDashboard = () =>
              history.push(getViewPatientBaseUrl(trialOption.id));

            const paypalUrl = generatePaypalConnectURL();

            return (
              <Dialog open onClose={goBackToDashboard}>
                <DialogHeader
                  title={t('paypalInstructions.title')}
                  onClose={goBackToDashboard}
                />

                <DialogContent>
                  <p>{t('paypalInstructions.text')}</p>
                </DialogContent>

                <DialogActions>
                  <Button onClick={goBackToDashboard} color='primary'>
                    {t('common.close')}
                  </Button>

                  <Button
                    variant='contained'
                    onClick={() => window.open(paypalUrl, '_blank')}
                    color='primary'
                  >
                    {t('participants.connectPayment.button')}
                  </Button>
                </DialogActions>
              </Dialog>
            );
          }}
        />

        <Route
          path={`${getViewPatientBaseUrl(trialOption.id)}/${
            ConnectRoutes.main
          }`}
          render={() => {
            return (
              <ConnectSourceDataRoutes isOpen trialOptionId={trialOption.id} />
            );
          }}
        />
      </Switch>
    );
  };

  const renderActionListItem = (
    activity: StudyActivity,
    index: number,
    trialOptionId: number
  ) => {
    switch (activity.config.type) {
      case ConnectFitbit:
        return <ConnectFitbitActionListItem key={index} activity={activity} />;
      case DownloadApp:
        return <DownloadAppActionListItem key={index} activity={activity} />;
      case Pluto:
        return <PlutoActionListItem key={index} activity={activity} />;
      case ScheduleVisit:
        return (
          <ScheduleVisitActionListItem
            key={index}
            activity={activity}
            trialOptionId={trialOptionId}
          />
        );
      default:
        return (
          <ActionListItem
            key={index}
            {...activitySel.getDisplayPropsFromActivity(activity)}
          />
        );
    }
  };

  const renderMilestone = (groupActivities: StudyActivity[]) => {
    const groupConfig = groupActivities && groupActivities[0].config.group;
    if (!groupConfig) return null;

    return (
      <div key={groupConfig.name}>
        <div className='pd-milestone'>
          <div className='pd-milestone-text'>
            <div className='pd-milestone-title'>{groupConfig.title}</div>
            <div className='pd-milestone-description'>
              {groupConfig.description}
            </div>
          </div>
          <img
            className='pd-milestone-img'
            src={
              groupConfig.icon
                ? `/icons/${groupConfig.icon}`
                : '/icons/lock.png'
            }
            alt='Locked activities'
          />
        </div>

        <div className='action-item-list'>
          {groupActivities.map((activity, index) =>
            renderActionListItem(activity, index, trialOption.id)
          )}
        </div>
      </div>
    );
  };

  if (!trialOption) return null;

  const activeActivities = trialOption.studyActivities.filter(act => {
    return act.config.type !== ScheduleVisit && act.status === Active;
  });

  const activeScheduleActivities = trialOption.studyActivities.filter(act => {
    return (
      act.config.type === ScheduleVisit &&
      (act.status === Active || !!act.activeId)
    );
  });

  const futureActivities = trialOption.studyActivities.filter(
    act => act.status === ActiveWrongTime
  );

  const inactiveActivities = trialOption.studyActivities.filter(
    act =>
      act.status === Inactive &&
      act.config.type !== StudyActivityType.CustomAction
  );

  // Group inactive activities by milestone
  const inactiveGroups = _groupBy(inactiveActivities, activity => {
    if (activity.config.group?.title) return activity.config.group?.name;
    return 'NO_MILESTONE';
  });
  const inactiveWithoutMilestone = inactiveGroups['NO_MILESTONE'] || [];
  delete inactiveGroups['NO_MILESTONE'];

  const { status, trial, trialConfiguration } = trialOption;

  const metadata = trialConfiguration.machine[status]?.meta;

  const allActiveActivities = [
    ...activeActivities,
    ...activeScheduleActivities,
  ];

  const hasActivities = allActiveActivities.length > 0;

  const numberToComplete = allActiveActivities;

  let headerBottomText: string = t('participants.headerBottomText');

  if (!hasActivities) {
    headerBottomText = t('participants.noActivitiesHeaderBottomText');
    if (metadata?.noActiveActivitiesCopy) {
      if (
        typeof metadata?.noActiveActivitiesCopy === 'object' &&
        metadata?.noActiveActivitiesCopy[locale]
      ) {
        headerBottomText = metadata?.noActiveActivitiesCopy[locale];
      } else if (typeof metadata?.noActiveActivitiesCopy === 'string') {
        headerBottomText = metadata?.noActiveActivitiesCopy;
      }
    }
  }

  const customCss = trialOption.trial.custom_css;

  return (
    <CustomStyleProvider customStyle={trial.customStyle}>
      <ParticipantDashboardContext.Provider value={{ refetch }}>
        {customCss && <style dangerouslySetInnerHTML={{ __html: customCss }} />}
        <div
          className='participant-dashboard-container'
          style={createDashboardBg(trial?.customStyle?.backgroundColor)}
        >
          <PageHeader
            trialName={trialOption.trial.name}
            customStyle={trialOption.trial.customStyle}
            shortDescription={trialOption.trial?.trialSummary.shortDescription}
          />
          <div className='participant-dashboard'>
            {/* Success Status if you have no cards! */}
            {!hasActivities && (
              <div className='pd-page-header'>
                <div className='pd-page-header-top-text'>
                  {t('participants.allSetForNow')}
                </div>
                <div className='pd-page-header-bottom-text'>
                  {headerBottomText}
                </div>
                <div className='success-img-container'>
                  <img
                    className='success-img'
                    src='/successPlaceholder.svg'
                    alt='Success Placeholder'
                  />
                </div>
              </div>
            )}

            {/* Cards Section */}
            {hasActivities && (
              <>
                {/* Header with count of tasks */}
                <div className='pd-page-header'>
                  <Typography variant='h3' className='pd-page-header-top-text'>
                    {!hideActivitiesNumber &&
                      (numberToComplete.length > 1
                        ? t('participants.taskTitle.plural', {
                            count: numberToComplete.length,
                          })
                        : t('participants.taskTitle.singular', {
                            count: numberToComplete.length,
                          }))}
                    {hideActivitiesNumber &&
                      t('participants.taskTitle.upcomingActivities')}
                  </Typography>
                  <div className='pd-page-header-bottom-text'>
                    {headerBottomText}
                  </div>
                </div>

                {/* Side-scrolling card view for high-priority items */}
                <div
                  className={`action-card-list${
                    mobileFriendlyActionCards ? ' action-card-list-mobile' : ''
                  }`}
                >
                  {activeActivities.map((activity, i) => {
                    switch (activity.config.type) {
                      case ConnectFitbit:
                        return <FitbitActionCard key={i} activity={activity} />;
                      case DownloadApp:
                        return (
                          <DownloadAppActionCard key={i} activity={activity} />
                        );
                      case Pluto:
                        return <PlutoActionCard key={i} activity={activity} />;
                      default:
                        return (
                          <ActionCard
                            key={i}
                            {...activitySel.getDisplayPropsFromActivity(
                              activity
                            )}
                          />
                        );
                    }
                  })}
                  {activeScheduleActivities.map((activity, i) => {
                    if (activity.activeId) {
                      return (
                        <ManageVisitActionCard
                          key={i}
                          activity={activity}
                          trialOptionId={trialOption.id}
                          visitBookingId={activity.activeId}
                        />
                      );
                    } else {
                      return (
                        <ScheduleVisitActionCard
                          key={i}
                          activity={activity}
                          trialOptionId={trialOption.id}
                        />
                      );
                    }
                  })}
                </div>
              </>
            )}

            <div className='pd-mid-section'>
              {/* Report events, if in the correct states */}
              <ReportNewEventHomepage
                helpContent={trialOption.helpContent}
                canReportMedicalProblem={
                  trialOption.trial.allowParticipantReportedMedicalProblems
                }
                {...props}
              />
            </div>

            {/* Future activities */}
            {(futureActivities.length > 0 ||
              inactiveWithoutMilestone.length > 0) && (
              <>
                <div className='pd-list-header'>
                  <div className='pd-list-header-bar' />
                  <Typography variant='h3' className='pd-list-header-bottom'>
                    {t('participants.stepsForLater')}
                  </Typography>
                </div>

                <div className='action-item-list'>
                  {futureActivities
                    .concat(inactiveWithoutMilestone)
                    .map((activity, index) =>
                      renderActionListItem(activity, index, trialOption.id)
                    )}
                </div>
              </>
            )}

            {/* Inactive activities, future last */}
            {Object.keys(inactiveGroups)
              .filter(groupName => groupName !== 'FUTURE')
              .map(inactiveGroup =>
                renderMilestone(inactiveGroups[inactiveGroup])
              )}
            {renderMilestone(inactiveGroups['FUTURE'])}

            {renderDialogRoutes()}
          </div>
        </div>
      </ParticipantDashboardContext.Provider>
    </CustomStyleProvider>
  );
};

const mapStateToProps = state => ({
  currentDate: state.currentDate,
  gtmContainers: state.user.gtmContainers,
});
const mapDispatchToProps = dispatch => ({
  resetDate: (dateOffset: number) => {
    dispatch({
      type: 'RESET_DATE',
      dateOffset,
    });
  },
});

const ParticipantDashboardComponent = withQueryResult<
  ParticipantDashboardQueryVariables,
  ParticipantDashboardQuery,
  PropsFromRedux,
  URLParams
>(
  ParticipantDashboardView,
  ParticipantDashboard,
  (props): ParticipantDashboardQueryVariables => ({
    trialOptionId: parseInt(props.match.params.trialOptionId),
    currentDate: DateTime.fromMillis(props.currentDate).toISO(),
  }),
  {
    noDismountOnRefetch: true,
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantDashboardComponent);
