import { sendEventsToMobile } from '../utils/mobileHelpers';

function safeZe(cmd: string, options?: any) {
  const { zE }: any = window;
  if (!zE) {
    console.debug(
      `Unable to execute command '${cmd}' because Zendesk widget not bound to window.`
    );
    return;
  }
  zE('webWidget', cmd, options);
}

export const hideZendeskWidget = () => safeZe('hide');
export const showZendeskWidget = () => safeZe('show');
export const zendeskSetLocale = (locale: string) => safeZe('setLocale', locale);

export const applyZendeskEventsForMobileApp = () => {
  setTimeout(() => {
    const { zE }: any = window;
    if (!zE) {
      console.debug(
        `Unable to apply events because Zendesk widget not bound to window.`
      );
      return;
    }
    // @ts-ignore
    window.zE('webWidget:on', 'open', () =>
      sendEventsToMobile('ZENDESK_SHOW_CLICKED')
    );
    // @ts-ignore
    window.zE('webWidget:on', 'close', () =>
      sendEventsToMobile('ZENDESK_HIDE_CLICKED')
    );
  }, 1000);
};

export function assignUserToZendeskWidget(name?: string, email?: string) {
  safeZe('identify', {
    name,
    email,
  });
}

export function prefillZendeskWidget(
  name?: string,
  email?: string,
  phone?: string
) {
  safeZe('prefill', {
    name: {
      value: name,
      readOnly: false,
    },
    email: {
      value: email,
      readOnly: false,
    },
    phone: {
      value: phone,
      readOnly: false,
    },
  });
}

export function removeUserFromZendeskWidget() {
  safeZe('reset');
}

export function addListenerToZendeskLauncher() {
  setTimeout(() => {
    const zeIframe = document.getElementById('launcher');

    if (!!zeIframe) {
      // @ts-ignore
      const zeButton = zeIframe.contentWindow.document.getElementsByTagName(
        'button'
      )[0];
      zeButton.addEventListener('click', applyFsExcludeToZendeskIframe, false);
      //Apply Zendesk events for mobile app
      applyZendeskEventsForMobileApp();
    } // @TODO: improve this and remove timeout dependency
  }, 3000);
}

function applyFsExcludeToZendeskIframe() {
  setTimeout(() => {
    const zeIframe = document.getElementById('webWidget');

    if (!!zeIframe) {
      // https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-FullStory-#code-first
      zeIframe.classList.add('fs-exclude');
    } // @TODO: improve this and remove timeout dependency
  }, 1000);
}

// deprecated
let widgetIframeClassName;
export function applyFsBlockToZendeskWidget() {
  setInterval(() => {
    const possibleIframes =
      document.getElementsByClassName('zEWidget-webWidget') || [];
    if (possibleIframes.length === 0) return;
    const iframe = possibleIframes[0];
    if (iframe.className !== widgetIframeClassName) {
      iframe.classList.add('fs-block');
      widgetIframeClassName = iframe.className;
    }
  }, 100); // Set an aggressive interval, to be certain no interaction with the widget is captured.
}
