import { Button } from '@material-ui/core';
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import withSizes from 'react-sizes';
import { flow } from 'fp-ts/function';
import { DatePicker, MuiPickersUtilsProvider, Day } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DateTime } from 'luxon';
import { CustomLuxonUtils } from 'src/utils/LuxonWrapper';
import { TimeSlotPure } from '@curebase/modules/scheduling/dto/scheduling';
import { LuxonFromAnything } from '@curebase/core/lib/dates';
import { getLocale, getLocaleFormatDates } from '../../context/localeContext';
import { DateType } from '@date-io/type';

interface SmallScreen {
  isSmallScreen?: boolean;
}

interface CurebaseSchedulerProps extends SmallScreen {
  slots: TimeSlotPure[];
  visitSiteTimezone: string;
  selectedStartTime: number | null;
  setSelectedStartTime: Dispatch<SetStateAction<number | string | null>> | null;
  onSelectSlot: (p: TimeSlotPure) => Promise<void>;
  setMonthVisibleToUser?: (month: string) => void;
  monthVisibleToUser?: string;
}
const localeFormats = getLocaleFormatDates();

function getSlotTextForTz(slot: TimeSlotPure, timezone: string) {
  const start = LuxonFromAnything(slot.start)
    .setZone(timezone)
    .toFormat(localeFormats.luxon.shortTimeMeridiem);
  const end = LuxonFromAnything(slot.end)
    .setZone(timezone)
    .toFormat(localeFormats.luxon.shortTimeMeridiem);
  return `${start} - ${end}`;
}

const dateFormat = localeFormats.luxon.dateFormat;

function datesToSlots(
  slots: TimeSlotPure[],
  timezone: string
): Partial<Record<string, TimeSlotPure[]>> {
  // [Unix milliseconds] => [MM/dd/yyyy] e.g. 10/11/1993
  const timeSlotPureToDate = flow(
    (slot: TimeSlotPure) => LuxonFromAnything(slot.start).setZone(timezone),
    (dateWithZone: DateTime) => dateWithZone.toFormat(dateFormat)
  );

  return (slots ?? []).reduce((acc, slot) => {
    const dateForSlot = timeSlotPureToDate(slot);
    if (acc[dateForSlot]) {
      acc[dateForSlot].push(slot);
    } else {
      acc[dateForSlot] = [slot];
    }
    return acc;
  }, {});
}

enum CurebaseSchedulerStatus {
  Idle,
  Loading,
  Error,
  Success,
}
function statusToButtonText(st: CurebaseSchedulerStatus, t: any) {
  switch (st) {
    case CurebaseSchedulerStatus.Loading:
      return t('booker.buttonLoading');
    case CurebaseSchedulerStatus.Success:
      return t('booker.buttonSuccess');
    case CurebaseSchedulerStatus.Error:
      return t('booker.buttonTryAgain');
    case CurebaseSchedulerStatus.Idle:
      return t('booker.buttonConfirm');
  }
}

const LoadingAndSuccess: ReadonlyArray<CurebaseSchedulerStatus> = [
  CurebaseSchedulerStatus.Loading,
  CurebaseSchedulerStatus.Success,
];

/**
 * This component takes timeslots with absolute time expressed in Unix milliseconds
 * and displays a calendar view expressed in the timezone passed in.
 */
function CurebaseScheduler(props: CurebaseSchedulerProps) {
  const {
    isSmallScreen,
    selectedStartTime,
    setSelectedStartTime,
    slots,
    visitSiteTimezone,
    setMonthVisibleToUser = (month: string) => {},
    monthVisibleToUser = '',
  } = props;
  const { country, language, locale } = getLocale();
  const { t } = useTranslation('translations');
  const [selected, setSelected] = useState<DateTime | null>(null);
  const [isDateConfirmed, setIsDateConfirmed] = useState<boolean>(false);
  const [status, setStatus] = useState<CurebaseSchedulerStatus>(
    CurebaseSchedulerStatus.Idle
  );
  const [visibleMonth, setVisibleMonth] = useState<string>(monthVisibleToUser);

  const onSelectSlot = useCallback(
    async (slot: TimeSlotPure) => {
      if (LoadingAndSuccess.includes(status)) {
        return;
      }
      setStatus(CurebaseSchedulerStatus.Loading);
      try {
        await props.onSelectSlot(slot);
        setStatus(CurebaseSchedulerStatus.Success);
      } catch (err) {
        setStatus(CurebaseSchedulerStatus.Error);
      }
    },
    [props, status]
  );

  const initialized = useRef(false);

  const datesToSlotsMap = useMemo(
    () => datesToSlots(slots, visitSiteTimezone),
    [slots, visitSiteTimezone]
  );

  const selectedSlots = selected
    ? datesToSlotsMap[selected.toFormat(dateFormat)]
    : undefined;

  const shouldDisable = useCallback(
    (day: DateTime) => {
      //date is JS Date in user's timezone - we must convert
      const date = day.setZone(visitSiteTimezone).toFormat(dateFormat);
      const available =
        datesToSlotsMap[date] && datesToSlotsMap[date]!.length > 0;

      const shouldDisable = !available;
      return shouldDisable;
    },
    [datesToSlotsMap, visitSiteTimezone]
  );

  const isOutsideWindow = useCallback(
    (day: DateTime) => {
      //date is JS Date in user's timezone - we must convert
      const date = day.setZone(visitSiteTimezone).toFormat(dateFormat);
      const available =
        datesToSlotsMap[date] &&
        datesToSlotsMap[date]!.length > 0 &&
        // @ts-ignore
        datesToSlotsMap[date]!.some(slot => !slot.isOutsideWindow);

      const shouldDisable = !available;
      return shouldDisable;
    },
    [datesToSlotsMap, visitSiteTimezone]
  );
  const firstBookableDate = DateTime.fromFormat(
    Object.keys(datesToSlotsMap)?.[0],
    dateFormat
  );
  const visibleDate: DateTime = Boolean(visibleMonth)
    ? DateTime.fromISO(visibleMonth || '')
    : firstBookableDate;
  const lastBookableDate = DateTime.fromFormat(
    Object.keys(datesToSlotsMap)?.[Object.keys(datesToSlotsMap).length - 1],
    dateFormat
  );
  // Subtract one day so we aren't focusing on first possible day
  // because otherwise if they select first day, it isn't detected
  const initialFocusedDate = visibleMonth
    ? visibleDate.toFormat(dateFormat)
    : firstBookableDate.minus({ days: 1 }).toFormat(dateFormat);

  const dateIsPastLastBookable = (date: DateTime): boolean => {
    return lastBookableDate.valueOf() < date.endOf('month').valueOf();
  };

  const isInFirstBookableMonth = (date: DateTime): boolean => {
    return firstBookableDate.startOf('month').equals(date.startOf('month'));
  };

  return (
    <>
      {!isDateConfirmed && (
        <MuiPickersUtilsProvider
          utils={CustomLuxonUtils}
          locale={`${language}-${country.toLocaleUpperCase()}`}
        >
          <DatePicker
            initialFocusedDate={initialFocusedDate}
            orientation={isSmallScreen ? 'portrait' : 'landscape'}
            value={selected}
            lang={language}
            variant='static'
            onMonthChange={(month: DateType) => {
              const date = DateTime.fromMillis(month.valueOf());
              // Set the visible date in the local component
              setVisibleMonth(date.toISODate());
              if (
                dateIsPastLastBookable(date) ||
                isInFirstBookableMonth(date)
              ) {
                // If we're reaching the penultimate month (or later) with bookable dates,
                // we'd like to go fetch more - if there are any.
                // Tell the parent component that the date has changed
                setMonthVisibleToUser(date.toISODate());
              }
            }}
            onChange={(date: any) => {
              if (!initialized.current) {
                initialized.current = true;
                return;
              }

              if (date) {
                const dateWithZone = date.setZone(visitSiteTimezone);
                setSelected(dateWithZone);

                if (!isOutsideWindow(dateWithZone)) {
                  setIsDateConfirmed(true);
                }

                if (setSelectedStartTime) {
                  setSelectedStartTime(null);
                }
              }
            }}
            shouldDisableDate={(date: any) => {
              if (!date) return true;
              return shouldDisable(date);
            }}
            disablePast={!visibleDate || isInFirstBookableMonth(visibleDate)}
            maxDate={lastBookableDate.toFormat(dateFormat)}
            leftArrowButtonProps={{ name: 'calendar-prev-button' }}
            rightArrowButtonProps={{ name: 'calendar-next-button' }}
            renderDay={(day: any, selectedDate, isInCurrentMonth) => {
              return (
                <div
                  className={`${
                    isOutsideWindow(day) &&
                    !shouldDisable(day!) &&
                    isInCurrentMonth
                      ? 'day-outside-window'
                      : ''
                  }`}
                >
                  <Day
                    hidden={!isInCurrentMonth}
                    selected={selected && day.equals(selectedDate)}
                    disabled={!isInCurrentMonth || shouldDisable(day!)}
                  >
                    {day!.toFormat('d')}
                  </Day>
                </div>
              );
            }}
          />

          <div className='booker-confirm'>
            {selected && isOutsideWindow(selected) && (
              <div className='booker-confirm-outside-window'>
                <div className='outside-window-title'>
                  {t('booker.outsideWindow')}
                </div>

                {t('booker.confirmOutsideWindow')}
              </div>
            )}
            <div className='booker-confirm-actions'>
              {selected && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => selected && setIsDateConfirmed(true)}
                >
                  {t('common.confirm')}
                </Button>
              )}
            </div>
          </div>
        </MuiPickersUtilsProvider>
      )}

      {selected && isDateConfirmed && selectedSlots && (
        <div className='scheduler'>
          <div className='scheduler-day'>
            <div className='scheduler-day-bottom'>
              <div className='scheduler-slots-row'>
                <div
                  className='scheduler-slot back-to-calendar confirm-slot'
                  onClick={() => {
                    initialized.current = false;
                    setSelected(null);
                    setIsDateConfirmed(false);
                    if (setSelectedStartTime) {
                      setSelectedStartTime(null);
                    }
                  }}
                >
                  <ArrowBackIcon />
                </div>
                <div className='scheduler-slot date'>
                  {selected.toFormat(localeFormats.luxon.schedulerSlot, {
                    locale,
                  })}
                </div>
              </div>
              {selectedSlots.map((slot, ix) => {
                const slotText = getSlotTextForTz(slot, visitSiteTimezone);
                if (
                  slot &&
                  selectedStartTime &&
                  setSelectedStartTime &&
                  slot.start === selectedStartTime
                ) {
                  return (
                    <div className='scheduler-slots-row' key={ix}>
                      <div
                        className='scheduler-slot'
                        onClick={
                          LoadingAndSuccess.includes(status)
                            ? undefined
                            : () => setSelectedStartTime(null)
                        }
                      >
                        {slotText}
                      </div>
                      <div
                        className='scheduler-slot confirm-slot'
                        onClick={
                          LoadingAndSuccess.includes(status)
                            ? undefined
                            : () => onSelectSlot(slot)
                        }
                      >
                        {statusToButtonText(status, t)}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className='scheduler-slots-row' key={ix}>
                      <div
                        className='scheduler-slot'
                        onClick={
                          slot
                            ? () =>
                                setSelectedStartTime
                                  ? setSelectedStartTime(slot.start)
                                  : onSelectSlot(slot)
                            : undefined
                        }
                      >
                        {slotText}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapSizesToProps = ({ width }) => ({
  isSmallScreen: width < 500,
});

export default withSizes<SmallScreen, CurebaseSchedulerProps>(mapSizesToProps)(
  CurebaseScheduler
);
