import produce from 'immer';
import {
  INIT_TRIAL,
  ADD_DOCUMENT,
  EDIT_DOCUMENT,
  REMOVE_DOCUMENT,
  REMOVE_DOCUMENTS,
  Action,
} from '../../actions/TrialBuilder/types';

export default produce((state: any, action: Action) => {
  switch (action.type) {
    case INIT_TRIAL:
      return action.payload.documentConfigurationIds ?? [];
    case ADD_DOCUMENT:
      return void state.push(action.payload.newId);
    case REMOVE_DOCUMENT: {
      const ix = state.findIndex(x => x === action.payload.oldId);
      return void state.splice(ix, 1);
    }
    case REMOVE_DOCUMENTS: {
      return state.filter(i => !action.payload.toRemove.includes(i));
    }
    case EDIT_DOCUMENT: {
      const ix = state.findIndex(x => x === action.payload.oldId);
      state[ix] = action.payload.newId;
      break;
    }
    default:
      return state;
  }
}, []);
