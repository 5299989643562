// import { TUserState } from '../reducers/user'; (does not exist)
import { TPatientModeState } from '../reducers/patientMode';
import { TResourcesState } from '../reducers/resources';
import { TNavigationState } from '../reducers/navigation';

export type ReduxStoreMain = {
  user: any;
  patientMode: TPatientModeState;
  resources: TResourcesState;
  navigation: TNavigationState;
};

export const getUserSelector = (store: ReduxStoreMain): any => store.user;

export const getUiSelector = (store: ReduxStoreMain): any => (store as any).ui;

export const getIsMenuOpen = (store: ReduxStoreMain): any =>
  (store as any).ui.menuDrawer.isVisible;

export const getIsCurebaseMobileApp = (store: ReduxStoreMain): any =>
  (store as any).ui.isCurebaseMobileApp;

export const getResourcesSelector = (store: ReduxStoreMain): any =>
  store.resources;

export const getPatientModeSelector = (store: ReduxStoreMain): any =>
  store.patientMode;
