import SafeDialog, { SafeDialogProps } from './SafeDialog';

export interface LightDialogProps extends SafeDialogProps {
  children?: React.ReactElement<any>;
  hideCloseButton?: boolean;
}

export default function LightDialog(props: LightDialogProps) {
  return (
    <SafeDialog {...props}>
      <div className='light-dialog'>
        {props.children}
        {!props.hideCloseButton && (
          <div
            className='close-dialog-icon-container'
            onClick={e => props.onClose && props.onClose(e, 'backdropClick')}
          >
            <img
              className='close-dialog-icon'
              src='/icons/closeDialog.svg'
              alt='X'
            />
          </div>
        )}
      </div>
    </SafeDialog>
  );
}
