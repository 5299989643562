import { getDispatch } from '../../store';
import { Action } from '../actions';

export function clearTelehealthDataInStore(msg?: string): void {
  const action: Action = { type: 'CLEAR_TELEHEALTH' };
  getDispatch()(action);
}

export function signatureFooterPortalMount(status: boolean) {
  const action: Action = { type: 'FOOTER_PORTAL_STATUS', status };
  getDispatch()(action);
}
