import { useRef, useState, useEffect } from 'react';
import SignaturePad from 'signature_pad';
import { useForceRerender } from 'src/hooks/useForceRerender';
import {
  SignatureSubElement,
  SubElementProps,
} from '@curebase/core/lib/dynamicform/types';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import { Button } from '@material-ui/core';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

export function dataURItoFile(dataURI, filename: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new File([ia], filename, { type: mimeString });
}

interface SignatureInputProps {
  screenWidth: number;
  data: any;
  pageIndex: number;
  subEle: SignatureSubElement;
  onChange: (subEle: SubElementProps, value: any, pageIndex: number) => void;
}
function SignatureInput(props: SignatureInputProps) {
  const { screenWidth, onChange, subEle, pageIndex, data } = props;
  const { t } = useOverridableTranslation();
  const rerender = useForceRerender();

  const filename: string | object | undefined = data[subEle.key];
  const [signed, setSigned] = useState(false);
  const width = Math.min(screenWidth, 800);
  const height = 300;

  const myCanvas = useRef<HTMLCanvasElement | null>(null);
  const signaturePad = useRef<SignaturePad | null>(null);

  if (myCanvas.current && !signaturePad.current) {
    signaturePad.current = new SignaturePad(myCanvas.current, {
      onEnd: () => {
        const file = dataURItoFile(
          signaturePad.current!.toDataURL(),
          'signature.png'
        );
        onChange(subEle, file, pageIndex);
        setSigned(true);
      },
    });
  }

  const clear = () => {
    signaturePad.current!.clear();
    onChange(subEle, null, pageIndex);
    setSigned(false);
  };

  //force a rerender after 0.3 seconds, to create the signature pad after the canvas ref has been set
  const [forcedRerenderHappened, forceRerender] = useState(false);
  useEffect(() => {
    if (!forcedRerenderHappened) {
      const timeout = setTimeout(() => forceRerender(true), 300);
      return () => clearTimeout(timeout);
    }
  }, [forcedRerenderHappened]);

  //load in the signature image already saved and draw it on the canvas
  useEffect(() => {
    function loadImageIntoCanvas(url: string) {
      const img = new Image();
      img.src = url;
      img.onload = function () {
        if (signaturePad.current && myCanvas.current) {
          const ctx = myCanvas.current.getContext('2d');
          ctx!.drawImage(img, 0, 0);
          rerender();
        }
      };
    }

    if (forcedRerenderHappened && filename && typeof filename === 'string') {
      const uri = secureImageUriFromFileName(filename);
      loadImageIntoCanvas(uri.replace('download', 'view'));
    }
  }, [filename, rerender, forcedRerenderHappened]);

  return (
    <div className='signature-container'>
      <div className='signature-input-container'>
        <div className='signature-input-open-portion' />
        <div className='signature-input-signing-portion'>
          <span>{t('dynamicForm.signature.signHereInput')}</span>
          <Button
            disabled={!signed}
            color={'primary'}
            variant={'text'}
            size={'small'}
            onClick={() => clear()}
          >
            {t('dynamicForm.signature.clearBtn')}
          </Button>
        </div>
      </div>

      <div className='signature-canvas-container'>
        <canvas ref={myCanvas} width={width} height={height} />
      </div>
    </div>
  );
}

export { SignatureInput };
