import moment from 'moment-timezone';
import { DateTime } from 'luxon';

const MAX_AGE_YEARS = 117;
const MAX_FUTURE_YEARS = 1;
const DATE_FORMAT = 'YYYY-MM-DD';

export function LuxonFromAnything(d: number | string | Date): DateTime {
  if (typeof d === 'string') {
    // d is unix time encoded as a string
    if (d.length === String(parseInt(d)).length) {
      return DateTime.fromMillis(parseInt(d));
    }
    return DateTime.fromISO(d);
  } else if (typeof d === 'number') {
    return DateTime.fromMillis(d);
  } else if (typeof d === 'object' && d instanceof Date) {
    return DateTime.fromJSDate(d);
  }

  throw new Error(
    `[LuxonFromAnything] - unhandled format for date - ${d} - type: ${typeof d}`
  );
}

const isValidFormat = (date: string) => {
  return moment(date, DATE_FORMAT).isValid();
};

export function isValidDate(
  date: string,
  allowAnyFutureDate?: boolean
): boolean {
  if (!date || !isValidFormat(date)) return false;

  return allowAnyFutureDate
    ? moment(date).isAfter(minValidDate)
    : moment(date).isBetween(minValidDate, maxValidDate, undefined, '[]');
}

export const minValidDate = moment(
  new Date().setFullYear(new Date().getFullYear() - MAX_AGE_YEARS)
).valueOf();

export const maxValidDate = moment(
  new Date().setFullYear(new Date().getFullYear() + MAX_FUTURE_YEARS)
).valueOf();

export function displayInterval(visitStartDay: number) {
  let interval;
  let numIntervals;

  if (visitStartDay === 0) {
    interval = 'Today';
    numIntervals = '';
  } else if (Number.isInteger(visitStartDay / 7)) {
    interval = 'Week';
    numIntervals = visitStartDay / 7;
  } else if (Number.isInteger(visitStartDay / 30)) {
    interval = 'Month';
    numIntervals = visitStartDay / 30;
  } else {
    interval = 'Day';
    numIntervals = visitStartDay;
  }

  return { interval, numIntervals };
}

export function getBusinessDaysBetween(startDate: Date, endDate: Date) {
  let numWorkDays = 0;
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    // Skips Sunday and Saturday
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      numWorkDays++;
    }
    const date = new Date(currentDate);
    date.setDate(date.getDate() + 1);
    currentDate = date;
  }
  return numWorkDays;
}

export function formatNotesTime(date: Date | string): string {
  return DateTime.fromISO(date.toString()).toFormat(`h:mma 'on' MMM d',' yyyy`);
}

export function addBusinessDays(date: DateTime, numberOfBizDays: number) {
  //set to next weekday
  switch (date.weekday) {
    case 6:
      date = date.plus({ days: 2 });
      break;
    case 7:
      date = date.plus({ days: 1 });
      break;
    default:
      break;
  }

  if (numberOfBizDays === 0) {
    return date;
  }

  const numberOfFullWeeksAdded = Math.floor(numberOfBizDays / 5);
  const daysOffset = numberOfBizDays % 5;

  return date.plus({ weeks: numberOfFullWeeksAdded, days: daysOffset });
}
