import {
  AddProviderToClinicRequest,
  AddProviderToClinicResponse,
} from '@curebase/core/decoders/providers';
import { jsonPost } from '../lib/fetchHelpers';

export async function addProviderToClinic(
  clinicId: string,
  email: string,
  isAdmin?: boolean
): Promise<AddProviderToClinicResponse> {
  const requestBody: AddProviderToClinicRequest = { clinicId, email, isAdmin };
  const response: AddProviderToClinicResponse = await jsonPost(
    '/provider/addtoclinic',
    requestBody
  );
  return response;
}
