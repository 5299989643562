import { useStyles } from '../Telemed/AudioVideoControlBar';
import React, { useEffect, useState } from 'react';
import { RolePermissions } from 'src/types';
import { useViewContainerDimensions } from 'src/hooks/misc';
import { throttle } from 'lodash';
import { currentUserHasPermission } from 'src/lib/auth';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Loading from '../Loading';

export const ImageElement = (props: {
  imageURI: string;
  onDelete?: () => void;
  permissionToDelete?: RolePermissions;
}) => {
  const { onDelete, permissionToDelete } = props;
  const style = useStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentURI, setCurrentURI] = useState(props.imageURI);
  const [isZoomed, setIsZoomed] = useState(false);
  const viewContainerDimensions = useViewContainerDimensions();
  const zoomedImageRef = React.useRef<any>(null);

  const [zoomedImageDimensions, setZoomedImageDimensions] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  const onResize = throttle(() => {
    if (zoomedImageRef?.current) {
      setZoomedImageDimensions(
        getTargetDimensions(
          zoomedImageRef.current!.naturalWidth,
          zoomedImageRef.current!.naturalHeight
        )
      );
    }
  }, 100);

  useEffect(() => {
    if (props.imageURI !== currentURI && isDeleting) {
      setCurrentURI(props.imageURI);
      setIsDeleting(false);
    }

    //this doesnt work yet, not sure why.
    window.addEventListener('resize', () => {
      onResize.cancel();
      onResize();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imageURI, zoomedImageRef?.current]);

  function scaleToBounds(ow: number, oh: number, mw: number, mh: number) {
    let scale = Math.min(mw / ow, mh / oh);
    if (scale > 1) scale = 1;
    return {
      width: ow * scale,
      height: oh * scale,
    };
  }

  function getTargetDimensions(iw: number, ih: number, padding = 0) {
    const target = scaleToBounds(
      iw,
      ih,
      viewContainerDimensions!.width - padding,
      viewContainerDimensions!.height - padding
    );
    const left = viewContainerDimensions!.width / 2 - target.width / 2;
    const top = viewContainerDimensions!.height / 2 - target.height / 2;
    return {
      x: left,
      y: top,
      w: target.width,
      h: target.height,
    };
  }

  return (
    <div className='img-holder-file-upload'>
      <div className={`delete-button-row ${isDeleting ? 'deleting' : ''}`}>
        {!!onDelete &&
          (!permissionToDelete ||
            (currentUserHasPermission(permissionToDelete) && (
              <>
                {!isDeleting ? (
                  <Button
                    className={`${style.telehealthUploadDelete} delete-button`}
                    onClick={() => {
                      onDelete();
                      setIsDeleting(true);
                    }}
                  >
                    <CancelIcon
                      style={{ backgroundColor: 'white', borderRadius: '100%' }}
                    />
                  </Button>
                ) : (
                  <div className={`loading-container`}>
                    <Loading />
                  </div>
                )}
              </>
            )))}
      </div>

      <img
        alt=''
        style={{ cursor: 'zoom-in' }}
        className='preview-element'
        src={props.imageURI}
        onClick={() => setIsZoomed(true)}
      ></img>

      {isZoomed && (
        <div
          className='enlarged-image-container'
          onClick={() => setIsZoomed(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 90,
            cursor: 'zoom-out',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255,255,255,0.8)',
            }}
          >
            <img
              src={props.imageURI}
              ref={zoomedImageRef}
              key={1}
              alt=''
              onLoad={() => {
                setZoomedImageDimensions(
                  getTargetDimensions(
                    zoomedImageRef.current!.naturalWidth,
                    zoomedImageRef.current!.naturalHeight
                  )
                );
              }}
              style={{
                pointerEvents: 'none',
                zIndex: 100,
                position: 'absolute',
                left: zoomedImageDimensions.x,
                top: zoomedImageDimensions.y,
                width: zoomedImageDimensions.w,
                height: zoomedImageDimensions.h,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ImageElement;
