import React, { useEffect, useState, useContext } from 'react';
import chimeContext from '../../context/getChimeContext';
import VideoElement from './VideoElement';
import { VisibiltyStatus } from './AudioVideo';
import useRoster from '../../hooks/useChimeRoster';
import { RECORDER_NAME } from './MainTelemedRoom';

const RemoteVideos = (props: {
  setAudioVideoElementsArray: (
    index: number,
    element: HTMLVideoElement
  ) => void;
}) => {
  const MAX_REMOTE_VIDEOS = 2;
  const chime = useContext(chimeContext);
  const [visibleIndices, setVisibleIndices] = useState<{
    [index: string]: { boundAttendeeId: string };
  }>({});
  const currentRoster = useRoster();
  const [indexMap, setIndexMap] = useState<{ [index: number]: number | null }>(
    {}
  );

  const videoElements: HTMLVideoElement[] = [];

  //looks complicated, but setup for expansion to multiple remote videos
  const acquireVideoElement = tileId => {
    // Return the same video element if already bound.

    for (let i = 0; i < MAX_REMOTE_VIDEOS; i += 1) {
      if (indexMap[i] === tileId) {
        return i;
      }
    }

    // Return the next available video element.
    for (let i = 0; i < MAX_REMOTE_VIDEOS; i += 1) {
      if (!indexMap[i]) {
        indexMap[i] = tileId;
        setIndexMap({
          ...indexMap,
          [i]: tileId,
        });
        return i;
      }
    }
    throw new Error(
      `no video element is available for: ${tileId} - ${JSON.stringify(
        indexMap
      )}`
    );
  };

  useEffect(() => {
    const meetingSession = chime.meetingSession;

    const observer = {
      // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID and other attendee's tile.
        if (
          !tileState.boundAttendeeId ||
          tileState.localTile ||
          !tileState.tileId
        ) {
          return;
        }

        const index = acquireVideoElement(tileState.tileId);

        setVisibleIndices(prevState => ({
          ...prevState,
          [index!]: {
            boundAttendeeId: tileState.boundAttendeeId,
          },
        }));

        meetingSession?.audioVideo.bindVideoElement(
          tileState.tileId,
          videoElements[index]
        );
      },
      videoTileWasRemoved: tileId => {
        console.warn(`tileID removed: ${tileId}`);

        for (let i = 0; i < 2; i += 1) {
          if (indexMap[i] === tileId) {
            delete indexMap[i];
            setIndexMap(prevState => ({
              ...prevState,
              [i]: null,
            }));
            setVisibleIndices(prevState => ({
              ...prevState,
              [i]: null,
            }));
            return;
          }
        }
      },
      remoteVideoSourcesDidChange: videoSources => {
        videoSources.forEach(videoSource => {
          const { attendee } = videoSource;
          console.log(
            `An attendee (${attendee.attendeeId} ${attendee.externalUserId}) is sending video`
          );
        });
      },
    };
    meetingSession?.audioVideo.addObserver(observer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      meetingSession?.audioVideo.removeObserver(observer);
    };
  }, []);

  //useCallback can't be used in Map
  let callbacks = Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map(
    (key, index) => {
      return (element: HTMLVideoElement | null) => {
        if (element) {
          videoElements[index] = element;
          props.setAudioVideoElementsArray(index, element);
        }
      };
    }
  );

  const currentRemoteAttendees = Object.keys(currentRoster).filter(
    e =>
      currentRoster[e].name !== RECORDER_NAME &&
      e !== chime.meetingSession?.configuration.credentials?.attendeeId
  );
  const numAssignedTiles = Object.values(visibleIndices).filter(e => !!e)
    .length;
  return (
    <>
      {Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map((e, idx) => {
        const visibility = visibleIndices[idx];

        return (
          <VideoElement
            key={idx}
            videoElementRef={callbacks[idx]}
            isVisible={
              visibility
                ? VisibiltyStatus.ShowVideo
                : VisibiltyStatus.CollapseBlock
            }
          />
        );
      })}

      {currentRemoteAttendees.length > numAssignedTiles &&
        Array.from(
          Array(currentRemoteAttendees.length - numAssignedTiles).keys()
        ).map((e, idx) => {
          return (
            <VideoElement key={idx} isVisible={VisibiltyStatus.ShowImage} />
          );
        })}
    </>
  );
};

export default RemoteVideos;
