import { StudyPlanVisit, VisitStatus } from '@curebase/core/types';
import { isDataCaptureSubmitted } from '@curebase/modules/dataCapture/services';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { userIsParticipant } from '../../lib/users';
import { useDataCaptureReviewQuery } from '../../types';
import Bullets from '../basic/Bullets';
import ListItem from '../basic/ListItem';
import StandardButton from '../basic/StandardButton';
import Subheader from '../basic/Subheader';
import Loading from '../Loading';
import { viewParticipantBaseRoute } from '../navigation/clinic';
import Spacer from '../Spacer';
import DataCaptureResults from './DataCaptureResults';
import { DataCaptureVisitHeader } from './DataCaptureVisitHeader';

type Props = {
  match: any;
  history: any;
};

const DataCaptureEvaluation = (props: {
  evaluation: any;
  studyPlanVisit: StudyPlanVisit;
  status: VisitStatus;
  history: any;
}) => {
  const { evaluation, studyPlanVisit, status, history } = props;

  const { t } = useTranslation('translations');

  const type = () => {
    if (!evaluation) {
      return 'PENDING';
    }

    return evaluation.didPass ? 'COMPLETED' : 'FAILED';
  };

  const title = () => {
    if (!evaluation) {
      return t('dataCaptureReviewView.pendingTitle');
    }

    return evaluation.didPass
      ? t('dataCaptureReviewView.evaluationSuccess')
      : t('dataCaptureReviewView.evaluationFailed');
  };

  const text = () => {
    if (!evaluation) {
      if (studyPlanVisit.isRemote) {
        return t('dataCaptureReviewView.waitingParticipantText');
      }

      return t('dataCaptureReviewView.waitingStaffText');
    }

    return t('dataCaptureReviewView.reviewAnswerText');
  };

  return (
    <ListItem
      // @ts-ignore
      status={{ type: type() }}
      middle={{
        title: title(),
        text: text(),
        buttons:
          studyPlanVisit.canRetry &&
          !evaluation?.didPass &&
          isDataCaptureSubmitted({ status })
            ? [
                <StandardButton
                  text={t('dataCaptureReviewView.resubmitBtn')}
                  onClick={() => {
                    history.push(
                      history.location.pathname.replace('review', 'capture')
                    );
                  }}
                />,
              ]
            : null,
      }}
    >
      {evaluation && !evaluation.didPass && (
        <>
          <Spacer height={20} />
          <Bullets
            items={_.filter(
              (evaluation.evaluationNotes || []).map(en => en.note),
              ele => ele
            )}
          />
        </>
      )}
    </ListItem>
  );
};

const DataCaptureReviewView = (props: Props) => {
  const { t } = useTranslation('translations');
  const { history } = props;

  const { data, loading } = useDataCaptureReviewQuery({
    variables: {
      trialOptionId: parseInt(props.match.params.trialOptionId),
      studyPlanVisitSlug: props.match.params.studyPlanVisitSlug,
    },
  });

  if (!data || loading) return <Loading />;

  const trialOption = data.getTrialOption;
  const { visit, patient } = trialOption;
  if (!visit) return null;

  const { evaluation, studyPlanVisit, status }: any = visit;

  const { trialOptionId, patientId } = props.match?.params;

  let backLink = `${viewParticipantBaseRoute}/${trialOptionId}`;
  if (userIsParticipant())
    backLink = `/u/patient/${patientId}/trial/${trialOptionId}`;

  return (
    <div className='data-capture-review'>
      <DataCaptureVisitHeader
        displayName={trialOption?.patient?.user.displayName!!}
        subjectIdentifier={trialOption?.subjectIdentifier!!}
        studyPlanVisitSummary={visit?.studyPlanVisit?.summary!!}
        history={props.history}
        status={trialOption.status}
        trialName={trialOption.trial.name}
        trialDeactivated={trialOption.trial.deactivated}
        onBack={() => props.history.push(backLink)}
        trialOption={trialOption}
      />
      <Subheader text={t('common.results')} />
      <div className='li-multi-container inset'>
        <DataCaptureEvaluation
          evaluation={evaluation}
          studyPlanVisit={studyPlanVisit}
          status={status}
          history={history}
        />
      </div>

      <Subheader text={t('dataCaptureReviewView.subheaderText')} />
      <div className='li-multi-container inset'>
        <ListItem>
          <DataCaptureResults
            studyPlanVisitSlug={studyPlanVisit.slug}
            trialOptionId={trialOption.id}
            timezone={patient?.user?.safeTimezone}
          />
        </ListItem>
      </div>
    </div>
  );
};

export default DataCaptureReviewView;
