import { IS_PRODUCTION, IS_SANDBOX } from './env';

type GitCommitInfo = {
  sha: string;
  version: string;
};

export function getDatadogVersion(info: GitCommitInfo): string {
  if (IS_PRODUCTION || IS_SANDBOX) {
    return info.version;
  }

  return info.sha?.substring(0, 6);
}
