import * as React from 'react';
import Button from '@material-ui/core/Button';
import Subheader from '../basic/Subheader';
import ListItem from '../basic/ListItem';
import Dialog from '../basic/SafeDialog';
import StandardButton from '../basic/StandardButton';
import TrialPickerInDialog from '../basic/pickers/TrialPickerInDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { useTranslation } from 'react-i18next';

type Props = {
  callbacks: {
    addTrialInstance: (trialId: number, trialObject: Object) => any;
    deactivateTrialInstance: (trialIdentifier: string) => any;
  };
  instances: {
    deactivated: boolean;
    trialIdentifier: string;
    trial: {
      id: number;
      name: string;
      trialIdentifier: string;
    } | null;
  }[];
  refetch: () => Promise<any>;
};

const TriaInstanceManagement = (props: Props) => {
  const { t } = useTranslation('translations');
  const {
    instances: unsortedInstances,
    callbacks: { addTrialInstance, deactivateTrialInstance },
  } = props;

  const [showTrialPicker, setShowTrialPicker] = React.useState(false);
  const [selectedTrial, setSelectedTrial] = React.useState<any>(null);
  const instances = [...unsortedInstances].sort((a, b) =>
    (a.trial?.name ?? '').localeCompare(b.trial?.name ?? '')
  );

  return (
    <>
      <Dialog open={showTrialPicker} onClose={() => setShowTrialPicker(false)}>
        <TrialPickerInDialog
          title={t('triaInstanceManagement.trialPickerDialog.title')}
          // @ts-ignore
          onPicked={(trialId: number, trialInfo: Object) => {
            setShowTrialPicker(false);
            setSelectedTrial(trialInfo);
          }}
          onClose={() => setShowTrialPicker(false)}
        />
      </Dialog>

      <Dialog open={!!selectedTrial} onClose={() => setSelectedTrial(null)}>
        <ConfirmationInDialog
          title={t(
            'triaInstanceManagement.trialPickerDialog.sureQuestionTitle'
          )}
          message={t(
            'triaInstanceManagement.trialPickerDialog.sureQuestionMsg'
          )}
          onClose={() => setSelectedTrial(null)}
          onConfirm={() => {
            addTrialInstance(0, selectedTrial);
            setSelectedTrial(null);
          }}
        />
      </Dialog>

      <Subheader
        text={t('triaInstanceManagement.subheaderText')}
        buttons={[
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowTrialPicker(true)}
          >
            {t('triaInstanceManagement.addTrialBtn')}
          </Button>,
        ]}
      />

      <div className='li-multi-container inset'>
        {instances.map((instance, i) => (
          <ListItem
            key={i}
            // @ts-ignore
            middle={{
              title: instance.trial?.name ?? instance.trialIdentifier,
              buttons: [
                <StandardButton
                  key={i}
                  text={
                    instance.deactivated
                      ? t('triaInstanceManagement.activateText')
                      : t('triaInstanceManagement.deactivateText')
                  }
                  onClick={() =>
                    deactivateTrialInstance(instance.trialIdentifier)
                  }
                  // @ts-ignore
                  styleName={'li-button-right'}
                  color={instance.deactivated ? 'primary' : 'secondary'}
                />,
              ],
            }}
          />
        ))}
        {!(instances && instances.length > 0) && (
          <ListItem
            // @ts-ignore
            middle={{
              title: t('triaInstanceManagement.listTitle'),
              text: t('triaInstanceManagement.listText'),
            }}
          />
        )}
      </div>
    </>
  );
};

export default TriaInstanceManagement;
