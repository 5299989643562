import type {
  DataField,
  CaseReport,
  StudyPlanVisit,
} from '@curebase/core/types';

import type {
  DataFieldMap,
  CaseReportMap,
  StudyPlanVisitMap,
} from '../../reducers/TrialBuilder';

export const INIT_TRIAL = 'INIT_TRIAL';
export const EDIT_TRIAL = 'EDIT_TRIAL';
export const EDIT_SPONSOR = 'EDIT_SPONSOR';
export const ADD_DATAFIELD = 'ADD_DATAFIELD';
export const EDIT_DATAFIELD = 'EDIT_DATAFIELD';
export const DELETE_DATAFIELD = 'DELETE_DATAFIELD';
export const ADD_CASEREPORT = 'ADD_CASEREPORT';
export const EDIT_CASEREPORT = 'EDIT_CASEREPORT';
export const ADD_STUDYPLANVISIT = 'ADD_STUDYPLANVISIT';
export const EDIT_STUDYPLANVISIT = 'EDIT_STUDYPLANVISIT';
export const DELETE_STUDYPLANVISIT = 'DELETE_STUDYPLANVISIT';
export const TOGGLE_CASEREPORT = 'TOGGLE_CASEREPORT';
export const DELETE_ALL_INSTANCES_OF_CRF = 'DELETE_ALL_INSTANCES_OF_CRF';
export const TOGGLE_TRIAL_DIALOG = 'TOGGLE_TRIAL_DIALOG';
export const TOGGLE_STUDYPLANVISIT_DIALOG = 'TOGGLE_STUDYPLANVISIT_DIALOG';
export const TOGGLE_CASEREPORT_DIALOG = 'TOGGLE_CASEREPORT_DIALOG';
export const TOGGLE_DATAFIELD_DIALOG = 'TOGGLE_DATAFIELD_DIALOG';
export const TOGGLE_CONFIRMATION_DIALOG = 'TOGGLE_CONFIRMATION_DIALOG';
export const OPEN_CONFIRM_DIALOG = 'OPEN_CONFIRM_DIALOG';
export const OPEN_DEPLOY_DIALOG = 'OPEN_DEPLOY_DIALOG';
export const OPEN_REVISION_MISMATCH_DIALOG = 'OPEN_REVISION_MISMATCH_DIALOG';
export const CLOSE_CONFIRM_DIALOG = 'CLOSE_CONFIRM_DIALOG';
export const CLOSE_DEPLOY_DIALOG = 'CLOSE_DEPLOY_DIALOG';
export const CLOSE_REVISION_MISMATCH_DIALOG = 'CLOSE_REVISION_MISMATCH_DIALOG';
export const APPLY_CHANGES_FROM_DB = 'APPLY_CHANGES_FROM_DB';
export const EDIT_MACHINE = 'EDIT_MACHINE';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENTS = 'REMOVE_DOCUMENTS';
export const CUSTOM_ACTION_REFS = 'CUSTOM_ACTION_REFS';

export type CustomActionRefsAction = {
  type: typeof CUSTOM_ACTION_REFS;
  payload: any;
};

//TODO - break into separate types for each action
export type DocumentAction = {
  type:
    | typeof ADD_DOCUMENT
    | typeof EDIT_DOCUMENT
    | typeof REMOVE_DOCUMENT
    | typeof REMOVE_DOCUMENTS;
  payload: any;
};

export type OpenDeployDialogAction = {
  type: 'OPEN_DEPLOY_DIALOG';
};

export type CloseDeployDialog = {
  type: typeof CLOSE_DEPLOY_DIALOG;
};

export type OpenRevisionMismatchDialogAction = {
  type: 'OPEN_REVISION_MISMATCH_DIALOG';
};

export type CloseRevisionMismatchDialog = {
  type: 'CLOSE_REVISION_MISMATCH_DIALOG';
};

export type ToggleConfirmationDialog = {
  type: 'TOGGLE_CONFIRMATION_DIALOG';
  payload?: {
    onSubmit: () => void;
    title: string;
    body: string;
  };
};

export type EditDocumentConfigurationsAction = {
  type: 'EDIT_DOCUMENT_SIGNING_CONFIGURATIONS';
  payload: Record<string, any>;
};

export type EditMachineAction = {
  type: 'EDIT_MACHINE';
  payload: Record<string, any>;
};

export type EditSponsorAction = {
  type: 'EDIT_SPONSOR';
  payload: Record<string, any>;
};

export type AddDataFieldAction = {
  type: 'ADD_DATAFIELD';
  payload: DataField;
};

export type EditDataFieldAction = {
  type: 'EDIT_DATAFIELD';
  payload: {
    slug: string;
    updates: Record<string, any>;
  };
};

export type DeleteDataFieldAction = {
  type: 'DELETE_DATAFIELD';
  payload: {
    slug: string;
    superId?: string;
  };
};

export type AddCaseReportAction = {
  type: 'ADD_CASEREPORT';
  payload: CaseReport;
};

export type EditCaseReportAction = {
  type: 'EDIT_CASEREPORT';
  payload: {
    slug: string;
    updates: Record<string, any>;
  };
};
export interface SummaryData {
  title: string;
  description: string;
  language: string;
}
export interface StudyPlanVisitSummaries
  extends Omit<StudyPlanVisit, 'summary'> {
  summary: {
    summaries: Array<SummaryData>;
    key?: string;
  };
}
export type AddStudyPlanVisitAction = {
  type: 'ADD_STUDYPLANVISIT';
  payload: StudyPlanVisitSummaries;
};

export type ToggleCaseReportAction = {
  type: 'TOGGLE_CASEREPORT';
  payload: {
    caseReportSlug: string;
    caseReportSimpleSlug: string;
    studyPlanVisitSlug: string;
  };
};

export type DeleteAllInstancesOfCrfAction = {
  type: typeof DELETE_ALL_INSTANCES_OF_CRF;
  payload: {
    caseReportSimpleSlug: string;
  };
};

export type ToggleTrialDialogAction = {
  type: 'TOGGLE_TRIAL_DIALOG';
};

export type ToggleStudyPlanVisitDialog = {
  type: 'TOGGLE_STUDYPLANVISIT_DIALOG';
  payload: {
    slug?: string;
  };
};

export type ToggleCaseReportDialog = {
  type: 'TOGGLE_CASEREPORT_DIALOG';
  payload: {
    slug?: string;
    studyPlanVisit?: {
      slug: string;
    };
  };
};

export type ToggleDataFieldDialogAction = {
  type: 'TOGGLE_DATAFIELD_DIALOG';
  payload: {
    slug?: string;
    caseReport?: {
      slug: string;
    };
  };
};

export type EditStudyPlanVisit = {
  type: 'EDIT_STUDYPLANVISIT';
  payload: {
    slug: string;
    updates: Record<string, any>;
  };
};

export type DeleteStudyPlanVisit = {
  type: 'DELETE_STUDYPLANVISIT';
  payload: {
    slug: string;
  };
};

export type OpenConfirmDialogAction = {
  type: 'OPEN_CONFIRM_DIALOG';
};

export type CloseConfirmDialogAction = {
  type: 'CLOSE_CONFIRM_DIALOG';
};

export type ApplyChangeFromDBAction = {
  type: 'APPLY_CHANGES_FROM_DB';
  payload: {
    studyPlanVisits?: StudyPlanVisitMap;
    caseReports?: CaseReportMap;
    dataFields?: DataFieldMap;
  };
};

export type InitTrialAction = {
  type: 'INIT_TRIAL';
  payload: Record<string, any>;
};

export type EditTrialAction = {
  type: 'EDIT_TRIAL';
  payload: {
    updates: Record<string, any>;
  };
};

export type Action =
  | CloseDeployDialog
  | CustomActionRefsAction
  | EditSponsorAction
  | InitTrialAction
  | EditTrialAction
  | OpenConfirmDialogAction
  | OpenDeployDialogAction
  | OpenRevisionMismatchDialogAction
  | CloseConfirmDialogAction
  | CloseRevisionMismatchDialog
  | ApplyChangeFromDBAction
  | AddDataFieldAction
  | EditDataFieldAction
  | DeleteDataFieldAction
  | AddCaseReportAction
  | EditCaseReportAction
  | ToggleTrialDialogAction
  | ToggleStudyPlanVisitDialog
  | DeleteStudyPlanVisit
  | EditStudyPlanVisit
  | DeleteAllInstancesOfCrfAction
  | ToggleCaseReportAction
  | ToggleCaseReportDialog
  | ToggleDataFieldDialogAction
  | ToggleConfirmationDialog
  | AddStudyPlanVisitAction
  | EditMachineAction
  | DocumentAction;
