import produce from 'immer';
import * as React from 'react';
import classnames from 'classnames';
import { Button, MenuItem, Radio, Select } from '@material-ui/core';
import { AutocompleteAdditionalSettings } from './DynamicForm';
import { useTranslation } from 'react-i18next';

export type listElemOptions = {
  text: string;
  placeholder?: string;
  required?: boolean;
  type: 'AUTOCOMPLETE' | 'TEXT' | 'DROPDOWN' | 'MULTISELECT' | 'DATE';
  options?: { text: string; value: string }[];
  additionalSettings?: AutocompleteAdditionalSettings;
}[];

export const ListElem = (props: {
  listData: any[];
  options: listElemOptions;
  readOnly?: boolean;
  style?: string;
  onClick: (updatedValue: any) => void;
}) => {
  const { t } = useTranslation('translations');
  const { listData, options, readOnly, style } = props;

  return (
    <>
      {listData.map((elem, row) => {
        return (
          <div
            className={classnames('dynamic-form-list-element', style)}
            key={row}
          >
            {options.map((option: any, column) => (
              <div
                key={`${row}:${column}`}
                className='dynamic-form-list-element-item'
              >
                {(option.type === 'TEXT' || !option.type) && (
                  <>
                    <span className='dynamic-form-list-element-item-text'>
                      {option.text}
                    </span>
                    <input
                      className='dynamic-form-list-element-item-input'
                      value={elem[column] || ''}
                      placeholder={option.placeholder}
                      disabled={readOnly || false}
                      onChange={e => {
                        const updatedValue = produce(listData, draft => {
                          draft[row][column] = e.target.value;
                        });
                        props.onClick(updatedValue);
                      }}
                    />
                  </>
                )}

                {option.type === 'DATE' && (
                  <>
                    <span className='dynamic-form-list-element-item-text'>
                      {option.text}
                    </span>
                    <input
                      disabled={readOnly || false}
                      type={'DATE'}
                      value={elem[column]}
                      className='dynamic-form-date'
                      onChange={async e => {
                        let v = e.target.value;
                        if (v) {
                          const updatedValue = produce(listData, draft => {
                            draft[row][column] = v;
                          });
                          props.onClick(updatedValue);
                        }
                      }}
                    />
                  </>
                )}

                {option.type === 'DROPDOWN' && (
                  <>
                    <span className='dynamic-form-list-element-item-text'>
                      {option.text}
                    </span>
                    <Select
                      fullWidth
                      value={elem[column] || ''}
                      onChange={e => {
                        const updatedValue = produce(listData, draft => {
                          draft[row][column] = e.target.value;
                        });
                        props.onClick(updatedValue);
                      }}
                      disabled={readOnly || false}
                    >
                      {option.options.map(({ text, value }, i) => (
                        <MenuItem
                          key={i}
                          value={value}
                          className='dropdown-item'
                        >
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}

                {option.type === 'MULTISELECT' && (
                  <>
                    {option.text}
                    {option.options.map((op, i) => (
                      <div
                        className='df-multiple-choice-option'
                        key={i}
                        onClick={() => {
                          const updatedValue = produce(listData, draft => {
                            draft[row][column] = op.value;
                          });
                          props.onClick(updatedValue);
                        }}
                      >
                        <Radio
                          disabled={readOnly || false}
                          color='primary'
                          checked={elem[column] === op.value}
                        />

                        <div className='dynamic-forms-multiselect-text'>
                          {op.text}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}

            <div className='dynamic-form-list-element dynamic-form-list-element-delete-button-container'>
              <Button
                color='primary'
                disabled={readOnly || false}
                onClick={e => {
                  const updatedValue = produce(
                    listData,
                    draft => void draft.splice(row, 1)
                  );
                  props.onClick(updatedValue);
                }}
              >
                {t('common.removeLabel')}
              </Button>
            </div>
          </div>
        );
      })}
      <div className='dynamic-form-list-element-add-button-container'>
        <Button
          color='primary'
          disabled={readOnly || false}
          onClick={e => {
            const updatedValue = produce(
              listData,
              draft => void draft.push([])
            );
            props.onClick(updatedValue);
          }}
        >
          {t('common.addLabel')}
        </Button>
      </div>
    </>
  );
};
