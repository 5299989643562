import { Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { mdToHTML } from '@curebase/core/lib/markdown';
import CustomStyleContext from '../../../context/customStyleContext';
import { createActionCardStyle } from '../../../lib/customStyle';
import { useTranslation } from 'react-i18next';
import ActionCardIcon from './ActionCardIcon';
import OptionalChip from './OptionalChip';
import ActionCardButton from './ActionCardButton';
import SkipTaskBody from './SkipTaskBody';
import Tag from '../Tag';

export type ButtonProps = {
  disabled?: boolean;
  isSecondary?: boolean;
  text: string;
  onClick: () => any;
  className?: string;
};

export type ActionDisplayProps = {
  title: string;
  description?: string;
  markdown?: string;
  iconSrc: string;
  isOverdue?: boolean | null | undefined;
  whenString?: string | null | undefined;
  buttons?: ButtonProps[] | null;
  extraButtons?: ButtonProps[] | null;
  compensationString?: string | null | undefined;
  showVerticalButtons?: boolean;
  optional?: boolean | null | undefined;
  onConfirmSkip?: () => void | undefined;
};

function ActionCard({
  title,
  description,
  iconSrc,
  isOverdue,
  whenString,
  buttons,
  showVerticalButtons,
  extraButtons,
  markdown,
  optional,
  onConfirmSkip,
}: ActionDisplayProps) {
  const { t } = useTranslation('translations');
  const context = useContext(CustomStyleContext);
  const [buttonsCollapsed, setButtonsCollapsed] = useState<boolean>(true);
  const classes = createActionCardStyle(context);
  const [showSkipBody, setShowSkipBody] = useState(false);
  let buttonsToShow: ButtonProps[] = [];
  if (buttonsCollapsed && buttons) buttonsToShow = [...buttons];
  if (!buttonsCollapsed && extraButtons && extraButtons.length > 0)
    buttonsToShow = extraButtons;

  function toggleSkipBody() {
    setShowSkipBody(!showSkipBody);
  }

  function selfOnConfirmSkip() {
    toggleSkipBody();
    onConfirmSkip && onConfirmSkip();
  }

  return (
    <div className='action-card-container'>
      <div className={`action-card`}>
        <ActionCardIcon iconSrc={iconSrc} />
        {!whenString && isOverdue && (
          <div className={'ac-tag'}>
            <Tag color={'red'}>{t('common.missed')}</Tag>
          </div>
        )}
        {whenString && (
          <div className={`ac-due-date-container ${classes.titles}`}>
            {whenString}
          </div>
        )}
        <div className='ac-inner-container'>
          <Typography
            variant='h3'
            className={`ac-inner-container-top ${
              isOverdue ? 'line-through' : ''
            }`}
          >
            {title}
            {optional && <OptionalChip />}
          </Typography>
          {markdown ? (
            <div
              className='ac-inner-container-bottom'
              dangerouslySetInnerHTML={{ __html: mdToHTML(markdown) }}
            />
          ) : (
            <div className='ac-inner-container-bottom'>{description}</div>
          )}
          <div className='ac-gutter'>
            <div
              className='ac-button-container'
              style={showVerticalButtons ? { flexDirection: 'column' } : {}}
            >
              {buttonsToShow && buttonsToShow.length > 0 && (
                <>
                  {buttonsToShow.map((button, i) => (
                    <ActionCardButton
                      className={button.className}
                      label={button.text}
                      onClick={button.disabled ? undefined : button.onClick}
                      showVerticalStyle={showVerticalButtons}
                      disabled={button.disabled}
                      variant={button.isSecondary ? 'secondary' : 'primary'}
                      key={`acb-${i}`}
                    />
                  ))}
                  {optional && (
                    <ActionCardButton
                      showVerticalStyle={showVerticalButtons}
                      onClick={toggleSkipBody}
                      label={t('actionCard.skipTask', 'Skip task')}
                      variant='secondary'
                    />
                  )}
                </>
              )}
              {extraButtons && (
                <div
                  className='ac-more-actions'
                  onClick={() => {
                    setButtonsCollapsed(prev => !prev);
                  }}
                >
                  {buttonsCollapsed
                    ? t('actionCard.showMoreLabel')
                    : t('actionCard.showLessLabel')}
                </div>
              )}

              {optional && (
                <SkipTaskBody
                  show={showSkipBody}
                  title={title}
                  onCancel={toggleSkipBody}
                  onConfirmSkip={selfOnConfirmSkip}
                  showVerticalStyle={showVerticalButtons}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionCard;
