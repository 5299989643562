import _chunk from 'lodash/chunk';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';
import _isNumber from 'lodash/isNumber';
import _range from 'lodash/range';
import moment from 'moment-timezone';
import {
  DataField,
  DataCaptureReserved,
  CapturedData,
  CaseReportDataType,
} from '@curebase/core/types';
import { humanReadableSchemaFlowValue } from '@curebase/core/lib/slugs';
import { CaseReport, OptionalityVisibility } from '@curebase/core/types';
import { PII_FIELDS } from '@curebase/core/lib/constants';

const {
  Autocomplete,
  Checkbox,
  Computed,
  Date: TDate, //not to confuse with the Date object
  MonthPicker,
  Time,
  Datetime,
  Dropdown,
  File,
  List,
  Multicheckbox,
  Multiselect,
  Number: TNumber,
  Paragraph,
  PhoneNumber,
  Signature,
  Slider,
  Text,
  YesNo,
  TelehealthFileUpload,
  ContentBlock,
  FuzzyDatePicker,
  Height,
  DrugbankAutocomplete,
} = CaseReportDataType;

type FormElement = {
  capturedData: any;
  dataField: any;
  values: Array<any>;
};

const deprecatedResponse = (value: Pick<CapturedData, 'value'>) =>
  `${value} (DEPRECATED RESPONSE)`;

export const humanReadableValueFromArray = (
  value: Pick<CapturedData, 'value'>,
  dataFieldConfig: Pick<DataField, 'options'>
) => {
  const foundOption = dataFieldConfig.options?.find(opt => opt.value === value);
  return foundOption ? foundOption.text : deprecatedResponse(value);
};

export function humanReadableValueFromHeight(value: any): string {
  if (value) {
    return `${value.feet} ft ${value.inches} in`;
  } else {
    return '-';
  }
}

export function getReadableValueFromCapturedData(
  dataFieldConfig: Pick<DataField, 'dataType' | 'options'>,
  value: CapturedData['value']
) {
  // TODO: apply locales

  if (value === PII_FIELDS.USER_WITHOUT_PERMISSIONS_TO_SEE_ANSWER) {
    return value;
  }

  // Do not convert captured data with NotApplicableDueToDependency if type is FILE
  // Let the component handle how to render it
  if (
    value === DataCaptureReserved.NotApplicableDueToDependency &&
    dataFieldConfig.dataType !== File
  ) {
    return humanReadableSchemaFlowValue(
      DataCaptureReserved.NotApplicableDueToDependency
    );
  }

  switch (dataFieldConfig.dataType) {
    case CaseReportDataType.Multiselect: {
      return humanReadableValueFromArray(value, dataFieldConfig);
    }
    case CaseReportDataType.Multicheckbox: {
      if (value && _isArray(value)) {
        return value
          .map(itemValue => {
            return humanReadableValueFromArray(itemValue, dataFieldConfig);
          })
          .join(', ');
      } else return deprecatedResponse(value);
    }
    case CaseReportDataType.Date: {
      return moment(value as string).format('MMM Do YY');
    }
    case CaseReportDataType.MonthPicker: {
      return moment(value as string).format('MMM YYYY');
    }
    case CaseReportDataType.Datetime: {
      return moment(value as number).format('LLL');
    }
    case CaseReportDataType.YesNo: {
      if (value === 'YES') return 'Yes';
      else if (value === 'NO') return 'No';
      return deprecatedResponse(value);
    }
    case CaseReportDataType.Height: {
      return humanReadableValueFromHeight(value);
    }
    case CaseReportDataType.Autocomplete: {
      return JSON.stringify(value, undefined, 4);
    }
    case CaseReportDataType.Dropdown: {
      return humanReadableValueFromArray(value, dataFieldConfig);
    }
    default:
      return value;
  }
}
export function getChangeList(element: FormElement) {
  const { capturedData, dataField, values } = element;
  const {
    // value, author, submittedOn->at
    capturedDataChanges,
    editReason,
    authoredByUser,
    submittedOn,
  } = capturedData;
  let edits = capturedDataChanges.map(
    ({ value, authorUser, submittedOn, editReason }: any) => ({
      // if there are no changes, then dont add the current value
      value: getReadableValueFromCapturedData(dataField, value),
      author: authorUser,
      createdAt: submittedOn,
      editReason,
    })
  );

  if (edits.length > 0) {
    // oldest first
    edits.push({
      // set the prior value
      value: values[0],
      editReason,
      author: authoredByUser,
      createdAt: submittedOn,
    });
  }

  edits.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1));
  edits = edits.map((m: any, i: any) => {
    // remove the initial data submission
    if (i > 0) {
      m.priorValue = edits[i - 1].value;
    }

    return m;
  });
  edits.shift();
  return edits;
}

export type DataFieldImportantProperties = Pick<
  DataField,
  | 'id'
  | 'allowFloat'
  | 'checkboxText'
  | 'dataType'
  | 'dependsOn'
  | 'dependsOnOperator'
  | 'description'
  | 'horizontalAxisMarkers'
  | 'isHorizontal'
  | 'order'
  | 'max'
  | 'min'
  | 'name'
  | 'optional'
  | 'options'
  | 'slug'
  | 'contentBlock'
  | 'additionalSettings'
>;

export function dataFieldToSubElement(
  dataField: DataFieldImportantProperties,
  caseReport: Pick<CaseReport, 'optionalityVisibility'>,
  dataFieldSubElemKeyOverride?: Record<string, string>
) {
  const { optionalityVisibility } = caseReport;
  const { optional } = dataField;
  const subElement = getElementSpecificAttributes(
    dataField,
    dataFieldSubElemKeyOverride
  );
  const optionalityText =
    optionalityVisibility === OptionalityVisibility.ShowOptional
      ? optional
        ? ' (optional)'
        : ''
      : optionalityVisibility === OptionalityVisibility.ShowAsterix
      ? optional
        ? ''
        : ' *'
      : '';
  return {
    key: subElement.key,
    keySlug: dataField.slug,
    title: dataField.name + optionalityText,
    note: dataField.description,
    dependsOn: dataField.dependsOn,
    dependsOnOperator: dataField.dependsOnOperator,
    subElements: [subElement],
  };
}
//[DT]: import into DynamicForm
type DynamicFormSubElement = {
  allowFloat?: boolean;
  allowNull: boolean;
  horizontalAxisMarkers?: any[];
  isHorizontal?: boolean;
  key: string;
  max?: number | null;
  min?: number | null;
  noAutocomplete?: boolean;
  options?: {
    text: string;
    value?: any;
    required?: boolean;
    placeholder?: string | null;
  }[];
  placeholder?: string;
  skipValidateOnChange?: boolean;
  validate?: (value: string) => any;
  text?: string | null;
  type: CaseReportDataType;
  contentBlock?: string | null;
  additionalSettings?: {
    freeSolo?: boolean | null;
    multiple?: boolean | null;
    formatAsId?: boolean | null;
    step?: number | null;
  };
};
function getElementSpecificAttributes(
  dataField: DataFieldImportantProperties,
  dataFieldSubElemKeyOverride?: Record<string, string>
): DynamicFormSubElement {
  const key = !dataFieldSubElemKeyOverride
    ? dataField.id
    : dataFieldSubElemKeyOverride[dataField.id] ?? dataField.id;
  switch (dataField.dataType) {
    case Checkbox:
      return {
        type: Checkbox,
        text: dataField.checkboxText,
        allowNull: !!dataField.optional,
        key,
      };
    case File:
      return {
        type: File,
        allowNull: !!dataField.optional,
        key,
      };
    case TelehealthFileUpload:
      return {
        type: TelehealthFileUpload,
        allowNull: !!dataField.optional,
        key,
      };
    case Text:
      return {
        type: Text,
        allowNull: !!dataField.optional,
        key,
      };
    case TNumber:
      return {
        type: TNumber,
        allowNull: !!dataField.optional,
        key,
        min: dataField.min,
        max: dataField.max,
        allowFloat: !!dataField.allowFloat,
      };
    case PhoneNumber:
      return {
        type: PhoneNumber,
        allowNull: !!dataField.optional,
        key,
        placeholder: 'Phone number',
        noAutocomplete: true,
        skipValidateOnChange: true,
      };
    case Multiselect:
      return {
        type: Multiselect,
        allowNull: !!dataField.optional,
        isHorizontal: !!dataField.isHorizontal,
        horizontalAxisMarkers: dataField.horizontalAxisMarkers,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          value: option.value,
          url: option.url,
        })),
      };
    case Multicheckbox:
      return {
        type: Multicheckbox,
        allowNull: !!dataField.optional,
        isHorizontal: !!dataField.isHorizontal,
        horizontalAxisMarkers: dataField.horizontalAxisMarkers,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          value: option.value,
          url: option.url,
        })),
      };
    case YesNo:
      return {
        type: YesNo,
        allowNull: !!dataField.optional,
        key,
      };
    case TDate:
      return {
        type: TDate,
        allowNull: !!dataField.optional,
        key,
      };
    case MonthPicker:
      return {
        type: MonthPicker,
        allowNull: !!dataField.optional,
        key,
      };
    case Time:
      return {
        type: Time,
        allowNull: !!dataField.optional,
        key,
      };
    case Datetime:
      return {
        type: Datetime,
        allowNull: !!dataField.optional,
        key,
      };
    case Dropdown:
      return {
        type: Dropdown,
        allowNull: !!dataField.optional,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          value: option.value,
        })),
      };
    case Autocomplete:
      return {
        type: Autocomplete,
        allowNull: !!dataField.optional,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          value: option.value,
        })),
        additionalSettings: {
          freeSolo: dataField?.additionalSettings?.freeSolo,
          multiple: dataField?.additionalSettings?.multiple,
          formatAsId: dataField?.additionalSettings?.formatAsId,
        },
      };
    case DrugbankAutocomplete:
      return {
        type: DrugbankAutocomplete,
        allowNull: dataField?.optional ?? false,
        key,
      };
    case List:
      return {
        type: List,
        allowNull: false,
        key,
        options: dataField.options?.map(option => ({
          text: option.text,
          placeholder: option.placeholder,
          required: !!option.required,
        })),
      };
    case Paragraph: {
      return {
        type: Paragraph,
        allowNull: dataField.optional ?? false,
        key,
      };
    }
    case Signature: {
      return {
        type: Signature,
        allowNull: dataField.optional ?? false,
        key,
      };
    }
    case Slider:
      return {
        type: Slider,
        allowNull: !!dataField.optional,
        horizontalAxisMarkers: dataField.horizontalAxisMarkers,
        key,
        min: dataField.min,
        max: dataField.max,
        additionalSettings: {
          step: dataField?.additionalSettings?.step,
        },
      };
    case ContentBlock:
      return {
        type: ContentBlock,
        allowNull: true,
        contentBlock: dataField.contentBlock,
        key,
      };
    case FuzzyDatePicker:
      return {
        type: FuzzyDatePicker,
        allowNull: true,
        key,
      };
    case Height:
      return {
        type: Height,
        allowNull: dataField.optional ?? false,
        key,
        min: dataField.min,
        max: dataField.max,
      };
    default:
      throw new Error(
        `[lib/caseReports:getElementSpecificAttributes]: unknown DataField dataType {${dataField?.dataType}}`
      );
  }
}

export type CaseReportDynamicForm = {
  dataFields: ReadonlyArray<DataFieldImportantProperties>;
  knowledgeContent?: any;
  displayOnSinglePage: boolean;
} & Pick<CaseReport, 'optionalityVisibility'>;

export function generateDynamicFormPagesFromCaseReport(
  caseReport: CaseReportDynamicForm,
  dataFieldSubElemKeyOverride?: Record<string, string>
) {
  const elements: ReturnType<typeof dataFieldToSubElement>[] = [];
  const sortedDFs = [...caseReport.dataFields].sort(
    (a, b) => a.order - b.order
  );
  _forEach(sortedDFs, dataField => {
    if (dataField.dataType !== Computed) {
      try {
        elements.push(
          dataFieldToSubElement(
            dataField,
            caseReport,
            dataFieldSubElemKeyOverride
          )
        );
      } catch (err) {
        console.error(err);
      }
    }
  });

  const elementsByPage = _filter(
    _chunk(elements, caseReport.displayOnSinglePage ? elements.length : 4),
    eles => eles.length > 0
  );

  const keyToPageMap: { [subElementKey: string]: number } = {};
  let processedElementsByPage: typeof elementsByPage = [];

  //Ensure dependencies are on the same page
  for (const i of _range(elementsByPage.length)) {
    const pageEles = elementsByPage[i];
    processedElementsByPage[i] = [];

    for (const ele of pageEles) {
      const { dependsOn } = ele;
      //if there is a dependency, add to the page with the dependency
      if (dependsOn && dependsOn.length > 0) {
        //find the last dependent and insert the element into the same page
        const pageOfParentDataFieldArr = dependsOn
          .map(({ dataFieldId: id }) => keyToPageMap[id])
          .filter(_isNumber);

        if (pageOfParentDataFieldArr?.length > 0) {
          const pageOfParentDataField = pageOfParentDataFieldArr.reduce(
            (a, b) => Math.max(a, b)
          ); //find the biggest page number or set to i as fallback
          processedElementsByPage[pageOfParentDataField].push(ele);
          keyToPageMap[ele.key] = pageOfParentDataField;
        }
      } else {
        keyToPageMap[ele.key] = i;
        processedElementsByPage[i].push(ele);
      }
    }
  }

  processedElementsByPage = _filter(
    processedElementsByPage,
    eles => eles.length > 0
  );

  return processedElementsByPage.map(elements => ({
    elements,
    knowledgeContent: caseReport.knowledgeContent,
  }));
}
