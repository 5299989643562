import { Locale } from '../types';

//TODO A.B: Once we get the sandbox zendesk, change this URL.
export const sandboxZendeskBaseURL = 'curebase.zendesk.com';
export const productionZendeskBaseURL = 'curebase.zendesk.com';
export const supportPhoneNumber: string = '(917) 924-5957';

export const DYNAMIC_UPLOAD_TRIAL_PREFIX = 'Trials';

export const ZIP_CODES_REGEX = /\b\d{4,6}\b/;
export const PHONE_NUMBER_REGEX = /^((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/;

export const DEFAULT_LOCALE: string = 'en-us';
export const DEFAULT_LOCALE_JSON: Locale = {
  language: 'en',
  country: 'us',
  locale: 'en-us',
  isDefault: true,
};

export enum PII_FIELDS {
  USER_WITHOUT_PERMISSIONS_TO_SEE_NOTE = 'USER_WITHOUT_PERMISSIONS_TO_SEE_NOTE',
  USER_WITHOUT_PERMISSIONS_TO_SEE_ANSWER = 'USER_WITHOUT_PERMISSIONS_TO_SEE_ANSWER',
}
