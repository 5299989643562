import * as React from 'react';
import Button from '@material-ui/core/Button';
import { withBreakpoints } from '../hocs/WithBreakpoints';

type Props = {
  buttons: {
    text: string;
    onClick: () => any;
    disabled?: boolean;
  }[];
  tabletSizedScreen?: boolean;
  children?: React.ReactNode;
};

type State = {};

class BottomButton extends React.Component<Props, State> {
  render() {
    return (
      <div className='bottom-buttons-container'>
        <div className='bottom-button-container'>{this.props.children}</div>

        {this.props.buttons.map((button, i) => (
          <div key={i} className='bottom-button-container'>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => {
                button.onClick();
              }}
              disabled={button.disabled}
            >
              {button.text}
            </Button>
          </div>
        ))}
      </div>
    );
  }
}

export default withBreakpoints(BottomButton as any) as any;
