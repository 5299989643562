import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';
import { CheckBoxElement } from './types';

export const useCreateAttestationsFields = ({
  attestations,
  isMinorAssentSignature,
  showMinorAssentCheckbox,
}: {
  attestations?: Readonly<Array<string>>;
  isMinorAssentSignature?: boolean;
  showMinorAssentCheckbox?: boolean;
}): Array<CheckBoxElement> => {
  const { t } = useOverridableTranslation('translations');

  if (isMinorAssentSignature) {
    return [];
  }

  const minorAssentAttestations = [
    {
      type: 'CHECKBOX',
      text: t('signatureCaptureInDialog.signatureOnBeHalfOfMinor'),
      key: 'parent_signature_on_behalf_of_minor',
      allowNull: true,
    },
  ];

  const _attestations: any = [
    {
      type: 'CHECKBOX',
      text: t('signatureCaptureInDialog.signatureLegallyBinding'),
      key: 'agree_signature_legally_binding',
    },
    {
      type: 'CHECKBOX',
      text: t('signatureCaptureInDialog.signatureForgingProhibited'),
      key: 'agree_signature_forging_prohibited',
    },
    ...(showMinorAssentCheckbox ? minorAssentAttestations : []),
  ];

  if (attestations && attestations.length > 0) {
    _attestations.push(
      ...attestations.map((q, i) => ({
        type: 'CHECKBOX',
        text: q,
        key: `agree_${i}`,
      }))
    );
  }

  return _attestations;
};

export const useCreatePages = ({
  attestationsFields,
  reason,
  isMinorAssentSignature,
}: {
  attestationsFields: Array<CheckBoxElement>;
  reason: string;
  isMinorAssentSignature: boolean | undefined;
}) => {
  const { t } = useOverridableTranslation('translations');

  const fullNameOfChild = {
    title: t('signatureCaptureInDialog.pages.fullNameOfChild'),
    subElements: [
      {
        type: 'TEXT',
        key: 'childLegalName',
        placeholder: t(
          'signatureCaptureInDialog.pages.fullNameOfChildPlaceholder'
        ),
      },
    ],
  };

  const minorAssentFieldDependsOn = {
    dependsOn: {
      key: 'parent_signature_on_behalf_of_minor',
      value: 'checked',
    },
  };

  return [
    {
      elements: [
        {
          title: t('signatureCaptureInDialog.pages.agreeTitle'),
          subElements: attestationsFields,
        },
        {
          title: reason,
          subElements: [{ type: 'SIGNATURE', key: 'signature' }],
        },
        ...(isMinorAssentSignature
          ? [fullNameOfChild]
          : [
              {
                title: t('signatureCaptureInDialog.pages.fullLegalName'),
                subElements: [
                  {
                    type: 'TEXT',
                    key: 'legalName',
                    placeholder: t(
                      'signatureCaptureInDialog.pages.fullLegalNamePlaceholder'
                    ),
                  },
                ],
              },
            ]),
        {
          ...fullNameOfChild,
          ...minorAssentFieldDependsOn,
        },
      ],
    },
  ];
};
