import { mdToHTML } from '@curebase/core/lib/markdown';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import DialogHeader from './DialogHeader';

type Props = {
  title: string;
  message: string;
  markdown?: string;
  onConfirm: () => any;
};

function AlertInDialog({ title, onConfirm, message, markdown }: Props) {
  const { t } = useTranslation('translations');
  return (
    <>
      <DialogHeader title={title} onClose={onConfirm} />

      <div className='confirmation-dialog-container'>
        <div className='confirmation-dialog-message'>
          {message.split('\n').map((item, idx) => {
            return <p key={item}>{item}</p>;
          })}

          {markdown && (
            <div
              className='confirmation-dialog-message-innerhtml'
              dangerouslySetInnerHTML={{
                __html: mdToHTML(markdown),
              }}
            />
          )}
        </div>

        <div className='confirmation-dialog-buttons'>
          <Button color='primary' onClick={onConfirm}>
            {t('common.ok')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AlertInDialog;
