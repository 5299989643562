import * as React from 'react';
import Loadable from 'react-loadable';

import { apiUrl } from '@curebase/core/lib/env';
import Loading from './Loading';
import { getLocale } from '../context/localeContext';

type Props = {
  endpoint: string;
};

const LoadableGraphiQL = Loadable({
  loader: () => import('graphiql'),
  loading: () => <Loading />,
});

class GraphQLAssociateFetcher extends React.Component<Props> {
  graphQLAssociateFetcher = graphQLParams => {
    const { endpoint } = this.props;
    return fetch(`${apiUrl}/${endpoint}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
        'x-curebase-locale': getLocale().locale,
      },
      credentials: 'include',
      body: JSON.stringify(graphQLParams),
    }).then(response => response.json());
  };

  render() {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <LoadableGraphiQL
          fetcher={this.graphQLAssociateFetcher}
          editorTheme='solarized light'
        />
      </div>
    );
  }
}

export default GraphQLAssociateFetcher;
