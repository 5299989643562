import { TReduxState } from '../types';

export const getUser = (state: TReduxState) => state.user;
export const getUserRoles = (state: TReduxState) => getUser(state).roles ?? [];
export const getUserStyles = (state: TReduxState) => {
  const { roles, trialOptionId } = getUser(state) as any;
  let customStyle: any = null;
  for (const role of roles || []) {
    for (const to of role?.patient?.trialOptions || []) {
      if (to.id === trialOptionId) {
        customStyle = to.trial.customStyle;
        break;
      }
    }
  }
  return customStyle;
};
/**
 * Selector that will return the name of a trial
 * given the trial ID.
 * Return an empty string if the trial is not found.
 *
 * @param state
 */
export const getTrialNameById = (state: TReduxState) => {
  return (id: number) => {
    const trials = state.user?.additionalUserInfo?.allowedTrials;
    const trialKeyMap = trials?.reduce((prev, curr) => {
      prev[curr.id] = curr.name;
      return prev;
    }, {});
    return trialKeyMap ? trialKeyMap[id] : '';
  };
};
