import difflib from 'difflib';
import { format } from 'util';

const cleanAndPrettify = (json: string) => {
  // @ts-ignore
  return JSON.stringify(JSON.parse(json), ' ', 4);
};
const convertTextIntoArray = (json: string) => {
  return json.split(/\r|\n|\r\n/);
};

interface PatchDiffParams {
  fromName: string;
  toName: string;
  from: string;
  to: string;
}

export const patchDiff = (props: PatchDiffParams) => {
  const diffArray = difflib.unifiedDiff(
    convertTextIntoArray(cleanAndPrettify(props.from)),
    convertTextIntoArray(cleanAndPrettify(props.to)),
    {
      fromfile: props.fromName,
      tofile: props.toName,
      lineterm: '',
    }
  );
  const diffString = format(
    'diff --git %s %s\n%s',
    props.fromName,
    props.toName,
    diffArray.join('\n')
  );

  return diffString;
};
