import * as React from 'react';
import DialogHeader from '../DialogHeader';
import TrialPicker from './TrialPicker';

type Props = {
  title: string;
  subtitle?: string;
  onPicked: (trialId: number) => any;
  restrictToClinicId?: string;
  onClose: () => any;
};

type State = {};

class TrialPickerInDialog extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <DialogHeader
          title={this.props.title}
          subtitle={this.props.subtitle}
          onClose={() => this.props.onClose()}
        />
        <TrialPicker
          onPicked={this.props.onPicked}
          restrictToClinicId={this.props.restrictToClinicId}
        />
      </div>
    );
  }
}

export default TrialPickerInDialog;
