import { PatientIntegrationSourceDataType } from '../types';

const { Medical, Wellness } = PatientIntegrationSourceDataType;

export const splitSourcesByDataType = (sources: any[] = []) => {
  const medicalSources = sources.filter(({ type }) => type === Medical);
  const wellnessSources = sources.filter(({ type }) => type === Wellness);

  return {
    medicalSources,
    wellnessSources,
  };
};
