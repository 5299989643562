import _ from 'lodash';
import {
  INIT_TRIAL,
  EDIT_TRIAL,
  ADD_STUDYPLANVISIT,
  EDIT_STUDYPLANVISIT,
  EDIT_SPONSOR,
  Action,
  EditTrialAction,
  AddStudyPlanVisitAction,
  EditStudyPlanVisit,
  EditSponsorAction,
  CUSTOM_ACTION_REFS,
} from '../../actions/TrialBuilder/types';
// @ts-ignore
import { RefsMap } from '../../../components/TrialBuilder/TrialBuilder';
import {
  TRIAL_STRING_REFS,
  TRIAL_OBJECT_REFS,
} from '../../../components/TrialBuilder/types';

import { wrapInLanguage } from '../../../shared/lib/languageHelpers';

const _updateStudyPlanVisitSummary = (
  state: RefsMap,
  action: EditStudyPlanVisit
) => {
  const nextState = { ...state };
  const summary = (action.payload.updates as any).summary;
  nextState[summary.key] = summary.summaries.map(
    ({ language, title, description }) => {
      return wrapInLanguage(language, { title, description });
    }
  );
  return nextState;
};

const _createStudyPlanVisitSummary = (
  state: RefsMap,
  action: AddStudyPlanVisitAction
) => {
  const { summary }: any = action.payload;
  if (!summary) return state;
  const nextState = { ...state };
  nextState[summary.key] = summary.summaries.map(
    ({ language, title, description }) => {
      return wrapInLanguage(language, { title, description });
    }
  );
  return nextState;
};

const _editTrial = (state: RefsMap, action: EditTrialAction) => {
  let nextState = { ...state };
  const grpkeys = {};
  const updates: any = action.payload.updates;
  const grpRefs = updates.extraDetails?.grpRefs || undefined;
  // Iterate over all the updates that are passed to this reducer
  _.forOwn(updates, (value, key) => {
    // Notifications are kind of unique in that the data value per locale is
    // actually an array of all the notifications, so we have to do
    // a little tricky looping here.
    // Note that refs are being created both for 'notifications' and the
    // 'name' value under '<restOfRef>/notifications/<name>'. This is legacy
    // behavior so may not be needed in the future.
    if (key === 'notifications') {
      nextState[value.key] = value.data?.map(el => {
        el.data.forEach(({ name, body }) => {
          const notificationKey = `${value.key}/${name}`;
          nextState[notificationKey] = [
            ...(nextState[notificationKey] || []),
            wrapInLanguage(el.language, { body }),
          ];
        });
        return wrapInLanguage(el.language, el.data);
      });
    } else if (key === 'groups') {
      // The groups key also has a sub-array of values,
      // one for each group
      value.forEach(grp => {
        if (grp.overrideMessageKey) {
          grpkeys[grp.overrideMessageKey] = grpRefs[grp.overrideMessageKey];
        }
      });
    }
    // As of this writing the only other key that works similar to
    // notifications is the helpContent card, because it has an object as
    // the value. We treat it similarly here.
    if (TRIAL_OBJECT_REFS.includes(key)) {
      nextState[value.key] = value.data?.map(({ language, data }) => {
        if (!data) return wrapInLanguage(language, '');
        // dataObj for contentHelp would be { title: "some title", body: "something"}
        for (const [k, v] of Object.entries(data)) {
          // This loop is being done in order to follow convention -
          //  the notifications behaves similarly to this. This results in
          // entries for each text field being saved to the Translations table. An improvement could
          // be not doing this at all.
          const refKey = `${value.key}/${k}`;
          nextState[refKey] = [
            ...(nextState[refKey].filter(el => el.language !== language) || []),
            {
              language,
              content: v,
            },
          ];
        }
        return wrapInLanguage(language, data);
      });
    }
    if (TRIAL_STRING_REFS.includes(key)) {
      // "String refs" all look the same and so can be dealt
      // with in the same way:
      nextState[value.key] = value.data.map(el => ({
        language: el.language,
        content: el.content,
      }));
    }
  });

  //We combine the keys with the extra refs that are being passed in.
  nextState = Object.assign({}, nextState, grpkeys);
  return nextState;
};

const _editSponsor = (state: RefsMap, action: EditSponsorAction) => {
  const nextState = { ...state };
  const { summary, sponsorText }: any = action.payload;
  nextState[summary] = sponsorText.map(
    ({ language, aboutText, prescreeningText }) => {
      return wrapInLanguage(language, { aboutText, prescreeningText });
    }
  );
  return nextState;
};

export default function (state: RefsMap = {}, action: Action) {
  switch (action.type) {
    case EDIT_SPONSOR:
      return _editSponsor(state, action);
    case EDIT_STUDYPLANVISIT:
      return _updateStudyPlanVisitSummary(state, action);
    case ADD_STUDYPLANVISIT:
      return _createStudyPlanVisitSummary(state, action);
    case EDIT_TRIAL:
      return _editTrial(state, action);
    case INIT_TRIAL: {
      const { refs } = action.payload;
      if (!refs) return state;
      return { ...refs };
    }
    case CUSTOM_ACTION_REFS: {
      const { refs } = action.payload;
      if (!refs) return state;
      const nextState = { ...state };
      Object.entries(refs).forEach(([key, val]) => {
        nextState[key] = [{ language: 'en', content: val }];
      });
      return nextState;
    }
    default:
      return state;
  }
}
