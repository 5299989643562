export const supportPhoneNumber: string = '(917) 924-5957';

export const testPassword: string = 'test';

export enum HTTP_STATUS_CODE {
  SERVICE_UNAVAILABLE = 503,
}

export enum DEFAULT_SIGNUP_FORM_FIELDS {
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ZIPCODE = 'ZIPCODE',
}

const SIGNUP_FORM_FIELDS_TRANSLATION_KEYS = {
  [DEFAULT_SIGNUP_FORM_FIELDS.FIRST_NAME]:
    'participants.remoteSignupForm.firstName',
  [DEFAULT_SIGNUP_FORM_FIELDS.LAST_NAME]:
    'participants.remoteSignupForm.lastName',
  [DEFAULT_SIGNUP_FORM_FIELDS.EMAIL_ADDRESS]:
    'participants.remoteSignupForm.emailAddress',
  [DEFAULT_SIGNUP_FORM_FIELDS.PHONE_NUMBER]:
    'participants.remoteSignupForm.phoneNumber',
  [DEFAULT_SIGNUP_FORM_FIELDS.ZIPCODE]: 'participants.remoteSignupForm.zipcode',
};

export const CUSTOM_IDS_PARAMS = ['wbaid'];

export const isDefaultSignupFormField = (value: string) =>
  Object.keys(DEFAULT_SIGNUP_FORM_FIELDS).includes(value);

export const getTranslatedKeys = (value: string, defaultValue: string) => {
  const val = value.toUpperCase();
  return isDefaultSignupFormField(val)
    ? SIGNUP_FORM_FIELDS_TRANSLATION_KEYS[val]
    : defaultValue;
};
