import { getSchemaIntrospection } from '../introspection';

export function getEnumArrayFromSchema(type: string) {
  const schema = getSchemaIntrospection();
  const typeEle = schema.__schema.types.find((ele: any) => ele.name === type);
  if (!typeEle) {
    throw new Error(
      'Could not find any element in the schema introspection file for type: ' +
        type
    );
  }
  if (typeEle.kind !== 'ENUM') {
    throw new Error(`Element of type ${type} is not of type ENUM`);
  }
  return typeEle.enumValues.map((v: any) => v.name);
}
