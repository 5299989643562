import ChimeSdkWrapper, { RosterType } from '../lib/chime';
import chimeContext from '../context/getChimeContext';
import { useState, useEffect, useContext } from 'react';

export default function useRoster() {
  const chime: ChimeSdkWrapper | null = useContext(chimeContext);
  const [roster, setRoster] = useState<RosterType>(chime?.roster ?? {});
  useEffect(() => {
    const callback = (newRoster: RosterType) => {
      setRoster({
        ...newRoster,
      } as RosterType);
    };
    chime?.subscribeToRosterUpdate(callback);
    return () => {
      chime?.unsubscribeFromRosterUpdate(callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return roster;
}
