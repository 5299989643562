import _ from 'lodash';
import { createSelector } from 'reselect';
import produce from 'immer';

import {
  byOrder,
  byStartDay,
  caseReportSlugFrom,
  isUniqueSimpleSlug,
  isNotSignupSimpleSlug,
  isNotSignupSPVSlug,
} from '../../../lib/trialBuilderHelpers';
import { TReduxState } from '../../types';
import { DataState } from '../../reducers/TrialBuilder';
import {
  CaseReport,
  DataField,
  Locale,
  Sponsor,
  StudyPlanVisit,
  Trial,
} from '@curebase/core/types';
import {
  TRIAL_REFS_FIELDS,
  TRIAL_STRING_REFS,
} from '../../../components/TrialBuilder/types';
import { DEFAULT_LOCALE_JSON } from '@curebase/core/lib/constants';

type DataFieldMap = any;
type CaseReportMap = any;
type StudyPlanVisitMap = any;

const byCaseReportSlug = slug => df => df.caseReport.slug === slug;

export const getTrialBuilderStore = (store: TReduxState) => store.trialBuilder;

export const noPastHistory = (store: TReduxState) =>
  getTrialBuilderStore(store).data.past.length === 0;

export const isTrialDialogOpen = (store: TReduxState) =>
  getUIState(store).dialogTrial;

export const getUIState = (store: TReduxState) =>
  getTrialBuilderStore(store).UI;

export const getData = (store: TReduxState) => getTrialBuilderStore(store).data;

export const getPresent = (store: TReduxState) => getData(store).present;
export const getPast = (store: TReduxState) => getData(store).past;
export const getFuture = (store: TReduxState) => getData(store).future;

export const getSlugToEdit = (store: TReduxState): string | null =>
  getUIState(store).slug;

export const getParentSlug = (store: TReduxState): string | null =>
  getUIState(store).parentSlug;

export const getParentId = (store: TReduxState): number | null =>
  getUIState(store).parentId;

export const getDataFields = (store: TReduxState) =>
  getPresent(store).dataFields;

export const getRefs = (store: TReduxState) => getPresent(store).refs;

export const getRefKeys = createSelector([getRefs], refs => Object.keys(refs));

export const getStudyPlanVisitSummaries = createSelector(
  [getRefs, getRefKeys],
  (refs: Object, refKeys: string[]) => {
    const summaryKeys = refKeys.filter(item => item.includes('visitSummaries'));
    return _.chain(refs)
      .pick(summaryKeys)
      .transform((result, value: any, key) => {
        result[key] = value[0].content.data;
      }, {})
      .value();
  }
);

export const getTrialRaw = (store: TReduxState): Object =>
  getPresent(store).trial;

export const getTrial = (store: TReduxState): Trial => {
  const refs: any = getRefs(store);
  let refsMapped = _.chain(getPresent(store).trial)
    .pickBy(
      (value, key) =>
        !!value && (TRIAL_REFS_FIELDS.includes(key) || key === 'groups')
    )
    .mapValues((value, key) => {
      if (key === 'notifications') {
        return refs?.[value]?.[0]?.content?.data;
      }

      //groups have a custom implementation, as they have a nested object that is a reference
      if (key === 'groups') {
        let updatedGroups = produce(value, draft => {
          draft.forEach(grp => {
            if (
              grp.overrideMessageKey &&
              refs[grp.overrideMessageKey].length >= 1
            ) {
              grp.overrideMessage =
                refs[grp.overrideMessageKey][0].content.data;
            }
          });
        });

        return updatedGroups;
      }
      // help Content is a ref that does not need to be grabbed from refs here
      // as it is saved in the local state in the english version.
      if (key === 'helpContent') {
        return value;
      }
      if (TRIAL_STRING_REFS.includes(key))
        return { content: refs[value][0].content, key: value };
      return { ...refs[value][0].content.data, key: value };
    })
    .value();
  // @ts-ignore
  return _.assign({}, getTrialRaw(store), refsMapped);
};

/**
 * Selects the string refs from the store related to a trial. These
 * refs are displayed for editing in the TrialDialogView.tsx component.
 */
export const getTrialDialogViewRefs = (store: TReduxState) => {
  const refs: any = getRefs(store);
  // Get all the fields related to a trial from the store.
  // Fields that are 'refs' will have a value of a string
  // reference with which you can get the value in the refs object.
  // For example for Cardiotech notifications:
  // refsMapped.notifications = 'trials/cardiotech/notifications'
  // So in order to get the value of this ref:
  // use refs[refsMapped.notifications], which would give you
  // the value of that ref.
  let refsMapped = _.chain(getPresent(store).trial)
    .pickBy(
      (value, key) =>
        !!value && (TRIAL_REFS_FIELDS.includes(key) || key === 'groups')
    )
    .mapValues((value, key) => {
      if (key === 'notifications') {
        // Use the 'value' here to find the actual value in the refs object
        return (
          refs?.[value]?.map(el => {
            return {
              language: el.language,
              data: el.content.data,
            };
          }) || []
        );
      }

      if (key === 'groups') {
        let updatedGroups = produce(value, draft => {
          draft.forEach(grp => {
            if (refs[grp?.overrideMessageKey]?.length >= 1) {
              grp.overrideMessages = refs[grp?.overrideMessageKey].map(el => {
                return {
                  language: el.language,
                  overrideMessage: el.content.data,
                };
              });
            }
          });
        });

        return updatedGroups;
      }
      if (key === 'helpContent') {
        // The ref path name is not stored directly on the trial like other
        // refs, so the ref name for it needs to be recreated here:
        return refs[`/trials/${getPresent(store).trial.slug}/helpContent`]?.map(
          ({ language, content }) => {
            return {
              language,
              data: content.data,
            };
          }
        );
      }

      if (TRIAL_STRING_REFS.includes(key)) {
        return {
          data: refs[value].map(({ language, content }) => ({
            language,
            content: content,
          })),
          key: value,
        };
      }
      return {
        data: refs[value].map(({ language, content }) => ({
          language,
          content: content.data,
        })),
        key: value,
      };
    })
    .value();

  return refsMapped;
};

export const getDocumentConfigurationIds = (store: TReduxState): string[] =>
  // @ts-ignore
  getPresent(store).documentConfigurationIds;

export const getVersion = (store: TReduxState): string =>
  getPresent(store).version;

export const getDeploymentInfo = (store: TReduxState): Object =>
  getPresent(store).deploymentInfo;

export const getCasebook = (store: TReduxState): any =>
  getPresent(store).casebook;

export const getSponsor = (store: TReduxState): Sponsor =>
  getPresent(store).sponsor;

export const getMachine = (store: TReduxState): Object =>
  getPresent(store).machine;

export const getDataFieldBySlug = (
  store: TReduxState,
  slug: string
): DataField => getDataFields(store)[slug];

export const getEvaluatorDependencies = (
  store: TReduxState,
  studyPlanVisitSlug: string
): Array<string> => {
  const spv = getStudyPlanVisitBySlug(store, studyPlanVisitSlug);
  const dataFieldSuperIds: any = [];
  const evaluators = spv?.evaluators || [];
  evaluators.forEach(({ dependsOnSuperIds }) => {
    dependsOnSuperIds.forEach(superId => dataFieldSuperIds.push(superId));
  });
  return dataFieldSuperIds;
};

export const getDataFieldsArrayByStudyPlanVisit = (
  store: TReduxState,
  studyPlanVisitSlug: string
) => {
  // @ts-ignore
  const caseReports = getCaseReportsByStudyPlanVisit(store, {
    slug: studyPlanVisitSlug,
  });
  const dataFields = getDataFieldsArray(store);
  return dataFields.filter(df =>
    caseReports.find(cr => cr.slug === df.caseReport.slug)
  );
};

export const getDataFieldsArray = createSelector(
  [getDataFields],
  (dataFields: DataFieldMap): Array<DataField> => Object.values(dataFields)
);

export const getDataFieldsByCaseReportSlug = (
  store: TReduxState,
  caseReportSlug: string
): Array<DataField> =>
  getDataFieldsArray(store)
    .filter(df => df.caseReport.slug === caseReportSlug)
    .sort((a, b) => a.order - b.order);

export const getCaseReports = (store: TReduxState) =>
  getPresent(store).caseReports || {};

export const getCaseReportBySlug = (
  store: TReduxState,
  slug?: string | null
  // @ts-ignore
): CaseReport | null => (slug ? getCaseReports(store)[slug] : undefined);

export const getCaseReportsArray = createSelector(
  [getCaseReports],
  (caseReports: CaseReportMap): Array<CaseReport> => {
    return Object.values(caseReports);
  }
);

export const getCaseReportsListAndMap = (store: TReduxState) => ({
  list: getCaseReportsArray(store),
  hashmap: getCaseReports(store),
});

export const getStudyPlanVisits = (store: TReduxState) =>
  getPresent(store).studyPlanVisits || {};

export const getStudyPlanVisitToEdit = (store: TReduxState) => {
  const slugToEdit = getSlugToEdit(store);
  if (!slugToEdit) return null;
  const spv: any = getStudyPlanVisits(store)[slugToEdit];
  return {
    ...spv,
    summary: {
      summaries: getRefs(store)[spv.summary].map(el => {
        return {
          description: el?.content?.data?.description,
          title: el?.content?.data?.title,
          language: el?.language,
        };
      }),
      key: spv.summary,
    },
  };
};

//memoized
export const getStudyPlanVisitsArray = createSelector(
  [getStudyPlanVisits],
  (studyPlanVisits: StudyPlanVisitMap): Array<StudyPlanVisit> => {
    // @ts-ignore
    return Object.values(studyPlanVisits).sort(byStartDay);
  }
);

export const getSPVsWithSummary = createSelector(
  [getStudyPlanVisitsArray, getRefs],
  (spvs: any[], refs: { [key: string]: any }) => {
    return spvs.map(spv => ({
      ...spv,
      summary: refs[spv.summary]?.[0]?.content.data,
    }));
  }
);

export const getCaseReportsByStudyPlanVisit = (
  store: TReduxState,
  studyPlanVisit: StudyPlanVisit
): Array<CaseReport> => {
  return getCaseReportsArray(store).filter(
    cr => cr.studyPlanVisit.slug === studyPlanVisit.slug
  );
};

export const getCaseReportsByStudyPlanVisitId = (
  store: TReduxState,
  id: number
): Array<CaseReport> =>
  getCaseReportsArray(store).filter((cr: any) => cr.studyPlanVisit.id === id);

const _dataFieldsByCaseReportId = (
  dfArray: Array<DataField>,
  slug: string
): Object => ({
  list: dfArray,
  slug,
});

export const makeGetDependsOnByCaseReportId = () => {
  return createSelector(
    [_dataFieldsByCaseReportId],
    (obj: { list: Array<DataField>; slug: string }) => {
      const { list: dataFields, slug: caseReportSlug } = obj;
      return dataFields
        .filter(byCaseReportSlug(caseReportSlug))
        .map(({ name, slug, order, options, dataType }) => ({
          name,
          slug,
          order,
          options,
          dataType,
        }));
    }
  );
};

const _dataFieldsByCaseReportIds = (
  dfArray: Array<DataField>,
  crIds: Array<string>
): Object => ({ list: dfArray, crIds });

//CHANGE
export const makeGetDataFieldsByCaseReport = () => {
  return createSelector(
    [_dataFieldsByCaseReportIds],
    (obj: { list: Array<DataField>; crIds: Array<number> }) => {
      const { list: allDataFields, crIds } = obj;
      return allDataFields.reduce(
        (tot, cur: any) =>
          crIds.includes(cur.caseReport.slug) ? [...tot, cur] : tot,
        []
      );
    }
  );
};

const _caseReportsByStudyPlanVisitSlug = (
  crArray: Array<CaseReport>,
  id: string //either id or slug
): Object => ({ list: crArray, id });

//CHANGE
export const makeGetCaseReportsByStudyPlanVisit = () => {
  return createSelector(
    [_caseReportsByStudyPlanVisitSlug],
    (obj: { list: Array<CaseReport>; id: number | string }): Object => {
      const { list: caseReports, id } = obj;
      const filteredAndSorted = caseReports
        .filter(x => x.studyPlanVisit.slug === id)
        .sort((a, b) => a.order - b.order);
      const slugs = filteredAndSorted.map(cr => cr.slug);
      return { caseReports: filteredAndSorted, slugs };
    }
  );
};

export const getStudyPlanVisitById = (
  store: TReduxState,
  id: number
): StudyPlanVisit | null => {
  // @ts-ignore
  return getStudyPlanVisitsArray(store).find(spv => spv.id === id);
};

export const getStudyPlanVisitBySlug = (
  store: TReduxState,
  slug: string
): StudyPlanVisit | null => {
  // @ts-ignore
  return getStudyPlanVisitsArray(store).find(spv => spv.slug === slug);
};

const _getStudyPlanVisitById = (
  spvArray: Array<StudyPlanVisit>,
  id: number | string //either ID or Slug
): Object => ({ list: spvArray, id });

// returns a unique copy of the memoized getStudyPlanVisitById
export const makeGetStudyPlanVisitById = () => {
  return createSelector(
    [_getStudyPlanVisitById],
    (obj: { list: Array<StudyPlanVisit>; id: number }) => {
      const { list: spv, id } = obj;
      return spv.find((x: any) => x.id === id);
    }
  );
};

export const makeGetStudyPlanVisitBySlug = () => {
  return createSelector(
    [_getStudyPlanVisitById],
    (obj: { list: Array<StudyPlanVisit>; id: string }) => {
      const { list: spv, id } = obj;
      return spv.find(x => x.slug === id);
    }
  );
};

export const FullStudyPlanVisitById = (
  store: TReduxState,
  id: number
): StudyPlanVisit | null => {
  // @ts-ignore
  return {
    ...getStudyPlanVisitById(store, id),
    caseReports: getCaseReportsByStudyPlanVisitId(store, id).map(cr => ({
      ...cr,
      dataFields: getDataFieldsByCaseReportSlug(store, cr.slug),
    })),
  };
};

//memoized version of the FullStudyPlanVisitById
export const buildFullStudyPlanVisitById = () => {
  return createSelector([FullStudyPlanVisitById], x => x);
};

export const getStudyPlanVisitsForTableView = createSelector(
  [getStudyPlanVisitsArray],
  spvArray => {
    return spvArray
      .filter(isNotSignupSPVSlug)
      .map(({ slug, startDay, id }) => ({
        id,
        startDay,
        slug,
      }));
  }
);

export const getDataFieldDependsOn = (
  store: TReduxState,
  slug?: string
): DataField[] => {
  if (!slug) return [];
  const dataField: DataField = getDataFieldBySlug(store, slug);
  const dataFieldsInCaseReport = getDataFieldsByCaseReportSlug(
    store,
    dataField.caseReport.slug
  );
  return dataFieldsInCaseReport.filter(
    df => !!df.dependsOn && df.dependsOn.map(x => x.key).includes(slug)
  );
};

export const getDescription = (store: TReduxState) =>
  getPresent(store).description;

export const getCaseReportsForTableView = createSelector(
  [getCaseReportsArray, getCaseReports],
  (list: Array<CaseReport>, hashmap: CaseReportMap) => {
    return list
      .filter(isUniqueSimpleSlug)
      .filter(isNotSignupSimpleSlug)
      .map(({ name, simpleSlug, slug }) => ({
        name,
        slug,
        simpleSlug,
        enabledIn: list
          .filter(cr => cr.simpleSlug === simpleSlug)
          .map(({ studyPlanVisit }) => studyPlanVisit.slug)
          .filter(slug => hashmap[caseReportSlugFrom(slug, simpleSlug)]),
      }));
  }
);

export const getConfigToSubmit = createSelector<TReduxState, {}, any, any>(
  [getPresent],
  produce((config: DataState) => {
    type WithTBTags = {
      new?: boolean;
      edited?: boolean;
      deleted?: boolean;
    } & any;
    //mutates!
    const deleteTBTagsFromObject = (obj: WithTBTags, typeOfObj?: string) => {
      if (typeOfObj === 'df') {
        delete obj.logicOperator;
        delete obj.dependsOnOptions;
        delete obj.dependsOnType;
        delete obj.evaluators;
        delete obj.confirmationDialog;
        delete obj.validators;
      }
      delete obj.new;
      delete obj.edited;
      delete obj.deleted;
      return obj;
    };

    const studyPlanVisits = Object.values(config.studyPlanVisits);
    const caseReports = Object.values(config.caseReports);
    const dataFields = Object.values(config.dataFields);

    (config.trial as any).studyPlanVisits = studyPlanVisits
      .map((spv: any) => {
        spv.caseReports = caseReports
          .filter((cr: any) => cr.studyPlanVisit?.slug === spv.slug)
          .sort(byOrder)
          .map((cr: any, index) => {
            cr.order = index + 1;
            cr.dataFields = dataFields
              .filter(df => {
                return df.caseReport?.slug === cr.slug;
              })
              .sort(byOrder)
              .map((df: any, index) => {
                df.order = index + 1;
                delete df.caseReport;
                // @ts-ignore
                return deleteTBTagsFromObject(df, 'df');
              });
            delete cr.studyPlanVisit;
            // @ts-ignore
            return deleteTBTagsFromObject(cr);
          });
        delete spv.trial;
        // @ts-ignore
        return deleteTBTagsFromObject(spv);
      })
      .sort(byStartDay);

    // @ts-ignore
    delete config.studyPlanVisits;
    // @ts-ignore
    delete config.caseReports;
    // @ts-ignore
    delete config.dataFields;
    // @ts-ignore
    delete config.trial.sponsor;
    // @ts-ignore
    delete config.deploymentInfo;
  })
);

export const getLocales = (store: TReduxState): Array<Locale> =>
  getPresent(store).trial?.locales ?? [DEFAULT_LOCALE_JSON];
