import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import hideZendeskWhileMounted from '../../hooks/useHideZendeskWhileMounted';
import {
  Accordion as ExpansionPanel,
  AccordionDetails as ExpansionPanelDetails,
  AccordionSummary as ExpansionPanelSummary,
} from '@material-ui/core';
import DataCaptureResults from '../DataCapture/DataCaptureResults';
import { signOffCaseReport } from '../../controllers/dataCaptureController';
import { CaseReportSignoffType } from '@curebase/core/types';
import { getCaseReportSignoffMaxDate } from '@curebase/core/lib/casereportsignoff';
import { capitalizeDisplayName } from '../../lib/ui';
import { SignatureDialog } from './SignOffSignatureDialog';
import { SignatureFooter } from './SignOffSignatureFooter';
import { NoSignOffsView } from './NoSignOffsView';
import { useTrialOptionCrfSignoffsQuery } from 'src/types';
import Loading from '../Loading';
import { getLocaleFormatDates } from '../../context/localeContext';
import { useTranslation } from 'react-i18next';

const localesFormat = getLocaleFormatDates();

type CrfEntryType = {
  studyPlanVisitSlug: string;
  type:
    | 'DATA_CAPTURE'
    | 'ADVERSE_EVENT'
    | 'PROTOCOL_DEVIATION'
    | 'STATE_TRANSITION';
  title: string;
  time: string;
  slug?: string;
};

interface TrialOptionCRFSignoffsProps {
  trialOptionId: number;
  crfData: Array<CrfEntryType>;
}

const TrialOptionCRFSignoffs = (props: TrialOptionCRFSignoffsProps) => {
  hideZendeskWhileMounted();
  const { crfData, trialOptionId } = props;
  const visits = crfData.filter(({ type }) => type === 'DATA_CAPTURE');
  const { t } = useTranslation('translations');
  const history = useHistory();
  const [crfIsDisplayed, setCrfDisplayed] = useState(
    visits.map(() => 'INITIAL')
  );

  const { data, loading } = useTrialOptionCrfSignoffsQuery({
    variables: { trialOptionId },
  });

  const [
    originalEligibilityAssessment,
    setOriginalEligibilityAssessment,
  ] = useState();

  const [enrollingProvider, setEnrollingProvider] = useState<any>();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [userAttestsToReviewing, setUserAttestsToReviewing] = useState({});

  // for monitoring if we have scrolled to the bottom of the CRFs.
  const [crfsToReviewRef, hasReviewedAllCrfs] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  if (loading) {
    return <Loading />;
  }

  if (!data || !crfData) {
    return null;
  }
  const { getTrialOption: trialOption, getSignableCRFSignoffs } = data;

  const possibleSignoffs = getSignableCRFSignoffs || [];

  const possibleEligbilitySignoffs = possibleSignoffs.filter(
    ({ type }) => type === CaseReportSignoffType.EligibilityAssessment
  );

  if (possibleSignoffs.length === 0) {
    return (
      <div>
        <NoSignOffsView />
      </div>
    );
  }

  const isEligbilityAssessmentSignoff = possibleEligbilitySignoffs.length > 0;

  const caseReportSignoff = isEligbilityAssessmentSignoff
    ? possibleEligbilitySignoffs[0]
    : possibleSignoffs[0];

  const participantName = trialOption.patient!.user.displayName;

  const maxDate = moment(
    getCaseReportSignoffMaxDate(caseReportSignoff, trialOption)
  );

  const visitList = visits.map((crfEntry: CrfEntryType, i: number) => {
    const isDisplayed = crfIsDisplayed[i];
    const shouldBeHidden = getVisits => {
      const allVisitSubmissionTimes = getVisits.flatMap(({ completedAt }) =>
        moment(completedAt)
      );

      const hasSomeRecentData = allVisitSubmissionTimes.some(submissionDate =>
        submissionDate.isSameOrBefore(maxDate)
      );

      const hideSignedDate = !isEligbilityAssessmentSignoff;

      const containsSomeUnsignedData = getVisits
        .flatMap(({ caseReportInstances }) =>
          caseReportInstances.flatMap(({ capturedData }) =>
            capturedData.flatMap(
              ({ caseReportSignoffId }) => caseReportSignoffId
            )
          )
        )
        .some(caseReportSignoffId => !caseReportSignoffId);

      const shouldDisplaySection =
        hasSomeRecentData && (hideSignedDate ? containsSomeUnsignedData : true);

      if (isEligbilityAssessmentSignoff) {
        const visitIsEligbilityAssessment = getVisits
          .map(({ studyPlanVisit }) => studyPlanVisit.isEligibilityAssessment)
          .some(Boolean);
        if (visitIsEligbilityAssessment) {
          const didPass = getVisits.every(
            ({ evaluation }) => evaluation.didPass
          );
          // Find the first non null author on the captured data
          const author = getVisits
            .flatMap(({ caseReportInstances }) =>
              caseReportInstances.flatMap(({ capturedData }) =>
                capturedData.flatMap(({ authoredByUser }) => authoredByUser)
              )
            )
            .find(x => x);

          const submissionDate = moment(getVisits[0].completedAt).format(
            localesFormat.moment.longFormalDateTime
          );

          setEnrollingProvider(author);

          setOriginalEligibilityAssessment(
            // @ts-ignore
            `${capitalizeDisplayName(author, t)} ${t(
              'trialOptionCRFSignoffs.eligbilityMsg',
              {
                participantName,
                didPass: didPass
                  ? t('trialOptionCRFSignoffs.eligibleText')
                  : t('trialOptionCRFSignoffs.ineligibleText'),
                submissionDate,
              }
            )}`
          );
        }
      }

      const newCrfDisplayed: any = [...crfIsDisplayed];
      newCrfDisplayed[i] = true;
      setCrfDisplayed(newCrfDisplayed);
      return shouldDisplaySection;
    };

    if (!isDisplayed) return null;

    return (
      // @ts-ignore
      <ExpansionPanel expanded={true} key={crfEntry.id}>
        <ExpansionPanelSummary>
          <div className='expansion-summary-content'>
            <span className='summary-time'>{crfEntry.time}</span>
            <span className='summary-title'>{crfEntry.title}</span>
          </div>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <div className='expansion-details-content'>
            <DataCaptureResults
              trialOptionId={trialOptionId}
              studyPlanVisitSlug={crfEntry.studyPlanVisitSlug}
              shouldBeHidden={shouldBeHidden}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });

  const onSubmitDialog = async data => {
    const caseReportSignoffId = isEligbilityAssessmentSignoff
      ? possibleEligbilitySignoffs[0].id
      : possibleSignoffs[0].id;

    // @ts-ignore
    await signOffCaseReport(caseReportSignoffId, data);
    history.push('/u/crfsignoff');
  };

  // only allow 'spying' on scroll once all DataCaptures have been loaded/set.
  const allCrfsSet = crfIsDisplayed.every(v => v !== 'INITIAL');

  const attestations: any = [
    { main: t('trialOptionCRFSignoffs.attestationMainMsg') },
  ];

  if (isEligbilityAssessmentSignoff) {
    attestations.push({
      main: t('trialOptionCRFSignoffs.mail.main'),
      sub: (
        <span className='sub-text' onClick={() => {}}>
          {t('trialOptionCRFSignoffs.mail.bodyBegin')}
          <a
            // @ts-ignore
            href={`mailto:${
              enrollingProvider?.email
            }?Subject=${encodeURIComponent(
              `${t('trialOptionCRFSignoffs.mail.signoffSubject')} ${
                trialOption.subjectIdentifier
              }`
            )}&cc=support@mycurebase.com`}
          >
            {t('trialOptionCRFSignoffs.mail.signoffLinkLabel')}
          </a>
          {t('trialOptionCRFSignoffs.mail.bodyEnd')}
        </span>
      ),
    });
  }

  return (
    <>
      <SignatureDialog
        attestations={attestations.map(({ main }) => main)}
        dialogOpen={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        onSubmit={onSubmitDialog}
      />
      <div>
        {/*have to wrap expansion panels in a div so first element css selector works as expected*/}
        {visitList}
      </div>
      <span ref={allCrfsSet ? crfsToReviewRef : null} />
      <SignatureFooter
        attestations={attestations}
        hasReviewedAllCrfs={hasReviewedAllCrfs}
        userAttestsToReviewing={userAttestsToReviewing}
        setUserAttestsToReviewing={i =>
          setUserAttestsToReviewing({
            ...userAttestsToReviewing,
            [i]: !userAttestsToReviewing?.[i] ?? true,
          })
        }
        originalAssessment={originalEligibilityAssessment}
        openDialog={() => setDialogOpen(true)}
      />
    </>
  );
};

export default TrialOptionCRFSignoffs;
