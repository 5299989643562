import { RolePermissions } from '../../types';

export const clinicalResearchCoordinatorEditPermissions = [
  RolePermissions.CanDisableAccounts,
  RolePermissions.CanProvideCounterSignature,
  RolePermissions.CanWriteDocumentNotes,
  RolePermissions.CreateAdHocPayment,
  RolePermissions.CreateCapturedDataQueryMessage,
  RolePermissions.CreateStubParticipant, // Create stub participants and SkipExternalPrescreening
  RolePermissions.CreateParticipantNote,
  RolePermissions.ViewParticipantNote,
  RolePermissions.CreateVisitExtension,
  RolePermissions.EditAxleScheduling,
  RolePermissions.EditCapturedData,
  RolePermissions.EditScheduling,
  RolePermissions.EditTrialOptionDetails,
  RolePermissions.EditTrialOptionVisitSite,
  RolePermissions.EditUserDetails,
  RolePermissions.EditVisitSite,
  RolePermissions.EnrollParticipant,
  RolePermissions.GenerateReferralSources,
  RolePermissions.MarkManualPayments,
  RolePermissions.RandomizeParticipants,
  RolePermissions.ReportEvent,
  RolePermissions.ResetParticipantPayerCredentials,
  RolePermissions.ScheduleVisit,
  RolePermissions.ScreenNewParticipant, //to enable support to set scheduling
  RolePermissions.SelectStudyReferralSource,
  RolePermissions.SkipSubmissionTimeCheck,
  RolePermissions.SubmitInitialDataCapture,
  RolePermissions.WithdrawParticipant,
  RolePermissions.SignInformedConsent,
  RolePermissions.CanProvideBothSignatures,
  RolePermissions.ViewCaseReportSignoff,
  RolePermissions.AddUnscheduledActivity,
  RolePermissions.BackfillDataCapture,
];

export const clinicalResearchCoordinatorReadOnlyPermissions = [
  RolePermissions.CanJoinAnyMeeting,
  RolePermissions.CanJoinTelehealthMeeting,
  RolePermissions.ReadCapturedDataEditQueryMessage, // Referral Sources
  RolePermissions.SkipExternalPrescreening, // Data Capture Edits
  RolePermissions.ViewAdverseEvents,
  RolePermissions.ViewCrf,
  RolePermissions.ViewGroupAssignmentBlindedLabel,
  RolePermissions.ViewInformedConsent,
  RolePermissions.ViewInvestigatorList,
  RolePermissions.ViewParticipantLocation, // Create stub participants
  RolePermissions.ViewParticipantReferralSource,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewPatientPayments,
  RolePermissions.ViewPii,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.CreateProtocolDeviations,
  RolePermissions.ViewReferralSources,
  RolePermissions.ViewScheduling,
  RolePermissions.ViewSettings,
  RolePermissions.ViewUtmCodes,
  RolePermissions.ViewZendeskProfile,
];

export const clinicalResearchCoordinatorDeidentifiedPermissions = [
  RolePermissions.CanDisableAccounts,
  RolePermissions.CreateAdHocPayment,
  RolePermissions.CreateStubParticipant,
  RolePermissions.CreateParticipantNote,
  RolePermissions.ViewParticipantNote,
  RolePermissions.CreateVisitExtension,
  RolePermissions.EditAxleScheduling,
  RolePermissions.EditCapturedData,
  RolePermissions.EditScheduling,
  RolePermissions.EditVisitSite,
  RolePermissions.GenerateReferralSources,
  RolePermissions.MarkManualPayments,
  RolePermissions.ReportEvent,
  RolePermissions.ScheduleVisit,
  RolePermissions.SkipSubmissionTimeCheck,
  RolePermissions.SubmitInitialDataCapture,
  RolePermissions.WithdrawParticipant,
  RolePermissions.SkipExternalPrescreening,
  RolePermissions.ViewAdverseEvents,
  RolePermissions.ViewCrf,
  RolePermissions.ViewGroupAssignmentBlindedLabel,
  RolePermissions.ViewInvestigatorList,
  RolePermissions.ViewParticipantReferralSource,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.CreateProtocolDeviations,
  RolePermissions.ViewReferralSources,
  RolePermissions.ViewScheduling,
  RolePermissions.ViewSettings,
  RolePermissions.ViewCaseReportSignoff,
  RolePermissions.AddUnscheduledActivity,
  RolePermissions.BackfillDataCapture,
  RolePermissions.ReadCapturedDataEditQueryMessage,
];
