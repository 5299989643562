import queryString from 'query-string';
import { useEffect } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { StatusColor } from 'src/shared/lib/colors';
import { connectPaypalAccount } from '../../controllers/paymentsController';
import { getViewPatientBaseUrl } from '../../lib/users';
import { showAlertMessage } from '../../store/actions';
import { getUserSelector } from '../../store/selectors';

const PayPalConnectRedirect = () => {
  const { t } = useTranslation('translations');
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUserSelector);

  useEffect(() => {
    const finishPaypalConnect = async () => {
      const parsedQueryString = queryString.parse(location.search);
      const { code, state: userId } = parsedQueryString;
      const errorRedirectUrl = `${getViewPatientBaseUrl(
        user.trialOptionId
      )}/paypal-instructions`;

      if (!code || !userId) {
        showAlertMessage(
          t('payPalConnectRedirect.accountNotAttachedAlert'),
          StatusColor.Red
        );
        history.push(errorRedirectUrl);
        return;
      }

      const userIdNum = parseInt(String(userId));
      const resp: any = await connectPaypalAccount(userIdNum, String(code));

      const hasError = !!resp.error;
      if (resp.error) {
        if (resp.error === `Could not find payerId from PayPal`) {
          showAlertMessage(
            t('payPalConnectRedirect.payerIdError'),
            StatusColor.Red
          );
        } else {
          showAlertMessage(
            t('payPalConnectRedirect.genericError'),
            StatusColor.Red
          );
        }
      } else {
        showAlertMessage(
          t('payPalConnectRedirect.methodAddedAlert'),
          StatusColor.Green
        );
      }

      if (hasError) history.push(errorRedirectUrl);
      else history.push('/u');
    };

    finishPaypalConnect();
  }, []);

  return <>{t('payPalConnectRedirect.savingMsg')}</>;
};

export default PayPalConnectRedirect;
