import {
  PatientSubmitProblemRequest,
  PatientSubmitProblemResponse,
  MarkEventAsFollowUpRequest,
  SubmitAEFormResponse,
  AdverseEventTransitionRequest,
  CreateAdverseEventQueryRequest,
  CreateAdverseEventQueryResponse,
  ResolveAdverseEventQueryRequest,
  ResolveAdverseEventQueryResponse,
  RejectMedwatchRequest,
  RejectMedwatchResponse,
  FinalizeReportRequest,
  FinalizeReportResponse,
  SubmitMedwatchFormRequest,
  SubmitMedwatchFormResponse,
  FinalizeFormRequest,
  FinalizeFormResponse,
  GetMedwatchLinkRequest,
  GetMedwatchLinkResponse,
} from '@curebase/core/decoders/adverseEvents';
import { FormResponseType } from '@curebase/core/types';
import { jsonPost } from '../lib/fetchHelpers';

export async function submitMedicalProblem(
  trialOptionId: number,
  data: Object
): Promise<PatientSubmitProblemResponse> {
  const requestBody: PatientSubmitProblemRequest = {
    trialOptionId,
    data: { ...data },
  };

  const response: PatientSubmitProblemResponse = await jsonPost(
    '/adverseevent/submitMedicalProblem',
    requestBody
  );
  return response;
}

export async function saveAEFormDraft(
  adverseEventId: number,
  data: Object,
  type: FormResponseType
): Promise<SubmitAEFormResponse> {
  const response: SubmitAEFormResponse = await jsonPost(
    '/adverseevent/saveAEFormDraft',
    {
      adverseEventId,
      data,
      type,
    }
  );
  return response;
}

export async function submitAEForm(
  adverseEventId: number
): Promise<FinalizeFormResponse> {
  const requestBody: FinalizeFormRequest = {
    adverseEventId,
  };

  const response: FinalizeFormResponse = await jsonPost(
    '/adverseevent/submitAEForm',
    requestBody
  );
  return response;
}

export async function submitInitialDetermination(
  adverseEventId: number
): Promise<FinalizeFormResponse> {
  const requestBody: FinalizeFormRequest = {
    adverseEventId,
  };

  const response: FinalizeFormResponse = await jsonPost(
    '/adverseevent/submitInitialDeterminationForm',
    requestBody
  );
  return response;
}

export async function submitSecondaryDetermination(
  adverseEventId: number
): Promise<FinalizeFormResponse> {
  const requestBody: FinalizeFormRequest = {
    adverseEventId,
  };

  const response: FinalizeFormResponse = await jsonPost(
    '/adverseevent/submitSecondaryDetermination',
    requestBody
  );
  return response;
}

export async function submitOtherProblem(
  trialOptionId: number,
  data: Object
): Promise<PatientSubmitProblemResponse> {
  const requestBody: PatientSubmitProblemRequest = {
    trialOptionId,
    data: { ...data },
  };

  const response: PatientSubmitProblemResponse = await jsonPost(
    '/adverseevent/submitOtherProblem',
    requestBody
  );
  return response;
}

export async function markEventAsFollowUp(
  adverseEventId: number,
  followUpEventId: number
) {
  const requestBody: MarkEventAsFollowUpRequest = {
    adverseEventId,
    followUpEventId,
  };

  const response = await jsonPost(
    '/adverseevent/markEventAsFollowUp',
    requestBody
  );
  return response;
}

export async function deleteEventAsFollowUp(
  adverseEventId: number,
  followUpEventId: number
) {
  const requestBody: MarkEventAsFollowUpRequest = {
    adverseEventId,
    followUpEventId,
  };

  const response = await jsonPost(
    '/adverseevent/deleteEventAsFollowUp',
    requestBody
  );
  return response;
}

export async function finalizeReport(
  adverseEventId: number
): Promise<FinalizeReportResponse> {
  const requestBody: FinalizeReportRequest = {
    adverseEventId,
  };

  const response: FinalizeReportResponse = await jsonPost(
    '/adverseevent/finalizeReport',
    requestBody
  );
  return response;
}

export async function finalizeMedwatchForm(adverseEventId: number) {
  const requestBody: AdverseEventTransitionRequest = {
    adverseEventId,
  };

  const response = await jsonPost(
    '/adverseevent/finalizeMedwatchForm',
    requestBody
  );
  return response;
}

export async function acceptMedwatchForm(adverseEventId: number) {
  const requestBody: AdverseEventTransitionRequest = {
    adverseEventId,
  };

  const response = await jsonPost(
    '/adverseevent/acceptMedwatchForm',
    requestBody
  );
  return response;
}

export async function rejectMedwatchForm(
  adverseEventId: number,
  reason: string
): Promise<RejectMedwatchResponse> {
  const requestBody: RejectMedwatchRequest = {
    adverseEventId,
    reason,
  };

  const response: RejectMedwatchResponse = await jsonPost(
    '/adverseevent/rejectMedwatchForm',
    requestBody
  );
  return response;
}

export async function createQuery(
  adverseEventId: number,
  query: string
): Promise<CreateAdverseEventQueryResponse> {
  const requestBody: CreateAdverseEventQueryRequest = {
    adverseEventId,
    query,
  };

  const response: CreateAdverseEventQueryResponse = await jsonPost(
    '/adverseevent/createQuery',
    requestBody
  );
  return response;
}

export async function resolveQuery(
  adverseEventId: number,
  queryId: number,
  queryResponse: string
): Promise<ResolveAdverseEventQueryResponse> {
  const requestBody: ResolveAdverseEventQueryRequest = {
    adverseEventId,
    queryId,
    response: queryResponse,
  };

  const response: ResolveAdverseEventQueryResponse = await jsonPost(
    '/adverseevent/resolveQuery',
    requestBody
  );
  return response;
}

export async function getMedwatchS3Link(
  adverseEventId: number,
  formResponseId?: number
): Promise<GetMedwatchLinkResponse> {
  const requestBody: GetMedwatchLinkRequest = {
    adverseEventId,
    formResponseId,
  };

  const response: GetMedwatchLinkResponse = await jsonPost(
    '/adverseevent/getMedwatchLink',
    requestBody
  );
  return response;
}

export async function saveMedwatchFormDraft(
  adverseEventId: number,
  data: Object
): Promise<SubmitMedwatchFormResponse> {
  const requestBody: SubmitMedwatchFormRequest = {
    adverseEventId,
    data: { ...data },
  };

  const response: SubmitMedwatchFormResponse = await jsonPost(
    '/adverseevent/saveMedwatchForm',
    requestBody
  );
  return response;
}
