import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { InjectedProps, withQueryResult } from '../hocs/WithQueryResult';
import {
  checkEmail,
  removeRoleFromUser,
} from '../../controllers/authController';
import Subheader from '../basic/Subheader';
import ListItem from '../basic/ListItem';
import DynamicForm from '../basic/DynamicForm';
import AlertInDialog from '../basic/AlertInDialog';
import Dialog from '../basic/SafeDialog';
import DialogHeader from '../basic/DialogHeader';
import { generateAccessCodeForProvider } from '../../controllers/accessCodeController';
import { addProviderToClinic } from '../../controllers/providerController';
import { getEmailValidationError } from '@curebase/core/lib/validators';
import { currentUserHasPermission } from '../../lib/auth';
import {
  InvestigatorList,
  InvestigatorListQuery,
  InvestigatorListQueryVariables,
  RolePermissions,
  User,
} from '@curebase/core/types';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import PermissionedButton from '../basic/PermissionedButton';
import { capitalizeDisplayName } from 'src/lib/ui';
import { UserRoleType } from 'src/types';

interface Props
  extends InjectedProps<InvestigatorListQueryVariables, InvestigatorListQuery>,
    WithTranslation {
  clinicId: string;
}

type State = {
  inviteData: { email: string | null; providerUserType: UserRoleType | null };
  showInviteUserDialog: boolean;
  showInviteSentDialog: boolean;
  selectedUserToRemove: User | null;
  showRemoveUserDialog: boolean;
  inviteUserErrorMessage: string | null | undefined;
};

class InvestigatorListView extends React.Component<Props, State> {
  state = {
    inviteData: { email: null, providerUserType: null },
    showInviteUserDialog: false,
    showInviteSentDialog: false,
    selectedUserToRemove: null,
    showRemoveUserDialog: false,
    inviteUserErrorMessage: null,
  };

  inviteUser = async () => {
    const { t, data } = this.props;
    const clinicId = this.props.queryResult.getClinic?.id;
    const email = this.state.inviteData.email;
    const userRoleType = this.state.inviteData.providerUserType;

    if (!email) {
      throw new Error('No email address was provided');
    }

    if (!userRoleType) {
      throw new Error('No user role was selected');
    }

    const { isAvailable } = await checkEmail(email);
    try {
      if (isAvailable) {
        if (clinicId) {
          const response = await generateAccessCodeForProvider(
            clinicId,
            email,
            userRoleType
          );
          // @ts-ignore
          if (response.error) throw new Error(response.message);
          await data.refetch();
        }
      } else {
        if (clinicId) {
          const response = await addProviderToClinic(clinicId, email);
          // @ts-ignore
          if (response.error) throw new Error(response.message);
          await data.refetch();
        }
      }
    } catch (e) {
      this.setState({
        inviteUserErrorMessage: t(
          `siteSettings.inviteDialog.errors.${e.message}`
        ),
      });
    }
  };

  getConfirmationBodyToRemoveUser = () => {
    const { t, queryResult } = this.props;
    const clinicName = queryResult.getClinic?.name;
    const user = this.state.selectedUserToRemove as any;
    return `${t('siteSettings.removeUserDialog.body', {
      userRoles: this.getUserRoleName(user, t),
      userDisplayName: `${_.startCase(
        user?.displayName
      )} (${this.getEmailDisplayName(user?.email)})`,
      clinicDisplayName: clinicName,
    })}`;
  };

  removeUserAsync = async () => {
    const { data } = this.props;
    const user = this.state.selectedUserToRemove as any;
    await removeRoleFromUser(
      user?.roles[0].type,
      user?.id,
      null,
      null,
      this.props.queryResult.getClinic?.id
    );
    await data.refetch();
    this.setState({ showRemoveUserDialog: false, selectedUserToRemove: null });
  };

  getUserRoleName = (user, t: any) => {
    return (
      user?.roles?.map(r => t(`userRoleDisplayNames.${r.type}`))?.join(', ') ??
      ''
    );
  };

  getEmailDisplayName = (email: string | null | undefined) => {
    const { t } = this.props;
    return !_.isEmpty(email)
      ? email
      : t('siteSettings.teamMembers.noEmailFound');
  };

  getConfirmationTitleToRemoveUser = () => {
    const { t } = this.props;
    return `${t('siteSettings.removeUserDialog.title', {
      userRoles: this.getUserRoleName(this.state.selectedUserToRemove, t),
    })}`;
  };

  render() {
    const { t, data, queryResult } = this.props;
    const clinic = queryResult.getClinic;
    const members = clinic?.memberships ?? [];
    const accessCodes = _.filter(
      clinic?.accessCodes ?? [],
      accessCode => !members.find(m => m.email === accessCode?.email)
    );

    return (
      <>
        <Subheader
          text={t('siteSettings.teamMembersSubheader')}
          buttons={
            !currentUserHasPermission(RolePermissions.EditInvestigatorList)
              ? undefined
              : [
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() =>
                      this.setState({ showInviteUserDialog: true })
                    }
                  >
                    {t('siteSettings.teamMembers.inviteButton')}
                  </Button>,
                ]
          }
        />
        <div className='li-multi-container inset'>
          {members.map((user, i) => (
            <ListItem
              key={i}
              middle={{
                title: capitalizeDisplayName(user, t),
                subtitle: this.getEmailDisplayName(user.email),
                text: this.getUserRoleName(user, t),
                buttons: [
                  <PermissionedButton
                    key={i}
                    disabled={false}
                    permission={RolePermissions.LegacyAssociates}
                    variant='text'
                    buttonText={t('siteSettings.teamMembers.removeButton')}
                    onClick={() => {
                      this.setState({
                        selectedUserToRemove: user as User,
                        showRemoveUserDialog: true,
                      });
                    }}
                  />,
                ],
              }}
            />
          ))}
          {_.filter(
            accessCodes,
            accessCode => !_.find(members, m => m.email === accessCode?.email)
          ).map((accessCode, i) => (
            <ListItem
              key={i}
              middle={{
                title: accessCode?.email,
                text: `Invitation sent, expires ${moment(
                  accessCode?.expiresOn
                ).fromNow()}`,
              }}
            />
          ))}
          {members.length === 0 && accessCodes.length === 0 && (
            <ListItem
              middle={{
                title: 'No providers in this clinic.',
              }}
            />
          )}
        </div>
        <Dialog
          open={this.state.showInviteUserDialog}
          onClose={() => this.setState({ showInviteUserDialog: false })}
        >
          <DialogHeader
            title={t('siteSettings.inviteDialog.title')}
            onClose={() => this.setState({ showInviteUserDialog: false })}
          />
          <DynamicForm
            pages={[
              {
                elements: [
                  {
                    title: t('siteSettings.inviteDialog.emailTitle'),
                    subElements: [
                      {
                        type: 'TEXT',
                        key: 'email',
                        skipValidateOnChange: true,
                        validate: (v: string) => {
                          return getEmailValidationError(v, t);
                        },
                      },
                    ],
                  },
                  {
                    title: t('siteSettings.inviteDialog.userTypeTitle'),
                    subElements: [
                      {
                        key: 'providerUserType',
                        type: 'DROPDOWN',
                        options: [
                          {
                            text: t('userRoleDisplayNames.PHYSICIAN'),
                            value: UserRoleType.Physician,
                          },
                          {
                            text: t('userRoleDisplayNames.NURSE'),
                            value: UserRoleType.Nurse,
                          },
                          {
                            text: t(
                              'userRoleDisplayNames.MEDICAL_ADMINISTRATOR'
                            ),
                            value: UserRoleType.MedicalAdministrator,
                          },
                        ],
                        additionalSettings: {
                          formatAsId: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ]}
            onChange={(key, value) => {
              this.setState({
                inviteData: {
                  ...this.state.inviteData,
                  [key]: value,
                },
                inviteUserErrorMessage: null,
              });
            }}
            onSubmit={async () => {
              await this.inviteUser();
            }}
            data={this.state.inviteData}
            errors={
              !!this.state.inviteUserErrorMessage
                ? {
                    email: this.state.inviteUserErrorMessage,
                  }
                : undefined
            }
          />
        </Dialog>
        <Dialog
          open={this.state.showInviteSentDialog}
          onClose={async () => await data.refetch()}
        >
          <AlertInDialog
            title={t('siteSettings.inviteSentDialog.title')}
            message={t('siteSettings.inviteSentDialog.text')}
            onConfirm={async () => await data.refetch()}
          />
        </Dialog>
        <Dialog open={this.state.showRemoveUserDialog}>
          <ConfirmationInDialog
            title={this.getConfirmationTitleToRemoveUser()}
            message={this.getConfirmationBodyToRemoveUser()}
            confirmationText={t(
              'researchRoles.confirmationDialog.confirmButtonText'
            )}
            closeText={t('researchRoles.confirmationDialog.closeButtonText')}
            onConfirm={async () => await this.removeUserAsync()}
            onClose={() => this.setState({ showRemoveUserDialog: false })}
          />
        </Dialog>
      </>
    );
  }
}

const InvestigatorListComponent: React.ComponentType<any> = withQueryResult(
  InvestigatorListView,
  InvestigatorList,
  (props: any): InvestigatorListQueryVariables => ({
    clinicId: props.clinicId,
  })
);

export default withTranslation('translations')(InvestigatorListComponent);
