import { Typography } from '@material-ui/core';
import React from 'react';

export const DocumentHeaderTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant='h2' className='df-header-text'>
      {title}
    </Typography>
  );
};
