import React, { useState, useContext, useEffect } from 'react';
import { MeetingSession } from 'amazon-chime-sdk-js';
import MicIcon from '@material-ui/icons/Mic';
import { useSelector } from 'react-redux';
import { CallOverlayMode, VideoStatus } from './AudioVideo';
import MicOffIcon from '@material-ui/icons/MicOff';
import { useDispatch } from 'react-redux';
import { midWidthForMobileView } from './MessageCenterPanel';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CallEndOutlinedIcon from '@material-ui/icons/CallEndOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import TelemedIndicator from '../../context/mainTelemedRoomOpenContext';
import { DeviceType } from '../../lib/chime';
import { isMobile } from 'react-device-detect';

import chimeContext from '../../context/getChimeContext';
import useRoles from 'src/hooks/useRoles';
import { userIsSponsor } from '@curebase/core/lib/user';

type Props = {
  photoBoothButtonDisabled?: boolean;
  meetingSession: MeetingSession;
  hasJoined: boolean;
  selectedVideoDevice?: DeviceType;
  onJoin: (withMedia: boolean) => void;
  showSettings: (mode: CallOverlayMode) => void;
  showPreview: boolean;
  flipPreview: () => void;
  previewVideoRef: any;
  nonParticipantPhotoboothCapture?: () => Promise<void>;
};

export const useStyles = makeStyles({
  button: {
    borderRadius: '100%',
    minWidth: 0,
    padding: '6px',
  },
  joinButton: {
    borderRadius: '20px 20px 20px 20px',
    width: '125px',
  },
  disabledButton: {
    opacity: '50%',
  },
  telehealthUploadDelete: {
    paddingLeft: '10px',
  },
});

const VideoToggleButton = (
  ownProps: { onClick: () => void; videoStatus: VideoStatus } & Props
) => {
  const chime = useContext(chimeContext);
  const style = useStyles();
  const {
    hasJoined,
    flipPreview,
    meetingSession,
    videoStatus,
    showPreview,
    selectedVideoDevice,
    previewVideoRef,
  } = ownProps;
  const onClick = async () => {
    if (hasJoined) {
      await chime.flipLocalVideoTile();
      ownProps.onClick();
    } else {
      if (showPreview) {
        meetingSession.audioVideo.stopVideoPreviewForVideoInput(
          previewVideoRef
        );
      } else {
        await meetingSession.audioVideo.chooseVideoInputDevice(
          selectedVideoDevice!.value
        );
        meetingSession.audioVideo.startVideoPreviewForVideoInput(
          previewVideoRef
        );
      }
      flipPreview();
    }
  };

  const videoOn =
    (hasJoined && videoStatus !== VideoStatus.VideoOff) ||
    (!hasJoined && showPreview);

  return (
    <Button
      className={`${style.button} telemed-button ${videoOn ? '' : 'off'}`}
      onClick={onClick}
    >
      {videoOn ? (
        <VideocamOutlinedIcon className='control-icon' />
      ) : (
        <VideocamOffOutlinedIcon className='control-icon' />
      )}
    </Button>
  );
};

const JoinedControls = (props: Props) => {
  const style = useStyles();
  const dispatch = useDispatch();
  const { meetingSession, hasJoined } = props;
  const forceUpdate = useForceUpdate();
  const chime = useContext(chimeContext);
  const [videoStatus, setVideoStatus] = useState<VideoStatus>(
    VideoStatus.Loading
  );
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const muted = meetingSession.audioVideo.realtimeIsLocalAudioMuted();
  chime.subscribeToVideoFlip((localVideoStatus: VideoStatus) =>
    setVideoStatus(localVideoStatus)
  );

  return (
    <>
      {hasJoined && (
        <Button
          className={`${style.button} telemed-button end-call`}
          style={{ backgroundColor: '#ff3823' }}
          onClick={() => {
            meetingSession.audioVideo.stop();
          }}
        >
          <CallEndOutlinedIcon />
        </Button>
      )}
      <Button
        className={`${style.button} telemed-button ${muted ? 'off' : ''}`}
        onClick={() => {
          muted
            ? meetingSession.audioVideo.realtimeUnmuteLocalAudio()
            : meetingSession.audioVideo.realtimeMuteLocalAudio();

          forceUpdate();
        }}
      >
        {muted ? (
          <MicOffIcon className='control-icon' />
        ) : (
          <MicIcon className='control-icon' />
        )}
      </Button>

      <VideoToggleButton
        onClick={forceUpdate}
        {...props}
        videoStatus={videoStatus}
      />

      <Button
        className={`${style.button} telemed-button`}
        onClick={() => {
          props.showSettings(CallOverlayMode.DeviceChange);
          forceUpdate();
        }}
      >
        <MoreVertIcon className='control-icon' />
      </Button>

      {hasJoined && (
        <Button
          className={`${style.button} telemed-button`}
          disabled={props.photoBoothButtonDisabled || isUploadingFile}
          classes={{ disabled: style.disabledButton }}
          onClick={async () => {
            if (props.nonParticipantPhotoboothCapture) {
              setIsUploadingFile(true);
              await props.nonParticipantPhotoboothCapture();
              setIsUploadingFile(false);
            } else {
              if (isMobile) {
                chime.stopLocalVideoTile();
                dispatch({
                  type: 'SET_PHOTO_MODE',
                  payload: { addPhotos: true },
                });
              } else {
                props.showSettings(CallOverlayMode.PhotoboothMenu);
              }
            }
          }}
        >
          <AddAPhotoIcon className='control-icon' />
        </Button>
      )}

      {!hasJoined && (
        <Button
          className={`${style.joinButton} join-button`}
          onClick={() => {
            props.onJoin(true);
          }}
        >
          Join
        </Button>
      )}
    </>
  );
};

export function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

export const useControlPanelMargin = () => {
  const { value: telemedMeetingRoomMounted } = useContext(TelemedIndicator);
  const { signatureMountStatus } = useSelector(
    (store: any) => store.telehealth
  );
  const matches = useMediaQuery(midWidthForMobileView);

  let marginBottom = '0px';
  const notMobileView = !(matches && !telemedMeetingRoomMounted);
  if (notMobileView) {
    if (signatureMountStatus) {
      marginBottom = '130px';
    } else {
      marginBottom = '35px';
    }
  }
  return marginBottom;
};

const AudioVideoControlBar = (props: Props) => {
  const {
    value: telemedMeetingRoomMounted,
    update: toggleMountedStatus,
  } = useContext(TelemedIndicator);
  const marginBottom = useControlPanelMargin();
  const roles = useRoles();
  const isSponsorSpectator = userIsSponsor(roles as any);

  useEffect(() => {
    // making it so sponsors start with the telemed room maximized.
    if (isSponsorSpectator) {
      toggleMountedStatus(true);
    }
  }, []);

  return (
    <div
      className='small-screen-wrapper'
      style={{
        marginBottom,
      }}
    >
      <div className='options'>
        <JoinedControls {...props} />
      </div>
      <Button
        className='app-nav-small-screen'
        onClick={() => {
          toggleMountedStatus(!telemedMeetingRoomMounted);
        }}
      >
        <MeetingRoomIcon />
        {`${telemedMeetingRoomMounted ? 'Minimize video' : 'Maximize Call'}`}
      </Button>
    </div>
  );
};

export default AudioVideoControlBar;
