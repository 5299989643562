import { BlockedParticipantsResponse } from '@curebase/core/decoders/trials';
import { jsonPost } from '../lib/fetchHelpers';

export async function sendAppDownloadLinkViaSms(
  phoneNumber: string
): Promise<BlockedParticipantsResponse> {
  return await jsonPost('/engage/send-app-download-link', {
    phoneNumber,
  });
}

export async function setDownloadAppAcitivtyDone(
  trialOptionId: number
): Promise<BlockedParticipantsResponse> {
  return await jsonPost('/engage/set-app-download-done', {
    trialOptionId,
  });
}

export async function setPlutoAcitivtyDone(
  trialOptionId: number
): Promise<BlockedParticipantsResponse> {
  return await jsonPost('/engage/set-pluto-done', {
    trialOptionId,
  });
}
