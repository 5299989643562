import { EnvironmentType, ENVIRONMENT_TYPE } from '@curebase/core/lib/env';
import { makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { getThemeBasedOnEnvironmentType } from 'src/styles/themes';
import TestModeIndicator from '../basic/TestModeIndicator';
import { withBreakpoints } from '../hocs/WithBreakpoints';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import LoginMagicLink from './LoginMagicLink';
import ResetPassword from './ResetPassword';
import SignupWithAccessCode from './SignupWithAccessCode';
interface AuthProps {
  classes: any;
  tabletSizedScreen: boolean;
}

const theme = getThemeBasedOnEnvironmentType();

const customStyles = {
  [EnvironmentType.WALGREENS]: makeStyles({
    'page-container': {
      '& .auth-container-left': {
        backgroundColor: '#F9F9F6 !important',
        '& .auth-container-left-p': {
          color: `${theme.texts?.body} !important`,
        },
        '& .auth-container-left-title': {
          '& img': {
            maxWidth: 190,
          },
          backgroundColor: 'transparent !important',
        },
      },
      '& .auth-container-right': {
        '& .auth-right-link, a': {
          '&:hover': {
            color: `${theme.texts?.hover} !important`,
          },
        },
      },
    },
  }),
};

function Auth(props: AuthProps) {
  const { tabletSizedScreen } = props;
  const { t } = useTranslation('translations');
  const styles = customStyles[ENVIRONMENT_TYPE]?.() || {};

  const logo = useMemo(() => {
    if (ENVIRONMENT_TYPE === EnvironmentType.WALGREENS)
      return <img src={theme.logo?.default} alt={'Walgreens logo'} />;

    return 'Curebase';
  }, [theme]);

  return (
    <div className={`page-container ${styles['page-container']}`}>
      <div className='view-container'>
        <div className='auth-page'>
          <div className='auth-container'>
            <div className={`auth-container-left`}>
              <div className='auth-container-left-header'>
                <div className='auth-container-left-title'>{logo}</div>
                <div className='auth-container-left-indicator'>
                  <TestModeIndicator />
                </div>
              </div>

              {!tabletSizedScreen && (
                <>
                  <div className='auth-container-left-p-wrapper'>
                    <div className='auth-container-left-p'>
                      {t('auth.main.line1')}
                    </div>
                    <div className='auth-container-left-p'>
                      {t('auth.main.line2')}
                    </div>
                  </div>
                  {ENVIRONMENT_TYPE === EnvironmentType.WALGREENS && (
                    <div className='powered-by'>
                      <span>{t('auth.main.powered_by')}</span>
                      <span>Curebase</span>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className='auth-container-right'>
              <Switch>
                <Route exact path='/auth/login' component={Login} />
                <Route
                  exact
                  path='/auth/sessionexpired'
                  // @ts-ignore
                  render={() => <Login sessionExpired />}
                />
                <Route
                  exact
                  path='/auth/signup/:accessCode'
                  component={SignupWithAccessCode}
                />
                <Route
                  exact
                  path='/auth/forgot-password'
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path='/auth/magic-link'
                  component={LoginMagicLink}
                />
                <Route
                  exact
                  path='/auth/reset-password/:email/:passwordResetHash'
                  component={ResetPassword}
                />
                <Route render={() => <Redirect to='/auth/login' />} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withBreakpoints(Auth as any);
