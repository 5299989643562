import { useRef, useEffect } from 'react';

//https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function (callback: () => any, delay: number | null) {
  const currentCallback: any = useRef();

  useEffect(() => {
    currentCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      currentCallback.current();
    }

    if (delay !== null) {
      let interval = setInterval(tick, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
}
