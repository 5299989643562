import * as React from 'react';
import Dialog from './SafeDialog';
import DialogHeader from './DialogHeader';

type Props = {
  open: boolean;
  children: any;
  title: string;
  onClose: () => any;
};

const DialogWithChildren = (props: Props) => {
  const { open, title, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <div className='li-multi-container'>{props.children}</div>
    </Dialog>
  );
};

export default DialogWithChildren;
