import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeDisplayName } from '../../lib/ui';
import {
  SourceDataVerificationEventType,
  useSourceDataVerificationLogsQuery,
} from '../../types';
import Dialog from '../basic/SafeDialog';
import DialogHeader from '../basic/DialogHeader';
import moment from 'moment';

export const SourceDataVerificationLog = ({
  open,
  onClose,
  caseReportInstanceId,
}) => {
  const { t } = useTranslation('translations');
  const { data, loading } = useSourceDataVerificationLogsQuery({
    fetchPolicy: 'network-only',
    variables: {
      caseReportInstanceId,
    },
  });
  const isLoading = !data || loading;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        title={t('dataCaptureResultsView.sdvLogTitle')}
        onClose={onClose}
      />

      {isLoading && (
        <>
          <Skeleton animation='wave' />
          <Skeleton animation='wave' />
          <Skeleton animation='wave' />
          <Skeleton animation='wave' />
          <Skeleton animation='wave' />
        </>
      )}

      {!isLoading && (
        <ul>
          {data?.getCaseReportInstance?.sourceDataVerifications?.map(
            sourceDataVerification => {
              const sdvStatusText =
                sourceDataVerification?.eventType ===
                SourceDataVerificationEventType?.Verified
                  ? t('dataCaptureResultsView.verified')
                  : t('dataCaptureResultsView.unVerified');

              return (
                <li style={{ marginRight: 50 }}>
                  <p>
                    <strong>{sdvStatusText.toUpperCase()}</strong>{' '}
                    {t('dataCaptureResultsView.by')}{' '}
                    <strong>
                      {capitalizeDisplayName({
                        displayName: sourceDataVerification?.userDisplayName,
                      })}
                    </strong>{' '}
                    {sourceDataVerification?.dataChange?.id
                      ? t('dataCaptureResultsView.dataChangeReason')
                      : ''}
                    {sourceDataVerification?.dataQuery?.id
                      ? t('dataCaptureResultsView.dataQueryReason')
                      : ''}
                  </p>
                  <p style={{ color: 'darkgray' }}>
                    {moment(sourceDataVerification?.createdAt).toLocaleString()}
                  </p>
                </li>
              );
            }
          )}
        </ul>
      )}
    </Dialog>
  );
};
