import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dataFieldToSubElement } from 'src/lib/caseReports';
import {
  useGetTrialPrescreeningSurveyQuery,
  OptionalityVisibility,
} from 'src/types';
import {
  CreateMockTrialOptionsDialog,
  CreateMockTrialOptionsDialogQuery,
  CreateMockTrialOptionsDialogQueryVariables,
} from '@curebase/core/types';
import { createMockTrialOptions } from '../../controllers/mockTrialOptionsController';
import { getSiteOptions } from '../../lib/clinicSubElements';
import { getAvailableStatesForMachine } from '@curebase/core/lib/mockTrialOptions';
import { showAlertMessage } from '../../store/actions';
import DialogHeader from '../basic/DialogHeader';
import DynamicForm, { ElementProps, GenericOption } from '../basic/DynamicForm';
import { withQueryResult } from '../hocs/WithQueryResult';
import Loading from '../Loading';
import { StatusColor } from 'src/shared/lib/colors';
import Analytics, {
  AnalyticsEventProperty,
  AnalyticsEventType,
} from '../Analytics/Analytics';

type VisitSite = {
  id: number;
  name: string;
};

type Clinic = {
  id: number;
  name: string;
  visitSites: Array<VisitSite>;
};

type TrialInstance = {
  id: number;
  clinic: Clinic;
};

type StratificationCriteriaSubset = {
  name: string;
  values: Array<string>;
  caseReportSlug: string;
  dataFieldSuperSlug: string;
  studyPlanVisitSlug: string;
};

type RandomizationConfig = {
  stratificationCriteria: Array<StratificationCriteriaSubset>;
  dataFieldsForAutoGen: any;
};

type Trial = {
  id: number;
  name: string;
  slug: string;
  trialIdentifier: string;
  machine: Object;
  randomizationConfig: RandomizationConfig;
  instances: Array<TrialInstance>;
};

type Props = {
  onClose: () => void;
  queryResult: CreateMockTrialOptionsDialogQuery;
};

type FormData = {
  count: number;
  clinicId: string;
  trialSlug: string;
  targetState: string;
  namePrefix: string;
  visitSiteId: number;
  executeSynchronous: string;
};

const appendRandomizationElements = (
  randomizationConfig: RandomizationConfig | null,
  createParticipantElements: Array<ElementProps>,
  spv: any
) => {
  if (randomizationConfig && randomizationConfig.stratificationCriteria) {
    const { dataFieldsForAutoGen } = randomizationConfig;
    if (dataFieldsForAutoGen && dataFieldsForAutoGen.length > 0 && spv) {
      const dataFields = spv.caseReports.flatMap(cr =>
        cr.dataFields.filter(df => dataFieldsForAutoGen.includes(df.superSlug))
      );
      createParticipantElements.push(
        ...dataFields.map(df => {
          const dynamicFormDataField = dataFieldToSubElement(df, {
            optionalityVisibility: OptionalityVisibility.ShowAsterix,
          });
          dynamicFormDataField.subElements[0].key = df.superSlug;
          return dynamicFormDataField;
        })
      );
    } else {
      const subsetElements = randomizationConfig.stratificationCriteria.map(
        criterion => {
          const options: Array<GenericOption> = criterion.values.map(option => {
            return {
              text: option,
              value: option,
            };
          });
          return {
            title: criterion.name,
            subElements: [
              {
                key: criterion.name,
                type: 'DROPDOWN',
                options: options,
              },
            ],
          };
        }
      );
      // @ts-expect-error
      createParticipantElements.push(...subsetElements);
    }
  }
};

const appendClinicElements = (
  createParticipantElements: Array<ElementProps>,
  clinics: Array<Clinic>,
  clinic: Clinic | undefined
) => {
  createParticipantElements.push(
    getSiteOptions('clinicId', 'Research Team', clinics)
  );

  if (clinic && clinic.visitSites.length > 1) {
    createParticipantElements.push(
      getSiteOptions('visitSiteId', 'Research Site', clinic.visitSites)
    );
  }
};

const generateCriterionSlugAndValue = (
  customDataCapture: any,
  foundCriterion: StratificationCriteriaSubset | undefined,
  value: any
) => {
  if (!foundCriterion) return;

  customDataCapture[foundCriterion.dataFieldSuperSlug] = value;
};

const appendRandomizationData = (
  randomizationConfig: RandomizationConfig | undefined,
  customDataCapture: any,
  formData: FormData
) => {
  if (!randomizationConfig || !randomizationConfig.stratificationCriteria) {
    return;
  }

  const { dataFieldsForAutoGen } = randomizationConfig;
  for (const [key, value] of Object.entries(formData)) {
    if (dataFieldsForAutoGen && dataFieldsForAutoGen.length > 0) {
      if (dataFieldsForAutoGen.includes(key)) customDataCapture[key] = value;
    } else {
      const foundCriterion:
        | StratificationCriteriaSubset
        | undefined = randomizationConfig.stratificationCriteria.find(
        criterion => criterion.name === key
      );

      generateCriterionSlugAndValue(customDataCapture, foundCriterion, value);
    }
  }
};

const updatedFormData = (formData: FormData, trials: ReadonlyArray<Trial>) => {
  let clinics: Array<Clinic> = [];
  let randomizationConfig;
  if (formData.trialSlug) {
    const trial: Trial | undefined = trials.find(
      trial => trial.slug === formData.trialSlug
    );

    if (trial) {
      clinics = trial.instances.map(instance => instance.clinic);
      randomizationConfig = trial.randomizationConfig;
    }
  }
  return { clinics, randomizationConfig };
};

const handleResponse = (response, history, formData) => {
  if (response && !response.error) {
    // Event for when a user autogenerates participants
    Analytics.track(
      AnalyticsEventType.PARTICIPANT_LIST_AUTOGENERATE_PARTICIPANT,
      {
        [AnalyticsEventProperty.PARTICIPANTS_AUTOGENERATED]:
          formData?.count || '',
        [AnalyticsEventProperty.STUDY_NAME]:
          formData?.trialSlug.toUpperCase() || '',
      }
    );
  }
  if (response && response.trialOptionIds > 0) {
    history.push(`/u/participants/${response.trialOptionIds[0]}`);
    return;
  }
  if (response && response.queuedCount > 0) {
    showAlertMessage(
      `Successfully queued ${response.queuedCount} participants. You will be notified via your email when the mocking completes.`,
      StatusColor.Green
    );
  }

  if (response.error) {
    showAlertMessage(response.error, StatusColor.Red);
  }
};

const CreateMockTrialOptionsDialogView = ({ onClose, queryResult }: Props) => {
  const [formData, setFormData] = useState<any>({
    executeSynchronous: 'NO',
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const trials = queryResult.getTrials ?? [];
  const { trialSlug } = formData;
  // TODO: Make sure that the correct trialConfiguration is being selected here - previously
  // this same behavior of selecting just one was occurring but just on the backend instead.
  const machine =
    trials.find(t => t.slug === trialSlug)?.trialConfigurations[0].machine ??
    {};
  const stateOptions = getAvailableStatesForMachine(machine);
  //@ts-ignore
  const { clinics, randomizationConfig } = updatedFormData(formData, trials);
  const clinic: Clinic | undefined = clinics.find(
    c => c.id === formData.clinicId
  );

  const { data } = useGetTrialPrescreeningSurveyQuery({
    skip: !trialSlug,
    variables: { trialSlug },
  });

  const submitDataCustom = async () => {
    setLoading(true);

    const customDataCapture = {};
    appendRandomizationData(randomizationConfig, customDataCapture, formData);

    const response = await createMockTrialOptions(
      formData.trialSlug,
      formData.targetState,
      formData.namePrefix,
      parseInt(formData.count),
      formData.clinicId,
      formData.visitSiteId,
      formData.executeSynchronous === 'YES',
      JSON.stringify(customDataCapture)
    );

    handleResponse(response, history, formData);

    setLoading(false);
    onClose();
  };

  const formattedTrials: Array<GenericOption> = trials
    .map(trial => ({
      text: trial.name,
      value: trial.slug,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const stateDropdownOptions: Array<GenericOption> = stateOptions.map(
    state => ({
      text: state,
      value: state,
    })
  );

  const createParticipantElements = [
    {
      title: 'Trial',
      subElements: [
        {
          key: 'trialSlug',
          type: 'AUTOCOMPLETE',
          options: formattedTrials,
          additionalSettings: {
            formatAsId: true,
          },
        },
      ],
    },
    {
      title: 'Target State',
      subElements: [
        {
          key: 'targetState',
          type: 'AUTOCOMPLETE',
          options: stateDropdownOptions,
          additionalSettings: {
            formatAsId: true,
          },
        },
      ],
    },
    {
      title: 'Participant Name Prefix',
      subElements: [
        {
          key: 'namePrefix',
          type: 'TEXT',
        },
      ],
    },
    {
      title: 'Number of Participants to Create',
      subElements: [
        {
          key: 'count',
          type: 'NUMBER',
        },
      ],
    },
    {
      title: 'Execute this creation synchronously?',
      subElements: [
        {
          key: 'executeSynchronous',
          type: 'YES_NO',
        },
      ],
    },
  ];

  appendRandomizationElements(
    randomizationConfig,
    // @ts-expect-error
    createParticipantElements,
    data?.getTrial?.prescreeningSurvey
  );
  // @ts-expect-error
  appendClinicElements(createParticipantElements, clinics, clinic);

  return (
    <>
      <DialogHeader
        title='Generate Participants'
        onClose={() => {
          onClose();
        }}
      />
      {loading && <Loading />}
      {!loading && (
        <DynamicForm
          pages={[{ elements: createParticipantElements }]}
          onChange={(key, value) => {
            const data: any = formData;

            //if the user re-selects a trial, clear the visit site details
            if (key === 'trialSlug') {
              data['visitSiteId'] = undefined;
              data['clinicId'] = undefined;
            }

            //if the chosen clinic has only 1 visit site, default to that
            if (key === 'clinicId') {
              const clinic = clinics.find(clinic => clinic.id === value);
              if (clinic && clinic.visitSites.length === 1) {
                data['visitSiteId'] = clinic.visitSites[0].id;
              } else {
                data['visitSiteId'] = undefined;
              }
            }

            setFormData({ ...data, [key]: value });
          }}
          data={formData}
          onSubmit={() => submitDataCustom()}
        />
      )}
    </>
  );
};

const CreateMockTrialOptionsDialogComponent: React.ComponentType<any> = withQueryResult(
  CreateMockTrialOptionsDialogView,
  CreateMockTrialOptionsDialog,
  (props: any): CreateMockTrialOptionsDialogQueryVariables => ({})
);

export default CreateMockTrialOptionsDialogComponent;
