import * as React from 'react';
import moment from 'moment-timezone';
import BoldList from '../basic/BoldList';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import Maybe from 'graphql/tsutils/Maybe';

export type EnrollmentProperties = {
  //following two fields are only undefined when creating mock participants
  enrollerFirstName: Maybe<string>;
  enrollerLastName: Maybe<string>;
  enrolledOn: string;
  signatureFileKey: Maybe<string>;
  timeSigned: Maybe<string>;
  nameSigned: Maybe<String>;
};

export const EnrollmentResults = (props: {
  enrollmentDetails: EnrollmentProperties;
}) => {
  const {
    enrollerFirstName,
    enrollerLastName,
    enrolledOn,
    signatureFileKey,
    timeSigned,
    nameSigned,
  } = props.enrollmentDetails;
  const timezone = moment.tz.guess();

  type RenderList = {
    title: string;
    value: any;
  };

  const listToRender: Array<RenderList> = [
    {
      title: 'Enrolled By',
      value: `${enrollerFirstName} ${enrollerLastName}`,
    },
    {
      title: 'Enrolled On',
      value: moment(enrolledOn).tz(timezone).format('LLL z'),
    },
  ];

  if (signatureFileKey) {
    listToRender.push(
      {
        title: 'Signature',
        value: (
          <div>
            <img
              className='signature-image'
              src={secureImageUriFromFileName(signatureFileKey)}
              alt='signature'
            />
          </div>
        ),
      },
      { title: 'Name on signature', value: nameSigned },
      { title: 'Date on signature', value: timeSigned }
    );
  }

  return (
    <div className='enrollment-results'>
      <div className='enrollment-left' />
      <div className='enrollment-right'>
        <BoldList list={listToRender} />
      </div>
    </div>
  );
};
