import {
  AudioVideoFacade,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  DeviceChangeObserver,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
  DefaultModality,
} from 'amazon-chime-sdk-js';
import throttle from 'lodash/throttle';
import { VideoStatus } from '../components/Telemed/AudioVideo';
import { joinRoom, getAttendeeName } from '../controllers/telemedController';

export type DeviceType = {
  label: string;
  value: string;
};

export type RegionType = {
  label: string;
  value: string;
};

export type RosterType = {
  [attendeeId: string]: {
    muted?: boolean;
    signalStrength?: number;
    name?: string;
  };
};

export default class ChimeSdkWrapper implements DeviceChangeObserver {
  meetingSession: MeetingSession | null = null;

  audioVideo: AudioVideoFacade | null = null;

  title: string | null = null;

  name: string | null = null;

  region: string | null = null;

  configuration: MeetingSessionConfiguration | null = null;

  currentAudioInputDevice: DeviceType | null = null;

  currentAudioOutputDevice: DeviceType | null = null;

  currentVideoInputDevice: DeviceType | null = null;

  audioInputDevices: DeviceType[] = [];

  audioOutputDevices: DeviceType[] = [];

  videoInputDevices: DeviceType[] = [];

  deviceChangeCallbacks: Record<
    'audio' | 'video',
    Array<(selectedDevice?: DeviceType) => void>
  > = {
    audio: [],
    video: [],
  };

  roster: RosterType = {};

  rosterUpdateCallbacks: ((roster: RosterType) => void)[] = [];

  localVideoFlipCallbacks: ((localVideoStatus: VideoStatus) => void)[] = [];

  initializeSdkWrapper = async () => {
    this.meetingSession = null;
    this.audioVideo = null;
    this.title = null;
    this.name = null;
    this.currentAudioInputDevice = null;
    this.currentAudioOutputDevice = null;
    this.currentVideoInputDevice = null;
    this.audioInputDevices = [];
    this.audioOutputDevices = [];
    this.roster = {};
    this.rosterUpdateCallbacks = [];
    this.localVideoFlipCallbacks = [];
    this.deviceChangeCallbacks = { audio: [], video: [] };
    this.videoInputDevices = [];
  };

  supportedChimeRegions: RegionType[] = [
    { label: 'United States (N. Virginia)', value: 'us-east-1' },
    { label: 'Japan (Tokyo)', value: 'ap-northeast-1' },
    { label: 'Singapore', value: 'ap-southeast-1' },
    { label: 'Australia (Sydney)', value: 'ap-southeast-2' },
    { label: 'Canada', value: 'ca-central-1' },
    { label: 'Germany (Frankfurt)', value: 'eu-central-1' },
    { label: 'Sweden (Stockholm)', value: 'eu-north-1' },
    { label: 'Ireland', value: 'eu-west-1' },
    { label: 'United Kingdom (London)', value: 'eu-west-2' },
    { label: 'France (Paris)', value: 'eu-west-3' },
    { label: 'Brazil (São Paulo)', value: 'sa-east-1' },
    { label: 'United States (Ohio)', value: 'us-east-2' },
    { label: 'United States (N. California)', value: 'us-west-1' },
    { label: 'United States (Oregon)', value: 'us-west-2' },
  ];

  lookupClosestChimeRegion = async (): Promise<RegionType> => {
    let region: string;
    try {
      const response = await fetch(`https://nearest-media-region.l.chime.aws`, {
        method: 'GET',
      });
      const json = await response.json();
      if (json.error) {
        throw new Error(`${json.error}`);
      }
      region = json.region;
    } catch (error) {
      this.logError(error);
    }
    return (
      this.supportedChimeRegions.find(({ value }) => value === region) ||
      this.supportedChimeRegions[0]
    );
  };

  requestMeetingInfo = async (
    meetingUUID: string,
    name: string,
    isRequestingPhotobooth: boolean,
    mockTime?: number | null
  ) => {
    this.title = meetingUUID;
    const region = await this.lookupClosestChimeRegion();

    //add more error handling
    return await joinRoom(
      meetingUUID,
      region.value,
      name,
      isRequestingPhotobooth,
      mockTime
    );
  };

  setDetectedDevices = async () => {};

  initializeMeetingSession = async (
    meeting: any,
    attendee: any,
    meetingTitle: string
  ): Promise<void> => {
    this.configuration = new MeetingSessionConfiguration(meeting, attendee);
    const logger = new ConsoleLogger('SDK', LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);
    this.meetingSession = new DefaultMeetingSession(
      this.configuration,
      logger,
      deviceController
    );
    this.audioVideo = this.meetingSession.audioVideo;

    this.audioInputDevices = [];
    (await this.audioVideo?.listAudioInputDevices()).forEach(
      (mediaDeviceInfo: MediaDeviceInfo) => {
        this.audioInputDevices.push({
          label: mediaDeviceInfo.label,
          value: mediaDeviceInfo.deviceId,
        });
      }
    );
    this.currentAudioInputDevice = this.audioInputDevices?.[0];

    this.audioOutputDevices = [];
    (await this.audioVideo?.listAudioOutputDevices()).forEach(
      (mediaDeviceInfo: MediaDeviceInfo) => {
        this.audioOutputDevices.push({
          label: mediaDeviceInfo.label,
          value: mediaDeviceInfo.deviceId,
        });
      }
    );
    this.currentAudioOutputDevice = this.audioOutputDevices?.[0];

    this.videoInputDevices = [];
    (await this.audioVideo?.listVideoInputDevices()).forEach(
      (mediaDeviceInfo: MediaDeviceInfo) => {
        this.videoInputDevices.push({
          label: mediaDeviceInfo.label,
          value: mediaDeviceInfo.deviceId,
        });
      }
    );
    this.currentVideoInputDevice = this.videoInputDevices?.[0];

    this.audioVideo?.addDeviceChangeObserver(this);

    this.audioVideo?.realtimeSubscribeToAttendeeIdPresence(
      (presentAttendeeId: string, present: boolean): void => {
        if (!present) {
          delete this.roster[presentAttendeeId];
          this.publishRosterUpdate.cancel();
          this.publishRosterUpdate();
          return;
        }

        this.audioVideo?.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId,
          async (
            attendeeId: string,
            volume: number | null,
            muted: boolean | null,
            signalStrength: number | null
          ) => {
            const baseAttendeeId = new DefaultModality(attendeeId).base();
            if (baseAttendeeId !== attendeeId) {
              if (
                baseAttendeeId !==
                this.meetingSession?.configuration.credentials?.attendeeId
              ) {
                // TODO: stop my content share
              }
              return;
            }
            if (!this.roster[attendeeId]) {
              const { name } = await getAttendeeName(meetingTitle, attendeeId);
              this.roster[attendeeId] = { name };
              this.publishRosterUpdate.cancel();
            }

            if (muted !== null) {
              this.roster[attendeeId].muted = muted;
            }
            if (signalStrength !== null) {
              this.roster[attendeeId].signalStrength = Math.round(
                signalStrength * 100
              );
            }
            this.publishRosterUpdate();
          }
        );
      }
    );
  };

  subscribeToRosterUpdate = (callback: (roster: RosterType) => void) => {
    this.rosterUpdateCallbacks.push(callback);
  };

  unsubscribeFromRosterUpdate = (callback: (roster: RosterType) => void) => {
    const index = this.rosterUpdateCallbacks.indexOf(callback);
    if (index !== -1) {
      this.rosterUpdateCallbacks.splice(index, 1);
    }
  };

  private publishRosterUpdate = throttle(() => {
    for (let i = 0; i < this.rosterUpdateCallbacks.length; i += 1) {
      const callback = this.rosterUpdateCallbacks[i];
      callback(this.roster);
    }
  }, 400);

  /**
   * ====================================================================
   * Observer methods
   * ====================================================================
   */

  audioInputsChanged(freshAudioInputDeviceList: MediaDeviceInfo[]): void {
    let hasCurrentDevice = false;
    this.audioInputDevices = [];
    freshAudioInputDeviceList.forEach((mediaDeviceInfo: MediaDeviceInfo) => {
      if (
        this.currentAudioInputDevice &&
        mediaDeviceInfo.deviceId === this.currentAudioInputDevice.value
      ) {
        hasCurrentDevice = true;
      }
      this.audioInputDevices.push({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });
    });
    if (!hasCurrentDevice) {
      this.currentAudioInputDevice =
        this.audioInputDevices.length > 0 ? this.audioInputDevices[0] : null;
    }
  }

  audioOutputsChanged(freshAudioOutputDeviceList: MediaDeviceInfo[]): void {
    let hasCurrentDevice = false;
    this.audioOutputDevices = [];
    freshAudioOutputDeviceList.forEach((mediaDeviceInfo: MediaDeviceInfo) => {
      if (
        this.currentAudioOutputDevice &&
        mediaDeviceInfo.deviceId === this.currentAudioOutputDevice.value
      ) {
        hasCurrentDevice = true;
      }
      this.audioOutputDevices.push({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });
    });
    if (!hasCurrentDevice) {
      this.currentAudioOutputDevice =
        this.audioOutputDevices.length > 0 ? this.audioOutputDevices[0] : null;
    }
  }

  videoInputsChanged(freshVideoInputDeviceList: MediaDeviceInfo[]): void {
    let hasCurrentDevice = false;
    this.videoInputDevices = [];
    freshVideoInputDeviceList.forEach((mediaDeviceInfo: MediaDeviceInfo) => {
      if (
        this.currentVideoInputDevice &&
        mediaDeviceInfo.deviceId === this.currentVideoInputDevice.value
      ) {
        hasCurrentDevice = true;
      }
      this.videoInputDevices.push({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });
    });
    if (!hasCurrentDevice) {
      this.currentVideoInputDevice =
        this.videoInputDevices.length > 0 ? this.videoInputDevices[0] : null;
    }
  }
  /**
   * ====================================================================
   * Device
   * ====================================================================
   */

  flipVideoDevice = async () => {
    try {
      if (this.videoInputDevices.length !== 2) return;
      const deviceToFlipTo = this.videoInputDevices.filter(
        e => e !== this.currentVideoInputDevice
      );
      this.currentVideoInputDevice = deviceToFlipTo[0];
      this.publishDeviceChange('video', this.currentVideoInputDevice);
    } catch (error) {
      this.logError(error);
    }
  };

  chooseAudioInputDevice = async (device: DeviceType) => {
    try {
      await this.audioVideo?.chooseAudioInputDevice(device.value);
      this.currentAudioInputDevice = device;
    } catch (error) {
      this.logError(error);
    }
  };

  chooseAudioOutputDevice = async (device: DeviceType) => {
    try {
      await this.audioVideo?.chooseAudioOutputDevice(device.value);
      this.currentAudioOutputDevice = device;
      this.publishDeviceChange('audio', device);
    } catch (error) {
      this.logError(error);
    }
  };

  chooseVideoInputDevice = async (device: DeviceType) => {
    try {
      await this.audioVideo?.chooseVideoInputDevice(device.value);
      this.currentVideoInputDevice = device;
    } catch (error) {
      this.logError(error);
    }
  };

  flipLocalVideoTile = async () => {
    if (this.audioVideo?.hasStartedLocalVideoTile()) {
      this.audioVideo.stopLocalVideoTile();
    } else {
      await this.audioVideo?.chooseVideoInputDevice(
        this.currentVideoInputDevice!.value
      );
      this.audioVideo?.startLocalVideoTile();
    }
    this.publishVideoFlip();
  };

  startLocalVideoTile = async () => {
    if (this.audioVideo?.hasStartedLocalVideoTile()) return;
    await this.audioVideo?.chooseVideoInputDevice(
      this.currentVideoInputDevice!.value
    );
    this.audioVideo?.startLocalVideoTile();
    this.publishVideoFlip();
  };

  stopLocalVideoTile = async () => {
    if (!this.audioVideo?.hasStartedLocalVideoTile()) return;
    await this.audioVideo.stopLocalVideoTile();
    this.publishVideoFlip();
  };

  publishVideoFlip = () => {
    for (let i = 0; i < this.localVideoFlipCallbacks.length; i += 1) {
      const callback = this.localVideoFlipCallbacks[i];
      //this will have been started becuase we just flipped in the previous func
      if (this.audioVideo?.hasStartedLocalVideoTile()) {
        callback(VideoStatus.Loading);
        setTimeout(() => callback(VideoStatus.VideoOn), 2000);
      } else {
        callback(VideoStatus.VideoOff);
      }
    }
  };

  subscribeToVideoFlip = (
    callback: (localVideoStatus: VideoStatus) => void
  ) => {
    this.localVideoFlipCallbacks.push(callback);
  };

  subscribeToDeviceChange = (
    callback: (selectedDevice: DeviceType) => void,
    type: 'audio' | 'video'
  ) => {
    this.deviceChangeCallbacks[type].push(callback);
  };

  publishDeviceChange = (type: 'audio' | 'video', device: DeviceType) => {
    for (const callback of this.deviceChangeCallbacks[type]) {
      callback(device);
    }
  };

  private logError = (error: Error) => {
    // eslint-disable-next-line
    console.error(error);
  };
}
