import React from 'react';
import { Switch } from 'react-router-dom';
import _map from 'lodash/map';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/WarningRounded';
import TrialOptionStudyPlan from './TrialOptionStudyPlan';
import TrialOptionPayments from './TrialOptionPayments';
import TrialOptionNotes from './Notes/TrialOptionNotes';
import TrialOptionDetails from './TrialOptionDetails';
import TrialOptionCRF from '../CRFSignOff/TrialOptionCRF';
import { withQueryResult, InjectedProps } from '../hocs/WithQueryResult';
import {
  TrialOptionView,
  TrialOptionViewQuery,
  TrialOptionViewQueryVariables,
  Pii_Status,
} from '@curebase/core/types';
import { ParticipantInfo } from './ParticipantTags';
import { viewParticipantBaseRoute } from '../navigation/clinic';
import { FileUploadContext } from '../basic/FilePreview';
import { RolePermissions } from 'src/types';
import { currentUserHasPermission } from '../../lib/auth';
import RestrictedRoute from '../../RestrictedRoute';
import { connect } from 'react-redux';
import { withTranslation, TFunction } from 'react-i18next';
import { Route } from 'react-router-dom';

interface OwnProps {
  trialOptionId: number;
  resetDate: (dateOffset: number) => void;
}

interface TrialOptionViewParams {
  trialOptionId: string;
  viewType?: string;
}

interface Props
  extends OwnProps,
    InjectedProps<
      TrialOptionViewQueryVariables,
      TrialOptionViewQuery,
      TrialOptionViewParams
    > {
  t: TFunction;
}

export const TrialOptionViewContext = React.createContext({
  trialOptionViewRefetch: () =>
    console.log('[TrialOptionView.trialOptionViewRefetch] NOOP'),
});

type Tab = {
  text: string;
  value: string;
  permission?: RolePermissions;
};

const tabs: Tab[] = [
  {
    text: 'Workflow',
    value: 'WORKFLOW',
  },
  {
    text: 'Case Report Form',
    value: 'CRF',
  },
  {
    text: 'Payments',
    value: 'PAYMENTS',
    permission: RolePermissions.ViewPatientPayments,
  },
  {
    text: 'Notes & Files',
    value: 'NOTES',
    permission: RolePermissions.ViewParticipantNote,
  },
  {
    text: 'Edit',
    value: 'EDIT',
    permission: RolePermissions.EditTrialOptionDetails,
  },
];

const VALID_TABS = _map(tabs, 'value');
const DEFAULT_TAB = VALID_TABS[0];

type PatientData = {
  label: string;
  text: string;
};

class TrialOptionViewView extends React.Component<Props> {
  componentDidMount() {
    const { queryResult, resetDate } = this.props;
    const trialOption = queryResult.getTrialOption;

    if (typeof trialOption.dateOffset === 'number') {
      resetDate(trialOption.dateOffset);
    }
  }

  changeTab = (nextTab: string): void => {
    if (!VALID_TABS.includes(nextTab)) {
      console.error(`[TrialOptionView] Invalid Tab: ${nextTab}`);
      return;
    }

    const { history, match } = this.props;
    history.push(
      `${viewParticipantBaseRoute}/${match.params.trialOptionId}/${nextTab}`
    );
  };

  currentTab = (): string => {
    const { viewType = DEFAULT_TAB } = this.props.match.params;
    return VALID_TABS.includes(viewType) ? viewType : DEFAULT_TAB;
  };

  renderHeader(): React.ReactNode {
    const { queryResult, t } = this.props;
    const trialOption = queryResult.getTrialOption;

    const { patient } = trialOption;
    if (!patient || !patient.user) return;

    const patientData: PatientData[] = [];
    const needsAccount: boolean =
      (patient.user.isStub && trialOption.piiStatus === Pii_Status.Enabled) ??
      false;

    if (patient.birthday) {
      const data: PatientData = {
        label: t('trialOptionViewView.birthdayLabel'),
        text: patient.birthday
          ? moment(patient.birthday).format('ll')
          : t('trialOptionViewView.noneLabel'),
      };
      patientData.push(data);
    }

    if (
      patient.primaryCarePhysician &&
      (patient.primaryCarePhysician.name !== '' ||
        patient.primaryCarePhysician.address !== '')
    ) {
      const data: PatientData = {
        label: t('trialOptionViewView.physicianLabel'),
        text: `Name: ${
          patient.primaryCarePhysician.name ||
          t('trialOptionViewView.noneLabel')
        } / Address: ${
          patient.primaryCarePhysician.address ||
          t('trialOptionViewView.noneLabel')
        }`,
      };
      patientData.push(data);
    }

    return (
      <div className='trial-option-header'>
        {/* @ts-ignore */}
        <ParticipantInfo trialOption={trialOption} sticky />
        <div className='more-row'>
          {React.Children.toArray(
            patientData.map((data: PatientData) => (
              <div className='header-tabledata-row'>
                <div className='header-tabledata-row-left'>{data.label}</div>
                <div className='header-tabledata-row-right'>{data.text}</div>
              </div>
            ))
          )}
        </div>
        <Tabs
          textColor='primary'
          indicatorColor='primary'
          variant='scrollable'
          value={this.currentTab()}
          className='participant-tabs'
          onChange={(_, tab: string) => this.changeTab(tab)}
        >
          {React.Children.toArray(
            tabs
              .filter(
                (tab: Tab) =>
                  !tab.permission || currentUserHasPermission(tab.permission)
              )
              .map((tab: Tab) => (
                <Tab
                  style={{
                    pointerEvents: 'auto',
                    cursor: 'pointer',
                  }}
                  label={t(`trialOptionViewView.tabs.${tab.value}`, tab.text)}
                  value={tab.value}
                  className={
                    tab.value === 'EDIT' && needsAccount ? 'needs-account' : ''
                  }
                  icon={
                    tab.value === 'EDIT' && needsAccount ? (
                      <WarningIcon />
                    ) : undefined
                  }
                />
              ))
          )}
        </Tabs>
      </div>
    );
  }

  render() {
    const { queryResult } = this.props;
    const {
      getTrialOption: trialOption,
      refetch: trialOptionViewRefetch,
    } = queryResult;

    return (
      <TrialOptionViewContext.Provider
        value={{
          trialOptionViewRefetch,
        }}
      >
        <FileUploadContext.Provider value={{ trialOptionId: trialOption.id }}>
          {this.renderHeader()}
          <Switch>
            <RestrictedRoute
              path={`${viewParticipantBaseRoute}/:trialOptionId/EDIT`}
              render={() => (
                <TrialOptionDetails
                  trialOptionId={trialOption.id}
                  trialId={trialOption.trialId}
                />
              )}
              permission={RolePermissions.EditTrialOptionDetails}
              customHeader={true}
              hideFooter={true}
            />
            <Route
              path={`${viewParticipantBaseRoute}/:trialOptionId/CRF`}
              render={() => <TrialOptionCRF trialOptionId={trialOption.id} />}
            />
            <Route
              path={`${viewParticipantBaseRoute}/:trialOptionId/PAYMENTS`}
              render={() => (
                <TrialOptionPayments trialOptionId={trialOption.id} />
              )}
            />
            <Route
              path={`${viewParticipantBaseRoute}/:trialOptionId/NOTES`}
              render={() => <TrialOptionNotes trialOptionId={trialOption.id} />}
            />
            <Route
              path={`${viewParticipantBaseRoute}/:trialOptionId/:viewType?/:activitySubroute?/:additionalInfo?`}
              render={() => (
                //@ts-ignore
                <TrialOptionStudyPlan trialOptionId={trialOption.id} />
              )}
            />
          </Switch>
        </FileUploadContext.Provider>
      </TrialOptionViewContext.Provider>
    );
  }
}

const TrialOptionViewComponent = withQueryResult<
  TrialOptionViewQueryVariables,
  TrialOptionViewQuery,
  OwnProps,
  TrialOptionViewParams
>(
  TrialOptionViewView,
  TrialOptionView,
  props => ({
    trialOptionId: parseInt(props.match.params.trialOptionId),
  }),
  {
    noDismountOnRefetch: true,
  }
);

const mapDispatchToProps = dispatch => ({
  resetDate: (dateOffset: number) => {
    dispatch({
      type: 'RESET_DATE',
      dateOffset,
    });
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation('translations')(TrialOptionViewComponent));
