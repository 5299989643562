import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chimeContext from '../../context/getChimeContext';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DeviceType } from '../../lib/chime';

import Dialog from '@material-ui/core/Dialog';
import { CallOverlayMode } from './AudioVideo';
import { sendLinkToMobileTelehealthPhotobooth } from 'src/controllers/telemedController';
import Loading from '../Loading';

type WrapperProps = {
  onClose: () => void;
  onVideoChangeOverride?: () => void;
  overlayMode: CallOverlayMode;
  meetingUUID?: string;
};
type Props = {
  onClose: () => void;
  onVideoChangeOverride?: () => void;
};

type SelectProps = {
  headerText: string;
  devices?: DeviceType[];
  current?: DeviceType;
  switchDevice: (deviceId: string) => void;
};

const useStyles1 = makeStyles({
  button: {
    color: 'white',
  },
});

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

const DeviceDropdown = (props: SelectProps) => {
  const styles = useStyles1();
  const { headerText, devices, current } = props;
  return (
    <div className='select-obj'>
      <div className='header-text '>{headerText}</div>
      <Select
        className={`${styles.button}`}
        fullWidth
        value={current?.value || ''}
        onChange={e => {
          props.switchDevice(e.target.value as string);
        }}
      >
        {devices?.map(({ label, value }, i) => (
          <MenuItem key={i} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const useStyles = makeStyles({
  button: {
    borderRadius: '20px 20px 20px 20px',
  },
});

const PhotoBoothMenu = (props: {
  onClose: () => void;
  meetingUUID: string;
}) => {
  const { signatureMountStatus } = useSelector(
    (store: any) => store.telehealth
  );
  const dispatch = useDispatch();
  const chime = useContext(chimeContext);
  const [hasSentLinkToNumber, setHasSentLinkToNumber] = useState<
    number | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const mockTime = useSelector((store: any) => store?.currentDate);
  const style = useStyles();

  return (
    <div
      className='photo-booth-menu'
      style={{ marginBottom: signatureMountStatus ? '110px' : '0px' }}
    >
      {!loading ? (
        !hasSentLinkToNumber ? (
          <>
            <Button
              className={`general-menu-button ${style.button}`}
              onClick={() => {
                chime.stopLocalVideoTile();
                dispatch({
                  type: 'SET_PHOTO_MODE',
                  payload: { addPhotos: true },
                });
                props.onClose();
              }}
            >
              Use camera on this device
            </Button>
            <Button
              className={`general-menu-button ${style.button}`}
              onClick={() => {
                setLoading(true);
                sendLinkToMobileTelehealthPhotobooth(
                  props.meetingUUID,
                  mockTime
                ).then(res => {
                  setHasSentLinkToNumber((res as any)?.numberSent);
                  setLoading(false);
                });
              }}
            >
              SEND LINK TO MOBILE DEVICE
            </Button>
            <Button
              className={`general-menu-button ${style.button}`}
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <div style={{ color: 'white' }}>
              {hasSentLinkToNumber > -1
                ? `Sent link to ${hasSentLinkToNumber}`
                : 'No number on file'}
            </div>
            <Button
              className={`general-menu-button ${style.button}`}
              onClick={() => {
                setHasSentLinkToNumber(undefined);
                props.onClose();
              }}
            >
              Ok
            </Button>
          </>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

const DeviceSelector = (props: Props) => {
  const styles = useStyles();
  const styles1 = useStyles1();
  const forceUpdate = useForceUpdate();
  const chime = useContext(chimeContext);
  const { addPhotos } = useSelector((store: any) => store?.telehealth);
  return (
    <>
      <div className='close-button'>
        <Button
          className={`${styles.button} ${styles1.button}`}
          onClick={() => props.onClose()}
        >
          <CloseIcon /> CLOSE SETTINGS
        </Button>
      </div>
      <DeviceDropdown
        headerText='Camera'
        devices={chime.videoInputDevices}
        current={chime.currentVideoInputDevice ?? undefined}
        switchDevice={deviceId => {
          const selectedDevice = chime.videoInputDevices.filter(
            e => e.value === deviceId
          );
          chime.chooseVideoInputDevice(selectedDevice[0]).then(() => {
            chime.publishDeviceChange('video', selectedDevice[0]);
            forceUpdate();
          });
        }}
      />
      {!addPhotos && (
        <DeviceDropdown
          headerText='Microphone'
          devices={chime.audioInputDevices}
          current={chime.currentAudioInputDevice ?? undefined}
          switchDevice={deviceId => {
            const selectedDevice = chime.audioInputDevices.filter(
              e => e.value === deviceId
            );
            chime.chooseAudioInputDevice(selectedDevice[0]).then(() => {
              chime.publishDeviceChange('audio', selectedDevice[0]);
              forceUpdate();
            });
          }}
        />
      )}
      {!addPhotos && (
        <DeviceDropdown
          headerText='Speakers'
          devices={chime.audioOutputDevices}
          current={chime.currentAudioOutputDevice ?? undefined}
          switchDevice={deviceId => {
            const selectedDevice = chime.audioOutputDevices.filter(
              e => e.value === deviceId
            );
            chime
              .chooseAudioOutputDevice(selectedDevice[0])
              .then(() => forceUpdate());
          }}
        />
      )}
    </>
  );
};

const CallOverlayPanelWrapper = (props: WrapperProps) => {
  const [showDialog, setShowDialog] = useState(true);
  const wrapperOnClose = () => {
    setShowDialog(false);
    props.onClose();
  };
  const { overlayMode } = props;
  const isDeviceChange = overlayMode === CallOverlayMode.DeviceChange;

  return (
    <Dialog
      open={showDialog}
      onClose={wrapperOnClose}
      PaperProps={{
        style: {
          backgroundColor: '#444444',
          margin: '10px',
          color: 'white',
        },
      }}
    >
      <div className='settings-overlay'>
        <div className='filler-div' style={{ flex: isDeviceChange ? 1 : 2 }} />
        <div
          className='device-changer'
          style={{ flex: isDeviceChange ? 2 : 1 }}
        >
          {props.overlayMode === CallOverlayMode.DeviceChange && (
            <DeviceSelector
              {...props}
              onClose={() => {
                wrapperOnClose();
              }}
            />
          )}
          {props.overlayMode === CallOverlayMode.PhotoboothMenu && (
            <PhotoBoothMenu
              onClose={() => {
                wrapperOnClose();
              }}
              meetingUUID={props.meetingUUID!}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default CallOverlayPanelWrapper;
