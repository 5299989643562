import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { Grid } from '@material-ui/core';
import Analytics, {
  AnalyticsEventProperty,
  AnalyticsEventType,
} from '../../Analytics/Analytics';
import { Trial } from '@curebase/core/types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchFiltersQuery } from 'src/types';
import {
  changeParticipantEpochPickerFilters,
  changeParticipantPickerFilters,
  changeParticipantSearchQueryPickerFilters,
  changeParticipantStatusesPickerFilters,
  changeParticipantStudiesPickerFilters,
} from 'src/store/participantPicker/actions';
import AutocompleteMultipleSelect, {
  Option as AutocompleteOption,
} from '../AutocompleteMultipleSelect';
import {
  getTrialOptionDisplayStatus,
  getUserAllowedTrials,
} from 'src/shared/lib/modelHelpers';
import { getUser } from 'src/store';
import { TReduxState } from 'src/store/types';
import { ParticipantPickerState } from 'src/store/participantPicker/types';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseParticipantFilter, stringifyQuery } from 'src/lib/filter';
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../../context/localeContext';

const getStatuses = (
  trialIds: number[],
  availableStudies: Trial[],
  selectedEpoch
): AutocompleteOption[] => {
  const statuses: string[][] = [];
  const selectedEpochValue = selectedEpoch.map(epoch => epoch.value);
  let filterStatus = [];
  availableStudies.forEach(s => {
    if (selectedEpochValue.length > 0) {
      if (
        selectedEpochValue.includes('none') &&
        _isEmpty(s.availableEpochs) &&
        (trialIds.length <= 0 || trialIds.includes(s.id))
      ) {
        statuses.push(s.availableStatuses.flat() as string[]);
      }
      filterStatus = selectedEpochValue
        .map(selectedEpoch => s.availableEpochs[selectedEpoch] || [])
        .flat();
      if (trialIds.length <= 0 || trialIds.includes(s.id)) {
        statuses.push(filterStatus.flat() as string[]);
      }
    } else if (trialIds.length <= 0 || trialIds.includes(s.id)) {
      statuses.push(s.availableStatuses.flat() as string[]);
    }
  });

  const uniqueStatuses = [...new Set(statuses.flat())].map(status => ({
    name: getTrialOptionDisplayStatus(status),
    value: status,
  }));
  return _sortBy(uniqueStatuses, ['name'], ['asc']);
};

const getStatusesJson = (
  trialIds: number[],
  availableStudies: Trial[]
): AutocompleteOption[] => {
  const statuses: string[][] = [];

  availableStudies.forEach(s => {
    if (trialIds.length <= 0 || trialIds.includes(s.id)) {
      if (s.availableStatusesJson) {
        let statusNames = s.availableStatusesJson
          .map(status => Object.values(status))
          .flat();
        for (let statusName of statusNames) {
          const studyLocale = getLocale().locale;
          statuses.push(statusName[studyLocale]);
        }
      }
    }
  });

  const uniqueStatuses = [...new Set(statuses)].map(status => ({
    name: getTrialOptionDisplayStatus(status),
    value: status,
  }));
  return _sortBy(uniqueStatuses, ['name'], ['asc']);
};

const getEpochs = (
  trialIds: number[],
  availableStudies: Trial[]
): AutocompleteOption[] => {
  const epochs: string[][] = [];

  availableStudies.forEach(s => {
    if (trialIds.length <= 0 || trialIds.includes(s.id)) {
      const availableEpochs = Object.keys(s.availableEpochs);
      epochs.push(availableEpochs.flat() as string[]);
    }
  });
  const uniqueEpoch = [...new Set(epochs.flat())].map(epoch => ({
    name: epoch,
    value: epoch,
  }));

  let sortedArray = _sortBy(uniqueEpoch, ['name'], ['asc']);
  if (sortedArray.length === 0 || trialIds.length === 0)
    sortedArray.unshift({
      name: 'None',
      value: 'none',
    });
  return sortedArray;
};

const SearchFilters = (): React.ReactElement => {
  const { t } = useTranslation('translations');
  const user = getUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const participantPicker: ParticipantPickerState = useSelector(
    (store: TReduxState) => store.participantPicker
  );
  const availableStudies = _sortBy(
    getUserAllowedTrials(user),
    ['name'],
    ['asc']
  );

  const { data, loading } = useSearchFiltersQuery();
  const availableStudiesJson = _sortBy(
    getUserAllowedTrials(user, data?.getTrials || []),
    ['name'],
    ['asc']
  );

  const handleSelectedStudy = (options: AutocompleteOption[]): void => {
    if (options) {
      // Analytics event for when a user filters by the available studies
      Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_FILTER_STUDY, {
        [AnalyticsEventProperty.STUDY_NAME_LIST]: _map(options, 'name'),
      });
    }
    dispatch(changeParticipantStudiesPickerFilters(_map(options, 'value')));
    dispatch(changeParticipantEpochPickerFilters([]));
    dispatch(changeParticipantStatusesPickerFilters([]));
  };

  const handleSelectedStatus = (options: AutocompleteOption[]): void => {
    if (options) {
      // Analytics event for when a user filters by the state/status
      // of a participant.
      Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_FILTER_STATE, {
        [AnalyticsEventProperty.PARTICIPANT_STATUS_LIST]: _map(options, 'name'),
      });
    }
    dispatch(changeParticipantStatusesPickerFilters(_map(options, 'value')));
  };

  const handleSelectedEpoch = (options: AutocompleteOption[]): void => {
    dispatch(changeParticipantEpochPickerFilters(_map(options, 'value')));
    dispatch(changeParticipantStatusesPickerFilters([]));
  };

  useEffect(() => {
    const { pathname } = location;
    const search = stringifyQuery(participantPicker);
    history.push({ pathname, search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantPicker]);

  useEffect(() => {
    const { search } = location;
    const {
      trialIds,
      searchQuery,
      filter,
      statuses,
      epochs,
    } = parseParticipantFilter(search);
    dispatch(changeParticipantStudiesPickerFilters(trialIds));
    dispatch(changeParticipantSearchQueryPickerFilters(searchQuery));
    dispatch(changeParticipantPickerFilters(filter));
    dispatch(changeParticipantStatusesPickerFilters(statuses));
    dispatch(changeParticipantEpochPickerFilters(epochs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const studyOptions = availableStudies.map(({ name, id, deactivated }) =>
    deactivated
      ? {
          name: `${name} (${t('associateTrialView.deactivatedLabel')})`,
          value: id,
        }
      : {
          name,
          value: id,
        }
  );
  const epochOptions = getEpochs(
    participantPicker.trialIds,
    availableStudiesJson as Trial[]
  );
  const selectedEpoch = epochOptions.filter(({ value }) =>
    participantPicker.epochs.includes(value)
  );
  const selectedStudy = studyOptions.filter(({ value }) =>
    participantPicker.trialIds.includes(value)
  );

  const statusOptions = getStatuses(
    participantPicker.trialIds,
    availableStudies as Trial[],
    selectedEpoch
  );

  const statusOptionsJson = getStatusesJson(
    participantPicker.trialIds,
    availableStudiesJson as Trial[]
  );

  const statusOptionsCombined = statusOptions.concat(statusOptionsJson);
  const selectedStatus = statusOptionsCombined.filter(({ value }) =>
    participantPicker.statuses.includes(value)
  );

  return (
    <div className='search-filters'>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className='search-filters-label'>
            {t('pickers.searchFilters.studyFilterLabel')}
          </div>
          <AutocompleteMultipleSelect
            value={selectedStudy}
            loading={loading}
            options={studyOptions}
            onChange={handleSelectedStudy}
            placeholder={t('pickers.searchFilters.studyFilterPlaceholder')}
          />
        </Grid>
        <Grid item xs={3}>
          <div className='search-filters-label'>
            {t('pickers.searchFilters.statusFilterLabel')}
          </div>
          <AutocompleteMultipleSelect
            value={selectedEpoch}
            loading={loading}
            options={epochOptions}
            onChange={handleSelectedEpoch}
            placeholder={t('pickers.searchFilters.statusFilterPlaceholder')}
          />
        </Grid>
        <Grid item xs={3}>
          <div className='search-filters-label'>
            {t('pickers.searchFilters.stateFilterLabel')}
          </div>
          <AutocompleteMultipleSelect
            value={selectedStatus}
            loading={loading}
            options={statusOptionsCombined}
            onChange={handleSelectedStatus}
            placeholder={t('pickers.searchFilters.stateFilterPlaceholder')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchFilters;
