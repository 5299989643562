import produce from 'immer';
import { TBSites, SiteEventAction, INIT_TRIAL, EDIT_SITE } from './types';

export default produce((state: TBSites, action: SiteEventAction) => {
  switch (action.type) {
    case EDIT_SITE:
      state[action.payload.slug] = action.payload.config;
      break;
    case INIT_TRIAL:
      return action.payload.sites;
  }
}, {});
