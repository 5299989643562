import * as React from 'react';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';

const TestModeIndicator = () => {
  if (!DEVELOPER_FEATURES_ENABLED) return null;

  return (
    <div className='test-mode-indicator'>
      <span className='test-mode-indicator-title'>TEST MODE </span>
      <span className='test-mode-indicator-subtitle'>NO PHI</span>
    </div>
  );
};

export default TestModeIndicator;
