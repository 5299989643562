import {
  CreateSignedDocumentRequest,
  CreateSignedDocumentResponse,
  ResetDocumentRequest,
  ResetDocumentResponse,
} from '@curebase/core/decoders/signatures';
import { jsonPost } from '../lib/fetchHelpers';
import { IS_PRODUCTION } from '@curebase/core/lib/env';

export async function createPaperDocument(
  fileIds: string[],
  documentType: string,
  trialOptionId: string
) {
  const request = {
    fileIds,
    documentType,
    trialOptionId: parseInt(trialOptionId),
  };
  return await jsonPost('/document/paper', request);
}

export async function uploadDocumentAndSignature(
  request: CreateSignedDocumentRequest
): Promise<CreateSignedDocumentResponse> {
  return await jsonPost('/document/create', request);
}

export async function resetDocument(
  documentType: string,
  trialOptionId: number
): Promise<ResetDocumentResponse> {
  const request: ResetDocumentRequest = {
    documentType,
    trialOptionId,
  };
  return await jsonPost('/document/resetDocument', request);
}

export async function autoCompleteDocuments(trialOptionId: number) {
  if (IS_PRODUCTION) return { success: false };
  return await jsonPost('/document/autocomplete', { trialOptionId });
}
