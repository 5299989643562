import produce from 'immer';
import {
  TOGGLE_TRIAL_DIALOG,
  TOGGLE_STUDYPLANVISIT_DIALOG,
  TOGGLE_CASEREPORT_DIALOG,
  TOGGLE_DATAFIELD_DIALOG,
  TOGGLE_CONFIRMATION_DIALOG,
  OPEN_CONFIRM_DIALOG,
  OPEN_DEPLOY_DIALOG,
  OPEN_REVISION_MISMATCH_DIALOG,
  CLOSE_CONFIRM_DIALOG,
  CLOSE_DEPLOY_DIALOG,
  CLOSE_REVISION_MISMATCH_DIALOG,
  Action,
} from '../../actions/TrialBuilder/types';

export type UIState = {
  confirmSubmitToDB: boolean;
  confirmationDialog: {
    open: boolean;
    onSubmit?: () => void;
    title: string;
    body: string;
  };
  dialogCR: boolean;
  dialogDF: boolean;
  dialogSPV: boolean;
  dialogTrial: boolean;
  deployDialog: boolean;
  revisionMismatchDialog: boolean;
  parentId: number | null;
  parentSlug: string | null;
  slug: string | null;
};

const initialState: UIState & any = {
  confirmSubmitToDB: false,
  confirmationDialog: {
    open: false,
    title: '',
    body: '',
  },
  dialogCR: false,
  dialogDF: false,
  dialogSPV: false,
  dialogTrial: false,
  deployDialog: false,
  parentId: null,
  parentSlug: null,
  slug: null,
};

export default produce((state: UIState, action: Action) => {
  switch (action.type) {
    case TOGGLE_CONFIRMATION_DIALOG:
      if (!!action.payload) {
        state.confirmationDialog.open = true;
        state.confirmationDialog.onSubmit = action.payload.onSubmit;
        state.confirmationDialog.title = action.payload.title;
        state.confirmationDialog.body = action.payload.body;
      } else {
        state.confirmationDialog.open = false;
        delete state.confirmationDialog.onSubmit;
      }
      break;
    case TOGGLE_TRIAL_DIALOG:
      state.dialogTrial = !state.dialogTrial;
      break;
    case TOGGLE_STUDYPLANVISIT_DIALOG:
      state.slug = action.payload.slug ? action.payload.slug : null;
      state.dialogSPV = !state.dialogSPV;
      break;
    case TOGGLE_CASEREPORT_DIALOG:
      state.slug = action.payload.slug ? action.payload.slug : null;
      state.dialogCR = !state.dialogCR;
      if (action.payload.studyPlanVisit) {
        state.parentSlug = action.payload.studyPlanVisit.slug;
      } else {
        state.parentSlug = null;
      }
      break;
    case TOGGLE_DATAFIELD_DIALOG:
      state.slug = action.payload.slug ? action.payload.slug : null;
      if (action.payload.caseReport) {
        state.parentSlug = action.payload.caseReport.slug;
      } else {
        state.parentSlug = null;
      }
      state.dialogDF = !state.dialogDF;
      break;
    case OPEN_CONFIRM_DIALOG:
      state.confirmSubmitToDB = true;
      break;
    case CLOSE_CONFIRM_DIALOG:
      state.confirmSubmitToDB = false;
      break;
    case OPEN_DEPLOY_DIALOG:
      state.deployDialog = true;
      break;
    case CLOSE_DEPLOY_DIALOG:
      state.deployDialog = false;
      break;
    case OPEN_REVISION_MISMATCH_DIALOG:
      state.revisionMismatchDialog = true;
      break;
    case CLOSE_REVISION_MISMATCH_DIALOG:
      state.revisionMismatchDialog = false;
      break;
    default:
      break;
  }
}, initialState);
