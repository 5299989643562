import React, { useContext, useState, useEffect, useRef } from 'react';
import VideoElement from './VideoElement';
import chimeContext from '../../context/getChimeContext';
import { VisibiltyStatus, VideoStatus } from './AudioVideo';

const LocalVideo = React.memo((props: { startingVideoState: boolean }) => {
  const chime = useContext(chimeContext);
  const localRef = useRef(null);
  const [localVideoStatus, setLocalVideoStatus] = useState<VideoStatus>(
    props.startingVideoState ? VideoStatus.Loading : VideoStatus.VideoOff
  );
  useEffect(() => {
    chime.subscribeToVideoFlip((localVideoStatus: VideoStatus) =>
      setLocalVideoStatus(localVideoStatus)
    );
    chime?.audioVideo?.addObserver({
      videoTileDidUpdate: (tileState): void => {
        if (
          !tileState.boundAttendeeId ||
          !tileState.localTile ||
          !tileState.tileId ||
          !localRef.current
        ) {
          return;
        }

        chime?.audioVideo?.bindVideoElement(
          tileState.tileId,
          (localRef.current as unknown) as HTMLVideoElement
        );
      },
    });
    //If starting video state is VideoOff, publish notification to the control bar.
    //if enabled, trigger the camera(which also publishes a notification)
    localVideoStatus === VideoStatus.Loading
      ? chime.startLocalVideoTile()
      : chime.publishVideoFlip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let visibility;
  switch (localVideoStatus) {
    case VideoStatus.VideoOff:
      visibility = VisibiltyStatus.ShowImage;
      break;
    case VideoStatus.Loading:
      visibility = VisibiltyStatus.ShowLoading;
      break;
    default:
      visibility = VisibiltyStatus.ShowVideo;
      break;
  }
  return <VideoElement videoRef={localRef} isVisible={visibility} />;
});

export default LocalVideo;
