import queryString from 'query-string';
import { fetchHelper, jsonPost } from '../lib/fetchHelpers';
import {
  UpdateTrialRequest,
  UpdateTrialResponse,
} from '@curebase/core/decoders/trials';

const basePath = '/trial';
export function fetchTrialSearchResults(
  query: string,
  restrictToClinicId?: string
): Promise<any> {
  const apiPath = `${basePath}/search`;

  return new Promise((resolve, reject) => {
    const urlQuery = queryString.stringify({ query, restrictToClinicId });
    const routeWithQuery = `${apiPath}?${urlQuery}`;
    return fetchHelper(routeWithQuery)
      .then(response => response.json())
      .then(responseJson => {
        resolve(responseJson[`trials`]);
      })
      .catch(e => {
        reject(e);
      });
  });
}

export async function updateTrial(
  trialId: string,
  reason: string,
  deactivated: boolean
): Promise<UpdateTrialResponse> {
  const requestBody: UpdateTrialRequest = {
    trialId,
    reason,
    data: { deactivated },
  };
  const response: UpdateTrialResponse = await jsonPost(
    `${basePath}/update`,
    requestBody
  );
  return response;
}
