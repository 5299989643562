import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Subheader from '../basic/Subheader';
import ListItem from '../basic/ListItem';
import DynamicForm from '../basic/DynamicForm';
import BottomButtons from '../basic/BottomButtons';
import Dialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { showAlertMessage } from '../../store/actions';
import { StatusColor } from 'src/shared/lib/colors';

type Props = {
  title: string;
  defaultData: Object;
  validate: (string, Object) => string | undefined;
  pages: Object[];
  onSubmit: any;
  onFinish: () => any;
};

function SettingsEditor(props: Props) {
  const { t } = useTranslation('translations');
  const [editData, setEditData] = useState<Object>({});
  const [editErrors, setEditErrors] = useState<Object>({});
  const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { title, pages } = props;

  useEffect(() => {
    const { defaultData } = props;
    setEditData(defaultData);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // leave it blank because it's replacing a componentDidMount

  function onCompleted(response) {
    const { onFinish } = props;

    if (!!response?.error) {
      showAlertMessage('Settings not saved.', StatusColor.Red);

      if (response.errorDetails) {
        const { path, message } = response.errorDetails[0];
        setEditErrors({ [`${path}`]: message }); // { email: 'email must be unique' }
      } else {
        // @TODO: improve error handling based on server response
      }
    } else {
      showAlertMessage('Settings saved.', StatusColor.Green);
      onFinish();
    }
  }

  async function submit() {
    const { onSubmit } = props;

    await onSubmit(editData, onCompleted);
    setFormHasChanges(false);
  }

  function validate() {
    const { validate } = props;
    const errors = {};

    for (const k of Object.keys(editData)) {
      const error = validate(k, editData[k]);
      if (error) errors[k] = error;
    }

    if (errors && Object.keys(errors).length > 0) {
      setEditErrors(errors);
    } else {
      setEditErrors({});
      setShowConfirmDialog(true);
    }
  }

  if (isLoading) return null;

  return (
    <>
      <Subheader text={title} />
      <div className='li-multi-container inset'>
        <ListItem>
          <DynamicForm
            pages={pages}
            onChange={(key, value) => {
              setEditData({
                ...editData,
                [key]: value,
              });
              setFormHasChanges(true);
            }}
            data={editData}
            errors={editErrors}
            noSubmit
          />
        </ListItem>
      </div>

      <BottomButtons
        buttons={[
          {
            text: t('profile.userDetailsSubmit'),
            onClick: () => {
              validate();
            },
            disabled: !formHasChanges,
          },
        ]}
      />

      <Dialog
        open={!!showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <ConfirmationInDialog
          title={t('profile.userDetailsConfirmDialogTitle')}
          message={t('profile.userDetailsConfirmDialogMessage')}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={async () => {
            setShowConfirmDialog(false);
            await submit();
          }}
        />
      </Dialog>
    </>
  );
}

export default SettingsEditor;
