import React from 'react';
import { withQueryResult, InjectedProps } from '../hocs/WithQueryResult';
import queryString from 'query-string';
import moment from 'moment-timezone';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  RolePermissions,
  TrialOptionCrf,
  TrialOptionCrfQuery,
  TrialOptionCrfQueryVariables,
  VisitStatus,
} from '@curebase/core/types';
import Loading from '../Loading';
import { executeQuery } from 'src/ApolloClient';
import { currentUserHasPermission } from '../../lib/auth';

import { TrialOptionCRFAllData, CrfEntryType } from './TrialOptionCRFAllData';
import TrialOptionCRFDataEdits from './TrialOptionCRFDataEdits';
import TrialOptionCRFSignoffs from './TrialOptionCRFSignoffs';
import { getLocaleFormatDates, getLocale } from '../../context/localeContext';
import { TFunction, withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const localesFormat = getLocaleFormatDates();
const { locale } = getLocale();
export const updateSearchAndGo = (history: any, newSearchParams: any) =>
  history.push(
    history.location.pathname + '?' + queryString.stringify(newSearchParams)
  );

type State = {
  crfData?: CrfEntryType[];
  expanded: string | boolean;
  trialOptionTimezone?: string;
};

interface OwnProps {
  trialOptionId: number;
  t: TFunction;
}

interface TrialOptionViewParams {
  trialOptionId: string;
  viewType?: string;
}

interface Props
  extends OwnProps,
    InjectedProps<
      TrialOptionCrfQueryVariables,
      TrialOptionCrfQuery,
      TrialOptionViewParams
    > {}

const ALL = 'ALL';
const CHANGES = 'CHANGES';
const SIGNOFF = 'SIGNOFF';

class TrialOptionCRFView extends React.Component<Props, State> {
  state = {
    crfData: undefined,
    expanded: false,
    trialOptionTimezone: undefined,
  } as State;

  getSpvIdForCapturedDataQuery = async () => {
    const searchParams = queryString.parse(this.props.location.search);
    const targetQueryId = searchParams.queryId;
    if (!targetQueryId) {
      return null;
    }
    const { getCapturedDataQuery: query } = await executeQuery(`
      getCapturedDataQuery(
        id: "${targetQueryId}"
      ) {
        id
        capturedData {
          id
          caseReportInstance {
            id
            visit {
              id
              studyPlanVisitId
            }
          }
        }
      }`);

    return query?.[0]?.capturedData?.caseReportInstance?.visit
      ?.studyPlanVisitId;
  };

  async componentDidMount() {
    const { t } = this.props;
    const trialOption = this.props.queryResult.getTrialOption;
    const crfData: CrfEntryType[] = [];
    if (!trialOption) return;
    const {
      studyPlanVisits,
      stateTransitions,
      enrollment,
      patient,
    } = trialOption;

    const targetSpvId = await this.getSpvIdForCapturedDataQuery();

    if (!!enrollment) {
      crfData.push({
        id: trialOption.id,
        type: 'ENROLLMENT',
        title: t('trialOptionCRFView.enrollment'),
        time: trialOption.enrollmentDate || enrollment.updatedAt,
        enrollmentProperties: {
          enrollerFirstName: enrollment.enrolledByUser?.firstName,
          enrollerLastName: enrollment.enrolledByUser?.lastName,
          // @ts-expect-error
          enrolledOn: trialOption.enrollmentDate,
          nameSigned: enrollment.signedDocument?.signatures[0].legalName,
          signatureFileKey: enrollment.signedDocument?.signatures[0].file?.key,
          timeSigned: moment(
            enrollment.signedDocument?.signatures[0].createdAt
          ).format(localesFormat.moment.dateFormat),
        },
      });
    }

    if (studyPlanVisits) {
      for (const spv of studyPlanVisits) {
        const isTargetSpv = spv.id === targetSpvId;
        crfData.push({
          id: spv.id,
          studyPlanVisitSlug: spv.slug,
          type: 'DATA_CAPTURE',
          title: spv.summary.title,
          time: spv.lastVisitCompletedAt
            ? spv.lastVisitCompletedAt
            : VisitStatus.InProgress,
          slug: spv.slug,
          isFocused: isTargetSpv,
        });
      }
    }

    if (stateTransitions && stateTransitions.length > 0) {
      crfData.push({
        id: stateTransitions[0].id,
        studyPlanVisitSlug: '',
        type: 'STATE_TRANSITION',
        title: t('trialOptionCRFView.withdrawal'),
        time: stateTransitions[0].startDate,
      });
    }
    crfData
      .sort((a, b) => {
        // If both CRFs are in progress, don't sort them
        if (
          a.time === VisitStatus.InProgress &&
          b.time === VisitStatus.InProgress
        )
          return 0;
        // If the first CRF is in progress, put it ahead of the one that's next.
        // This makes sure that the in progress ones are closer to the beginning of
        // the array.
        if (a.time === VisitStatus.InProgress) return -1;
        if (b.time === VisitStatus.InProgress) return 1;
        return a.time > b.time ? -1 : 0;
      })
      .map(e => {
        e.time =
          e.time !== VisitStatus.InProgress
            ? DateTime.fromISO(e.time).toFormat(
                localesFormat.luxon.trialOptionCRF,
                { locale }
              )
            : e.time;
        return e;
      });

    this.setState({
      crfData,
      trialOptionTimezone: patient?.user?.safeTimezone,
    });
  }

  crfTab = () => {
    const defaultTab = ALL;
    if (this.props && this.props.location && this.props.location.search) {
      const searchParams = queryString.parse(this.props.location.search);
      return this.validTabs()
        .map(t => t.value)
        .includes(searchParams.crfTab as any)
        ? searchParams.crfTab
        : defaultTab;
    }
    return defaultTab;
  };

  validTabs = () => {
    const { t } = this.props;
    return [
      {
        value: ALL,
        text: t('trialOptionCRFView.tabs.all'),
      },
      {
        value: CHANGES,
        permission: RolePermissions.ReadCapturedDataEditQueryMessage,
        text: t('trialOptionCRFView.tabs.changes'),
      },
      {
        value: SIGNOFF,
        permission: RolePermissions.SignCaseReport,
        text: t('trialOptionCRFView.tabs.signoff'),
      },
    ].filter(t => !t.permission || currentUserHasPermission(t.permission));
  };

  renderCrfTabBar = () => (
    <Tabs
      textColor='primary'
      indicatorColor='primary'
      variant='scrollable'
      value={this.crfTab()}
      onChange={(e, crfTab) =>
        updateSearchAndGo(this.props.history, { crfTab })
      }
    >
      {this.validTabs().map((tabItem, i) => (
        <Tab
          key={i}
          style={{ pointerEvents: 'auto', cursor: 'pointer' }}
          label={tabItem.text}
          value={tabItem.value}
        />
      ))}
    </Tabs>
  );

  render() {
    const { crfData, trialOptionTimezone } = this.state;
    if (!crfData) return <Loading />;

    const { trialOptionId } = this.props;
    const currentTab = this.crfTab();

    return (
      <>
        <div className='crf-container'>
          {this.renderCrfTabBar()}
          {currentTab === ALL ? (
            <TrialOptionCRFAllData
              trialOptionId={trialOptionId}
              crfData={crfData}
              timezone={trialOptionTimezone}
            />
          ) : null}
          {currentTab === CHANGES ? (
            <TrialOptionCRFDataEdits trialOptionId={trialOptionId} />
          ) : null}
          {currentTab === SIGNOFF ? (
            <TrialOptionCRFSignoffs
              trialOptionId={trialOptionId}
              // @ts-ignore
              crfData={crfData}
            />
          ) : null}
        </div>
      </>
    );
  }
}

const TrialOptionCRFComponent: React.ComponentType<{
  trialOptionId: number;
}> = withQueryResult(
  TrialOptionCRFView,
  TrialOptionCrf,
  (props): TrialOptionCrfQueryVariables => ({
    trialOptionId: props.trialOptionId,
  })
);

export default withTranslation('translations')(TrialOptionCRFComponent);
