interface ActiveElement extends Element {
  type?: string;
  blur?: Function;
}

document?.addEventListener('wheel', function () {
  const element: ActiveElement | null = document?.activeElement;
  if (element?.type === 'number') {
    element?.blur?.();
  }
});

export {};
