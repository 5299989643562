import React from 'react';
import _sortBy from 'lodash/sortBy';
import { TrialOption, Note } from '@curebase/core/types';
import { formatNotesTime } from '../../../utils/dates';

import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import Loading from '../../Loading';
import ListItem from '../../basic/ListItem';
import ExpansionPanelItem from '../../basic/ExpansionPanelItem';
import PermissionedButton from '../../basic/PermissionedButton';
import Subheader from '../../basic/Subheader';
import CreateNoteDialog, { NoteForm } from './CreateNoteDialog';
import { useBoolean } from 'src/hooks/useBoolean';
import {
  useTrialOptionNotesQuery,
  TrialOptionNotesQueryVariables,
  useAddTrialOptionNotesMutation,
  AddTrialOptionNotesMutationVariables,
} from '../../../types';
import { capitalizeDisplayName } from 'src/lib/ui';
import FilePreview from 'src/components/basic/FilePreview';
import { useTranslation } from 'react-i18next';
import { RolePermissions } from 'src/types';
import { PII_FIELDS } from '@curebase/core/lib/constants';

interface Props {
  trialOptionId: number;
}

function TrialOptionNotes(props: Props): React.ReactElement {
  const { t } = useTranslation('translations');
  const [noteDialog, showNoteDialog, hideNoteDialog] = useBoolean(false);

  const variables: TrialOptionNotesQueryVariables = {
    trialOptionId: props.trialOptionId,
  };

  const { data, loading, refetch: refetchNotes } = useTrialOptionNotesQuery({
    variables,
  });

  const [addNote] = useAddTrialOptionNotesMutation();

  if (loading || !data) {
    return <Loading />;
  }

  const trialOption = data.getTrialOption;
  const notes = _sortBy(trialOption.notes, ['createdAt'], ['asc']);
  const hasNotes: boolean = notes && notes.length > 0;

  const subheaderButton: React.ReactElement = (
    <PermissionedButton
      disabled={false}
      variant='contained'
      color='primary'
      buttonText={t('trialOptionNotes.addNoteBtn')}
      onClick={showNoteDialog}
      permission={RolePermissions.CreateParticipantNote}
    >
      <AddIcon />
    </PermissionedButton>
  );

  const getExpansionPanelItemProps = (note: Note) => {
    const { title, content, files, createdAt } = note;
    let buttons: React.ReactNode[] = [];

    const name: string = capitalizeDisplayName(note?.authoredByUser, t);
    const time: string = formatNotesTime(createdAt);
    const subtitleMetadata: string = t('trialOptionNotes.subtitleMetadata', {
      name,
      time,
    });

    const subtitle: React.ReactNode = (
      <>
        {files.length > 0 && <AttachFileIcon fontSize='inherit' />}
        {subtitleMetadata}
      </>
    );

    const details: React.ReactNode = (
      <div>
        <div>{t(`piiMessages.${content}`, content)}</div>

        <h3>{t('common.files')}</h3>

        <FilePreview
          disabled
          files={files.map(file => ({
            path: file.key,
            type: file.type,
            name: file.name,
          }))}
        />
      </div>
    );

    return {
      title: t(`piiMessages.${title}`, title),
      subtitle,
      details,
      buttons,
      disabled: title === PII_FIELDS.USER_WITHOUT_PERMISSIONS_TO_SEE_NOTE,
    };
  };

  const onSubmitNote = async (
    data: NoteForm,
    onSubmitCallBack?: () => void
  ): Promise<void> => {
    const input = {
      title: data.title,
      content: data.content,
      // @ts-ignore
      files: data.files.map(file => file.fileId),
      trialOptionId: props.trialOptionId,
      isPII: data.isPII,
    };

    const variables: AddTrialOptionNotesMutationVariables = { input };

    await addNote({ variables });
    await refetchNotes?.();

    if (typeof onSubmitCallBack === 'function') {
      onSubmitCallBack();
    }

    hideNoteDialog();
  };

  return (
    <>
      <Subheader
        text={t('trialOptionNotes.subheader')}
        buttons={[subheaderButton]}
      />
      <div className='li-multi-container inset no-shadow'>
        {React.Children.toArray(
          notes.map(
            (note: Note): React.ReactNode => (
              <ExpansionPanelItem {...getExpansionPanelItemProps(note)} />
            )
          )
        )}

        {!hasNotes && (
          <ListItem
            // @ts-ignore
            middle={{
              title: t('trialOptionNotes.noNotesFoundTitle'),
            }}
          />
        )}
      </div>
      <CreateNoteDialog
        trialOption={trialOption as TrialOption}
        open={noteDialog}
        onClose={hideNoteDialog}
        onSubmit={onSubmitNote}
      />
    </>
  );
}

export default TrialOptionNotes;
