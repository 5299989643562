import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import withPermission from '../hocs/WithPermission';
import { CaseReportDisplayMode, RolePermissions } from '@curebase/core/types';
import DialogWithChildren from '../basic/DialogWithChildren';
import FormDialog from '../basic/FormDialog';
import { editCapturedData } from '../../controllers/queryEditsController';
import { generateDynamicFormPagesFromCaseReport } from '../../lib/caseReports';
import { markDependenciesOnChange } from '@curebase/core/lib/dynamicform/dynamicForms';
import { TrialOptionViewContext } from '../TrialOption/TrialOptionView';
import { FullDataFieldFragment } from '@curebase/core/types';
import { OptionalityVisibility } from 'src/types';

type DataFieldValue = any;

type FormResultsData = {
  capturedData: {
    authoredByUser: any;
    capturedDataChanges: Array<any>;
    id: number;
    queries: Array<any>;
    submittedOn: any;
    value: DataFieldValue;
  };
  dataField: Omit<FullDataFieldFragment, 'caseReport'>;
  trialOptionId?: number;
};

type FormData = {
  editReason?: string;
};

type Props = {
  capturedDataId?: number;
  initialReason?: string | null | undefined;
  caseReportCapturedData?: FormResultsData[];
  onClose: () => any;
  onSubmit: () => any;
} & WithTranslation;

const editReasonElement = t => ({
  title: t('queries.dialogs.edit.editReasonQuestion'),
  note: t('queries.dialogs.blindingWarning'),
  subElements: [
    {
      key: 'editReason',
      type: 'PARAGRAPH',
      placeholder: t('queries.dialogs.edit.editReasonPlaceholder'),
    },
  ],
});

class EditDataDialog extends React.Component<Props> {
  state = {
    allElements: null,
    attemptingToEditComputer: false,
    initialFormData: null,
    pageElements: [],
  };

  createInitialFormData(caseReportCapturedData: FormResultsData[]) {
    const initialFormState = {
      editReason: this.props.initialReason ?? '',
    };

    for (const cd of caseReportCapturedData) {
      const { dataField, capturedData } = cd;
      initialFormState[dataField.id] = capturedData.value;

      // Remove anything that isn't dependent on the primary question or one of its children
    }

    return initialFormState;
  }
  // 1. Remove any dataField thats are ordered earlier than minmumOrder

  removeAnythingThatIsntDependentOnPrimaryQuestion(
    // 2. calculate all keys that should be included
    // They must be dependent on the primary question (at least in directly through another question)
    allCaseReportCapturedData: FormResultsData[],
    minimumOrder: number
  ) {
    const caseReportCapturedData = allCaseReportCapturedData.filter(
      cd => cd.dataField.order >= minimumOrder
    );
    caseReportCapturedData.sort((a, b) =>
      a.dataField.order > b.dataField.order ? 1 : -1
    );
    const goodKeys = [caseReportCapturedData[0].dataField.slug];

    for (const cd of caseReportCapturedData) {
      const { dataField } = cd;
      const { dependsOn } = dataField;
      // this will need to be updated maybe?

      if (dependsOn?.some(({ key }) => goodKeys.includes(key))) {
        goodKeys.push(dataField.slug);
      }
    }

    return caseReportCapturedData.filter(({ dataField }) =>
      goodKeys.includes(dataField.slug)
    );
  }

  async componentDidMount() {
    const {
      t,
      caseReportCapturedData: allCaseReportCapturedData = [],
      capturedDataId: targetCapturedDataId,
    } = this.props;
    const primaryDataField = allCaseReportCapturedData.find(
      cd => cd.capturedData.id === targetCapturedDataId
    )?.dataField;
    if (!primaryDataField) return;
    if (primaryDataField.dataType === 'COMPUTED') {
      this.setState({
        attemptingToEditComputer: true,
      });
    }
    // Just for the data edit modal:
    //   Remove any dependencies from the primary question data field so it always displays
    const minmumOrder = primaryDataField.order;
    const caseReportCapturedData = this.removeAnythingThatIsntDependentOnPrimaryQuestion(
      allCaseReportCapturedData,
      minmumOrder
    );
    const caseReport = {
      displayMode: CaseReportDisplayMode.Default,
      optionalityVisibility: OptionalityVisibility.ShowOptional,
      dataFields: caseReportCapturedData.map(({ dataField }) => {
        let dataFieldToReturn = { ...dataField };
        if (dataField.id === primaryDataField.id && dataField.dependsOn) {
          dataFieldToReturn.dependsOn = [];
        }

        return dataFieldToReturn;
      }),
    };
    const pages = generateDynamicFormPagesFromCaseReport(caseReport);
    const pageElements = pages[0]?.elements ?? [];
    const allElements = [...pageElements, editReasonElement(t)];
    const initialFormData = this.createInitialFormData(caseReportCapturedData);
    this.setState({
      allElements,
      initialFormData,
    });
  }

  onSubmit = async (formData: FormData) => {
    const {
      capturedDataId,
      onSubmit,
      onClose,
      caseReportCapturedData,
    } = this.props;

    if (!capturedDataId) return;

    const [crf] =
      caseReportCapturedData?.filter(
        cr => cr.capturedData.id === capturedDataId
      ) ?? [];
    const { editReason } = formData;
    delete formData.editReason;
    await editCapturedData(
      capturedDataId,
      formData,
      String(editReason),
      crf?.trialOptionId
    );
    onClose();
    onSubmit();
  };

  onChange = (
    key: string,
    value: any,
    formData: FormData,
    updateFn: (data: Object) => void
  ) => {
    const proposedNewPageData = {
      ...formData,
      [key]: value,
    };
    const newData = markDependenciesOnChange(
      proposedNewPageData,
      this.state.allElements
    );
    updateFn(newData);
  };

  render() {
    const { capturedDataId, onClose, t } = this.props;

    const {
      allElements,
      attemptingToEditComputer,
      initialFormData,
    } = this.state;
    if (!capturedDataId || !initialFormData) return null;

    if (attemptingToEditComputer) {
      const closeComputerEdit = () => {
        this.setState({
          attemptingToEditComputer: false,
        });
        return onClose();
      };
      return (
        <DialogWithChildren
          open={!!capturedDataId}
          onClose={closeComputerEdit}
          title={t('queries.dialogs.edit.error.title')}
          children={
            <div className='edit-data-dialog-computer'>
              {t('queries.dialogs.edit.error.message')}
            </div>
          }
        />
      );
    }

    return (
      <TrialOptionViewContext.Consumer>
        {({ trialOptionViewRefetch }) => (
          <FormDialog
            key={capturedDataId}
            open={!!capturedDataId}
            onClose={onClose}
            // we do this so we can refetch the trialOption status in the top header of the page.
            title={t('queries.dialogs.edit.title')}
            initialState={initialFormData}
            onChange={this.onChange}
            onSubmit={async formData => {
              await this.onSubmit(formData);
              await trialOptionViewRefetch();
            }}
            dynamicFormPages={[
              {
                // @ts-ignore
                elements: allElements,
              },
            ]}
          />
        )}
      </TrialOptionViewContext.Consumer>
    );
  }
}

export default withPermission({
  permission: RolePermissions.EditCapturedData,
})(withTranslation('translations')(EditDataDialog));
