import { getUser } from '../store';
import { getRoleName } from '@curebase/core/lib/authorization';
import {
  assignUserToZendeskWidget,
  prefillZendeskWidget,
  removeUserFromZendeskWidget,
} from './zendeskWidget';
import { getUserPatientIds } from './auth';

export const userIsParticipant = () => {
  if (getUserPatientIds()?.length > 0) {
    return true;
  }
  return false;
};

export const getViewPatientBaseUrl = (trialOptionId: number) => {
  if (userIsParticipant()) return `/u/dashboard/patient/${trialOptionId}`;
  if (!userIsParticipant()) return `/u/participants/${trialOptionId}`;
  throw new Error(`Invalid arguments to build patient baseUrl:
    trialOptionId = ${trialOptionId || ''}}`);
};

export const interfaceType = (t?: any) => {
  const roles = getUser().roles;

  //Note: since we're NOT going to have conflicting roles (as in you cannot be a MEDICAL_MONITOR & a CRC at the same time)
  //using the first role for display purposes should be ok.
  if (roles && roles.length >= 1) {
    return getRoleName(roles[0].type, t);
  }
};

export function handleUserChangeInStore(user: any) {
  if (user.userId) {
    const { phoneNumber, email } = user;
    let firstName, lastName;
    if (user.additionalUserInfo) {
      firstName = user.additionalUserInfo.firstName;
      lastName = user.additionalUserInfo.lastName;
    }
    const name = `${firstName || ''} ${lastName || ''}`.trim();
    assignUserToZendeskWidget(name, email);
    prefillZendeskWidget(name, email, phoneNumber);
  } else {
    removeUserFromZendeskWidget();
  }
}

export function getProviderClinicIds() {
  const user = getUser();
  const {
    additionalUserInfo: { roles = [] },
  } = user;
  const res: any[] = [];
  roles.forEach(elem => {
    if (elem.clinic?.id) res.push(elem.clinic.id);
  });
  return res;
}
