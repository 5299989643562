import React from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

export default function OptionalChip() {
  const { t } = useTranslation('translations');
  return (
    <div>
      <Chip
        size='small'
        style={{
          fontSize: 11,
          height: 16,
          fontWeight: 'normal',
        }}
        label={t('actionCard.optionalTask', 'Optional task')}
      />
    </div>
  );
}
