import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirstGeocodeFromAddress } from '../../../lib/booker';

interface BookerConfirmZipcodeProps {
  zipcodeValue: string;
  onChangeZipcodeValue: (arg0: string) => any;
  onConfirmOrUpdateZipcode: (arg0: string) => any;
  isReschedule: boolean;
  onBack?: () => void | Promise<void>;
}

function BookerConfirmZipcode(props: BookerConfirmZipcodeProps) {
  const { t } = useTranslation('translations');

  const [error, setError] = useState<string | null>();

  const {
    zipcodeValue,
    onChangeZipcodeValue,
    onConfirmOrUpdateZipcode,
    isReschedule,
    onBack,
  } = props;

  return (
    <div className='booker-text-container'>
      <div className='booker-title'>
        {!isReschedule
          ? t('bookerConfirmZipcode.noReschedule.title')
          : t('bookerConfirmZipcode.reschedule.title')}
      </div>

      {onBack && (
        <div className='booker-back-button' onClick={onBack}>
          ← {t('common.goBack')}
        </div>
      )}

      <div className='booker-text'>
        {!isReschedule
          ? t('bookerConfirmZipcode.noReschedule.text')
          : t('bookerConfirmZipcode.reschedule.text')}
      </div>

      <div className='booker-text-input-container'>
        <input
          key='zipcode-input'
          id='zipcode-input'
          className='booker-input'
          type='text'
          value={zipcodeValue}
          onChange={e => onChangeZipcodeValue(e.target.value)}
        />
      </div>
      {error && <div className='booker-text error'>{error}</div>}

      <div className='booker-button-container'>
        <div
          className='booker-button'
          role='button'
          onClick={async () => {
            setError(null);
            if (zipcodeValue.match(/^\s*$/)) {
              setError(t('bookerConfirmZipcode.zipcode.empty'));
              return;
            }
            const patientGeo = await getFirstGeocodeFromAddress(zipcodeValue);
            if (!patientGeo) {
              setError(t('bookerConfirmZipcode.zipcode.invalid'));
              return;
            }

            await onConfirmOrUpdateZipcode(props.zipcodeValue);
          }}
        >
          {t('bookerConfirmZipcode.confirm')}
        </div>
      </div>
    </div>
  );
}

export default BookerConfirmZipcode;
