import React from 'react';
import { VisitOptionWithCoordinates } from 'src/lib/booker';
import OneClickPicker from '../../basic/OneClickPicker';
import { userIsParticipant } from 'src/lib/users';
import { BookerRootContext } from './BookerDialog';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBookerVirtualVisitSiteInfoQuery } from 'src/types';
import Loading from 'src/components/Loading';

interface BookerVirtualVisitSelectSiteProps {
  visitOptions: VisitOptionWithCoordinates[];
  onSelectVisitSite: (arg0: number) => any;
  onBack: () => void | Promise<void>;
  backButtonText: string;
  trialOptionId: number;
}

function BookerVirtualVisitSelectSite({
  visitOptions,
  onSelectVisitSite,
  onBack,
  backButtonText,
  trialOptionId,
}: BookerVirtualVisitSelectSiteProps) {
  const { t } = useTranslation('translations');
  const { config } = React.useContext(BookerRootContext);
  const { bookerSelectSiteGenericMessage } = useFlags();

  const { data, loading } = useBookerVirtualVisitSiteInfoQuery({
    variables: {
      trialOptionId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  const { id: visitSiteId = null } = data?.getTrialOption?.visitSite || {
    id: null,
  };

  // TODO: Fetch this from a table, no?!
  const supportContact = {
    email: 'support@mycurebase.com.',
  };

  // Use the feature flag here to determine which text to display to the user
  const participantTitle = bookerSelectSiteGenericMessage
    ? t('bookerVirtualVisitSelectSite.title.participantGeneric')
    : t('bookerVirtualVisitSelectSite.title.participant');
  const participantText = bookerSelectSiteGenericMessage
    ? t('bookerVirtualVisitSelectSite.text.participantGeneric')
    : t('bookerVirtualVisitSelectSite.text.participant');

  if (visitSiteId) {
    // If participant already has a clinic/site assigned, then auto select it
    onSelectVisitSite(visitSiteId);
  }

  return (
    <>
      <div className='booker-text-container'>
        <div className='booker-title'>
          {visitOptions.length === 0
            ? t('bookerVirtualVisitSelectSite.title.noOptions')
            : config?.default.appointmentBookingInfo
                ?.appointmentBookingHeader ??
              (userIsParticipant()
                ? participantTitle
                : t('bookerVirtualVisitSelectSite.title.nonParticipant'))}
        </div>

        <div className='booker-back-button' onClick={onBack}>
          {backButtonText}
        </div>

        <div className='booker-text'>
          {visitOptions.length === 0
            ? t('bookerVirtualVisitSelectSite.text.noOptions', {
                supportEmail: supportContact.email,
              })
            : config?.default.appointmentBookingInfo?.appointmentBookingDesc ??
              (userIsParticipant()
                ? participantText
                : t('bookerVirtualVisitSelectSite.text.nonParticipant'))}
        </div>

        <div className='booker-ocp-container'>
          <OneClickPicker
            options={visitOptions.map(
              (visitOption: VisitOptionWithCoordinates) => ({
                title: visitOption.siteName,
                subtitle:
                  config?.default.appointmentBookingInfo
                    ?.virtualVisitBookingItemDescription ??
                  t('bookerVirtualVisitSelectSite.pickerSubtitle'),
                value: `${visitOption.visitSiteId}`,
              })
            )}
            onSelect={option => onSelectVisitSite(parseInt(option))}
          />
        </div>
      </div>
    </>
  );
}

export default BookerVirtualVisitSelectSite;
