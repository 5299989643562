import _pickBy from 'lodash/pickBy';
import { isValidDate } from '@curebase/core/lib/dates';
import { dependencyMet } from '@curebase/modules/dataCapture/services';
import {
  AcceptedCurrency,
  CaseReportDataType,
  DataCaptureReserved,
} from '@curebase/core/types';
import {
  PageProps,
  ElementProps,
  NonCaseReportDataType,
  CurrencySubElement,
  medKeys,
} from './types';
import { isInRange } from '@curebase/core/lib/height';

const tMock = (a: any, b: any) => b || a;
interface DatumForReview {
  title: string;
  values: any[];
}
export function getDataForReview(
  pages: PageProps[],
  data: {
    [key: string]: any;
  }
): DatumForReview[] {
  const returnData: DatumForReview[] = [];
  const elements: ElementProps[] = [];
  for (const page of pages) {
    elements.push(...page.elements);
  }
  for (const element of elements) {
    const returnDataElement: DatumForReview = {
      title: element.title,
      values: [],
    };
    for (const subElement of element.subElements) {
      let value;
      if (subElement.options) {
        const rawValue = data[subElement.key];
        const options =
          typeof subElement?.options === 'function'
            ? subElement?.options(data) ?? []
            : subElement?.options ?? [];
        const foundOption = options.find(opt => opt.value === rawValue);
        if (foundOption) {
          value = foundOption.text;
        } else {
          value = rawValue;
        }
      } else {
        value = data[subElement.key];
      }
      let displayValue;
      if (typeof value === 'boolean') {
        displayValue = value ? 'Yes' : 'No';
      } else {
        displayValue = value;
      }
      returnDataElement.values.push(displayValue);
    }
    returnData.push(returnDataElement);
  }
  return returnData;
}

export function validateDynamicFormPages(
  pages: PageProps[],
  data: Record<string, any> = {},
  skipNullCheck?: boolean,
  knownErrors: {
    [key: string]: any;
  } = {},
  isOnChange?: boolean,
  t: any = tMock
) {
  let errors = {};
  pages.forEach(page => {
    errors = {
      ...errors,
      ...validateDynamicFormPage(
        page,
        data,
        skipNullCheck,
        knownErrors,
        isOnChange,
        t
      ),
    };
  });
  return errors;
}

interface ValidationResult {
  valid: boolean;
  message?: string;
}

function validateCurrencyElement(
  enteredData: any,
  subEle: CurrencySubElement,
  t: any = tMock
): ValidationResult {
  const { amount, currency } = enteredData;
  const parsedAmount = Number(amount);

  if (isNaN(parsedAmount) || parsedAmount <= 0) {
    return {
      valid: false,
      message: t(
        'dynamicForm.validation.currency.validAmount',
        'Must be a valid amount'
      ),
    };
  }

  if (subEle.max && parsedAmount > subEle.max) {
    return {
      valid: false,
      message: t(
        'dynamicForm.validation.currency.exceedsAmount',
        'Exceeds the allowed amount'
      ),
    };
  }

  if (
    ![
      AcceptedCurrency.Usd,
      AcceptedCurrency.Cad,
      AcceptedCurrency.Gbp,
      AcceptedCurrency.Eur,
    ].includes(currency)
  ) {
    return {
      valid: false,
      message: t(
        'dynamicForm.validation.currency.invalidCur',
        'Invalid currency'
      ),
    };
  }

  return { valid: false };
}

export function validateDynamicFormPage(
  page: PageProps,
  data: {
    [key: string]: any;
  } = {},
  skipNullCheck?: boolean,
  knownErrors: {
    [key: string]: any;
  } = {},
  isOnChange?: boolean,
  t: any = tMock
) {
  let errors: any = {};
  // [DT] - caused crashes in provider consent
  if (!page) return errors;

  for (const ele of page.elements || []) {
    const hiddenByDependency = !dependencyMet(
      ele.dependsOn,
      ele.dependsOnOperator,
      data
    );

    if (hiddenByDependency) {
      continue;
    }

    for (const subEle of ele.subElements) {
      const enteredData = data[subEle.key];
      const isArray = enteredData && Array.isArray(enteredData);
      if (
        !skipNullCheck &&
        !subEle.allowNull &&
        subEle.type === CaseReportDataType.Slider &&
        isNaN(parseInt(enteredData)) &&
        !hiddenByDependency &&
        !(isOnChange && subEle.skipValidateOnChange)
      ) {
        errors[subEle.key] = t(
          'dynamicForm.validation.pages.dragToSelect',
          'Please drag to select a value'
        );
      } else if (
        !skipNullCheck &&
        !subEle.allowNull &&
        subEle.type !== CaseReportDataType.Slider &&
        !((isArray && enteredData.length > 0) || (!isArray && enteredData))
      ) {
        // @ts-ignore
        if (subEle.type !== NonCaseReportDataType.Signature) {
          errors[subEle.key] = t(
            'dynamicForm.validation.pages.cannotEmpty',
            'Cannot be empty'
          );
        } else {
          // @ts-ignore
          errors[subEle.key] = t(
            'dynamicForm.validation.pages.drawSignature',
            'Please draw your signature above'
          );
        }
      } else if (
        !skipNullCheck &&
        !subEle.allowNull &&
        subEle.type === 'CHECKBOX' &&
        enteredData !== 'checked'
      ) {
        errors[subEle.key] = t(
          'dynamicForm.validation.pages.mustCheck',
          'You must check this.'
        );
      } else {
        let validationError;
        if (subEle.validate && enteredData) {
          validationError = subEle.validate(enteredData, data);
        }
        if (subEle.type === 'NUMBER' && enteredData) {
          const parsedNumber = Number(enteredData);
          if (isNaN(parsedNumber)) {
            validationError = t(
              'dynamicForm.validation.pages.mustBeNumber',
              'Must be a number'
            );
          } else if (subEle.max && parsedNumber > subEle.max) {
            validationError = `${t(
              'dynamicForm.validation.pages.mustLessEqual',
              'Must be less than or equal to'
            )} ${subEle.max}`;
          } else if (subEle.min && parsedNumber < subEle.min) {
            validationError = `${t(
              'dynamicForm.validation.pages.mustGreaterEqual',
              'Must be greater than or equal to'
            )} ${subEle.min}`;
          }
          if (!subEle.allowFloat && Math.floor(parsedNumber) !== parsedNumber) {
            validationError = t(
              'dynamicForm.validation.pages.mustWholeNumber',
              'Must be a whole number.'
            );
          }
        }
        if (subEle.type === NonCaseReportDataType.Currency && enteredData) {
          const { valid, message } = validateCurrencyElement(
            enteredData,
            subEle,
            t
          );

          if (!valid) {
            validationError = message;
          }
        }
        if (subEle.type === 'PHONE_NUMBER' && enteredData) {
          // This regex only validate to only real phonenumbers without international 00 numbers
          // will be only acceptable numbers with country code.
          const re = /^(\+\d{1,2})(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){5})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/;
          const isValid = re.exec(enteredData);
          if (!isValid) {
            validationError = t(
              'dynamicForm.validation.pages.validPhone',
              'Please enter a valid phone number'
            );
          }
        }
        if (subEle.type === 'EMAIL' && enteredData) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const isValid = re.exec(enteredData.toLowerCase());
          if (!isValid) {
            validationError = t(
              'dynamicForm.validation.pages.validEmail',
              'Enter a valid email address.'
            );
          }
        }
        if (subEle.type === 'PIN') {
          if (enteredData && enteredData.trim().length < (subEle.fields || 6)) {
            validationError = t(
              'dynamicForm.validation.pages.pin',
              `Enter all ${subEle.fields || 6} digits`,
              { pinLength: subEle.fields || 6 }
            );
          }
        }
        if (subEle.type === 'DATE' && enteredData) {
          if (!isValidDate(enteredData, subEle.allowAnyFutureDate)) {
            validationError = t(
              'dynamicForm.validation.pages.validDate',
              'Please enter a valid date'
            );
          }
        }
        if (subEle.type === 'DRUGBANK_AUTOCOMPLETE' && enteredData) {
          const {
            medications,
            isOptingOut,
            showFrequency,
            showIndication,
          } = enteredData;

          if (!isOptingOut) {
            for (let j = 0; j < medications.length; j++) {
              const currMed = medications[j];
              // Filter out the keys/fields that need to be validated
              const keys = Object.keys(medKeys).filter(k => {
                switch (k) {
                  case medKeys.other:
                    return false;
                  case medKeys.indication:
                    return showIndication;
                  case medKeys.frequency:
                    return showFrequency;
                  default:
                    return true;
                }
              });

              for (const key of keys) {
                if (key === 'dates' && currMed[key]) {
                  const [start, end] = currMed[key];

                  if (!start || !end) {
                    validationError = t(
                      'dynamicForm.validation.pages.fillDates',
                      'Please fill the dates'
                    );
                    break;
                  }
                }

                if (currMed[key] === undefined) {
                  validationError = t(
                    'dynamicForm.validation.pages.medData',
                    'Finish filling out your med data'
                  );
                  break;
                }
              }
            }
          }
        }
        if (subEle.type === 'LIST') {
          //isOnChange is triggered even when a new List element is added.
          //We dont want to error out here, as the user might not have had enough time submit their answer
          if (isOnChange) continue;

          const { options } = subEle;
          if (!options) continue;

          const listErrors: string[] = [];
          for (const [index, option] of options.entries()) {
            if (option.required) {
              const data = enteredData || [];
              for (const answer of data) {
                if (!answer[index]) {
                  listErrors.push(option.text);
                }
              }
            }
          }
          const unique: Array<string> = [...new Set(listErrors)];
          if (unique && unique.length >= 1) {
            errors[subEle.key] = `${unique.join(',')} ${t(
              'dynamicForm.validation.pages.cannotEmpty',
              'Cannot be empty'
            )}`;
          }
        }

        if (subEle.type === CaseReportDataType.Height && enteredData) {
          if (!isInRange(enteredData, subEle.min, subEle.max)) {
            errors[subEle.key] = t(
              'dynamicForm.validation.pages.heightOutRange',
              'The height is out of range.'
            );
          }
        }

        if (validationError) {
          errors[subEle.key] = validationError;
        }
      }
    }
  }

  errors = {
    ...errors,
    ...knownErrors,
  };

  //@ts-ignore
  return _pickBy(errors, (k, v) => !!(v && v !== {}));
}

// if the change resulted in a dependent question no longer being displayed
// then its answer and its dependents answers should also be marked as NA
// if a question has a value NA, but is now answerable, then the value should be null (so it can be answered)
export const markDependenciesOnChange = (pageData: any, elements: any) => {
  const newPageData = Object.assign({}, pageData);

  for (const ele of elements) {
    const met = dependencyMet(
      ele.dependsOn,
      ele.dependsOnOperator,
      newPageData
    );

    for (const key of ele.subElements.map(({ key }: any) => key)) {
      if (!met) {
        newPageData[key] = DataCaptureReserved.NotApplicableDueToDependency;
      } else if (
        Object.values(DataCaptureReserved).includes(newPageData[key])
      ) {
        newPageData[key] = null;
      }
    }
  }
  return newPageData;
};
