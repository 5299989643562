import React from 'react';
import Box from '@material-ui/core/Box';
import FormResults from 'src/components/basic/FormResults';
import { CapturedData, Visit } from '@curebase/core/types';
import { getReadableValueFromCapturedData } from 'src/lib/caseReports';
import { translateOutSideComponent } from 'src/lib/translate';

type Props = {
  visit: any;
  caseReportInstance: any;
  editable?: boolean;
  refetch?: any;
  showAuditTrail?: boolean;
};

const mapCapturedData = (capturedData: CapturedData[], visit: Visit) => {
  return capturedData
    .sort((a, b) => a.dataField.order - b.dataField.order)
    .map(capturedData => {
      const capturedDataConfig = capturedData.dataField;
      const value = getReadableValueFromCapturedData(
        capturedDataConfig,
        capturedData.value
      );

      const hasError =
        visit.evaluation &&
        visit.evaluation.evaluationNotes &&
        visit.evaluation.evaluationNotes.find(
          en => en.slug === capturedDataConfig.slug
        );

      return {
        capturedData: capturedData,
        dataField: capturedDataConfig,
        dataType: capturedDataConfig.dataType,
        hasError,
        title: translateOutSideComponent(capturedDataConfig.name),
        values: [value],
        trialOptionId: visit.trialOptionId,
      };
    });
};

const CapturedDataView = ({
  visit,
  caseReportInstance,
  editable,
  refetch,
  showAuditTrail,
}: Props) => {
  const renderCaseReportInstance = (visit, caseReportInstance) => (
    <FormResults
      // @ts-ignore
      data={mapCapturedData(caseReportInstance.capturedData, visit)}
      editable={editable}
      showAuditTrail={showAuditTrail}
      refetch={refetch}
    />
  );

  return <Box>{renderCaseReportInstance(visit, caseReportInstance)}</Box>;
};

export default CapturedDataView;
