import React, { useState } from 'react';
import Dialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import useInterval from '../../hooks/useInterval';
import { setMaintenanceModeEnabled } from '../../store/actions/index';

type Props = {
  open: boolean;
};
/*
 * This component informs users that the app has been shut down and is going into maintenance mode.
 * Confirmation of the message will refresh the page and allow the maintenance page to load.
 */
const MaintenanceDialog = (props: Props) => {
  const { open = false } = props;

  const [keepOpen, setKeepOpen] = useState(true);
  const [confirmationText, setConfirmationText] = useState({
    text: 'Go To Maintenance Page',
    count: 10,
  });
  // This just reloads the root page since when the app is in maintenance mode all routes will lead to
  // the maintenance page.
  const reloadMaintenancePage = () => window.location.reload();
  useInterval(() => {
    if (confirmationText.count <= 1 && open) {
      reloadMaintenancePage();
    }
    setConfirmationText(prevState => ({
      ...prevState,
      count: prevState.count - 1,
    }));
  }, 1000);

  return (
    <>
      <Dialog open={open && keepOpen}>
        <ConfirmationInDialog
          title='Curebase is under maintenance'
          message='Curebase is entering maintenance mode. You will soon be logged out of the system. We apologize for any inconvenience. If you need immediate help, contact support@mycurebase.com.'
          onClose={() => {
            setMaintenanceModeEnabled(false);
            setKeepOpen(false);
          }}
          confirmationText={`${confirmationText.text} (${confirmationText.count})`}
          hideCancelButton
          onConfirm={() => reloadMaintenancePage()}
        />
      </Dialog>
    </>
  );
};

export default MaintenanceDialog;
