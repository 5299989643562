import * as _ from 'lodash';

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

export function getTopPriorityTrialOption(trialOptions) {
  return _.sortedUniqBy(trialOptions, (trialOption: any) => {
    if (trialOption.status === 'ENROLLED') {
      return 3;
    }
    if (trialOption.status === 'PASSED_PRESCREENING') {
      return 2;
    }
    return 1;
  })[0];
}

export function formatName({ title, firstName, lastName }) {
  if (title === 'DR') {
    const formattedTitle = formatTitle(title);
    return `${formattedTitle} ${firstName} ${lastName}`;
  }
  return `${firstName} ${lastName}`;
}

export function formatUserType(userType) {
  const userTypeMap = {
    PHYSICIAN: 'Physician',
    NURSE: 'Nurse',
    PATIENT: 'Patient',
    MEDICAL_ADMINISTRATOR: 'Medical administrator',
  };
  return userTypeMap[userType];
}

export function formatTitle(title) {
  const titleMap = {
    MR: 'Mr.',
    MRS: 'Mrs.',
    MS: 'Ms.',
    DR: 'Dr.',
  };
  return titleMap[title];
}

export function formatGender(gender) {
  const genderMap = {
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
  };
  return genderMap[gender];
}

export function formatTrialStatus(trialStatus, trialName) {
  const trialStatusMap = {
    PASSED_PRESCREENING: 'Passed prescreening for',
    ENROLLED: 'Enrolled in',
  };
  const formattedStatus = trialStatusMap[trialStatus];
  return formattedStatus
    ? `${formattedStatus} ${trialName}`
    : 'Not yet considered';
}

export function formatTrialStatusColor(trialStatus) {
  const trialStatusColorMap = {
    PASSED_PRESCREENING: 'yellow',
    CONSENTED: 'yellow',
    ENROLLED: 'green',
    WITHDRAWN: 'gray',
  };
  const formattedColor = trialStatusColorMap[trialStatus];
  return formattedColor || 'Gray';
}

export function statusColorForDaysLeft(daysLeft) {
  if (daysLeft <= 0) {
    return 'red';
  }
  if (daysLeft <= 3) {
    return 'yellow';
  }
  if (daysLeft <= 7) {
    return 'yellow';
  }
  return 'gray';
}
