import moment from 'moment-timezone';
import { forceDownload } from '../lib/downloads';

type Column = {
  name: string;
  key: string;
};

export function createCSV(name: string, rows: Object[], columns: Column[]) {
  let csv = `${columns.map(c => c.name).join(',')}\n`;
  for (const row of rows) {
    csv += columns.map(c => `"${row[c.key]}"`).join(',');
    csv += '\n';
  }

  const blob = new Blob([csv], { type: 'text/csv' });
  const downloadName = `${name}-${moment().format('YYYY-MM-DD.HH.mm')}.csv`;

  return { blob, downloadName };
}

export const createAndDownloadCSV = (
  name: string,
  rows: Object[],
  columns: Column[]
) => {
  const { downloadName, blob } = createCSV(name, rows, columns);
  return forceDownload({ downloadName, blob });
};
