import * as React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

type DataFieldOption = {
  text: string;
};

type Props = {
  values: Array<any>;
  options: Array<DataFieldOption>;
};

class FormResultsListQuestion extends React.Component<Props> {
  renderList = (
    values: Array<any>,
    names: Array<string>,
    index: number,
    renderTotalListCount: boolean
  ) => {
    const rows = values.map((v, i) => ({ value: v, name: names[i] }));
    return (
      <React.Fragment key={index}>
        {renderTotalListCount ? <h4>{index + 1}:</h4> : null}
        <Paper key={index}>
          <Table size='small' aria-label='list-question'>
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow key={row.name}>
                    <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell>
                    <TableCell align='right'>{row.value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  };

  render() {
    const { values, options } = this.props;
    if (!(values && values[0] && values[0][0])) return null;
    const lists = values[0];

    if (typeof lists === 'string') return <span>{lists}</span>;
    const names = options.map(o => o.text);

    return (
      <>
        {lists.map((list, index) =>
          this.renderList(list, names, index, lists.length > 1)
        )}
      </>
    );
  }
}

export default FormResultsListQuestion;
