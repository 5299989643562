import * as React from 'react';
import moment from 'moment-timezone';

import { capitalizeDisplayName } from '../../../lib/ui';
import { getLocaleFormatDates } from '../../../context/localeContext';
import { useTranslation } from 'react-i18next';

type ResolvedQuerySectionProps = {
  query: {
    resolvedOn: string;
    resolvedByUser: {
      displayName: string;
    };
  };
};

const localeFormat = getLocaleFormatDates();
const DATE_FORMAT = localeFormat.moment.dateFormat;

const ResolvedQuerySection = (props: ResolvedQuerySectionProps) => {
  const { t } = useTranslation('translations');
  const { query } = props;
  const dateClosed = moment(query.resolvedOn).format(DATE_FORMAT);
  return (
    <div className='fr-query-resolved' key={query.resolvedOn}>
      <h4>
        {t('formResultsQuerySection.querySection.resolvedByLabel')}:{' '}
        {capitalizeDisplayName(query.resolvedByUser, t)} ({dateClosed})
      </h4>
    </div>
  );
};

export default ResolvedQuerySection;
