import { DependsOnConfig } from '@curebase/modules/dataCapture/services';
import { CaseReportDataType, DependsOnOperator } from '@curebase/core/types';

export enum medKeys {
  medication = 'medication',
  route = 'route',
  dosageStr = 'dosageStr',
  dates = 'dates',
  other = 'other',
  indication = 'indication',
  frequency = 'frequency',
}

export enum NonCaseReportDataType {
  Email = 'EMAIL',
  NewPassword = 'NEW_PASSWORD',
  Password = 'PASSWORD',
  PasswordWithReset = 'PASSWORD_WITH_RESET',
  Pin = 'PIN',
  Signature = 'SIGNATURE',
  Currency = 'CURRENCY',
}

export type GenericOption = {
  text: string;
  value: string;
  values: Function;
  url?: string | null;
};

interface GenericElementProps<Option extends {} = Record<string, any>> {
  type: CaseReportDataType | NonCaseReportDataType;
  options?: Option[] | ((data: any) => Option[]);
  key: string;
  style?: string;
  allowNull?: boolean;
  skipValidateOnChange?: boolean;
  validate?: (value: string, data: Object) => string | undefined;
  readOnly?: boolean;
}

interface MultiselectSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Multiselect;
  options: {
    text: string;
    value: string;
    url: string;
  }[];
  isHorizontal?: boolean;
  horizontalAxisMarkers?: Object;
}

interface MulticheckboxSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Multicheckbox;
  options: GenericOption[];
  isHorizontal?: boolean;
  horizontalAxisMarkers?: Object;
}

export interface DropdownSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Dropdown;
  placeholder?: string;
  options: GenericOption[];
}

interface YesNoSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.YesNo;
}

export interface AutocompleteSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Autocomplete;
  placeholder?: string;
  options: GenericOption[] | ((data: Object) => GenericOption[]);
  additionalSettings?: AutocompleteAdditionalSettings;
}

export interface DrugBankAutocomplete extends GenericElementProps {
  type: typeof CaseReportDataType.DrugbankAutocomplete;
  additionalSettings?: {
    medications?: DrugBankOptions;
  };
}

export interface DrugBankOptions {
  showIndication?: boolean;
  showFrequency?: boolean;
}

export interface AutocompleteAdditionalSettings {
  freeSolo?: boolean;
  multiple?: boolean;
  formatAsId?: boolean;
}

export interface TextSubElement extends GenericElementProps {
  type:
    | typeof CaseReportDataType.Date
    | typeof CaseReportDataType.Datetime
    | typeof NonCaseReportDataType.Email
    | typeof NonCaseReportDataType.NewPassword
    | typeof CaseReportDataType.Paragraph
    | typeof NonCaseReportDataType.Password
    | typeof NonCaseReportDataType.PasswordWithReset
    | typeof CaseReportDataType.PhoneNumber
    | typeof CaseReportDataType.Text;
  placeholder?: string;
  min?: string;
  magicLinkClick?: () => void;
  max?: string;
  noSpellcheck?: boolean;
  allowAnyFutureDate?: boolean;
}

export interface ParagraphSubElement extends TextSubElement {
  type: typeof CaseReportDataType.Paragraph;
  min: never;
  max: never;
}

interface ListSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.List;
  options: {
    text: string;
    placeholder?: string;
    required?: boolean;
    type: 'TEXT' | 'DROPDOWN' | 'MULTISELECT' | 'DATE';
  }[];
}

interface PinSubElement extends GenericElementProps {
  type: typeof NonCaseReportDataType.Pin;
  fields?: number;
}

interface NumberSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Number | typeof CaseReportDataType.Slider;
  min?: number;
  max?: number;
  allowFloat?: boolean;
}

interface SliderSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Number | typeof CaseReportDataType.Slider;
  min?: number;
  max?: number;
  allowFloat?: boolean;
  additionalSettings?: {
    step?: number;
    slider?: SliderAdditionalSettings;
  };
}

export interface SliderAdditionalSettings {
  orientation?: 'vertical' | 'horizontal';
  showSelection?: boolean;
  selectionLabel?: string;
  majorLabel?: SliderMajorLabel;
}

export type SliderMajorLabel = false | { maxLabel?: string; minLabel?: string };

export interface CurrencySubElement extends GenericElementProps {
  type: typeof NonCaseReportDataType.Currency;
  placeholder?: string;
  currency: GenericOption[];
  max?: number;
}

export interface HeightSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Height;
  min: number;
  max: number;
}

interface CheckboxSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Checkbox;
  text: string;
  uncheckedValue?: string;
  checkedValue?: string;
}

export interface SignatureSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.Signature;
}

export interface FuzzyDatePickerSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.FuzzyDatePicker;
}

interface FilePickerSubElement extends GenericElementProps {
  type: typeof CaseReportDataType.File;
  disabled?: boolean;
}

export type SubElementProps =
  | ListSubElement
  | MultiselectSubElement
  | MulticheckboxSubElement
  | YesNoSubElement
  | AutocompleteSubElement
  | DropdownSubElement
  | TextSubElement
  | NumberSubElement
  | SliderSubElement
  | CheckboxSubElement
  | SignatureSubElement
  | FilePickerSubElement
  | PinSubElement
  | CurrencySubElement
  | HeightSubElement
  | FuzzyDatePickerSubElement
  | DrugBankAutocomplete;

export type ElementProps = {
  title: string;
  subElements: SubElementProps[];
  note?: string;
  noteBullets?: string[];
  tooltip?: string;
  dependsOn?: DependsOnConfig | DependsOnConfig[];
  dependsOnOperator?: DependsOnOperator;
};

export type PageProps = {
  headerText?: string;
  elements: ElementProps[];
  knowledgeContent?: Object;
  shallowGutter?: boolean;
};

// TODO: add this to the input type
export type InputValueTypes = {
  [NonCaseReportDataType.Currency]: string;
  [NonCaseReportDataType.Email]: string;
  [NonCaseReportDataType.Password]: string;
  [NonCaseReportDataType.PasswordWithReset]: string;
  [NonCaseReportDataType.Pin]: string;
  [NonCaseReportDataType.Signature]: string;
  [NonCaseReportDataType.Currency]: string;
  [CaseReportDataType.Autocomplete]: {
    predefinedValues?: Array<string>;
    freeResponse?: Array<string>;
  };
  [CaseReportDataType.DrugbankAutocomplete]: {
    medications: Array<{
      medication?: string;
      route?: string;
      dosageStr?: string;
      dates?: Array<string>[];
    }>;
    isOptingOut?: boolean;
  };
  [CaseReportDataType.Checkbox]: Array<string>;
  [CaseReportDataType.Date]: string;
  [CaseReportDataType.MonthPicker]: string;
  [CaseReportDataType.Time]: string;
  [CaseReportDataType.Datetime]: string;
  [CaseReportDataType.Dropdown]: string;
  [CaseReportDataType.File]: Array<{
    name?: string;
    type?: string;
    trialOptionId?: number;
    path?: string;
    fileId?: string;
  }>;
  [CaseReportDataType.List]: Array<Array<string>>;
  [CaseReportDataType.Multicheckbox]: Array<string>;
  [CaseReportDataType.Multiselect]: Array<string>;
  [CaseReportDataType.Number]: Number;
  [CaseReportDataType.PhoneNumber]: string;
  [CaseReportDataType.Slider]: string;
  [CaseReportDataType.Text]: string;
  [CaseReportDataType.Height]: {
    feet?: number;
    inches?: number;
  };
  [CaseReportDataType.YesNo]: string;
  [CaseReportDataType.TelehealthFileUpload]: string;
  [CaseReportDataType.FuzzyDatePicker]: {
    isExactDate?: boolean;
    year?: number;
    month?: number;
    date?: string;
  };
  [CaseReportDataType.Address]: {
    address1?: string;
    city?: string;
    zipCode?: string;
    state?: string;
    address2?: string;
  };
};
