import { jsonPost } from '../lib/fetchHelpers';

export const joinRoom = async (
  meetingUUID: string,
  region: string,
  name: string,
  isRequestingPhotobooth: boolean,
  mockTime?: number | null
): Promise<{ meeting: any; attendee: any; error?: string }> => {
  return await jsonPost('/telemed/join', {
    meetingUUID,
    region,
    name,
    isRequestingPhotobooth,
    mockTime,
  });
};

export const unlockDataFieldForUpload = async (
  meetingUUID: string,
  dataFieldId: string,
  visitId: number
): Promise<any> => {
  return await jsonPost('/telemed/unlock', {
    meetingUUID,
    dataFieldId,
    visitId,
  });
};

export const uploadScreenshot = async (
  meetingUUID: string,
  filePath: string,
  fileId: string,
  dataFieldId: string,
  visitId: number,
  mockTime?: number
): Promise<any> => {
  return await jsonPost('/telemed/screenshot', {
    meetingUUID,
    filePath,
    fileId,
    dataFieldId,
    visitId,
    mockTime,
  });
};

export const deleteScreenshot = async (
  fileId: string,
  dataFieldId: string,
  visitId: number,
  mockTime?: number
): Promise<any> => {
  return await jsonPost('/telemed/delete/screenshot', {
    fileId,
    dataFieldId,
    visitId,
    mockTime,
  });
};

export const sendTelehealthHeartbeat = async (
  meetingUUID: string,
  deviceType: string
): Promise<any> => {
  return await jsonPost('/telemed/heartbeat', { meetingUUID, deviceType });
};

export const deleteAttendeeNow = async (
  meetingUUID: string,
  attendeeId: string
): Promise<any> => {
  return await jsonPost('/telemed/deleteattendee', { meetingUUID, attendeeId });
};

export const getAttendeeName = async (
  meetingTitle: string,
  attendeeId: string
): Promise<any> => {
  return await jsonPost('/telemed/name', { meetingTitle, attendeeId });
};

export const sendLinkToMobileTelehealthPhotobooth = async (
  meetingUUID: string,
  mockTime?: number
) => {
  return await jsonPost('/telemed/linkphotobooth', { meetingUUID, mockTime });
};
