import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

class Spacer extends React.Component<Props, {}> {
  render() {
    const { width, height } = this.props;
    return (
      <div
        style={{
          width: width || 1,
          height: height || 1,
          display: 'flex',
          flex: '0 0 100%',
        }}
      />
    );
  }
}

export default Spacer;
