import { sendEventsToMobile } from '../utils/mobileHelpers';

export function forceDownload(params: any) {
  const downloadAnchor: any = document.createElement('a');
  downloadAnchor.style = 'display: none';

  const blobMode = !!params.blob;

  if (blobMode) {
    downloadAnchor.href = window.URL.createObjectURL(params.blob);
    downloadAnchor.download = params.downloadName;
  } else {
    downloadAnchor.href = params.downloadUrl;
    downloadAnchor.target = '_blank';
  }

  const reactNativeWebView = window['ReactNativeWebView'];
  if (reactNativeWebView) {
    const pathName = new URL(downloadAnchor.href).pathname;
    sendEventsToMobile('FILE_DOWNLOAD', {
      url: downloadAnchor.href,
      fileName: pathName.substr(pathName.lastIndexOf('/') + 1),
    });
  } else {
    document.body.appendChild(downloadAnchor);
    setTimeout(function () {
      downloadAnchor.click();
      if (blobMode) {
        window.URL.revokeObjectURL(downloadAnchor.href);
      }
      document.body.removeChild(downloadAnchor);
    }, 50);
  }
}
