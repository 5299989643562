import { CTypography } from '@curebase/design-system';
import { LinearProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckedIcon from '../icons/CheckedIcon';
import { WarningIcon } from '../icons/WarningIcon';
import StandardButton from './StandardButton';

type Props = {
  title: string;
  description: string;
  progress: number;
  timeToCompletion: number;
  downloadFileName: string;
  onDownloadClick: () => void;
  hidden: boolean;
  failed?: boolean;
  failedMessage?: string;
};

const ProgressText = ({ children, failed = false }) => {
  return (
    <div
      style={{
        lineHeight: 1.43,
        color: failed ? '#A91F1D' : '#6E6F72',
        fontSize: '14px',
        fontFamily: 'Lato',
        fontWeight: 400,
        textAlign: 'left',
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

const InProgress = ({
  progress,
  timeToCompletion,
}: Pick<Props, 'progress' | 'timeToCompletion'>) => {
  const { t } = useTranslation('translations');
  const progressText = t('downloadProgressCard.progress', {
    progress: progress.toFixed(0),
    timeToCompletion,
  });

  return (
    <>
      <ProgressText>{progressText}</ProgressText>
      <LinearProgress
        sx={{
          backgroundColor: '#E9E9EB',
          borderRadius: '100px',
          height: '10px',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#325F64',
          },
        }}
        value={progress}
        variant='determinate'
      />
    </>
  );
};

const Finished = ({ downloadFileName }: Pick<Props, 'downloadFileName'>) => {
  return (
    <ProgressText>
      {' '}
      <CheckedIcon /> 100% | {downloadFileName}
    </ProgressText>
  );
};

const Failed = ({ failedMessage }: Pick<Props, 'failedMessage'>) => {
  return (
    <ProgressText failed>
      <WarningIcon /> {failedMessage}
    </ProgressText>
  );
};

export const DownloadProgressCard = ({
  title,
  description,
  progress,
  timeToCompletion,
  downloadFileName,
  onDownloadClick,
  hidden,
  failed = false,
  failedMessage,
}: Props) => {
  const isInProgress = !failed && progress !== 100;
  return (
    <div className={`download-progress-card ${hidden ? 'hidden' : ''}`}>
      <div className='left'>
        <div className='title'>
          <CTypography type='title-regular'>{title}</CTypography>
        </div>
        {!failed && (
          <div className='subtitle'>
            <CTypography>{description}</CTypography>
          </div>
        )}

        {isInProgress && (
          <InProgress progress={progress} timeToCompletion={timeToCompletion} />
        )}
        {!isInProgress && !failed && (
          <Finished downloadFileName={downloadFileName} />
        )}
        {failed && <Failed failedMessage={failedMessage} />}
      </div>

      {!isInProgress && !failed && (
        <div className='right'>
          <StandardButton variant='contained' onClick={onDownloadClick}>
            Download
          </StandardButton>
        </div>
      )}
    </div>
  );
};
