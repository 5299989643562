import { TopBarItem } from '@curebase/core/machines/topbar';
import React from 'react';

export interface TopBarContextProps {
  items?: TopBarItem[];
  currentItemIndex?: number;
}

const context = React.createContext<TopBarContextProps>({
  items: [],
  currentItemIndex: 0,
});

export default context;
