import { RolePermissions } from '../../types';

export const technicalTrialManagerPermissions = [
  RolePermissions.CanJoinAnyMeeting,
  RolePermissions.CanUnlockTelehealthFileUpload,
  RolePermissions.CanJoinTelehealthMeeting,
  RolePermissions.CreateVisitExtension,
  RolePermissions.ReverseEnrollment,
  RolePermissions.LegacyAssociates,
  RolePermissions.CanDisableAccounts,
  RolePermissions.EditUserRoles,
  RolePermissions.EditUserDetails,
  RolePermissions.EditAxleScheduling,
  RolePermissions.EditTrialOptionVisitSite,
  RolePermissions.MarkManualPayments,
  RolePermissions.CreateAdHocPayment,
  RolePermissions.EditInvestigatorList,
  RolePermissions.SkipSubmissionTimeCheck,
  RolePermissions.DeployTrialConfig,
  RolePermissions.EditTrialConfig,
  RolePermissions.TechnicalTrialManager,
  RolePermissions.ResetParticipantPayerCredentials,
  RolePermissions.ForceTransitionParticipant,
  RolePermissions.SourceDataVerification,
  RolePermissions.ViewSponsors,
  RolePermissions.ViewAdminTools,
  RolePermissions.ViewCaseReportSignoff,
  RolePermissions.ViewResearchSites,
];
