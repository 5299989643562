import * as React from 'react';
import { useState } from 'react';
import Dialog from './SafeDialog';
import DialogHeader from './DialogHeader';
import DynamicForm, { PageProps } from './DynamicForm';

type Props = {
  open: boolean;
  title: string;
  onChange?: any;
  onClose: () => any;
  onSubmit: (state: any) => Promise<any> | any;
  validationFunc?: (form: Record<string, any>) => object;
  noSubmit?: boolean;
  dynamicFormPages: PageProps[];
  initialState?: Record<string, any> | null;
  children?: React.ReactNode;
  timezone?: string;
};

const FormDialog = (props: Props) => {
  const {
    open,
    title,
    onClose,
    onSubmit,
    dynamicFormPages,
    initialState,
    validationFunc,
    timezone,
    onChange,
  } = props;
  const [form, updateForm] = useState(initialState ?? {});
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const errors = validationFunc ? validationFunc(form) : undefined;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />
      <div className='li-multi-container'>
        {props.children}
        <DynamicForm
          // @ts-ignore
          pages={dynamicFormPages}
          data={form}
          onChange={(key, value) => {
            if (onChange) return onChange(key, value, form, updateForm);
            updateForm({
              ...form,
              [key]: value === '' ? null : value,
            });
          }}
          onSubmit={
            disableSubmit
              ? undefined
              : async () => {
                  setDisableSubmit(true);
                  await onSubmit(form);
                  setDisableSubmit(false);
                }
          }
          errors={errors}
          timezone={timezone}
        />
      </div>
    </Dialog>
  );
};

export default FormDialog;
