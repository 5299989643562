import * as React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Dropdown from '../Dropdown';

type Props = {
  currentClinicId: string | null;
  clinics: Object[];
  history: any;
  t: TFunction;
};

class ClinicDropdown extends React.Component<Props> {
  onClose = (clinicId?: string) => {
    if (clinicId) {
      const currentLocation = window.location.pathname.split('/').pop();
      this.props.history.push(`/u/clinic/${clinicId}/${currentLocation}`);
    }
  };

  render() {
    const { clinics, currentClinicId, t } = this.props;
    if (!currentClinicId) return null;

    const currentClinic = clinics.find((c: any) => c.id === currentClinicId);
    if (!currentClinic) {
      return null;
    }

    return (
      <Dropdown
        dropdownObjects={clinics}
        dropdownTitle={t('clinicDropdown.title')}
        // @ts-ignore
        currentlySelectedName={currentClinic.name}
        // @ts-ignore
        onClose={this.onClose}
      />
    );
  }
}

export default withRouter(
  withTranslation('translations')(ClinicDropdown) as any
);
