import { Chip } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isSourceDataVerified: boolean;
}

export const SDVChip = ({ isSourceDataVerified }: Props) => {
  const { t } = useTranslation('translations');

  const sourceDataVerificationStatusText = isSourceDataVerified
    ? t('chipSDV.verified')
    : t('chipSDV.needsVerification');

  return (
    <Chip
      label={sourceDataVerificationStatusText}
      color={isSourceDataVerified ? 'primary' : 'secondary'}
      icon={
        isSourceDataVerified ? <CheckBoxIcon fontSize='small' /> : undefined
      }
    />
  );
};
