import { isNil } from 'lodash';
import curebaseTheme from 'src/styles/themes/curebase';

export const DEFAULT_THEME_PROPS = {
  palette: {
    primary: {
      main: curebaseTheme.colors?.primary as string,
      contrastText: curebaseTheme.contrast?.primary as string,
    },
    secondary: {
      main: curebaseTheme.colors?.secondary as string,
      contrastText: curebaseTheme.contrast?.secondary as string,
    },
  },
  typography: {
    body1: {
      color: '#1d5e63',
      lineHeight: undefined,
    },
    body2: {
      color: '#000',
      fontSize: undefined,
    },
    h2: {
      color: '#1d5e63',
      fontFamily: "'Lora', serif",
      fontWeight: 700,
      fontSize: '30px',
    },
    h3: {
      fontFamily: "'Lora', serif",
      fontWeight: 700,
      fontSize: '30px',
      color: '#000',
    },
    h4: {
      fontFamily: "'Lora', serif",
      fontWeight: 700,
      fontSize: '30px',
      color: '#1d5e63',
    },
    h5: {
      color: '#1d5e63',
    },
    h6: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      color: '#000',
    },
  },
};

/*
 * Help functions:
 */

export function hexToRgb(hex: string) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hexParsed = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexParsed);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function shadeColor(hex: string, percent: number) {
  const { r, g, b } = hexToRgb(hex) || {};
  if (isNil(r) || isNil(g) || isNil(b)) {
    return hex;
  }

  const rn = r < 255 ? parseInt(((r * (100 + percent)) / 100).toString()) : 255;
  const gn = g < 255 ? parseInt(((g * (100 + percent)) / 100).toString()) : 255;
  const bn = b < 255 ? parseInt(((b * (100 + percent)) / 100).toString()) : 255;

  const RR =
    rn.toString(16).length === 1 ? '0' + rn.toString(16) : rn.toString(16);
  const GG =
    gn.toString(16).length === 1 ? '0' + gn.toString(16) : gn.toString(16);
  const BB =
    bn.toString(16).length === 1 ? '0' + bn.toString(16) : bn.toString(16);

  return '#' + RR + GG + BB;
}

export function luminance(r: number, g: number, b: number) {
  const a = [r, g, b].map(v => {
    const f = v / 255;
    return f <= 0.03928 ? f / 12.92 : Math.pow((f + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrast(hex: string) {
  const { r, g, b } = hexToRgb(hex) || { r: 0, b: 0, g: 0 };
  const l = luminance(r, g, b);
  return l >= 0.5 ? '#000' : '#fff';
}

export function isWhite(hex: string) {
  const normalized = (hex || '').toLowerCase();
  return (
    normalized === '#fff' ||
    normalized === '#ffffff' ||
    normalized === '#ffffffff'
  );
}

export function safeGetColorsForTheme({ colors, palette }) {
  return {
    primary: colors?.primary
      ? {
          main: isWhite(colors.primary) ? 'rgba(0, 0, 0, .16)' : colors.primary,
        }
      : {
          main:
            palette?.primary.main || DEFAULT_THEME_PROPS.palette.primary.main,
        },
    secondary: colors?.secondary
      ? {
          main: isWhite(colors.secondary)
            ? 'rgba(0, 0, 0, .16)'
            : colors.secondary,
        }
      : {
          main:
            palette?.secondary.main ||
            DEFAULT_THEME_PROPS.palette.secondary.main,
        },
  };
}
