import {
  CreateCapturedDataRequest,
  CreateCapturedDataResponse,
  CreateVisitExtensionRequest,
  CreateVisitExtensionResponse,
  FileUploadRequest,
  FileUploadResponse,
  FileUploadSuccessRequest,
  FileUploadSuccessResponse,
  SaveDataCaptureResponse,
  signOffCaseReportResponse,
  SignoffCapturedDataRequest,
  SignoffCapturedDataResponse,
  TimeTravelRequest,
  TimeTravelResponse,
  ValidateCapturedDataRequest,
  ValidateCapturedDataResponse,
  SignCaseReportResponse,
  AutocompleteSignCaseReportResponse,
  SignCaseReportRequest,
  OpenForBackFillRequest,
} from '@curebase/core/decoders/data';
import { CreateSignature } from '@curebase/core/decoders/signatures';
import { jsonPost } from '../lib/fetchHelpers';
import { buildFormDataForSubmit } from '../lib/caseReports';
import { UploadProgress } from '../components/basic/FilePreview';
import { ErrorResponse } from '@curebase/core/decoders/patients';

export async function uploadFiles(
  files: any,
  trialOptionId: number,
  onUploadProgress: (name: string, uploadProgress: UploadProgress) => void
): Promise<FileUploadResponse[]> {
  return Promise.all(
    files.map(file => uploadFile(file, trialOptionId, onUploadProgress))
  );
}

export async function uploadFile(
  file: any,
  trialOptionId: number,
  onUploadProgress?: (name: string, uploadProgress: UploadProgress) => void,
  onComplete?: (successStatus: boolean) => void
) {
  const { name, type } = file;
  const fileUploadRequest: FileUploadRequest = {
    name,
    type,
  };
  if (trialOptionId) {
    fileUploadRequest.trialOptionId = trialOptionId;
  }
  const { fields, url, key, fileId }: FileUploadResponse = await jsonPost(
    '/data/files/upload',
    fileUploadRequest
  );

  function progressHandler(progress: ProgressEvent<XMLHttpRequestEventTarget>) {
    const oneMillion: number = 1_000_000;

    const percentDone: number = parseFloat(
      Math.round((progress.loaded / progress.total) * 100).toFixed(2)
    );
    const uploadedMb: number = parseFloat(
      (progress.loaded / oneMillion).toFixed(2)
    );
    const totalMb: number = parseFloat(
      (progress.total / oneMillion).toFixed(2)
    );

    if (onUploadProgress)
      onUploadProgress(name, {
        percentDone,
        uploadedMb,
        totalMb,
      });
  }

  await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    Object.entries(fields).forEach(([key, value]: [string, any]) =>
      formData.append(key, value)
    );
    formData.append('file', file);

    xhr.open('POST', url, true);
    xhr.onload = async function () {
      if (this.status === 204) {
        const successfulRequest: FileUploadSuccessRequest = {
          fileId,
          trialOptionId,
        };
        await uploadSuccessful(successfulRequest);
        if (onComplete) onComplete(true);
        resolve(undefined);
      } else {
        if (onComplete) onComplete(false);
        reject(this.responseText);
      }
    };
    xhr.upload.addEventListener('progress', progressHandler);
    xhr.send(formData);
  });

  return { ...fileUploadRequest, path: key, fileId };
}

export async function uploadSuccessful(
  data: FileUploadSuccessRequest
): Promise<FileUploadSuccessResponse> {
  return jsonPost('/data/files/upload/successful', data);
}

export async function signOffCaseReport(
  caseReportSignoffId: number,
  signature: CreateSignature
): Promise<signOffCaseReportResponse> {
  const request = {
    caseReportSignoffId,
    signature,
  };

  const response: signOffCaseReportResponse = await jsonPost(
    '/data/signcasereport',
    request
  );
  return response;
}

export async function saveDataCapture(
  visitId: number,
  capturedData: Record<string, any>,
  currentDate?: number
): Promise<SaveDataCaptureResponse> {
  const requestBody = buildFormDataForSubmit(
    visitId,
    capturedData,
    currentDate
  );
  const response: SaveDataCaptureResponse = await jsonPost(
    '/data/save',
    requestBody
  );
  return response;
}

export async function signCaseReport(
  body: SignCaseReportRequest
): Promise<SignCaseReportResponse> {
  return await jsonPost('/data/sign', body);
}

export async function submitCapturedData(
  visitId: number,
  mockedDate?: number
): Promise<CreateCapturedDataResponse | ErrorResponse> {
  const requestBody: CreateCapturedDataRequest = {
    visitId,
    mockedDate,
  };
  const response = await jsonPost<
    CreateCapturedDataRequest,
    CreateCapturedDataResponse
  >('/data/create', requestBody);
  return response;
}

export async function submitSkipData(
  visitId: number,
  mockedDate?: number
): Promise<CreateCapturedDataResponse | ErrorResponse> {
  const requestBody: CreateCapturedDataRequest = {
    visitId,
    mockedDate,
  };
  const response = await jsonPost<
    CreateCapturedDataRequest,
    CreateCapturedDataResponse
  >('/data/skip', requestBody);
  return response;
}

export async function autoCompleteSignCaseReport(
  visitId: number
): Promise<AutocompleteSignCaseReportResponse> {
  const requestBody = {
    visitId,
  };
  return await jsonPost('/data/sign-autocomplete', requestBody);
}

export async function signoffCapturedData(
  visitId: number
): Promise<SignoffCapturedDataResponse> {
  const requestBody: SignoffCapturedDataRequest = { visitId };
  const response: SignoffCapturedDataResponse = await jsonPost(
    '/data/signoff',
    requestBody
  );
  return response;
}

export async function validateCapturedData(
  capturedData: Record<string, any>,
  studyPlanVisitId: string,
  trialOptionId: number,
  entireSpv: boolean
): Promise<ValidateCapturedDataResponse> {
  const requestBody: ValidateCapturedDataRequest = {
    capturedData,
    studyPlanVisitId,
    trialOptionId,
    entireSpv,
  };
  const response: ValidateCapturedDataResponse = await jsonPost(
    '/data/validate',
    requestBody
  );
  return response;
}

export async function timeTravelTrialOption(
  dateOffset: number,
  trialOptionId: number
): Promise<TimeTravelResponse> {
  const requestBody: TimeTravelRequest = { dateOffset, trialOptionId };
  return await jsonPost('/data/timeTravel', requestBody);
}

export const createVisitExtension = async (
  visitId: number,
  reason: string,
  dueDate: string,
  timezone: string
): Promise<CreateVisitExtensionResponse> => {
  const requestBody: CreateVisitExtensionRequest = {
    visitId,
    reason,
    dueDate,
    timezone,
  };

  const response: CreateVisitExtensionResponse = await jsonPost(
    '/data/createvisitextension',
    requestBody
  );
  return response;
};

export const openForBackFill = async (
  visitId: number,
  backFillCreatedAt: string
): Promise<void> => {
  const requestBody: OpenForBackFillRequest = {
    visitId,
    backFillCreatedAt,
  };

  await jsonPost('/data/openForBackFill', requestBody);
};
