import showdown from 'showdown';
import {
  ApolloClient,
  QueryOptions,
  OperationVariables,
  NormalizedCacheObject,
} from '@apollo/client';
import { PolicyDocumentType } from '@curebase/core/types';
import {
  defaultPrivacyPolicy,
  defaultTermsOfService,
} from '@curebase/core/lib/legal';
import { getLocale } from '../../context/localeContext';
import { DEFAULT_LOCALE } from '@curebase/core/lib/constants';

export async function libExecuteQuery(
  client: ApolloClient<NormalizedCacheObject>,
  args: QueryOptions<OperationVariables>
): Promise<any> {
  const res = await client.query(args);
  if (res.errors) throw new Error(res.errors.join('\n'));
  if (!res.data) throw new Error('No data returned from graph query!');

  const rootElement = res.data;
  if (!rootElement) throw new Error('Nothing returned from graph query!');
  return rootElement;
}

export type DocumentHTML = { __html: string };
const defaultDocuments = [defaultPrivacyPolicy, defaultTermsOfService];

export async function queryLegalDocumentHTML(
  type: PolicyDocumentType,
  version?: string
): Promise<DocumentHTML> {
  const requiredVersion = type !== PolicyDocumentType.CookiePolicy;

  if (requiredVersion && !version) {
    console.error(`version must be provided for type ${type}`);
  }

  const mapper: Partial<Record<PolicyDocumentType, string>> = {
    [PolicyDocumentType.PrivacyPolicy]: version,
    [PolicyDocumentType.TermsOfService]: version,
    [PolicyDocumentType.CookiePolicy]: 'cookiepolicy.md',
  };

  let legalUrl = `/legal/${mapper[type]}`;
  if (defaultDocuments.includes(mapper[type] || '')) {
    const { locale = DEFAULT_LOCALE } = getLocale();
    legalUrl = `/legal/${locale}/${mapper[type]}`;
  }
  const mdResponse = await fetch(legalUrl);
  const md = await mdResponse.text();
  const converter = new showdown.Converter();
  return { __html: converter.makeHtml(md) };
}
