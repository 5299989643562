import { makeStyles, darken, lighten } from '@material-ui/core';
import { StudyCustomStyle } from '@curebase/core/types/customStyle';

/*
 * Page Header Custom Style
 */

export const createPageHeaderStyle = (style: StudyCustomStyle) => {
  const { colors, contrast } = style || {};
  return makeStyles({
    header: {
      padding: 20,
      backgroundColor: colors?.secondary,
    },
    headerText: {
      color: contrast?.secondary,
    },
    menu: {
      '& path': {
        fill: contrast?.secondary || undefined,
        transform: 'scale(1.2)',
      },
    },
  })();
};

/*
 * Action Card Custom Style
 */

export const createActionCardStyle = (style: StudyCustomStyle) => {
  const { colors } = style || {};
  return makeStyles({
    largeRadial: {
      backgroundColor: colors?.primary
        ? `${lighten(colors?.primary, 0.96)} !important`
        : undefined,
    },
    radial: {
      backgroundColor: colors?.primary
        ? `${lighten(colors?.primary, 0.9)} !important`
        : undefined,
    },
    icon: {
      fill: `${colors?.primary} !important`,
    },
    titles: {
      color: `${colors?.primary} !important`,
    },
  })();
};

export const createActionCardButtonStyle = (
  style: StudyCustomStyle,
  inverted?: boolean
) => {
  const { colors, contrast } = style || {};

  return makeStyles({
    button: {
      border: inverted ? `1px solid ${colors?.primary}` : undefined,
      backgroundColor: `${
        inverted ? contrast?.primary : colors?.primary
      } !important`,
      color: `${inverted ? colors?.primary : contrast?.primary} !important`,
      flexGrow: 1,
      '&:hover': {
        backgroundColor: colors?.primary
          ? `${darken(
              inverted ? contrast?.primary || '#fff' : colors.primary,
              inverted ? 0.1 : 0.5
            )} !important`
          : undefined,
      },
    },
  })();
};
