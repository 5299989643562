import {
  Logger as DDLogger,
  HandlerType,
  StatusType,
  datadogLogs,
} from '@datadog/browser-logs';

// Core components of the application.
type ComponentType = 'telemedicine' | 'consent' | 'visits' | 'payments';

type LoggerFn = (message: string, context?: object) => void;

type LoggerType = {
  getInstance: () => DDLogger; // Direct ref to datadog instance (only for custom use cases).
  info: LoggerFn;
  warn: LoggerFn;
  error: LoggerFn;
  debug: LoggerFn;
};

export const Logger = (
  type: ComponentType,
  handler: HandlerType = 'http'
): LoggerType => {
  let instance = datadogLogs.getLogger(type);

  const formatMessage = (message: string, status: StatusType) =>
    `[${status}] ${type}: ${message}`;

  const defaultLoggers = (instance: DDLogger) => ({
    getInstance: () => instance,
    info: (message: string, context?: object) =>
      instance.info(formatMessage(message, 'info'), context),
    warn: (message: string, context?: object) =>
      instance.warn(formatMessage(message, 'warn'), context),
    error: (message: string, context?: object) =>
      instance.error(formatMessage(message, 'error'), context),
    debug: (message: string, context?: object) =>
      instance.debug(formatMessage(message, 'debug'), context),
  });

  if (instance) {
    return defaultLoggers(instance);
  }

  instance = datadogLogs.createLogger(type, { handler });

  return defaultLoggers(instance);
};
