import { getLocale } from '../context/localeContext';

export interface UserPrivacyPreferences {
  hasConsentGiven: boolean;
  personalInformation?: boolean;
  socialMediaCookie?: boolean;
  performanceCookies?: boolean;
  targetingCookies?: boolean;
  strictlyNecessaryCookies?: boolean;
}

const getCookieUrl = () => {
  const { locale } = getLocale();
  return `/legal/${locale}/cookie-policy.html`;
};
export const COOKIE_POLICY_URL = getCookieUrl();

export function openCookiePolicy() {
  window.open(getCookieUrl(), '_blank');
}

export function getUserPrivacyStatus(): UserPrivacyPreferences {
  const global = window as any;
  return global.getUserPrivacyStatus();
}

export function onPrivacyPreferencesChanges(cb) {
  const global = window as any;
  return global.onPrivacyPreferencesChanges(cb);
}
