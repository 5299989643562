import DiffViewer from '../basic/DiffViewer';
import { useParams } from 'react-router';
import { patchDiff } from 'src/lib/diff';
import { usePreviousVersion } from '../../hooks/usePreviousVersion';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface RevisionSummaryProps {
  version?: string;
  trialIdentifier?: string;
}

function RevisionSummary(props: RevisionSummaryProps) {
  const { t } = useTranslation('translations');
  const params = useParams<{
    trialIdentifier: string;
    version: string;
  }>();
  const { configs, isLoading } = usePreviousVersion(
    params.trialIdentifier,
    params.version
  );

  if (isLoading) {
    return (
      <div className='revision-summary-loading'>
        <CircularProgress size={32} />
        <p>{t('revisionSummary.downloadMsg')}</p>
      </div>
    );
  }

  if (!configs[0] || !configs[1] || configs.length === 0) {
    return (
      <div className='revision-summary--version-one'>
        <p>{t('revisionSummary.versionOneMsg')}</p>
      </div>
    );
  }

  return (
    <DiffViewer
      fromName={configs[0].title}
      toName={configs[1].title}
      patchDiff={patchDiff({
        fromName: configs[0].title,
        toName: configs[1].title,
        from: configs[0].content,
        to: configs[1].content,
      })}
    />
  );
}

export default RevisionSummary;
