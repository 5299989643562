import hostname, { IS_DOCKER_ENV } from '../hostname';

const CUREBASE_DOMAIN = '.curebase.com';

export enum Environments {
  PREVIEW = 'preview',
  DEVELOPMENT = 'development',
  TEST = 'test',
  STAGING = 'staging',
  VALIDATION = 'validation',
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
  EU_STAGING = 'eu-staging',
  EU_VALIDATION = 'eu-validation',
  EU_SANDBOX = 'eu-sandbox',
  EU = 'eu',
  DEMO = 'demo',
  WALGREENS = 'walgreens',
  WALGREENS_SANDBOX = 'walgreens-sandbox',
  WALGREENS_VALIDATION = 'walgreens-validation',
  WALGREENS_STAGING = 'walgreens-staging',
  GENENTECH = 'genentech',
  GENENTECH_SANDBOX = 'genentech-sandbox',
}

export const US_DEFAULT_REGION = 'us-west-2';
export const EU_DEFAULT_REGION = 'eu-west-1';

export const isEnvironment = (environment: Environments): boolean => {
  return hostname.replace(CUREBASE_DOMAIN, '') === environment;
};

export const PROCESS_ENVIRONMENT = (() => Object.assign({}, process.env))();
export const NODE_ENVIRONMENT =
  PROCESS_ENVIRONMENT.NODE_ENV || Environments.DEVELOPMENT;

export const IS_PREVIEW = PROCESS_ENVIRONMENT.IS_PREVIEW || false;

export const DEFAULT_AWS_REGION = (() => {
  const regionMap: Record<string, string> = {
    [Environments.DEVELOPMENT]: US_DEFAULT_REGION,
    [Environments.TEST]: US_DEFAULT_REGION,
    [Environments.STAGING]: US_DEFAULT_REGION,
    [Environments.VALIDATION]: US_DEFAULT_REGION,
    [Environments.SANDBOX]: US_DEFAULT_REGION,
    [Environments.PRODUCTION]: US_DEFAULT_REGION,
    [Environments.EU_STAGING]: EU_DEFAULT_REGION,
    [Environments.EU_VALIDATION]: EU_DEFAULT_REGION,
    [Environments.EU_SANDBOX]: EU_DEFAULT_REGION,
    [Environments.EU]: EU_DEFAULT_REGION,
    [Environments.DEMO]: US_DEFAULT_REGION,
    [Environments.WALGREENS]: US_DEFAULT_REGION,
    [Environments.WALGREENS_SANDBOX]: US_DEFAULT_REGION,
    [Environments.WALGREENS_VALIDATION]: US_DEFAULT_REGION,
    [Environments.WALGREENS_STAGING]: US_DEFAULT_REGION,
    [Environments.GENENTECH]: US_DEFAULT_REGION,
    [Environments.GENENTECH_SANDBOX]: US_DEFAULT_REGION,
  };

  return regionMap[NODE_ENVIRONMENT];
})();

export const IS_TEST = NODE_ENVIRONMENT === Environments.TEST;
export const IS_DEFAULT_STAGING = isEnvironment(Environments.STAGING);
export const IS_DEFAULT_VALIDATION = isEnvironment(Environments.VALIDATION);
export const IS_DEFAULT_SANDBOX = isEnvironment(Environments.SANDBOX);
export const IS_DEFAULT_PRODUCTION = isEnvironment(Environments.PRODUCTION);
export const IS_EU_STAGING = isEnvironment(Environments.EU_STAGING);
export const IS_EU_VALIDATION = isEnvironment(Environments.EU_VALIDATION);
export const IS_EU_SANDBOX = isEnvironment(Environments.EU_SANDBOX);
export const IS_EU = isEnvironment(Environments.EU);
export const IS_DEMO = isEnvironment(Environments.DEMO);
export const IS_WALGREENS = isEnvironment(Environments.WALGREENS);
export const IS_WALGREENS_VALIDATION = isEnvironment(
  Environments.WALGREENS_VALIDATION
);
export const IS_WALGREENS_SANDBOX = isEnvironment(
  Environments.WALGREENS_SANDBOX
);
export const IS_WALGREENS_STAGING = isEnvironment(
  Environments.WALGREENS_STAGING
);
export const IS_GENENTECH = isEnvironment(Environments.GENENTECH);
export const IS_GENENTECH_SANDBOX = isEnvironment(
  Environments.GENENTECH_SANDBOX
);

export const IS_STAGING =
  IS_DEFAULT_STAGING || IS_EU_STAGING || IS_WALGREENS_STAGING;
export const IS_VALIDATION =
  IS_DEFAULT_VALIDATION || IS_EU_VALIDATION || IS_WALGREENS_VALIDATION;
export const IS_SANDBOX =
  IS_DEFAULT_SANDBOX ||
  IS_EU_SANDBOX ||
  IS_DEMO ||
  IS_WALGREENS_SANDBOX ||
  IS_GENENTECH_SANDBOX;
export const IS_PRODUCTION =
  IS_DEFAULT_PRODUCTION || IS_EU || IS_WALGREENS || IS_GENENTECH;
export const IS_DEVELOPMENT =
  !IS_STAGING && !IS_VALIDATION && !IS_SANDBOX && !IS_PRODUCTION;

export const DEVELOPER_FEATURES_ENABLED =
  IS_DEVELOPMENT || IS_STAGING || IS_VALIDATION || IS_SANDBOX;

export const IS_HOSTED_ENVIRONMENT = !IS_DEVELOPMENT;

export const HOSTNAME_ENVIRONMENT = (() => {
  if (IS_DEFAULT_STAGING) return Environments.STAGING;
  if (IS_DEFAULT_VALIDATION) return Environments.VALIDATION;
  if (IS_DEFAULT_SANDBOX) return Environments.SANDBOX;
  if (IS_DEFAULT_PRODUCTION) return Environments.PRODUCTION;
  if (IS_EU_STAGING) return Environments.EU_STAGING;
  if (IS_EU_VALIDATION) return Environments.EU_VALIDATION;
  if (IS_EU_SANDBOX) return Environments.EU_SANDBOX;
  if (IS_EU) return Environments.EU;
  if (IS_DEMO) return Environments.DEMO;
  if (IS_WALGREENS) return Environments.WALGREENS;
  if (IS_WALGREENS_SANDBOX) return Environments.WALGREENS_SANDBOX;
  if (IS_WALGREENS_VALIDATION) return Environments.WALGREENS_VALIDATION;
  if (IS_WALGREENS_STAGING) return Environments.WALGREENS_STAGING;
  if (IS_GENENTECH) return Environments.GENENTECH;
  if (IS_GENENTECH_SANDBOX) return Environments.GENENTECH_SANDBOX;

  return Environments.DEVELOPMENT;
})();

export enum EnvironmentType {
  CUREBASE_US,
  CUREBASE_EU,
  WALGREENS,
  GENENTECH,
}

export const ENVIRONMENT_TYPE = ((): EnvironmentType => {
  if (
    IS_WALGREENS ||
    IS_WALGREENS_VALIDATION ||
    IS_WALGREENS_SANDBOX ||
    IS_WALGREENS_STAGING
  ) {
    return EnvironmentType.WALGREENS;
  }

  if (IS_GENENTECH || IS_GENENTECH_SANDBOX) {
    return EnvironmentType.GENENTECH;
  }

  if (IS_EU || IS_EU_SANDBOX || IS_EU_VALIDATION || IS_EU_STAGING) {
    return EnvironmentType.CUREBASE_EU;
  }

  return EnvironmentType.CUREBASE_US;
})();

export { IS_DOCKER_ENV };

export const PROTOCOL = IS_DEVELOPMENT ? 'http' : 'https';
const PORT = IS_DEVELOPMENT ? '3000' : '443';

export const getAPIHostName = (ENVIRONMENT: Environments) => {
  const HostMap: Record<Environments, string> = {
    [Environments.PREVIEW]: 'localhost',
    [Environments.DEVELOPMENT]: 'localhost',
    [Environments.TEST]: 'localhost',
    [Environments.STAGING]: 'staging.us.curebase.com',
    [Environments.VALIDATION]: 'validation.us.curebase.com',
    [Environments.SANDBOX]: 'sandbox.us.curebase.com',
    [Environments.PRODUCTION]: 'app.us.curebase.com',
    [Environments.EU_STAGING]: 'staging.eu.curebase.com',
    [Environments.EU_VALIDATION]: 'validation.eu.curebase.com',
    [Environments.EU_SANDBOX]: 'sandbox.eu.curebase.com',
    [Environments.EU]: 'app.eu.curebase.com',
    [Environments.DEMO]: 'demo.us.curebase.com',
    [Environments.WALGREENS]: 'app.walgreens.curebase.com',
    [Environments.WALGREENS_SANDBOX]: 'sandbox.walgreens.curebase.com',
    [Environments.WALGREENS_VALIDATION]: 'validation.walgreens.curebase.com',
    [Environments.WALGREENS_STAGING]: 'staging.walgreens.curebase.com',
    [Environments.GENENTECH]: 'app.genentech.curebase.com',
    [Environments.GENENTECH_SANDBOX]: 'sandbox.genentech.curebase.com',
  };

  const hostname = HostMap[ENVIRONMENT];

  return hostname;
};

const API_HOSTNAME = getAPIHostName(HOSTNAME_ENVIRONMENT);

const dockerAppUri = `${PROTOCOL}://${API_HOSTNAME}`;
const dockerApiUri = `${PROTOCOL}://${API_HOSTNAME}/api`;

const legacyAppUri = `${PROTOCOL}://${API_HOSTNAME}:5000`;
const legacyApiUri = `${PROTOCOL}://${API_HOSTNAME}:${PORT}`;

// Note: we use anonymous functions as ternary causes runtime issues with webpack

const appBaseUrl = (() => {
  if (IS_DOCKER_ENV) {
    return dockerAppUri;
  }
  return legacyAppUri;
})();

export const apiBaseUri = (() => {
  if (IS_DOCKER_ENV) {
    return dockerApiUri;
  }
  return legacyApiUri;
})();

export const apiUrl = (() => {
  if (IS_DOCKER_ENV) {
    return '/api';
  }
  return legacyApiUri;
})();

export const baseUrl = appBaseUrl;
export const webBaseUrl = appBaseUrl;

export function secureImageUriFromFileName(fileName: string): string {
  return `${apiBaseUri}/image/download?imageUri=${encodeURIComponent(
    fileName
  )}`;
}

export function secureVideoUriFromFileName(fileName: string): string {
  return `${apiBaseUri}/media/video?fileName=${encodeURIComponent(fileName)}`;
}
