import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightBorder, statusColors } from 'src/shared/lib/colors';

type StyleProps = {
  hasError?: boolean;
};

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 250,
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  field: ({ hasError }: StyleProps) => ({
    padding: '0.25rem 0.5rem',
    backgroundColor: 'white',
    borderRadius: '5px',
    border: `${hasError ? statusColors.red : lightBorder} 1px solid`,
    width: 120,
  }),
  label: {
    fontSize: theme.typography.body2.fontSize,
    marginBottom: 10,
  },
  error: {
    marginTop: 10,
    color: statusColors.red,
  },
}));
