import { TextField } from '@material-ui/core';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
/* eslint-disable react-hooks/exhaustive-deps */

type Option = { text: string; value: string };

interface Props {
  readOnly: boolean;
  value: string;
  onChange: (input: string) => void;
  onSelect: (option: Option) => void;
  buildAutocompleteLazyQuery: () => [Function, string, string];
  buildSelectLazyQuery: () => [Function, string, string];
  transformNewOptions: (
    values: Array<Record<string, unknown>>
  ) => Array<Option>;
  transformSelection: (values: Record<string, unknown>) => any;
  placeholder?: string;
}

function Autocomplete(props: Props) {
  const { t } = useTranslation('translations');
  const {
    readOnly,
    value,
    onChange,
    onSelect,
    transformNewOptions,
    transformSelection,
    buildAutocompleteLazyQuery,
    buildSelectLazyQuery,
    placeholder = t('common.typeSomethingPlaceholder'),
  } = props;

  const [
    lazyAutocompleteQuery,
    autocompleteInputKey,
    autocompleteOutputKey,
  ] = buildAutocompleteLazyQuery();
  const [
    getAutocompleteData,
    { data: autocompleteData },
  ] = lazyAutocompleteQuery();
  const requestOptions = (input = '') =>
    getAutocompleteData({ variables: { [autocompleteInputKey]: input } });
  const options = useMemo(
    () => transformNewOptions(autocompleteData?.[autocompleteOutputKey]),
    [autocompleteData]
  );
  const request = useCallback(debounce(requestOptions, 400), []);

  const [
    lazySelectQuery,
    selectInputKey,
    selectOutputKey,
  ] = buildSelectLazyQuery();
  const [getSelectData, { data: selectData }] = lazySelectQuery();
  const select = useMemo(
    () => transformSelection(selectData?.[selectOutputKey]),
    [selectData]
  );

  useEffect(() => {
    if (select) {
      onSelect(select);
    }
  }, [select]);

  const handleChange = (_: ChangeEvent<HTMLInputElement>, optionSelected) => {
    if (!isEmpty(optionSelected)) {
      getSelectData({ variables: { [selectInputKey]: optionSelected.value } });
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    request.cancel();
    request(event?.target?.value);
    onChange(event?.target?.value);
  };

  return (
    <MaterialAutocomplete
      className='autocomplete'
      value={value}
      inputValue={value}
      freeSolo={true}
      disabled={readOnly}
      options={options}
      getOptionLabel={option => option.text || ''}
      onChange={handleChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: undefined,
          }}
          placeholder={placeholder}
          inputProps={{ ...params.inputProps }}
        />
      )}
      onInput={handleInput}
    />
  );
}

export default Autocomplete;
