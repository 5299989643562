import { StudyActivity } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';
import ActionListItem from '../../basic/ActionListItem';

interface DownloadAppActionListItemProps {
  activity: StudyActivity;
}

function DownloadAppActionListItem(_: DownloadAppActionListItemProps) {
  const { t } = useTranslation('translations');
  return (
    <ActionListItem
      title={t('participants.downloadApp.title', 'Get the Curebase mobile app')}
      description={t(
        'participants.downloadApp.description',
        'Get the Curebase mobile app on your Android or iOS mobile device to complete study activities, schedule appointments, and join calls on the go.'
      )}
      iconSrc={`/icons/download-app.svg`}
    />
  );
}

export default DownloadAppActionListItem;
