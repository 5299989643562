import * as React from 'react';
import {
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataCaptureResults from '../DataCapture/DataCaptureResults';
import {
  EnrollmentResults,
  EnrollmentProperties,
} from '../TrialOption/EnrollmentResults';

export type CrfEntryType = {
  id: number | string;
  studyPlanVisitSlug: string;
  type:
    | 'DATA_CAPTURE'
    | 'ADVERSE_EVENT'
    | 'PROTOCOL_DEVIATION'
    | 'STATE_TRANSITION'
    | 'ENROLLMENT';
  title: string;
  time: string;
  slug?: string;
  visitIds?: number[];
  isFocused?: boolean;
  enrollmentProperties?: EnrollmentProperties;
};

type Props = {
  trialOptionId: number;
  crfData: CrfEntryType[];
  timezone?: string;
};

type State = Array<boolean>;

export const TrialOptionCRFAllData = (props: Props, state: State) => {
  const { trialOptionId, crfData, timezone } = props;

  const [expanded, setExpanded] = React.useState(
    crfData.map(({ isFocused }) => isFocused)
  );

  const content = crfData.map((crfEntry: CrfEntryType, i: number) => {
    const isExpanded = expanded[i];
    return (
      <ExpansionPanel expanded={isExpanded} key={crfEntry.id}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => {
            const newExpanded = [...expanded];
            newExpanded[i] = !newExpanded[i];
            setExpanded(newExpanded);
          }}
        >
          <div className='expansion-summary-content'>
            <span
              className={`summary-time ${
                crfEntry.type === 'STATE_TRANSITION' ? 'red' : ''
              }`}
            >
              {crfEntry.time}
            </span>
            <span className='summary-title'>{crfEntry.title}</span>
          </div>
        </ExpansionPanelSummary>

        {crfEntry.type === 'ENROLLMENT' &&
          isExpanded &&
          !!crfEntry.enrollmentProperties && (
            <ExpansionPanelDetails>
              <div className='expansion-details-content'>
                <EnrollmentResults
                  enrollmentDetails={crfEntry.enrollmentProperties}
                />
              </div>
            </ExpansionPanelDetails>
          )}

        {crfEntry.type === 'DATA_CAPTURE' && isExpanded && (
          <ExpansionPanelDetails>
            <div className='expansion-details-content'>
              <DataCaptureResults
                trialOptionId={trialOptionId}
                studyPlanVisitSlug={crfEntry.studyPlanVisitSlug}
                timezone={timezone}
              />
            </div>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    );
  });
  // have to wrap expansion panels in a div so first element css selector works as expected
  return <div>{content}</div>;
};
