import _ from 'lodash';
import produce from 'immer';
import {
  INIT_TRIAL,
  EDIT_SPONSOR,
  Action,
} from '../../actions/TrialBuilder/types';

//$FlowFixMe
export default produce((state: any, action: Action) => {
  switch (action.type) {
    case INIT_TRIAL:
      return action.payload.sponsor;
    case EDIT_SPONSOR:
      state.summary = action.payload.summary;
      state.branding = _.pickBy(
        action.payload.branding,
        (value, key) => !!value
      );
      state.nameAbbreviation = action.payload.nameAbbreviation;
      break;
    default:
      break;
  }
}, {});
