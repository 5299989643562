import produce from 'immer';
import {
  Maybe,
  PublicProviderUserRoleType,
  PublicUserRoleType,
  RolePermissions,
  Trial,
  UserRole,
  UserRoleType,
} from '../types';
import { PartialRecord } from './documentSigningMachine';
import { clinicalTrialManagerPermissions } from './rolePermissions/clinicalTrialManager';
import { clinicianPermissions } from './rolePermissions/clinician';
import { investigatorPermissions } from './rolePermissions/investigator';
import {
  sponsorApiAdministrator,
  sponsorReadOnlyPermissions,
} from './rolePermissions/sponsor';
import {
  clinicalResearchCoordinatorDeidentifiedPermissions,
  clinicalResearchCoordinatorEditPermissions,
  clinicalResearchCoordinatorReadOnlyPermissions,
} from './rolePermissions/clinicalResearchCoordinator';
import { technicalTrialManagerPermissions } from './rolePermissions/technicalTrialManager';
import {
  clinicalResearchAssociate,
  clinicalResearchAssociateBlinded,
  clinicalResearchAssociateDeidentified,
  clinicalResearchAssociateDeidentifiedBlinded,
} from './rolePermissions/clinicalResearchAssociated';
import { deidentifiedDataManager } from './rolePermissions/dataManager';

export const ROLES_MAP = PublicUserRoleType;
export const ROLES_MAP_PROVIDERS = PublicProviderUserRoleType;

export function filterRolesByTrial<
  T extends {
    roles?: Maybe<ReadonlyArray<{ trial?: Maybe<Pick<Trial, 'id'>> }>>;
  }
>(user: T, trialId?: number) {
  type Role = NonNullable<T['roles']>[number];
  const filterFunc = trialId //return roles with correct trialId or no trial specified
    ? (role: Role) => (role.trial ? role.trial.id === trialId : true) //only return roles without a trial specified
    : (role: Role) => !role.trial;
  return produce(user, draft => {
    draft.roles = draft.roles?.filter(filterFunc);
  });
}

export const permissionsMap: Partial<
  Record<UserRoleType, RolePermissions[]>
> = {
  PATIENT: [
    RolePermissions.ScheduleVisit,
    RolePermissions.AddTrialOptionToUser,
    RolePermissions.ViewScheduling,
    RolePermissions.PatientDashboard,
    RolePermissions.ViewPatientPayments,
    RolePermissions.ContactClinic,
    RolePermissions.ViewSettings,
    RolePermissions.ReportEvent,
    RolePermissions.SubmitInitialDataCapture,
    RolePermissions.SignInformedConsent,
    RolePermissions.ViewInformedConsent,
    RolePermissions.DownloadInformedConsent,
    RolePermissions.ViewPii,
  ],
  SPONSOR_API_ADMINISTRATOR: sponsorApiAdministrator,
  SAFETY_MANAGER: [
    RolePermissions.ViewSettings,
    RolePermissions.ViewAdverseEvents,
    RolePermissions.WithdrawParticipant,
    RolePermissions.ViewProtocolDeviations,
    RolePermissions.CreateProtocolDeviations,
    RolePermissions.ViewCrf,
  ],
  MEDICAL_MONITOR: [
    RolePermissions.ViewSettings,
    RolePermissions.ViewAdverseEvents,
    RolePermissions.ViewProtocolDeviations,
    RolePermissions.CreateProtocolDeviations,
    RolePermissions.ViewCrf,
  ],
  CLINICAL_RESEARCH_ASSOCIATE: [...clinicalResearchAssociate],
  CLINICAL_RESEARCH_ASSOCIATE_BLINDED: [...clinicalResearchAssociateBlinded],
  DEIDENTIFIED_CLINICAL_RESEARCH_ASSOCIATE: [
    ...clinicalResearchAssociateDeidentified,
  ],
  DEIDENTIFIED_CLINICAL_RESEARCH_ASSOCIATE_BLINDED: [
    ...clinicalResearchAssociateDeidentifiedBlinded,
  ],
  PRINCIPAL_INVESTIGATOR: [
    ...investigatorPermissions,
    RolePermissions.EditCapturedData,
    RolePermissions.ReceiveCaseReportSignoffNotifications,
    RolePermissions.EnrollParticipant,
    RolePermissions.ScheduleSameDayVisit,
  ],
  CLINIC_PRINCIPAL_INVESTIGATOR: [
    ...investigatorPermissions,
    RolePermissions.EditCapturedData,
    RolePermissions.ReceiveCaseReportSignoffNotifications,
    RolePermissions.EnrollParticipant,
  ],
  ASSOCIATE_INVESTIGATOR: investigatorPermissions,
  CLINICAL_TRIAL_MANAGER: clinicalTrialManagerPermissions,
  CLINICAL_RESEARCH_COORDINATOR: [
    ...clinicalResearchCoordinatorEditPermissions,
    ...clinicalResearchCoordinatorReadOnlyPermissions,
    RolePermissions.DownloadParticipantBinder,
    RolePermissions.DownloadInformedConsent,
    RolePermissions.ScheduleSameDayVisit,
  ],
  READ_ONLY_SUPPORT_AGENT: [
    ...clinicalResearchCoordinatorReadOnlyPermissions,
    RolePermissions.DownloadInformedConsent,
  ],
  DEIDENTIFIED_CLINICAL_RESEARCH_COORDINATOR: clinicalResearchCoordinatorDeidentifiedPermissions,
  QUALITY_ASSURANCE: [
    RolePermissions.ApproveTrialConfig,
    RolePermissions.TrialBuilder,
    RolePermissions.ViewTrials,
  ],
  PHYSICIAN: clinicianPermissions,
  MEDICAL_ADMINISTRATOR: [
    ...clinicianPermissions,
    RolePermissions.ScheduleSameDayVisit,
  ],
  NURSE: [...clinicianPermissions, RolePermissions.ScheduleSameDayVisit],
  TECHNICAL_TRIAL_MANAGER: [
    ...clinicianPermissions,
    ...clinicalTrialManagerPermissions,
    ...technicalTrialManagerPermissions,
    RolePermissions.DownloadParticipantBinder,
  ],
  VENDOR: [
    RolePermissions.ViewParticipants,
    RolePermissions.ViewPii,
    RolePermissions.SubmitInitialDataCapture,
    RolePermissions.EditCapturedData,
    RolePermissions.ViewSettings,
    RolePermissions.CanJoinTelehealthMeeting,
    RolePermissions.CanJoinAnyMeeting,
  ],
  SPONSOR_READ_ONLY: sponsorReadOnlyPermissions,
  DEIDENTIFIED_DATA_MANAGER: deidentifiedDataManager,
};

//When the server sends the email, it doesnt have access to the internationalization, so adding a backup here.
const BACKUP_NAMES: PartialRecord<UserRoleType, string> = {
  MEDICAL_MONITOR: 'Medical Monitor',
  SAFETY_MANAGER: 'Safety Manager',
  CLINICAL_RESEARCH_ASSOCIATE: 'Clinical Research Associate',
  DEIDENTIFIED_CLINICAL_RESEARCH_ASSOCIATE:
    'Clinical Research Associate (Deidentified)',
  CLINICAL_RESEARCH_COORDINATOR: 'Clinical Research Coordinator',
  PRINCIPAL_INVESTIGATOR: 'Principal Investigator',
  ASSOCIATE_INVESTIGATOR: 'Associate Investigator',
  CLINICAL_TRIAL_MANAGER: 'Clinical Trial Manager',
  CLINIC_PRINCIPAL_INVESTIGATOR: 'Clinic Principal Investigator',
  DEIDENTIFIED_CLINICAL_RESEARCH_COORDINATOR:
    'Clinical Research Coordinator (Deidentified)',
  VENDOR: 'Vendor',
  READ_ONLY_SUPPORT_AGENT: 'Support Agent (Read-Only)',
  SPONSOR_READ_ONLY: 'Sponsor (Read-Only)',
  DEIDENTIFIED_DATA_MANAGER: 'Data Manager (Deidentified)',
};

export function getRoleName(role: UserRoleType, t?: any) {
  if (t) {
    return t(`userRoleDisplayNames.${role}`, role);
  }

  if (BACKUP_NAMES[role]) {
    return BACKUP_NAMES[role];
  }

  return role;
}

export function userHasPermission(
  user: {
    roles?: Maybe<ReadonlyArray<Pick<UserRole, 'type' | 'trialId'>>>;
  },
  permission: RolePermissions
): boolean {
  if (!user) {
    throw new Error(`user doesn't exist!`);
  }

  if (user.roles) {
    for (const role of user.roles) {
      if (
        permissionsMap[role.type] &&
        hasAnyPermission(permissionsMap[role.type], permission)
      ) {
        return true;
      }
    }
  }

  return false;
}

function hasAnyPermission(
  list: RolePermissions[] | undefined,
  permission: RolePermissions
) {
  if (list && list.includes(permission)) {
    return true;
  }

  return false;
}

// Reported Events Related Permissions (Dynamic)
//State X Allows Roles A,B,C, Permissions 1,2,3
//You need Permission RolePermissions.ReportEvent to file a new event ^
const reportedEventStatePermissions: any = {
  CREATED: {
    CLINICAL_RESEARCH_COORDINATOR: [
      RolePermissions.MarkAsFollowUp,
      RolePermissions.UpdateAeForm,
    ],
  },
  DRAFTED: {
    CLINICAL_RESEARCH_COORDINATOR: [
      RolePermissions.UpdateAeForm,
      RolePermissions.MarkAsFollowUp,
    ],
    PRINCIPAL_INVESTIGATOR: [RolePermissions.MakeInitialDetermination],
    ASSOCIATE_INVESTIGATOR: [RolePermissions.MakeInitialDetermination],
  },
  INVESTIGATOR_DETERMINED: {
    MEDICAL_MONITOR: [
      RolePermissions.MakeSecondDetermination,
      RolePermissions.IssueQueries,
    ],
    SAFETY_MANAGER: [RolePermissions.IssueQueries],
    CLINICAL_RESEARCH_COORDINATOR: [
      RolePermissions.UpdateAeForm,
      RolePermissions.MarkAsFollowUp,
    ],
  },
  MONITOR_DETERMINED: {
    MEDICAL_MONITOR: [
      RolePermissions.MakeSecondDetermination,
      RolePermissions.IssueQueries,
    ],
    SAFETY_MANAGER: [
      RolePermissions.IssueQueries,
      RolePermissions.FinalizeReport,
    ],
    CLINICAL_RESEARCH_COORDINATOR: [
      RolePermissions.UpdateAeForm,
      RolePermissions.MarkAsFollowUp,
    ],
  },
  MEDWATCH_DRAFTED: {
    SAFETY_MANAGER: [
      RolePermissions.UpdateMedwatchForm,
      RolePermissions.FinalizeMedwatchForm,
      RolePermissions.ViewMedwatchForm,
    ],
    MEDICAL_MONITOR: [RolePermissions.ViewMedwatchForm],
  },
  MEDWATCH_REVIEW: {
    MEDICAL_MONITOR: [
      RolePermissions.AcceptRejectMedwatchForm,
      RolePermissions.ViewMedwatchForm,
    ],
    SAFETY_MANAGER: [RolePermissions.ViewMedwatchForm],
  },
  COMPLETED: {
    SAFETY_MANAGER: [RolePermissions.ViewMedwatchForm],
  },
};

export function userHasPermissionForAdverseEvent(
  user: any,
  event: any,
  permission: any
) {
  const statePermissionList = reportedEventStatePermissions[event.status];

  if (user && user.roles) {
    for (const role of user.roles) {
      const permissionList = statePermissionList[role.type];
      if (permissionList && permissionList.includes(permission)) {
        return true;
      }
    }
  }

  return false;
}
