import React from 'react';

interface Props {
  knowledgeContent?: any;
}

const KnowledgeContentView = ({ knowledgeContent }: Props) => {
  if (!knowledgeContent) return null;

  return <div className='dc-knowledge-content'>{knowledgeContent}</div>;
};

export default KnowledgeContentView;
