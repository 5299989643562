import React from 'react';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import {
  AutocompleteSubElement,
  GenericOption,
  SubElementProps,
} from '@curebase/core/lib/dynamicform/types';

interface AutoCompleteProps {
  data: Record<string, any>;
  readOnly: boolean;
  subEle: AutocompleteSubElement;
  onChange: (subEle: SubElementProps, value: any) => void;
}

function AutoCompleteDropdown(props: AutoCompleteProps) {
  const { data, subEle, onChange, readOnly } = props;
  const options =
    typeof subEle?.options === 'function'
      ? subEle?.options(data) ?? []
      : subEle?.options ?? [];
  const value = resolveInternalValue(
    options,
    data[subEle.key],
    subEle?.additionalSettings?.multiple,
    subEle?.additionalSettings?.formatAsId
  );
  return (
    <>
      <MaterialAutocomplete
        className='Autocomplete'
        value={value}
        multiple={subEle?.additionalSettings?.multiple}
        autoHighlight
        fullWidth
        forcePopupIcon
        freeSolo={subEle?.additionalSettings?.freeSolo}
        autoSelect={subEle?.additionalSettings?.freeSolo}
        disabled={readOnly}
        options={options}
        getOptionLabel={option =>
          isFreeSoloInput(option) ? option : option.text
        }
        getOptionSelected={(option, value) => {
          // If the value is not an object, its a freeSolo input
          // and so isnt a listed option.
          if (isFreeSoloInput(value)) {
            return false;
          } else {
            return option.value === value.value;
          }
        }}
        onChange={(event, optionSelected) => {
          onChangeWrapper(optionSelected, subEle, onChange);
        }}
        renderInput={params => <TextField {...params} variant='outlined' />}
      />
    </>
  );
}

function isFreeSoloInput(val: any) {
  return typeof val !== 'object' || val === null;
}

function onChangeWrapper(optionSelected, subEle, onChange) {
  const value = resolveOutputValue(
    optionSelected,
    subEle?.additionalSettings?.multiple === true,
    subEle?.additionalSettings?.formatAsId === true
  );
  onChange(subEle, value);
}
/*
  This transforms the value saved in the application into one that 
  the MaterialAutocomplete component understands.
*/
function resolveInternalValue(options, value, isMultiple, isFormatAsId) {
  if (isMultiple) {
    return resolveMultipleInternalValues(options, value);
  } else if (!value) {
    return '';
  } else if (isFormatAsId) {
    for (const opt of options) {
      if (value === opt.value) {
        return opt;
      }
    }
    return value;
  } else if (isFreeSoloInput(value)) {
    return value;
  } else {
    for (const opt of options) {
      if (value?.predefinedValues?.includes(opt.value)) {
        return opt;
      }
    }
    for (const val of value?.freeResponse ?? []) {
      return val;
    }
  }
}

function resolveMultipleInternalValues(options, value) {
  const result = Array<any>();
  for (const opt of options ?? []) {
    if (value?.predefinedValues?.includes(opt.value)) {
      result.push(opt);
    }
  }

  for (const val of value?.freeResponse ?? []) {
    result.push(val);
  }
  return result;
}

/*
  This transforms the internal MaterialAutocomplete value 
  into the following shape we save in the applicaiton.
  {
    freeResponse: [...]
    predefinedValues: [...]
  }
*/
function resolveOutputValue(
  optionSelected: any,
  isMultiple: boolean,
  isFormatAsId: boolean
) {
  if (isFormatAsId) {
    return (optionSelected as GenericOption)?.value;
  }
  if (isFreeSoloInput(optionSelected)) {
    return {
      freeResponse: [optionSelected],
    };
  } else if (!isMultiple) {
    return {
      predefinedValues: [(optionSelected as GenericOption)?.value],
    };
  } else {
    return resolveMultipleOutputValues(
      (optionSelected as GenericOption)?.values()
    );
  }
}

function resolveMultipleOutputValues(optionsSelected: Array<any>) {
  const result = {
    predefinedValues: Array<string>(),
    freeResponse: Array<string>(),
  };

  for (let value of optionsSelected) {
    if (isFreeSoloInput(value)) {
      result.freeResponse.push(value);
    } else {
      result.predefinedValues.push((value as GenericOption)?.value);
    }
  }
  return result;
}

export default AutoCompleteDropdown;
