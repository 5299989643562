import {
  RemoteAccessMagicLinkRequest,
  RemoteAccessMagicLinkResponse,
  RemoteAccessRequest,
} from '@curebase/core/decoders/remote';
import { jsonPost } from '../lib/fetchHelpers';

export async function signin(
  token: string
): Promise<RemoteAccessMagicLinkResponse | null> {
  const requestBody: RemoteAccessRequest = { token };
  return jsonPost('/remote/signin', requestBody);
}

export async function requestSignInMagicLink({
  deliveryType,
  redirectUrl,
  email,
}: {
  deliveryType: 'email' | 'sms';
  redirectUrl?: string;
  email?: string;
}) {
  const requestBody: RemoteAccessMagicLinkRequest = {
    deliveryType,
    redirectUrl,
    email,
  };
  return await jsonPost(
    email ? '/remote/createtokenunauth' : '/remote/createtoken',
    requestBody
  );
}
