import {
  BinderStatus,
  ParticipantBinder,
  Trial,
  TrialOption,
  VisitSite,
} from '../types';

export const isBinderBeingGenerated = (
  binder: ParticipantBinder | undefined | null
): boolean => {
  const inProgressStatus = [BinderStatus.Created, BinderStatus.InProgress];
  return !!binder?.status && inProgressStatus.includes(binder.status);
};

export const getBinderFileName = (
  trialOption: TrialOption,
  trial: Trial,
  visitSite?: VisitSite | null
) => {
  const fileName = `${trial.slug}_${visitSite ? visitSite.slug + '_' : ''}${
    trialOption.subjectIdentifier
  }_binder.zip`;
  return fileName;
};
