import stringSimilarity from 'string-similarity';
import { uploadFile } from '../../../controllers/dataCaptureController';
import { CheckBoxElement, SignatureDialogProps } from './types';

export const signatureIsLikelyCorrect = (
  patientMode: boolean,
  legalName: string,
  patient: any,
  userData: any,
  trialOptionId: number,
  isChildAssent: boolean = false,
  childLegalName?: string
) => {
  const nameExpected =
    isChildAssent && childLegalName
      ? childLegalName
      : patientMode
      ? `${patient.user.firstName} ${patient.user.lastName}`
      : `${userData.firstName} ${userData.lastName}`;

  if (!nameExpected)
    console.error(
      `could not resolve an expectedName for the signature signature. trialOptionId: ${trialOptionId}. ${
        patientMode ? 'patient' : 'physician'
      } signature expected.`
    );

  return (
    stringSimilarity.compareTwoStrings(
      legalName.toLowerCase(),
      nameExpected.toLowerCase()
    ) > 0.6
  );
};

export const submitSignatureData = async (
  props: SignatureDialogProps,
  trialOptionId: number,
  data: any,
  attestationsFields: ReadonlyArray<CheckBoxElement>
) => {
  const file = await uploadFile(data.signature, trialOptionId);
  if (!file.path) {
    return;
  }

  const attestations = attestationsFields.reduce(
    (attestations: string[], attestationField: Readonly<CheckBoxElement>) => {
      if (data[attestationField.key] === 'checked') {
        attestations.push(attestationField.text);
      }
      return attestations;
    },
    [...(props.alreadyAcknowledgedQuestions ?? [])]
  );

  await props.onSubmit({
    ...data,
    attestations,
    filePath: file.path,
    fileId: file.fileId,
    reason: props.reason,
  });
};
