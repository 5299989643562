import React from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatMap from 'lodash/flatMap';
import _isString from 'lodash/isString';
import _map from 'lodash/map';
import { CSSTransition } from 'react-transition-group';
import {
  CloudUpload,
  Help as HelpIcon,
  Link as LinkIcon,
  PhoneIphone as SmartphoneIcon,
  RemoveCircle,
} from '@material-ui/icons';
import {
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core';

import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import { hashFile } from '@curebase/core/lib/fileHelpers';
import { isMobileBrowser } from '../../lib/ui';
import Dialog from './SafeDialog';
import Loading from '../Loading';

import ConfirmationInDialog from './ConfirmationInDialog';
import { requestSignInMagicLink } from '../../controllers/remoteAccessController';
import { uploadFiles } from '../../controllers/dataCaptureController';
import { showAlertMessage } from '../../store/actions';
import { StatusColor } from 'src/shared/lib/colors';
import ImageElement from '../basic/ImageElement';
import { TFunction, withTranslation } from 'react-i18next';

export type UploadProgress = {
  percentDone: number;
  uploadedMb: number;
  totalMb: number;
};

type BackendFile = {
  name: string;
  path: string;
};

type Props = {
  files: ReadonlyArray<File | string | BackendFile>;
  disabled?: boolean;
  onChange?: (file: any) => any;
  classes: any;
  t: TFunction;
};

type State = {
  selectedFiles: ReadonlyArray<File | string | BackendFile>;
  showPhoneUploadInstructions: boolean;
  filesUploading: {
    [name: string]: UploadProgress;
  };
};

const styles = () => ({
  materialIcon: {
    width: 28,
    height: 28,
    padding: '20px',
  },

  materialIconBigger: {
    width: 56,
    height: 56,
    padding: '20px',
  },
});

type UploadContext = {
  trialOptionId: undefined | number;
};

const MAX_ALLOWED_FILES: number = 20;

export const FileUploadContext = React.createContext<UploadContext>({
  trialOptionId: undefined,
});

type UploadedFile = any;

class FilePreview extends React.Component<Props, State> {
  static contextType = FileUploadContext;
  private filePreviewInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFiles: props.files || [],
      showPhoneUploadInstructions: false,
      filesUploading: {},
    };
    this.filePreviewInput = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.files !== nextProps.files) {
      this.setState({
        selectedFiles: nextProps.files,
      });
    }
  }

  onFilesChange = (selectedFiles: any) => {
    this.setState({ selectedFiles });
    this.props.onChange && this.props.onChange(selectedFiles);
  };

  updateProgress = (name, uploadProgress) => {
    const { filesUploading } = this.state;

    this.setState({
      filesUploading: {
        ...filesUploading,
        [name]: uploadProgress,
      },
    });
  };

  onAddFiles = async (files: FileList | null) => {
    const { t } = this.props;

    const newFiles = _flatMap(files, file => {
      const newFileHash = hashFile(file);

      const alreadyExists = _find(this.state.selectedFiles, selectedFile => {
        const existingHash = hashFile(selectedFile);
        return existingHash === newFileHash;
      });

      if (alreadyExists) {
        return [];
      }

      return [file];
    });

    if (newFiles.length > MAX_ALLOWED_FILES) {
      showAlertMessage(t('filePreview.maxFileExceeded'), StatusColor.Red);
      return;
    }

    const { trialOptionId } = this.context;
    const uploadedFiles = await uploadFiles(
      newFiles,
      trialOptionId,
      this.updateProgress
    );

    this.setState({ filesUploading: {} });
    const selectedFiles = [...this.state.selectedFiles, ...uploadedFiles];

    this.onFilesChange(selectedFiles);
    if (this.filePreviewInput.current) {
      this.filePreviewInput.current.value = '';
    }
  };

  onRemoveClick = (currentIndex: number) => {
    return () => {
      const files = _filter(this.state.selectedFiles, function (_, index) {
        return index !== currentIndex;
      });

      this.onFilesChange(files);
    };
  };

  onClosePhoneInstructions = () => {
    this.setState({
      showPhoneUploadInstructions: false,
    });
  };

  onConfirmPhoneInstructions = () => {
    this.setState({
      showPhoneUploadInstructions: false,
    });
    window.location.reload();
  };

  openFilePreview = (file: UploadedFile): void => {
    let fileUrl: string;
    if (file.path) {
      fileUrl = secureImageUriFromFileName(file.path);
    } else if (_isString(file)) {
      fileUrl = secureImageUriFromFileName(file);
    } else {
      fileUrl = URL.createObjectURL(file);
    }

    window.open(fileUrl, '_blank');
  };

  render() {
    const { classes, disabled, t } = this.props;
    const { filesUploading } = this.state;

    return (
      <div className='file-preview-container'>
        <CSSTransition
          classNames='filePreview'
          timeout={{ enter: 500, exit: 300 }}
        >
          <>
            {React.Children.toArray(
              _map(
                this.state.selectedFiles,
                (file: UploadedFile, index: number) => (
                  <UploadedFileListItem
                    file={file}
                    disabled={disabled}
                    onPreview={() => this.openFilePreview(file)}
                    onRemove={this.onRemoveClick(index)}
                  />
                )
              )
            )}
            <>
              {React.Children.toArray(
                Object.keys(filesUploading).map(name => {
                  const { percentDone, uploadedMb, totalMb } = filesUploading[
                    name
                  ];
                  return (
                    <UploadingFileListItem
                      name={name}
                      percentDone={percentDone}
                      uploadedMb={uploadedMb}
                      totalMb={totalMb}
                    />
                  );
                })
              )}
            </>
          </>
        </CSSTransition>

        {!disabled && (
          <>
            {!isMobileBrowser() && (
              <>
                <Button
                  className='file-preview-input-upload'
                  onClick={async () => {
                    await requestSignInMagicLink({
                      deliveryType: 'sms',
                      redirectUrl: window.location.pathname,
                    });
                    this.setState({
                      showPhoneUploadInstructions: true,
                    });
                  }}
                >
                  <SmartphoneIcon className={classes.materialIcon} />
                  {t('filePreview.phoneUploadBtn')}
                </Button>

                <Dialog
                  open={this.state.showPhoneUploadInstructions}
                  onClose={this.onClosePhoneInstructions}
                >
                  <ConfirmationInDialog
                    title={t('filePreview.confirmDialog.title')}
                    message={t('filePreview.confirmDialog.message')}
                    onClose={this.onClosePhoneInstructions}
                    onConfirm={this.onConfirmPhoneInstructions}
                    confirmationText={t(
                      'filePreview.confirmDialog.confirmation'
                    )}
                  />
                </Dialog>
              </>
            )}

            <Button
              className='file-preview-input-upload'
              onClick={() => this.filePreviewInput?.current?.click()}
            >
              <CloudUpload className={classes.materialIcon} />
              {this.state.selectedFiles.length > 0
                ? t('filePreview.cloudUpload.addMoreFiles')
                : t('filePreview.cloudUpload.addFiles')}
              <input
                className='file-preview-input'
                ref={this.filePreviewInput}
                type='file'
                onChange={async event => {
                  await this.onAddFiles(event.target.files);
                }}
                multiple
              />
            </Button>
          </>
        )}

        {!(this.state.selectedFiles && this.state.selectedFiles.length > 0) &&
          disabled && (
            <div className='file-preview-no-files'>
              {t('filePreview.cloudUpload.noFilesUploaded')}
            </div>
          )}
      </div>
    );
  }
}

interface UploadedFileListItemProps {
  file: UploadedFile;
  disabled?: boolean;
  onRemove: () => void;
  onPreview: () => void;
}

export function UploadedFileListItem(
  props: UploadedFileListItemProps
): React.ReactElement {
  const { file, disabled, onPreview, onRemove } = props;
  let fileIcon;
  // JR (this is historical - not sure if needed)
  const fileType = file.fileType || file.type;

  if (fileType === 'application/pdf') {
    fileIcon = <img src='/pdf.png' alt='pdfPreview' height='40px' />;
  } else if (fileType === 'link') {
    fileIcon = <LinkIcon fontSize='large' />;
  } else if (fileType?.startsWith('image')) {
    const imageURI = secureImageUriFromFileName(props.file.path);
    fileIcon = <ImageElement imageURI={imageURI} />;
  } else {
    fileIcon = <HelpIcon fontSize='large' />;
  }

  return (
    <div className='file-preview'>
      <List>
        <ListItem>
          <ListItemAvatar>
            <div className='file-preview-thumbnail'>{fileIcon}</div>
          </ListItemAvatar>
          <Link component='button' variant='body1' onClick={onPreview}>
            {file.name}
          </Link>
          {!disabled && (
            <ListItemSecondaryAction>
              <IconButton edge='end' aria-label='delete' onClick={onRemove}>
                <RemoveCircle />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
    </div>
  );
}

interface UploadingFileListItemProps {
  name: string;
  percentDone: number;
  uploadedMb: number;
  totalMb: number;
}

function UploadingFileListItem(
  props: UploadingFileListItemProps
): React.ReactElement {
  const { name, percentDone, uploadedMb, totalMb } = props;

  const uploadStatus: string = `${percentDone}% | ${uploadedMb}mb/${totalMb}mb`;

  return (
    <div className='file-preview'>
      <List>
        <ListItem>
          <ListItemAvatar>
            <div className='file-preview-thumbnail'>
              <Loading />
            </div>
          </ListItemAvatar>
          <ListItemText primary={name} secondary={uploadStatus} />
        </ListItem>
      </List>
    </div>
  );
}

export default withStyles(styles)(withTranslation('translations')(FilePreview));
