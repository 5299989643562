import React, { useEffect, useState, useRef, useContext } from 'react';
import CallOverlay from './CallOverlayPanel';
import VideoElement from './VideoElement';
import ChimeSdkWrapper from '../../lib/chime';
import chimeContext from '../../context/getChimeContext';
import { useSelector, useDispatch } from 'react-redux';
import { MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import LocalVideo from './LocalVideo';
import AudioVideoControlBar, { useStyles } from './AudioVideoControlBar';
import Button from '@material-ui/core/Button';
import { clearTelehealthDataInStore } from '../../store/telehealth/actions';
import RemoteVideos from './RemoteVideos';
import Loading from '../Loading';
import PhotoBooth, { captureImage, uploadTelemedPhoto } from './PhotoBooth';
import useRoles from 'src/hooks/useRoles';
import { useTelehealthUnlockedDataFieldQuery } from '../../types';
import { deleteAttendeeNow } from 'src/controllers/telemedController';
import { userIsParticipant } from '@curebase/core/lib/user';
import { showAlertMessage } from 'src/store/actions';

type Props = {
  meeting: any;
  attendee: any;
  photoBoothEnabled: boolean;
  recording?: boolean;
};

export enum CallOverlayMode {
  DeviceChange = 'DEVICE_CHANGE',
  PhotoboothMenu = 'PHOTOBOOTH_MENU',
}

export enum VideoStatus {
  VideoOn,
  VideoOff,
  Loading,
}

export enum VisibiltyStatus {
  ShowImage,
  ShowVideo,
  CollapseBlock,
  ShowLoading,
}

export const MeetingHasEnded = () => {
  const styles = useStyles();
  const chime = useContext(chimeContext);

  useEffect(() => {
    chime.initializeSdkWrapper();
  }, [chime]);
  return (
    <div className='tile-container center'>
      <div className='meeting-end'>The meeting has ended</div>
      <div className='rejoin-buttons-container'>
        <Button
          className={`${styles.joinButton} rejoin-button`}
          onClick={() => {
            clearTelehealthDataInStore();
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

const AudioVideo = React.memo((props: Props) => {
  //const history = useHistory();
  const dispatch = useDispatch();
  const chime = useContext(chimeContext);
  const { openVideo: inTelehealthMeeting } = useSelector(
    (store: any) => store?.telehealth
  );
  const { currentDate } = useSelector((store: any) => store);

  const roles = useRoles();
  const isPPT = userIsParticipant(roles as any);
  const [meetingHasEnded, setMeetingHasEnded] = useState(false);
  const [chimeHasInited, setChimeHasInited] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [showSettings, setShowSettings] = useState<CallOverlayMode | undefined>(
    undefined
  );
  const [localHasJoinedCall, setLocalHasJoinedCall] = useState(false);
  const { photoBoothEnabled } = props;
  const styles = useStyles();
  const [localHasLeftCall, setLocalHasLeftCall] = useState(false);
  const audioRef = useRef(null);
  const previewVideoRef = useRef(null);
  const {
    recording: recorderIsPresent,
    meetingTitle,
    attendee,
    meetingName,
    callLeft,
    meetingTime,
  } = useSelector((store: any) => store.telehealth);
  const { data, error } = useTelehealthUnlockedDataFieldQuery({
    variables: { meetingUUID: meetingTitle },
    pollInterval: 2000,
  });

  const photoBoothIsUnlocked = !!data?.getTelehealthUnlockedDataField;
  const photoBoothWithoutMeeting = photoBoothEnabled && !inTelehealthMeeting;

  // should only be one right now
  const remoteVideoElements: HTMLVideoElement[] = [];

  const [date] = useState(new Date(meetingTime));

  useEffect(() => {
    if (callLeft) chime.meetingSession?.audioVideo.stop();
  }, [callLeft]);

  useEffect(
    () => {
      const { meeting, attendee } = props;

      if (
        !audioRef.current ||
        photoBoothWithoutMeeting ||
        !props.meeting ||
        !props.attendee ||
        !previewVideoRef?.current ||
        chimeHasInited
      )
        return;

      const savedPreviewRef = previewVideoRef?.current!;

      chime
        .initializeMeetingSession(meeting, attendee, meetingTitle)
        .then(() => {
          setUpGeneralObserver();
          attachCurrentInputs(chime, audioRef, recorderIsPresent).then(() => {
            chime.audioVideo?.start();
            chime.audioVideo?.realtimeMuteLocalAudio();
            setLocalHasJoinedCall(true);
            setChimeHasInited(true);
          });
        });

      return () => {
        if (!!savedPreviewRef)
          chime.audioVideo?.stopVideoPreviewForVideoInput(savedPreviewRef!);
        chime.stopLocalVideoTile();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      //experiment with use callback later
      //props.meeting,
      //props.attendee,
      // audioRef.current,
      // previewVideoRef.current,
    ]
  );

  if (error) {
    console.log(
      '[AudioVideo] error fetching data for telehealth useTelehealthUnlockedDataFieldQuery',
      error
    );
  }

  const setUpGeneralObserver = async () => {
    const observer = {
      audioVideoDidStart: () => {
        console.log('Started');
        dispatch({ type: 'OPEN_VIDEO_CHAT' });
      },
      audioVideoDidStop: sessionStatus => {
        // See the "Stopping a session" section for details.
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.AudioCallEnded) {
          console.log('The session has ended');
          if (chime.audioVideo?.hasStartedLocalVideoTile)
            chime.audioVideo?.stopLocalVideoTile();
          setMeetingHasEnded(true);
        } else if (sessionStatusCode === MeetingSessionStatusCode.Left) {
          /*
            - You called meetingSession.audioVideo.stop().
            - When closing a browser window or page, Chime SDK attempts to leave the session.
          */
          console.log('You left the sessions');
          setLocalHasLeftCall(true);
        } else {
          console.log(
            'Stopped with a session status code: ',
            sessionStatusCode
          );
        }
      },
      audioVideoDidStartConnecting: reconnecting => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          console.log('Attempting to reconnect');
        }
      },
    };
    chime.audioVideo?.addObserver(observer);
  };

  const attachCurrentInputs = async (
    chimeObject: ChimeSdkWrapper,
    audioRef: any,
    recorderIsPresent?: boolean
  ) => {
    if (chimeObject.currentAudioInputDevice) {
      await chimeObject.chooseAudioInputDevice(
        chimeObject.currentAudioInputDevice
      );
    }
    if (!recorderIsPresent && audioRef?.current) {
      //@ts-ignore
      await chimeObject.audioVideo?.bindAudioElement(audioRef.current);

      if (chimeObject.currentVideoInputDevice) {
        await chimeObject.chooseVideoInputDevice(
          chimeObject.currentVideoInputDevice
        );
      }
    }
  };

  return (
    <>
      <audio className='meeting-audio' ref={audioRef} />
      {!photoBoothWithoutMeeting ? (
        <>
          <div
            style={{
              height: '100%',
              width: '100%',
              display:
                photoBoothEnabled && photoBoothIsUnlocked ? 'none' : 'flex',
            }}
          >
            {meetingHasEnded ? (
              <MeetingHasEnded />
            ) : (
              <div
                className={`tile-container ${
                  localHasLeftCall ? 'center' : 'space-between'
                }`}
              >
                {!chimeHasInited && (
                  <div className='video-window'>
                    <div className='loading'>
                      <Loading />
                    </div>
                  </div>
                )}
                <div className={'name-video-container'}>
                  <div className={'meeting-name'}>
                    {date.toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                    }) +
                      ' | ' +
                      meetingName}
                  </div>
                  <div
                    className={`video-window ${
                      chimeHasInited ? '' : 'collapse'
                    }`}
                  >
                    {!localHasJoinedCall && (
                      <VideoElement
                        videoRef={previewVideoRef}
                        isVisible={
                          showPreview
                            ? VisibiltyStatus.ShowVideo
                            : VisibiltyStatus.ShowImage
                        }
                      />
                    )}

                    {localHasJoinedCall && !localHasLeftCall && (
                      <>
                        <RemoteVideos
                          setAudioVideoElementsArray={(index, element) => {
                            remoteVideoElements[index] = element;
                          }}
                        />
                        {!recorderIsPresent && (
                          <LocalVideo startingVideoState={showPreview} />
                        )}
                      </>
                    )}

                    {localHasJoinedCall && localHasLeftCall && (
                      <>
                        <div className='call-left-copy-container'>
                          <label>You left the call</label>
                        </div>
                        <div className='rejoin-buttons-container '>
                          <Button
                            className={`${styles.joinButton} rejoin-button`}
                            onClick={() => {
                              attachCurrentInputs(chime, audioRef).then(() => {
                                setUpGeneralObserver().then(() => {
                                  chime.audioVideo?.start();
                                  setLocalHasLeftCall(false);
                                });
                              });
                            }}
                          >
                            Rejoin
                          </Button>
                          <Button
                            className={`done-button`}
                            onClick={() => {
                              chime.stopLocalVideoTile();
                              deleteAttendeeNow(
                                meetingTitle,
                                attendee?.Attendee?.AttendeeId!
                              );
                              clearTelehealthDataInStore('Done with call.');
                              chime.initializeSdkWrapper();
                            }}
                          >
                            Done
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!recorderIsPresent && chimeHasInited && !localHasLeftCall && (
                  <AudioVideoControlBar
                    photoBoothButtonDisabled={!photoBoothIsUnlocked}
                    meetingSession={chime.meetingSession!}
                    selectedVideoDevice={chime.currentVideoInputDevice!}
                    hasJoined={!!localHasJoinedCall}
                    showPreview={showPreview}
                    flipPreview={() => setShowPreview(!showPreview)}
                    previewVideoRef={previewVideoRef.current!}
                    onJoin={() => {
                      chime.audioVideo!.stopVideoPreviewForVideoInput(
                        previewVideoRef.current!
                      );
                      chime.audioVideo?.start();
                      setLocalHasJoinedCall(true);
                    }}
                    showSettings={(mode: CallOverlayMode) => {
                      setShowSettings(mode);
                    }}
                    nonParticipantPhotoboothCapture={
                      isPPT
                        ? undefined
                        : async () => {
                            const remoteRefPresent = remoteVideoElements.filter(
                              e => !e.className.includes('collapse')
                            );
                            if (remoteRefPresent.length > 0) {
                              const fileObj = captureImage(
                                remoteRefPresent[0]!
                              );
                              await uploadTelemedPhoto(
                                meetingTitle,
                                fileObj,
                                data?.getTelehealthUnlockedDataField
                                  ?.dataFieldId!,
                                data?.getTelehealthUnlockedDataField?.visitId!,
                                currentDate
                              );
                            } else {
                              showAlertMessage('No participant in the call.');
                            }
                          }
                    }
                  />
                )}
                {showSettings && (
                  <CallOverlay
                    onClose={() => setShowSettings(undefined)}
                    overlayMode={showSettings}
                    meetingUUID={meetingTitle}
                  />
                )}
              </div>
            )}
          </div>
          {photoBoothEnabled && photoBoothIsUnlocked && (
            <PhotoBooth
              photoBoothWithoutMeeting={photoBoothWithoutMeeting}
              meetingUUID={meetingTitle}
              dataFieldId={data?.getTelehealthUnlockedDataField?.dataFieldId!}
              visitId={data?.getTelehealthUnlockedDataField?.visitId!}
            />
          )}
        </>
      ) : (
        <>
          {photoBoothIsUnlocked ? (
            <PhotoBooth
              photoBoothWithoutMeeting={photoBoothWithoutMeeting}
              meetingUUID={meetingTitle}
              meeting={props.meeting}
              attendee={props.attendee}
              dataFieldId={data?.getTelehealthUnlockedDataField?.dataFieldId!}
              visitId={data?.getTelehealthUnlockedDataField?.visitId!}
            />
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
});

export default AudioVideo;
