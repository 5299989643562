import * as React from 'react';
import ParticipantPicker from '../basic/pickers/ParticipantPicker';

const ParticipantsView = (props: any) => {
  const { history } = props;

  return (
    <>
      <ParticipantPicker
        onPicked={(trialOptionId: number) => {
          history.push(`/u/participants/${trialOptionId}`);
        }}
        embedded={false}
      />
    </>
  );
};

export default ParticipantsView;
