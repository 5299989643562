import { ReactNode } from 'react';

interface TagProps {
  children?: ReactNode;
  color?: 'red' | 'gray';
  size?: 'sm' | 'xs' | '2xs';
}

export default function Tag({
  children,
  color = 'gray',
  size = 'sm',
}: TagProps) {
  return (
    <div className={`tag tag-size-${size} tag-color-${color}`}>{children}</div>
  );
}
