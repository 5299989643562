import * as React from 'react';
import moment from 'moment-timezone';

import type { TextSubElement } from '../basic/DynamicForm';
import { getLocaleFormatDates } from '../../context/localeContext';

const localesFormat = getLocaleFormatDates();
// Move this to common area with other DF Inputs
type Props = {
  subEle: TextSubElement & any;
  hasError: boolean;
  pageIndex: number;
  data: any;
  onChange: any;
  noDay?: boolean;
  readOnly: boolean | null;
};

type TErrors = string[] | null;

type State = {
  month: string | null;
  day: string | null;
  year: string | null;
  errors: TErrors;
};

const nullDate = { day: null, month: null, year: null };
const initialState = { ...nullDate, errors: null };

class DateInput extends React.Component<Props, State> {
  state: any = initialState;

  componentDidMount() {
    const { subEle, data }: any = this.props;
    let value = data[subEle.key] || subEle.value; // missing from props? i think this is necessary
    if (value) {
      value = this.getValidMoment(value);
      this.setState({
        month: value.format('MM'),
        day: value.format('DD'),
        year: value.format('YYYY'),
      });
    }
  }

  getValidMoment(value) {
    let calculatedValueMoment = moment(value);

    if (!calculatedValueMoment.isValid()) {
      calculatedValueMoment = moment(value / 1000);
    }
    return calculatedValueMoment;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const validatedStateValue = (state: State) => {
      const { day, month, year } = state;
      if (!(month && year && day)) return null;
      if (parseInt(day) === 0 || parseInt(month) === 0) return null;
      if (year.length !== 4) return null;

      return moment()
        .set({
          date: parseInt(day),
          month: parseInt(month) - 1,
          year: parseInt(year),
        })
        .format(localesFormat.moment.defaultFormat);
    };
    const validatedMonthPicker = (state: State) => {
      const { month, year } = state;
      if (!(month && year)) return null;
      if (parseInt(month) === 0) return null;
      if (year.length !== 4) return null;
      return moment()
        .set({
          month: parseInt(month) - 1,
          year: parseInt(year),
        })
        .format(localesFormat.moment.monthYearFormat);
    };
    const { noDay } = this.props;

    let value;
    let prevValue;
    if (!noDay) {
      value = validatedStateValue(this.state);
      prevValue = validatedStateValue(prevState);
    } else {
      value = validatedMonthPicker(this.state);
      prevValue = validatedMonthPicker(prevState);
    }

    if (prevState !== initialState && value !== prevValue) {
      const { subEle, pageIndex, onChange } = this.props;
      onChange(subEle, value, pageIndex);
    }
    if (!prevValue && !value) {
      const { subEle, data } = this.props;
      let calculatedValueNum = data[subEle.key] || subEle.value; // missing from props? i think this is necessary

      if (calculatedValueNum) {
        const calculatedValueMoment = moment(calculatedValueNum);

        const stateChange =
          prevState?.day !== calculatedValueMoment.format('DD') ||
          prevState?.month !== calculatedValueMoment.format('MM') ||
          prevState?.year !== calculatedValueMoment.format('YYYY');

        if (stateChange) {
          this.setState({
            month: calculatedValueMoment.format('MM'),
            day: calculatedValueMoment.format('DD'),
            year: calculatedValueMoment.format('YYYY'),
          });
        }
      }
    }
  }

  updateStateWithInput = (type: 'day' | 'month' | 'year', e: any) => {
    const validatedStringInput = (() => {
      const { value, maxLength, min, max } = e.target;
      const parsedValue = value.slice(0, maxLength).replace(/\D/g, '');

      if (parsedValue.length !== maxLength) return parsedValue;
      if (max && parseInt(parsedValue) > max) return null;
      if (min && parseInt(parsedValue) < min) return null;
      return parsedValue;
    })();

    const targetMonthInvalidatesDate = (targetMonth: string | null) => {
      const { year, day } = this.state;
      if (!targetMonth || !year) return false;
      if (year.length !== 4) return false;
      return (
        moment()
          .set({
            year: parseInt(year),
            month: parseInt(targetMonth) - 1,
          })
          .daysInMonth() < day
      );
    };

    const targetYearInvalidatesDate = (targetYear: string | null) => {
      const { month, day } = this.state;
      if (!targetYear || !month) return false;
      if (targetYear.length !== 4) return false;
      return (
        moment()
          .set({
            year: parseInt(targetYear),
            month: parseInt(month) - 1,
          })
          .daysInMonth() < day
      );
    };

    const targetDayInvalidatesDate = (targetDay: string | null) => {
      const { month, year } = this.state;
      if (!targetDay || !month || !year) return false;
      if (year.length !== 4) return false;

      return (
        // @ts-ignore
        moment()
          .set({
            year: parseInt(year),
            month: parseInt(month) - 1,
          })
          .daysInMonth() < targetDay
      );
    };

    // If editing month/year, and existing date is out-of-range, set month/year and clear date
    if (
      (type === 'month' && targetMonthInvalidatesDate(validatedStringInput)) ||
      (type === 'year' && targetYearInvalidatesDate(validatedStringInput))
    )
      // @ts-ignore
      return this.setState({ [type]: validatedStringInput, day: null });
    // If editing date, and date is out-of-range of existing month/year, clear date
    if (type === 'day' && targetDayInvalidatesDate(validatedStringInput))
      return this.setState({ day: null });
    // Otherwise, edit single field as normal
    // @ts-ignore
    this.setState({ [type]: validatedStringInput });
  };

  render() {
    const { subEle, hasError, readOnly, noDay } = this.props;
    const { day, month, year } = this.state;
    const disabled = readOnly || subEle.readOnly || false;
    const inputClassName = hasError ? 'with-error' : '';

    return (
      <div className='df-date-sub-element'>
        <input
          disabled={disabled}
          type='number'
          onKeyPress={e => {
            if (e.which < 48 || e.which > 57) e.preventDefault();
          }}
          className={inputClassName}
          value={month || ''}
          onChange={e => this.updateStateWithInput('month', e)}
          placeholder='MM'
          // @ts-ignore
          maxLength='2'
          min='1'
          max='12'
        />
        <span className='date-divider'>/</span>

        {!noDay && (
          <>
            <input
              disabled={disabled}
              type='number'
              onKeyPress={e => {
                if (e.which < 48 || e.which > 57) e.preventDefault();
              }}
              className={inputClassName}
              value={day || ''}
              onChange={e => this.updateStateWithInput('day', e)}
              placeholder='DD'
              // @ts-ignore
              maxLength='2'
              min='1'
              max='31'
            />
            <span className='date-divider'>/</span>
          </>
        )}

        <input
          disabled={disabled}
          type='number'
          onKeyPress={e => {
            if (e.which < 48 || e.which > 57) e.preventDefault();
          }}
          className={inputClassName}
          value={year || ''}
          onChange={e => this.updateStateWithInput('year', e)}
          placeholder='YYYY'
          // @ts-ignore
          maxLength='4'
        />
      </div>
    );
  }
}

export default DateInput;
