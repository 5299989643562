import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';
import Button from '@material-ui/core/Button';
import _intersection from 'lodash/intersection';
import CreateClinicDialog from './CreateClinicDialog';
import Header from '../basic/Header';
import Subheader from '../basic/Subheader';
import ListItem from '../basic/ListItem';
import { createClinic } from '../../controllers/clinicController';
import { useResearchTeamListQuery } from 'src/types';
import { useTranslation } from 'react-i18next';

const sortClinicConfigs = (
  a: { name: string } & any,
  b: { name: string } & any
) => a.name.localeCompare(b.name);

const ResearchTeamList = () => {
  const { t } = useTranslation('translations');
  const { data, loading, refetch } = useResearchTeamListQuery();

  const clinicsInS3 = React.useMemo(
    () => data?.getSiteManagementConfigs ?? [],
    [data?.getSiteManagementConfigs]
  );
  const clinicsInThisEnv = React.useMemo(() => data?.getClinics ?? [], [
    data?.getClinics,
  ]);
  const history = useHistory();
  const [showDialog, setShowDialog] = React.useState(false);

  const uuidsInBoth = React.useMemo(
    () =>
      _intersection(
        clinicsInS3.map(({ id }) => id),
        clinicsInThisEnv.map(({ id }) => id)
      ),
    [clinicsInS3, clinicsInThisEnv]
  );
  const clinicsInBoth = React.useMemo(
    () =>
      clinicsInThisEnv
        .filter(({ id }) => uuidsInBoth.includes(id))
        .sort(sortClinicConfigs),
    [clinicsInThisEnv, uuidsInBoth]
  );
  const clinicsNotInThisEnv = React.useMemo(
    () =>
      clinicsInS3
        .filter(({ id }) => !uuidsInBoth.includes(id))
        .sort(sortClinicConfigs),
    [clinicsInS3, uuidsInBoth]
  );
  if (loading || !data) return <Loading />;

  const onSubmitCreateClinic = async (submitData: any) => {
    await createClinic(submitData.clinicName);
    await refetch();
    setShowDialog(false);
  };

  return (
    <>
      <CreateClinicDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onSubmit={onSubmitCreateClinic}
      />
      <Header displayText={t('researchTeamList.headerTitle')} />
      <Subheader text={t('researchTeamList.headerTitle')} />

      {!(clinicsInBoth && clinicsInBoth.length > 0) && (
        <ListItem
          middle={{
            title: t('researchTeamList.noClinicsTitle'),
            text: t('researchTeamList.noClinicsText'),
          }}
        />
      )}

      <div className='li-multi-container inset'>
        {clinicsInBoth.map((clinic, i) => {
          let text;
          return (
            <ListItem
              key={i}
              middle={{
                title: clinic.name,
                text,
              }}
              onClick={() => history.push(`/u/clinic/${clinic.id}`)}
            />
          );
        })}
      </div>

      <Subheader
        text={t('researchTeamList.subHeaderResearchNotDeployedText')}
        buttons={[
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowDialog(true)}
          >
            {t('researchTeamList.newBtn')}
          </Button>,
        ]}
      />

      <div className='li-multi-container inset'>
        {clinicsNotInThisEnv.length !== 0 ? (
          clinicsNotInThisEnv.map((clinic, i) => {
            let text;
            return (
              <ListItem
                key={i}
                middle={{
                  title: clinic.name,
                  text,
                }}
                onClick={() => history.push(`/u/clinic/${clinic.id}`)}
              />
            );
          })
        ) : (
          <ListItem
            middle={{
              title: t('researchTeamList.allResearchTeamsTitle'),
            }}
          />
        )}
      </div>
    </>
  );
};

export default ResearchTeamList;
