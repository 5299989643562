import LuxonUtils from '@date-io/luxon';
import { DateTime, Info } from 'luxon';
import { getLocale } from 'src/context/localeContext';

/**
 * SEE:
 *    - https://github.com/mui-org/material-ui-pickers/issues/1626#issuecomment-755411315
 *    - https://github.com/mui-org/material-ui-pickers/issues/1626#issuecomment-612031743
 */

const getLocaleInformation = () => {
  const { locale } = getLocale();
  const weekdaysNarrow = Info.weekdays('narrow', { locale });
  const weekdaysShort = Info.weekdays('short', { locale });

  const defaultDataWrapper = weekdaysNarrow.map((dayName, index) => ({
    index,
    name: weekdaysShort[index],
    dayName,
  }));

  return {
    defaultDataWrapper,
    weekdaysShort,
    weekdaysNarrow,
    locale,
  };
};

class DataWrapper {
  default = getLocaleInformation().defaultDataWrapper;
  dayNames: string[] = getLocaleInformation().weekdaysNarrow;

  processedDayNames: string[] = [];

  startDay: any = {};

  setStartDay(index) {
    this.startDay = this.default[index];
  }

  processWeekDayOrder() {
    const days = [...this.dayNames];
    const remainingDays = days.splice(0, this.startDay.index);
    this.processedDayNames = days.concat(remainingDays);
    return this.processedDayNames;
  }
}

export class CustomLuxonUtils extends LuxonUtils {
  dayNames = getLocaleInformation().weekdaysNarrow;

  wrapper: DataWrapper;

  constructor() {
    super();
    this.wrapper = new DataWrapper();
    this.wrapper.setStartDay(6);
    this.locale = getLocaleInformation().locale;
  }

  getWeekdays() {
    return this.wrapper.processWeekDayOrder();
  }

  getWeekArray(date: DateTime) {
    const index = this.wrapper.startDay.index;

    const endDate = date
      .endOf('month')
      // if a month ends on sunday, luxon will consider it already the end of the week
      // but we need to get the _entire_ next week to properly lay that out
      // so we add one more day to cover that before getting the end of the week
      .plus({ days: index !== 0 ? this.dayNames.length - index : 1 })
      .endOf('week');
    const startDate = date
      .startOf('month')
      .startOf('week')
      // must subtract 1, because startOf('week') will be Mon, but we want weeks to start on Sun
      // this is the basis for every day in a our calendar
      .minus({ days: index !== 0 ? this.dayNames.length - index : 1 });

    const { days } = endDate.diff(startDate, 'days').toObject();

    const weeks: DateTime[][] = [];
    new Array(Math.round(days!))
      .fill(0)
      .map((_, i) => i)
      .map(day => startDate.plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    // a consequence of all this shifting back/forth 1 day is that you might end up with a week
    // where all the days are actually in the previous or next month.
    // this happens when the first day of the month is Sunday (Dec 2019 or Mar 2020 are examples)
    // or the last day of the month is Sunday (May 2020 or Jan 2021 is one example)
    // so we're only including weeks where ANY day is in the correct month to handle that
    return weeks.filter(w => w.some(d => d.month === date.month));
  }
}
