import { getOrderedStatesForMachine } from '.';
import { Machine } from './machine';
import { StudyActivityType } from '../types';

const {
  DataCapture,
  InformedConsent,
  Pluto,
  ScheduleVisit,
} = StudyActivityType;

export enum IconName {
  AssignmentDeprecated = 'assigment',
  Assignment = 'assignment',
  Lock = 'lock',
  Event = 'event',
  Stethoscope = 'stethoscope',
  Calligraphy = 'calligraphy',
}
export interface TopBarItem {
  name: string;
  icon: IconName;
  configId?: string;
  locales?: any;
}

export interface TopBarItems extends ReadonlyArray<TopBarItem> {}

export const REGISTRATION_TOP_BAR_ITEM = 'topBar.register';
export const CREATE_PASSWORD_TOP_BAR_ITEM = 'topBar.createPassword';

/**
 * Creates the configuration for a top bar given the trial configuration machine.
 *
 *  Note that overriding the initial top bar item may result in unintended side effects,
 * like no longer having a registration item in the flow - just make sure you include
 * a registration page (if you need one) in the initialTopBarItem parameter.
 *
 * @param machine | The machine in the trial configuration
 * @param passwordOnRegistration | Indicates whether password should be created during registration
 * @param initialTopBarItems | Override the initial top bar items
 */
export function makeTopbar(
  machine: Machine,
  passwordOnRegistration: boolean,
  initialTopBarItems?: TopBarItem[]
): TopBarItems {
  const machineStates = getOrderedStatesForMachine(machine);
  const topBarStartValue =
    initialTopBarItems && initialTopBarItems?.length > 0
      ? initialTopBarItems
      : [{ name: REGISTRATION_TOP_BAR_ITEM, icon: IconName.Assignment }];
  const topbar: TopBarItems = machineStates.reduce(
    (prev: TopBarItem[], cur) => {
      const state = machine[cur];
      const topBarItems =
        state.meta?.studyActivities
          //@ts-ignore
          .filter(sa => sa.topBar)
          .map(sa => {
            switch (sa.type) {
              case InformedConsent:
                return { ...sa.topBar!, configId: InformedConsent };
              case DataCapture:
              case ScheduleVisit:
                return { ...sa.topBar!, configId: sa.configId };
              case Pluto:
                return { ...sa.topBar! };
              default: {
                //@ts-ignore
                const n: never = sa;
                throw new Error(`Unexpected TopBar StudyActivity ${n}`);
              }
            }
          }) ?? [];
      return [...prev, ...(topBarItems as TopBarItems)];
    },
    [...topBarStartValue]
  );

  if (topbar.length === 1) return [];
  return passwordOnRegistration
    ? topbar
    : [...topbar, { name: CREATE_PASSWORD_TOP_BAR_ITEM, icon: IconName.Lock }];
}
