import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CustomStyleContext from '../../context/customStyleContext';
import { DEFAULT_THEME_PROPS } from '../../lib/colors';
import StandardButton from '../basic/StandardButton';
import { VisitStatus } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';

type MissingProps = {
  completedAt: string;
  status: VisitStatus;
};

const DataCaptureMissed = ({ completedAt, status }: MissingProps) => {
  const history = useHistory();
  const customStyle = useContext(CustomStyleContext);
  const { t } = useTranslation('translations');

  let titleString;
  let subTitleString;
  if (status === VisitStatus.Completed) {
    titleString = t('dataCaptureMissed.complete.title');
    subTitleString = t('dataCaptureMissed.complete.subtitle');
  } else {
    titleString = t('dataCaptureMissed.notComplete.title');
    subTitleString = t('dataCaptureMissed.notComplete.subtitle', {
      completedAt,
    });
  }

  return (
    <div
      className='data-capture-missed-page'
      style={{
        backgroundColor: customStyle?.backgroundColor
          ? `${customStyle?.backgroundColor}10`
          : '#ecf4f5',
      }}
    >
      <div className='data-capture-missed-container'>
        <div className='margin-assist'>
          <Typography
            variant='h2'
            className='title'
            style={{
              color: customStyle?.colors?.text ?? '#2d6d88',
            }}
          >
            {titleString}
          </Typography>
          <Typography
            variant='subtitle1'
            className='sub-title'
            style={{
              color: customStyle?.colors?.text
                ? DEFAULT_THEME_PROPS.typography.body2.color
                : '#2d6d88',
            }}
          >
            {subTitleString}
          </Typography>
          <div className='button'>
            <StandardButton
              onClick={() => history.push('/')}
              variant='contained'
              size='xlarge'
              text={`${t('common.backToDashboard')}  →`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCaptureMissed;
