import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Dialog, { SafeDialogProps } from '../basic/SafeDialog';
import OneClickPicker, { PickerOption } from '../basic/OneClickPicker';
import { DocumentsContext } from './DocumentsOverview';
import { uploadDocumentAndSignature } from '../../controllers/signatureController';
import { DocumentAction, SignedDocument } from '@curebase/core/types';
import {
  DocumentConfigurationAppliesTo,
  availableActions,
  getMachineForDocument,
} from '@curebase/core/lib/documentSigningMachine';
import { showEnterPatientModeDialog } from 'src/store/actions';
import UnlockConsentConfirmationDialog from './UnlockConsentConfirmationDialog';
import useRoles from 'src/hooks/useRoles';
import { userIsParticipant } from '../../lib/users';
import { SigningType } from '@curebase/core/decoders/signatures';
import { useTranslation } from 'react-i18next';

const {
  AcquireParticipantSignature,
  ProvideCounterSignature,
  UnlockParticipantSigning,
} = DocumentAction;

interface SignDocumentActionDialogButton extends PickerOption {
  value: { type: SigningType; action: DocumentAction };
}

interface SignDocumentActionDialogProps<
  T extends DocumentConfigurationAppliesTo
> extends SafeDialogProps {
  onClose: () => any;
  document: Pick<SignedDocument, 'status'>;
  documentConfig: T;
  documentType: string;
  refetch: () => any;
  trialOption: any;
}

/**
 * This component takes a document and renders all the actions possible for the document
 * for the current user. Once the action is selected, it set in the parent context.
 */

function SignDocumentActionDialog<T extends DocumentConfigurationAppliesTo>(
  props: SignDocumentActionDialogProps<T>
) {
  const { setContext } = React.useContext(DocumentsContext);
  const { t } = useTranslation('translations');
  const {
    document,
    documentConfig,
    documentConfig: { id: documentConfigurationId },
    documentType,
    refetch,
    trialOption,
    onClose,
    ...dialogProps
  } = props;
  const user = useRoles(trialOption?.trial.id);
  const history = useHistory();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { trialOptionId } = useParams<{ trialOptionId: string }>();
  const isPPT = userIsParticipant();
  const machine = getMachineForDocument(documentConfig);
  const actionsMap = availableActions(
    machine,
    document.status,
    user,
    documentConfig
  );
  const actions = Object.keys(actionsMap) as DocumentAction[];
  const baseUrl = `/u/${trialOptionId}/consent/`;

  const updateContextAndCloseDialog = (
    v: SignDocumentActionDialogButton['value']
  ) => {
    const { type, action } = v;
    setContext(type, action);
    onClose();
  };

  const onClickDefault = (v: SignDocumentActionDialogButton['value']) => {
    updateContextAndCloseDialog(v);
    history.push(baseUrl + documentType);
  };

  //[DT] every case is covered, eslint is wrong here
  // eslint-disable-next-line array-callback-return
  const options = actions
    .map(action => {
      switch (action) {
        case AcquireParticipantSignature:
          return isPPT
            ? {
                value: { type: SigningType.remote, action },
                title: t('signDocumentActionDialog.imReadyToSignText'),
                iconSrc: '/icons/doctor.svg',
                onClick: onClickDefault,
              }
            : {
                value: { type: SigningType.inPerson, action },
                title: t('signDocumentActionDialog.patientWithMeText'),
                iconSrc: '/icons/doctor.svg',
                onClick: (v: any) => {
                  updateContextAndCloseDialog(v);
                  showEnterPatientModeDialog({
                    history,
                    pendingSettings: {
                      baseRoute: baseUrl + 'patientMode',
                      successRoute: baseUrl + 'patientMode/' + documentType,
                    },
                  });
                },
              };
        case UnlockParticipantSigning:
          return {
            value: { type: SigningType.remote, action },
            title: t('signDocumentActionDialog.patientAtHomeText'),
            iconSrc: '/icons/laptop.svg',
            onClick: async () => {
              onClose();
              setConfirmationOpen(true);
            },
          };
        case ProvideCounterSignature:
          return {
            value: { type: SigningType.remote, action },
            title: t('signDocumentActionDialog.imReadyToSignText'),
            iconSrc: '/icons/laptop.svg',
            onClick: onClickDefault,
          };
        default:
          return null;
      }
    })
    // Filter actions not covered ex.: declined
    .filter(i => !!i) as Array<SignDocumentActionDialogButton>;

  return (
    <>
      <UnlockConsentConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={async () => {
          const request: Parameters<typeof uploadDocumentAndSignature>[0] = {
            action: UnlockParticipantSigning,
            documentConfigurationId,
            documentType,
            signingType: SigningType.remote,
            trialOptionId: parseInt(trialOptionId),
          };
          await uploadDocumentAndSignature(request);
          await refetch();
          setConfirmationOpen(false);
        }}
      />
      <Dialog onClose={onClose} {...dialogProps}>
        <div className='light-dialog'>
          <div className='booker booker-loading'>
            <div className='booker-text-container'>
              <div className='booker-title'>
                {t('signDocumentActionDialog.selectConsentTitle')}
              </div>
              <OneClickPicker options={options} />
            </div>
            <div className='close-dialog-icon-container' onClick={onClose}>
              <img
                className='close-dialog-icon'
                src='/icons/closeDialog.svg'
                alt='X'
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SignDocumentActionDialog;
