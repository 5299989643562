import { TimeSlot } from '@curebase/modules/scheduling/dto/scheduling';
import { LuxonFromAnything } from '@curebase/core/lib/dates';
import { User, VisitMethod } from '@curebase/core/types';
import _find from 'lodash/find';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { Logger } from 'src/lib/logger';
import { userIsParticipant } from 'src/lib/users';
import {
  cancelBookingRequest,
  cancelVisit,
  scheduleVisit,
  sendBookingRequestMessage,
} from '../../../controllers/bookingController';
import {
  attemptCustomGtmEvent,
  GtmCustomEvents,
} from '../../../lib/analytics-gtm';
import {
  addCoordinatesToVisitOptions,
  Address,
  GeoCoordinates,
  getFirstGeocodeFromAddress,
  getLatAndLngFromGeocode,
  getStateFromGeocode,
  sortVisitSites,
  VisitOption,
  VisitOptionWithCoordinates,
} from '../../../lib/booker';
import { getProviderClinicIds } from '../../../lib/users';
import Loading from '../../Loading';
import BookerCancel from './BookerCancel';
import BookerCancelRequest from './BookerCancelRequest';
import BookerConfirmZipcode from './BookerConfirmZipcode';
import BookerFinalConfirmation from './BookerFinalConfirmation';
import BookerInPersonSelectSite from './BookerInPersonSelectSite';
import BookerManualCancel from './BookerManualCancel';
import BookerManualSuccess from './BookerManualSuccess';
import BookerNowOrLater from './BookerNowOrLater';
import BookerSelectSlot from './BookerSelectSlot';
import BookerSelectVisitMethod from './BookerSelectVisitMethod';
import BookerSuccess from './BookerSuccess';
import BookerVirtualVisitAddSpectator from './BookerVirtualVisitAddSpectator';
import BookerVirtualVisitSelectSite from './BookerVirtualVisitSelectSite';
import { updatePatient } from '../../../controllers/patientController';
import { isEmpty, isNil } from 'lodash';

interface Props
  extends RouteComponentProps<{
    method?: VisitMethod;
    configId: string;
    activitySubroute: string;
  }> {
  refetch: () => Promise<void>;
  trialOptionId: number;
  trialSlug: string;
  availableMethods: VisitMethod[];
  schedulingWindow?: { start: number; end: number } | null;
  patientAddress: Address;
  visitOptions: VisitOption[];
  onVisitStatusUpdate: () => void | Promise<void>;
  existingBookingVisitOption?: VisitOption;
  onClose?: () => Promise<void>;
  t: TFunction;
  sponsorUsers: User[];
  allowSpectator?: boolean;
}

type State = {
  zipcodeTextInputValue: string;
  zipcodeValue: string | null;
  stateName?: string;
  stateCode?: string;
  methodSelected?: VisitMethod;
  allowedVisitOptions?: VisitOptionWithCoordinates[];
  virtualVisitSupportedForZipcode?: boolean;
  visitOptionsWithCoordinates?: VisitOptionWithCoordinates[];
  patientCoordinates?: GeoCoordinates;
  geoSetInState: boolean;
  spectatorId?: number;
};

type VisitUrl = 'inperson' | 'virtual' | 'athome';

function getVisitTypeFromUrlFormat(urlString: VisitUrl): VisitMethod {
  if (urlString === 'inperson') return VisitMethod.InPerson;
  else if (urlString === 'virtual') return VisitMethod.Virtual;
  else if (urlString === 'athome') return VisitMethod.AtHome;
  else
    throw new Error(
      'Invalid url format, cannot convert to visit type: ' + urlString
    );
}

function getUrlFormatFromVisitMethod(method: VisitMethod): VisitUrl {
  switch (method) {
    case VisitMethod.InPerson:
      return 'inperson';
    case VisitMethod.Virtual:
      return 'virtual';
    case VisitMethod.AtHome:
      return 'athome';
    default:
      const never: never = method;
      throw new Error(
        'Invalid visit type, cannot convert to URL format: ' + never
      );
  }
}

// We use this because we want zipcode to be a string always for types reasons
// but we should move away from sentinel values
export const NULL_ZIPCODE = '00000';

class Booker extends React.Component<Props, State> {
  baseUrl: string;
  onClose: () => Promise<void>;

  constructor(props: Props) {
    super(props);
    const { patientAddress, match, location, onClose } = props;
    const { method, configId } = match.params;

    this.onSponsorListOpen = this.onSponsorListOpen.bind(this);
    const path = location.pathname.split('/' + configId);
    this.baseUrl = path[0];
    this.onClose =
      onClose ??
      async function () {
        // [JR] HACK because this is undefined for some reason when cancelling as associate
        if (!this) {
          props.history.push('/u');
        } else {
          props.history.push(this.baseUrl);
        }
      };

    this.state = {
      zipcodeTextInputValue: patientAddress.zipcode,
      zipcodeValue: patientAddress.zipcode,
      methodSelected: method as VisitMethod, // might be undefined, depending on state of url
      stateName: undefined,
      stateCode: undefined,
      visitOptionsWithCoordinates: undefined,
      allowedVisitOptions: undefined,
      virtualVisitSupportedForZipcode: undefined,
      patientCoordinates: undefined,
      geoSetInState: false,
      spectatorId: undefined,
    };
  }

  async componentDidMount() {
    const { patientAddress } = this.props;
    const { zipcode } = patientAddress;

    if (!!zipcode && zipcode !== NULL_ZIPCODE)
      this.setZipcodeDependentState(zipcode); // There is no zipcode required at signup in quitgenius, for example
  }

  setZipcodeDependentState = async (zipcode: string): Promise<void> => {
    const { match, visitOptions } = this.props;
    const patientGeo = await getFirstGeocodeFromAddress(zipcode);
    if (!patientGeo) {
      this.setState({
        zipcodeTextInputValue: '',
        zipcodeValue: '',
      });
      return;
    }
    const { stateName, stateCode } = getStateFromGeocode(patientGeo);
    let methodSelected;
    if (match.params.activitySubroute === 'virtual') methodSelected = 'VIRTUAL';
    if (match.params.activitySubroute === 'inperson')
      methodSelected = 'IN_PERSON';
    const patientCoordinates = getLatAndLngFromGeocode(patientGeo);
    const visitOptionsWithCoordinates = await addCoordinatesToVisitOptions(
      visitOptions
    );

    this.setState({
      zipcodeValue: zipcode,
      stateName,
      stateCode,
      methodSelected,
      visitOptionsWithCoordinates,
      virtualVisitSupportedForZipcode: true, // TODO, this needs to be configurable and based on the zip
      allowedVisitOptions: methodSelected
        ? await sortVisitSites(patientCoordinates, visitOptionsWithCoordinates)
        : undefined,
      patientCoordinates,
      geoSetInState: true,
    });
  };

  onConfirmOrUpdateZipcode = async (zipcode: string): Promise<void> => {
    const { trialOptionId } = this.props;

    await updatePatient({ trialOptionId, zipcode });

    await this.props.refetch();

    await this.setZipcodeDependentState(zipcode);

    this.goToSubroute('/selectmethod');
  };

  goToSubroute = (subroute: string, clearQueryParams?: boolean) => {
    const { history, location, match } = this.props;
    const bookerUrlBase = `${this.baseUrl}/${match.params.configId}/booker`;
    history.push(
      bookerUrlBase + subroute + (clearQueryParams ? '' : location.search)
    );
  };

  backToZipcode = () => {
    this.setState({ zipcodeValue: null }, () =>
      this.goToSubroute('/confirmzipcode')
    );
  };

  backToSelectMethod = () => {
    const { availableMethods } = this.props;
    const { methodSelected } = this.state;
    if (availableMethods.length === 1 && methodSelected) {
      this.backToZipcode();
    } else this.goToSubroute('/selectmethod');
  };

  onSelectVisitMethod = async (method: VisitMethod): Promise<void> => {
    const {
      stateCode,
      patientCoordinates,
      visitOptionsWithCoordinates,
    } = this.state;
    if (!stateCode || !patientCoordinates || !visitOptionsWithCoordinates)
      throw new Error(
        'Should not be obtaining possible visit options without geo information'
      );
    const options = await sortVisitSites(
      patientCoordinates,
      visitOptionsWithCoordinates
    );
    this.setState(
      {
        methodSelected: method,
        allowedVisitOptions: options,
      },
      () => {
        const singleVisitSite =
          options.length >= 1 ? options[0].visitSiteId : undefined;
        if (method === VisitMethod.Virtual)
          return this.goToSubroute('/virtual/selectsite');
        else if (method === VisitMethod.InPerson)
          return this.goToSubroute('/inperson/selectsite');
        else if (method === VisitMethod.AtHome)
          return this.goToSubroute(`/athome/${singleVisitSite}/selectslot`);
        else throw new Error('Invalid visit method: ' + method);
      }
    );
  };

  onSelectVisitSite = async (
    visitSiteId: number,
    method: VisitMethod
  ): Promise<void> => {
    const queryParams = queryString.parse(this.props.location.search);
    const methodForUrl = getUrlFormatFromVisitMethod(method);
    const directToConfirmation = queryParams['conduct_now'] === 'true';
    if (directToConfirmation)
      return this.goToSubroute(`/${methodForUrl}/${visitSiteId}/confirm`);
    else if (
      this.props.allowSpectator &&
      method === VisitMethod.Virtual &&
      !userIsParticipant()
    ) {
      return this.goToSubroute(`/${methodForUrl}/${visitSiteId}/addspectator`);
    } else
      return this.goToSubroute(`/${methodForUrl}/${visitSiteId}/selectslot`);
  };

  onSelectVisitSpectator = async (spectatorId?: number): Promise<void> => {
    this.setState({
      spectatorId: spectatorId,
    });
  };

  onSponsorListOpen() {
    const { sponsorUsers } = this.props;
    if (isNil(sponsorUsers) || isEmpty(sponsorUsers)) {
      Logger('telemedicine').warn('sponsor list is empty');
    }
  }

  onConfirmVisitSpectator = async (
    visitSiteId: number,
    method: VisitMethod
  ): Promise<void> => {
    const queryParams = queryString.parse(this.props.location.search);
    const methodForUrl = getUrlFormatFromVisitMethod(method);
    const directToConfirmation = queryParams['conduct_now'] === 'true';
    if (directToConfirmation)
      return this.goToSubroute(`/${methodForUrl}/${visitSiteId}/confirm`);
    else return this.goToSubroute(`/${methodForUrl}/${visitSiteId}/selectslot`);
  };

  onRequestManualScheduling = async (
    visitSiteId: number,
    method: VisitMethod
  ) => {
    const { trialOptionId, onVisitStatusUpdate } = this.props;
    await sendBookingRequestMessage(trialOptionId, visitSiteId, method);
    onVisitStatusUpdate();
    this.goToSubroute(
      `/${getUrlFormatFromVisitMethod(method)}/${visitSiteId}/complete`
    );
  };

  onFinalConfirmation = async (
    startTime: string,
    visitSiteId: number,
    method: VisitMethod,
    visitAvailabilityId?: number,
    spectatorId?: number
  ): Promise<void> => {
    const { trialOptionId, onVisitStatusUpdate, match } = this.props;
    const { configId } = match.params;
    const { visitBookingId } = await scheduleVisit(
      trialOptionId,
      startTime,
      visitSiteId,
      method,
      configId,
      visitAvailabilityId,
      spectatorId
    );
    const eventData = {
      visitBookingId,
    };
    attemptCustomGtmEvent(GtmCustomEvents.SCHEDULED_VISIT, eventData);
    onVisitStatusUpdate();
    const visitScheduledEvent = new CustomEvent<{ visitBookingId: number }>(
      GtmCustomEvents.SCHEDULED_VISIT,
      {
        detail: { visitBookingId },
      }
    );
    window.dispatchEvent(visitScheduledEvent);
    this.goToSubroute('/success');
  };

  onCancelRequest = async (): Promise<void> => {
    const { trialOptionId, onVisitStatusUpdate } = this.props;
    await cancelBookingRequest(trialOptionId);
    onVisitStatusUpdate();
    this.goToSubroute('/cancel-success');
  };

  onCancelVisit = async (removeClinicAccess: boolean): Promise<void> => {
    const { trialOptionId, onVisitStatusUpdate, match } = this.props;
    const { configId } = match.params;
    await cancelVisit(trialOptionId, configId, removeClinicAccess);
    onVisitStatusUpdate();
    this.goToSubroute('/cancel-success');
  };

  renderCatchAllRedirect = () => {
    const { configId } = this.props.match.params;
    return (
      <Redirect
        to={
          userIsParticipant()
            ? `${this.baseUrl}/${configId}/booker/confirmzipcode`
            : `${this.baseUrl}/${configId}/booker/noworlater`
        }
      />
    );
  };

  renderNowOrLaterRoute() {
    const { geoSetInState } = this.state;
    const usersClinicIds = getProviderClinicIds();
    return (
      <Route
        exact
        path={`${this.baseUrl}/:configId/booker/noworlater`}
        render={() => (
          <BookerNowOrLater
            onScheduleForNow={
              () =>
                !!geoSetInState
                  ? this.goToSubroute('/selectmethod?conduct_now=true')
                  : this.goToSubroute('/confirmzipcode?conduct_now=true') // We need a zipcode to confirm telemedicine is legal
            }
            onScheduleForLater={() =>
              usersClinicIds?.length > 0 && !!geoSetInState
                ? this.goToSubroute('/selectmethod')
                : this.goToSubroute('/confirmzipcode')
            } // Providers are assume to enroll at their clinic
          />
        )}
      />
    );
  }

  renderSuccessRoute() {
    return (
      <Route
        exact
        path={`${this.baseUrl}/:configId/booker/success`}
        render={() => {
          return (
            <BookerSuccess
              onSubmit={async () => {
                this.props.refetch();
                this.onClose();
              }}
            />
          );
        }}
      />
    );
  }

  render() {
    const {
      visitOptions,
      existingBookingVisitOption,
      trialOptionId,
      t,
      sponsorUsers,
    } = this.props;
    const {
      zipcodeValue,
      stateName,
      allowedVisitOptions,
      geoSetInState,
      spectatorId,
    } = this.state;
    const preZipcodeRoutes = [
      {
        path: `${this.baseUrl}/:configId/booker/manual-cancel`,
        render: routeProps => {
          const query = new URLSearchParams(routeProps.location.search);
          const isRescheduling = Boolean(query.get('isRescheduling') ?? false);

          return (
            <BookerManualCancel
              isRescheduling={isRescheduling}
              onClose={() => {
                this.props.refetch();
                this.onClose();
              }}
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/cancel`,
        render: routeProps => {
          if (!existingBookingVisitOption) {
            console.error(
              'Attempt to render cancel UI when there is no scheduled visit.'
            );
            return this.renderCatchAllRedirect();
          }

          return (
            <BookerCancel
              onCancelVisit={this.onCancelVisit}
              visitSiteName={existingBookingVisitOption.siteName}
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/cancelrequest`,
        render: routeProps => {
          return <BookerCancelRequest onCancelRequest={this.onCancelRequest} />;
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/cancel-success`,
        render: routeProps => {
          return <BookerSuccess onSubmit={this.onClose} isCancellation />;
        },
      },
    ];
    const postZipcodeRoutes = [
      {
        path: `${this.baseUrl}/:configId/booker/reschedule`,
        render: () => {
          const allowBack = !userIsParticipant();
          return (
            <BookerConfirmZipcode
              zipcodeValue={this.state.zipcodeTextInputValue}
              onChangeZipcodeValue={v =>
                this.setState({
                  zipcodeTextInputValue: v,
                })
              }
              onConfirmOrUpdateZipcode={this.onConfirmOrUpdateZipcode}
              isReschedule={true}
              onBack={
                allowBack
                  ? () => this.goToSubroute('/noworlater', true)
                  : undefined
              }
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/selectmethod`,
        render: routeProps => {
          return (
            <BookerSelectVisitMethod
              stateName={stateName}
              availableMethods={this.props.availableMethods}
              onSelectVisitMethod={this.onSelectVisitMethod}
              onBack={() =>
                userIsParticipant()
                  ? this.backToZipcode()
                  : this.goToSubroute('/noworlater', true)
              }
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/virtual/selectsite`,
        render: () => {
          return (
            <BookerVirtualVisitSelectSite
              visitOptions={allowedVisitOptions || []}
              onSelectVisitSite={visitSiteId =>
                this.onSelectVisitSite(visitSiteId, VisitMethod.Virtual)
              }
              onBack={this.backToSelectMethod}
              backButtonText={
                this.props.availableMethods.length === 1 &&
                this.state.methodSelected
                  ? t('booker.locationSelection')
                  : `← ${t('booker.backToVisitMethod')}`
              }
              trialOptionId={trialOptionId}
            />
          );
        },
      },
      ...(this.props.allowSpectator
        ? [
            {
              path: `${this.baseUrl}/:configId/booker/virtual/:visitSiteId/addspectator`,
              render: routeProps => {
                const { visitSiteId } = routeProps.match.params;
                return (
                  <BookerVirtualVisitAddSpectator
                    sponsorUsers={sponsorUsers || []}
                    onConfirmVisitSpectator={() =>
                      this.onConfirmVisitSpectator(
                        visitSiteId,
                        VisitMethod.Virtual
                      )
                    }
                    onSelectVisitSpectator={this.onSelectVisitSpectator}
                    onBack={this.backToSelectMethod}
                    onOpen={this.onSponsorListOpen}
                    backButtonText={
                      this.props.availableMethods.length === 1 &&
                      this.state.methodSelected
                        ? t('booker.locationSelection')
                        : `← ${t('booker.backToVisitMethod')}`
                    }
                  />
                );
              },
            },
          ]
        : []),
      {
        path: `${this.baseUrl}/:configId/booker/inperson/selectsite`,
        render: () => {
          return (
            //@ts-ignore type-defs for "react-sizes" is wrong
            <BookerInPersonSelectSite
              visitOptionsWithCoordinates={allowedVisitOptions || []}
              onSelectVisitSite={visitSiteId =>
                this.onSelectVisitSite(visitSiteId, VisitMethod.InPerson)
              }
              onBack={() => {
                this.state.virtualVisitSupportedForZipcode
                  ? this.props.availableMethods.length !== 1
                    ? this.backToSelectMethod()
                    : this.backToZipcode()
                  : userIsParticipant()
                  ? this.backToZipcode()
                  : this.goToSubroute('/noworlater', true);
              }}
              backButtonText={
                this.state.virtualVisitSupportedForZipcode
                  ? this.props.availableMethods.length !== 1
                    ? `← ${t('booker.backToVisitMethod')}`
                    : t('booker.locationSelection')
                  : userIsParticipant()
                  ? t('booker.locationSelection')
                  : `← ${t('common.goBack')}`
              }
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/:method/:visitSiteId/selectslot`,
        render: routeProps => {
          const visitSiteId = parseInt(routeProps.match.params.visitSiteId);

          const visitOption = _find(
            visitOptions,
            o => o.visitSiteId === visitSiteId
          );

          const method = getVisitTypeFromUrlFormat(
            routeProps.match.params.method
          );
          if (!visitOption)
            throw new Error('Invalid visit site ID: ' + visitSiteId);

          return (
            <BookerSelectSlot
              trialOptionId={trialOptionId}
              schedulingWindow={this.props.schedulingWindow}
              trialInstanceId={visitOption.trialInstanceId}
              visitSiteId={visitSiteId}
              visitSiteTimezone={visitOption.timezone}
              onSelectSlot={async (slot: TimeSlot) => {
                const start = LuxonFromAnything(slot.start).toISO();
                await this.onFinalConfirmation(
                  start,
                  visitSiteId,
                  method,
                  slot.visitAvailabilityId,
                  spectatorId
                );
              }}
              onRequestManualScheduling={() => {
                this.onRequestManualScheduling(visitSiteId, method);
              }}
              onBack={() => {
                this.setState({ zipcodeValue: null });
                this.goToSubroute(
                  `/${getUrlFormatFromVisitMethod(method)}/selectsite`
                );
              }}
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/:methodUrlFormat/:visitSiteId/confirm`, // This is the "Conduct Now" scenario
        render: routeProps => {
          const { methodUrlFormat, visitSiteId } = routeProps.match.params;
          const method = getVisitTypeFromUrlFormat(methodUrlFormat);
          const visitSiteIdAsInt = parseInt(visitSiteId);
          const startTimeAsIso = DateTime.now().toISO();

          const visitOption = _find(
            visitOptions,
            o => o.visitSiteId === visitSiteIdAsInt
          );
          if (!visitOption) return null;

          return (
            <BookerFinalConfirmation
              method={method}
              visitOption={visitOption}
              startTime={startTimeAsIso}
              endTime={startTimeAsIso}
              onConfirm={() => {
                this.onFinalConfirmation(
                  startTimeAsIso,
                  visitSiteIdAsInt,
                  method,
                  undefined,
                  spectatorId
                );
              }}
              onBack={() => {
                this.goToSubroute(`/${methodUrlFormat}/selectsite`);
              }}
            />
          );
        },
      },
      {
        path: `${this.baseUrl}/:configId/booker/:method/:visitSiteId/complete`,
        render: routeProps => {
          const visitSiteId = parseInt(routeProps.match.params.visitSiteId);

          const visitOption = _find(
            visitOptions,
            o => o.visitSiteId === visitSiteId
          );

          if (!visitOption) throw new Error('[Booker]: no VisitOption found.');

          const { method } = routeProps.match.params;
          return (
            <BookerManualSuccess
              method={method}
              visitSiteName={visitOption.siteName}
              onSubmit={this.onClose}
            />
          );
        },
      },
    ];
    const validZipcode = !!zipcodeValue && zipcodeValue !== NULL_ZIPCODE;
    const showLoading = validZipcode && !geoSetInState;

    return (
      <div className={`booker ${showLoading ? 'booker-loading' : ''}`}>
        <Switch>
          {/* Routes that don't require a zipcode */}
          {this.renderNowOrLaterRoute()}
          <Route
            exact
            path={`${this.baseUrl}/:configId/booker/confirmzipcode`}
            render={() => {
              const allowBack = !userIsParticipant();
              if (zipcodeValue)
                return (
                  <Redirect
                    to={`${this.baseUrl}/${this.props.match.params.configId}/booker/selectmethod`}
                  />
                );
              return (
                <BookerConfirmZipcode
                  zipcodeValue={this.state.zipcodeTextInputValue}
                  onChangeZipcodeValue={v =>
                    this.setState({
                      zipcodeTextInputValue: v,
                    })
                  }
                  onConfirmOrUpdateZipcode={this.onConfirmOrUpdateZipcode}
                  isReschedule={false}
                  onBack={
                    allowBack
                      ? () => {
                          this.goToSubroute('/noworlater', true);
                        }
                      : undefined
                  }
                />
              );
            }}
          />
          {this.renderSuccessRoute()}
          {preZipcodeRoutes.map((props, i) => (
            <Route key={i} exact {...props} />
          ))}
          {/* Routes that require a zipcode */}
          {showLoading && <Loading />}
          {validZipcode &&
            geoSetInState &&
            postZipcodeRoutes.map((props, i) => (
              <Route key={i} exact {...props} />
            ))}
          <Route>{this.renderCatchAllRedirect()}</Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(withTranslation('translations')(Booker));
