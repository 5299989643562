const {
  REACT_APP_ZENDESK_KEY,
  REACT_APP_TINFOIL_NAME,
  REACT_APP_TINFOIL_CONTENT,
  REACT_APP_SEGMENT_KEY,
  REACT_APP_FULLSTORY_ORG_ID,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_PAYPAL_URL,
  REACT_APP_SENTRY_DNS,
  REACT_APP_ENVIRONMENT,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  REACT_APP_PRIVACY_CENTER_URL,
  REACT_APP_DEPLOYED_REGION,
  REACT_APP_DD_SITE,
  REACT_APP_DD_APPLICATION_ID,
  REACT_APP_DD_CLIENT_TOKEN,
} = process.env;

export const ZENDESK_KEY = REACT_APP_ZENDESK_KEY;
export const TINFOIL_NAME = REACT_APP_TINFOIL_NAME;
export const TINFOIL_CONTENT = REACT_APP_TINFOIL_CONTENT;
export const SEGMENT_KEY = REACT_APP_SEGMENT_KEY;
export const FULLSTORY_ORG_ID = REACT_APP_FULLSTORY_ORG_ID;
export const PAYPAL_CLIENT_ID = REACT_APP_PAYPAL_CLIENT_ID;
export const PAYPAL_URL = REACT_APP_PAYPAL_URL;
export const SENTRY_DNS = REACT_APP_SENTRY_DNS;
export const ENVIRONMENT = REACT_APP_ENVIRONMENT;
export const LAUNCH_DARKLY_CLIENT_SIDE_ID = REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;
export const PRIVACY_CENTER_URL = REACT_APP_PRIVACY_CENTER_URL;
export const DEPLOYED_REGION = REACT_APP_DEPLOYED_REGION || 'en-us';
export const DD_SITE = REACT_APP_DD_SITE;
export const DD_APPLICATION_ID = REACT_APP_DD_APPLICATION_ID;
export const DD_CLIENT_TOKEN = REACT_APP_DD_CLIENT_TOKEN;
