import { getEnumArrayFromSchema } from '@curebase/core/lib/enum';
import { AcceptedCountryCodes, VisitSite } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';
import FormDialog from '../basic/FormDialog';

type Props = {
  open: boolean;
  requestInProgress: boolean;
  onSubmit: (data: any) => Promise<any>;
  onClose: () => void;
  formData: VisitSite | null;
};

const ConfigureResearchSiteDialog = (props: Props) => {
  const { t } = useTranslation('translations');
  const { open, onClose, onSubmit, requestInProgress } = props;

  function getStateOptionsByCountry(data: VisitSite) {
    let options = [];

    switch (data.country) {
      case AcceptedCountryCodes.Us:
        options = getEnumArrayFromSchema('US_STATE');
        break;
      case AcceptedCountryCodes.Ca:
        options = getEnumArrayFromSchema('CA_PROVINCE');
        break;
      case AcceptedCountryCodes.Fr:
        options = getEnumArrayFromSchema('FR_REGION');
        break;

      case AcceptedCountryCodes.Uk:
        break;
    }

    return options.map(item => ({ text: item, value: item }));
  }

  function getPages(t: any) {
    return [
      {
        elements: [
          {
            title: t('configureResearchSiteDialog.pages.name.title'),
            subElements: [
              {
                key: 'name',
                type: 'TEXT',
                placeholder: t(
                  'configureResearchSiteDialog.pages.name.placeholder'
                ),
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.address.title'),
            subElements: [
              {
                key: 'addressLine1',
                type: 'TEXT',
                placeholder: t(
                  'configureResearchSiteDialog.pages.address.placeholder'
                ),
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.address2.title'),
            subElements: [
              {
                key: 'addressLine2',
                type: 'TEXT',
                placeholder: t(
                  'configureResearchSiteDialog.pages.address2.placeholder'
                ),
                allowNull: true,
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.city.title'),
            subElements: [
              {
                key: 'city',
                type: 'TEXT',
                placeholder: t(
                  'configureResearchSiteDialog.pages.city.placeholder'
                ),
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.county.title'),
            subElements: [
              {
                key: 'county',
                type: 'TEXT',
                allowNull: true,
                placeholder: t(
                  'configureResearchSiteDialog.pages.county.placeholder'
                ),
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.country.title'),
            subElements: [
              //$FlowFixMe
              {
                key: 'country',
                type: 'DROPDOWN',
                options: [
                  { text: 'Canada', value: 'ca' },
                  { text: 'France', value: 'fr' },
                  { text: 'United States', value: 'us' },
                  { text: 'United Kingdom', value: 'uk' },
                ],
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.state.title'),
            subElements: [
              //$FlowFixMe
              {
                key: 'state',
                type: 'AUTOCOMPLETE',
                options: getStateOptionsByCountry,
                allowNull: true,
                additionalSettings: {
                  formatAsId: true,
                },
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.zipCode.title'),
            subElements: [
              {
                key: 'zipcode',
                type: 'TEXT',
                placeholder: t(
                  'configureResearchSiteDialog.pages.zipCode.placeholder'
                ),
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.timezone.title'),
            subElements: [
              //$FlowFixMe
              {
                key: 'timezone',
                type: 'AUTOCOMPLETE',
                options: [
                  {
                    text: 'America/Boise',
                    value: 'America/Boise',
                  },
                  {
                    text: 'America/Chicago',
                    value: 'America/Chicago',
                  },
                  {
                    text: 'America/Denver',
                    value: 'America/Denver',
                  },
                  {
                    text: 'America/Detroit',
                    value: 'America/Detroit',
                  },
                  {
                    text: 'America/Los_Angeles',
                    value: 'America/Los_Angeles',
                  },
                  {
                    text: 'America/New_York',
                    value: 'America/New_York',
                  },
                  {
                    text: 'America/Phoenix',
                    value: 'America/Phoenix',
                  },
                  {
                    text: 'Europe/London',
                    value: 'Europe/London',
                  },
                  {
                    text: 'Europe/Paris',
                    value: 'Europe/Paris',
                  },
                ],
                additionalSettings: {
                  formatAsId: true,
                },
              },
            ],
          },
          {
            title: t('configureResearchSiteDialog.pages.slug.title'),
            subElements: [
              {
                key: 'slug',
                type: 'TEXT',
              },
            ],
          },
        ],
      },
    ];
  }

  return (
    <FormDialog
      // @ts-ignore
      dynamicFormPages={getPages(t)}
      initialState={props.formData}
      noSubmit={requestInProgress}
      onClose={onClose}
      onSubmit={onSubmit}
      open={open}
      title={
        props.formData
          ? t('configureResearchSiteDialog.editTitle')
          : t('configureResearchSiteDialog.createTitle')
      }
    />
  );
};

export default ConfigureResearchSiteDialog;
