import { RolePermissions } from '../../types';

export const clinicalTrialManagerPermissions = [
  RolePermissions.ViewPatientPayments,
  RolePermissions.ViewTrials,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewScheduling,
  RolePermissions.ViewSettings,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.ViewInformedConsent,
  RolePermissions.DownloadInformedConsent,
  RolePermissions.ViewCrf,
  RolePermissions.ViewInvestigatorList,
  RolePermissions.ViewTrialOptionVisitBookings,
  RolePermissions.ViewZendeskProfile,
  RolePermissions.ViewReferralSources,
  RolePermissions.ViewUtmCodes,
  RolePermissions.ViewParticipantLocation,
  RolePermissions.SelectStudyReferralSource, // Create stub participants
  RolePermissions.SkipExternalPrescreening,
  RolePermissions.ViewParticipantReferralSource,
  RolePermissions.ViewClinicAtlasConfig,
  RolePermissions.TrialBuilder,
  RolePermissions.ViewParticipantNote,
];
