import { createContext } from 'react';

type ParticipantContextValues = {
  locale?: string;
};

export const OverridableTranslationContext = createContext<ParticipantContextValues>(
  {
    locale: undefined,
  }
);
