import { useContext } from 'react';
import * as React from 'react';
import CustomStyleContext from '../../context/customStyleContext';

type Props = {
  id: string;
  type: 'radio' | 'checkbox';
  isChecked: boolean;
  readOnly: boolean;
  onClick: () => void;
  option: {
    text: string;
    url?: string | null;
  };
};

const InnerImage = ({ option }) => (
  <span className='inner-img'>
    <img src={option.url} alt={option.text} />
  </span>
);

const MultipleChoiceInput = (props: Props) => {
  const context = useContext(CustomStyleContext);
  const { id, type, isChecked, readOnly, onClick, option } = props;

  const noop = e => e && e.stopPropagation();
  return (
    <div
      className={`df-multiple-choice-option ${isChecked ? 'active' : ''}`}
      style={{
        borderColor: isChecked ? context?.colors?.text : '',
      }}
      onClick={onClick}
    >
      <input
        checked={isChecked}
        style={{
          borderColor: isChecked ? context?.colors?.text : '',
        }}
        disabled={readOnly}
        id={id}
        onChange={noop}
        type={type}
      />
      <label
        className='dynamic-forms-multiselect-text'
        htmlFor={id}
        onClick={noop}
      >
        {type === 'radio' && option.url && <InnerImage option={option} />}
        <span className='inner-text'>{option.text}</span>
      </label>
      {type === 'checkbox' && option.url && <InnerImage option={option} />}
    </div>
  );
};

export default MultipleChoiceInput;
