import React from 'react';

export interface Props {
  onClick?: () => void;
  className?: string;
  customLogo?: string;
  alt?: string;
}

export default function PageBrand({
  onClick,
  className,
  customLogo,
  alt,
}: Props) {
  return (
    <img
      onClick={() => onClick && onClick()}
      className={className || `ph-logo`}
      src={customLogo || '/logo2020.svg'}
      alt={alt || 'Curebase Logo'}
    />
  );
}
