import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'src/store/user/selectors';
import { filterRolesByTrial } from '@curebase/core/lib/authorization';

/**
 * Memoized hook that returns a user object with only the roles applicable to the specified trialId
 * @param trialId trialId for which to return roles. If none specified, return only trial-agnostic roles.
 */
const useRoles = (trialId?: number) => {
  const user = useSelector(getUser);
  const userWithFilteredRoles = useMemo(
    () => filterRolesByTrial(user, trialId),
    [user, trialId]
  );
  return userWithFilteredRoles;
};

export default useRoles;
