import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

export function useViewContainerDimensions() {
  const res = document.getElementById('view-container');
  const [state, setState] = useState(
    res?.getBoundingClientRect() ?? {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  );

  const updateState = throttle(() => {
    setState(
      res?.getBoundingClientRect() ?? {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    );
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateState.cancel();
      updateState();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}
