import * as D from 'io-ts/Decoder';
import { pipe } from 'fp-ts/function';
import { DocumentAction } from '../types';

export enum SigningType {
  remote = 'remote',
  inPerson = 'inPerson',
}

export const SigningTypeValidator = D.literal(
  SigningType.inPerson,
  ...Object.values(SigningType)
);

export const CreateSignature = pipe(
  D.struct({
    legalName: D.string,
    filePath: D.string,
    fileId: D.string,
    reason: D.string,
  }),
  D.intersect(
    D.partial({
      childLegalName: D.string,
      attestations: D.array(D.string),
    })
  )
);
export type CreateSignature = D.TypeOf<typeof CreateSignature>;

export const CreateSignedDocumentRequest = pipe(
  D.struct({
    action: D.literal(
      DocumentAction.AcquireParticipantSignature,
      ...Object.values(DocumentAction)
    ),
    documentType: D.string,
    trialOptionId: D.number,
    signingType: SigningTypeValidator,
    documentConfigurationId: D.string,
  }),
  D.intersect(
    D.partial({
      statusJustification: D.string,
      signature: CreateSignature,
    })
  )
);
export type CreateSignedDocumentRequest = D.TypeOf<
  typeof CreateSignedDocumentRequest
>;
export type CreateSignedDocumentResponse = {
  signedDocumentId: number;
  nextSignableDocument?: string;
  hasSignedAllDocuments: boolean;
};

export const CreatePaperDocumentRequest = D.struct({
  documentType: D.string,
  trialOptionId: D.number,
  fileIds: D.array(D.string),
});
export type CreatePaperDocumentRequest = D.TypeOf<
  typeof CreatePaperDocumentRequest
>;

export const ResetDocumentRequest = D.struct({
  trialOptionId: D.number,
  documentType: D.string,
});
export type ResetDocumentRequest = D.TypeOf<typeof ResetDocumentRequest>;
export type ResetDocumentResponse = { success: boolean };

export const AutoCompleteDocumentsRequest = D.struct({
  trialOptionId: D.number,
});
export type AutoCompleteDocumentsRequest = D.TypeOf<
  typeof AutoCompleteDocumentsRequest
>;
