import React from 'react';
import ListItem from '../basic/ListItem';
import { SDVChip } from './SDV/SDVChip';

type Props = {
  isSDVRequired: boolean;
  isAllVerified: boolean;
  isComplete: boolean;
  listItemProps: React.ComponentProps<typeof ListItem>;
};

export const InformedConsentListItem = ({
  isSDVRequired,
  isAllVerified,
  isComplete,
  listItemProps,
}: Props) => {
  const tags: React.ReactElement[] = [];

  if (isSDVRequired && isComplete) {
    tags.push(<SDVChip key={0} isSourceDataVerified={isAllVerified} />);
  }

  return (
    <ListItem
      {...listItemProps}
      middle={{
        ...(listItemProps.middle ?? {}),
        tags,
      }}
    />
  );
};
