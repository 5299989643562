import './utils/input-number-remove-wheel';

//css
import 'graphiql/graphiql.css';
import './App.css';
import './styles/less/Main.less';

import { CThemeProvider } from '@curebase/design-system';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { client } from './ApolloClient';
import AppView from './AppView';
import AppAnalytics from './components/Analytics/AppAnalytics';
import FeaturesFlagsProvider from './providers/FeaturesFlagsProvider';
import { history } from './sentry';
import { getDispatch, persistor, store } from './store';
import { getThemeBasedOnEnvironmentType } from './styles/themes';

const envTheme = getThemeBasedOnEnvironmentType();

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato',
  },
  palette: {
    primary: {
      main: envTheme.colors?.primary as string,
    },
    secondary: {
      main: envTheme.colors?.secondary as string,
      contrastText: envTheme.contrast?.secondary as string,
    },
  },
});

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <FeaturesFlagsProvider>
            <ApolloProvider client={client}>
              <MuiThemeProvider theme={theme}>
                <CThemeProvider>
                  <AppAnalytics />
                  <AppView store={store} />
                </CThemeProvider>
              </MuiThemeProvider>
            </ApolloProvider>
          </FeaturesFlagsProvider>
        </Router>
      </PersistGate>
    </ReduxProvider>
  );
}

setInterval(() => {
  const alertMessage = store.getState().ui.alertMessage;
  if (alertMessage && alertMessage.visibleForSeconds > 0) {
    getDispatch()({
      type: 'TICK_ALERT_MESSAGE_TIMER',
    });
  }
}, 1000);

export default App;
