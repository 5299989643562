import queryString from 'query-string';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { signoutSession } from '../../controllers/authController';
import { attemptLogin } from '../../lib/auth';
import { supportPhoneNumber } from '../../lib/constants';
import { isCurebaseMobileApp } from '../../lib/ui';
import { userIsParticipant } from '../../lib/users';
import StandardButton from '../basic/StandardButton';
import StatusText from '../basic/StatusText';
import TextInput from '../basic/TextInput';

type Props = {
  history: any;
  location: any;
  t: any;
  sessionExpired?: boolean;
};

type State = {
  enteredEmail: string;
  enteredPassword: string;
  isRememberChecked?: string;
  errorMessage?: string;
};

type SearchParams = {
  redirect: string;
};

const getNextPath = (redirect: string) => {
  if (userIsParticipant()) {
    return redirect
      ? `/u/trialSelector?redirect=${redirect}`
      : '/u/trialSelector';
  }
  return redirect ? `${redirect}` : '/u';
};

class Login extends React.Component<Props, State> {
  state: State = {
    enteredEmail: '',
    enteredPassword: '',
  };

  async componentDidMount() {
    await signoutSession();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className='auth-right-header'>
          {this.props.sessionExpired
            ? t('auth.login.sessionExpiredTitle')
            : t('auth.login.title')}

          <StatusText color='red' text={this.state.errorMessage || ''} />

          {this.props.sessionExpired && !this.state.errorMessage && (
            <StatusText
              color='gray'
              text={t('auth.login.sessionExpiredDesc')}
            />
          )}
        </div>

        <div className='auth-right-body'>
          <TextInput
            placeholder={t('auth.login.emailPlaceholder')}
            onChange={v => this.setState({ enteredEmail: v })}
            specialType='email'
            value={this.state.enteredEmail}
            onEnter={() => this.onSubmit()}
            hidePersonalInformation
          />

          <TextInput
            placeholder={t('auth.login.passwordPlaceholder')}
            onChange={v => this.setState({ enteredPassword: v })}
            specialType='password'
            value={this.state.enteredPassword}
            onEnter={() => this.onSubmit()}
            hidePersonalInformation
          />

          <div className='auth-login-bottom-row'>
            {!isCurebaseMobileApp() && (
              <div className='auth-login-bottom-row-left'>
                <div
                  className='auth-right-link'
                  onClick={() => {
                    this.props.history.push('/auth/magic-link');
                  }}
                >
                  {t('auth.login.magicLink')}
                </div>
              </div>
            )}

            <div className='auth-login-bottom-row-right'>
              <div
                className='auth-right-link'
                onClick={() => {
                  this.props.history.push('/auth/forgot-password');
                }}
              >
                {t('auth.login.forgotPassword')}
              </div>
            </div>
          </div>

          <StandardButton
            onClick={this.onSubmit}
            variant='contained'
            fullWidth
            size='xlarge'
          >
            {t('auth.login.logIn')}
          </StandardButton>

          <div className='auth-right-login-bottommost-row'>
            <div className='auth-right-text'>
              {t('auth.login.needHelpLabel')}{' '}
              <b>
                <a href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</a>
              </b>
              .
            </div>
          </div>
        </div>
      </>
    );
  }

  onSubmit = async () => {
    const { t } = this.props;
    const { enteredEmail, enteredPassword } = this.state;
    const handleLoginError = (errorMessage: string) => {
      console.error('Error while logging in: ', errorMessage);
      this.setState({ errorMessage });
    };

    if (enteredEmail === '' || enteredPassword === '') {
      this.setState({ errorMessage: t('auth.login.invalidInputError') });
    } else {
      try {
        const res: any = await attemptLogin(enteredEmail, enteredPassword);
        if (res.error)
          return handleLoginError(
            t(`auth.login.errors.${res.error}`, res.error)
          );

        const values = queryString.parse(
          this.props.location.search
        ) as SearchParams;
        this.props.history.push(getNextPath(values.redirect));
      } catch (error) {
        handleLoginError(error);
      }
    }
  };
}

export default withTranslation('translations')(withRouter(Login as any) as any);
