import { UserRoleType, UserRole, Patient } from '../types';
import { baseUrl } from './env';

interface UserWithRoles<RoleShape extends {}>
  extends Readonly<{
    roles: ReadonlyArray<RoleShape>;
  }> {}

interface RoleWithType extends Pick<UserRole, 'type'> {}

export const userIsParticipant = <T extends UserWithRoles<RoleWithType>>(
  user: T
) => {
  const roles = user?.roles?.map(e => e.type) ?? [];
  return roles.includes(UserRoleType.Patient);
};

export const userIsProviderType = <T extends UserWithRoles<RoleWithType>>(
  user: T
) => {
  const roles = user.roles?.map(e => e.type) ?? [];
  return roles.some(e => isProviderRoleType(e));
};

export const userIsSponsor = <T extends UserWithRoles<RoleWithType>>(
  user: T
) => {
  const roles = user.roles?.map(e => e.type) ?? [];
  return roles.includes(UserRoleType.SponsorApiAdministrator);
};

export const userIsVendor = <T extends UserWithRoles<RoleWithType>>(
  user: T
) => {
  const roles = user.roles?.map(e => e.type) ?? [];
  return roles.includes(UserRoleType.Vendor);
};

export const userHasRoles = (
  user: { roles?: Pick<UserRole, 'type'>[] },
  roles: UserRoleType[] | UserRoleType
) => {
  const userRoles = user.roles?.map(e => e.type) ?? [];
  const checkRoles = Array.isArray(roles) ? roles : [roles];
  return checkRoles.filter(x => userRoles.includes(x)).length > 0;
};

interface RoleWithPatientId {
  patient?: Readonly<Pick<Patient, 'id'>> | null;
}

export const getPatientIdsFromRoles = <
  U extends UserWithRoles<RoleWithPatientId>
>(
  user: U
): number[] => {
  const ids: number[] =
    user.roles
      ?.map(r => r.patient?.id)
      .flat()
      .filter((n: number | undefined): n is number => n !== undefined) ?? [];

  return ids;
};

export const signupOrLoginLink = (isStub: boolean, accessCodes: any[]) => {
  if (isStub) {
    const newestAccessCode = accessCodes.reduce(
      (maxIdAccessCode, anotherAccessCode) =>
        maxIdAccessCode && maxIdAccessCode.id > anotherAccessCode.id
          ? maxIdAccessCode
          : anotherAccessCode,
      null
    );

    if (newestAccessCode) return `${baseUrl}/signup/` + newestAccessCode.code;
  }

  return `${baseUrl}/auth/login`;
};

export const isProviderRoleType = (type: UserRoleType): boolean =>
  [
    UserRoleType.Physician,
    UserRoleType.Nurse,
    UserRoleType.MedicalAdministrator,
  ].includes(type);

export const hasProviderRole = <T extends RoleWithType>(
  roles: ReadonlyArray<T>
) => {
  return roles.some(({ type }) => isProviderRoleType(type));
};
