export function at_least_1_stool(num: string) {
  return parseInt(num) >= 1;
}

export function have_apple_or_android(values: string[]) {
  return (
    values.includes('apple_iphone') ||
    values.includes('apple_ipad') ||
    values.includes('android_smartphone')
  );
}
