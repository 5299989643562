import React from 'react';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import {
  queryLegalDocumentHTML,
  DocumentHTML,
} from 'src/shared/lib/queryHelpers';
import { PolicyDocumentType } from '@curebase/core/types';
import { useTranslation } from 'react-i18next';
interface ExternalTOSAffirmationProps {
  acceptedTOS: boolean;
  privacyPolicyVersion?: string;
  termsOfServiceVersion?: string;
  toggleAcceptedTOS: () => void;
  setState: (
    updates: Partial<{ document: DocumentHTML | null; showDialog: boolean }>
  ) => void;
}
function ExternalTOSAffirmation(props: ExternalTOSAffirmationProps) {
  const {
    acceptedTOS,
    toggleAcceptedTOS,
    setState,
    privacyPolicyVersion,
    termsOfServiceVersion,
  } = props;
  const getTOSHtmlDocument = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type: PolicyDocumentType
  ): Promise<void> => {
    event.preventDefault();
    let document = await queryLegalDocumentHTML(type, privacyPolicyVersion);
    if (type === PolicyDocumentType.TermsOfService) {
      document = await queryLegalDocumentHTML(type, termsOfServiceVersion);
    }
    setState({ document, showDialog: true });
  };
  const { t } = useTranslation('translations');
  return (
    <Grid
      container
      spacing={2}
      justifyContent='flex-start'
      alignItems='center'
      className='signup-affirmation'
    >
      <Grid item sm={1} xs={2}>
        <Checkbox
          className='tos-checkbox'
          color='primary'
          checked={acceptedTOS}
          onChange={toggleAcceptedTOS}
        />
      </Grid>
      <Grid item sm={11} xs={10}>
        <Typography variant='body2' className='signup-tos-text'>
          {`${t('tosa.confirmQuestion.part1')} `}
          <span
            onClick={event =>
              getTOSHtmlDocument(event, PolicyDocumentType.PrivacyPolicy)
            }
          >
            {t('tosa.confirmQuestion.part2')}
          </span>
          {' ' + t('tosa.confirmQuestion.part4') + ' '}
          <span
            onClick={event =>
              getTOSHtmlDocument(event, PolicyDocumentType.TermsOfService)
            }
          >
            {t('tosa.confirmQuestion.part3') + '.'}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
}
export default ExternalTOSAffirmation;
