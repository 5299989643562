import i18next from 'i18next';
import { useEffect } from 'react';
import { zendeskSetLocale } from 'src/lib/zendeskWidget';
import { setLocaleInStorage } from '../context/localeContext';

export const updateLocale = (locale: string) => {
  const [language, country] = locale.split('-');
  setLocaleInStorage(locale);
  i18next.changeLanguage(language);
  zendeskSetLocale(`${language}_${country.toLocaleUpperCase()}`);
};

export const useLocale = (changeTolocale: any) => {
  useEffect(() => {
    if (changeTolocale) {
      updateLocale(changeTolocale);
    }
  }, [changeTolocale]);

  return { locale: changeTolocale };
};
