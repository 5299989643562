import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ParticipantDataCaptureReviewQuery,
  PostCompletionContentOptions,
  useParticipantDataCaptureReviewQuery,
} from 'src/types';

import { PostCompletionContent } from '@curebase/core/types';
import StandardButton from '../basic/StandardButton';
import Loading from '../Loading';
import PageHeader from '../PageHeader';

const { NoPage, DefaultFailed } = PostCompletionContentOptions;

// Types are flexible here
// In the event we get incomplete data, we should not display a page.
export const getPostInfoPageConfig = (
  didPass: boolean,
  postCompletionContent?: PostCompletionContent
) => {
  const passedConfig = postCompletionContent?.PASSED ?? NoPage;
  const failedConfig = postCompletionContent?.FAILED ?? NoPage;
  const configToUse = didPass ? passedConfig : failedConfig;
  return configToUse;
};

const BackToDashboardButton = () => {
  const history = useHistory();
  const { t } = useTranslation('translations');

  return (
    <div className='participant-dc-review-button'>
      <StandardButton
        onClick={_ => history.push('/')}
        size='xlarge'
        text={t('participantDataCaptureReview.backToDashboardBtn')}
        variant='contained'
      />
    </div>
  );
};

const makeContent = (
  content: { title: string; body: string },
  didPass: boolean
) => {
  return (
    <>
      <div className='participant-dc-review-header'>
        <div className='participant-dc-review-header-top-text'>
          {content.title}
        </div>
        <div className='participant-dc-review-header-bottom-text'>
          {content.body}
        </div>
      </div>
      <div className='participant-dc-review-footer'>
        {didPass && (
          <SVG
            src='/visit_eligible.svg'
            className='participant-review-eligible'
          />
        )}

        <BackToDashboardButton />
      </div>
    </>
  );
};

const postVisitContent = (
  data: ParticipantDataCaptureReviewQuery,
  history: any,
  defaultFailedContent: any
) => {
  const { getVisit } = data;
  if (getVisit?.evaluation?.didPass === undefined)
    throw new Error('Visit is not evaluated');

  const {
    evaluation: { didPass },
    studyPlanVisit: { postCompletionContent },
  } = getVisit;

  const configToUse = getPostInfoPageConfig(didPass, postCompletionContent);
  let content;
  if (configToUse === NoPage) {
    history.push('/');
  } else if (configToUse === DefaultFailed) {
    content = makeContent(defaultFailedContent, didPass);
  } else {
    content = makeContent(configToUse, didPass);
  }

  return content;
};

const ParticipantDataCaptureReview = () => {
  const history = useHistory();
  const { t } = useTranslation('translations');
  const {
    params: { visitId },
  } = useRouteMatch<{ visitId: string }>();

  const { data, loading } = useParticipantDataCaptureReviewQuery({
    variables: { visitId: parseInt(visitId) },
  });

  let content;
  if (loading || !data) {
    content = <Loading />;
  } else {
    content = postVisitContent(data, history, {
      title: t('participantDataCaptureReview.defaultFailedVisit.title'),
      body: t('participantDataCaptureReview.defaultFailedVisit.body'),
    });
  }

  return (
    <div className='participant-data-capture-review-container'>
      <PageHeader
        customStyle={data?.getVisit.studyPlanVisit.trial.customStyle}
      />
      <div className='participant-data-capture-review'>{content}</div>
    </div>
  );
};

export default ParticipantDataCaptureReview;
