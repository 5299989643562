import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { getUserSelector } from './store/selectors';
import { getUnauthenticatedRedirect, isAuthenticatedHelper } from './lib/auth';

export type CustomRouteProps =
  | CustomRouteWithComponent
  | CustomRouteWithRenderFunc;
interface CustomRouteWithComponent extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: never;
}

interface CustomRouteWithRenderFunc extends RouteProps {
  component?: never;
  render: (props: RouteComponentProps<any>) => React.ReactNode;
}

const PrivateRoute = (props: CustomRouteProps) => {
  const { component: Component, render, ...rest } = props;

  const user: any = useSelector(getUserSelector);
  if (!isAuthenticatedHelper(user)) {
    return <Redirect to={getUnauthenticatedRedirect(props)} />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (user && user.additionalUserInfo && user.additionalUserInfo.isStub) {
          return <Redirect to={getUnauthenticatedRedirect(props)} />;
        } else {
          if (Component) return <Component {...props} />;
          if (render) return render(props);
          console.error(`PrivateRoute: missing component and render props`);
          return <Redirect to='/' />;
        }
      }}
    />
  );
};

export default PrivateRoute;
