import React from 'react';
import {
  ParagraphSubElement,
  SubElementProps,
} from '@curebase/core/lib/dynamicform/types';

interface ParagraphInputProps {
  data: Record<string, any>;
  readOnly: boolean;
  subEle: ParagraphSubElement;
  pageIndex: number;
  hasError: boolean;
  onChange: (subEle: SubElementProps, value: any, pageIndex: number) => void;
}

function ParagraphInput(props: ParagraphInputProps) {
  const { data, readOnly, subEle, pageIndex, hasError, onChange } = props;
  const disabled = readOnly || subEle.readOnly || false;
  const value = data[subEle.key] ?? '';

  return (
    <textarea
      disabled={disabled}
      spellCheck={subEle.noSpellcheck ? false : undefined}
      cols={50}
      rows={10}
      className={hasError ? 'with-error' : undefined}
      onChange={async e => {
        const { value } = e.target;
        onChange(subEle, value, pageIndex);
      }}
      value={value ?? ''}
      placeholder={disabled ? undefined : subEle.placeholder}
    />
  );
}

export { ParagraphInput };
