import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  ParticipantListQuery,
  ParticipantListQueryVariables,
  TrialOption,
} from '@curebase/core/types';
import { useSelector } from 'react-redux';
import { ParticipantInfo } from '../../TrialOption/ParticipantTags';
import ListItem from '../ListItem';
import { ParticipantListDocument } from 'src/types';
import { TReduxState } from 'src/store/types';
import Loading from 'src/components/Loading';
import usePaginatedQuery, { Page } from 'src/hooks/usePaginatedQuery';
import { useTranslation } from 'react-i18next';
import { isSchedulingFilter } from '@curebase/core/lib/trialOptionHelpers';
import Analytics, {
  AnalyticsEventType,
  AnalyticsEventProperty,
} from 'src/components/Analytics/Analytics';
import { getTrialNameById } from 'src/store/user/selectors';

type Props = {
  embedded: boolean;
  onPicked: (trialOptionId: number) => void;
};

/**
 * Helper function that iterates through the results to assess which
 * filters matched on which part of a result.
 *
 * For example, if a user typed in a search query that matched to the
 * email of a participant, the "likeEmail" value will go up by +1.
 * @param query
 * @param data
 */
const findSearchQueryLike = (query: string, data) => {
  const likeCounts = {
    likeName: 0,
    likeSubjectIdentifier: 0,
    likeEmail: 0,
    likeVisitSite: 0,
  };
  if (!data) return likeCounts;
  data.forEach((el: any) => {
    if (el?.patient?.user?.displayName?.includes(query)) {
      likeCounts.likeName += 1;
    }
    if (el?.subjectIdentifier?.includes(query)) {
      likeCounts.likeSubjectIdentifier += 1;
    }
    if (el?.patient?.user?.email?.includes(query)) {
      likeCounts.likeEmail += 1;
    }
    if (el?.visitSite?.name?.includes(query)) {
      likeCounts.likeVisitSite += 1;
    }
  });
  return likeCounts;
};

function ParticipantList(props: Props): React.ReactElement {
  const { t } = useTranslation('translations');
  const { embedded, onPicked } = props;

  const selector = (state: TReduxState) => ({
    participantPicker: state.participantPicker,
  });

  const { participantPicker } = useSelector(selector);
  const {
    trialIds,
    searchQuery,
    filter,
    statuses,
    sortingOrder,
    epochs,
  } = participantPicker;

  function getVariables(): ParticipantListQueryVariables {
    const hasTrialIds = trialIds && trialIds.length > 0;
    const hasStatuses = statuses && statuses.length > 0;
    const filterEpochs = epochs.map((epoch: string) =>
      epoch === 'none' ? null : epoch
    );
    const hasEpochs = filterEpochs && filterEpochs.length > 0;
    return {
      searchQuery,
      filter,
      sortingOrder,
      ...(hasTrialIds && { trialId: trialIds }),
      ...(hasStatuses && { status: statuses }),
      ...(hasEpochs && { epoch: filterEpochs }),
      withVisitBooking: isSchedulingFilter(filter),
    };
  }

  const {
    data,
    loading,
    error,
    page,
    resultsCount,
    variables,
  } = usePaginatedQuery<ParticipantListQuery, ParticipantListQueryVariables>({
    query: 'getParticipantList',
    document: ParticipantListDocument,
    options: {
      variables: getVariables(),
      fetchPolicy: 'network-only',
    },
  });

  useEffect(() => {
    // If the value of the searchQuery returned by the graphql query changes
    // (aka if a user inputs a search query) then send an event signalling
    // that the user just searched.
    if (variables?.searchQuery && data && !loading) {
      const likeResults = findSearchQueryLike(
        searchQuery,
        data?.getParticipantList?.rows
      );
      Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_SEARCH_MATCH, {
        [AnalyticsEventProperty.NUMBER_PARTICIPANTS_VISIBLE]: resultsCount,
        ...(data ? likeResults : {}),
      });
    }
  }, [data]);
  const getTrialById = useSelector(getTrialNameById);
  useEffect(() => {
    if (data && !loading) {
      // This is essentially a page view, it reloads whenever the participant
      // data changes.
      Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_VIEW, {
        [AnalyticsEventProperty.NUMBER_PARTICIPANTS_VISIBLE]: resultsCount,
        [AnalyticsEventProperty.OPERATIONS_FILTER_DESCRIPTION]: filter,
        [AnalyticsEventProperty.STUDY_NAME_LIST]: trialIds.map(el =>
          getTrialById(el)
        ),
        [AnalyticsEventProperty.PARTICIPANT_STATUS]: variables?.status || '',
        [AnalyticsEventProperty.SEARCH_QUERY_CHANGED]: variables?.searchQuery
          ? true
          : false,
      });
    }
  }, [
    resultsCount,
    filter,
    trialIds,
    variables?.status,
    variables?.epoch,
    variables?.searchQuery,
  ]);

  useEffect(() => {
    page.set(page.initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantPicker]);

  if (!data || loading || error) {
    return <Loading />;
  }

  const trialOptions = data.getParticipantList?.rows;

  function renderPage(trialOption: TrialOption): React.ReactNode {
    const infoTrialOption = {
      ...trialOption,
      visitBookings: trialOption.visitBooking ? [trialOption.visitBooking] : [],
    };

    return (
      trialOption && (
        <ListItem
          onClick={() => {
            // Send the open profile event here
            Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_OPEN_PROFILE, {
              [AnalyticsEventProperty.STUDY_NAME]: trialOption?.trial?.name,
              [AnalyticsEventProperty.PARTICIPANT_STATUS]: trialOption?.status,
            });
            onPicked(trialOption.id);
          }}
        >
          <ParticipantInfo clickable={true} trialOption={infoTrialOption} />
        </ListItem>
      )
    );
  }

  function onPageChange(_: React.ChangeEvent<unknown>, nextPage: Page): void {
    // Send an analytics event when the user uses pagination to go
    // between pages of users.
    Analytics.track(AnalyticsEventType.PARTICIPANT_LIST_PAGINATION, {
      [AnalyticsEventProperty.PAGINATION_NAVIGATION_NEXT]: nextPage,
      [AnalyticsEventProperty.PAGINATION_NAVIGATION_CURRENT]: page.current,
    });
    page.set(nextPage);
  }

  const participantList: React.ReactNode = React.Children.toArray(
    trialOptions.map(renderPage)
  );

  const isEmpty: boolean = trialOptions?.length === 0;

  return (
    <>
      <div className={`li-multi-container ${!embedded ? 'inset' : ''}`}>
        {isEmpty && (
          <ListItem
            middle={{
              title: t('pickers.participantList.noParticipantFoundTitle'),
              text: t('pickers.participantList.noParticipantFoundText'),
            }}
          />
        )}
        {participantList}
      </div>
      {!isEmpty && (
        <Box display='flex' justifyContent='center' p={3}>
          <Pagination
            page={page.current}
            size='large'
            shape='rounded'
            color='primary'
            onChange={onPageChange}
            count={page.total}
          />
        </Box>
      )}
    </>
  );
}

export default ParticipantList;
