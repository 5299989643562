import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { calculateStrength, Strength } from 'src/lib/password';
import { strengthColors } from 'src/shared/lib/colors';

interface Props {
  password: string | undefined;
}

type StyleProps = {
  color: string;
};

const useStyles = makeStyles({
  root: {
    height: 8,
    width: '100%',
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#D4D5D7',
  },
  bar: {
    backgroundColor: ({ color }: StyleProps) => color,
    borderRadius: 5,
  },
});

function PasswordStrength(props: Props): React.ReactElement {
  const { t } = useTranslation('translations');
  const { password = '' } = props;

  const strength: Strength = calculateStrength(password);

  const color = strengthColors[strength];
  const classes = useStyles({ color });

  const percentage: Record<Strength, number> = {
    [Strength.None]: 0,
    [Strength.Weak]: 33,
    [Strength.Decent]: 67,
    [Strength.Strong]: 100,
  };

  return (
    <Box
      className='password-strength-container'
      display='flex'
      flexDirection='column'
      marginTop={1}
    >
      <strong className='strength-header'>
        {t('auth.passwordStrength.headerText')}
        <span style={{ color }}>{strength.toString()}</span>
      </strong>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        marginTop={0.5}
      >
        <LinearProgress
          classes={classes}
          variant='determinate'
          value={percentage[strength]}
        />
      </Box>
    </Box>
  );
}

export default PasswordStrength;
