import { isStudyActivityActive } from '@curebase/core/lib/studyPlan';
import {
  PatientIntegrationSourceDataType,
  StudyActivity,
  StudyActivityStatus,
  StudyActivityType,
  TrialOption,
} from '@curebase/core/types';
import { isDataCaptureSubmittableAtTime } from '@curebase/modules/dataCapture/services';
import { TFunction } from 'i18next';
import _capitalize from 'lodash/capitalize';
import { RouteComponentProps } from 'react-router-dom';
import { submitSkipData } from 'src/controllers/dataCaptureController';
import { getLocaleFormatDates } from '../../../context/localeContext';
import { markCustomActionAsComplete } from '../../../controllers/patientController';
import { generatePaypalConnectURL } from '../../../lib/payments';
import { getViewPatientBaseUrl } from '../../../lib/users';
import { ActionDisplayProps } from '../../basic/ActionCard';
import { ConnectRoutes } from '../ConnectSourceData/ConnectSourceDataRoutes';
import { whenStringFromBeginningAndMaybeEnd } from '../helpers';

//
// Descontructing
//

const {
  ConnectPayment,
  ConnectSourceData,
  CustomAction,
  DataCapture,
  InformedConsent,
  ScheduleVisit,
  WaitForScheduling,
} = StudyActivityType;

const { Active } = StudyActivityStatus;

//
// Helper functions
//

export function actionCardShowSkipButton(activity: StudyActivity) {
  return activity?.config?.optional && activity?.config?.showSkipButton;
}

//
// interfaces
//

export interface IActivitySelectorParams {
  trialOption: TrialOption;
  currentDate: any;
  history: RouteComponentProps['history'];
  t: TFunction;
  refetch: () => Promise<any>;
}
/**
 * The Activity Selector Function
 * @description This function is reponsible to receive the Participant Dashboard props and
 * return a function to map the StudyActivity param and return ActionDisplayProps.
 * @param trialOption
 * @param currentDate
 * @param history
 * @param t
 * @returns
 */
export function activitySelector(params: IActivitySelectorParams) {
  const { trialOption, currentDate, history, t, refetch } = params;
  const localesFormat = getLocaleFormatDates();

  function getInformedConsentDisplayProps(
    activity: StudyActivity
  ): ActionDisplayProps {
    const optional = actionCardShowSkipButton(activity);
    let titleDesc = {
      title: t('participants.informConcentDisplay.title'),
      description: t('participants.informConcentDisplay.desc'),
    };
    let buttonText = t('participants.informConcentDisplay.button');
    if (activity.isReconsentActive) {
      titleDesc = {
        title: t('participants.reconsentDisplay.title'),
        description: t('participants.reconsentDisplay.description'),
      };
      buttonText = t('participants.reconsentDisplay.button');
    }
    const buttons =
      activity.status === Active
        ? [
            {
              text: buttonText,
              onClick: () => history.push(`/u/${trialOption.id}/consent`),
            },
          ]
        : null;

    return {
      ...titleDesc,
      iconSrc: '/icons/actionCardSignature.svg',
      buttons,
      optional,
    };
  }

  function getDataCaptureDisplayProps(
    activity: StudyActivity
  ): ActionDisplayProps {
    // TODO make this into a component; we shouldn't have to fish through all SPVs to find the right one, we should request it on-demand
    const spv = trialOption.trialConfiguration.studyPlanVisits.find(
      spv => spv.slug === activity.config.configId
    );
    if (!spv)
      throw new Error('Data capture actions should have study plan visits');

    const { activeTimes } = activity;

    const optional = actionCardShowSkipButton(activity);
    const buttons =
      isStudyActivityActive(activity) &&
      !!activeTimes &&
      isDataCaptureSubmittableAtTime(activeTimes, currentDate)
        ? [
            {
              text:
                activity.status === Active
                  ? t('participants.begin')
                  : t('participants.submitEarly'),
              onClick: () =>
                history.push(`/u/data/${activity.activeId}/capture`),
            },
          ]
        : null;

    const onConfirmSkip = optional
      ? () => {
          submitSkipData(activity.activeId as number)
            .then(async _ => {
              await refetch();
            })
            .catch(err => {
              console.error({ err });
            });
        }
      : undefined;

    return {
      title: spv.summary.title,
      description: spv.summary.description,
      whenString:
        !!activity.activeId && activeTimes?.firstSubmittableTime
          ? whenStringFromBeginningAndMaybeEnd(
              currentDate,
              t('participants.submit'),
              activeTimes.dueTime,
              activeTimes.extendedLastSubmittableTime
                ? activeTimes.extendedLastSubmittableTime
                : activeTimes.lastSubmittableTime,
              localesFormat.moment.shortTime,
              t
            )
          : null,
      iconSrc: '/icons/actionCardSurvey.svg',
      buttons,
      optional,
      onConfirmSkip,
    };
  }

  function getScheduleVisitDisplayProps(_: StudyActivity): ActionDisplayProps {
    return {
      title: t('participants.scheduleVisit'),
      iconSrc: '/icons/actionCardCalendar.svg',
    };
  }

  function getCustonActionProps(activity: StudyActivity): ActionDisplayProps {
    const { title } = activity.content!;
    const optional = actionCardShowSkipButton(activity);
    const buttons = [
      {
        text: t('participants.clickToDismiss'),
        onClick: async () => {
          await markCustomActionAsComplete(
            trialOption.id,
            activity.config.configId!
          );
          await refetch();
        },
      },
    ];

    return {
      title,
      iconSrc: '/icons/actionCardSurvey.svg',
      buttons,
      optional,
    };
  }

  function getWaitForSchedulingDisplayProps(
    activity: StudyActivity
  ): ActionDisplayProps {
    const optional = actionCardShowSkipButton(activity);
    const buttons = [
      {
        text: t('participants.waitScheduling.button'),
        onClick: () =>
          history.push(
            `${getViewPatientBaseUrl(trialOption.id)}/booker/cancelrequest`
          ),
      },
    ];

    return {
      title: t('participants.waitScheduling.title'),
      description: t('participants.waitScheduling.desc'),
      iconSrc: '/icons/actionCardCalendar.svg',
      buttons,
      optional,
    };
  }

  function getConnectPaymentDisplayProps(_: StudyActivity): ActionDisplayProps {
    const paypalUrl = generatePaypalConnectURL();
    return {
      title: t('participants.connectPayment.title'),
      description: t('participants.connectPayment.desc'),
      iconSrc: '/icons/actionCardCreditCard.svg',
      buttons: [
        {
          text: t('participants.connectPayment.button'),
          onClick: () => {
            window.open(paypalUrl, '_blank');
          },
        },
      ],
    };
  }

  function getConnectSourceDataDisplayProps(
    activity: StudyActivity
  ): ActionDisplayProps {
    const {
      config: { configId },
    } = activity;

    const tPrefix = `patientInterface.dashboard.cards.CONNECT_SOURCE_DATA.${configId}`;

    // default to medical icon if both wellness and medical
    const iconName =
      configId === PatientIntegrationSourceDataType.Wellness
        ? PatientIntegrationSourceDataType.Wellness
        : PatientIntegrationSourceDataType.Medical;

    return {
      title: t(`${tPrefix}.title`),
      description: t(`${tPrefix}.description`),
      iconSrc: `/icons/actionCard${_capitalize(iconName)}.svg`,
      buttons: [
        {
          text: t('participants.connectNow'),
          onClick: () =>
            history.push(
              `${getViewPatientBaseUrl(trialOption.id)}/${ConnectRoutes.main}/${
                ConnectRoutes.HUMAN_API
              }`
            ),
        },
      ],
    };
  }

  const mapper = {
    [CustomAction]: getCustonActionProps,
    [ConnectSourceData]: getConnectSourceDataDisplayProps,
    [ConnectPayment]: getConnectPaymentDisplayProps,
    [DataCapture]: getDataCaptureDisplayProps,
    [InformedConsent]: getInformedConsentDisplayProps,
    [ScheduleVisit]: getScheduleVisitDisplayProps,
    [WaitForScheduling]: getWaitForSchedulingDisplayProps,
  };

  return {
    getDisplayPropsFromActivity(activity: StudyActivity): ActionDisplayProps {
      return mapper[activity.config.type](activity);
    },
  };
}

// End of Get display props functions.
