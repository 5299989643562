import moment from 'moment';

export function age_over_18(dateOfBirth: string) {
  return moment().diff(moment(dateOfBirth), 'years') >= 18;
}

export function age_over_14(dateOfBirth: string) {
  return moment().diff(moment(dateOfBirth), 'years') >= 14;
}

export function age_between_21_40(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 21 && years <= 40;
}

export function age_between_18_70(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 18 && years <= 70;
}

export function age_between_18_75(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 18 && years <= 75;
}

export function age_between_18_85(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 18 && years <= 85;
}

export function age_between_18_45(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 18 && years <= 45;
}

export function age_between_40_75(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 40 && years <= 75;
}

export function age_between_21_80(dateOfBirth: string) {
  const years = moment().diff(moment(dateOfBirth), 'years');
  return years >= 21 && years <= 80;
}

export function bmi_between_18_28(bmi: any) {
  const _bmi = parseInt(bmi);
  return _bmi >= 18.5 && bmi <= 28;
}

export function bmi_between_18_30(bmi: any) {
  const _bmi = parseInt(bmi);
  return _bmi >= 18.5 && bmi <= 30;
}

export function weight_at_least_110(weight: any) {
  const _weight = parseInt(weight);
  return _weight >= 110;
}

export function bmi_at_most_30(bmi: any) {
  return parseInt(bmi) <= 30;
}

export function exercise_less_than_12_hours(hours: any) {
  return parseInt(hours) < 12;
}

export function average_10_drinks_per_week(drinks: any) {
  return parseInt(drinks) < 10;
}

export function number_greater_than_equal_to_18(number: any) {
  return parseInt(number) >= 18;
}

export function number_greater_than_equal_to_2(theNumber: any) {
  return parseInt(theNumber) >= 2;
}
