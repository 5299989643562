import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ListItem from '../basic/ListItem';
import StandardButton from '../basic/StandardButton';
import Subheader from '../basic/Subheader';
import SafeDialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { InjectedProps, withQueryResult } from '../hocs/WithQueryResult';
import {
  ResearchSiteList,
  ResearchSiteListQuery,
  ResearchSiteListQueryVariables,
} from '@curebase/core/types';
import { formatAddress } from '@curebase/core/lib/clinics';
import ConfigureResearchSiteDialog from './ConfigureResearchSiteDialog';
import {
  createVisitSite,
  editVisitSite,
  activateVisitSite,
  deactivateVisitSite,
} from '../../controllers/visitSiteController';
import {
  CreateVisitSiteRequest,
  EditVisitSiteRequest,
} from '@curebase/core/decoders/visitsites';

interface Props
  extends InjectedProps<ResearchSiteListQueryVariables, ResearchSiteListQuery> {
  subHeaderText: string;
}

const ResearchSiteListView = (props: Props) => {
  const { t } = useTranslation('translations');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [deactivateDialogOpen, setDeactivateDialogOpen] = React.useState(false);
  const [visitSiteToEdit, setVisitSiteToEdit] = React.useState(null);
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const { visitSites, id: clinicId } = props.queryResult?.getClinic ?? {};
  const hasSites = !!visitSites && !!visitSites.length && visitSites.length > 0;

  const onSubmit = async (
    data: CreateVisitSiteRequest | EditVisitSiteRequest
  ) => {
    if (!props.queryResult || !clinicId) return;
    setRequestInProgress(true);
    const response: boolean = !visitSiteToEdit
      ? await createVisitSite(clinicId, data as CreateVisitSiteRequest)
      : await editVisitSite(data as EditVisitSiteRequest);
    if (!response) {
      setRequestInProgress(false);
      return;
    }

    setVisitSiteToEdit(null);
    setRequestInProgress(false);
    setDialogOpen(false);
    await props.queryResult.refetch();
  };

  const activateFunc = async site => {
    setRequestInProgress(true);
    await activateVisitSite(site.id);
    setRequestInProgress(false);
    await props.queryResult.refetch();
  };

  const deactivateFunc = async site => {
    setRequestInProgress(true);
    await deactivateVisitSite(site.id);
    setRequestInProgress(false);
    setDeactivateDialogOpen(false);
    await props.queryResult.refetch();
  };

  const editButton = site => ({
    text: t('researchSiteListView.editBtn'),
    onClick: () => {
      setVisitSiteToEdit(site);
      setDialogOpen(true);
    },
    disabled: requestInProgress,
  });

  const activateButton = site => ({
    text: t('researchSiteListView.activateBtn'),
    onClick: async () => await activateFunc(site),
    disabled: requestInProgress || !site.deactivated,
  });

  const deactivateButton = site => ({
    text: site.deactivated
      ? t('researchSiteListView.deactivatedBtn')
      : t('researchSiteListView.deactivateBtn'),
    onClick: () => {
      setDeactivateDialogOpen(true);
      setVisitSiteToEdit(site);
    },
    styleName: 'li-button-right',
    color: 'secondary',
    disabled: site.deactivated || requestInProgress,
  });

  return (
    <>
      <SafeDialog open={deactivateDialogOpen}>
        <ConfirmationInDialog
          title={t('researchSiteListView.confirmDialog.title')}
          message={t('researchSiteListView.confirmDialog.message')}
          onClose={() => {
            setVisitSiteToEdit(null);
            setDeactivateDialogOpen(false);
          }}
          onConfirm={() => deactivateFunc(visitSiteToEdit)}
        />
      </SafeDialog>
      <ConfigureResearchSiteDialog
        key={dialogOpen ? 0 : 1}
        open={dialogOpen}
        formData={visitSiteToEdit}
        requestInProgress={requestInProgress}
        onSubmit={onSubmit}
        onClose={() => setDialogOpen(false)}
      />
      <Subheader
        text={props.subHeaderText}
        buttons={[
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setVisitSiteToEdit(null);
              setDialogOpen(true);
            }}
          >
            {t('researchSiteListView.newSiteBtn')}
          </Button>,
        ]}
      />
      {!hasSites ? (
        <ListItem
          middle={{
            title: t('researchSiteListView.noSiteTitle'),
            text: t('researchSiteListView.noSiteText'),
          }}
        />
      ) : (
        <div className='li-multi-container inset'>
          {!!visitSites &&
            visitSites.map((site, i) => {
              const buttons = [
                editButton(site),
                {
                  text: t('researchSiteListView.schedulingBtn'),
                  onClick: () => {
                    const path = `/u/clinic/${clinicId}/site/${site.id}/scheduling`;
                    props.history.push(path);
                  },
                },
                deactivateButton(site),
              ];
              if (site.deactivated) buttons.push(activateButton(site));

              return (
                <ListItem
                  key={i}
                  middle={{
                    title: site.name,
                    subtitle: formatAddress(site),
                    buttons: buttons.map((button: any, index: number) => (
                      <StandardButton key={index} {...button} />
                    )),
                  }}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

const ResearchSiteListComponent = withQueryResult(
  ResearchSiteListView,
  ResearchSiteList,
  (props: any): ResearchSiteListQueryVariables => {
    return {
      clinicId: props.match.params.clinicId,
    };
  }
);

export default withTranslation('translations')(ResearchSiteListComponent);
