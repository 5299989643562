import * as FullStory from '@fullstory/browser';

import {
  disableFullStoryInStore,
  enableFullStoryInStore,
} from '../store/actions';

import { FULLSTORY_ORG_ID } from './env';
import { userIsParticipant } from './users';

export function installFullStory() {
  FullStory.init({ orgId: FULLSTORY_ORG_ID });
}

export function shutdownFullStory() {
  if (!window['FS']) return;
  clearFullStoryIdentity();
  window['FS'] = undefined;
}

function clearFullStoryIdentity() {
  if (!window['FS']) return;
  // This is the only way to clear a fullstory identity
  window['FS'].identify(null);
  window['FS'].shutdown();
  window['FS'].restart();
  window['FS'].identify(null);
}

export function ensureFullStoryIdentity(userId?: number) {
  // FullStory might not be installed (e.g. staging, local dev, etc.)
  if (!window['FS']) {
    return;
  }
  if (userId) {
    window['FS'].identify(userId);
  } else {
    clearFullStoryIdentity();
  }
}

function turnOnFullStory(userId?: number) {
  // FullStory might not be installed (e.g. staging, local dev, etc.)
  if (!window['FS']) {
    return;
  }
  enableFullStoryInStore();
  window['FS'].shutdown();
  window['FS'].restart();
  ensureFullStoryIdentity(userId);
}

// Routes have FullStory set to OFF by default
// Each user type has its own set of routes where FullStory should be ON
// If a user type is not specified here, then all its routes have FullStory OFF
// NO_USER is special key for unauthenticated routes and their behavior
const fullStoryWhitelist = {
  ANY_OR_NO_USER: [new RegExp('/explore/*'), new RegExp('/auth/*')],
  PATIENT: [
    new RegExp('/legal/*'),
    new RegExp('/u/dashboard/patient/[0-9]+', 'g'),
    new RegExp('/u/data/[0-9]+/capture'),
    new RegExp('/u/[0-9]+/consent/*'),
  ],
};

export function setFullStoryStatusFromPath(
  pathname,
  isFullStoryEnabled,
  userId
) {
  // Compare the URL to a list of URLs for which we have blocked personal information
  let pathInWhitelist = false;
  const isPPT = userIsParticipant();

  const whitelistForUser = [...fullStoryWhitelist['ANY_OR_NO_USER']];
  if (isPPT) {
    whitelistForUser.push(...fullStoryWhitelist['PATIENT']);
  }

  for (const regex of whitelistForUser) {
    if (regex.test(pathname)) {
      pathInWhitelist = true;
      break;
    }
  }

  // Only take action if a change of full story ON/OFF status is required
  ensureFullStoryIdentity(userId);
  if (pathInWhitelist && !isFullStoryEnabled) {
    // @ts-ignore
    document.getElementById('body').classList.remove('fs-block');
    turnOnFullStory(userId);
  } else if (!pathInWhitelist) {
    // @ts-ignore
    document.getElementById('body').classList.add('fs-block');
    // Always turn off full story in the story
    disableFullStoryInStore();
    // Special case... if you log out and log in as a new user that isn't whitelisted, don't run full story.
    if (!isPPT && window['FS']) {
      window['FS'].shutdown();
    }
  }
}
