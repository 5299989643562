import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { showAlertMessage } from '../../store/actions';
import { baseUrl } from '@curebase/core/lib/env';
import ListItem from '../basic/ListItem';
import StandardButton from '../basic/StandardButton';
import { TrialOptionDetailsQuery } from '../../types';
import { StatusColor } from 'src/shared/lib/colors';
import { inviteParticipant } from '../../controllers/accessCodeController';

type Props = {
  trialOption: TrialOptionDetailsQuery['getTrialOption'];
  refetch: () => {};
};

function InviteUser(props: Props) {
  const { t } = useTranslation('translations');
  const { trialOption, refetch } = props;

  const [, forceRender] = useState(0);

  const inviteUser = async (isResend?: boolean) => {
    const email = trialOption.patient?.user.email;

    // @ts-ignore
    await inviteParticipant(trialOption.id, email);
    refetch();
    showAlertMessage(
      t('trialOption.details.invitationSentAlertMessage', { email: email }),
      StatusColor.Green
    );
    forceRender(1);
  };

  let title: string;
  let text: React.ReactNode;
  let status: string | undefined;
  let buttonText: string;
  let onClick: (event?: any) => any;

  const accessCodes: any = trialOption.patient?.user.accessCodesForReplacement;
  if (accessCodes && accessCodes.length > 0) {
    const lastAccessCode: any = accessCodes[accessCodes.length - 1];
    const signupEmail = lastAccessCode.email;
    title = t('trialOption.details.invitationSent');
    const invitationUrl: string = `${baseUrl}/signup/${lastAccessCode.code}`;

    text = (
      <div className='list-item-text'>
        <span
          dangerouslySetInnerHTML={{
            __html: t('trialOption.details.invitationLinkSent', {
              link: invitationUrl,
              email: signupEmail,
              expires: moment(lastAccessCode.expiresOn).fromNow(),
              interpolation: { escapeValue: true },
            }),
          }}
        />
      </div>
    );
    buttonText = t('trialOption.details.reSendInvitationButton');
    onClick = async () => {
      await inviteUser(true);
    };
  } else {
    // @ts-ignore
    const signupEmail = trialOption.patient.user.email;
    title = t('trialOption.details.managedPatientTitle');
    status = signupEmail
      ? t('trialOption.details.prescreeningSubmittedWithEmail', {
          signupEmail: signupEmail,
        })
      : t('trialOption.details.prescreeningSubmittedByProvider');
    text = (
      <span className='list-item-text'>
        {t('trialOption.details.managedPatientText')}
      </span>
    );
    buttonText = t('trialOption.details.inviteParticipantButton');
    onClick = async () => {
      await inviteUser(false);
    };
  }

  return (
    <div className='li-multi-container inset'>
      <ListItem
        // @ts-ignore
        middle={{
          title,
          text,
          subtitle: status,
          buttons: [
            <StandardButton
              variant={'contained'}
              text={buttonText}
              onClick={onClick}
            />,
          ],
        }}
      />
    </div>
  );
}

export default InviteUser;
