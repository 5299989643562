import { GtmContainerBlob } from '../../../lib/analytics-gtm';

export const EDIT_GTM_CONTAINERS = 'EDIT_GTM_CONTAINERS';
export const INIT_TRIAL = 'INIT_TRIAL';
export type TBGtmContainer = GtmContainerBlob;

declare namespace TBGtmEvent {
  interface Payload {}

  export interface Action {
    type: typeof EDIT_GTM_CONTAINERS | typeof INIT_TRIAL;
    payload: Payload;
  }
}

export interface EditGtmConfigAction extends TBGtmEvent.Action {
  type: typeof EDIT_GTM_CONTAINERS;
  payload: {
    gtmContainers: GtmContainerBlob;
  };
}

export interface InitTrialAction extends TBGtmEvent.Action {
  type: typeof INIT_TRIAL;
  payload: {
    gtmContainers: GtmContainerBlob;
  };
}

export type GtmEventAction = EditGtmConfigAction | InitTrialAction;
