import React from 'react';
import _find from 'lodash/find';
import GoogleMapReact from 'google-map-react';
import withSizes from 'react-sizes';
import { withTranslation, TFunction } from 'react-i18next';
import { GOOGLE_PUBLIC_API_KEY } from '../../../lib/booker';
import { VisitOptionWithCoordinates } from '../../../lib/booker';
import VisitSitePin from './VisitSitePin';
import CardRoll from '../../basic/CardRoll';
import { userIsParticipant } from '../../../lib/users';

interface ScreenHeight {
  screenHeight?: number;
}
interface Props extends ScreenHeight {
  visitOptionsWithCoordinates: VisitOptionWithCoordinates[];
  onSelectVisitSite: (arg0: number) => any;
  onBack: () => void | Promise<void>;
  backButtonText: string;
  screenHeight: number;
  t: TFunction;
}

type State = {
  selectedVisitSiteId?: number;
  zoom: number;
  hideMap: boolean;
};

function getAlmost11Zoom() {
  return 11 + Math.random() / 1000;
}

class BookerSelectVisitSite extends React.Component<Props, State> {
  mapRefreshInterval: any;

  state: State = {
    zoom: 11,
    hideMap: false,
  };

  async componentDidMount() {
    const { visitOptionsWithCoordinates } = this.props;
    this.onChangeOrInitVisitSiteSelection(
      visitOptionsWithCoordinates[0]?.visitSiteId
    );
  }

  componentWillUnmount() {
    clearInterval(this.mapRefreshInterval);
  }

  // On mobile chrome specifically, there is problem where when the map center is moved
  // there can be a rendering issue. Making a tiny adjustment after a 250 milli interval
  // forces a refresh.
  onChangeOrInitVisitSiteSelection = (selectedVisitSiteId: number): void => {
    this.setState(
      {
        hideMap: true,
      },
      () => {
        this.setState({ selectedVisitSiteId }, () => {
          setTimeout(() => {
            this.setState({
              zoom: getAlmost11Zoom(),
            });
            setTimeout(() => {
              this.setState({
                hideMap: false,
              });
            }, 250);
          }, 250);
        });
      }
    );
  };

  renderActiveMapView = () => {
    const { visitOptionsWithCoordinates } = this.props;
    const { selectedVisitSiteId, zoom } = this.state;
    if (!(visitOptionsWithCoordinates && selectedVisitSiteId))
      throw new Error(
        'Should not attempt to render map view without coordinates and selected option.'
      );
    const selectedVisitOption = _find(
      visitOptionsWithCoordinates,
      option => option.visitSiteId === selectedVisitSiteId
    );
    if (!selectedVisitOption) return null;

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_PUBLIC_API_KEY }}
        defaultZoom={11}
        options={{
          zoomControl: false,
          fullscreenControl: false,
        }}
        center={{
          lat: selectedVisitOption.lat,
          lng: selectedVisitOption.lng,
        }}
        zoom={zoom}
      >
        {visitOptionsWithCoordinates.map((option, key) => (
          <VisitSitePin
            key={key}
            //@ts-ignore
            lat={option.lat}
            lng={option.lng}
            text={option.siteName}
          />
        ))}
      </GoogleMapReact>
    );
  };

  renderCardRoll = () => {
    const { visitOptionsWithCoordinates } = this.props;
    const { selectedVisitSiteId } = this.state;
    if (!(visitOptionsWithCoordinates && selectedVisitSiteId))
      throw new Error(
        'Should not attempt to render card roll without coordinates and selected option.'
      );
    return (
      <div className='booker-card-roll-container'>
        <CardRoll
          options={visitOptionsWithCoordinates.map(option => ({
            title: option.siteName,
            lines: [
              option.address.addressLine1,
              option.address.addressLine2, // $FlowFixMe
              `${option.address.city ? option.address.city + ', ' : ''}${
                option.address.state || option.address.county
              } ${option.address.zipcode}`,
            ],
            value: `${option.visitSiteId}`,
          }))}
          selectedValue={`${selectedVisitSiteId}`}
          onChange={v => this.onChangeOrInitVisitSiteSelection(parseInt(v))}
        />
      </div>
    );
  };

  render() {
    const {
      onBack,
      backButtonText,
      visitOptionsWithCoordinates,
      screenHeight,
      t,
    } = this.props;
    const { selectedVisitSiteId, hideMap } = this.state;
    const readyForActiveMap =
      visitOptionsWithCoordinates && selectedVisitSiteId !== undefined;
    const city = visitOptionsWithCoordinates[0]?.address.city;
    if (!city) return null;

    return (
      <>
        <div className='booker-text-container'>
          {screenHeight > 650 && (
            <div className='booker-title'>
              {screenHeight > 800
                ? userIsParticipant()
                  ? city
                    ? t('bookerIn.closestLocationTitle', { city })
                    : t('bookerIn.nearbyTitle')
                  : t('bookerIn.confirmVisit')
                : t('bookerIn.chooseLocation')}
            </div>
          )}

          <div className='booker-back-button' onClick={onBack}>
            {backButtonText}
          </div>

          {screenHeight > 800 && (
            <div className='booker-text'>
              {userIsParticipant()
                ? t('bookerIn.participantScreen')
                : t('bookerIn.patientWithPhysicianScreen')}
            </div>
          )}
        </div>

        <div className={`booker-map-container ${hideMap ? 'map-hidden' : ''}`}>
          {readyForActiveMap && this.renderActiveMapView()}
          {readyForActiveMap && this.renderCardRoll()}
        </div>

        <div className='booker-button-bar'>
          <div
            className={`booker-button full-width ${
              readyForActiveMap ? '' : 'disabled'
            }`}
            role='button'
            id='select-location-button'
            onClick={() => {
              if (!selectedVisitSiteId)
                throw new Error('Should not have no selected visit site');
              this.props.onSelectVisitSite(selectedVisitSiteId);
            }}
          >
            {t('bookerIn.selectthislocation')} →
          </div>
        </div>
      </>
    );
  }
}

const mapSizesToProps = ({ height }) => ({
  screenHeight: height,
});

export default withSizes<ScreenHeight, Props>(mapSizesToProps)(
  withTranslation('translations')(BookerSelectVisitSite) as any
);
