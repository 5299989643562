import moment from 'moment';

import { dependencyMet } from '@curebase/modules/dataCapture/services';
import { getOrderedStatesForMachine } from '../machines';
import { CaseReportDataType, DataCaptureReserved } from '../types';
import { defaultHeightValue, Height, toFeet } from './height';
import { InputValueTypes } from './dynamicform/types';

export const preCompletedStates: Array<string> = ['CREATED'];
export const unsupportedStates: Array<string> = [
  'WITHDRAWN',
  'OPTED_OUT',
  'FAILED_SCREENING',
];

export const getAvailableStatesForMachine = (machine: any) => {
  const orderedStates = getOrderedStatesForMachine(machine);
  const filteredStates: Array<string> = orderedStates.filter(state => {
    return (
      !preCompletedStates.includes(state) && !unsupportedStates.includes(state)
    );
  });

  return filteredStates;
};

const dataTypeFillValue = (dataField: any): any => {
  switch (dataField.dataType) {
    case CaseReportDataType.Paragraph:
    case CaseReportDataType.Text:
      return 'abcd';
    case CaseReportDataType.Height:
      return JSON.stringify(
        dataField.min ? toFeet(dataField.min + 1) : defaultHeightValue
      );
    case CaseReportDataType.Address:
      return {
        address1: 'Mocked Address',
        city: 'Frisco',
        zipCode: '75034',
        state: 'Texas',
        address2: 'Apt 007',
      } as InputValueTypes[CaseReportDataType.Address];
    case CaseReportDataType.Slider:
    case CaseReportDataType.Number:
      return dataField.min ? dataField.min + 1 : 1;
    case CaseReportDataType.Multiselect:
    case CaseReportDataType.Dropdown:
      return dataField.options[0].value;
    case CaseReportDataType.Multicheckbox:
      return [dataField.options[0].value];
    case CaseReportDataType.YesNo:
      return 'YES';
    case CaseReportDataType.Date:
      return '1993-09-10';
    case CaseReportDataType.Checkbox:
      return 'checked';
    case CaseReportDataType.File:
      return 'FILE';
    case CaseReportDataType.Datetime:
      return '2021-12-20T00:00:00.000Z';
    case CaseReportDataType.List:
      return JSON.stringify([
        dataField.options.map((i: any) => i.value || 'abcd'),
      ]);
    default:
      return '';
  }
};

export const getAutoFilledCapturedDataForSurvey = (
  caseReports: ReadonlyArray<any>
) => {
  const capturedData: any = {};
  const caseReportsSorted = [...caseReports].sort((a, b) =>
    a.order > b.order ? 1 : -1
  );
  for (const caseReport of caseReportsSorted) {
    const res: any = {};
    const dataFieldsSorted = [...caseReport.dataFields].sort((a: any, b: any) =>
      a.order > b.order ? 1 : -1
    );
    dataFieldsSorted.forEach((dataField: any) => {
      const met = dependencyMet(
        dataField.dependsOn,
        dataField.dependsOnOperator,
        res
      );
      let fillValue;
      if (dataField.evaluatorMockValue) {
        fillValue = dataField.evaluatorMockValue;
      } else if (dataField.evalValue) {
        fillValue = dataField.evalValue;
      } else if (dataField.evalValues) {
        fillValue = dataField.evalValues[0];
      } else {
        fillValue = dataTypeFillValue(dataField);
      }

      if (
        CaseReportDataType.Multicheckbox === dataField.dataType &&
        !Array.isArray(fillValue)
      ) {
        fillValue = [fillValue];
      } else if (CaseReportDataType.Date === dataField.dataType) {
        fillValue = moment(fillValue).format('YYYY-MM-DD');
      } else if (CaseReportDataType.Datetime === dataField.dataType) {
        fillValue = moment(fillValue).valueOf();
        // Data types that have a JSON mock value format
      } else if (CaseReportDataType.Autocomplete === dataField.dataType) {
        if (fillValue?.length > 0) fillValue = JSON.parse(fillValue);
      } else if (CaseReportDataType.Height === dataField.dataType) {
        if (fillValue?.length > 0) fillValue = JSON.parse(fillValue) as Height;
      } else if (CaseReportDataType.List === dataField.dataType) {
        if (fillValue?.length > 0) fillValue = JSON.parse(fillValue);
      }

      if (!met) {
        res[dataField.id] = DataCaptureReserved.NotApplicableDueToDependency;
      } else {
        res[dataField.id] = fillValue;
      }
    });

    capturedData[caseReport.id] = res;
  }

  return capturedData;
};
