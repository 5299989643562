import { formatAddress } from '@curebase/core/lib/clinics';
import { SchedulingConfiguration } from '@curebase/core/schema/validation/SchedulingConfiguration';
import { JoinStatus } from '@curebase/core/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';
import { useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLocale, getLocaleFormatDates } from 'src/context/localeContext';
import { getViewPatientBaseUrl } from 'src/lib/users';
import {
  Maybe,
  useManageVisitQuery,
  VisitBooking,
  VisitMethod,
} from 'src/types';
import ActionCard from '../basic/ActionCard';
import Loading from '../Loading';
import { whenStringFromBeginningAndMaybeEnd } from './helpers';
import { StudyActivity } from '@curebase/core/types';
import { GtmCustomEvents } from 'src/lib/analytics-gtm';

interface ManageVisitActionCardProps {
  key?: string | number;
  trialOptionId: number;
  activity: StudyActivity;
  visitBookingId: number;
}

function getRescheduleVisitButton({
  t,
  history,
  trialOptionId,
  id,
  isDisabled,
  addRescheduleSuffix,
}: {
  t: TFunction<'translations'>;
  history: { push: (param: string) => void };
  trialOptionId: number;
  isDisabled: boolean;
  id?: string | null;
  addRescheduleSuffix?: boolean;
}) {
  return {
    text: t('common.reschedule'),
    disabled: isDisabled,
    onClick: () => {
      history.push(
        `${getViewPatientBaseUrl(trialOptionId)}/${id}/booker${
          addRescheduleSuffix ? '/reschedule' : '/schedule'
        }`
      );
    },
  };
}

function getMardownMessage(
  t: TFunction<'translations'>,
  timeLuxon: DateTime,
  summary?: string,
  isMissed?: boolean,
  rescheduleIsDisabled?: boolean
) {
  let time: LocaleOptions & DateTimeFormatOptions = {
    ...DateTime.TIME_SIMPLE,
    timeZoneName: 'short',
  };
  return `
  ${summary ? summary : ''} ${
    isMissed
      ? rescheduleIsDisabled
        ? t('manageVisitActionCard.missedAppointmentRescheduleDisabled')
        : t('manageVisitActionCard.missedAppointment')
      : ''
  } \n\n
  <div class="card-date"><img class="card-calendar-icon" src='/icons/calendar.svg'/></img><label class="card-label">${timeLuxon.toLocaleString(
    DateTime.DATE_FULL
  )}</label></div>\n\n
  <div class="card-time"><img class="card-calendar-icon" src='/icons/clock.svg'/></img>
  <label class="card-label">${timeLuxon.toLocaleString(
    time
  )}</label></div> \n\n`;
}

const TelehealthActionCard = (props: {
  visitBooking: VisitBooking;
  currentDate: number;
  trialOptionId: number;
  title: string;
  key?: string | number;
  summary?: string;
}) => {
  const dispatch = useDispatch();
  const { allowTelehealthRescheduling } = useFlags();
  const history = useHistory();
  const { locale } = getLocale();
  const iconSrc = '/icons/actionCardCalendar.svg';
  const { visitBooking, trialOptionId, title, summary, key } = props;
  const { openVideo } = useSelector((store: any) => store?.telehealth);
  const {
    default: { disableParticipantScheduling },
    id,
  } = visitBooking.schedulingConfiguration!;

  const { t } = useTranslation('translations');
  const { joinStatus } = visitBooking.getJoinStatus;
  const participantHasJoined = visitBooking?.participantHasJoined;
  const timeLuxon = DateTime.fromISO(visitBooking.start, { locale }).setZone(
    visitBooking.getJoinStatus.participantTimeZone
  );
  const markdown = (customSummary?: string) =>
    getMardownMessage(
      t,
      timeLuxon,
      customSummary,
      joinStatus === JoinStatus.Late,
      disableParticipantScheduling || !allowTelehealthRescheduling
    );
  if (joinStatus === JoinStatus.TooEarly) {
    const buttons = [
      {
        text: t('manageVisitActionCard.telehealthCard.viewDetails'),
        onClick: () => {
          history.push(`/u/telemed/${visitBooking.meetingUUID}`);
        },
      },
    ];
    const inTimeProps = {
      markdown: markdown(summary),
      showVerticalButtons: false,
      buttons: buttons,
      extraButtons:
        allowTelehealthRescheduling && !disableParticipantScheduling
          ? [
              getRescheduleVisitButton({
                t,
                history,
                trialOptionId,
                id,
                isDisabled: false,
              }),
              getCancelButton(
                t,
                history,
                trialOptionId,
                id,
                visitBooking.schedulingConfiguration as Maybe<SchedulingConfiguration>
              ),
            ]
          : undefined,
    };

    return (
      <ActionCard
        key={`${key}-${joinStatus}`}
        iconSrc={iconSrc}
        title={`${title}`}
        {...inTimeProps}
      />
    );
  }

  if (joinStatus === JoinStatus.InTime || joinStatus === JoinStatus.JoinEarly) {
    const onClick = () =>
      history.push(`/u/telemed/${visitBooking.meetingUUID}?autoJoin=true`);
    const inTimeProps = {
      markdown: markdown(summary),
      extraButtons: allowTelehealthRescheduling
        ? [
            getCancelButton(
              t,
              history,
              trialOptionId,
              id,
              visitBooking.schedulingConfiguration as Maybe<SchedulingConfiguration>
            ),
          ]
        : null,
      buttons: [
        {
          text: t('manageVisitActionCard.telehealthCard.viewDetails'),
          onClick: () => {
            history.push(`/u/telemed/${visitBooking.meetingUUID}`);
          },
          isSecondary: true,
          className: 'viewDetails-btn-secondary',
        },
        !openVideo
          ? {
              text: t('manageVisitActionCard.telehealthCard.join'),
              onClick,
            }
          : {
              text: t('manageVisitActionCard.telehealthCard.leavecall'),
              onClick: () => {
                dispatch({ type: 'LEAVE_CALL' });
              },
            },
      ],
    };

    return (
      <ActionCard
        key={`${key}-${joinStatus}`}
        iconSrc={iconSrc}
        title={`${title}`}
        showVerticalButtons={true}
        {...inTimeProps}
      />
    );
  }

  if (joinStatus === JoinStatus.Late && !participantHasJoined) {
    const lateProps = {
      markdown: markdown(),
      buttons:
        allowTelehealthRescheduling && !disableParticipantScheduling
          ? [
              getRescheduleVisitButton({
                t,
                history,
                trialOptionId,
                id,
                isDisabled: false,
              }),
            ]
          : [],
    };
    return (
      <ActionCard
        key={`${key}-${joinStatus}`}
        iconSrc={iconSrc}
        title={`${title}`}
        showVerticalButtons={true}
        isOverdue={true}
        {...lateProps}
      />
    );
  }

  return null;
};

function getCancelButton(
  t: TFunction<'translations'>,
  history: { push: (param: string) => void },
  trialOptionId: number,
  configId: string | null | undefined,
  schedulingConfiguration?:
    | SchedulingConfiguration
    | Maybe<SchedulingConfiguration>
) {
  return {
    text: t('common.cancel'),
    onClick: () => {
      if (
        schedulingConfiguration?.default?.bookerSuccessText
          ?.participantDoNotEffectiveCancel
      ) {
        history.push(
          `${getViewPatientBaseUrl(
            trialOptionId
          )}/${configId}/booker/manual-cancel`
        );
      } else {
        history.push(
          `${getViewPatientBaseUrl(trialOptionId)}/${configId}/booker/cancel`
        );
      }
    },
  };
}

function ManageVisitActionCard(props: ManageVisitActionCardProps) {
  const { locale } = getLocale();
  const { trialOptionId, visitBookingId, activity, key } = props;
  const localesFormat = getLocaleFormatDates();
  const currentDate: number = useSelector((state: any) => state.currentDate);
  const history = useHistory();

  const { data, refetch } = useManageVisitQuery({
    fetchPolicy: 'network-only',
    variables: {
      trialOptionId,
      visitBookingId,
    },
  });

  const { t } = useTranslation('translations');

  useEffect(() => {
    refetch();
  }, [currentDate]);

  useEffect(() => {
    function refetchData() {
      refetch();
    }
    window.addEventListener(GtmCustomEvents.SCHEDULED_VISIT, refetchData);
    return () => {
      window.removeEventListener(GtmCustomEvents.SCHEDULED_VISIT, refetchData);
    };
  }, []);

  if (!data) {
    return <Loading />;
  }

  const visitBooking = data.getTrialOption.visitBooking;
  const visitSite =
    data.getTrialOption.visitBooking?.visitAvailability?.visitSite ??
    data.getTrialOption.visitSite;

  /**
   * TODO: This could be fetched from the Api based on the users config, no?!
   */
  const supportContact = {
    email: 'support@mycurebase.com',
    phone: '(917) 924-5957',
  };

  if (!visitBooking) {
    return null;
  }

  const { name, summary } =
    visitBooking.schedulingConfiguration?.locales?.[locale] ||
    visitBooking.schedulingConfiguration ||
    {};
  const iconSrc = '/icons/actionCardCalendar.svg';
  const title = t('manageVisitActionCard.title', {
    name: name ?? t('manageVisitActionCard.titleDefault'),
  });
  const timeLuxon = DateTime.fromISO(visitBooking.start, { locale }).setZone(
    visitBooking.getJoinStatus.participantTimeZone
  );
  const markdown = (
    customSummary?: string,
    isMissed?: boolean,
    rescheduleIsDisabled?: boolean
  ) =>
    getMardownMessage(
      t,
      timeLuxon,
      customSummary || summary,
      isMissed,
      rescheduleIsDisabled
    );

  if (visitBooking.method === VisitMethod.AtHome) {
    return (
      <ActionCard
        iconSrc={iconSrc}
        title={name ?? t('manageVisitActionCard.atHome.title')}
        markdown={markdown(
          t('manageVisitActionCard.atHome.desc', {
            supportEmail: supportContact.email,
            supportPhone: supportContact.phone,
          }),
          false,
          false
        )}
      />
    );
  } else if (
    visitBooking.method === VisitMethod.Virtual &&
    visitBooking.meetingUUID
  ) {
    return (
      <TelehealthActionCard
        key={key}
        //@ts-ignore
        visitBooking={visitBooking}
        currentDate={currentDate}
        trialOptionId={trialOptionId}
        title={title}
        summary={summary}
      />
    );
  }

  if (visitBooking.acuityAppointmentId) {
    return (
      <ActionCard
        key={key}
        iconSrc={iconSrc}
        title={title}
        description={t('manageVisitActionCard.acuityScheduleDesc')}
      />
    );
  }

  if (!visitSite) {
    return null;
  }

  const addressString = formatAddress(visitSite);
  const isOverdue =
    DateTime.fromISO(visitBooking.end) < DateTime.fromMillis(currentDate);
  const { method } = visitBooking;
  let methodString = '';
  if (method === VisitMethod.Virtual)
    methodString = t('manageVisitActionCard.telemedicine.method');
  const description = t('manageVisitActionCard.bookedAt', {
    siteName: `${visitSite.name}${methodString}`,
  });
  const disableParticipantScheduling =
    visitBooking?.schedulingConfiguration?.default
      ?.disableParticipantScheduling ?? false;
  const whenString = visitBooking
    ? whenStringFromBeginningAndMaybeEnd(
        currentDate,
        t('common.attend'),
        visitBooking.start,
        visitBooking.end,
        localesFormat.moment.shortTime,
        t
      )
    : null;
  const buttons = !isOverdue
    ? [
        {
          text: t('common.viewOnMap'),
          onClick: () =>
            window.open(
              `https://www.google.com/maps/search/${encodeURIComponent(
                addressString
              )}`
            ),
        },
      ]
    : !disableParticipantScheduling
    ? [
        getRescheduleVisitButton({
          t,
          history,
          trialOptionId,
          id: activity.config.configId,
          isDisabled: false,
        }),
      ]
    : [];
  const extraButtons: any[] | null =
    !isOverdue && !visitBooking?.axleHealthId ? [] : null;

  if (!disableParticipantScheduling) {
    extraButtons?.push(
      getRescheduleVisitButton({
        t,
        history,
        trialOptionId,
        id: activity.config.configId,
        addRescheduleSuffix: true,
        isDisabled: false,
      })
    );
  }

  extraButtons?.push(
    getCancelButton(
      t,
      history,
      trialOptionId,
      activity.config.configId,
      visitBooking.schedulingConfiguration as SchedulingConfiguration
    )
  );

  return (
    <ActionCard
      buttons={buttons}
      markdown={markdown(description, isOverdue, disableParticipantScheduling)}
      extraButtons={extraButtons}
      iconSrc={iconSrc}
      isOverdue={isOverdue}
      title={title}
      whenString={!isOverdue ? whenString : ''}
    />
  );
}

export default ManageVisitActionCard;
