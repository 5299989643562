import { RolePermissions } from '../../types';

const basicClinicalResearchAssociate = [
  RolePermissions.ViewPatientPayments,
  RolePermissions.ViewPii,
  RolePermissions.ViewSettings,
  RolePermissions.ViewParticipants,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.ViewCrf, // Data Edits and Querying
  RolePermissions.CreateCapturedDataQuery,
  RolePermissions.ResolveCapturedDataQuery,
  RolePermissions.CreateCapturedDataQueryMessage,
  RolePermissions.CreateCapturedDataQueryReminder,
  RolePermissions.ReadCapturedDataEditQueryMessage, // CRF Signoff
  RolePermissions.ViewCaseReportSignoff,
  RolePermissions.ViewParticipantNote,
  RolePermissions.ViewInformedConsent,
  RolePermissions.SourceDataVerification,
];

export const clinicalResearchAssociateBlinded = [
  ...basicClinicalResearchAssociate,
  RolePermissions.ViewGroupAssignmentBlindedLabel,
];

export const clinicalResearchAssociate = [
  ...basicClinicalResearchAssociate,
  RolePermissions.ViewGroupAssignment,
];

const basicClinicalResearchAssociateDeidentified = [
  RolePermissions.ViewPatientPayments,
  RolePermissions.CreateCapturedDataQuery,
  RolePermissions.CreateCapturedDataQueryMessage,
  RolePermissions.CreateCapturedDataQueryReminder,
  RolePermissions.ViewParticipantNote,
  RolePermissions.ReadCapturedDataEditQueryMessage, // CRF Signoff
  RolePermissions.ResolveCapturedDataQuery,
  RolePermissions.ViewCrf, // Data Edits and Querying
  RolePermissions.ViewParticipants,
  RolePermissions.ViewSettings,
  RolePermissions.ViewProtocolDeviations,
  RolePermissions.SourceDataVerification,
];

export const clinicalResearchAssociateDeidentifiedBlinded = [
  RolePermissions.ViewGroupAssignmentBlindedLabel,
  ...basicClinicalResearchAssociateDeidentified,
];

export const clinicalResearchAssociateDeidentified = [
  RolePermissions.ViewGroupAssignment,
  ...clinicalResearchAssociateDeidentifiedBlinded,
];
