import React, { useContext } from 'react';
import CustomStyleContext from '../../../context/customStyleContext';
import { createActionCardButtonStyle } from '../../../lib/customStyle';

export interface Props {
  onClick?: () => void;
  showVerticalStyle?: boolean;
  label: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  className?: string;
}

export default function ActionCardButton({
  label,
  disabled,
  onClick,
  showVerticalStyle,
  variant = 'primary',
  className = '',
}: Props) {
  const context = useContext(CustomStyleContext);
  const cls = createActionCardButtonStyle(context, variant === 'secondary');
  return (
    <div
      className={`${className} ac-button ${cls.button} ${
        disabled ? 'ac-button-disabled' : ''
      } ${variant === 'secondary' ? 'ac-button-secondary ' : ''}`}
      onClick={onClick}
      style={{
        marginBottom: showVerticalStyle ? '5px' : 0,
      }}
    >
      {label}
    </div>
  );
}
