import produce from 'immer';
import {
  TBScheduling,
  ScheduledEventAction,
  NEW_SCHEDULED_EVENT,
  EDIT_SCHEDULED_EVENT,
  REMOVE_SCHEDULED_EVENT,
} from './types';

export default produce((state: TBScheduling, action: ScheduledEventAction) => {
  switch (action.type) {
    case NEW_SCHEDULED_EVENT:
      const { id } = action.payload.config;
      state[id] = action.payload.config;
      break;
    case EDIT_SCHEDULED_EVENT:
      state[action.payload.id] = action.payload.config;
      break;
    case REMOVE_SCHEDULED_EVENT:
      delete state[action.payload.id];
      break;
    //@ts-ignore
    case 'INIT_TRIAL':
      //@ts-ignore
      return action.payload.scheduling;
  }
}, {});
