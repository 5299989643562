import { LDProvider } from 'launchdarkly-react-client-sdk';
import { flatten } from 'lodash';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LAUNCH_DARKLY_CLIENT_SIDE_ID } from 'src/lib/env';
import {
  isDownloadRoute,
  isSurveyRoute,
  isExploreRoute,
  getTrialSlugFromExploreRoute,
} from 'src/lib/ui';
import { getUser } from 'src/store/user/selectors';
import { UserState } from '../store/user/types';

const ANONYMOUS_USER = 'ANONYMOUS_USER';

const FeaturesFlagsProvider = (props: PropsWithChildren<{}>) => {
  const userState: UserState = useSelector(getUser);
  const location = useLocation();

  function getCurrentTrialSlug(userState: UserState) {
    if (!userState) return;
    const roles = userState.roles || [];
    const trialOptions =
      // @ts-ignore
      flatten(roles?.map(role => role?.patient?.trialOptions || []) || []);
    const currentTrialOption = trialOptions?.find(
      trialOption => trialOption?.id === userState.trialOptionId
    );
    return currentTrialOption?.trial?.slug;
  }

  function getUserProfile() {
    // This should NOT be removed, after removing this the feature flags
    // in the frontend stopped working for participants
    if (!userState || !userState.email) return;

    const userProfile: any = {
      key: userState?.userId?.toString() || '',
    };

    try {
      // @ts-ignore
      const trialSlug =
        getCurrentTrialSlug(userState) ||
        (isExploreRoute(location.pathname)
          ? getTrialSlugFromExploreRoute(location.pathname)
          : 'none');
      userProfile.custom = { trialSlug };
    } finally {
      return userProfile;
    }
  }

  // This line was added so we can detect if we in the survey or any other page.
  const isAllowedPublicRoute =
    isSurveyRoute(location.pathname) || isDownloadRoute(location.pathname);
  let user: any = getUserProfile();

  if (!user && isAllowedPublicRoute) {
    user = {
      anonymous: true,
      key: ANONYMOUS_USER,
      custom: {
        trialSlug: getTrialSlugFromExploreRoute(location.pathname),
      },
    };
  }

  return (
    <LDProvider
      clientSideID={LAUNCH_DARKLY_CLIENT_SIDE_ID}
      deferInitialization={true}
      context={user}
    >
      {props.children}
    </LDProvider>
  );
};

export default FeaturesFlagsProvider;
