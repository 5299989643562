import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import _debounce from 'lodash/debounce';
import { executeQuery } from 'src/ApolloClient';
import { MedDetail, MedicationBlob } from 'src/types';
import { StrengthDetails } from '@curebase/core/types';
import { medKeys } from '@curebase/core/lib/dynamicform/types';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import clsx from 'clsx';

const DEBOUNCE_MS = 500;

type Props = {
  data: Record<string, any>;
  subEle: any;
  onChange: (subEle: any, value: any) => void;
};

type MedObj = Partial<
  Record<medKeys, string | boolean | (string | undefined)[]>
>;

const selectStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      backgroundColor: 'white',
      '& .MuiInputLabel-root': {
        zIndex: 999,
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '& .MuiInputLabel-root.MuiFormLabel-filled': {
        marginTop: '0px !important',
      },
    },
    select: {
      width: '100%',
      backgroundColor: 'white',
    },
    label: {
      zIndex: 999,
      marginTop: -5,
      marginLeft: 13,
    },
    autocompleteField: {
      '& .MuiInputLabel-root.Mui-focused': {
        marginTop: '0px !important',
      },
    },
  };
});

const MedDateRange = (props: {
  enabled: boolean;
  startValue?: string;
  endValue?: string;
  onChange: (dateData: string[]) => void;
}) => {
  const selectClasses = selectStyles();
  const { t } = useTranslation('translations');
  const { startValue, endValue } = props;
  const dateOnChange = (value, identity: 'start' | 'end') => {
    if (identity === 'start') {
      props.onChange([value.toISO(), endValue]);
    } else {
      props.onChange([startValue, value.toISO()]);
    }
  };

  const dateTimeFormat = 'DD';
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <>
      <div className='start-picker'>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DatePicker
            disableFuture
            label={t('dynamicForm.medication.startDate')}
            inputVariant='outlined'
            className={clsx(selectClasses.root)}
            disabled={!props.enabled}
            value={startValue ?? null}
            onChange={value => dateOnChange(value, 'start')}
            format={dateTimeFormat}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className='to'>to</div>

      <div className='end-picker'>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DatePicker
            disableFuture
            inputVariant='outlined'
            label={t('dynamicForm.medication.endDate')}
            minDate={startValue ?? new Date()}
            maxDate={yesterday}
            className={clsx(selectClasses.root)}
            disabled={!props.enabled || endValue === 'present'}
            value={!endValue || endValue === 'present' ? null : endValue}
            onChange={value => dateOnChange(value, 'end')}
            format={dateTimeFormat}
          />
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
};

const AutoCompleteElement = (props: {
  medKey: number;
  element: MedObj;
  onDelete: (key: number) => void;
  onChange: (key: number, medData: MedObj) => void;
  showIndication: boolean;
  showFrequency: boolean;
}) => {
  const { t } = useTranslation('translations');

  let {
    other,
    medication,
    route,
    dosageStr,
    dates,
    indication,
    frequency,
  } = props.element;
  const { showIndication, showFrequency } = props;

  const frequencyLabels = [
    t('dynamicForm.medication.frequency.onceDay'),
    t('dynamicForm.medication.frequency.twiceDay'),
    t('dynamicForm.medication.frequency.thriceDay'),
    t('dynamicForm.medication.frequency.twelve'),
    t('dynamicForm.medication.frequency.bed'),
    t('dynamicForm.medication.frequency.morning'),
    t('dynamicForm.medication.frequency.other'),
  ];

  const [medicationOptions, setMedicationOptions] = useState<
    Record<string, MedDetail[]>
  >({});

  const [loading, setLoading] = useState(false);
  const selectClasses = selectStyles();

  const drugBankPopulateOptions = async str => {
    const res = await executeQuery(`
  getDrugbankMedicationData(medicationName: "${str}") {
    name
    medDetails {
      route
      strengths {
        number
        unit
        prescribableName
      }
    }
  }`);

    const valuesToSave: Record<string, MedDetail[]> = {};
    (res.getDrugbankMedicationData as MedicationBlob[]).forEach(elem => {
      //@ts-ignore
      valuesToSave[elem.name] = elem.medDetails;
    });

    if (Object.keys(valuesToSave).length <= 0)
      valuesToSave['Other'] = [{ route: undefined, strengths: [] }];

    setMedicationOptions(valuesToSave);
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const makeDrugBankRequestDebounce = useCallback(
    _debounce(drugBankPopulateOptions, DEBOUNCE_MS),
    []
  );

  useEffect(() => {
    if (medication) {
      try {
        makeDrugBankRequestDebounce(medication);
      } catch (e) {
        console.log('[DrugbankAutocomplete] error: ', e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currMedicationStrings = Object.keys(medicationOptions);
  const currRouteMap: Record<string, StrengthDetails[] | undefined> = {};

  if (medication && medicationOptions[medication as string]) {
    medicationOptions[medication as string].forEach(elem => {
      if (elem.route) {
        //@ts-ignore
        currRouteMap[elem.route] = elem.strengths;
      }
    });
  }

  return (
    <div className='medication-elem'>
      <div className='medication-row spacer'>
        <div className='autocomplete-container'>
          {!other && (
            <MaterialAutocomplete
              value={medication}
              fullWidth
              aria-label={t('dynamicForm.medication.ariaMedication')}
              freeSolo={false}
              onChange={(event, value) => {
                if (value === 'Other') {
                  props.onChange(props.medKey, { other: true });
                } else {
                  props.onChange(props.medKey, { medication: value! });
                }
              }}
              onInputChange={(event, value) => {
                if (
                  value &&
                  value !== '' &&
                  value !== medication &&
                  !currMedicationStrings.includes(value)
                ) {
                  setLoading(true);
                  props.onChange(props.medKey, {});
                  makeDrugBankRequestDebounce.cancel();
                  makeDrugBankRequestDebounce(value);
                }
              }}
              loading={loading}
              options={loading ? [] : currMedicationStrings}
              filterOptions={options => options}
              renderInput={params => (
                <TextField
                  {...params}
                  className={selectClasses.autocompleteField}
                  label={t('dynamicForm.medication.medicationName')}
                  variant='outlined'
                />
              )}
            />
          )}
          {other && (
            <p className='other-medication'>
              {t('dynamicForm.medication.otherMedication')}
            </p>
          )}
        </div>
        <div className='del-container'>
          <DeleteIcon
            className='del-svg'
            onClick={() => {
              props.onDelete(props.medKey);
            }}
          />
        </div>
      </div>
      {other && (
        <div className='medication-row spacer'>
          <TextField
            className={clsx(selectClasses.select)}
            disabled={!medication && !other}
            id='outlined-basic'
            variant='outlined'
            label={t('dynamicForm.medication.medicationName')}
            placeholder={t('dynamicForm.medication.medicationName')}
            fullWidth
            value={!!medication ? medication : ''}
            onChange={event =>
              props.onChange(props.medKey, {
                other,
                medication: event.target.value,
                route,
                dosageStr,
                dates,
              })
            }
          />
        </div>
      )}
      <div className='medication-row spacer'>
        {Object.keys(currRouteMap).length > 0 && (
          <FormControl fullWidth>
            <InputLabel className={selectClasses.label} id='select-label'>
              {t('dynamicForm.medication.route')}
            </InputLabel>
            <Select
              fullWidth
              label={t('dynamicForm.medication.route')}
              variant='outlined'
              className={clsx(selectClasses.select)}
              value={!!route ? route : ''}
              onChange={event => {
                props.onChange(props.medKey, {
                  medication,
                  route: event.target.value as string,
                  dosageStr,
                  dates,
                });
              }}
              disabled={!(!!medication && Object.keys(currRouteMap).length > 0)}
            >
              {medication && Object.keys(currRouteMap).length > 0
                ? Object.keys(currRouteMap).map((elem, i) => (
                    <MenuItem key={i} value={elem}>
                      {elem}
                    </MenuItem>
                  ))
                : []}
            </Select>
          </FormControl>
        )}

        {Object.keys(currRouteMap).length <= 0 && (
          <TextField
            className={clsx(selectClasses.select)}
            disabled={!medication && !other}
            id='outlined-basic'
            variant='outlined'
            label={t('dynamicForm.medication.route')}
            placeholder={t('dynamicForm.medication.route')}
            fullWidth
            value={!!route ? route : ''}
            onChange={event =>
              props.onChange(props.medKey, {
                other,
                medication,
                route: event.target.value as string,
                dosageStr,
                dates,
              })
            }
          />
        )}
      </div>
      <div className='medication-row spacer'>
        <div style={{ flex: 1 }}>
          {Object.keys(currRouteMap).length <= 0 && (
            <TextField
              className={clsx(selectClasses.select)}
              id='outlined-basic'
              variant='outlined'
              label={t('dynamicForm.medication.dosage')}
              placeholder={t('dynamicForm.medication.dosage')}
              fullWidth
              value={!!dosageStr ? dosageStr : ''}
              disabled={!route && !other}
              onChange={event =>
                props.onChange(props.medKey, {
                  other,
                  medication,
                  route,
                  dosageStr: event.target.value as string,
                  dates,
                })
              }
            />
          )}

          {currRouteMap[route as string] && (
            <FormControl fullWidth>
              <InputLabel className={selectClasses.label} id='select-label'>
                {t('dynamicForm.medication.dosage')}
              </InputLabel>
              <Select
                value={!!dosageStr ? dosageStr : ''}
                fullWidth
                variant='outlined'
                label={t('dynamicForm.medication.dosage')}
                placeholder={t('dynamicForm.medication.dosage')}
                className={clsx(selectClasses.select)}
                onChange={event => {
                  props.onChange(props.medKey, {
                    medication,
                    route,
                    dosageStr: event.target.value as string,
                    dates,
                  });
                }}
                disabled={
                  !(!!route && (currRouteMap[route as string] ?? []).length > 0)
                }
              >
                {route && (currRouteMap[route as string] ?? []).length > 0
                  ? currRouteMap[route as string]!.map((elem, i) => {
                      const valueString = `${elem.number} ${elem.unit}`;
                      return (
                        <MenuItem key={i} value={valueString}>
                          {valueString}
                        </MenuItem>
                      );
                    })
                  : []}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
      <div className='medication-row'>
        <MedDateRange
          enabled={!!medication}
          onChange={value => {
            props.onChange(props.medKey, {
              other,
              medication,
              route,
              dosageStr,
              dates: value,
            });
          }}
          startValue={dates?.[0]}
          endValue={dates?.[1]}
        />
      </div>
      <div className='medication-row'>
        <FormControlLabel
          control={
            <Checkbox
              onChange={event => {
                props.onChange(props.medKey, {
                  other,
                  medication,
                  route,
                  dosageStr,
                  dates: [
                    dates?.[0],
                    event.target.checked ? 'present' : undefined,
                  ],
                });
              }}
              checked={dates?.[1] === 'present'}
            />
          }
          label={t('dynamicForm.medication.currentlyTaking')}
        />
      </div>
      {showIndication && (
        <div className='medication-row spacer'>
          <TextField
            className={clsx(selectClasses.select)}
            id='outlined-basic'
            variant='outlined'
            label={t('dynamicForm.medication.indication')}
            placeholder={t('dynamicForm.medication.indication')}
            fullWidth
            value={!!indication ? indication : ''}
            disabled={!route && !other}
            onChange={event =>
              props.onChange(props.medKey, {
                other,
                medication,
                route,
                dosageStr,
                dates,
                indication: event.target.value as string,
              })
            }
          />
        </div>
      )}
      {showFrequency && (
        <div className='medication-row'>
          <FormControl fullWidth>
            <InputLabel className={selectClasses.label} id='select-label'>
              {t('dynamicForm.medication.frequency.label')}
            </InputLabel>
            <Select
              className={clsx(selectClasses.select)}
              id='outlined-basic'
              variant='outlined'
              label={t('dynamicForm.medication.frequency.label')}
              fullWidth
              value={!!frequency ? frequency : ''}
              disabled={!route && !other}
              onChange={event =>
                props.onChange(props.medKey, {
                  other,
                  medication,
                  route,
                  dosageStr,
                  dates,
                  indication,
                  frequency: event.target.value as string,
                })
              }
            >
              {frequencyLabels &&
                frequencyLabels.map((elem, i) => (
                  <MenuItem key={`${i}${elem}`} value={elem}>
                    {elem}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export const DrugbankAutocomplete = (props: Props) => {
  const { t } = useTranslation('translations');
  const { subEle, data, onChange } = props;
  const {
    // Show a field so a user can specify the indication for the drug
    showIndication = false,
    // Toggle whether a dropdown is shown that indicates frequency
    // of use.
    showFrequency = false,
  } = subEle?.additionalSettings?.medications || {};
  let currValue = (data[subEle.key] ?? { medications: [] }) as {
    medications: MedObj[];
    isOptingOut?: boolean;
  };

  const onChangeWrapper = (medications: MedObj[], isOptingOut?: boolean) => {
    onChange(subEle, {
      medications,
      isOptingOut: !!isOptingOut,
      // Pass these in so that the validation system knows
      // whether these fields are enabled or not:
      showIndication,
      showFrequency,
    });
  };

  return (
    <div className='drugbank-list'>
      {currValue?.medications?.map((currElement, i) => {
        return (
          <>
            {i > 0 && <div className='divider' />}
            <AutoCompleteElement
              element={currElement}
              key={`medication-${i}`}
              medKey={i}
              onDelete={key => {
                const newMedications = [...currValue.medications];
                newMedications.splice(key, 1);

                onChangeWrapper(newMedications);
              }}
              onChange={(key: number, medData: MedObj) => {
                const newMedications = [...currValue.medications];
                newMedications.splice(key, 1, medData);

                onChangeWrapper(newMedications);
              }}
              showFrequency={showFrequency}
              showIndication={showIndication}
            />
          </>
        );
      })}
      <div className='add-medication'>
        <Button
          className='add-medication-button'
          onClick={() => {
            const newValue = currValue?.medications?.concat([{}]) || [{}];
            onChangeWrapper(newValue, false);
          }}
        >
          <AddIcon /> {t('dynamicForm.medication.addMedication')}
        </Button>
        {currValue?.medications?.length <= 0 && (
          <div className='add-medication-button no-medications-to-report'>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={event => onChangeWrapper([], event.target.checked)}
                  checked={!!currValue.isOptingOut}
                />
              }
              label={t('dynamicForm.medication.noMedicationsReported')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
