import moment from 'moment';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import { PHONE_NUMBER_REGEX, ZIP_CODES_REGEX } from './constants';

const T_MOCK = (a: any, b: any) => b || a;

export function isUuidV4(val: string) {
  return uuidValidate(val) && uuidVersion(val) === 4;
}

export function getEmailValidationError(
  email?: string | null,
  t: any = T_MOCK
) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email || !re.test(email.toLowerCase())) {
    return t(
      'dynamicForm.validation.enterValidEmail',
      'Enter a valid email address.'
    );
  }

  return null;
}

export function getPhoneValidationError(phone?: string, t: any = T_MOCK) {
  if (!phone || !PHONE_NUMBER_REGEX.test(phone.toLowerCase())) {
    return t(
      'dynamicForm.validation.enterValidPhone',
      'Enter a valid phone number.'
    );
  }

  return null;
}

export function getDigitZipcodeValidationError(
  zipcode?: string,
  t: any = T_MOCK
) {
  if (zipcode && ZIP_CODES_REGEX.test(zipcode)) {
    return null;
  }

  return t(
    'dynamicForm.validation.enterValidZipcode',
    'Enter a valid zipcode.'
  );
}

// Source: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url/49849482
export function isValidURL(url?: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return pattern.test(url || '');
}

export function isValidAge(timestamp: string) {
  const bday = moment(timestamp);

  if (!bday.isValid()) {
    return false;
  }
  const years = moment().diff(bday, 'years', true);
  if (years < 0 || years > 100) {
    return false;
  }

  return true;
}
