// Pass a hash of settings to the `config` method. allowPassphrases is set to false
// to prevent bypassing the strength requirement once the password has satisfied the
// the minPhraseLength check.
export const owaspConfig = {
  allowPassphrases: false,
  maxLength: 128,
  minLength: 10,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4,
};
