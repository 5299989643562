export enum States {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  DC = 'DC',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export const getStateFromZipcode = (
  zipString: string
): { abbreviation: string | null; name: string | null } => {
  if (zipString.length !== 5 || !/^[0-9]+$/.test(zipString)) {
    // Zipcode is not a US zipcode, so don't try to guess it
    return {
      abbreviation: null,
      name: null,
    };
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zipString, 10);

  let stateAbbreviation;
  let stateName;

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    stateAbbreviation = States.AL;
    stateName = 'Alabama';
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    stateAbbreviation = States.AK;
    stateName = 'Alaska';
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    stateAbbreviation = States.AZ;
    stateName = 'Arizona';
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    stateAbbreviation = States.AR;
    stateName = 'Arkansas';
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    stateAbbreviation = States.CA;
    stateName = 'California';
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    stateAbbreviation = States.CO;
    stateName = 'Colorado';
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    stateAbbreviation = States.CT;
    stateName = 'Connecticut';
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    stateAbbreviation = States.DE;
    stateName = 'Delaware';
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    stateAbbreviation = States.FL;
    stateName = 'Florida';
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    stateAbbreviation = States.GA;
    stateName = 'Georgia';
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    stateAbbreviation = States.HI;
    stateName = 'Hawaii';
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    stateAbbreviation = States.ID;
    stateName = 'Idaho';
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    stateAbbreviation = States.IL;
    stateName = 'Illinois';
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    stateAbbreviation = States.IN;
    stateName = 'Indiana';
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    stateAbbreviation = States.IA;
    stateName = 'Iowa';
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    stateAbbreviation = States.KS;
    stateName = 'Kansas';
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    stateAbbreviation = States.KY;
    stateName = 'Kentucky';
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    stateAbbreviation = States.LA;
    stateName = 'Louisiana';
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    stateAbbreviation = States.ME;
    stateName = 'Maine';
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    stateAbbreviation = States.MD;
    stateName = 'Maryland';
  } else if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode == 5501 ||
    zipcode == 5544
  ) {
    stateAbbreviation = States.MA;
    stateName = 'Massachusetts';
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    stateAbbreviation = States.MI;
    stateName = 'Michigan';
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    stateAbbreviation = States.MN;
    stateName = 'Minnesota';
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    stateAbbreviation = States.MS;
    stateName = 'Mississippi';
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    stateAbbreviation = States.MO;
    stateName = 'Missouri';
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    stateAbbreviation = States.MT;
    stateName = 'Montana';
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    stateAbbreviation = States.NC;
    stateName = 'North Carolina';
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    stateAbbreviation = States.ND;
    stateName = 'North Dakota';
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    stateAbbreviation = States.NE;
    stateName = 'Nebraska';
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    stateAbbreviation = States.NV;
    stateName = 'Nevada';
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    stateAbbreviation = States.NH;
    stateName = 'New Hampshire';
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    stateAbbreviation = States.NJ;
    stateName = 'New Jersey';
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    stateAbbreviation = States.NM;
    stateName = 'New Mexico';
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode == 6390 ||
    zipcode == 501 ||
    zipcode == 544
  ) {
    stateAbbreviation = States.NY;
    stateName = 'New York';
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    stateAbbreviation = States.OH;
    stateName = 'Ohio';
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    stateAbbreviation = States.OK;
    stateName = 'Oklahoma';
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    stateAbbreviation = States.OR;
    stateName = 'Oregon';
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    stateAbbreviation = States.PA;
    stateName = 'Pennsylvania';
  } else if (zipcode >= 300 && zipcode <= 999) {
    stateAbbreviation = States.PR;
    stateName = 'Puerto Rico';
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    stateAbbreviation = States.RI;
    stateName = 'Rhode Island';
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    stateAbbreviation = States.SC;
    stateName = 'South Carolina';
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    stateAbbreviation = States.SD;
    stateName = 'South Dakota';
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    stateAbbreviation = States.TN;
    stateName = 'Tennessee';
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    stateAbbreviation = States.TX;
    stateName = 'Texas';
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    stateAbbreviation = States.UT;
    stateName = 'Utah';
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    stateAbbreviation = States.VT;
    stateName = 'Vermont';
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode == 20598
  ) {
    stateAbbreviation = States.VA;
    stateName = 'Virginia';
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    stateAbbreviation = States.DC;
    stateName = 'Washington DC';
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    stateAbbreviation = States.WA;
    stateName = 'Washington';
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    stateAbbreviation = States.WV;
    stateName = 'West Virginia';
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    stateAbbreviation = States.WI;
    stateName = 'Wisconsin';
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    stateAbbreviation = States.WY;
    stateName = 'Wyoming';
  } else {
    console.warn('No state found matching');
    return {
      abbreviation: null,
      name: null,
    };
  }

  return {
    abbreviation: stateAbbreviation,
    name: stateName,
  };
};
