/* eslint-disable import/first */
import '@fontsource/lato';
import '@fontsource/comfortaa';
import '@fontsource/lora';
import 'array-flat-polyfill';
import ReactDOM from 'react-dom';
import 'whatwg-fetch'; // fetch polyfill

import { enableAllPlugins } from 'immer';
import { USE_LOCAL_FULLSTORY } from './sentry';
enableAllPlugins();

import { IS_PRODUCTION } from '@curebase/core/lib/env';
import App from './App';
import './i18n';
import './index.css';
import {
  getUserPrivacyStatus,
  onPrivacyPreferencesChanges,
} from './lib/cookieConsent';
import { installFullStory, shutdownFullStory } from './lib/fullStory';
import { unregisterServiceWorker } from './registerServiceWorker';

const root = document.getElementById('root');

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import {
  ENVIRONMENT,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SITE,
} from './lib/env';
import GitCommit from './_git_commit';

import owasp from 'owasp-password-strength-test';
import { owaspConfig } from '@curebase/core/lib/owaspConfig';
import { getDatadogVersion } from '@curebase/core/lib/version';

owasp.config(owaspConfig);

const ddConfig = {
  clientToken: DD_CLIENT_TOKEN,
  site: DD_SITE,
  service: 'curebase/server',
  env: ENVIRONMENT,
};

datadogRum.init({
  ...ddConfig,
  applicationId: DD_APPLICATION_ID,
  version: getDatadogVersion(GitCommit),
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingOrigins: [
    /https:\/\/.*\.curebase\.com/,
    /https:\/\/.*\.cb-access\.com/,
  ],
});

datadogLogs.init({
  ...ddConfig,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

function initFullStory() {
  /*
    We need to compare performanceCookies with `false`,
    because this value initially is `undefined` (when the user didn't choose his cookie preferences),
    so in this case we should initialize FullStory.
  */
  const { performanceCookies } = getUserPrivacyStatus();
  if (
    (IS_PRODUCTION || USE_LOCAL_FULLSTORY) &&
    performanceCookies !== false &&
    !window['FS']
  ) {
    installFullStory();
  }

  if (window['FS'] && performanceCookies === false) {
    shutdownFullStory();
  }
}

if (root !== null) {
  initFullStory();
  ReactDOM.render(<App />, root);
}

// We are not using the service worker for anything except caching,
// which CloudFront will handle for us. Disable completely unless there
// are performance issues, or until we need other service worker functionality.
unregisterServiceWorker();
onPrivacyPreferencesChanges(initFullStory);
