import React, { useContext } from 'react';
import { userIsParticipant } from 'src/lib/users';
import { BookerRootContext } from './BookerDialog';
import { mdToHTML } from '@curebase/core/lib/markdown';
import { useTranslation } from 'react-i18next';

interface BookerSuccessProps {
  onSubmit: () => any;
  isCancellation?: boolean;
}

function BookerSuccess({ onSubmit, isCancellation }: BookerSuccessProps) {
  const { t } = useTranslation('translations');
  const config = useContext(BookerRootContext);
  const {
    participantSuccess,
    participantCancel,
    nonParticipantSuccess,
    nonParticipantCancel,
    participantSuccessButton,
    participantCancelButton,
    nonParticipantSuccessButton,
    nonParticipantCancelButton,
  } = config.config?.default.bookerSuccessText ?? {};

  const participantSuccessMessage: string =
    participantSuccess ?? t('bookerSuccess.participantSuccess');
  const participantCancelMessage: string =
    participantCancel ?? t('bookerSuccess.participantCancel');
  const nonParticipantSuccessMessage: string =
    nonParticipantSuccess ?? t('bookerSuccess.nonParticipantSuccess');
  const nonParticipantCancelMessage: string =
    nonParticipantCancel ?? t('bookerSuccess.nonParticipantCancel');

  const title = isCancellation
    ? t('bookerSuccess.cancelTitle')
    : t('bookerSuccess.successTitle');
  // [JR/DT] - sorry - custom scheduler text should be added to SchedulingConfiguration model, for now hard coded
  return (
    <div className='booker-text-container'>
      <div className='booker-title'>{title}</div>

      <div
        className='booker-text'
        dangerouslySetInnerHTML={{
          __html: mdToHTML(
            userIsParticipant()
              ? !isCancellation
                ? participantSuccessMessage
                : participantCancelMessage
              : !isCancellation
              ? nonParticipantSuccessMessage
              : nonParticipantCancelMessage
          ),
        }}
      />

      <div className='booker-button-container'>
        <div
          className='booker-button'
          role='button'
          id='booker-success-button'
          onClick={() => onSubmit()}
        >
          {(userIsParticipant()
            ? !isCancellation
              ? participantSuccessButton
              : participantCancelButton
            : !isCancellation
            ? nonParticipantSuccessButton
            : nonParticipantCancelButton) ?? t('bookerSuccess.thanksMessage')}
        </div>
      </div>
    </div>
  );
}

export default BookerSuccess;
