import showdown from 'showdown'; // installed by schemaflow importers
import installYoutubeExtension from './youtube';
import installStaticImageS3Extension from './s3StaticImage';
import installVariableExtension from './variable';

installVariableExtension(showdown);
installYoutubeExtension(showdown);
installStaticImageS3Extension(showdown);

export function mdToHTML(md: string, variables: object = {}) {
  const converter = new showdown.Converter({
    extensions: ['youtube', 's3StaticImage', 'variable'],
    knowledgeContentVariables: variables,
  });
  return converter.makeHtml(md);
}
