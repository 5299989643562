import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { TFunction, withTranslation } from 'react-i18next';

type Context = string;

type BuildTranslationPath = (
  context: Context | undefined
) => (
  t: TFunction
) => (textRole: 'title' | 'desc' | 'instructions' | 'reloadBtn') => string;

type DeclaredProps = {
  context?: Context;
  errorId: string | null;
};

type InjectedProps = {
  classes: Record<string, string>;
  history: any;
  t: TFunction;
};

type Props = InjectedProps & DeclaredProps;

const styles = theme => ({
  icon: {
    color: '#434343',
    width: 100,
    height: 100,
  },
});

const ErrorPage: React.FC<Props> = ({
  classes,
  history,
  t,
  context,
  errorId,
}) => {
  console.error('Frontend Crash Error Page Displayed to User!');
  console.error(`ErrorId: ${errorId}`);

  const buildTranslationPath: BuildTranslationPath = context => t => textRole =>
    context
      ? t(`errorPage.contexts.${context}.${textRole}`)
      : t(`errorPage.${textRole}`);

  const get = buildTranslationPath(context)(t);
  return (
    <div className='error-page-container'>
      <div className='error-page-title'>{get('title')}</div>
      <div className='error-page-icon'>
        <ErrorIcon className={classes.icon} />
      </div>
      <div className='error-page-text'>
        <p>{get('desc')}</p>
        <p>{get('instructions')}</p>
        <p className='error-id-text'>Error ID: {errorId}</p>
        <Button
          onClick={() => {
            history.goBack();
          }}
          fullWidth
          color='primary'
          variant='contained'
        >
          {get('reloadBtn')}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(
  withRouter(withTranslation('translations')(ErrorPage) as any)
) as React.FC<DeclaredProps>;
