import React from 'react';
import { Switch } from 'react-router-dom';
import { withBreakpoints } from '../hocs/WithBreakpoints';
import LegalDocument from './LegalDocument';
import { PolicyDocumentType } from '@curebase/core/types';
import { Route } from 'react-router-dom';

type Props = {
  tabletSizedScreen?: boolean;
};

class Legal extends React.Component<Props> {
  render() {
    return (
      <Switch>
        <Route
          path='/legal/adopt/:documentType'
          render={props => (
            <LegalDocument
              documentType={
                props.match.params.documentType as PolicyDocumentType
              }
              onAdopt={() => props.history.push('/')}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withBreakpoints(Legal as any);
