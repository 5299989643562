import {
  TrialOptionFilter,
  TrialOptionSortingOrder,
} from '@curebase/core/types';

export interface ParticipantPickerState {
  trialIds: number[];
  searchQuery: string;
  filter: TrialOptionFilter;
  sortingOrder: TrialOptionSortingOrder;
  statuses: string[];
  epochs: string[];
}

export const CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER =
  'CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER';
export const CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER =
  'CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER';
export const CHANGE_PARTICIPANT_PICKER_FILTERS =
  'CHANGE_PARTICIPANT_PICKER_FILTERS';
export const CHANGE_PARTICIPANT_PICKER_SORTING_ORDER =
  'CHANGE_PARTICIPANT_PICKER_SORTING_ORDER';
export const CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER =
  'CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER';
export const CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER =
  'CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER';

export interface ChangeParticipantStudiesPickerFiltersAction {
  payload: {
    trialIds: number[];
  };
  type: typeof CHANGE_PARTICIPANT_STUDIES_PICKER_FILTER;
}

export interface ChangeParticipantSearchQueryFilterAction {
  payload: {
    searchQuery: string;
  };
  type: typeof CHANGE_PARTICIPANT_SEARCH_QUERY_FILTER;
}

export interface ChangeParticipantSortingOrderilterAction {
  payload: {
    sortingOrder: TrialOptionSortingOrder;
  };
  type: typeof CHANGE_PARTICIPANT_PICKER_SORTING_ORDER;
}

export interface ChangeParticipantPickerFiltersAction {
  payload: {
    filter: TrialOptionFilter;
  };
  type: typeof CHANGE_PARTICIPANT_PICKER_FILTERS;
}

export interface ChangeParticipantStatusesPickerFiltersAction {
  payload: {
    statuses: string[];
  };
  type: typeof CHANGE_PARTICIPANT_STATUSES_PICKER_FILTER;
}

export interface ChangeParticipantEpochsPickerFiltersAction {
  payload: {
    epochs: string[];
  };
  type: typeof CHANGE_PARTICIPANT_EPOCHS_PICKER_FILTER;
}

export type ParticipantPickerActionTypes =
  | ChangeParticipantStudiesPickerFiltersAction
  | ChangeParticipantSearchQueryFilterAction
  | ChangeParticipantPickerFiltersAction
  | ChangeParticipantStatusesPickerFiltersAction
  | ChangeParticipantSortingOrderilterAction
  | ChangeParticipantEpochsPickerFiltersAction;
