import React, { useContext, useState } from 'react';
import AudioVideo from './AudioVideo';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TelemedIndicator from '../../context/mainTelemedRoomOpenContext';
import { useSelector } from 'react-redux';
import { sendTelehealthHeartbeat } from 'src/controllers/telemedController';
import { clearTelehealthDataInStore } from 'src/store/telehealth/actions';
import {
  osName,
  deviceType,
  browserName,
  isMobileOnly,
} from 'react-device-detect';
import { heartbeatFrequency } from '@curebase/modules/scheduling/telehealth';
import chimeContext from '../../context/getChimeContext';
import Loading from '../Loading';

type Props = {
  meeting?: any;
  attendee?: any;
  width: number;
  photoBoothMode: boolean;
};
export const midWidthForMobileView = '(max-width:600px)';
export const messageCenterMobileHeight = 160;

export const useShouldRenderBottomPanel = () => {
  const smallWidth = useMediaQuery(midWidthForMobileView);
  const { value: telemedMeetingRoomMounted } = useContext(TelemedIndicator);
  const { openVideo } = useSelector((store: any) => store?.telehealth);

  return getIsMobileView(smallWidth, telemedMeetingRoomMounted, openVideo);
};

const Reconnecting = () => {
  return (
    <div className='tile-container center absolute'>
      <div className='meeting-end'>Reconnecting...</div>
      <Loading />
    </div>
  );
};

const getIsMobileView = (
  smallWidth: boolean,
  telemedMeetingRoomMounted: boolean,
  openVideo: boolean
) => {
  return smallWidth && !telemedMeetingRoomMounted && openVideo;
};

const getClassName = (
  telemedMeetingRoomMounted: boolean,
  renderMobileView: boolean
) => {
  let className = 'message-center-panel';
  if (telemedMeetingRoomMounted) {
    className += ' maximized';
  }
  if (renderMobileView) {
    className = 'message-center-panel-mobile';
  }
  return className;
};

const MessageCenter = (props: Props) => {
  const { value: telemedMeetingRoomMounted } = useContext(TelemedIndicator);
  const { openVideo } = useSelector((store: any) => store?.telehealth);
  const smallWidth = useMediaQuery(midWidthForMobileView);

  const { meetingTitle: meetingUUID, signatureMountStatus } = useSelector(
    (store: any) => store.telehealth
  );
  const [heartbeatHanging, setHeartbeatHanging] = useState(false);
  const chime = useContext(chimeContext);
  const renderMobileView = getIsMobileView(
    smallWidth,
    telemedMeetingRoomMounted,
    openVideo
  );

  React.useEffect(() => {
    //@ts-ignore
    window.zE('webWidget', 'hide');
    let interval;
    let isDisconnecting = false;
    const intervalHandler = () => {
      sendTelehealthHeartbeat(
        meetingUUID,
        `${deviceType}-${browserName}-${osName}`
      )
        .then(res => {
          if ((res as any).error) {
            clearTelehealthDataInStore(`Attendee is not in meeting`);
          } else {
            console.log('successfully logged heartbeat');
            if (isDisconnecting) {
              isDisconnecting = false;
              setHeartbeatHanging(false);
            }
          }
        })
        .catch(err => {
          if (!isDisconnecting) {
            isDisconnecting = true;
            setHeartbeatHanging(true);
          }
        });
    };

    if (props.attendee && props.meeting) {
      intervalHandler();
      interval = setInterval(intervalHandler, heartbeatFrequency * 1000);
    }

    return () => {
      if (!!interval) {
        clearInterval(interval);
      }
      chime.stopLocalVideoTile();
      chime.audioVideo?.stop();
      clearTelehealthDataInStore('MessageCenter panel is unmounting');
      //@ts-ignore
      window.zE('webWidget', 'show');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={getClassName(telemedMeetingRoomMounted, renderMobileView)}
      style={
        renderMobileView
          ? {
              height: `${messageCenterMobileHeight}px`,
              marginBottom: signatureMountStatus ? '84px' : '0px',
            }
          : { width: isMobileOnly ? '100%' : `${props.width}px` }
      }
    >
      <div className='fixed-wrapper'>
        <div
          className='fixed-div'
          style={{
            position: 'fixed',
            width: telemedMeetingRoomMounted ? '100%' : `${props.width}px`,
          }}
        >
          <div className='flex-message-center-attributes'>
            {heartbeatHanging && <Reconnecting />}
            <AudioVideo
              meeting={props.meeting}
              attendee={props.attendee}
              photoBoothEnabled={props.photoBoothMode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCenter;
