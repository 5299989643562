import React from 'react';
import moment from 'moment-timezone';
import _pick from 'lodash/pick';
import ListItem from '../basic/ListItem';
import FormDialog from '../basic/FormDialog';
import type { VisitAvailabilityMixed } from '@curebase/core/lib/scheduling';
import { editVisitAvailability } from '../../controllers/schedulingController';
import { getLocaleFormatDates } from '../../context/localeContext';
import { useTranslation } from 'react-i18next';

const getPages = (isRecurring: boolean, schedulingConfig: any[], t: any) => {
  const elements: any =
    schedulingConfig.length > 0
      ? [
          {
            title: t('editAvailabilityDialog.pages.selectEventTitle'),
            subElements: [
              {
                key: 'schedulingConfigurationIds',
                type: 'MULTICHECKBOX',
                options: schedulingConfig.map(x => ({
                  text: x.name,
                  value: x.id,
                })),
              },
            ],
          },
        ]
      : [];

  if (isRecurring) {
    elements.push(
      ...[
        {
          title: t('editAvailabilityDialog.pages.selectEndDate.title'),
          subElements: [
            {
              key: 'endDate',
              type: 'DATE',
              allowNull: true,
              allowAnyFutureDate: true,
            },
          ],
        },
        {
          title: t('editAvailabilityDialog.pages.selectDaysRepeat.title'),
          subElements: [
            {
              key: 'byDay',
              type: 'MULTICHECKBOX',
              options: [
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.mo'
                  ),
                  value: 'Mo',
                },
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.tu'
                  ),
                  value: 'Tu',
                },
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.we'
                  ),
                  value: 'We',
                },
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.th'
                  ),
                  value: 'Th',
                },
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.fr'
                  ),
                  value: 'Fr',
                },
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.sa'
                  ),
                  value: 'Sa',
                },
                {
                  text: t(
                    'editAvailabilityDialog.pages.selectDaysRepeat.options.su'
                  ),
                  value: 'Su',
                },
              ],
            },
          ],
        },
        {
          title: t('editAvailabilityDialog.pages.aptDuration.title'),
          subElements: [
            {
              key: 'aptDuration',
              type: 'NUMBER',
            },
          ],
        },
        {
          title: t(
            'editAvailabilityDialog.pages.maxConfirmedConcurrency.title'
          ),
          subElements: [
            {
              key: 'maxConfirmedConcurrency',
              type: 'NUMBER',
            },
          ],
        },
        {
          title: t(
            'editAvailabilityDialog.pages.maxUnconfirmedConcurrency.title'
          ),
          subElements: [
            {
              key: 'maxUnconfirmedConcurrency',
              type: 'NUMBER',
            },
          ],
        },
        {
          title: t('editAvailabilityDialog.pages.participantEnabled.title'),
          subElements: [
            {
              key: 'participantEnabled',
              type: 'YES_NO',
            },
          ],
        },
      ]
    );
  } else {
    elements.push(
      ...[
        {
          title: t('editAvailabilityDialog.pages.startDate.title'),
          subElements: [
            {
              key: 'start',
              type: 'DATETIME',
            },
          ],
        },
        {
          title: t('editAvailabilityDialog.pages.endDate.title'),
          subElements: [
            {
              key: 'end',
              type: 'DATETIME',
            },
          ],
        },
        {
          title: t('editAvailabilityDialog.pages.aptDuration.title'),
          subElements: [
            {
              key: 'aptDuration',
              type: 'NUMBER',
            },
          ],
        },
        {
          title: t(
            'editAvailabilityDialog.pages.maxConfirmedConcurrency.title'
          ),
          subElements: [
            {
              key: 'maxConfirmedConcurrency',
              type: 'NUMBER',
            },
          ],
        },
        {
          title: t(
            'editAvailabilityDialog.pages.maxUnconfirmedConcurrency.title'
          ),
          subElements: [
            {
              key: 'maxUnconfirmedConcurrency',
              type: 'NUMBER',
            },
          ],
        },
        {
          title: t('editAvailabilityDialog.pages.participantEnabled.title'),
          subElements: [
            {
              key: 'participantEnabled',
              type: 'YES_NO',
            },
          ],
        },
      ]
    );
  }

  return [{ elements }];
};

type OneOffForm = {
  start: string;
  end: string;
  aptDuration: number;
  maxConfirmedConcurrency: number;
  maxUnconfirmedConcurrency: number;
  participantEnabled: 'YES' | 'NO';
};

type RecurringForm = {
  endDate: string;
  aptDuration: number;
  byDay: string[];
  maxConfirmedConcurrency: number;
  maxUnconfirmedConcurrency: number;
  participantEnabled: 'YES' | 'NO';
};

type Props = {
  open: boolean;
  onClose: () => any;
  timezone: string;
  visitAvailability: VisitAvailabilityMixed;
  schedulingConfig: any[];
  refetch: () => any;
};

const EditAvailabilityDialog = (props: Props) => {
  const { t } = useTranslation('translations');

  if (!props.visitAvailability) {
    return null;
  }

  const localesFormat = getLocaleFormatDates();
  const {
    visitAvailability: {
      id: windowId,
      start: instanceStart,
      end: instanceEnd,
      originalStart,
      originalEnd,
      rrule,
    },
    refetch,
    visitAvailability,
    open,
    onClose,
    timezone,
    schedulingConfig,
  }: any = props;
  const start = originalStart ? originalStart : instanceStart;
  const end = originalEnd ? originalEnd : instanceEnd;
  const startMoment = moment(start).tz(timezone);
  const endMoment = moment(end).tz(timezone);
  const isRecurringWindow = !!rrule?.byDay;

  const initialState: RecurringForm | OneOffForm = {
    ..._pick(visitAvailability, [
      'aptDuration',
      'maxConfirmedConcurrency',
      'maxUnconfirmedConcurrency',
      'schedulingConfigurationIds',
    ]),
    byDay: isRecurringWindow ? rrule.byDay : undefined,
    // @ts-ignore
    endDate: isRecurringWindow ? endMoment : undefined,
    // @ts-ignore
    end: !isRecurringWindow ? endMoment : undefined,
    // @ts-ignore
    start: startMoment,
    participantEnabled: visitAvailability.participantEnabled ? 'YES' : 'NO',
  };

  const infoListItem = isRecurringWindow
    ? {
        title: `${startMoment.format(
          localesFormat.moment.editAvailabilityStartFormat
        )} - ${endMoment.format(
          localesFormat.moment.editAvailabilityEndFormat
        )}`,
        text:
          rrule.byDay &&
          `${t('editAvailabilityDialog.repeatEveryLabel')} ${rrule.byDay.join(
            ', '
          )}`,
        subtitle: `${startMoment.format(
          localesFormat.moment.longTime
        )} - ${endMoment.format(localesFormat.moment.longTime)}`,
      }
    : {
        title: `${startMoment.format(localesFormat.moment.longFormalDateTime)}`,
        text: t('editAvailabilityDialog.noRepeatLabel'),
        subtitle: `${startMoment.format(
          localesFormat.moment.longTime
        )} - ${endMoment.format(localesFormat.moment.longTime)}`,
      };

  const dynamicFormPages = getPages(isRecurringWindow, schedulingConfig, t);

  return (
    <FormDialog
      open={open}
      title={t('editAvailabilityDialog.title')}
      onClose={onClose}
      initialState={initialState}
      dynamicFormPages={dynamicFormPages}
      timezone={timezone}
      onSubmit={async (data: any) => {
        let request: RecurringForm | OneOffForm = {
          ...data,
          id: windowId,
          maxConfirmedConcurrency: data?.maxConfirmedConcurrency
            ? Number(data?.maxConfirmedConcurrency)
            : data?.maxConfirmedConcurrency,
          maxUnconfirmedConcurrency: data?.maxUnconfirmedConcurrency
            ? Number(data?.maxUnconfirmedConcurrency)
            : data?.maxUnconfirmedConcurrency,
          aptDuration: data?.aptDuration
            ? Number(data?.aptDuration)
            : data?.aptDuration,
          rrule: {
            byDay: data.byDay,
          },
        };

        // @ts-ignore
        request.participantEnabled = request.participantEnabled === 'YES';
        if (isRecurringWindow) {
          const hours = moment(originalEnd).tz(timezone).hours();
          const minutes = moment(originalEnd).tz(timezone).minutes();
          // @ts-ignore
          request.end = moment(data.endDate)
            .hours(hours)
            .minutes(minutes)
            .toISOString();
        } else {
          // @ts-ignore
          request.start = moment.tz(data.start, timezone).toISOString();
          // @ts-ignore
          request.end = moment.tz(data.end, timezone).toISOString();
        }
        const response = await editVisitAvailability(request as any);
        if (response) {
          refetch();
          onClose();
        }
      }}
    >
      <ListItem middle={infoListItem} key='info' />
    </FormDialog>
  );
};

export default EditAvailabilityDialog;
