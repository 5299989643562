import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import { withdrawPatient } from '../../controllers/trialOptionController';
import Dialog from '../basic/SafeDialog';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { TrialOptionDetailsQuery } from '../../types';
import { useTranslation } from 'react-i18next';

interface Props {
  trialOption: TrialOptionDetailsQuery['getTrialOption'];
  refetch: () => {};
}

function WithdrawPatient(props: Props) {
  const { t } = useTranslation('translations');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(
    false
  );

  const { trialOption } = props;

  return (
    <>
      <Button
        variant='contained'
        color='secondary'
        style={{ margin: '26px' }}
        onClick={() => setShowConfirmationDialog(true)}
      >
        {t('withdrawPatient.withdrawBtn')}
      </Button>

      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <ConfirmationInDialog
          title={t('withdrawPatient.title')}
          message={t('withdrawPatient.message')}
          onConfirm={async () => {
            await withdrawPatient(trialOption.id);
            setShowConfirmationDialog(false);
            props.refetch();
          }}
          onClose={() => setShowConfirmationDialog(false)}
        />
      </Dialog>
    </>
  );
}

export default WithdrawPatient;
