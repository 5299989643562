import {
  CreateMockTrialOptionsRequest,
  CreateMockTrialOptionsResponse,
} from '@curebase/core/decoders/mockTrialOptions';
import { jsonPost } from '../lib/fetchHelpers';

export async function createMockTrialOptions(
  trialSlug: string,
  targetState: string,
  namePrefix: string,
  count: number,
  clinicId: string,
  visitSiteId: number,
  executeSynchronous: boolean,
  customDataCapture: string | null
): Promise<CreateMockTrialOptionsResponse> {
  const requestBody: CreateMockTrialOptionsRequest = {
    trialSlug,
    targetState,
    namePrefix,
    count,
    clinicId,
    visitSiteId,
    customDataCapture,
    executeSynchronous,
  };
  const response: CreateMockTrialOptionsResponse = await jsonPost(
    '/mockTrialOptions/create',
    requestBody
  );
  return response;
}
