import * as React from 'react';
import { withTranslation } from 'react-i18next';

import TextInput from '../basic/TextInput';
import StandardButton from '../basic/StandardButton';
import StatusText from '../basic/StatusText';
import { requestPasswordReset } from '../../controllers/authController';

type Props = {
  history: any;
  match: any;
  t: any;
};

type State = {
  success?: boolean;
  errorMessage?: string;
  enteredEmail?: string;
};

class Signup extends React.Component<Props, State> {
  state = {};

  onSubmit = async () => {
    const { t } = this.props;
    const { enteredEmail }: any = this.state;

    if (!enteredEmail) {
      this.setState({
        errorMessage: t('auth.forgotPassword.emailEmpty'),
        success: false,
      });
      return;
    }
    await requestPasswordReset(enteredEmail);
    this.setState({ success: true, errorMessage: '' });
  };

  render() {
    const { t } = this.props;
    const { success, errorMessage, enteredEmail }: any = this.state;

    return (
      <>
        <div className='auth-right-header'>
          {t('auth.forgotPassword.title')}
          <StatusText color='red' text={errorMessage || ''} />
          {success ? (
            <StatusText color='green' text={t('auth.forgotPassword.success')} />
          ) : (
            <StatusText
              color='gray'
              text={t('auth.forgotPassword.instructions')}
            />
          )}
        </div>

        <div className='auth-right-body'>
          <TextInput
            placeholder={t('auth.forgotPassword.emailPlaceholder')}
            onChange={v => this.setState({ enteredEmail: v })}
            specialType='email'
            value={enteredEmail}
            noSpellcheck={true}
            onEnter={this.onSubmit}
            hidePersonalInformation
          />

          <StandardButton
            className='button'
            onClick={this.onSubmit}
            variant='contained'
            fullWidth
            size='xlarge'
          >
            {t('auth.forgotPassword.submitEmail')}
          </StandardButton>
        </div>
      </>
    );
  }
}

export default withTranslation('translations')(Signup as any);
