import { getDispatch } from '../../store';
import i18n from '../../i18n';
import { AdditionalUserInfo, TTrackingData } from '../user/types';
import {
  SelectClinicAction,
  SelectPatientAction,
  SelectTrialOptionAction,
} from './navigation';
import { clearTelehealthDataInStore } from '../telehealth/actions';
import { StatusColor } from 'src/shared/lib/colors';
import {
  changeParticipantPickerFilters as changeParticipantPickerFiltersAction,
  changeParticipantPickerSortingOrderFilters as changeParticipantPickerSortingOrderFiltersAction,
  changeParticipantSearchQueryPickerFilters,
} from '../participantPicker/actions';

type TParticipantPickerFilters = any;

type TelehealthAction =
  | {
      type: 'SET_JOIN_INFO';
      payload: {
        meeting: any;
        attendee: any;
      };
    }
  | {
      type: 'SET_MEETING_TITLE';
      payload: {
        meetingTitle: string;
      };
    }
  | {
      type: 'SET_MEETING_NAME';
      payload: {
        meetingName: string;
      };
    }
  | {
      type: 'SET_MEETING_TIME';
      payload: {
        meetingTime: string;
      };
    }
  | { type: 'OPEN_VIDEO_CHAT' }
  | { type: 'CLOSE_VIDEO_CHAT' }
  | { type: 'LEAVE_CALL' }
  | { type: 'RECORDING_ENABLED' }
  | {
      type: 'SET_PHOTO_MODE';
      payload: {
        addPhotos: boolean;
      };
    }
  | {
      type: 'FOOTER_PORTAL_STATUS';
      status: boolean;
    }
  | { type: 'CLEAR_TELEHEALTH' };

export type Action =
  | SelectClinicAction
  | SelectPatientAction
  | SelectTrialOptionAction
  | TelehealthAction
  | {
      type: 'PATIENT_MODE_PENDING_SET';
      pendingSettings: {
        baseRoute: string;
        successRoute: string;
      };
    }
  | { type: 'PATIENT_MODE_PENDING_CLEAR'; exitCallback: () => void }
  | { type: 'SET_PATIENT_MODE' }
  | { type: 'CLEAR_PATIENT_MODE' }
  | { type: 'FULLY_AUTHENTICATE' }
  | { type: 'FULLY_ADOPT_LEGAL' }
  | { type: 'CLEAR_USER' }
  | { type: 'SET_PIN_CODE'; pinCode: string }
  | {
      type: 'SET_USER';
      userId?: number;
      pinCode?: string;
      phoneNumber?: string;
      email?: string;
      additionalUserInfo?: AdditionalUserInfo;
    }
  | { type: 'HIDE_WELCOME_MODAL' }
  | { type: 'SHOW_MENU_DRAWER' }
  | { type: 'HIDE_MENU_DRAWER' }
  | { type: 'SET_LANGUAGE'; code: string }
  | { type: 'SET_PATIENTS_TRIAL_OPTIONS'; data: Object }
  | {
      type: 'SHOW_ALERT_MESSAGE';
      visibleForSeconds: number;
      text: string;
      statusColor?: StatusColor;
    }
  | { type: 'TICK_ALERT_MESSAGE_TIMER' }
  | { type: 'SET_DATE'; date: number }
  | { type: 'RESET_DATE'; dateOffset: number }
  | { type: 'SET_MAINTENANCE_MODE_ENABLED'; isEnabled: boolean }
  | { type: 'SAVE_TRACKING_DATA'; data: TTrackingData }
  | { type: 'SHOW_FULL_LOADING' }
  | { type: 'HIDE_FULL_LOADING' }
  | {
      type: 'CHANGE_PARTICIPANT_PICKER_FILTERS';
      data: TParticipantPickerFilters;
    }
  | {
      type: 'SET_IS_FULL_STORY_ENABLED';
      isEnabled: boolean;
    };

export function showFullLoading() {
  const action: Action = { type: 'SHOW_FULL_LOADING' };
  getDispatch()(action);
}

export function selectTrial(trialOptionId: number) {
  // @ts-ignore
  const action: Action = { type: 'SELECT_TRIAL', payload: { trialOptionId } };
  getDispatch()(action);
}

export function hideFullLoading() {
  const action: Action = { type: 'HIDE_FULL_LOADING' };
  getDispatch()(action);
}

export function showAlertMessage(
  text: string,
  statusColor?: StatusColor,
  visibleForSeconds: number = 3
) {
  const action: Action = {
    type: 'SHOW_ALERT_MESSAGE',
    text,
    visibleForSeconds,
    statusColor,
  };
  getDispatch()(action);
}

export function showEnterPatientModeDialog({
  pendingSettings,
  history,
}: {
  pendingSettings: {
    baseRoute: string;
    successRoute: string;
  };
  history: any;
}) {
  const action: Action = {
    type: 'PATIENT_MODE_PENDING_SET',
    pendingSettings,
  };
  getDispatch()(action);
}

export function showExitPatientModeDialog(exitCallback: () => void) {
  const action: Action = {
    type: 'PATIENT_MODE_PENDING_CLEAR',
    exitCallback,
  };
  getDispatch()(action);
}

export function setPatientMode() {
  const action: Action = { type: 'SET_PATIENT_MODE' };
  getDispatch()(action);
}

export function clearPatientMode() {
  const action: Action = { type: 'CLEAR_PATIENT_MODE' };
  getDispatch()(action);
}

export function clearUser() {
  const action: Action = { type: 'CLEAR_USER' };
  getDispatch()(action);
  clearTelehealthDataInStore('clearing user');
}

export function fullyAdoptLegal() {
  const action: Action = { type: 'FULLY_ADOPT_LEGAL' };
  getDispatch()(action);
}

export function dontAdoptLegal() {
  // @ts-ignore
  const action: Action = { type: 'DONT_ADOPT_LEGAL' };
  getDispatch()(action);
}

export function setUser(
  userId: number,
  pinCode?: string,
  // @ts-ignore
  email: string,
  phoneNumber: string,
  additionalUserInfo: AdditionalUserInfo
) {
  const action: Action = {
    type: 'SET_USER',
    userId,

    pinCode,
    email,
    phoneNumber,
    additionalUserInfo,
  };
  getDispatch()(action);
}

export function setTrialOptions(
  patientId: number,
  pptTrialOptions: { trialOptionId: Number; trialIdentifier: string }[]
) {
  const action: Action = {
    type: 'SET_PATIENTS_TRIAL_OPTIONS',
    data: { patientId, pptTrialOptions },
  };
  getDispatch()(action);
}

export function showMenuDrawer() {
  const action: Action = { type: 'SHOW_MENU_DRAWER' };
  getDispatch()(action);
}

export function hideMenuDrawer() {
  const action: Action = { type: 'HIDE_MENU_DRAWER' };
  getDispatch()(action);
}

export function setLanguage(code: string) {
  const action: Action = { type: 'SET_LANGUAGE', code };
  i18n.changeLanguage(code);
  getDispatch()(action);
  window.location.reload();
}

export function saveTrackingData(data: TTrackingData) {
  const action: Action = { type: 'SAVE_TRACKING_DATA', data };
  getDispatch()(action);
}

// TODO: Should be removed later when ParticipantPicker class component is
// refactored to function component.
export function changeParticipantPickerFilters(filter: string) {
  // @ts-expect-error
  getDispatch()(changeParticipantPickerFiltersAction(filter));
}
export function changeParticipantPickerSortingOrderFilters(filter: string) {
  // @ts-expect-error
  getDispatch()(changeParticipantPickerSortingOrderFiltersAction(filter));
}

export function changeParticipantSearchQueryFilter(searchQuery: string) {
  getDispatch()(changeParticipantSearchQueryPickerFilters(searchQuery));
}

export function enableFullStoryInStore() {
  const action: Action = {
    type: 'SET_IS_FULL_STORY_ENABLED',
    isEnabled: true,
  };
  getDispatch()(action);
}

export function disableFullStoryInStore() {
  const action: Action = {
    type: 'SET_IS_FULL_STORY_ENABLED',
    isEnabled: false,
  };
  getDispatch()(action);
}

/**
 * Helper function for submitting the action indicating whether
 * maintenance mode is enabled for the application.
 *
 * When the app detects that the backend is returning a 503 HTTP status code,
 * indicating that the app is in maintenance mode, this action is called.
 * @param isEnabled
 */
export function setMaintenanceModeEnabled(isEnabled: boolean) {
  const action: Action = {
    type: 'SET_MAINTENANCE_MODE_ENABLED',
    isEnabled,
  };
  getDispatch()(action);
}
