import { Height, parseHeight } from '@curebase/core/lib/height';
import {
  Input,
  InputAdornment,
  InputProps,
  InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';

interface Props {
  min?: number | null;
  max?: number | null;
  value: Height;
  hasError?: boolean;
  disabled?: boolean;
  label?: string;
  onChange: (value: Height) => void;
}

const CBHeightInput = withStyles({
  input: {
    border: 'none !important',
    boxShadow: 'none !important',
  },
})(Input);

function HeightInput(props: Props): React.ReactElement {
  const { value, label, hasError, onChange } = props;

  const classes = useStyles({ hasError });

  const [feet, setFeet] = useState(value.feet);
  const [inches, setInches] = useState(value.inches);

  useEffect(() => {
    const height = parseHeight(feet, inches);
    onChange(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feet, inches]);

  function onFeetChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const value = parseInt(event.target.value);
    if (value < 0) return;
    setFeet(value);
  }

  function onInchChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const value = parseInt(event.target.value);
    if (value < 0 || value > 12) return;

    setInches(value);
  }

  const sharedProps: InputProps = {
    className: classes.field,
    type: 'number',
    disableUnderline: true,
  };

  return (
    <div className={classes.container}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <div className={classes.form}>
        <CBHeightInput
          value={feet}
          onChange={onFeetChange}
          endAdornment={<InputAdornment position='end'>ft</InputAdornment>}
          {...sharedProps}
        />
        <CBHeightInput
          value={inches}
          onChange={onInchChange}
          endAdornment={<InputAdornment position='end'>in</InputAdornment>}
          {...sharedProps}
        />
      </div>
    </div>
  );
}

export default HeightInput;
