import { getUser } from '../store';
import { baseUrl } from '@curebase/core/lib/env';
import { PAYPAL_URL, PAYPAL_CLIENT_ID } from './env';
import { getLocale } from '../context/localeContext';

export const generatePaypalLoginURL = (): string => {
  return `${PAYPAL_URL}/login`;
};

export const generatePaypalConnectURL = (): string => {
  const clientId = PAYPAL_CLIENT_ID;
  const { country = 'us', language = 'en' } = getLocale();

  const url = `${PAYPAL_URL}/connect?flowEntry=static&client_id=${clientId}&response_type=code&scope=profile%20https://uri.paypal.com/services/paypalattributes&country.x=${country.toLocaleUpperCase()}&locale.x=${language}_${country.toLocaleUpperCase()}&redirect_uri=${baseUrl}/paypal/save-id&state=${
    getUser().userId
  }`;

  return url;
};
