import md5 from 'md5';
import _isString from 'lodash/isString';

type UploadedFileInfo = {
  label: string;
  path: string;
};

export type FileInfo = File | UploadedFileInfo;

export function hashFile(file: any): string {
  if (_isString(file)) return file;
  const name = file.name ? file.name : file.originalname;
  if (file.label) {
    return file.label;
  }
  if (!name || !file.size) return '';
  return `${md5(name)}_${file.size}`;
}

export const testSignature = {
  legalName: '{Legal Name Here}',
  attestations: ['Attestation1', 'Attestation2'],
  filePath: 'Files/000204f6-9c32-4db3-a644-5e4ddbeb9e22/devTestSignature.png',
  fileId: '000204f6-9c32-4db3-a644-5e4ddbeb9e22',
  reason: 'I SIGN',
};
