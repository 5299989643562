import * as React from 'react';

type Props = {
  text: string;
  color: string;
};

type State = {};

class StatusText extends React.Component<Props, State> {
  render() {
    return (
      <div className={`status-text-container status-color-${this.props.color}`}>
        {this.props.text}
      </div>
    );
  }
}

export default StatusText;
