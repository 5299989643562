import * as React from 'react';
import moment from 'moment-timezone';

import { capitalizeDisplayName } from '../../../lib/ui';
import { secureImageUriFromFileName } from '@curebase/core/lib/env';
import { DataCaptureReserved, DataField } from '@curebase/core/types';
import { CaseReportDataType } from 'src/types';
import FormResultsMedicationQuestion from '../FormResultsMedicationQuestion';
import { useTranslation } from 'react-i18next';
import { ReadOnlyFuzzyDate } from 'src/components/TrialBuilder/FuzzyDatePicker';

type DataEditSectionProps = {
  dataField: DataField;
  change: {
    createdAt: string;
    author?: {
      displayName: string;
    };
    editReason: string;
    value: any;
    priorValue: any;
  };
};

const fileListDiffText = value => {
  const basecase = <em>No Uploaded Files</em>;
  if (
    value !== DataCaptureReserved.NotApplicableDueToDependency &&
    value?.length > 0
  ) {
    const links = value.map((upload, index) => (
      <span key={index}>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={secureImageUriFromFileName(upload.path)}
        >
          {upload.name}
        </a>
        {index === value.length - 1 ? '' : ', '}
      </span>
    ));
    return links;
  }
  return basecase;
};

const FileUploadDiffSection = ({ priorValue, value }) => {
  return (
    <>
      {fileListDiffText(priorValue)} to {fileListDiffText(value)}
    </>
  );
};

const MedicationDiffSection = ({ priorValue, value }) => {
  return (
    <>
      <FormResultsMedicationQuestion
        medications={priorValue.medications}
        isOptingOut={priorValue.isOptingOut}
      />

      <p>to</p>

      <FormResultsMedicationQuestion
        medications={value.medications}
        isOptingOut={value.isOptingOut}
      />
    </>
  );
};

const AddressDiffSection = ({ priorValue, value }) => {
  const { t } = useTranslation('translations');
  const { address1, address2, city, state, zipCode } = priorValue;
  const {
    address1: newAddress1,
    address2: newAddress2,
    city: newCity,
    state: newState,
    zipCode: newZipCode,
  } = value;
  const PLACEHOLDER = 'Unavailable';
  return (
    <>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.address')}</b>
        <p>{address1 ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.address2')}</b>
        <p>{address2 ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.city')}</b>
        <p>{city ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.state')}</b>
        <p>{state ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.zipCode')}</b>
        <p>{zipCode ?? PLACEHOLDER}</p>
      </div>

      <p>{t('formResultsQuerySection.editSection.address.toLabel')}</p>

      <div>
        <b>{t('formResultsQuerySection.editSection.address.address')}</b>
        <p>{newAddress1 ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.address2')}</b>
        <p>{newAddress2 ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.city')}</b>
        <p>{newCity ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.state')}</b>
        <p>{newState ?? PLACEHOLDER}</p>
      </div>
      <div>
        <b>{t('formResultsQuerySection.editSection.address.zipCode')}</b>
        <p>{newZipCode ?? PLACEHOLDER}</p>
      </div>
    </>
  );
};

const FuzzyDateDiffSection = ({ priorValue, value }) => {
  return (
    <>
      <em>
        <ReadOnlyFuzzyDate value={priorValue} /> to{' '}
        <ReadOnlyFuzzyDate value={value} />
      </em>
    </>
  );
};

const DiffComponents = {};
DiffComponents[CaseReportDataType.File] = FileUploadDiffSection;
DiffComponents[CaseReportDataType.DrugbankAutocomplete] = MedicationDiffSection;
DiffComponents[CaseReportDataType.Address] = AddressDiffSection;
DiffComponents[CaseReportDataType.FuzzyDatePicker] = FuzzyDateDiffSection;

const DataEditSection = (props: DataEditSectionProps) => {
  const { t } = useTranslation('translations');
  const { change, dataField } = props;
  const { priorValue, value, author, editReason, createdAt } = change;

  const isFileUpload = dataField.dataType === CaseReportDataType.File;

  const DiffComponent = DiffComponents[dataField.dataType];

  let diff;

  if (DiffComponent) {
    diff = <DiffComponent priorValue={priorValue} value={value} />;
  } else {
    diff = (
      <>
        <em>{priorValue?.toString()}</em> to <em>{value?.toString()}</em>
      </>
    );
  }

  const editReasonPrefix = isFileUpload ? 'Edit Reason: ' : '';

  return (
    <div className='fr-query-message'>
      <div className='fr-query-message-header'>
        {`${capitalizeDisplayName(author, t)} (${moment(createdAt).fromNow()})`}
      </div>
      <div className='fr-query-message-content'>
        {t('formResultsQuerySection.editSection.changeMessage')}: {diff}
      </div>
      <div className='fr-query-message-content'>
        {editReasonPrefix}
        <em>{editReason}</em>
      </div>
    </div>
  );
};

export default DataEditSection;
