import * as React from 'react';

type Props = {
  onChange: (newValue: string) => any;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
};

function SearchBar(props: Props) {
  const { className, defaultValue, placeholder, onChange } = props;
  return (
    <input
      defaultValue={defaultValue}
      placeholder={placeholder}
      type='text'
      className={`search-bar-input ${className || ''}`}
      onChange={event => onChange(event.target.value)}
    />
  );
}

export default SearchBar;
