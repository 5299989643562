import * as QuitGenius_001 from './QuitGenius_001';
import * as QuitGenius_002 from './QuitGenius_002';
import * as easitx from './easitx';
import * as eclipse from './eclipse';
import * as shared from './shared';

export default {
  QuitGenius_001,
  QuitGenius_002,
  easitx,
  shared,
  eclipse,
};
