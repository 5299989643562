import { Typography } from '@material-ui/core';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionDisplayProps, ButtonProps } from './ActionCard';
import CustomStyleContext from '../../context/customStyleContext';

function ActionListItem(props: ActionDisplayProps) {
  const { title, description, buttons, extraButtons } = props;
  const [buttonsCollapsed, setButtonsCollapsed] = useState<boolean>(true);
  const { t } = useTranslation('translations');
  const { colors } = useContext(CustomStyleContext) || {};

  let allButtons: ButtonProps[] = [];
  if (buttons && buttons.length > 0) allButtons.push(...buttons);
  if (!!extraButtons) allButtons = [...allButtons, ...extraButtons];

  const hasCollapse = extraButtons?.length && extraButtons.length > 1;

  return (
    <div className='action-li'>
      <div
        className='action-li-left-bar'
        style={{ backgroundColor: colors?.primary }}
      />

      <div className='action-li-left'>
        <Typography variant='h3' className='action-li-left-top'>
          {title}
        </Typography>
        <div className='action-li-left-bottom'>{description}</div>
      </div>

      <div className='action-li-right'>
        <div
          className={`action-li-buttons-container ${
            hasCollapse && buttonsCollapsed ? 'collapsed' : ''
          }`}
        >
          {allButtons.length > 0 &&
            allButtons.map((b: ButtonProps, i) => (
              <div
                key={i}
                className={`action-li-button ${
                  b.disabled ? 'action-li-button-disabled' : ''
                } ${b.isSecondary ? 'action-li-button-secondary' : ''} ${
                  hasCollapse && buttonsCollapsed && i > 0 ? 'collapsed' : ''
                }`}
                onClick={b.disabled ? undefined : b.onClick}
              >
                {b.text}
              </div>
            ))}
        </div>

        {hasCollapse && (
          <div
            className={`action-li-show-more`}
            onClick={() => {
              setButtonsCollapsed(prev => !prev);
            }}
          >
            {buttonsCollapsed
              ? t('actionListItem.moreLabel')
              : t('actionListItem.lessLabel')}
          </div>
        )}
      </div>
    </div>
  );
}

export default ActionListItem;
