import _reduce from 'lodash/reduce';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';
import { DistanceUnit } from '@curebase/core/lib/height';
import { validate } from 'uuid';

export default function flattenCapturedData(
  data: Record<string, Record<string, string | number | Array<any>>>
) {
  return _reduce(
    data,
    (prev, value, key) => {
      if (_isObject(value) && !_isArray(value)) {
        // FIXME: we don't want to spread the Height object
        if (DistanceUnit.Feet in value && DistanceUnit.Inches in value) {
          return { ...prev, [key]: value };
        }
        const allUUID = Object.keys(value).every(ele => {
          return validate(ele);
        });
        if (allUUID) {
          return { ...prev, ...value };
        } else {
          return { ...prev, [key]: value };
        }
      } else return { ...prev, [key]: value };
    },
    {}
  );
}
