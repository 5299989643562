import * as React from 'react';

type SelectOption = {
  text: string;
  value: string;
};

type Props = {
  options: SelectOption[];
  selectedValue: string;
  onChange: (arg0: string) => void | Promise<void>;
};

class SingleSelect extends React.Component<Props> {
  render() {
    const { options, selectedValue, onChange } = this.props;
    return (
      <div className='single-select'>
        {options.map((option: SelectOption, i) => (
          <div
            className='ss-option'
            key={i}
            onClick={() => onChange(option.value)}
          >
            <div className='ss-option-left'>
              <img
                src={
                  option.value === selectedValue
                    ? '/radioButtonSelected.svg'
                    : '/radioButtonNotSelected.svg'
                }
                alt='Radio'
                className='ss-option-radio-button'
              />
            </div>
            <div className='ss-option-right'>{option.text}</div>
          </div>
        ))}
      </div>
    );
  }
}

export default SingleSelect;
