import * as React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { isMobileBrowser } from '../../lib/ui';

type Props = {
  fields?: number;
  value?: string;
  onChange?: (value: string) => any;
  onEnter?: () => any;
  hasError?: boolean;
};

type State = {};

class PinInput extends React.Component<Props, State> {
  inputs: any[];

  constructor(props: Props) {
    super(props);
    this.inputs = [];
  }

  componentDidUpdate() {
    const { value } = this.props;
    const newCursorIndex = value
      ? Math.min(value.trim().length, this.inputs.length - 1)
      : 0;
    this.inputs[newCursorIndex].focus();
  }

  render() {
    const { fields, value, onChange, onEnter, hasError } = this.props;
    const safeFields = fields || 6;
    const safeValue = `${value || ''}      `.substring(0, 6);
    return (
      <div className='pin-input-outside-container'>
        <div className='pin-input-container'>
          {_.range(safeFields).map(i => (
            <div key={i} className={'pin-input-single'}>
              <input
                ref={input => {
                  this.inputs[i] = input;
                }}
                readOnly={isMobileBrowser()}
                onFocus={e => {
                  if (isMobileBrowser()) {
                    e.target.blur();
                  }
                }}
                type='text'
                value={
                  safeValue.length > i ? safeValue.substring(i, i + 1) : ''
                }
                min={0}
                max={9}
                className={`pin-input-input ${
                  hasError ? 'pin-input-has-error' : ''
                }`}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    if (onEnter) {
                      onEnter();
                    }
                  }
                }}
                onKeyDown={e => {
                  // Backspace
                  if (e.keyCode === 8) {
                    if (onChange) {
                      onChange(
                        `${safeValue.substring(0, i)} ${safeValue.substring(
                          i + 1,
                          safeValue.length
                        )}`
                      );
                    }
                    if (i > 0) {
                      this.inputs[i - 1].focus();
                    }
                  }
                  // Digits
                  if (e.keyCode >= 48 && e.keyCode <= 57) {
                    const digit = e.keyCode - 48;
                    if (onChange) {
                      onChange(
                        `${safeValue.substring(
                          0,
                          i
                        )}${digit}${safeValue.substring(
                          i + 1,
                          safeValue.length
                        )}`
                      );
                    }
                    if (i < safeFields - 1) {
                      this.inputs[i + 1].focus();
                    }
                  }
                }}
              />
            </div>
          ))}
        </div>
        {isMobileBrowser() && this.renderMobile()}
      </div>
    );
  }

  renderMobile() {
    const { fields, value, onChange, onEnter } = this.props;
    const safeFields = fields || 6;
    const safeValue = `${value || ''}      `.substring(0, 6);
    return (
      <div className='mobile-pin-input-container'>
        {_.range(4).map(i => (
          <div className='mobile-pin-input-row'>
            {_.range(3).map(j => {
              const digit = i <= 2 ? i * 3 + j + 1 : j === 1 ? 0 : undefined;
              return (
                <div
                  className={`
                  mobile-pin-input-button
                  ${j === 0 ? 'mobile-pin-input-button-left' : ''}
                  ${j === 1 ? 'mobile-pin-input-button-center' : ''}
                  ${j === 2 ? 'mobile-pin-input-button-right' : ''}
                  ${i === 3 && j !== 1 ? 'mobile-pin-input-button-noop' : ''}`}
                >
                  <Button
                    variant={'fab' as any}
                    onClick={() => {
                      if (digit !== undefined) {
                        const focusIndex = safeValue.trim().length;
                        if (onChange) {
                          onChange(
                            `${safeValue.substring(
                              0,
                              focusIndex
                            )}${digit}${safeValue.substring(
                              focusIndex + 1,
                              safeValue.length
                            )}`
                          );
                        }
                        setTimeout(() => {
                          if (focusIndex === safeFields - 1) {
                            if (onEnter) {
                              onEnter();
                            }
                          }
                        }, 50);
                      }
                    }}
                  >
                    {digit}
                  </Button>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}

export default PinInput;
