import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PatientIntegrationSourceDataType } from '../../../types';

import Dialog from '../../basic/SafeDialog';
import LoaderDialog from '../../basic/LoaderDialog';

import ConfirmationInDialog from '../../basic/ConfirmationInDialog';
import { splitSourcesByDataType } from '@curebase/core/lib/connectSourceData';

type Props = {
  goToNextSource: () => void;
  onClose: () => void;
  requiredSources: string;
  sources: any[];
};

const ConnectionNavigationDialog = ({
  requiredSources,
  sources,
  onClose,
  goToNextSource,
}: Props) => {
  const smartDevices = 'smart devices';
  const medicalRecords = 'medical records';
  const { t } = useTranslation('translations');

  const {
    medicalSources: { length: numberOfMedicalSources },
    wellnessSources: { length: numberOfWellnessSources },
  } = splitSourcesByDataType(sources ?? []);

  const successfullyConnectedBothSources =
    numberOfWellnessSources > 0 && numberOfMedicalSources > 0;

  if (successfullyConnectedBothSources) {
    // we just need to wait for props from above to propigate and stop rendering this component.
    return <LoaderDialog open={true} />;
  }

  let title,
    description = '';

  // if they successfully connected at least one device
  const successfullyConnectedAtLeastOneSource =
    numberOfWellnessSources > 0 || numberOfMedicalSources > 0;
  if (successfullyConnectedAtLeastOneSource) {
    let needsToConnect = '';
    let hasConnected = '';
    if (
      requiredSources === PatientIntegrationSourceDataType.Medical &&
      numberOfWellnessSources > 0
    ) {
      needsToConnect = medicalRecords;
      hasConnected = smartDevices;
    } else if (
      requiredSources === PatientIntegrationSourceDataType.Wellness &&
      numberOfMedicalSources > 0
    ) {
      needsToConnect = smartDevices;
      hasConnected = medicalRecords;
    }
    title = t('connectionNavigationDialog.withConnections.title', {
      needsToConnect,
    });
    description = t('connectionNavigationDialog.withConnections.desc', {
      hasConnected,
      needsToConnect,
    });
  } else {
    // requiredSources could be MEDICAL_WELLNESS in which case we'll just start with MEDICAL
    const nextSource =
      requiredSources === PatientIntegrationSourceDataType.Wellness
        ? PatientIntegrationSourceDataType.Wellness
        : PatientIntegrationSourceDataType.Medical;

    const tPrefix = `connectionNavigationDialog.default.${nextSource.toLocaleLowerCase()}`;
    title = t(`${tPrefix}.title`);
    description = t(`${tPrefix}.description`);
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <ConfirmationInDialog
        title={title}
        message={description}
        onClose={onClose}
        onConfirm={goToNextSource}
        confirmationText={`${t('common.connectNow')} ➔`}
        closeText={t('connectionNavigationDialog.closeTextDialog')}
      />
    </Dialog>
  );
};

export default ConnectionNavigationDialog;
