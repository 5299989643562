import { SigningType } from '@curebase/core/decoders/signatures';
import {
  finishedInformedConsent,
  getAttestationsByAction,
  hasMinorAssentDocument,
  isMinorAssentDocument,
  signedDocumentOfType,
} from '@curebase/core/lib/documents';
import {
  availableActions,
  DocumentConfigurationAppliesTo,
  getMachineForDocument,
} from '@curebase/core/lib/documentSigningMachine';
import { DEVELOPER_FEATURES_ENABLED } from '@curebase/core/lib/env';
import { mdToHTML } from '@curebase/core/lib/markdown';
import { getOrderedStatesForMachine } from '@curebase/core/machines';
import {
  DocumentAction,
  DocumentConfiguration,
  DocumentContent,
  DocumentType,
  SigningMode,
} from '@curebase/core/types';
import {
  AccessTimeOutlined,
  ArrowForwardRounded,
  Check,
  Close,
  EditOutlined,
} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { last } from 'lodash';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { getNextExploreRoute } from 'src/controllers/patientController';
import useRoles from 'src/hooks/useRoles';
import { signatureFooterPortalMount } from 'src/store/telehealth/actions';
import {
  SignedDocumentStatus,
  StudyActivityType,
  useInformedConsentSignedDocumentStatusQuery,
  useSignDocumentQuery,
} from 'src/types';
import { KnowledgeSection } from '../../../../core/types';
import {
  autoCompleteDocuments,
  uploadDocumentAndSignature,
} from '../../controllers/signatureController';
import {
  attemptCustomGtmEvent,
  GtmCustomEvents,
} from '../../lib/analytics-gtm';
import { userIsParticipant } from '../../lib/users';
import { onDialogOpen } from '../../utils/mobileHelpers';
import KnowledgeContent from '../basic/KnowledgeContent';
import Dialog from '../basic/SafeDialog';
import SignatureCaptureInDialog from '../basic/SignatureCaptureInDialog';
import StandardButton from '../basic/StandardButton';
import ConsentNotes from './ConsentNotes';
import { DocumentHeaderSubtext } from './SignDocumentComponents/DocumentHeaderSubtext';
import DocumentSignToolbar from './DocumentSignToolbar';
import { DocumentsContext } from './DocumentsOverview';
import MinorAssentDialog from './MinorAssentDialog';
import ParticipantHelpRequestDialog from './ParticipantHelpRequestDialog';
import { DocumentHeaderCount } from './SignDocumentComponents/DocumentHeaderCount';
import { DocumentHeaderTitle } from './SignDocumentComponents/DocumentHeaderTitle';
import ExploreStepProgress from '../Explore/ExploreStepProgress';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useIsOnExplore } from 'src/hooks/useIsOnExplore';
import { useOverridableTranslation } from 'src/hooks/useOverridableTranslation';

//TrialOptionId is valid if parseInt returns an Int and not a NaN
const isValidTrialOptionId = (x: any): x is string =>
  !Number.isNaN(parseInt(x));

type DocumentConfig = DocumentConfigurationAppliesTo &
  Pick<
    DocumentConfiguration,
    'id' | 'displayName' | 'isMinorAssent' | 'locales'
  >;
interface SignDocumentProps<T extends DocumentConfig> {
  baseUrl: string;
  document: any;
  onlyComment?: boolean;
  documentConfig: T;
  documentContents: DocumentContent;
  setDocumentType: (docType: any) => any;
  refetch: () => any;
  inPatientMode?: boolean;
  trialOption: any;
  goToDocument: (
    documentType: string | undefined,
    patientMode?: boolean
  ) => void;
}

const SignatureFooter = (props: any) => {
  const { advanceOnSubmit, advanceText, autocomplete } = props;
  const isOnExplore = useIsOnExplore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    signatureFooterPortalMount(true);
    return () => {
      signatureFooterPortalMount(false);
    };
  }, []);
  const footerContent = (
    <div className='df-footer'>
      <div className='df-button-container'>
        {isOnExplore && isMobile && (
          <div className={'df-left-side-footer-slot'}>
            <ExploreStepProgress mobileOnly />
          </div>
        )}
        <StandardButton
          onClick={advanceOnSubmit}
          variant='contained'
          className='forward-button'
          showLoading
        >
          {advanceText}
          <ArrowForwardRounded />
        </StandardButton>

        {autocomplete && (
          <StandardButton
            color='secondary'
            onClick={autocomplete}
            variant='contained'
            className='autocomplete-button'
          >
            <img src='/logo/logo-transparent.png' alt='Curebase Logo' />
          </StandardButton>
        )}
      </div>
    </div>
  );

  const footerRef = document.getElementById('sticky-footer');
  return footerRef ? ReactDOM.createPortal(footerContent, footerRef) : null;
};

const getSignatureReason = (
  action: DocumentAction | undefined | null,
  sectionAttestations: KnowledgeSection | undefined,
  defaultSigningMessage: string
) =>
  String(
    action === DocumentAction.ProvideCounterSignature
      ? sectionAttestations?.counterAttestation ?? defaultSigningMessage
      : sectionAttestations?.signatureAttestation ?? defaultSigningMessage
  );

const SignDocument = <T extends DocumentConfig>(
  props: SignDocumentProps<T>
) => {
  const {
    baseUrl,
    document,
    documentConfig,
    documentConfig: { id: documentConfigurationId },
    documentContents,
    goToDocument,
    inPatientMode,
    refetch,
    trialOption,
    onlyComment,
  } = props;

  const documentConfigurations = useMemo(() => {
    let configs: NonNullable<
      typeof trialOption.signedDocuments[number]['documentConfiguration']
    >[] = [];
    trialOption.signedDocuments.forEach(sd => {
      if (
        sd.documentConfiguration &&
        sd.documentConfiguration.documentType !== DocumentType.Enrollment
      ) {
        configs.push(sd.documentConfiguration);
      }
    });
    return configs;
  }, [trialOption]);

  const isParticipant = userIsParticipant();
  const { t, locale } = useOverridableTranslation();
  const history = useHistory();
  const location = useLocation();
  const user = useRoles(trialOption.trial.id);
  const { setContext, action, signingType } = useContext(DocumentsContext);
  const sectionAttestations = documentContents.sections?.[0];
  const machine = getMachineForDocument(documentConfig);
  const actions = availableActions(
    machine,
    document.status,
    user,
    documentConfig
  );
  const isOnExplore = useIsOnExplore();
  const { trialOptionId, documentType, trialIdentifier } = useParams<any>();
  const dashboardUrl = '/u';

  const back = useCallback(() => {
    if (isParticipant) {
      history.push(dashboardUrl);
    } else {
      history.push(baseUrl);
    }
  }, [history, trialOptionId, isParticipant]);

  //state
  const [showSignatureDialog, setShowSignatureDialog] = useState(false);
  const [showMinorAssentDialog, setShowMinorAssentDialog] = useState(false);
  const [
    participantHelpRequestDialogOpen,
    setParticipantHelpRequestDialogOpen,
  ] = useState(false);

  const { data, stopPolling } = useInformedConsentSignedDocumentStatusQuery({
    fetchPolicy: 'network-only',
    variables: { signedDocumentId: document.id },
    pollInterval: 5000,
  });

  const { data: trialData } = useSignDocumentQuery({
    fetchPolicy: 'network-only',
    variables: {
      trialId: trialOption.trial.id,
    },
  });

  const isLastStepInTheFlow = useCallback(() => {
    // This keeps the old behavior - user will be redirected to their dashboard after this step if
    // there is unexpected trialdata data missing.
    if (!trialData) return true;
    const machine =
      trialData?.getTrial?.latestTrialConfiguration?.machine || {};
    const topBarStates = {};
    Object.keys(machine).forEach(state => {
      const studyActivities = machine[state]?.meta?.studyActivities || [];
      const isPresentOnTopBar = studyActivities?.some(
        sa => sa.topBar || sa.allowInSignup
      );
      if (isPresentOnTopBar) {
        topBarStates[state] = machine[state];
      }
    });
    const machineStates = getOrderedStatesForMachine(topBarStates);
    const lastState = last(machineStates) as keyof typeof machine;
    const lastStateActivities =
      machine[lastState]?.meta?.studyActivities?.filter(
        sa => sa.topBar || sa.allowInSignup
      ) || [];
    const lastActivityType = last<any>(lastStateActivities)?.type;
    return lastActivityType === StudyActivityType.InformedConsent;
  }, [trialData]);

  useEffect(() => {
    if (
      data?.getSignedDocument.status ===
      SignedDocumentStatus.NeedsParticipantSignature
    ) {
      stopPolling && stopPolling();
      setContext(signingType, DocumentAction.AcquireParticipantSignature);
    }
  }, [data, setContext, signingType, stopPolling]);

  useEffect(() => {
    if (documentConfig.isMinorAssent) {
      setShowMinorAssentDialog(true);
    }
  }, [documentConfig.isMinorAssent]);

  //bad params, redirect to the dashboard
  if (!isValidTrialOptionId(trialOptionId)) {
    return <Redirect to={dashboardUrl} />;
  }

  let headerSubtextTop: string = '';
  let headerSubtextBottom: string = '';
  let headerIcon: React.ReactNode = <EditOutlined />;

  const getSafeProperty = function (key: any) {
    const localeFromService = documentConfig.locales?.[locale]?.[key];
    return localeFromService ?? documentConfig[key];
  };

  const getDocumentSections = () => {
    const localeSections = documentConfig.locales?.[locale]?.content?.sections;
    const sections = documentContents.sections?.map((section, index) => {
      if (localeSections?.[index] && localeSections[index]?.text) {
        return localeSections[index];
      }
      return section;
    });
    return sections;
  };

  const getDocumentAttestations = () => {
    const localesAttestations =
      documentConfig.locales?.[locale]?.content?.attestations;
    const attestations = documentContents.attestations?.map(
      (attestation, index) => {
        if (localesAttestations?.[index].attestations?.length > 0) {
          return localesAttestations?.[index];
        }
        return attestation;
      }
    );

    return attestations;
  };

  const preface: string | null = getSafeProperty('preface');
  const hasNoActions = _isEmpty(actions);

  if (action === DocumentAction.AcquireParticipantSignature) {
    headerSubtextTop = t('signDocuments.headerTopReadyToSign');
    headerSubtextBottom = preface || t('signDocuments.headerBottomReadyToSign');
  } else if (action === DocumentAction.ProvideCounterSignature) {
    headerSubtextTop = t('signDocuments.headerTopHasSigned');
    headerSubtextBottom = t('signDocuments.headerBottomHasSigned');
  } else if (
    document.status === SignedDocumentStatus.Completed &&
    document.signatures?.length > 0
  ) {
    const signature = document.signatures.find(
      (item: any) => item.signatureUserType === 'PATIENT'
    );
    if (!!signature && !!signature.legalName && !!signature.createdAt) {
      headerIcon = <Check />;
      headerSubtextTop = t('signDocuments.headerTopSigned');
      headerSubtextBottom = t('signDocuments.headerBottomSigned', {
        legaName: signature.legalName,
        date: new Date(signature.createdAt).toLocaleString(),
      });
    }
  } else if (document.status === SignedDocumentStatus.Declined) {
    headerIcon = <Close />;
    headerSubtextTop = t('signDocuments.headerTopSkipped');
    headerSubtextBottom = t('signDocuments.headerBottomSkipped');
  } else if (hasNoActions) {
    headerIcon = <AccessTimeOutlined />;
    headerSubtextTop = t('signDocuments.headerTopNoAction');
    headerSubtextBottom = t('signDocuments.headerBottomNoAction');
    if (document.status === SignedDocumentStatus.NeedsCounterSignature) {
      headerSubtextBottom = t(
        'signDocuments.headerBottomNeedsCounterSignature'
      );
    }
  }

  const documentTypes = trialOption.signedDocuments.map(
    doc => doc.documentType
  ) as string[];

  const currentDocument: any = signedDocumentOfType(
    trialOption.signedDocuments,
    documentType
  );

  if (!currentDocument) {
    const error: string = 'signedDocumentId is not present';
    console.error(error);
    throw new Error(error);
  }

  const currentDocumentConfig = _find(documentConfigurations, {
    documentType: currentDocument.documentType,
  })!;
  const signedDocumentId: number = currentDocument.id;

  const showConsentNotes: boolean =
    !isParticipant && !!currentDocumentConfig.allowNotes && !inPatientMode;

  const currentDocumentIndex = parseInt(
    // @ts-ignore
    Object.keys(documentTypes).find(key => documentTypes[key] === documentType)
  );
  const unsignedDocuments = trialOption.signedDocuments
    .filter(
      signedDocument => signedDocument.status !== SignedDocumentStatus.Completed
    )
    .map(signedDocument => signedDocument.documentType);
  const numUnsignedDocuments = unsignedDocuments.length;
  const currentUnsignedDocIndex = unsignedDocuments.indexOf(documentType);

  let nextDocumentIndex = currentDocumentIndex + 1;
  let nextDocumentType: string | undefined = documentTypes[nextDocumentIndex];
  let nextDocumentIsUnsigned = unsignedDocuments.find(
    doc => doc === nextDocumentType
  );
  const findDoc = (documentType: string) => documentType === nextDocumentType;

  while (!nextDocumentIsUnsigned && nextDocumentType !== undefined) {
    nextDocumentIndex = nextDocumentIndex + 1;
    nextDocumentType = documentTypes[nextDocumentIndex];
    nextDocumentIsUnsigned = unsignedDocuments.find(findDoc);
  }

  const advanceText = _isEmpty(actions)
    ? t('signDocuments.nextDocument')
    : t('signDocuments.readyToSign');

  const proceed = async () => {
    if (isOnExplore && !isLastStepInTheFlow()) {
      const nextRoute = await getNextExploreRoute({
        trialIdentifier,
        trialOptionId: parseInt(trialOptionId),
      });
      nextRoute ? history.push(nextRoute) : back();
    } else {
      back();
    }
  };

  const advanceOnSubmit = () => {
    //document is completed
    if (_isEmpty(actions)) {
      if (!finishedInformedConsent(trialOption)) {
        goToDocument(nextDocumentType, false);
        window.scrollTo(0, 0);
      } else {
        proceed();
      }
    } else if (!isParticipant) {
      if (!action || !signingType) {
        history.push(baseUrl);
      }
      // @ts-ignore
      setShowSignatureDialog(true);
    } else if (
      actions[DocumentAction.AcquireParticipantSignature] &&
      documentConfig.remote === SigningMode.NoCountersignature
    ) {
      setContext(
        SigningType.remote,
        DocumentAction.AcquireParticipantSignature
      );
      setShowSignatureDialog(true);
    } else {
      setParticipantHelpRequestDialogOpen(true);
    }
  };

  const autocomplete =
    !finishedInformedConsent(trialOption) && DEVELOPER_FEATURES_ENABLED
      ? async () => {
          await autoCompleteDocuments(props.trialOption.id);
          if (location.pathname.startsWith('/explore/miracle')) {
            const nextRoute = await getNextExploreRoute({
              trialIdentifier,
              trialOptionId: parseInt(trialOptionId),
            });
            history.push(nextRoute);
          } else {
            proceed();
          }
        }
      : null;

  const onClickDeclineDocument = async () => {
    const request = {
      action: DocumentAction.DeclinedParticipantSignature,
      signingType: SigningType.remote,
      documentType,
      trialOptionId: parseInt(trialOptionId),
      documentConfigurationId,
    };
    await uploadDocumentAndSignature(request);
    if (nextDocumentType) {
      goToDocument(nextDocumentType, !userIsParticipant());
    } else {
      proceed();
    }
  };

  const onClickNextDocument = () => {
    goToDocument(nextDocumentType, false);
    window.scrollTo(0, 0);
  };

  const defaultSigningMessage = t('signDocuments.approveThisDoc');

  const attestations = action
    ? getAttestationsByAction(getDocumentAttestations(), action)
    : undefined;

  const isMinorAssentSignature = isMinorAssentDocument(documentType);
  const showMinorAssentCheckbox = hasMinorAssentDocument(
    documentConfigurations
  );

  const reason = getSignatureReason(
    action,
    sectionAttestations,
    defaultSigningMessage
  );

  return (
    <>
      <div className='df-body'>
        {onlyComment && (
          <div className='ic-back-button' onClick={() => history.goBack()}>
            <ArrowBackIcon fontSize='inherit' />
          </div>
        )}
        <div className='df-page'>
          <div className='ic-header'>
            <DocumentHeaderCount
              current={currentUnsignedDocIndex + 1}
              howManyDocuments={numUnsignedDocuments}
            />
            <DocumentHeaderTitle title={getSafeProperty('displayName')} />
            {/* @ts-ignore */}
            {documentConfig.richDescription && (
              <div
                className='markdown'
                dangerouslySetInnerHTML={{
                  //@ts-ignore
                  __html: mdToHTML(getSafeProperty('richDescription')),
                }}
              />
            )}
            {!!headerSubtextTop && !!headerSubtextBottom && !onlyComment && (
              <DocumentHeaderSubtext
                headerIcon={headerIcon}
                headerSubtextTop={headerSubtextTop}
                headerSubtextBottom={headerSubtextBottom}
              />
            )}
          </div>
          {/* @ts-ignore */}
          <KnowledgeContent sections={getDocumentSections()} />

          <DocumentSignToolbar
            document={document}
            enableSignature={!hasNoActions}
            documentConfig={currentDocumentConfig}
            onClickNext={onClickNextDocument}
            onClickToSign={advanceOnSubmit}
            onClickToSkip={onClickDeclineDocument}
            onAutoComplete={autocomplete}
            hasNextDocument={!!nextDocumentType}
          />

          {documentConfig.isMinorAssent && (
            <Dialog maxWidth={false} open={showMinorAssentDialog}>
              <MinorAssentDialog
                onProceed={proceed}
                trialOptionId={parseInt(trialOptionId)}
                onClose={() => setShowMinorAssentDialog(false)}
                documentConfigurationId={documentConfigurationId}
              />
            </Dialog>
          )}

          {isParticipant && (
            <ParticipantHelpRequestDialog
              status={document?.status}
              open={participantHelpRequestDialogOpen}
              onDeny={() => {
                setParticipantHelpRequestDialogOpen(false);
              }}
              onConfirm={() => {
                setParticipantHelpRequestDialogOpen(false);
                setShowSignatureDialog(true);
              }}
              onClose={() => setParticipantHelpRequestDialogOpen(false)}
              refetch={refetch}
            />
          )}

          {document && (
            <Dialog
              maxWidth={false}
              open={onDialogOpen(showSignatureDialog)}
              onClose={() => setShowSignatureDialog(false)}
            >
              <SignatureCaptureInDialog
                trialOptionId={parseInt(trialOptionId)}
                patient={trialOption?.patient}
                inPatientMode={inPatientMode ?? false}
                isMinorAssentSignature={isMinorAssentSignature}
                showMinorAssentCheckbox={showMinorAssentCheckbox}
                reason={reason}
                attestations={attestations}
                onSubmit={async (signatureData: any) => {
                  if (action === null || signingType === null) {
                    return;
                  }

                  const request = {
                    signature: signatureData,
                    signingType,
                    action,
                    documentType,
                    trialOptionId: parseInt(trialOptionId),
                    documentConfigurationId,
                  };
                  const {
                    signedDocumentId,
                    hasSignedAllDocuments,
                  } = await uploadDocumentAndSignature(request);

                  if (signedDocumentId)
                    attemptCustomGtmEvent(GtmCustomEvents.SIGNED_DOCUMENT);

                  if (hasSignedAllDocuments)
                    attemptCustomGtmEvent(GtmCustomEvents.SIGNED_ALL_DOCUMENTS);

                  setShowSignatureDialog(false);
                  await refetch();

                  if (!hasSignedAllDocuments) {
                    // User has not signed all documents, and is or isn't in patient mode
                    if (isParticipant) {
                      // User is participant, go to next document
                      goToDocument(nextDocumentType, false);
                    } else {
                      // If there is no next document
                      if (!nextDocumentType) {
                        // Go to DocumentsOverview
                        history.push(baseUrl);
                      } else {
                        goToDocument(nextDocumentType, inPatientMode);
                      }
                    }
                  } else {
                    if (isParticipant && !isOnExplore) {
                      history.push(dashboardUrl);
                      return;
                    }
                    // User has signed all documents
                    if (!isOnExplore) {
                      history.push(baseUrl);
                      return;
                    }
                    if (isLastStepInTheFlow()) {
                      back();
                      return;
                    }
                    const nextRoute = await getNextExploreRoute({
                      trialIdentifier,
                      trialOptionId: parseInt(trialOptionId),
                    });
                    history.push(nextRoute);
                  }
                }}
                onClose={() => setShowSignatureDialog(false)}
              />
            </Dialog>
          )}

          {!onlyComment && !currentDocumentConfig.allowDecline && (
            <SignatureFooter
              advanceText={advanceText}
              advanceOnSubmit={advanceOnSubmit}
              autocomplete={autocomplete}
            />
          )}
        </div>
        {showConsentNotes && (
          <ConsentNotes
            trialOptionId={parseInt(trialOptionId)}
            signedDocumentId={signedDocumentId}
          />
        )}
      </div>
    </>
  );
};

export default SignDocument;
