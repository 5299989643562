import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../basic/SafeDialog';
import Subheader from '../basic/Subheader';
import ListItem from '../basic/ListItem';
import DynamicForm from '../basic/DynamicForm';
import BottomButtons from '../basic/BottomButtons';
import ConfirmationInDialog from '../basic/ConfirmationInDialog';
import { useTrialOptionDetailsEditorQuery } from '../../types';
import { updateTrialOption } from '../../controllers/trialOptionController';
import { currentUserHasPermission } from '../../lib/auth';
import {
  Locale,
  Pii_Status,
  RolePermissions,
  UserRoleType,
  VisitSite,
} from '@curebase/core/types';
import { showAlertMessage } from '../../store/actions';
import { UpdateTrialOptionRequest } from '@curebase/core/decoders/trialOptions';
import { StatusColor } from '../../shared/lib/colors';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import useRoles from 'src/hooks/useRoles';
import { isInternalUser } from '../Analytics/tools';
import { userHasRoles } from '@curebase/core/lib/user';
import { getUser } from '../../store';

interface Props {
  trialOptionId: number;
  trialId: number;
  availableLocales: Array<Locale> | undefined;
}

function TrialOptionDetailsEditor(props: Props) {
  const { trialOptionId, trialId, availableLocales } = props;
  const { t } = useTranslation('translations');
  const user = useRoles(trialId);
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [data, setData] = useState<UpdateTrialOptionRequest | undefined>(
    undefined
  );
  const [dataErrors, setDataErrors] = useState<Object>({});

  const {
    data: trialOptionDetailsEditorData,
    // loading,
    refetch: refetchGetTrialOptionDetailsEditor,
  } = useTrialOptionDetailsEditorQuery({
    variables: {
      trialOptionId: trialOptionId,
      trialId: trialId,
    },
  });

  useEffect(() => {
    if (trialOptionDetailsEditorData?.getTrialOption) {
      const trialOption = trialOptionDetailsEditorData.getTrialOption;
      setData({
        emrId: trialOption?.emrId ?? null,
        trialOptionId,
        visitSiteId: trialOption.visitSite ? trialOption.visitSite.id : null,
        customSubjectIdentifier: trialOption.customSubjectIdentifier,
        locale: trialOption.locale,
      });
    }
  }, [trialOptionDetailsEditorData]);

  async function submit() {
    if (!!data) {
      const response: any = await updateTrialOption({
        trialOptionId: trialOptionId,
        emrId: data?.emrId,
        visitSiteId: data.visitSiteId || null,
        customSubjectIdentifier: data.customSubjectIdentifier,
        locale: data?.locale,
      });
      if (response.error) {
        if (response.errorDetails) {
          setDataErrors(response.errorDetails);
        } else {
          //@todo move this hardcoded error message to the backend, render with setDataErrors
          const errorMsg = `Unable to move participant${
            response.message.includes('documentConfigId')
              ? ': Signed Documents are incompatible'
              : ''
          }`;
          showAlertMessage(errorMsg, StatusColor.Red, 30);
        }
      } else {
        setDataHasChanged(false);
        refetchGetTrialOptionDetailsEditor();
      }
    }
  }

  function validateDetails() {
    const errors = {};

    // add validation if necessary
    if (errors && Object.keys(errors).length > 0) {
      setDataErrors(errors);
    } else {
      setDataErrors({});
      setShowConfirmDialog(true);
    }
  }

  // @FIXME: not sure if this is right
  if (!trialOptionDetailsEditorData) return null;

  const canCollectPII =
    trialOptionDetailsEditorData.getTrialOption.piiStatus ===
    Pii_Status.Enabled;

  //@ts-ignore
  const visitSites: Array<VisitSite> = trialOptionDetailsEditorData.getTrialInstances
    ? trialOptionDetailsEditorData.getTrialInstances
        .map(instance => instance.clinic.visitSites)
        .flat()
    : [];

  const elements: any[] = [];

  if (
    trialOptionDetailsEditorData.getTrialOption.trial.allowCustomSubjectId &&
    userHasRoles(getUser(), [
      UserRoleType.TechnicalTrialManager,
      UserRoleType.ClinicalResearchCoordinator,
    ])
  ) {
    elements.push({
      title: 'SUBJID',
      subElements: [
        {
          type: 'TEXT',
          key: 'customSubjectIdentifier',
          placeholder: t('common.customSubjectIdentifier'),
        },
      ],
    });
  }

  if (canCollectPII) {
    elements.push({
      title: 'EMR ID',
      subElements: [{ type: 'TEXT', key: 'emrId', placeholder: 'EMR ID' }],
    });
  }

  if (currentUserHasPermission(RolePermissions.EditTrialOptionVisitSite)) {
    elements.push({
      title: t('profile.visitSiteTitle'),
      subElements: [
        {
          type: 'DROPDOWN',
          key: 'visitSiteId',
          options: visitSites.map(site => ({
            text: site.name,
            value: site.id,
          })),
        },
      ],
    });
  }

  if (elements.length === 0) return null;

  const isTechnicalTrialManager = user.roles?.find(
    ({ type }) => type === UserRoleType.TechnicalTrialManager
  );
  const canEditLanguage = isTechnicalTrialManager || isInternalUser(user.email);

  return (
    <>
      <Subheader text={t('profile.trialParticipantionSubheader')} />
      <div className='li-multi-container inset'>
        <ListItem>
          <DynamicForm
            pages={[{ elements }]}
            onChange={(key, value) => {
              // @ts-expect-error
              setData({ ...data, [key]: value });
              setDataHasChanged(true);
            }}
            data={data}
            errors={dataErrors}
            noSubmit
          />
          <div className='dynamic-form participant-language-container'>
            {canEditLanguage &&
              availableLocales &&
              availableLocales.length > 1 && (
                <FormControl className='df-body'>
                  <div className='df-context-title'>
                    {' '}
                    {t('signupForm.formElements.language')}{' '}
                  </div>
                  <Select
                    value={data?.locale ?? ''}
                    defaultValue={data?.locale ?? ''}
                    onChange={event => {
                      // @ts-expect-error
                      setData({ ...data, locale: event.target.value });
                      setDataHasChanged(true);
                    }}
                  >
                    {availableLocales?.map(({ locale, language, country }) => (
                      <MenuItem value={locale} key={locale}>
                        {' '}
                        {t(`languages.${country}.${language}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
          </div>
        </ListItem>
      </div>

      <BottomButtons
        buttons={[
          {
            text: t('profile.userDetailsSubmit'),
            onClick: () => {
              validateDetails();
            },
            disabled: !dataHasChanged,
          },
        ]}
      />

      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <ConfirmationInDialog
          title={t('profile.userDetailsConfirmDialogTitle')}
          message={t('profile.userDetailsConfirmDialogMessage')}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={async () => {
            setShowConfirmDialog(false);
            await submit();
          }}
        />
      </Dialog>
    </>
  );
}

export default TrialOptionDetailsEditor;
